import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'trimText' })
export class TrimText implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: any, length: any) {
    if (!(content.message instanceof Array)) {
      if (!content.message) {
        content = this.processStringContent(content, length);
      } else {
        content.message = this.processContent(content.message);
        content.isVisible = false;
      }
    }
    return content;
  }

  processContent(content: string) {
    const contentArray = [];
    const tabContent = document.createElement('ng-template');
    tabContent.innerHTML = content;
    const hiddenContent: HTMLElement = document.createElement('div');
    for (let i = 0; i < tabContent.childNodes.length; i++) {
      if (i !== 0) {
        hiddenContent.insertBefore(tabContent.childNodes[i], hiddenContent.childNodes[0]);
      }
    }
    contentArray.push(tabContent.innerHTML);
    if (hiddenContent.childNodes.length > 0) {
      contentArray.push(hiddenContent.innerHTML);
    }
    return contentArray;
  }

  processStringContent(content: string, length: any) {
    const contentArray = [];
    const tabContent = document.createElement('ng-template');
    tabContent.innerHTML = content;
    const hiddenContent: HTMLElement = document.createElement('div');
    const visibleContent: HTMLElement = document.createElement('div');
    for (let i = 0; i < tabContent.childNodes.length; i++) {
      if (i !== 0) {
        hiddenContent.insertBefore(tabContent.childNodes[i], hiddenContent.childNodes[0]);
      }
    }
    contentArray.push(tabContent.innerHTML);
    if (hiddenContent.childNodes.length > 0) {
      contentArray.push(hiddenContent.innerHTML);
    }
    return contentArray;
  }

}
