import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { ContentHelper } from '../../../common/values/contentHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppSession } from './../../../common/values/appSession';
import { ProviderDetailsNavigationService } from './../../../fad/provider-details/services/providerDetailsNavigationSvc';

@Component({
  moduleId: module.id,
  selector: 'app-pf-cost-affiliation-cmp',
  templateUrl: '../views/pfCostAffiliationCmp.html',
  providers: []
})
export class PFCostAffiliationComponent extends BaseComponent implements OnChanges {
  @Input() index = 0;
  @Input() costAffiliation;
  @Input() pdtKey;
  @Output()
  openMapModal: EventEmitter<any> = new EventEmitter<any>();
  showAffiliation = false;
  showMore: boolean = true;
  isShowDistance: boolean = true;

  constructor(private _route: ActivatedRoute,
              private _eventHandler: EventHandler,
              @Inject(ContentHelper)
              private _contentHelper: ContentHelper,
              private _navigationService: NavigationService,
              private _appSession: AppSession,
              private _providerDetailsNavigationService: ProviderDetailsNavigationService) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isShowDistance = (this._appSession && this._appSession.searchParams && this._appSession.searchParams.coordinates &&
      ((this._appSession.searchParams.coordinates.latitude === "0" && this._appSession.searchParams.coordinates.longitude === "0")
        || (this._appSession && this._appSession.searchParams && typeof this._appSession.searchParams.coordinates.latitude === 'undefined'
          && typeof this._appSession.searchParams.coordinates.longitude === 'undefined'))
      ? false : true);
  }

  formatDistance(distance: string) {
    return (distance ? parseFloat(distance).toFixed(2) : '');
  }

  showProviderDetails(professional: any) {
    if (professional) {
      professional.pdtKey = this.pdtKey;
      professional.isAffiliated = true;
    }
    this._providerDetailsNavigationService.navigateToProviderDetails(professional);
  }

  toggleAffiliation() {
    this.showAffiliation = !this.showAffiliation;
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  openLocationMapModal(professional: any) {
    this.openMapModal.emit(professional);
  }
}
