import { Injectable } from '@angular/core';
import { WcsUtil } from '../../utilities/wcsUtil';
import { NEGATIVE, POSITIVE } from './../../../common/constants/alertTypes';
import { DOCTOR_NAME, EFFECTIVE_DATE, ERROR_CODE, MEMBER_NAME } from './../../../common/constants/app-constants';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { IAlertMessage } from './../../../common/interfaces/iMessage';
import { IModifyPcpResponse } from './../../../common/interfaces/iPcpDetails';
import { IPcpIdItem } from './../../../common/interfaces/iPCPInfo';
import { ACTIVE_COVERAGE, BOTH_COVERAGES, FUTURE_COVERAGE, IPcpModifyRequest, IPcpRequest } from './../../../common/interfaces/iPcpRequest';
import { ApiHelper } from './../../../common/services/apiHelper';
import { DataHelper } from './../../../common/services/dataHelper';
import { PCPService } from './../../../common/services/pcpSvc';
import { ContentHelper } from './../../../common/values/contentHelper';

declare let _satellite: any;

@Injectable()
export class ModifyPcpApiHelper extends ApiHelper<IPcpModifyRequest, IModifyPcpResponse>{

  providerName: string;
  memberName: string;

  constructor(private _pcpSvc: PCPService, private _contentHlpr: ContentHelper, private _dataHlpr: DataHelper) {
    super();
  }

  onSuccess(resp: IModifyPcpResponse): void {
    if (!resp) {
      throw new Error('Invalid Data');
    }

    const content = this._contentHlpr.getContent('PFAssignPcpContainerComponent').assignPcpCmpContent;
    let msgPartials: IAlertMessage[] = content.modifyApiContent.successWithEffectiveDate;
    if (this._dataHlpr.isEmptyString(resp.pcpEffectiveDt)) {
      msgPartials = content.modifyApiContent.successWithoutEffectiveDate;
    }
    let msg: string = WcsUtil.getInfoListContent(msgPartials);
    msg = msg.replace(DOCTOR_NAME, this.providerName);
    msg = msg.replace(MEMBER_NAME, this.memberName);
    msg = msg.replace(EFFECTIVE_DATE, resp.pcpEffectiveDt);
    this.alert.alertType = POSITIVE;
    this.alert.alertContent = msg;
    if (typeof _satellite !== 'undefined') {
      _satellite.track('assignPcpSuccess');
    }
  }

  onFailure(errorResp: any): void {
    const content = this._contentHlpr.getContent('PFAssignPcpContainerComponent').assignPcpCmpContent;
    let error: IException = errorResp.error?.exceptions?.error?.[0];
    let msgPartials: IAlertMessage[];
    if (error) {
      //get error msg by code
      const failureMsgs = content.modifyApiContent.failureMsgs as { codes: string, message: IAlertMessage[] }[];
      const msgObj = failureMsgs.find((msg) => {
        const codes = msg.codes.split(',');
        const hasCode = codes.some((cd) => {
          return this._dataHlpr.areEqualStrings(cd, error.code);
        });
        return hasCode;
      });
      if (msgObj) {
        msgPartials = msgObj.message;
      } else {
        msgPartials = content.modifyApiContent.defaultFailureMsg;
      }
    } else {
      error = errorResp.error?.exceptions;
    }
    if (errorResp instanceof Error) {
      error = this.getException(errorResp.message);
      msgPartials = content.modifyApiContent.defaultFailureMsg;
    }
    if (typeof error === 'string') {
      error = this.getException(error + '');
      msgPartials = content.modifyApiContent.defaultFailureMsg;
    }

    let msg = WcsUtil.getInfoListContent(msgPartials);
    msg = msg.replace(DOCTOR_NAME, this.providerName);
    msg = msg.replace(MEMBER_NAME, this.memberName);
    msg = msg.replace(ERROR_CODE, error.code);

    this.alert.alertType = NEGATIVE;
    this.alert.alertContent = msg;
    if (typeof _satellite !== 'undefined') {
      _satellite.track('assignPcpFailure');
    }
  }

  execute(request: IPcpModifyRequest): Promise<IModifyPcpResponse> {
    const invalidReqError = new Error('Invalid request param');
    if (!request || !request.selectedAffliation || !request.selectedReason) {
      return Promise.reject(invalidReqError);
    }
    const contractStatus = request.selectedAffliation.contractStatus;
    const futurePcpIdObj = request.selectedAffliation.futurePcpIds?.[0];
    const activePcpIdObj = request.selectedAffliation.activePcpIds?.[0];
    const providerName = request.providerName;
    const reasonCd = request.selectedReason.value;
    const translatedReasonCd = request.selectedReason.translatedCode;
    const selMbrUid = request.selMbrUid;

    this.alert.alertContent = '';
    let modifyPromise1: Promise<IModifyPcpResponse>;
    let modifyPromise2: Promise<IModifyPcpResponse>;

    if (!contractStatus
      || (this._dataHlpr.areEqualStrings(contractStatus, BOTH_COVERAGES) && (!activePcpIdObj || !futurePcpIdObj))
      || (this._dataHlpr.areEqualStrings(contractStatus, FUTURE_COVERAGE) && !futurePcpIdObj)
      || (this._dataHlpr.areEqualStrings(contractStatus, ACTIVE_COVERAGE) && !activePcpIdObj)
    ) {
      return Promise.reject(invalidReqError);
    }

    const resultPromise = new Promise<IModifyPcpResponse>((resolve, reject) => {
      (async () => {
        let req1: IPcpRequest;
        let req2: IPcpRequest;
        let resp1: IModifyPcpResponse;
        let resp2: IModifyPcpResponse;
        try {
          switch (contractStatus.toUpperCase()) {
            case BOTH_COVERAGES:
              req1 = this.getModifyApiRequest(providerName, translatedReasonCd, selMbrUid, futurePcpIdObj);
              //Call Modify API for Future contract first
              resp1 = await this._pcpSvc.modifyPCP(req1);
              //Call Modify API for Active contract later
              req2 = this.getModifyApiRequest(providerName, reasonCd, selMbrUid, activePcpIdObj);
              await this._pcpSvc.modifyPCP(req2);
              resolve(resp1);
              break;
            case FUTURE_COVERAGE:
              req1 = this.getModifyApiRequest(providerName, reasonCd, selMbrUid, futurePcpIdObj);
              resp1 = await this._pcpSvc.modifyPCP(req1);
              resolve(resp1);
              break;
            case ACTIVE_COVERAGE:
              req2 = this.getModifyApiRequest(providerName, reasonCd, selMbrUid, activePcpIdObj);
              resp2 = await this._pcpSvc.modifyPCP(req2);
              resolve(resp2);
              break;
          }
        } catch (reason) {
          reject(reason);
        }
      })();
    });
    return resultPromise;
  }

  private getModifyApiRequest(providerName: string, pcpTrmntnRsnCd: string, selMbrUid: string, pcpIdObj: IPcpIdItem): IPcpRequest {
    const request = {
      providerName,
      contractUid: pcpIdObj.contractUid,
      multi_updates: pcpIdObj.multi_updates,
      pcpInfo: {
        pcpId: pcpIdObj.pcpId,
        pcpTrmntnRsnCd,
      },
      selMbrUid
    };
    return request;
  }
}
