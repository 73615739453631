import { IGeoLocationInRequest } from '../../../common/interfaces/iCommonSearchRequest';
import { IMedicalCodeCriteria } from '../../../common/interfaces/iCptMedicalRequest';

export interface IProviderCptOfficeVisitRequest {
  providerList: Array<IProviders>;
  medicalCodeCriteria: IMedicalCodeCriteria;
  memberCriteria: IMemberContractInRequest;
  isFuturePlan: boolean;
  location: IGeoLocationInRequest;
  locale: string;
}

export interface IProviders {
  providerKey: string;
  addrKey: string;
  bcbsa5PartKey?: string;
  pcpIndicator?: string;
}

export interface IMemberContractInRequest  {
  contractUid?: string;
  mbrUid?: string;
}

export enum PCP_INDICATOR {
  PCP = 'P',
  SPECIALIST = 'S'
}
