import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../../findcare/common/components/core/baseCmp';
import { IMachineReadable, IUrl } from './../../../../common/components/machine-readables/interfaces/iMachineReadable';

@Component({
  moduleId: module.id,
  selector: 'app-fc-machine-readable',
  templateUrl: '/machineReadableCmp.html'
})
export class MachineReadableComponent extends BaseComponent {
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  get machineReadable(): IMachineReadable {
    return this.content?.landing?.machineReadable;
  }

  get brand(): IUrl {
    return this.content?.landing?.machineReadable?.brand[(this._appSession.metaData.brandCd || '').toLowerCase()];
  }

  onMachineReadableClick(event: Event): void {
    event?.stopPropagation();
    return;
  }
}
