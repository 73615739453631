import { Inject, Injectable } from '@angular/core';
import {
  IAppConfig,
  IBaseUrl,
  IBingMaps,
  IDatadog,
  IFeatures,
  IImages,
  ILogin,
  IMedicaidState,
  IPaginationSize,
  IProviderFinder,
  IRecaptcha,
  IRegister,
  ISso,
  IWcsApi,
  IWeb
} from '../../../app/findcare/common/interfaces/iAppConfig';
import { FcConfig } from '../constants/fc-config';
import { APP_CONFIG, ENV_REPLACER, PROD_ENVS } from '../enums/appInitializerEnum';
import { AppSession } from './appSession';
/**
 *  App configuration settings
 */
@Injectable()
export class AppConfig implements IAppConfig {
  production = undefined as unknown as boolean;
  baseUrl = undefined as unknown as IBaseUrl;
  web = undefined as unknown as IWeb;
  sso = undefined as unknown as ISso;
  wcsApi = undefined as unknown as IWcsApi;
  bingMaps = undefined as unknown as IBingMaps;
  recaptcha = undefined as unknown as IRecaptcha;
  providerFinder = undefined as unknown as IProviderFinder;
  images = undefined as unknown as IImages;
  cvsPharmacy = undefined as unknown as Record<string, string>;
  esiPharmacy = undefined as unknown as Record<string, string>;
  paginationSize = undefined as unknown as IPaginationSize;
  chineseMotionPointUrl = undefined as unknown as Record<string, string>;
  features = undefined as unknown as IFeatures;
  login = undefined as unknown as ILogin;
  register = undefined as unknown as IRegister;
  MedicaidState = undefined as unknown as IMedicaidState;
  datadog = undefined as unknown as IDatadog;
  lookupResultSortOrder = undefined as unknown as string[];
  environment = undefined as unknown as string;

  constructor(@Inject(AppSession) private appSession?: AppSession) {}

  // assigning all appConfig properties to this class variables
  preapreAppConfig(config: any) {
    const _fcConfig = FcConfig.CONFIG;
    let envInUrl = '';
    if (config) {
      const curEnv = config.environment;
      this.production = PROD_ENVS.includes(curEnv.toLocaleLowerCase());
      if (!this.production) envInUrl = '.' + curEnv;
      this.baseUrl = config.baseUrl;
      this.web = config.web ?? _fcConfig.web;
      this.sso = config.sso ?? _fcConfig.sso;
      this.wcsApi = config.wcsApi ?? _fcConfig.wcsApi;
      this.bingMaps = config.bingMaps ?? _fcConfig.bingMaps;
      this.recaptcha = config.recaptcha ?? _fcConfig.recaptcha;

      const publicBaseUrl = config.providerFinder?.baseUrl ?? _fcConfig.providerFinder.baseUrl;
      this.providerFinder = {
        // FcConfig.getPFBrandUrls() is a helper function to get the public & secure urls based on the environment - prod or non-prod
        baseUrl: FcConfig.getPFBrandUrls(publicBaseUrl, envInUrl),
        vitalsBase: config.providerFinder?.vitalBase ?? _fcConfig.providerFinder.vitalsBase,
        authToken: config.providerFinder?.authToken ?? _fcConfig.providerFinder.authToken
      };

      this.images = {
        qualityBaseUrl: config.images?.qualityBaseUrl ?? _fcConfig.images.qualityBaseUrl,
        commonBaseUrl: config.images?.commonBaseUrl ?? _fcConfig.images.commonBaseUrl,
        brandBaseUrl: config.images?.brandBaseUrl ?? _fcConfig.images.brandBaseUrl,
        semBaseUrl: config.images?.semBaseUrl ?? _fcConfig.images.semBaseUrl.replace(ENV_REPLACER, envInUrl)
      };
      // FcConfig.getEnvUrls() is a helper function to get the urls based on the environment - prod or non-prod
      this.cvsPharmacy = FcConfig.getEnvUrls(config.cvsPharmacy ?? _fcConfig.cvsPharmacy, envInUrl);
      this.esiPharmacy = FcConfig.getEnvUrls(config.esiPharmacy ?? _fcConfig.esiPharmacy, envInUrl);
      this.paginationSize = config.paginationSize ?? _fcConfig.paginationSize;
      this.chineseMotionPointUrl = config.chineseMotionPointUrl ?? _fcConfig.chineseMotionPointUrl;
      this.features = config.features ?? _fcConfig.features;
      this.login = config.login;
      this.register = config.register;
      this.MedicaidState = config.MedicaidState ?? _fcConfig.MedicaidState;
      this.datadog = config.datadog ?? _fcConfig.datadog;
      // override 'rumConfiguration.env' value to current env if accessing from FcConfig.datadog
      if (!config.datadog && this.datadog.rumConfiguration) {
        this.datadog.rumConfiguration['env'] = curEnv;
      }
      this.lookupResultSortOrder = config.lookupResultSortOrder ?? _fcConfig.lookupResultSortOrder;
      this.environment = curEnv;

      // save appConfig data in appSession
      if (this.appSession) {
        this.appSession.appConfig = this.getAppConfig();
      }
    }
  }

  loadConfigService(): void {
    this.preapreAppConfig(window[APP_CONFIG]);
  }

  // get all appConfig properties in a single object
  private getAppConfig(): any {
    return {
      production: this.production,
      baseUrl: this.baseUrl,
      web: this.web,
      sso: this.sso,
      wcsApi: this.wcsApi,
      bingMaps: this.bingMaps,
      recaptcha: this.recaptcha,
      providerFinder: this.providerFinder,
      images: this.images,
      cvsPharmacy: this.cvsPharmacy,
      esiPharmacy: this.esiPharmacy,
      paginationSize: this.paginationSize,
      chineseMotionPointUrl: this.chineseMotionPointUrl,
      features: this.features,
      login: this.login,
      register: this.register,
      MedicaidState: this.MedicaidState,
      datadog: this.datadog,
      lookupResultSortOrder: this.lookupResultSortOrder,
      environment: this.environment
    };
  }
}
