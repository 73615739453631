export class CustomizedFeatures {
  public emulateMode: boolean;
  public showChangePlan: boolean;
  public showAssignPCPBtn: boolean;
  public showAssignPCPPanel: boolean;
  public showSelectProvider: boolean;
  public showSearchResultsBackBtn: boolean;
  public showAdddCareTeam: boolean;
  public showScheduleAppointment: boolean;
  public showInitialVisitCostMsg: boolean;
  public showKHealthFeatures: boolean;
  public showVirtualCare: boolean;
  public showMoreOptions: boolean;
  public showGetDirections: boolean;
  public showAddReviewButton: boolean;

  constructor() {
    this.emulateMode = false;
    this.showChangePlan = true;
    this.showAssignPCPBtn = false;
    this.showAssignPCPPanel = true;
    this.showSelectProvider = false;
    this.showSearchResultsBackBtn = true;
    this.showAdddCareTeam = true;
    this.showScheduleAppointment = true;
    this.showInitialVisitCostMsg = false;
    this.showKHealthFeatures = false;
    this.showVirtualCare = true;
    this.showMoreOptions = true;
    this.showGetDirections = true;
    this.showAddReviewButton = true;
  }
}
