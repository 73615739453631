import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-virtual-care-label',
  templateUrl: './virtualCareLabel.html'
})

export class VirtualCareLabelComponent {
  @Input() label: string;
}
