import { ElementRef } from '@angular/core';

export enum Type {
  Clear = 'Clear',
  Apply = 'Apply',
  None = 'None'
}

export class PillActions {
  /** Reference to the chip that emitted the event. */
  private _source: ElementRef;
  /** Whether the chip that emitted the event is selected. */
  private _selected: boolean;
  /** Whether the selection change was a result of a user interaction. */
  private _isUserInput = false;

  constructor(source: ElementRef, selected: boolean, isUserInput = false) {
    this._source = source;
    this._selected = selected;
    this._isUserInput = isUserInput;
  }

  get source(): ElementRef {
    return this._source;
  }

  get selected(): boolean {
    return this._selected;
  }

  get isUserInput(): boolean {
    return this._isUserInput;
  }

  get getType(): Type {
    return Type.None;
  }
}

export class ClearButton extends PillActions {
  get getType(): Type {
    return Type.Clear;
  }
}

export class ApplyButton extends PillActions {
  get getType(): Type {
    return Type.Apply;
  }
}
