import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { IMapRequest } from '../../../fad/search-results/interfaces/iMapRequest';
import { IProvider } from '../../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { RIGHT } from '../../constants/app-constants';
import { IDrivingModal } from '../../models/locationAddress';
import { DataHelper } from '../../services/dataHelper';
import { EventHandler } from '../../services/eventHandler';
import { NavigationService } from '../../services/navigationService';
import { SliderService } from '../../services/sliderSvc';
import { AppUtility } from '../../utilities/appUtil';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { PFMapComponent } from './pfMapCmp';

@Component({
  moduleId: module.id,
  selector: 'app-driving-direction-slide-out-cmp',
  templateUrl: './drivingDirectionSlideOutCmp.html'
})
export class DrivingDirectionSlideOutComponent extends BaseComponent implements OnDestroy {
  @ViewChild('viewDrivingDirectionsSlider')
  viewDrivingDirectionsSlider: TemplateRef<HTMLElement>;
  @ViewChild('viewProvidersDirectionsMapCmp')
  viewProvidersDirectionsMapCmp: PFMapComponent;
  provider: IProvider;
  index: number;
  sidePanelRef: ModalRef<HTMLElement>;
  startLocation = '';
  endLocation = '';
  showInputContent = false;
  directionsMapData: IMapRequest[] = [];
  isShortestTime = true;
  directionMapError = false;
  showLocationsError = false;
  radioShortestTime: IDrivingModal = {
    label: this.content?.mapModal?.shortestTime,
    id: 'compareShortTime',
    name: 'compareDistance',
    value: true
  };
  radioShortestDistance: IDrivingModal = {
    label: this.content?.mapModal?.shortestDistance,
    id: 'compareShortdistance',
    name: 'compareDistance',
    value: false,
  };
  private _sliderSubscription: Subscription;

  constructor(
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _providerUtilityService: ProviderUtilityService,
    private _appUtility: AppUtility,
    private _dataHelper: DataHelper,
    private _navigationService: NavigationService,
    private _sliderService: SliderService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');

    this._sliderSubscription = this._sliderService.onSlideOutOpen.subscribe((provider) => this.openSlider(provider));
  }

  ngOnDestroy(): void {
    if (typeof this._sliderSubscription !== 'undefined' && this._sliderSubscription !== null) {
      this._sliderSubscription.unsubscribe();
    }
  }

  openSlider(data: any): void {
    this.provider = data;
    this.index = data.actualIndex;
    const mapRequest = CommonUtil.buildMapRequest(this.provider, 'E', null, this.showSPFI);
    this.directionsMapData.push(mapRequest);
    this.endLocation = mapRequest.title;
    this.viewProvidersDirectionsMapCmp?.clearDirections();
    this.viewProvidersDirectionsMapCmp?.setMap(this.directionsMapData);
    this.showInputContent = true;
    if (document.getElementsByClassName('overlay-container') && document.getElementsByClassName('overlay-container').length) {
      document.getElementsByClassName('overlay-container')[0].innerHTML = '';
    }
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.viewDrivingDirectionsSlider);
  }

  closeSlider(): void {
    this.startLocation = '';
    this.directionsMapData = [];
    this.directionMapError = false;
    this.sidePanelRef?.close();
  }

  useYourLocation(): void {
    if (this._appSession?.defaultLocation?.formattedAddress) {
      this.startLocation = this._appSession.defaultLocation.formattedAddress;
    } else if (!this._appSession?.defaultLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => this.onGeoLocationSuccess(position));
    }
  }

  async onGeoLocationSuccess(position: GeolocationPosition): Promise<void> {
    if (position?.coords) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      if (!isNaN(Number(latitude)) && !isNaN(Number(longitude))) {
        const address = await this._providerUtilityService.getAddressByLatLong(latitude.toString(), longitude.toString());
        if (!this._dataHelper.isEmptyString(address?.zipcode)) {
          const zipCode = address.zipcode;
          const formattedAddress = address.formattedAddress;
          const geoLocation = await this._providerUtilityService.getGeoLocation(zipCode);
          if (geoLocation && Object.keys(geoLocation).length > 0) {
            const results = this._appUtility.buildLocationResponse(geoLocation);
            this._appSession.defaultLocation = {
              zip: zipCode,
              state: results[Object.keys(results)[0]].stateCd,
              latitude: results[Object.keys(results)[0]].lat,
              longitude: results[Object.keys(results)[0]].long,
              formattedAddress: formattedAddress
            };
            this.startLocation = formattedAddress;
          }
        }
      }
    }
  }

  setDirectionMap(): void {
    if (!this._dataHelper.isEmptyString(this.startLocation) && !this._dataHelper.isEmptyString(this.endLocation)) {
      this.directionMapError = false;
      this.showInputContent = false;
      const directionAttributes = {
        startLocation: this.startLocation,
        endLocation: this.endLocation,
        isShortestTimeSelected: this.isShortestTime,
        isShortestDistanceSelected: !this.isShortestTime
      };
      this.viewProvidersDirectionsMapCmp?.clearDirections();
      this.viewProvidersDirectionsMapCmp?.createDirections(directionAttributes);
    } else {
      this.directionMapError = true;
    }
  }

  hideMapDirection() {
    this.viewProvidersDirectionsMapCmp?.clearDirections();
    this.showInputContent = true;
  }

  getDirectionActionId(): string {
    try {
      if (!this._dataHelper.isEmptyString(this.startLocation) && !this._dataHelper.isEmptyString(this.endLocation) && this._contentHelper.isSearchResultRoute(this._navigationService.currentRoute)) {
        return this.userInteractionUtil.getSearchResultPageActionId(this.provider, this.index, this.userInteraction.providerSummaryPage.getDirectionsLink.actionTaken);
      }
      return null;
    } catch (error) {
      return '';
    }
  }

  print(): void {
    window.print();
  }
}
