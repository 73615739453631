import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { UxSharedModule } from "@anthem/uxd-shared";
import { CustomPipesModule } from "../../pipes/customPipesModule";
import { LeavingDisclaimerModule } from "../leaving-disclaimer/leavingDisclaimerModule";
import { MapModule } from "../map/pfMapModule";
import { CardComponent } from './components/cardCmp';
import { CardsHListComponent } from './components/cardsHListCmp';
import { CardsVListComponent } from './components/cardsVListCmp';
import { OnSiteClinicSliderComponent } from "./components/onSiteClinicSliderCmp";

@NgModule({
  declarations: [CardComponent, CardsHListComponent, CardsVListComponent, OnSiteClinicSliderComponent],
  exports: [CardComponent, CardsHListComponent, CardsVListComponent, OnSiteClinicSliderComponent],
  imports: [
    UxSharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    LeavingDisclaimerModule,
    MapModule,
    CustomPipesModule
  ],
  providers: []
})
export class CardsModule { }
