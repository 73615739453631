import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IProviderCptCodesRequest } from '../models/iProviderCptCodesRequest';
import { IProviderCptCodesResponse } from '../models/iProviderCptCodesResponse';
import { HttpMethod } from './../../../../app/common/enums/httpMethodEnum';
import { IUrlParam } from './../../../../app/common/interfaces/iUrlParam';
import { apiNameList } from './../../../../environments/api-name-list';
import { BaseService } from './../../../common/services/baseService';

@Injectable()
export class ProviderCptCodesService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  getProviderCptCodes(reqObj: IProviderCptCodesRequest, locale: string): Promise<IProviderCptCodesResponse> {
    const urlParams: IUrlParam[] = [{ name: 'locale', value: locale, isQueryParam: true }];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerCptCodes,
      data: reqObj,
      headers: this.getHeaders(),
      urlParams
    });
  }
}
