import { Component, TemplateRef, ViewChild } from "@angular/core";
import { ModalRef, SidePanel } from "@anthem/uxd/modal";
import { ISlideoutMessage } from "../../interfaces/iSlideoutMsg";

/**
 * Use this component to display an alert in a Panel overlay of any page
 */
@Component({
  moduleId: module.id,
  selector: 'app-common-slideout-msg-cmp',
  templateUrl: './slideoutMsgCmp.html'
})
export class SlideoutMessageComponent {
  title: string = '';
  close: string = '';
  message: string = '';
  modalRef: ModalRef<HTMLElement, any>;
  @ViewChild('AlertPanelTemplate')
  alertPanelTmpltRef: TemplateRef<HTMLElement>;

  constructor(private _sidePanel: SidePanel) {
  }

  showMessage(msg: ISlideoutMessage) {
    this.title = msg.title;
    this.close = msg.close;
    this.message = msg.message;
    this.modalRef = this._sidePanel.open('right', this.alertPanelTmpltRef);
  }

  onClose() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
