import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppSession } from '../../../common/values/appSession';
import { BootstrapHandler } from '../classes/bootstrapHandler';
import { IAppContract } from '../interfaces/iAppContract';
import { IBootstrapResponse } from '../interfaces/iBootstrap';
import { IContract } from '../interfaces/iContract';
import { IMember } from '../interfaces/iMember';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  public members: IMember[] = [];
  public contracts: IContract[] = [];
  public appContract: IAppContract | undefined = undefined;
  private bootstrapSubject = new BehaviorSubject<IBootstrapResponse | undefined>(undefined);
  private appContractSubject = new BehaviorSubject<IAppContract | undefined>(this.appContract);
  private eligibleMemberSubject = new BehaviorSubject<string>(undefined);

  contract = this.appContractSubject.asObservable();
  array$ = this.eligibleMemberSubject.asObservable();

  constructor(
    private bootstrapHandler: BootstrapHandler,
    private appSession: AppSession
  ) {}

  /**
   * Initializes the bootstrap service by fetching and setting the bootstrap data.
   */
  async initialize(): Promise<void> {
    const bootstrap = await this.bootstrapHandler.getBootstrap();
    this.setBootstrap(bootstrap);
  }

  /**
   * Sets the selected member contract based on the provided member UID, contract UID, and status code.
   * If a matching contract is found, it updates the appContract with the contract.
   * If no matching contract is found, it does nothing.
   * @param mbrUid - The member UID to match.
   * @param contractUid - The contract UID to match (optional).
   * @param statusCd - The status code to match (optional).
   */
  setAppContract(mbrUid: string, contractUid?: string, statusCd?: string): void {
    if (isEmpty(mbrUid)) {
      return;
    }

    const contracts = this.bootstrapSubject.getValue()?.contracts || [];
    let _selectedContract: IContract | undefined;

    // Find the contract based on the provided parameters
    if (!isEmpty(contractUid) && !isEmpty(statusCd)) {
      _selectedContract = contracts.find((contract) => contract.mbrUid === mbrUid && contract.contractUid === contractUid && contract.statusCd === statusCd);
    } else if (!isEmpty(contractUid)) {
      _selectedContract = contracts.find((contract) => contract.mbrUid === mbrUid && contract.contractUid === contractUid);
    } else {
      _selectedContract = contracts.find((contract) => contract.mbrUid === mbrUid);
    }

    if (!_selectedContract) {
      return;
    }

    // Get the bootstrap flags and contract product flags, defaulting to empty arrays if undefined
    const bootstrapFlags = this.appSession.bootstrap?.flags || [];
    const contractProductFlags = _selectedContract.productFlags || [];
    // Combine the bootstrap flags and contract product flags, ensuring uniqueness using a Set
    const uniqueAppFlags = new Set([...bootstrapFlags, ...contractProductFlags]);
    this.appSession.appFlags = Array.from(uniqueAppFlags);

    // Create the app contract object
    const _appContract: IAppContract = {
      members: this.members,
      selectedContract: _selectedContract,
      contracts: this.contracts
    };

    // Update the app contract and notify subscribers
    this.appContract = _appContract;
    this.appContractSubject.next(_appContract);
  }

  /**
   * Sets the bootstrap data and updates the app session.
   * @param bootstrap The bootstrap response object.
   */
  private setBootstrap(bootstrap: IBootstrapResponse | null): void {
    if (bootstrap) {
      // Assign the bootstrap response to the app session
      this.appSession.bootstrap = bootstrap;
      this.appSession.appFlags = bootstrap.flags;
      this.bootstrapSubject.next(bootstrap);

      if (bootstrap.contracts) {
        this.setMembers(bootstrap.contracts);
      }
    }
  }

  /**
   * Updates the members list based on the provided contracts.
   * @param contracts The array of bootstrap contracts.
   */
  private setMembers(contracts: IContract[] | null): void {
    if (!contracts) {
      return;
    }

    const memberMap = new Map<string, IMember>();

    contracts.forEach((contract) => {
      this.addMemberToMap(memberMap, contract);
    });

    this.members = Array.from(memberMap.values());
    contracts.forEach((contract) => this.updateAssociatedMembers(contract));
    this.contracts = Array.from(new Map(contracts.map((contract) => [`${contract.contractUid}-${contract.statusCd}`, { ...contract }])).values());
  }

  /**
   * Updates the associated members for a given contract.
   * @param contract The contract to update associated members for.
   */
  private updateAssociatedMembers(contract: IContract): void {
    const memberMap = new Map<string, IMember>();
    const contracts = this.bootstrapSubject.getValue()?.contracts?.filter((c) => c.contractUid === contract.contractUid) || [];

    contracts.forEach((c) => {
      this.addMemberToMap(memberMap, c);
    });

    contract.associatedMembers = Array.from(memberMap.values());
  }

  /**
   * Enables eligible members only for the member chips
   * @param newArray
   */
  enableEligibleMembers(newArray: string) {
    this.eligibleMemberSubject.next(newArray);
  }

  /**
   * Adds a member to the member map if it doesn't already exist.
   * @param memberMap The map to add the member to.
   * @param contract The contract containing the member information.
   */
  private addMemberToMap(memberMap: Map<string, IMember>, contract: IContract): void {
    if (!memberMap.has(contract.mbrUid)) {
      memberMap.set(contract.mbrUid, {
        mbrUid: contract.mbrUid,
        firstNm: contract.firstNm,
        lastNm: contract.lastNm,
        dob: contract.dob,
        relationshipCd: contract.relationshipCd,
        genderCode: contract.genderCode,
        yearOfBirth: contract.yearOfBirth
      });
    }
  }
}
