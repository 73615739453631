import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppEvents } from './../../enums/appEvents';
import { DataService } from './../../services/dataService';
import { EventHandler } from './../../services/eventHandler';

@Component({
  selector: 'app-global-header',
  templateUrl: './globalHeader.html'
})
export class GlobalHeaderComponent implements AfterViewInit {
  pageTitle: string = '';
  subTitle: string = '';
  private _subscription: any;
  private _headerEvent: EventEmitter<string> = this._eventHandler.get(
    AppEvents[AppEvents.APP_HEADER]
  );

  constructor(
    private _eventHandler: EventHandler,
    private location: Location,
    private dataService: DataService,
    private titleService: Title
  ) {}

  ngAfterViewInit() {
    this.dataService.pageTitle.subscribe((res) => {
      this.subTitle = res;
    });
    this._subscription = this._headerEvent.subscribe((obj: any) => {
      setTimeout(() => {
        if (obj && obj.header) {
          this.pageTitle = obj.header;
          const temp = this.location.path().replace('/', '');
          this.subTitle = temp.replace('-', ' ');
        }
      }, 125);
      this.titleService.setTitle(obj.title);
    });
  }

}
