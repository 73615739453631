import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MODAL_TYPE } from '../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../../common/constants/strategy-search-constant';
import { IAdobeSearchDetail } from '../../../../common/interfaces/iAdobe';
import { IUserInteraction } from '../../../../common/interfaces/iUserInteraction';
import { EventHandler } from '../../../../common/services/eventHandler';
import { NavigationService } from '../../../../common/services/navigationService';
import { SearchSvc } from '../../../../common/services/searchSvc';
import { AppSession } from '../../../../common/values/appSession';
import { ContentHelper } from '../../../../common/values/contentHelper';
import { IRulesResponse } from '../../../../fad/rules/interfaces/iRulesResponse';
import { RulesService } from '../../../../fad/rules/services/RulesSvc';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { QuickLinksService } from '../../../../fad/search-providers/services/quickLinksSvc';
import { EycProcedureCriteria, EycSearchParam } from '../../../../fad/search-results/interfaces/iSearchRequest';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from '../../../common/constants/common';
import { IAppContract } from '../../../common/interfaces/iAppContract';
import { BootstrapService } from '../../../common/store/bootstrapSvc';
import { PROCEDURE_VIEW_MORE_LIMIT, QuickLinkType } from '../../constants/landing';
import { IProcedure } from '../../interfaces/iProcedure';
import { IProviderType } from '../../interfaces/iProviderType';
import { IQuickLinks } from './../../../../fad/search-providers/interfaces/iQuickLinks';
import { IContract } from './../../../common/interfaces/iContract';

@Component({
  moduleId: module.id,
  selector: 'app-fc-quicklink-cmp',
  templateUrl: './quicklinkCmp.html'
})
export class QuicklinkComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() analytics: EventEmitter<IAdobeSearchDetail> = new EventEmitter<IAdobeSearchDetail>();
  @Output() leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  @Output() modal: EventEmitter<string> = new EventEmitter<string>();

  personalizedIndicator: number;
  maxProceduresInView = PROCEDURE_VIEW_MORE_LIMIT;
  searchParams: ISearchParameters;
  providerTypes: IProviderType[] = [];
  procedures: IProcedure[] = [];
  hasEYC = false;
  showLoader = false;
  quickLinkContent = this.content?.landing?.quickLinks;
  selectedMemberContract: IContract;
  searchCriteria: ISearchParameters;

  private _contractSubscription: Subscription;

  constructor(
    private searchService: SearchSvc,
    @Inject(AppSession) private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _quickLinksService: QuickLinksService,
    private _navigationService: NavigationService,
    private _bootstrapService: BootstrapService,
    private _ruleService: RulesService,
    @Inject(ContentHelper) private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Initializes the component and subscribes to contract changes.
   * If `waitUntilAppReload` is true, the method returns early.
   * Otherwise, it subscribes to the contract observable and updates the component state accordingly.
   */
  ngOnInit(): void {
    if (this.waitUntilAppReload) {
      return;
    }

    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract) => {
      if (!this.selectedMemberContract || this.selectedMemberContract?.contractUid !== appContract?.selectedContract?.contractUid) {
        this.selectedMemberContract = appContract?.selectedContract;
        if (this._appSession) {
          this.hasEYC = this.isSecureState && (this._appSession.hasEYC || this._appSession.hasDentalEYC);
        }
        this.searchParams = this._appSession.searchParams;
        this.getQuickLinks();
      } else {
        this.selectedMemberContract = appContract?.selectedContract;
        this.searchParams = this._appSession.searchParams;
        this.getQuickLinks();
      }
    });
  }

  /**
   * Cleans up the component by unsubscribing from the contract observable.
   * This method is called when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this._contractSubscription) {
      this._contractSubscription.unsubscribe();
    }
  }

  /**
   * Fetches the quick links response and updates the component state.
   */
  getQuickLinks() {
    this.showLoader = true;
    this._appSession.metaData.appContract.mbrUid = this.selectedMemberContract?.mbrUid;
    this._quickLinksService.getQuickLinksV3List(CommonUtil.buildRuleRequest(this.searchParams, this._appSession))?.then((result: IQuickLinks) => {
      this.showLoader = false;
      this.personalizedIndicator = result.personalizedIndicator;
      this.providerTypes = result.providers;
      this.procedures = result.procedures;
    });
  }

  /**
   * Generates a feedback event interaction object for analytics.
   * @param quickLink The quick link object.
   * @param quickLinkType The type of the quick link.
   * @returns The user interaction object.
   */
  getFeedbackEventInteraction(quickLink: { rankPercentile?: number; modelId?: string; name?: string }, quickLinkType: QuickLinkType): IUserInteraction {
    return {
      eventType: quickLinkType === QuickLinkType.PROVIDER ? this.userInteraction?.landingPage?.providerQuickLink?.eventType : this.userInteraction?.landingPage?.procedureQuickLink?.eventType,
      actionId: quickLink?.modelId || quickLink?.name,
      personalizedIndicator: this.personalizedIndicator,
      rankPercentile: quickLink?.rankPercentile
    };
  }

  /**
   * Generates an ARIA label for a provider or procedure.
   * @param providerName The name of the provider.
   * @param type The type of the quick link.
   * @returns The ARIA label.
   */
  getAriaLabelForProviderAndProced(providerName: string, type: string): string {
    return type?.replace(/{provider_name}/gi, providerName) ?? '';
  }

  /**
   * Navigates to the promoted procedures page.
   */
  viewMoreProcedure() {
    this._navigationService.navigateByUrl(AppNavigations.PROMOTED_PROCEDURES_PATH);
  }

  /**
   * Sets analytics data and emits the analytics event.
   * @param name The name of the procedure or provider type.
   * @param eyc Indicates if the event is for EYC.
   */
  setAnalytics(name: string, eyc: boolean) {
    const searchDetails: IAdobeSearchDetail = {
      procedureName: eyc ? name : undefined,
      provType: eyc ? undefined : name,
      zip: this.searchParams?.zip
    };
    this.analytics.emit(searchDetails);
  }

  /**
   * Resets the application session parameters.
   * @param eyc Indicates if the reset is for EYC.
   */
  resetAppSessionParams(eyc: boolean = false) {
    this.searchParams.provNm = '';
    Object.assign(this._appSession, {
      filterSearchParams: undefined,
      searchParams: { ...this.searchParams, distanceNm: PROVIDER_SEARCH_DISTANCE },
      initialSearch: true,
      integratedSearch: true,
      isEyc: eyc,
      isMedicalCodeSearch: false,
      billingType: undefined,
      medicalCode: undefined,
      medicalModifierOptionCode: undefined,
      medicalPosCode: undefined
    });
  }

  /**
   * Checks location coordinates in appSession defaultLocation.
   * @returns boolean
   */
  isLocationValid() {
    let _isValid = true;
    if (
      !(
        this._appSession.searchParams.coordinates &&
        this._appSession.searchParams.coordinates.latitude !== '' &&
        this._appSession.searchParams.coordinates.longitude !== '' &&
        this._appSession.searchParams.zip !== ''
      )
    ) {
      _isValid = false;
    }
    return _isValid;
  }

  /**
   * Checks if navigating outside of anthem site.
   * @param providerTypeCode
   * @returns boolean based on provider type vendorUrl
   */
  isLeavingAnthem(providerTypeCode: string): boolean {
    const provType: IProviderType = (this._appSession.providerTypes || []).find((p) => {
      return p.code.toUpperCase() === providerTypeCode.toUpperCase();
    });
    if (provType?.vendorUrl) {
      this.leavingDisclaimer.emit(provType.vendorUrl);
      return true;
    }
    return false;
  }

  /**
   * Initiates a search by provider type.
   * @param providerType The provider type to search by.
   */
  searchByProviderType(providerType: IProviderType) {
    if (!this.isLeavingAnthem(providerType.code) && this.isLocationValid() && providerType) {
      Object.assign(this.searchParams, {
        ...this._appSession.searchParams,
        typeSelectNm: providerType.code,
        coverageTypeCode: '',
        specialtySelectNm: providerType.specialtyCategoryList,
        taxonomySelectNm: providerType.taxonomyList,
        providerTypeCodeList: [],
        ableToServePcp: providerType.ableToServePcp
      });
      this.searchService.currentScenario = this.isSecureState ? CommonUtil.getSecureSpecialtyScenario([providerType.code] as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      Object.assign(this._appSession, {
        searchTerm: '',
        isSearchTerm: false
      });
      this.searchParams = CommonUtil.buildStandAlone(this.searchParams, this._appSession);

      if (this.searchCriteria?.typeSelectNm) {
        this.searchParams?.providerTypeCodeList.push(this.searchCriteria.typeSelectNm);
      }
      // Medsupp scenario
      if (this._appSession.planRule && this._appSession?.planRule?.isMedicareGov) {
        this.searchParams.standAloneVision = true;
      }
      this.searchCriteria = this.searchParams;

      this.setAnalytics(providerType.name, false);
      this.resetAppSessionParams();

      //Set Flag for Pre Filtering BCP filter
      const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
      let isSearchCriteriaPath: boolean = false;
      if (_routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH) {
        isSearchCriteriaPath = true;
      }

      this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
        (result: IRulesResponse) => {
          const _result = result;
          if (_result) {
            if (_result.showMedicareSupplement) {
              this.modal.emit(MODAL_TYPE.MEDICARE_SUPPLEMENT);
            } else {
              this._appSession.feature = _result;
              if (this._appSession && isSearchCriteriaPath && _result.showBCP && _result.showPreFilterBCP && this._appSession.searchParams) {
                this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
              }
              this._navigationService.navigateByUrl(AppNavigations.FCR_RESULT_PATH);
            }
          }
        },
        (error: any) => {
          throw error;
        }
      );
    }
    if (this.isMemberSecure && !this._appSession.isEmulation) {
      this.emitLiveChatEvent();
    }
  }

  /**
   * Initiates a search by procedure.
   * @param procedure The procedure to search by.
   */
  searchByProcedure(procedure: IProcedure) {
    const eycCriteria = new EycProcedureCriteria();
    eycCriteria.procedureCode = procedure.procedureCode && procedure.procedureCode.code ? procedure.procedureCode.code : '';
    eycCriteria.providerCategoryCode = procedure.providerCategoryCode?.code;
    eycCriteria.procedureName = procedure.procedureCode?.name;

    const eycSearchParam = new EycSearchParam();
    eycSearchParam.procedureDescription = procedure.procedureCode && procedure.procedureCode.description ? procedure.procedureCode.description : '';
    eycSearchParam.costDescription = procedure.costExplainationText ? procedure.costExplainationText : '';
    eycSearchParam.eycProcedureCriteria = eycCriteria;

    this.searchParams = {
      ...this._appSession.searchParams,
      typeSelectNm: '',
      coverageTypeCode: '',
      specialtySelectNm: [],
      taxonomySelectNm: [],
      providerTypeCodeList: [],
      brandCode: this._appSession.metaData.brandCd,
      selectedMem: '',
      eycSearchParam: eycSearchParam
    };

    this.searchService.currentScenario = this.isSecureState ? CommonUtil.getSecureProcedureScenario(procedure?.providerCategoryCode?.code as PROVIDER_TYPE) : SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL;

    const appContract = this.selectedMemberContract;
    if (appContract?.mbrUid) {
      this.searchParams.selectedMem = `${appContract.firstNm} ${appContract.lastNm}`;
    }

    this.setAnalytics(procedure.name, true);
    this.resetAppSessionParams(true);
    this._navigationService.navigateByUrl(AppNavigations.FCR_RESULT_PATH);
  }
}
