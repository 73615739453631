import { Component, ElementRef, Inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { INFOBOX_EVENTS } from './../../../enums/infoBox';
import { EventHandler } from './../../../services/eventHandler';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';
import { BaseComponent } from './../../base-component/baseCmp';

/**
*  This component is used to load the ssoLinkDir.
*
*/

@Component({
  moduleId: module.id,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-fad-sso-link-cmp]',
  template: `
    <div *ngIf="showAddReview()">
      <!-- BEFORE ICON -->
      <span *ngIf="ssoContent?.iconBeforeClasses !== undefined && ssoContent?.iconBeforeClasses !== ''"
      [class]="ssoContent?.iconBeforeClasses"></span>

      <!-- LINK: IF NOT EMULATING -->
      <a
        #ssoLink
        data-fad-sso-link-v2-dir
        href="javascript:void(0)"
        [linkTarget]="ssoContent?.linkTarget"
        [ssoSubType]="ssoContent?.ssoSubType"
        [ssoErrorType]="ssoContent?.ssoErrorType"
        [ssoErrorClass]="ssoContent?.ssoErrorClass"
        [ssoErrorRedirectUrl]="ssoContent?.ssoErrorRedirectUrl"
        [ssoErrorPageHeader]="ssoContent?.ssoErrorPageHeader"
        [class]="ssoContent?.linkClasses"
        [innerHTML]="ssoContent?.linkText"
        [ssoType]="ssoContent?.ssoType"
        [errorMessage]="ssoContent?.errorMessage"
        [uniqueId]="ssoContent?.uniqueId"
        [contractUid]="contractUid ? contractUid : ssoContent?.contractUid"
        [newWindow]="ssoContent?.newWindow"
        [disclaimerEnabled]="ssoContent?.disclaimerEnabled"
        [disclaimerContent]="ssoContent?.disclaimerContent"
        [acctKey]="acctKey"
        [attr.data-analytics]="ssoContent?.analyticsText"
        [attr.aria-label]="ariaLabel"
        [flexAcctKey]="flexAcctKey"
        [acctOptions]="acctOptions"
        [acctTargetId]="acctTargetId"
        [pdtStrKey]="pdtStrKey"
        [baseURL]="baseURL"
        [id]="'fad-pf-sso-link-' + index"
        [careType]="ssoContent?.careType"
        [ssoKey]="ssoContent?.ssoKey"
      ></a>

      <!-- EXTERNAL ICON -->
      <img  *ngIf="ssoContent?.ssoKey" class="img" alt="" [src]="getCommonImagePath(ssoContent?.imageSource)" />

      <!-- AFTER ICON -->
      <span *ngIf="ssoContent?.iconAfterClasses !== undefined && ssoContent?.iconAfterClasses !== ''"
      [class]="ssoContent?.iconAfterClasses"></span>

      <!-- INLINE ERRORS -->
      <div [id]="'errorId' + ssoContent?.uniqueId" class="tcp-sso-link-error-inline ant-inline-alert hidden"></div>
    </div>
  `
})
export class SsoLinkComponent extends BaseComponent implements OnChanges {
  @Input()
  acctKey: any;
  @Input()
  contractUid: any;
  //@Output() acctKey: any;
  @Input()
  flexAcctKey: any;
  @Input()
  ariaLabel: any;
  @Input()
  acctOptions: any;
  @Input()
  acctTargetId: any;
  @Input()
  pdtStrKey: any;
  @Input()
  ssoContent: any;
  @Input()
  index: number = 0;
  @Input()
  eventKey: string = '';

  @Input()
  set config(value: any) {
    if (this.ssoContent !== value) {
      this.ssoContent = value;
    }
  }
  get config(): any {
    return this.ssoContent;
  }
  @ViewChild('ssoLink')
  ssoLink: ElementRef;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['eventKey'] !== 'undefined' &&
      changes['eventKey'] !== null &&
      typeof changes['eventKey'].currentValue !== 'undefined' &&
      changes['eventKey'].currentValue !== null &&
      changes['eventKey'].currentValue !== '') {

      const eventKey = changes['eventKey'].currentValue as string;

      this.handleEvents(eventKey);
    }
  }

  private handleEvents(eventKey: string) {
    switch (eventKey) {
      case INFOBOX_EVENTS.SSO_LINK:
        {
          this.ssoLink.nativeElement.click();
          break;
        }
      default:
        break;
    }
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }
}
