import { Location } from '@angular/common';
import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AppEvents } from '../../../../../common/enums/appEvents';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { IPageTitle } from '../../../interfaces/iPageTitle';

@Component({
  selector: 'app-fc-global-header',
  templateUrl: './globalHeader.html'
})
export class GlobalHeaderComponent implements OnDestroy {
  pageTitle = '';
  subTitle = '';
  private subscription: Subscription;
  private headerEvent: EventEmitter<IPageTitle> = this.eventHandler.get(AppEvents[AppEvents.APP_HEADER]);

  constructor(
    private eventHandler: EventHandler,
    private location: Location,
    private titleService: Title
  ) {
    this.subscription = this.headerEvent.subscribe((pageTitle: IPageTitle) => {
      setTimeout(() => {
        if (pageTitle && pageTitle.header) {
          this.pageTitle = pageTitle.header;
          this.subTitle = this.formatSubTitle(this.location.path());
        }
      }, 125);
      this.titleService.setTitle(pageTitle.title);
    });
  }

  /**
   * Formats the subtitle based on the current location path.
   * @param {string} path - The current location path.
   * @returns {string} The formatted subtitle.
   */
  private formatSubTitle(path: string): string {
    return path.replace('/', '').replace('-', ' ');
  }

  /**
   * Unsubscribes from the header event when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
