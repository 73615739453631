export enum ContentKey {
  COMMON = 'common',
  HOME = 'home',
  LANDING = 'landing',
  RESULT = 'result'
}

export enum ProviderFlag {
  EVENING_HOURS = 'eveningHours',
  WEEKEND_HOURS = 'weekendHours',
  IHSP = 'ihspIndc',
  REMOTE_PROVIDER = 'remoteProviderIndc',
  SSO = 'ssoIndc',
  HOME_HEALTH_CARE = 'homeHlthCareIndc',
  TELEHEALTH_PROVIDER = 'teleHealthProvider',
  DIRECTORY_PROVIDER = 'isDirectoryProvider',
  REGIONAL_PROVIDER = 'regionalProvInd',
  VIRTUAL_PROVIDER = 'virtualProvInd',
  MDCFLG = 'MDCFLG',
  NIMDCFLG = 'NIMDCFLG',
  ONLINE_PROVIDER = 'onlineProvInd',
  PRIME_GROUP = 'isPrimeGroup'
}

export enum PROVIDER_TYPE {
  HEARING = 'A',
  VISION = 'V',
  RETAIL_CLINIC = 'C',
  DOCTOR_PROFESSIONAL = 'P',
  MEDICAL_GROUP = 'G',
  HOSPITAL = 'H',
  BEHAVIORAL_HEALTH = 'B',
  DENTAL = 'D',
  URGENT_CARE = 'U',
  OTHER_MEDICAL = 'O',
  PHARMACY = 'R',
  MEDICAL_EQUIPMENT = 'M',
  OTHER_SERVICES_PROVIDER = 'CA-OSP',
  LAB = 'L'
}

export const PROVIDER_SEARCH_DISTANCE = '20';

export enum LOOKUP_TYPE {
  SPECIALITY = 'specialtyList',
  PROCEDURE = 'procedureList',
  PROCEDURE_LICENSE = 'providerLicenseList',
  AOE = 'aoeList',
  OFC_SERVICE = 'ofcSrvcList'
}

export enum CareTeamActionType {
  REMOVE_CARE_TEAM_MENU_FIND_CARE = 'removeCareTeamMenuFindCare',
  CHANGE_PCP_TEAM_MENU_FIND_CARE = 'changePcpTeamMenuFindCare',
  BOOK_APPT_TEAM_MENU_FIND_CARE = 'bookApptTeamMenuFindCare'
}

export enum ContractCoverageType {
  MED = 'Medical',
  PHAR = 'Pharmacy',
  DEN = 'Dental',
  VSN = 'Vision'
}

export enum ContractStatus {
  ACTIVE = 'A',
  FUTURE = 'F'
}
export interface IGeoCoordinate {
  longitude: string;
  latitude: string;
}

export enum LookupType {
  NAME = 'NM',
  SMART_SEARCH = 'SS',
  NPI = 'NPI',
  LICENSE_NUMBER = 'LN',
  AOE = 'AOE',
  OFFICE_SERVICES = 'OS',
  MEDICAL_CODE = 'CPT'
}

export enum SliderDirection {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export const VIEW_COUNT_LIMIT = 5;
export const MAX_PLAN_LENGTH = 22;
export const MAX_MOB_PLAN_LENGTH = 12;
export const MAX_MOB_RESPONSIVE_WIDTH_L = 481;
export const MAX_MOB_RESPONSIVE_WIDTH_M = 375;
export const MAX_MOB_RESPONSIVE_WIDTH_S = 320;
export const DOLLAR_SYMBOL = '$';

export enum ACCOUNT_TYPE {
  HIA = 'HIA',
  HRA = 'HRA',
  HSA = 'HSA'
}

export const BRANDCODE = {
  ABCBS: 'ABCBS',
  ABC: 'ABC'
};

export const CA_STATE = 'CA';

export enum PageAlertType {
  INFORMATION = 'information',
  WARNING = 'warning',
  NEGATIVE = 'negative'
}
