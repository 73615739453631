import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG, DEFAULT, ENV_REPLACER, LOCAL, LOCAL_ENV } from '../enums/appInitializerEnum';
import { AppConfig } from '../values/appConfig';
/**
 * This file is responsible for initializing the application settings before the application is bootstrapped.
 * It sets up global app request/response interceptors, application WCS URL hash with app bootstrap datetime,
 * and application template URL hash using build script hash value. It also calls the membership metadata API
 * before bootstrapping the application.
 */

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  // NOTE: Replace the below path with specific environment json for local testing - eg: config.local.json

  constructor(private http: HttpClient) {}

  async loadConfig(url: string): Promise<any> {
    return (await fetch(url)).json();
  }
}

@Injectable()
export class AppInitializer {
  constructor(
    private configService: ConfigService,
    private appConfig: AppConfig
  ) {}
  async init(): Promise<any> {
    if (!window[APP_CONFIG]) {
      window[APP_CONFIG] = await this.configService.loadConfig(this.urlBuilder());
    }

    // appConfig.loadConfigService() will load the app config from the window object and FcConfig service and combine them
    this.appConfig.loadConfigService();
    return Promise.resolve();
  }

  private urlBuilder() {
    let configUrl = 'assets/config/config.{ENV}.json';
    const config = configUrl.replace(ENV_REPLACER, window[LOCAL_ENV] ? (window[LOCAL_ENV].includes(LOCAL) ? LOCAL : window[LOCAL_ENV]) : DEFAULT);
    return config;
  }
}

export function appInitializerFactory(appInitializer: AppInitializer) {
  return () => appInitializer.init();
}
