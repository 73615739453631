import { Injectable } from '@angular/core';
import { DataHelper } from '../../common/services/dataHelper';
import { IAffiliation, IPCPInfo, IPCPInfoUIData } from '../interfaces/iPCPInfo';

@Injectable({ providedIn: 'root' })
export class PcpDetailsUtil {
  private _dataHelper: DataHelper;

  constructor(dataHelper: DataHelper) {
    this._dataHelper = dataHelper;
  }

  groupByPcpId(collection: Array<IPCPInfo>, id: string, subProps: Array<string>, partof: string): Array<IPCPInfoUIData> {
    const uniqueValueMap = this._dataHelper.groupByKey(collection, id);
    const finalList: IPCPInfoUIData[] = [];
    if (uniqueValueMap && uniqueValueMap.size > 0) {
      uniqueValueMap.forEach((values, key) => {
        const pcpInfoList = {
          id: key,
          affiliations: this._dataHelper.uniqueObjectBy(values.map((affiliation) => this._dataHelper.pick(this.setAffiliationContent(affiliation, partof), subProps)))
        };
        finalList.push(pcpInfoList);
      });
    }
    return finalList;
  }

  setAffiliationContent(affiliation: IAffiliation, partof: string): IAffiliation {
    if (!this._dataHelper.isEmptyString(affiliation?.affiliationName) && this._dataHelper.isEmptyString(affiliation?.affiliationGroup)) {
      affiliation.affiliationLabel = affiliation.affiliationName;
      return affiliation;
    } else if (this._dataHelper.isEmptyString(affiliation?.affiliationName)) {
      affiliation.affiliationLabel = '';
      return affiliation;
    } else {
      affiliation.affiliationLabel = affiliation?.affiliationName + partof + affiliation?.affiliationGroup;
      return affiliation;
    }
  }
}
