import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { BILLING_TYPE, Locale, OFFICE_VISIT_MEDICALCODE } from '../../../common/constants/app-constants';
import { SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../common/enums/appEvents';
import { IRule } from '../../../common/interfaces/iSearchStrategyResponse';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IDetailsResponse, IProviderDetail } from '../../../fad/provider-details/models/iDetailsResponse';
import { IHealthwiseAdobeAnalyticsObj, ISpecialty } from '../../../fad/search-results/interfaces/iSummaryResp';
import { Email, Print, ResultActionType, ResultActions } from '../../../fad/search-results/values/resultActions';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { EmailComponent } from '../../common/components/shareable/email/emailCmp';
import { ViewHealthwiseComponent } from '../../common/components/shareable/healthwise/healthwiseCmp';
import { SearchSlideOutComponent } from '../../common/components/shareable/search-slide-out/searchSlideOutCmp';
import { CptSearchSlideOutTabs } from '../../common/components/shareable/search-slide-out/searchSlideOutConstants';
import { SliderDirection } from '../../common/constants/common';
import { FormattedMbrPlanProgress } from '../../common/interfaces/iMemberPlanProgressResponse';
import { IPageTitle } from '../../common/interfaces/iPageTitle';
import { TransformMemberPlanProgressPipe } from '../../common/pipes/transformMemberPlanProgressPipe';
import { ActionService } from '../../common/services/actionSvc';
import { PublicTccMedicalAdapterSvc } from '../../common/services/publicTccMedicalAdapterSvc';
import { SecureCptAdapterSvc } from '../../common/services/secureCptAdapterSvc';
import { SecureDentalProcedureAdapterSvc } from '../../common/services/secureDentalProcedureAdapterSvc';
import { SecureProviderCptOfficeVisitCostSvc } from '../../common/services/secureProviderCptOfficeVisitCostSvc';
import { SecureTccMedicalAdapterSvc } from '../../common/services/secureTccMedicalAdapterSvc';
import { SpecialtySearchSvc } from '../../common/services/specialtySearchSvc';
import { CommonUtility } from '../../common/utilities/commonUtil';
import { HYPHEN, PDF_FILE_NAME, ProviderCardNavigation, UNDERSCORE } from '../constants/result';
import { IMapLocation } from '../interfaces/iMap';
import { IProvider, IProviderList } from '../interfaces/iProvider';
import { ProviderDetail } from '../services/providerDetail';
import { SearchSvc } from './../../../common/services/searchSvc';
import { ProviderMapComponent } from './providerMap/providerMapCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-result-cmp',
  templateUrl: './resultCmp.html'
})
export class ResultComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('providerMap') providerMap: ProviderMapComponent;
  @ViewChild('fcEmailmodalNew') emailModal: EmailComponent;

  providers: IProvider[] = [];
  ruleList: IRule[] = [];
  selectedProvider: IProvider;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  mapVisible: boolean = true;
  showLoading: boolean = false;
  showLoadProviders: boolean = false;
  searchClickSubscription: Subscription;
  @ViewChild('searchSlideOutContentModal')
  searchSlideOutContentModal: SearchSlideOutComponent;
  formattedMbrPlanProgressList: FormattedMbrPlanProgress[] = [];
  currentIndex: number = 0;
  showProviderCard: boolean = false;
  isCptSearch: boolean = false;
  closeProviderCard: boolean = false;
  showSearchResultsFilter: boolean = false;
  resultContent = this.content?.result?.result;
  detailsResponse: IProviderDetail;
  resultCountText = this.content?.result?.pagination?.resultCountText;
  totalPages: number;
  selectedPageNumber: number = 1;
  totalRecords: number;
  showPdfAndEmail: boolean = false;
  showPageProgress: boolean = false;
  isVitalsRequired: boolean = false;

  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: true
  };
  showAboutYourSearch: boolean = false;
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;
  private showProviderCardSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _specialtySearchSvc: SpecialtySearchSvc,
    private _actionService: ActionService,
    private _transformMemberPlanProgressPipe: TransformMemberPlanProgressPipe,
    private searchService: SearchSvc,
    private _secureTccMedicalSvc: SecureTccMedicalAdapterSvc,
    private _secureTccDentalSvc: SecureDentalProcedureAdapterSvc,
    private _publicTccMedicalSvc: PublicTccMedicalAdapterSvc,
    private _secureCptMedicalSvc: SecureCptAdapterSvc,
    private _providerDetail: ProviderDetail,
    private _providerOfcVisitCostSvc: SecureProviderCptOfficeVisitCostSvc,
    private _appUtility: AppUtility,
    private _infoListSvc: InfoListService,
    private _renderer: Renderer2,
    private _el: ElementRef
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.onPreLoad();
    this.setPageHeader();
    this.getProviders();
    this.subscribeSearchClick();
    if (this._appSession.isEyc || this._appSession.isMedicalCodeSearch) {
      this.showAboutYourSearch = true;
    }
    this.getMemBerPlanProgress();
    this.showPdfAndEmail =
      this.searchService?.currentScenario &&
      (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL ||
        this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL ||
        this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL ||
        this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL);
    const pageHeaderWrapper = this._el.nativeElement.querySelector('.page-header-wrapper');
    this._renderer.listen(pageHeaderWrapper, 'click', () => {
      this.showProviderCard = false;
    });
  }

  getMemBerPlanProgress(): void {
    this.formattedMbrPlanProgressList = this._transformMemberPlanProgressPipe.transform(this._appSession.memberPlanProgressDetails, this.content);
  }

  ngOnDestroy() {
    this.searchClickSubscription?.unsubscribe();
    this.showProviderCardSubscription?.unsubscribe();
  }

  setPageHeader() {
    if (this.content && this.content.common && this.content.common.pageHeader && this.content.common.pageTitle) {
      const pageTitleObj: IPageTitle = {
        header: this.content.common.pageHeader.fadPageTitle,
        title: this.content.common.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageTitleObj);
    }
  }

  getProviders(map = false, pageNumber: number = 1) {
    //TODO- FCR disclaimer - SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY is pending(v2 version)
    if (map) {
      this.showLoadProviders = true;
    } else {
      this.showLoading = true;
    }

    if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
      this._secureTccDentalSvc.search(pageNumber, 20).then((response) => {
        if (response) {
          this.onGetProviderSuccess(response, pageNumber);
        }
      });
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
      this._secureTccMedicalSvc.search(pageNumber, 20).then((response) => {
        if (response) {
          this.onGetProviderSuccess(response, pageNumber);
        }
      });
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
      this._publicTccMedicalSvc.search(pageNumber, 20).then((response) => {
        if (response) {
          this.onGetProviderSuccess(response, pageNumber);
        }
      });
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL && this.getHasCptSearch()) {
      this.isCptSearch = true;
      this._secureCptMedicalSvc.search(pageNumber, 20).then((response) => {
        this.onGetProviderSuccess(response, pageNumber);
        this._appSession.medicalModifierOptionCode = response.modifier?.code;
        this._appSession.medicalPosCode = response.pos?.code;
      });
    } else {
      this._specialtySearchSvc.search(pageNumber, 20).then((response) => {
        if (response) {
          this.onGetProviderSuccess(response, pageNumber);
          this._providerOfcVisitCostSvc.getProviderCptOfficeVisitCost(this.providers);
        }
      });
    }
  }

  getHasCptSearch(): boolean {
    return CommonUtility.isCptSearch(this._appSession);
  }

  onProviderHover(provider: IProvider) {
    this.providerMap?.toggleProviderInfobox(provider, true);
  }

  onProviderLeave(provider: IProvider) {
    this.providerMap?.toggleProviderInfobox(provider, false);
  }

  onMapSearch(center: IMapLocation) {
    this._appSession.searchParams.coordinates = center;
    this.getProviders(true);
  }

  toggleMap(visible: boolean) {
    this.mapVisible = visible;
  }

  subscribeSearchClick() {
    this.searchClickSubscription = this._actionService.getSearchClick().subscribe((value) => {
      if (value) {
        this.getProviders();
      }
    });
  }

  /** Open healthwise side Panel */
  openHealthwiseSidePanel(specialty?: ISpecialty, provider?: IProvider, procedureCode?: string) {
    if (procedureCode) {
      this._adobeAnalyticsObj.procedureName = this.procedureName;
    }
    if (specialty && specialty.specialtyCodeName) {
      this._adobeAnalyticsObj.specialtyName = specialty.specialtyCodeName;
    }
    this.viewHealthwiseContentModal.openSidePanel(SliderDirection.RIGHT, specialty, provider, procedureCode, this._adobeAnalyticsObj);
  }

  get procedureName(): string {
    if (
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName
    ) {
      return this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
    }
    return '';
  }

  get procedureNameCode(): string {
    return this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
  }

  /**
   * Method to get the selected provider.
   * Getting the selected provider details that needs to be shown in provider details card.
   * @param provider Provider data.
   */
  onProviderSelect(provider: IProvider) {
    this._providerDetail.initialize(provider.providerIdentifier, provider.addressSummary.addressIdentifier);
    this.selectedProvider = provider;
    this._appSession.selectedProvider = this.selectedProvider;
    if (provider?.costInfo?.isOfficeVisitCost && CommonUtil.isValidString(provider.costInfo?.copay)) {
      this._appSession.medicalCode = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
      this._appSession.billingType = BILLING_TYPE.CPT;
      this._appSession.selectedPosCode = this._appSession.metaData?.locale === Locale.ENGLISH ? OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_EN : OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_ES;
      this._appSession.searchTerm = OFFICE_VISIT_MEDICALCODE.SEARCH_TERM;
      this._appSession.isSpecialtyOfficeVisitCost = true;
    }
  }

  onShowProviderCard(cardStatus: boolean) {
    this.showProviderCard = cardStatus;
  }

  onCloseProviderCard() {
    this.selectedProvider = null;
    this.showProviderCard = false;
  }

  provDetailsLoaded(details: IDetailsResponse) {
    this.detailsResponse = details.providerDetail;
  }

  get isEyc() {
    return this._appSession.isEyc;
  }

  openSearchSidePanel(procedureCode?: string, tabName?: CptSearchSlideOutTabs) {
    this.searchSlideOutContentModal.openSidePanel('right', procedureCode, tabName);
  }

  onPageChanged(pageNumber: number) {
    this.showProviderCard = false;
    this.getProviders(true, pageNumber);
  }

  onGetProviderSuccess(response: IProviderList, pageNumber?: number) {
    this.providers = response?.providers;
    this.totalPages = response?.totalPages;
    this.totalRecords = response?.totalRecords;
    this.ruleList = response?.rules;
    if (this.totalRecords < 20) {
      this.resultCountText = this.content?.result?.pagination?.resultCountLessProviderText;
    } else {
      this.resultCountText = this.content?.result?.pagination?.resultCountText;
    }
    if (pageNumber) {
      this.selectedPageNumber = pageNumber;
    }
    this.showLoading = false;
    this.showLoadProviders = false;
    this._appSession.selectedPosCode = response.pos?.code;
    this.setVitalsIndicator(response.providers);
    this._appUtility.scrollToTop();
  }

  onPrintClick() {
    this.onActions(new Print());
  }

  onEmailClick() {
    this.onActions(new Email());
  }

  onActions(action: ResultActions) {
    if (action.getType === ResultActionType.Print) {
      this.showPageProgress = true;
      this.generatePdf('');
    } else if (action.getType === ResultActionType.Email) {
      setTimeout(() => {
        this.emailModal.show();
      }, 300);
    }
  }

  private generatePdf(emailId: string) {
    if (!CommonUtility.isValidString(emailId)) {
      this.executeOnPrint();
    } else {
      this.executeOnEmail(emailId);
    }
  }

  async executeOnPrint() {
    this.showPageProgress = true;
    if (this.isSecureState) {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
        this._secureTccMedicalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
        this._secureTccDentalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
        this._secureCptMedicalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      }
    } else {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
        this._publicTccMedicalSvc
          .print(this.content.result)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      }
    }
  }

  onPrintSuccess(printResponse: Buffer) {
    this.downloadPdf(printResponse);
    this.showPageProgress = false;
  }

  onPrintFailure(error) {
    this._appSession.initialSearch = false;
    this.showPageProgress = false;
  }

  private downloadPdf(pdf: any) {
    if (this._appUtility.isWebkitBrowser()) {
      // Download for webkit based browser
      let binary = '';
      const bytes = new Uint8Array(pdf);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const base64 = 'data:' + 'application/pdf' + ';base64,' + window.btoa(binary);
      const fileURL = encodeURI(base64);
      let windowParams: string;
      windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
      const winArgs = [fileURL, '_blank'];
      if (windowParams) {
        winArgs.push(windowParams);
      }
      window.open.apply(window, winArgs);
    } else {
      const file = new Blob([pdf], { type: 'application/octet-stream' });
      //Download for other browsers
      const lnkDownload = document.createElement('a');
      lnkDownload.style.display = 'none';
      const fileURL = URL.createObjectURL(file);
      lnkDownload.href = fileURL;
      lnkDownload.download = `${this.pdfFileName}.pdf`;
      document.body.appendChild(lnkDownload);
      lnkDownload.click();
      // To make work with Firefox
      setTimeout(() => {
        document.body.removeChild(lnkDownload);
        window.URL.revokeObjectURL(fileURL);
      }, 100);
    }
  }

  private get pdfFileName(): string {
    const now = new Date(Date.now());
    const date = `${now.getMonth() + 1}${now.getDate()}${now.getFullYear()}`;
    const code = this._appSession.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
    const medical_code = this._appSession.billingType + HYPHEN + this._appSession.medicalCode;

    if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
      const dental_procedure_content = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.DENTAL_PROCEDURE];
      return this.getPdfFileName(dental_procedure_content, code, date);
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL || this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
      const medical_procedure_content = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.MEDICAL_PROCEDURE];
      return this.getPdfFileName(medical_procedure_content, code, date);
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
      const medical_code_content = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.MEDICAL_CODE];
      return this.getPdfFileName(medical_code_content, medical_code, date);
    }
  }

  private getPdfFileName(content: string, code: string, date: string): string {
    const search_on = this.content.result.summaryComponent.pdfFileName[PDF_FILE_NAME.SEARCH_ON];
    return content + UNDERSCORE + code + search_on + `${date}`;
  }

  getEmailPopupHeader(): string {
    return this.content.result.searchEmailComponent.emailSearchHeader;
  }

  emailBody(): string {
    return this.content.result.searchEmailComponent.emailSearchNote;
  }

  onSendEmail(emailId: string) {
    this.generatePdf(emailId);
  }

  async executeOnEmail(email: string) {
    if (this.isSecureState) {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
        this._secureTccMedicalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
        this._secureTccDentalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
        this._secureCptMedicalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      }
    } else {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
        this._publicTccMedicalSvc
          .email(email, this.content.result)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      }
    }
  }

  onEmailSuccess() {
    this.emailModal.emailMessageSent = this.content.common.alerts.emailSent;
  }

  onEmailFailure() {
    this.emailModal.emailMessageSent = this.content.common.alerts.emailFailed;
  }

  onToggleSearchResultFilter() {
    this.showSearchResultsFilter = !this.showSearchResultsFilter;
  }

  onCardNavigation(flowType: ProviderCardNavigation) {
    if (flowType == ProviderCardNavigation.AFFILIATION) {
      this.showSearchResultsFilter = true;
    }
  }

  setVitalsIndicator(providers: IProvider[]) {
    this.isVitalsRequired = providers.some((provider) => !isEmpty(provider.pdtKey));
  }

  // Handle the Alert message on AddCare
  showAddCareAlert(disclaimerCodes) {
    this._infoListSvc.setInfoList(disclaimerCodes);
  }
}
