import { Injectable } from "@angular/core";
import { apiNameList } from "../../../../environments/api-name-list";
import { HttpMethod } from "../../../common/enums/httpMethodEnum";
import { BaseService } from "../../../common/services/baseService";
import {
  IFeedbackRequest,
  IFeedbackResponse,
} from "../interfaces/iFeedback";
import { HttpClientService } from "./../../../common/services/httpClientService";
import { AppUtility } from "./../../../common/utilities/appUtil";
import { AppSession } from "./../../../common/values/appSession";

@Injectable()
export class FeedbackService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async submitV1Feedback(
    request: IFeedbackRequest
  ): Promise<IFeedbackResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.feedbackV1,
      data: request,
      headers: this.getHeaders(),
    });
  }
}
