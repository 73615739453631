import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { GlobalBackButtonComponent } from './globalBackBtnCmp';


@NgModule({
  declarations: [GlobalBackButtonComponent],
  exports: [GlobalBackButtonComponent],
  imports: [
      CommonModule,
      UxSharedModule.forRoot(),
      FormsModule
  ],
  providers: []
})
export class GlobalBackBtnModule { }
