import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBaseProvider, IPCPInfoUIData } from '../../interfaces/iPCPInfo';
import { IWindow } from '../../interfaces/iWindow';
import { PcpDetailsUtil } from '../../utilities/pcpDetailsUtil';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { AFFILIATION_GROUP, AFFILIATION_LABEL, AFFILIATION_NAME, ID } from './../../constants/app-constants';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pcp-id-list-cmp',
  templateUrl: './pfPcpIdListCmp.html'
})
export class PcpIdListComponent extends BaseComponent {

  private _alwaysExpanded: boolean = false;

  @ViewChild('PCPInfoListElm')
  pcpInfoListElm: ElementRef;

  private _provider: IBaseProvider;
  private _pcpInfoList: IPCPInfoUIData[];
  private _prevScrollY: number;

  hasMultiPCPs: boolean;
  isExpanded: boolean = false;

  defaultPCPInfo: IPCPInfoUIData;
  expandableItems?: IPCPInfoUIData[];
  initialised: boolean = false;

  constructor(route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession, contentHlpr: ContentHelper, private _dataHlpr: DataHelper, private _pcpUtils: PcpDetailsUtil,
    @Inject('Window')
    private _window: IWindow) {
    super(route, eventHandler, appSession, contentHlpr, 'PFPcpIdListComponent', 'pcpIdListCmpContent');
  }

  @Input()
  public set provider(value: IBaseProvider) {
    if (!value) {
      return;
    }

    if (value !== this._provider) {
      this._provider = value;
      this.initialised = false;
      this.isExpanded = false; // store toggle status
      this._pcpInfoList = this.createPCPInfoListData(value);
      if (!Array.isArray(this._pcpInfoList) || this._pcpInfoList.length === 0) {
        return;
      }
      this.defaultPCPInfo = this._pcpInfoList[0];
      this.expandableItems = this._pcpInfoList.slice(1);// All PCPInfo items except the 0th one
      this.hasMultiPCPs = this.defaultPCPInfo.affiliations.length > 1 || this.expandableItems.length > 0;
      if (this.alwaysExpanded) {
        this.isExpanded = true;
      }
      this.initialised = true;
    }
  }

  public get provider(): IBaseProvider {
    return this._provider;
  }

  @Input()
  public set alwaysExpanded(val: boolean) {
    this._alwaysExpanded = val;
    this.isExpanded = val;
  }

  public get alwaysExpanded(): boolean {
    return this._alwaysExpanded;
  }

  /**
   * Method to toggle between 'Show More' or 'Show Less'
   */
  toggleExpandedView(): void {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      //While expanding the list, save the window's scrollY value in a temp variable.
      //This value can be used while collapsing the list by clicking 'Show less'
      this._prevScrollY = this._window.scrollY;
    } else {
      //While collapsing a tall list, scroll to the top of the list
      this._window.scroll({ left: 0, top: this._prevScrollY, behavior: 'smooth' });
    }
  }

  /**
 * Make a unique list of PCPIDs and group their Affiliation names in an array
 * @param providers
 * @returns Array of IPCPInfoUIData
 */
  private createPCPInfoListData(provider: IBaseProvider): IPCPInfoUIData[] {
    if (!Array.isArray(provider.pcpInfoList)) {
      return;
    }
    return this._pcpUtils.groupByPcpId(provider.pcpInfoList, ID, [AFFILIATION_NAME, AFFILIATION_GROUP, AFFILIATION_LABEL],this.content.partof);
  }
}
