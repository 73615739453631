import { Injectable } from '@angular/core';
import { SEARCH_SCENARIOS, SELECT_DEFAULT_ZERO } from '../../../common/constants/strategy-search-constant';
import { IProviderMiscFilterInRequest } from '../../../common/interfaces/iCommonSearchRequest';
import { IFinalProvider, IFinalSearchResponse } from '../../../common/interfaces/iCommonSearchResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecurePharmacyEmailRequest } from '../../../common/interfaces/iSecurePharmacyEmailRequest';
import { ISecurePharmacyDemographicFilter, ISecurePharmacyRequest, ISecurePharmacySearchCriteria } from '../../../common/interfaces/iSecurePharmacySearchRequest';
import { ISecurePharmacyResponse } from '../../../common/interfaces/iSecurePharmacySearchResponse';
import { BaseService } from '../../../common/services/baseService';
import { SecureV1RxSpecialty } from '../../../common/services/secureV1RxSpecialitySvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { SearchRequestUtil } from '../../utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../utilities/searchResponseUtil';
import { API_NAME } from '../values/providerSearchConstants';
import { HttpClientService } from './../../../common/services/httpClientService';


@Injectable({
  providedIn: 'root',
})

export class SecureRxAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = this.appSession.appConfig?.paginationSize.summaryPrintPage;
  constructor(
    appSession: AppSession,
    private _securePharmacySvc: SecureV1RxSpecialty,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility, httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    const apiResponse = await this._securePharmacySvc.execute(transformedRequest);
    return this.transformResponse(apiResponse);
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ISecurePharmacyRequest = { ...searchRequest };
    const apiName = SearchRequestUtil.getApiName(API_NAME.SECURE_RX_SPECIALTY);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(email: string, content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ISecurePharmacyEmailRequest = { ...searchRequest, email };
    const apiName = SearchRequestUtil.getApiName(API_NAME.SECURE_RX_SPECIALTY);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ISecurePharmacyRequest {
    const securePharmacyRequest = this.transformRequest(
      pageNumber, pageSize
    );
    const filterReq: ISecurePharmacyRequest = {
      ...securePharmacyRequest,
      scenario: SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY,
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): ISecurePharmacyRequest {
    const searchParams = this.appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(this.appSession, pageNumber, pageSize);
    const demographicFilter: ISecurePharmacyDemographicFilter = {
      coverageTypeCode: searchParams?.coverageTypeCode
    };
    const miscFilter: IProviderMiscFilterInRequest = {
      smartSearchRequestId: searchParams?.smartSearchRequestId
    };
    if (searchParams?.languages && !searchParams?.languages?.includes(SELECT_DEFAULT_ZERO)) {
      demographicFilter.languageCodes = searchParams.languages;
    }
    if (searchParams?.provNm || this.appSession.isMedicalAffSearch || this.appSession.isHospAffSearch) {
      demographicFilter.providerName = this.appSession.isMedicalAffSearch ? this.appSession.medicalGrpSearchTerm :
        (this.appSession.isHospAffSearch ? this.appSession.hospSearchTerm : searchParams?.provNm);
    }
    else {
      if (!this.appSession.isSpecialitySearch && !searchParams?.nationalProviderIdentifier && !this.appSession.isEyc && !this.appSession.isAoeSearch && !this.appSession.isOfcSrvcSearch && !this.appSession.isSearchTerm) {
        demographicFilter.providerName = this.appSession.searchTerm;
      }
    }
    demographicFilter.specialtyCategoryCodes = searchParams?.specialtySelectNm
      && searchParams?.specialtySelectNm.length > 0 ?
      searchParams.specialtySelectNm.filter((x) => x !== SELECT_DEFAULT_ZERO) : [];
    demographicFilter.specialtyCategoryCodes = SearchRequestUtil.getSpecialSpecialties(this.appSession, demographicFilter.specialtyCategoryCodes);

    demographicFilter.taxonomyCodes = searchParams?.taxonomySelectNm || [];
    demographicFilter.typeCodes = searchParams?.providerTypeCodeList?.length ? searchParams?.providerTypeCodeList : [searchParams?.typeSelectNm];
    const rxFilter = SearchRequestUtil.buildRXFilterRequest(searchParams);

    const searchCriteria: ISecurePharmacySearchCriteria = {
      brandCode: this.appSession.metaData.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this.appSession),
      isInitialSearch: this.appSession?.initialSearch,
      demographicFilter,
      rxFilter,
      miscFilter
    };
    const request: ISecurePharmacyRequest = {
      ...commonSearchRequest,
      searchCriteria
    }
    return request;
  }

  private transformResponse(apiResponse: ISecurePharmacyResponse) {
    const response = {} as IFinalSearchResponse;
    const finalProviders: IFinalProvider[] = [];
    if (apiResponse) {
      response.pageNumber = apiResponse.pageNumber;
      response.pageSize = apiResponse.pageSize;
      response.totalPages = apiResponse.totalPages;
      response.totalRecords = apiResponse.totalRecords;
      response.defaultSort = apiResponse.sortBy;
      response.ruleList = apiResponse.rules;
      response.sortOptions = apiResponse.sortOptions;
      response.criteriaChange = apiResponse.criteriaChange;
      response.onsiteClinics = apiResponse?.onsiteClinics ?? undefined;
      if (apiResponse.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IFinalProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            finalProviders.push(finalProvider);
          }
        });
      }
      response.providerList = finalProviders;
      response.sortIndicator = apiResponse.sortIndicator;
    }
    return response;
  }

}
