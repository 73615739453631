import { EventEmitter, Injectable } from '@angular/core';
import { IEvent } from '../interfaces/iEvent';

/**
 *  Helper provider to handle events globally.
 *  This allows creating internal event cache that will be used to manage global events.
 *  other components in the application can get specific event and listen/emit on those events.
 */
@Injectable()
export class EventHandler {
  private _events: Array<IEvent> = [];

  /**
   *  Allow getting cached EventEmitter for given key
   *  @param name - cache key to be used to hold the created event
   *  @return - returns created or cached EventEmitter
   * */
  get(keyName: string | number): EventEmitter<any> {
    let event = this._events.find((e) => e.name === keyName);
    if (!event) {
      event = {
        name: keyName,
        value: new EventEmitter<any>()
      };

      this._events.push(event);
    }
    return event.value;
  }

  removeAll() {
    this._events.forEach((e) => {
      if (!e.value.closed) {
        e.value.unsubscribe();
        e.value = null;
      }
    });
    this._events = [];
  }
}
