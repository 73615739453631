import { Directive, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionItemLookupComponent } from '../components/actionItemLookupCmp';
import { ActionItemLookupService } from '../services/actionItemLookupSvc';
import { ActionItemCmp } from './../components/actionItemCmp';

@Directive({ selector: '[action-item-lookup]' })
export class ActionItemLookupDirective implements OnDestroy {
  @Input() parentComponent: ActionItemCmp;
  @Input() items: Array<string>;
  @Input() isFeedbackMenu = false;
  @Output() readonly actionItemClick: EventEmitter<string> = new EventEmitter<string>();
  private _actionItemClickSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private lookupService: ActionItemLookupService
  ) {
    this.lookupService.destoryComponent();
  }

  ngOnInit() {
    const templateView = this.templateRef.createEmbeddedView({});
    const componentRef = this.viewContainer.createComponent(ActionItemLookupComponent, {
      injector: this.viewContainer.injector,
      projectableNodes: [templateView.rootNodes]
    });
    const instance = componentRef?.instance;
    if (instance) {
      instance.parentComponent = this.parentComponent;
      instance.items = this.items;
      instance.isFeedbackMenu = this.isFeedbackMenu;
      this._actionItemClickSubscription = instance.actionItemClick.subscribe((event) => {
        this.actionItemClick.emit(event);
      });
      this.lookupService.registerComponent(componentRef);
    }
  }

  ngOnDestroy(): void {
    if (this.parentComponent) {
      this.parentComponent.selected = false;
    }
    this._actionItemClickSubscription?.unsubscribe();
  }
}
