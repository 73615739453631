/*
  This is used for simple 10 digit numeric transformation to xxx-xxx-xxxx pattern.
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonePattern'
})
export class PhonePatternPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value.length === 10) {
      value.replace(/\D/g, '');
      value = '(' + value.substr(0, 3) + ') ' + value.substr(3, 3) + '-' + value.substr(6, 4);
    }
    return value;
  }
}
