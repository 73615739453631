import { Injectable } from '@angular/core';
import { IDetailsParameters } from '../../fad/provider-details/models/iDetailsParameters';
import { ILocation } from '../../fad/provider-details/models/iLocation';
import { ILocationRequest } from '../../fad/provider-details/models/iLocations';
import { IPlanList } from '../../fad/provider-details/models/iPlanList';
import { OUT_OF_NETWORK, PROVIDER_TYPE, RETAIL_PHARMACY } from '../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../fad/utilities/commonUtil';
import { SearchRequestUtil } from '../../fad/utilities/searchRequestUtil';
import { Locale } from '../constants/app-constants';
import { IMemberCriteria } from '../interfaces/iCommonSearchRequest';
import { IFinalProvider } from '../interfaces/iCommonSearchResponse';
import { AppSession } from '../values/appSession';
import { IBaseCode } from './../interfaces/iBaseCode';

@Injectable({ providedIn: 'root' })
export class LocationUtil {
  private _appSession: AppSession;
  constructor(appSession: AppSession) {
    this._appSession = appSession;
  }

  buildVcardRequest(selectedAddressId: string, provider: IFinalProvider = {} as IFinalProvider): IDetailsParameters {
    const location: ILocation = {
      latitude: this._appSession?.searchParams?.coordinates?.latitude ?? '',
      longitude: this._appSession?.searchParams?.coordinates?.longitude ?? ''
    };
    const plan: IPlanList = {
      identifier: this._appSession?.searchParams?.plan?.identifier?.trim() ?? null,
      isNationalPlan: this._appSession?.searchParams?.plan?.isNationalPlan
    };
    const netwrkList: IBaseCode[] = [];
    for (const network of this._appSession?.searchParams?.plan?.networkList || []) {
      netwrkList.push({ code: network } as IBaseCode);
    }
    const request: IDetailsParameters = {
      providerIdentifier: provider.providerIdentifier,
      addressIdentifier: selectedAddressId,
      brandCode: this._appSession?.metaData?.brandCd,
      state: undefined,
      distance: this._appSession?.searchParams?.distanceNm ? parseInt(this._appSession.searchParams.distanceNm.trim(), 10) : 0,
      planStateCode: this._appSession?.searchParams?.plan?.stateCd,
      rxChoiceNtwkIndicator: '',
      specialtyCategoryList: this._appSession?.searchParams?.specialtySelectNm?.length > 0 ? this._appSession.searchParams.specialtySelectNm.filter((x) => x !== '00') : null,
      networkList: netwrkList,
      alphaPrefix: CommonUtil.getAlphaPrefix(this._appSession),
      location: location,
      locale: this._appSession?.metaData?.locale?.toString() ?? Locale.ENGLISH,
      planList: [plan]
    };

    if (provider.providerTypeCodeList) {
      request.providerTypeCodeList = provider.providerTypeCodeList;
    }
    if (this._appSession?.metaData?.appContract?.mbrUid) {
      request.mbrUid = this._appSession.metaData.appContract.mbrUid;
    }
    if (this._appSession?.metaData?.appContract?.contractUid) {
      request.contractUid = this._appSession.metaData.appContract.contractUid;
    }
    if (provider.providerTypeCodeList?.length === 1 && provider.providerTypeCodeList[0] === PROVIDER_TYPE.PHARMACY) {
      request.providerTypeCode = PROVIDER_TYPE.PHARMACY;
    }
    if (request && request.providerTypeCode === PROVIDER_TYPE.PHARMACY && this._appSession?.searchParams?.coverageTypeCode === RETAIL_PHARMACY) {
      request.pharmacyName = provider.providerName;
      request.pharmacyNumber = provider.providerIdentifier;
      request.coverageTypeCode = this._appSession?.searchParams?.coverageTypeCode;

      let location = provider.location;
      if (location && location.address && location.address.state) {
        request.state = location.address.state;
      } else if (this._appSession?.searchParams?.stateCd?.code) {
        request.state = this._appSession.searchParams.stateCd.code;
      }
      if (location && location.address && location.address.postalCode) {
        request.zipCode = location.address.postalCode;
      } else if (this._appSession?.searchParams?.zip) {
        request.zipCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
      }
      if (location && location.address && location.address.city) {
        request.city = location.address.city;
      } else if (this._appSession?.searchParams?.city) {
        request.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
      }
    } else {
      if (this._appSession?.searchParams?.stateCd?.code) {
        request.state = this._appSession.searchParams.stateCd.code;
      }
      if (this._appSession?.searchParams?.zip) {
        request.zipCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
      }
      if (this._appSession?.searchParams?.city) {
        request.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
      }
    }
    if ((CommonUtil.isMemberSecure(this._appSession) || this._appSession?.searchParams?.identificationNumber) && this._appSession?.isFutureCoverage) {
      request.isFutureCoverage = this._appSession.isFutureCoverage;
    }
    return request;
  }

  buildLocationRequest(provider: IFinalProvider = {} as IFinalProvider): ILocationRequest {
    const memberCriteria = {} as IMemberCriteria;
    const identifier = this._appSession?.searchParams?.plan?.identifier?.trim() ?? '';

    const locationsRequest = {
      id: provider.providerIdentifier,
      addressId: provider.location?.address?.addressId ?? '',
      brandCode: this._appSession?.metaData?.brandCd,
      distance: this._appSession?.searchParams?.distanceNm ? parseInt(this._appSession.searchParams.distanceNm.trim(), 10) : 0,
      planStateCode: this._appSession?.searchParams?.plan?.stateCd,
      networks: this._appSession?.searchParams?.plan?.networkList,
      alphaPrefix: CommonUtil.getAlphaPrefix(this._appSession),
      latitude: this._appSession?.searchParams?.coordinates?.latitude ?? '',
      longitude: this._appSession?.searchParams?.coordinates?.longitude ?? '',
      plans: identifier ? [identifier] : undefined,
      locale: this._appSession?.metaData?.locale?.toString() ?? Locale.ENGLISH,
      specialtyCategories: this._appSession?.searchParams?.specialtySelectNm?.length ? this._appSession.searchParams.specialtySelectNm.filter((spec) => spec !== '00') : undefined,
      coverageType: SearchRequestUtil.isOutOfNetwork(this._appSession) ? OUT_OF_NETWORK : undefined
    } as ILocationRequest;

    if (provider.providerTypeCodeList?.length) {
      locationsRequest.typeCodes = provider.providerTypeCodeList;
    }
    if (this._appSession?.metaData?.appContract?.mbrUid) {
      memberCriteria.mbrUid = this._appSession.metaData.appContract.mbrUid;
    }
    if (this._appSession?.metaData?.appContract?.contractUid) {
      memberCriteria.contractUid = this._appSession.metaData.appContract.contractUid;
    }
    if (this._appSession?.searchParams?.identificationNumber && !this._appSession?.isPlanUnknown) {
      memberCriteria.identificationNumber = this._appSession.searchParams.identificationNumber;
    }
    if ((CommonUtil.isMemberSecure(this._appSession) || this._appSession?.searchParams?.identificationNumber) && this._appSession?.isFutureCoverage) {
      memberCriteria.isFuturePlan = this._appSession.isFutureCoverage;
    }
    locationsRequest.memberCriteria = memberCriteria;

    if (provider.providerTypeCodeList?.length === 1 && provider.providerTypeCodeList[0] === PROVIDER_TYPE.PHARMACY) {
      locationsRequest.typeCodes = [PROVIDER_TYPE.PHARMACY];
    }
    if (locationsRequest.typeCodes?.includes(PROVIDER_TYPE.PHARMACY)) {
      locationsRequest.providerName = provider.providerName;
      locationsRequest.pharmacyNumber = provider.providerIdentifier;
      locationsRequest.coverageTypeCode = this._appSession?.searchParams?.coverageTypeCode;

      const location = provider.location;
      if (location && location.address && location.address.state) {
        locationsRequest.state = location.address.state;
      } else if (this._appSession?.searchParams?.stateCd?.code) {
        locationsRequest.state = this._appSession.searchParams.stateCd.code;
      }

      if (location && location.address && location.address.postalCode) {
        locationsRequest.postalCode = location.address.postalCode;
      } else if (this._appSession?.searchParams?.zip) {
        locationsRequest.postalCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
      }

      if (location && location.address && location.address.city) {
        locationsRequest.city = location.address.city;
      } else if (this._appSession?.searchParams?.city) {
        locationsRequest.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
      }
    }

    return locationsRequest;
  }
}
