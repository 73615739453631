import { EventEmitter } from '@angular/core';
export interface IEvent {
  name: string | number;
  value: EventEmitter<any>;
}

export const CONTINUE = 'continue';

export interface IApiStatusEvent {
  isProgress: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  hasValidData: boolean;
}

export interface IPageEvent {
  header: string;
  title: string;
}
