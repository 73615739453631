import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EventHandler } from "../../../common/services/eventHandler";
import { AppSession } from "../../../common/values/appSession";
import { ContentHelper } from "../../../common/values/contentHelper";
import { ITranslation } from "../interfaces/iAdaListResp";
import { IFtsSuggestion } from "../interfaces/iFtsClientInteraction";
import { BaseComponent } from "./../../../common/components/base-component/baseCmp";

@Component({
  moduleId: module.id,
  selector: "app-fad-pf-fts-suggestion-cmp",
  templateUrl: "../views/pfFtsSuggestionCmp.html",
  providers: [],
})
export class PFFtsSuggestionComponent extends BaseComponent implements OnInit {
  @Input()
  suggestions: IFtsSuggestion<ITranslation>[] = [];
  @Output()
  suggestionSelect: EventEmitter<IFtsSuggestion<ITranslation>> =
    new EventEmitter<IFtsSuggestion<ITranslation>>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchContainerComponent"
    );
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  onSuggestionSelect(suggestion: IFtsSuggestion<ITranslation>) {
    this.suggestionSelect.emit(suggestion);
  }
}
