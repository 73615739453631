import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { EmailComponent } from './pfEmailCmp';

@NgModule({
    declarations: [EmailComponent],
    exports: [EmailComponent],
    imports: [
        FormsModule, ReactiveFormsModule,
        UxSharedModule,
        CommonModule
    ],
    providers: []
})
export class EmailModule { }
