import { IBaseCode } from './../../../common/interfaces/iBaseCode';
import { ILocation } from './iLocation';

export interface IAffiliationsRequest {
  providerIdentifier: string;
  addressIdentifier: string;
  networkList?: Array<string>;
  alphaPrefixList?: Array<string>;
  isDoctorAffiliation: boolean;
  providerTypeCode?: string;
  providerTypeCodeList?: Array<string>;
  distance?: number;
  location?: Array<ILocation>;
  outputFlags?: IOutputFlags;
  inNetworkList?: Array<string>;
}

export class AffiliationResponse {
  affiliationSummaryList: Array<AffiliationDetails>;
  showPCPDisclaimer: boolean;
  pcpCount: string;
}

export class AffiliationDetails {
  affiliationType: string;
  providerDetails: Array<ProviderDetails>;
}

export class ProviderDetails {
  providerIdentifier: string;
  addressIdentifier: string;
  providerName: string;
  pcp: PcpList;
  locations?: Array<IAffiliationAddress>;
  relationshipTypeCode?: string;
  providerTypeCodeList: Array<string>;
}

export class PcpList {
  identifier: Array<string>;
  phoneEnrollmentID: Array<string>;
  paperEnrollmentID: Array<string>;
}

export interface IAffiliationAddress {
  addressId: string;
  addressOne: string;
  addressTwo: string;
  phone: string;
  specialtyList?: Array<ISpecialty>;
  latitude: string;
  longitude: string;
}

export interface ISpecialty {
  code: string;
  name: string;
  description: string;
}

export interface IOutputFlags {
  includeDemographicInfo: boolean;
  includeRelationshipCode: boolean;
}

export interface IAffliations {
  doctorAffiliation: [];
  specialtyAffiliation: [];
  urgentCareAffiliation: [];
  dentalAffiliation: [];
}

export class AffiliationDetailsResponse {
  totalRecords: string;
  totalPages: string;
  providerList: Array<IProvider>;
}

export interface IProvider {
  providerIdentifier: string;
  providerName: string;
  addressSummary: IAddressSummary;
  relationshipTypeCode: string;
  pcpList: Array<IPCPIdentifier>;
  provTypeCode: IBaseCode;
  provTypeCodeList: Array<IBaseCode>;
  provCategoryCode: IBaseCode;
}

export interface IAddressSummary {
  addressTypeCode: IBaseCode;
  addressIdentifier: string;
  addressLine1Text: string;
  cityName: string;
  countryCode: IBaseCode;
  stateCode: IBaseCode;
  postalCode: string;
  latitude: string;
  longitude: string;
  distance: string;
  contactList: Array<IContact>;
  specialtyList?: Array<ISpecialtyList> | Array<IBaseCode>;
}

export interface IContact extends IBaseCode {
  phoneNumber: string;
}

export interface ISpecialtyList {
  specialtyCode: IBaseCode;
  spcltyBoardCertificationCode: string;
  txnmyBoardCertificationCode: string;
  addressSpecialtyDetail: ISpecialityDetail;
}

export interface ISpecialityDetail {
  spcltyBoardCertificationCode: string;
  txnmyBoardCertificationCode: string;
  provAdrsAcceptingPatientsTypeCode: IBaseCode;
}

export interface IPCPIdentifier {
  identifier: string;
  phoneEnrollmentID: string;
  paperOnlineEnrollmentID: string;
}

export class AffiliationResponseV2 {
  providerList: Array<IProviderV2>;
}

export interface IProviderV2 {
  providerIdentifier: string;
  providerName: string;
  networkRegisteredName?: string;
  addressSummary: IAddressSummaryV2;
  provTypeCodeList: Array<IBaseCode>;
  provCategoryCode: IBaseCode;
  specialtyList?: Array<IBaseCode>;
  pcpList?: Array<IPcpIdentifier>;
  titleList?: Array<string>;
}

export interface IAddressSummaryV2 {
  addressIdentifier: string;
  addressLine1Text: string;
  cityName: string;
  countryCode: string;
  stateCode: string;
  postalCode: string;
  latitude: string;
  longitude: string;
  contactList: IContact;
}
export interface IPcpIdentifier{
  identifier: string;
}
