import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { GlobalHeaderComponent } from './globalHeaderCmp';

@NgModule({
    declarations: [GlobalHeaderComponent],
    exports: [GlobalHeaderComponent],
    imports: [
        CommonModule,
        UxSharedModule.forRoot(),
        FormsModule
    ],
    providers: []
})
export class GlobalHeaderModule { }
