import { IProvider } from './iProvider';

export interface IMapInfoboxContext {
  provider?: IProvider;
  providers?: IProvider[];
}

export enum IMapIcon {
  DEFAULT_PUSHPIN = 'DEFAULT_PUSHPIN',
  HOVER_PUSHPIN = 'HOVER_PUSHPIN',
  DEFAULT_CLUSTER = 'DEFAULT_CLUSTER',
  HOVER_CLUSTER = 'HOVER_CLUSTER'
}

export interface IMapLocation {
  latitude: string;
  longitude: string;
}
