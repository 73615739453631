import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { IWcsApi } from '../../findcare/common/interfaces/iAppConfig';
import { Locale } from '../constants/app-constants';
import { HttpMethod } from '../enums/httpMethodEnum';
import { AppConfig } from '../values/appConfig';
import { AppSession } from './../values/appSession';
import { ContentHelper } from './../values/contentHelper';
import { HttpClientService } from './httpClientService';

@Injectable()
export class WcsContentResolver implements Resolve<any> {
  private contentType = '.json';
  private wcsApi: IWcsApi;
  // private webApi: any;
  private locale: any;
  private contentPath: string;
  private _appConfig: AppConfig;

  constructor(
    private _httpClientService: HttpClientService,
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    this._appConfig = this._appSession.appConfig;
    this.wcsApi = this._appConfig?.wcsApi || ({} as IWcsApi);
    this.wcsApi.baseUrl = this._appConfig?.baseUrl[this._appSession.state] + this.wcsApi?.baseUrl;
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale : Locale.ENGLISH;
    // Default content path to English as WCS use locale to identify languages
    this.contentPath = this.wcsApi?.contentPath[Locale.ENGLISH];

    let templatePath: string = this._contentHelper.getResolvedUrl(route);

    if (this._contentHelper.getRouteContent(templatePath) && !(window as Window)['enablePfCustomNavigation']) {
      return Promise.resolve(this._contentHelper.getRouteContent(templatePath));
    }

    const lindex = templatePath.lastIndexOf('/');
    templatePath = lindex > 0 ? templatePath.substring(lindex, 0) : templatePath;

    return this.getWcsData(templatePath);
  }

  private getWcsData(templatePath: string): Promise<any> {
    return this.getData(templatePath).then(
      (result: any) => {
        if (result !== undefined && result != null && result !== '') {
          return this.getAdditionalContents(result);
        }
      },
      (err: any) => {
        throw err;
      }
    );
  }

  private getData(templatePath: string): Promise<any> {
    if (this._appConfig?.wcsApi.enableLocalContent === true) {
      this.wcsApi.baseUrl = location.origin + '/assets/wcs/'; // Rewrite the baseUrl.
      return this.getWebServerContent(this.getTemplatePath(templatePath, true) + this.contentType).then(
        (result: any) => {
          if (result !== undefined && result != null && result !== '') {
            return result;
          } else {
            console.info('Web Server Template returns empty content.');
            return;
          }
        },
        (err: any) => {
          throw err;
        }
      );
    } else {
      return this.getWcsContent(this.getTemplatePath(templatePath)).then(
        (result: any) => {
          if (result !== undefined && result != null && result !== '') {
            return result;
          } else {
            console.info('WCS Api returns empty content.');
            return;
          }
        },
        (error: any) => {
          return this.getWebServerContent(this.getTemplatePath(templatePath, true) + this.contentType).then(
            (res: any) => {
              if (res !== undefined && res != null && res !== '') {
                return res;
              } else {
                console.info('Web Server Template returns empty content.');
                return;
              }
            },
            (err: any) => {
              throw err;
            }
          );
        }
      );
    }
  }

  private getAdditionalContents(wcsContent: any): Promise<any> {
    const response = [];
    for (const element of wcsContent.widgets) {
      if (element.widgetHeader.addWidgetsFromOtherRoutes && element.widgetHeader.addWidgetsFromOtherRoutes.length > 0) {
        for (const route of element.widgetHeader.addWidgetsFromOtherRoutes) {
          if (this._contentHelper.getRouteContent('/' + route)) {
            continue;
          }
          const promise = new Promise<void>((resolve, reject) => {
            resolve(
              this.getData(route).then(
                (result: any) => {
                  if (result) {
                    this._contentHelper.registerRouteContent('/' + route, result);

                    for (const widget of result.widgets) {
                      const widgetComponentName = widget.widgetHeader.dataWidgetComponentName;
                      const wcsData: any = widget.widgetContent;

                      if (!this._contentHelper.getContent(widgetComponentName) && this._contentHelper.getContent(widgetComponentName) === undefined) {
                        this._contentHelper.registerContent(widgetComponentName, wcsData);
                      }
                    }
                  }
                  resolve();
                },
                (error: any) => {
                  throw error;
                }
              )
            );
          });
          response.push(promise);
        }
      }
    }
    if (response.length > 0) {
      return Promise.all(response).then(
        (result: any) => {
          return wcsContent;
        },
        (error: any) => {
          throw error;
        }
      );
    } else {
      return wcsContent;
    }
  }

  private getTemplatePath(templatePath: string, isWebServerContent: boolean = false) {
    if (this._appConfig?.wcsApi.enableLocalContent === true || isWebServerContent) {
      this.contentPath = this._appConfig?.wcsApi.contentPath[this.locale];
    }

    templatePath = templatePath.startsWith('/') ? templatePath.substring(1, templatePath.length) : templatePath;
    templatePath = this.contentPath?.replace('{ROUTE-PATH}', templatePath);
    return templatePath;
  }

  private getWcsContent(pageRoute: string): Promise<any> {
    return this._httpClientService.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.wcsApi.baseUrl + pageRoute,
      urlParams: [
        {
          name: 'locale',
          value: this.locale,
          isQueryParam: true
        }
      ]
    });
  }

  private getWebServerContent(pageRoute: string): Promise<any> {
    return this._httpClientService.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.wcsApi.baseUrl + this.locale + '/' + pageRoute,
      urlParams: [
        {
          name: 'locale',
          value: this.locale,
          isQueryParam: true
        }
      ]
    });
  }
}
