import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CptSlidePanelModule } from '../search-slide-out/searchSlideOutModule';
import { PFSearchCostEstimateSlideOutCmp } from './pfSearchCostEstimateSlideOutCmp';
@NgModule({
    declarations: [PFSearchCostEstimateSlideOutCmp],
    exports: [PFSearchCostEstimateSlideOutCmp],
    imports: [
        CommonModule, UxSharedModule, CptSlidePanelModule
    ],
    providers: []
})
export class PFSearchCostEstimateSlideOutModule {
  static forRoot(): ModuleWithProviders<PFSearchCostEstimateSlideOutModule> {
    return {
      ngModule: PFSearchCostEstimateSlideOutModule,
      providers: []
    };
  }
 }
