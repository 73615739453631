import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtil } from '../../../app/fad/utilities/commonUtil';
import { AnpSidePanelComponent } from '../../common/components/common-alert/anpSidePanelCmp';
import { SlideoutMessageComponent } from '../../common/components/common-alert/slideoutMsgCmp';
import { ISelectedProvider } from '../../fad/provider-details/models/iSelectedProvider';
import { PROVIDER_TYPE, RETAIL_PHARMACY } from '../../fad/search-providers/values/providerSearchConstants';
import { IAdvocateDetails } from '../interfaces/iCareCircle';
import { ICareProvider, ICareProviderDetails } from '../interfaces/iCareProvider';
import { BaseComponent } from './../../common/components/base-component/baseCmp';
import { CaHmoPanelComponent } from './../../common/components/pcp/caHmoPanelCmp';
import { AssignPcpComponent } from './../../common/components/pcp/pfAssignPcpCmp';
import { ANP_ERROR_CODE, AppExternalCommands, CA_HMO_DISCLAIMER, DOCTOR_NAME, MEMBER_NAME, RIGHT, TERMINATION_DATE } from './../../common/constants/app-constants';
import { AppEvents } from './../../common/enums/appEvents';
import { IEventDetail } from './../../common/interfaces/iEventDetail';
import { ISlideoutMessage } from './../../common/interfaces/iSlideoutMsg';
import { DataHelper } from './../../common/services/dataHelper';
import { DataService } from './../../common/services/dataService';
import { EventHandler } from './../../common/services/eventHandler';
import { NavigationService } from './../../common/services/navigationService';
import { AppUtility } from './../../common/utilities/appUtil';
import { AppSession } from './../../common/values/appSession';
import { ContentHelper } from './../../common/values/contentHelper';
import { IPcpNavResponse } from './../../fad/assign-pcp/models/iMemberPcpDetails';
import { AssignPcpNavigationService } from './../../fad/assign-pcp/services/assignPcpNavigationSvc';
import { ProviderDetailsNavigationService } from './../../fad/provider-details/services/providerDetailsNavigationSvc';
import { ISpecialty } from './../../fad/search-results/interfaces/iSummaryResp';
import { CareCircleUtil } from './../utilities/careCircleUtil';
import { CareCircleAdvocateDetailsComponent } from './careCircleAdvocateDetailsCmp';

@Component({
  moduleId: module.id,
  selector: 'app-care-circle-list',
  templateUrl: '../views/careCircleListCmp.html'
})
export class CareCircleListComponent extends BaseComponent implements OnInit {

  @Input()
  providerList: Array<ICareProvider> | Array<IAdvocateDetails> = [];
  @Output()
  openDrivingDirectionModal: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onNicknameSave: EventEmitter<any> = new EventEmitter(false);
  @Output()
  onRemoveProvider: EventEmitter<any> = new EventEmitter(false);
  @Output()
  onReloadCareTeam: EventEmitter<any> = new EventEmitter(false);
  @Output()
  onScheduleAppointment: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('assignPcpModal')
  assignPcpModal: AssignPcpComponent;
  @ViewChild('advocateDetailsModal')
  advocateDetailsModal: CareCircleAdvocateDetailsComponent;
  @Output()
  onClickFounderName: EventEmitter<any> = new EventEmitter(true);
  @ViewChild('slideoutMsgCmp')
  slideoutMsgCmp: SlideoutMessageComponent;
  @ViewChild('anpSidePanelCmp') anpSidePanelCmp: AnpSidePanelComponent;
  @ViewChild('caHmoPanelCmp') caHmoPanelCmp: CaHmoPanelComponent;
  showLoader: boolean = false;
  isEmulation: boolean = false;
  selectedAdvocate: IAdvocateDetails;
  private _pfAppCommandEvent: EventEmitter<IEventDetail>
    = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    _route: ActivatedRoute,
    _router: Router,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    _navigationService: NavigationService,
    private _elementRef: ElementRef,
    private _appUtility: AppUtility,
    private _dataService: DataService,
    private _dataHelper: DataHelper,
    private _assignPcpNavSvc: AssignPcpNavigationService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _careCircleUtil?: CareCircleUtil) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'CareCircleContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appUtility.scrollToTop();

    if (this._appSession) {
      this.isEmulation = this._appSession.isEmulation;
    }
  }

  updateLabelWithProviderName(labelText, providerNm) {
    return labelText?.replace(/{PROVIDER}/gi, providerNm);
  }

  showProviderDetails(provider: any) {
    let address = {};
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.carePageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    this._dataService.setPageTitle(pageFadObj);
    this._appSession.isCareProvider = true;
    if (provider.address.latitude && provider.address.longitude &&
      (!this._appSession.searchParams.coordinates.latitude ||
        !this._appSession.searchParams.coordinates.longitude
      )) {
      this._appSession.searchParams.coordinates.latitude =
        provider.address.latitude;
      this._appSession.searchParams.coordinates.longitude =
        provider.address.longitude;
    }
    if (provider.providerTypes && provider.providerTypes.length === 1
      && provider.providerTypes[0] === PROVIDER_TYPE.PHARMACY) {
      this._appSession.searchParams.coverageTypeCode = RETAIL_PHARMACY;
      if (provider.address) {
        let provAddress = provider.address;
        address = {
          addressId: provAddress.addressId,
          city: provAddress.city,
          state: provAddress.stateCd ? provAddress.stateCd.code : '',
          postalCode: provAddress.zipCd
        }
      }
    } else {
      this._appSession.searchParams.coverageTypeCode = '';
      if (provider.address) {
        address = {
          addressId: provider.address.addressId,
        }
      }
    }
    const selectedProvider = {
      providerIdentifier: provider.providerId,
      providerTypeCodeList: provider.providerTypes,
      providerName: provider.fullNm,
      titleList: provider.titleList,
      location: {
        address: address
      },
      isShipDirectToDoor: provider.isShipDirectToDoor
    } as ISelectedProvider;
    this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
  }

  removeProvider(provider: any) {
    provider.isRemoved = true;
    this.onRemoveProvider.emit(provider);
  }

  viewClaims(provider: any) {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.details = eventDetail.message = AppExternalCommands.RCP_CLAIMS_LANDING.MSG;
    eventDetail.target = AppExternalCommands.RCP_CLAIMS_LANDING.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  onNavigateToChatBotClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.details = eventDetail.message = AppExternalCommands.RCP_CHAT_BOT.MSG;
    eventDetail.target = AppExternalCommands.RCP_CHAT_BOT.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  onCallbackRequestClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_CONTACT_US_CALL_MAIL_SECTION.MSG;
    eventDetail.target = AppExternalCommands.RCP_CONTACT_US_CALL_MAIL_SECTION.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  navigateToIdCardPage() {
    this._careCircleUtil.navigateToIdCardPage();
  }

  onNicknameSaveClick(careTeam: any) {
    careTeam.nickName = careTeam.modifiedNickname;
    careTeam.editNickname = false;
    this.onNicknameSave.emit(careTeam);
  }

  onNicknameEditClick(careTeam: ICareProvider) {
    careTeam.editNickname = !careTeam.editNickname;
    careTeam.modifiedNickname = careTeam.nickName;
    this.setCtrlFocus(this._elementRef, `#txt-provider-nick-${careTeam.id}`);
  }

  getDirection(careTeam: ICareProvider) {
    this.openDrivingDirectionModal.emit(careTeam);
  }


  // Set Ctrl Focus
  setCtrlFocus(element: ElementRef, querySelector: string, delay: number = 100) {
    setTimeout(() => {
      let _ele;
      if (element && element.nativeElement && querySelector) {
        _ele = element.nativeElement.querySelector(querySelector);
      } else if (element && element.nativeElement) {
        _ele = element.nativeElement;
      }
      if (_ele) {
        _ele.focus();
      }
    }, delay);
  }

  /** Open Side Panel or Navigate to New Assign PCP page*/
  onAssignPcpClick(direction: any, careTeam: ICareProvider) {
    const professional = this.mapProviderDetails(careTeam);
    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(professional);
    this.handleAssignPcpNavPromise(navPromise, professional, direction);
  }

  onContinueAssignPcp() {
    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(this._appSession.selectedProvider, true);
    this.handleAssignPcpNavPromise(navPromise, this._appSession.selectedProvider, RIGHT);
  }

  async handleAssignPcpNavPromise(navPromise: Promise<IPcpNavResponse>, professional: any, direction: string) {
    try {
      const resp = await navPromise;
      //Open the old Assign as PCP Flow
      if (!resp.navigatedToAssignPcpPage) {
        this.assignPcpModal.openSidePanel(direction, professional, true);
      }
    } catch (error) {
      //Handle Errors using the error.code value
      if (error.code === ANP_ERROR_CODE) {
        this.anpSidePanelCmp.openSidePanel();
      } else if (error.code === CA_HMO_DISCLAIMER) {
        this.caHmoPanelCmp.openSidePanel();
      } else {
        //Display other Error messages in UI
        const slideoutMsgContent = this.content.assignPcpCmpContent.slideoutMsgCmpContent;
        let message = this._dataHelper.getValueByKey<string>(error.code, slideoutMsgContent);
        let title: string = slideoutMsgContent['title_' + error.code] ?? slideoutMsgContent.title;
        message = message.replace(DOCTOR_NAME, professional.providerName);
        message = message.replace(TERMINATION_DATE, error.terminationDt ?? '');
        message = message.replace(MEMBER_NAME, this._appSession.getMemberName());
        const slideMessage: ISlideoutMessage = {
          title,
          close: slideoutMsgContent.close,
          message
        };
        this.slideoutMsgCmp.showMessage(slideMessage);
      }
      throw error;
    }
  }

  openRemovePCPSidePanel(direction: any, professional: any) {
    this.assignPcpModal.openSidePanel(direction, professional, true, true);
  }

  reloadCareTeam() {
    this.onReloadCareTeam.emit();
  }

  onAppointmentSchedule(data: any) {
    this.onScheduleAppointment.emit(data);
  }

  showFounderInfo(founderInfo: ICareProvider) {
    this.onClickFounderName.emit(founderInfo);
  }

  showAdvocateDetails(advocate: IAdvocateDetails) {
    this.selectedAdvocate = advocate;
    this.advocateDetailsModal.openSidePanel('right');
  }

  private mapProviderDetails(careProvider: ICareProvider): ICareProviderDetails {
    const specialties = careProvider.specialties?.map<ISpecialty>((spl) => {
      return { specialtyCode: spl.code, specialtyCodeName: spl.name };
    });

    const professional = {
      providerIdentifier: careProvider.providerId,
      providerName: careProvider.fullNm,
      titleList: careProvider.titleList,
      providerTypeCodeList: careProvider.providerTypes,
      pcpList: careProvider.pcpList,
      pcpInfoList: careProvider.pcpInfoList,
      nickName: careProvider.nickName,
      labels: careProvider.labels,
      id: careProvider.id,
      anp: careProvider.anp,
      location: {
        address: {
          addressId: careProvider.address ? careProvider.address.addressId : '',
          addressOne: careProvider.address ? careProvider.address.addressLineOne : '',
          addressTwo: (careProvider.address && careProvider.address.city ? careProvider.address.city + ', ' : '') +
            (careProvider.address && careProvider.address.stateCd ? careProvider.address.stateCd.code : '') +
            (careProvider.address && careProvider.address.zipCd ? ' ' + careProvider.address.zipCd : ''),
          phone: careProvider.phone
        }
      },
      providerCategoryCode: careProvider.providerCategoryCode,
      specialties,
      providerNetworkStatus: { typeCode: careProvider.networkTypeCodes }
    } as ICareProviderDetails;
    return professional;
  }

  getProviderName(careProvider:ICareProvider): string {
    return CommonUtil.getProviderTitles(careProvider.titleList, '', careProvider.fullNm);
  }
}
