import { Injectable } from '@angular/core';
import { ILinkProcedure, ILinkProvider, IQuickLinks } from '../../../app/fad/search-providers/interfaces/iQuickLinks';
import { FREQUENT_PROCEDURE_COUNT, INDEX_ZERO } from '../../../app/fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../app/fad/utilities/commonUtil';
import { FindCareFeature } from '../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../findcare/common/utilities/featureManagerUtil';
import { USER_IMPRESSION_DEST_ID } from '../constants/app-constants';
import { IUserImpression, payLoad } from '../interfaces/iUserImpression';
import { UserImpressionSvc } from '../services/userImpressionSvc';
import { AppSession } from '../values/appSession';

@Injectable({ providedIn: 'root' })
export class UserImpressionUtil {
  constructor(
    private _appSession: AppSession,
    private _userImpressionSvc: UserImpressionSvc
  ) {
    this._appSession;
  }

  async postLandingPageImpression(quicklink: IQuickLinks) {
    let userImpressionReq = {} as IUserImpression;
    let providerArr: payLoad[] = [];
    let procedureArr: payLoad[] = [];
    if (quicklink?.providers?.length) {
      quicklink.providers.forEach((prov: ILinkProvider) => {
        const payload = {} as payLoad;
        payload.name = encodeURI(prov.name);
        if (prov?.modelId) {
          payload.modelId = encodeURI(prov.modelId);
        }
        if (prov?.rankPercentile !== undefined && prov?.rankPercentile !== null) {
          payload.rankPercentile = prov.rankPercentile;
        }
        providerArr.push(payload);
      });
    }
    if (quicklink?.procedures?.length) {
      quicklink.procedures.slice(INDEX_ZERO, FREQUENT_PROCEDURE_COUNT.SIX).forEach((proc: ILinkProcedure) => {
        procedureArr.push(this.getProcedurePayLoad(proc));
      });
    }
    userImpressionReq.providers = providerArr;
    userImpressionReq.procedures = this._appSession.hasEYC || this._appSession.hasDentalEYC ? procedureArr : undefined;
    userImpressionReq.destinationId = USER_IMPRESSION_DEST_ID.landingPage;
    userImpressionReq.personalizedIndicator = quicklink.personalizedIndicator;
    this.callImpressionSvc(userImpressionReq);
  }

  async postShopForProcedureImpression(procedure: ILinkProcedure[], personalizedIndicator: number) {
    let userImpressionReq = {} as IUserImpression;
    let procedureArr: payLoad[] = [];
    if (procedure?.length) {
      procedure.forEach((proc: ILinkProcedure) => {
        procedureArr.push(this.getProcedurePayLoad(proc));
      });
    }
    userImpressionReq.procedures = procedureArr;
    userImpressionReq.destinationId = USER_IMPRESSION_DEST_ID.shopForProcedurePage;
    userImpressionReq.personalizedIndicator = personalizedIndicator;
    this.callImpressionSvc(userImpressionReq);
  }

  callImpressionSvc(userImpressionReq: IUserImpression) {
    if (FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_EVENT_IMPRESSION, this._appSession) && !CommonUtil.isProdEmulation(this._appSession)) {
      this._userImpressionSvc.execute(userImpressionReq);
    }
  }

  private getProcedurePayLoad(proc: ILinkProcedure): payLoad {
    const payload = {} as payLoad;
    payload.name = encodeURI(proc.procedureCode.name);
    if (proc?.modelId) {
      payload.modelId = encodeURI(proc.modelId);
    }
    if (proc?.rankPercentile !== undefined && proc?.rankPercentile !== null) {
      payload.rankPercentile = proc.rankPercentile;
    }
    return payload;
  }
}
