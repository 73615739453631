import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from './../../../services/eventHandler';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';
import { CardsHListComponent } from './cardsHListCmp';

@Component({
  moduleId: module.id,
  selector: 'app-cards-v-list',
  templateUrl: 'cardsVListCmp.html'
})
export class CardsVListComponent extends CardsHListComponent {
  constructor(route: ActivatedRoute, eventHandler: EventHandler,
    @Inject(AppSession) appSession: AppSession,
    contentHlpr: ContentHelper,

  ) {
    super(route, eventHandler, appSession, contentHlpr);
  }

}
