import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { IPublicTccMedicalSearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { IEmailResponse } from '../../../common/interfaces/iEmailResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import {
  IPublicMedicalEycProcedureCriteriaRequest,
  IPublicTccMedicalEmailRequest,
  IPublicTccMedicalOutputFlags,
  IPublicTccMedicalPrintRequest,
  IPublicTccMedicalSearchCriteria,
  IPublicTccMedicalSearchRequest
} from '../../../common/interfaces/iPublicTccMedicalRequest';
import { IPublicTccMedicalSearchResponse } from '../../../common/interfaces/iPublicTccMedicalSearchResponse';
import { PublicV1TccMedical } from '../../../common/services/publicV1TccMedicalSvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppSession } from '../../../common/values/appSession';

import { API_NAME } from '../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../../fad/utilities/searchResponseUtil';
import { IAddressSummary, IProvider, IProviderList } from '../../result/interfaces/iProvider';
import { environment } from './../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PublicTccMedicalAdapterSvc {
  private pdfPageNumber = 1;
  private pdfPageSize = environment.paginationSize.summaryPrintPage;

  constructor(
    private _appSession: AppSession,
    private _publicV1TccMedicalSvc: PublicV1TccMedical,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc
  ) { }

  async search(pageNumber: number, pageSize: number): Promise<IProviderList> {
    const searchRequest = this.transformRequest(pageNumber, pageSize);
    const response = await this._publicV1TccMedicalSvc.execute(searchRequest);
    return this.transformResponse(response);
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent }>(content: T): Promise<Buffer> {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: IPublicTccMedicalPrintRequest = {
      ...searchRequest
    };
    const endPoint = SearchRequestUtil.getApiName(API_NAME.PUBLIC_TCC_MEDICAL);
    return this._summaryPrintSvc.execute(printRequest, endPoint);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent }>(email: string, content: T): Promise<IEmailResponse> {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: IPublicTccMedicalEmailRequest = {
      ...searchRequest,
      email
    };
    const endPoint = SearchRequestUtil.getApiName(API_NAME.PUBLIC_TCC_MEDICAL);
    return this._summaryEmailSvc.execute(emailRequest, endPoint);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): IPublicTccMedicalSearchFilterRequest {
    const tccMedicalPublicSearchReq = this.transformRequest(pageNumber, pageSize);
    const filterReq: IPublicTccMedicalSearchFilterRequest = {
      ...tccMedicalPublicSearchReq,
      scenario: SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): IPublicTccMedicalSearchRequest {
    const searchParams = this._appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(this._appSession, pageNumber, pageSize);
    commonSearchRequest.plan = undefined;
    commonSearchRequest.planNames = undefined;
    const { includeDisclaimerRules, includeTaxonomy, includeReviewRatings, includeVisibilityRules, imposeAddressConsolidation, improveSearchCriteria } = commonSearchRequest.outputFlags || {};
    const outputFlags: IPublicTccMedicalOutputFlags = {
      includeColdStateProcedureSearch: true,
      includeDisclaimerRules,
      includeReviewRatings,
      includeTaxonomy,
      includeVisibilityRules,
      imposeAddressConsolidation,
      improveSearchCriteria
    };
    let returnTypeofProvider: string;
    returnTypeofProvider = CommonUtil.getProcedureProviderReturnType(searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode);

    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(this._appSession);
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const searchCriteria: IPublicTccMedicalSearchCriteria = {
      brandCode: this._appSession?.metaData?.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this._appSession),
      integratedBrandCode: this._appSession.metaData.integratedBrandCode || undefined
    };
    // binding demogrphic filters if available
    if (demographicFilter) {
      searchCriteria.demographicFilter = demographicFilter;
    }
    // binding misc filters if available
    if (miscFilter) {
      searchCriteria.miscFilter = miscFilter;
    }

    const eycProcedureCriteria: IPublicMedicalEycProcedureCriteriaRequest = {
      procedureCode: searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode,
      providerCategoryCode: searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode,
      returnTypeofProvider,
      eycPrefix: searchParams?.eycPrefix
    };
    const searchRequest: IPublicTccMedicalSearchRequest = {
      ...commonSearchRequest,
      searchCriteria,
      eycProcedureCriteria,
      memberCriteria: undefined,
      outputFlags
    };
    return searchRequest;
  }

  private transformResponse(apiResponse: IPublicTccMedicalSearchResponse) {
    let response = {} as IProviderList;
    const finalProviders: IProvider[] = [];
    if (!isEmpty(apiResponse)) {
      response.rules = apiResponse?.rules || [];
      if (apiResponse?.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            const { address } = provider.location;
            const { taxonomies } = provider.specialty;

            finalProvider.addressSummary = {
              phoneNumberList: [address.phone],
              addressIdentifier: address.addressId,
              addressOne: address.addressOne,
              addressTwo: address.addressTwo,
              cityName: address.city,
              stateCode: address.state,
              postalCode: address.postalCode,
              latitude: address.latitude,
              longitude: address.longitude,
              distance: address.distance.toString(),
              taxonomies: taxonomies.map(({ code, name }) => ({ code, name }))
            } as IAddressSummary;
            finalProviders.push(finalProvider);
          }
        });
        response = { ...apiResponse, providers: finalProviders };
      }
    }
    response.providers = finalProviders;

    return response;
  }
}
