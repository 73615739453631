import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeedBackSidePanelComponent } from '../../../../../../common/components/feedback-sidepanel/feedbackSidePanelCmp';
import { SIDE_PANEL_STATUS } from '../../../../../../common/constants/app-constants';
import { IUserInteraction } from '../../../../../../common/interfaces/iUserInteraction';
import { EventHandler } from '../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../common/values/appSession';
import { CommonUtil } from '../../../../../../fad/utilities/commonUtil';
import { IActionReasonCode } from '../../../../interfaces/iActionReasonCode';
import { BaseComponent } from '../../../core/baseCmp';
import { ActionItemLookupService } from '../services/actionItemLookupSvc';

declare let _satellite: any;
declare let window: any;

@Component({
  selector: 'app-fc-action-item-cmp',
  templateUrl: './actionItemCmp.html'
})
export class ActionItemCmp extends BaseComponent implements OnInit {
  @Input() interactionPayload: IUserInteraction;
  @Input() actionReasonCodes: IActionReasonCode;
  @Input() identifier = 'action-item-btn';
  @Input() dataAnalytics: string;
  @Input() items: Array<string>;
  @Output() changePCP: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() removeFromCareTeam: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() memberSvcChangePCP: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() bookAppointment: EventEmitter<Event> = new EventEmitter<Event>();
  @ViewChild('feedbackSidePanel') feedbackSidePanel: FeedBackSidePanelComponent;

  actionItemParentRef = this;
  isFeedbackAction = false;
  iAmInterested = this.content?.common?.hideQuickLinkShopForProcedure?.iAmInterested;
  notInterested = this.content?.common?.hideQuickLinkShopForProcedure?.notInterested;

  private _selected = false;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _elementRef: ElementRef,
    private _actionItemLookupService: ActionItemLookupService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.getItemsForReasonCodes();
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    const coercedValue = coerceBooleanProperty(value);
    if (coercedValue !== this._selected) {
      this._selected = coercedValue;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target && !this._elementRef?.nativeElement.contains(target) && target.className !== 'motif-info motif-icon motif-menu-kebab fcr-action-icon') {
      this._actionItemLookupService.destoryComponent();
    }
  }

  /**
   * Method to handle the action menu click
   * @param event Event object for the action menu click
   */
  actionMenuClick(event: Event) {
    this._selected = !this.selected;
    if (this._selected && this.identifier) {
      this._document.getElementById(this.identifier)?.blur();
    }
  }

  /**
   * Method to get the items for the reason codes
   * @returns void
   */
  getItemsForReasonCodes() {
    if (!this.actionReasonCodes) {
      this.selected = false;
      return;
    }
    const reasonCodeItems: string[] = [];
    const { positive, negative } = this.actionReasonCodes;
    if (positive?.length) {
      reasonCodeItems.push(this.iAmInterested?.name);
    }
    if (negative?.length) {
      reasonCodeItems.push(this.notInterested?.name);
    }
    this.isFeedbackAction = CommonUtil.isNonEmptyArrayOfStrings(reasonCodeItems);
    this.items = reasonCodeItems;
  }

  /**
   * Method to handle the action item click
   * @param item Selected action item from the action menu
   */
  onActionItemClick(item: string) {
    if (window?.digitalData?.page?.pageInfo) {
      window.digitalData.page.pageInfo.FindCareTiles = this.dataAnalytics;
    }
    const { removeFromCareTeam, changePCP, memberSvcChangePCP, bookAppointment } = this.content?.landing?.careTeams?.actionItemsLabel || {};
    const actions = {
      [this.iAmInterested?.name]: () => {
        this.trackAnalyticsEvent(this.iAmInterested?.dataAnalytics?.interestedOptionFindCare);
        this.feedbackSidePanel?.openSlideOutPanel(this.actionReasonCodes?.positive, true);
      },
      [this.notInterested?.name]: () => {
        this.trackAnalyticsEvent(this.notInterested?.dataAnalytics?.notInterestedOptionFindCare);
        this.feedbackSidePanel?.openSlideOutPanel(this.actionReasonCodes?.negative, false);
      },
      [removeFromCareTeam]: () => this.removeFromCareTeam.emit(),
      [changePCP]: () => this.changePCP.emit(),
      [memberSvcChangePCP]: () => this.memberSvcChangePCP.emit(),
      [bookAppointment]: () => this.bookAppointment.emit()
    };
    actions[item]?.();
  }

  /**
   * Method to get the aria label for the action item button
   * @returns String - Aria label for the action item button
   */
  getAriaLabelForActionItem(): string {
    if (this.items) {
      const ariaLabel = this.content?.common?.actionItems?.actionItemButton?.replace(/{ACTION}/gi, this.items.join(', '));
      return ariaLabel;
    }
    return '';
  }

  /**
   * Method to track the analytics event for the tag
   * @param analyticsTag Analytics tag to track
   */
  trackAnalyticsEvent(analyticsTag: string) {
    if (typeof _satellite !== 'undefined') {
      _satellite.track(analyticsTag);
    }
  }

  /**
   * Method to handle the feedback side panel status change
   * @param status value of the side panel status
   */
  onFeedbackSidePanelStatusChange(status: SIDE_PANEL_STATUS): void {
    if (this.identifier && status === SIDE_PANEL_STATUS.CLOSE) {
      const ellipsisButton = this._document.getElementById(this.identifier);
      ellipsisButton?.focus();
    }
  }
}
