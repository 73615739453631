import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICptCostInfo, PFSearchCostEstimateContent, PFSearchCostEstimateCostInfoVM, PFSearchCostEstimateSidePanelVM, PFSearchCostInfo } from '../../../common/interfaces/iCptMedicalResponse';

import { COST_ESTIMATE_ORDER, COST_SCENARIO } from '../../../fad/search-providers/enums/personalizationEnums';
import { IProviderList } from '../interfaces/iProvider';

@Injectable({ providedIn: "root" })
export class CptSummaryModel {
  private _cptResponse = new BehaviorSubject<IProviderList>(undefined);
  private _cptCostResponse= new  BehaviorSubject<PFSearchCostInfo>(undefined);

  setCptSummaryResponse(response: IProviderList) {
    this._cptResponse.next(response);
  }

  setCptCostResponse(response: PFSearchCostInfo) {
    this._cptCostResponse.next(response);
  }

  get cptSummaryResponse() {
    return this._cptResponse.asObservable();
  }

  get cptCostResponse() {
    return this._cptCostResponse.asObservable();
  }

  costEstimate(costEstimateContent: PFSearchCostEstimateContent, value: PFSearchCostInfo) {
    // create object with properties needed by UI Model
    const requiredCostInfo = {
      costInfo: {
        estimatedBill: value.costInfo.estimatedBill,
        planPays: value.costInfo.planPays,
        memberResponsibility: value.costInfo.memberResponsibility,
        appliedDeductible: value.costInfo.appliedDeductible,
        coinsurance: value.costInfo.coinsurance,
        copay: value.costInfo.copay,
      },
      placeOfService: value.placeOfService,
      modifierCode: value.modifierCode
    };

    const costEstimate: PFSearchCostEstimateCostInfoVM[] = [];
    const { costInfo, modifierCode, placeOfService } = requiredCostInfo;
      const sortedAndFilteredCostEstimate = Object.keys(costInfo)
        .sort(this.sortCostKeys)
        .reduce((accumulator, key) => {
          // if key is undefined from API then do not add it in the final UI model
          if(costInfo[key]) {
            accumulator[key] = costInfo[key];
          }
          return accumulator;
        }, {});
      Object.keys(sortedAndFilteredCostEstimate)
        .forEach((key: keyof Partial<ICptCostInfo>) => {
          const vm: PFSearchCostEstimateCostInfoVM = { ...costEstimateContent[key], value: '', id: key };
          vm.value = costInfo[key];
          costEstimate.push(vm);
      });
      const searchCostEstimateVM: PFSearchCostEstimateSidePanelVM = {
        placeOfService,
        modifierCode,
        costInfo: costEstimate
      };
      return searchCostEstimateVM;
  }

  sortCostKeys(element1, element2) {
    return COST_ESTIMATE_ORDER[element1] - COST_ESTIMATE_ORDER[element2];
  }

  costEstimateWithContent(oonCostDetails: PFSearchCostInfo, costContent: PFSearchCostEstimateContent, costContentForPercentage: PFSearchCostEstimateContent): PFSearchCostEstimateSidePanelVM {
    const content: PFSearchCostEstimateContent = oonCostDetails.costInfo?.scenario?.toLowerCase() === COST_SCENARIO.PERCENTAGE.toLowerCase() ?
      { ...costContent, ...costContentForPercentage } : { ...costContent };
    return this.costEstimate(content, oonCostDetails);
  }
}
