import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CommonDirectivesModule } from '../../../../../common/directives/commonDirectivesModule';
import { OutOfNetworkCostEstimateComponent } from './outOfNetworkCostEstimateCmp';
import { OutOfNetworkPricingComponent } from './outOfNetworkPricingTabCmp';
import { ProcServiceLimitCmp } from './procServiceLimitCmp';
import { SearchSlideOutComponent } from './searchSlideOutCmp';
import { SummaryTabComponent } from './summaryTabCmp';
@NgModule({
    declarations: [SearchSlideOutComponent, SummaryTabComponent, OutOfNetworkPricingComponent, OutOfNetworkCostEstimateComponent, ProcServiceLimitCmp],
    exports: [SearchSlideOutComponent, SummaryTabComponent, OutOfNetworkPricingComponent, OutOfNetworkCostEstimateComponent, ProcServiceLimitCmp],
    imports: [
        UxSharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CommonDirectivesModule
    ],
    providers: []
})
export class SearchSlideOutModule { }
