import { Injectable } from '@angular/core';
import { apiNameList } from '../../../environments/api-name-list';
import { ISearchRequest } from '../../fad/search-results/interfaces/iSearchRequest';
import { ISummaryAPIResp } from '../../fad/search-results/interfaces/iSummaryResp';
import { HttpMethod } from '../enums/httpMethodEnum';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';

@Injectable({
  providedIn: 'root'
})
export class DefaultPrecareSearch extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(searchRequest: ISearchRequest): Promise<ISummaryAPIResp> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.summary,
      data: searchRequest,
      headers: this.getHeaders()
    });
  }
}
