import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '../../../../../../libs/uxd-shared/uxdSharedModule';
import { FeedBackSidePanelComponent } from './feedbackSidePanelCmp';

@NgModule({
  declarations: [FeedBackSidePanelComponent],
  exports: [FeedBackSidePanelComponent],
  imports: [FormsModule, ReactiveFormsModule, UxSharedModule, CommonModule],
  providers: []
})
export class FeedbackSidePanelModule {}
