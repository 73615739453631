import { IAlert } from '@anthem/uxd/util';
import { IException } from '../interfaces/iAppExceptions';
import { NEGATIVE } from './../constants/alertTypes';
export abstract class ApiHelper<Req, Res> {

  isProgress = false;
  isSuccess = false;
  isFailure = false;

  readonly alert: IAlert = {
    alertType: NEGATIVE,
    alertContent: ''
  };

  reset() {
    this.isProgress = false;
    this.isSuccess = false;
    this.isFailure = false;
  }

  invoke(request: Req): Promise<Res> {
    this.reset();
    this.isProgress = true;

    const promise = new Promise<Res>(async (resolve, reject) => {
      try {
        const response = await this.execute(request);
        this.isProgress = false;
        this.isSuccess = true;
        this.isFailure = false;

        this.onSuccess(response, request);
        resolve(response);
      } catch (error) {
        this.delegateOnFailure(error);
        reject(error);
      }
    });

    return promise;
  }

  /**
   * Abstarct onSuccess method. Implement this method in extended classes.
   * Use this method to receive the loaded data from API.
   * Perform data manipulation and data binding.
   * @param resp
   */
  abstract onSuccess(resp: Res, req?: Req): void;

  /**
 * Abstarct onFailure method. Implement this method in extended classes.
 * Use this method to receive error returned from API.
 * Perform error handling.
 * @param errResp
 */
  abstract onFailure(errResp): void;

  /**
 * Abstarct execute method. Implement this method in extended classes.
 * Use this method to make an api call that returns a promise.
 * @param request
 */
  protected abstract execute(request: Req): Promise<Res>;

  /**
   * Extract error code from a string error message.
   * @param errMsg Example: 'ERR9001 Some unknown error happened'.
   * @returns IException {code:'ERR9001', message:'Some unknown error happened'}.
   */
  protected getException(errMsg: string): IException {
    errMsg = errMsg ?? '';
    let code = errMsg.split(' ')[0];
    let message: string;
    const errCdPattern = /\d.*/gi;
    if (errCdPattern.test(code)) {
      message = errMsg.substring(code.length + 1);
    } else {
      code = '';
      message = errMsg;
    }
    return { code, message }
  }

  protected delegateOnFailure(err) {
    this.isProgress = false;
    this.isSuccess = false;
    this.isFailure = true;

    this.onFailure(err);
  }
}
