import { Injectable } from '@angular/core';
/**
 * Re-usable Data Validation Helper class
 */
@Injectable()
export class DataHelper {

  /**
   * Get any property value from an object using a given key.
   * @param key string . Not Case Sensitive.
   * @param content any
   * @param defaultsTo any
   * @returns any
   */
  getValueByKey<Type>(key: string, content: any, defaultsTo?: Type): Type {
    let result: Type;

    for (let prop in content) {
      if (this.areEqualStrings(prop, key)) {
        result = content[prop];
        break;
      }
    }

    //When nothing found for the given key, try to return a default prop in the content obj when it is available.
    if (!result && content?.default) {
      result = content.default;
    }

    //When no default prop given in the wcs content, return defaultsTo value passed to this fn.
    if (!result) {
      result = defaultsTo;
    }

    return result;
  }

  areEqualStrings(str1: string, str2: string): boolean {
    if (str1 === undefined || str1 === null) {
      return false;
    }
    if (str2 === undefined || str2 === null) {
      return false;
    }

    str1 = this.isEmptyString(str1) ? '' : str1;
    str2 = this.isEmptyString(str2) ? '' : str2;
    return str1.toLowerCase().trim() === str2.toLowerCase().trim();
  }

  /**
   * Return true if the given value is a non-string
   * Return true if the given value is a String and its value is empty.
   * @param value
   */
  isEmptyString(value: string): boolean {
    if (typeof value === 'string' && value.trim().length > 0) {
      return false;
    }
    return true;
  }

  /**
   * Return true if the given value is an array with at least one element in it
   * @param value
   */
  isNonEmptyArray(value: Array<any>): boolean {
    return Array.isArray(value) && value.length > 0;
  }

  /**
   * Return true if the given value is an empty array
   * @param value
   */
  isEmptyArray(value: Array<any>): boolean {
    return Array.isArray(value) && value.length === 0;
  }

  /**
   * Return true if both objects are equals, else false
   * @param obj1 object
   * @param obj2 object
   * return
   */
  isEquivalent(obj1: any, obj2: any) {
    // Create arrays of property names
    const obj1Props = Object.getOwnPropertyNames(obj1);
    const obj2Props = Object.getOwnPropertyNames(obj2);

    // If number of properties is different,
    // objects are not equivalent
    if (obj1Props.length !== obj2Props.length) {
      return false;
    }

    for (let propName of obj1Props) {
      if (obj1[propName] !== obj2[propName]) {
        return false;
      }
    }
    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  /**
   * Return true if both nested objects are equal, else false
   * @param object1 Object
   * @param object2 Object
   */
  deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObjectIncludesArray(val1) && this.isObjectIncludesArray(val2);
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }

    return true;
  }

  /**
   * Return true if both string or both number array are equals
   * @param arrA string/number
   * @param arrB string/number
   * return
   */
  areEqualArrays(arrA: Array<any>, arrB: Array<any>) {
    //check if lengths are different
    if (arrA.length !== arrB.length) {
      return false;
    }

    //slice so we do not effect the original
    //sort makes sure they are in order
    //join makes it a string so we can do a string compare
    const cA = arrA
      .slice()
      .sort()
      .join(',');
    const cB = arrB
      .slice()
      .sort()
      .join(',');

    return cA === cB;
  }

  /**
   * Return true if the given value is an object.
   * NOTE: This method will not consider Array as an object.
   * @param value
   */
  isObject(value: any) {
    return value !== null && !Array.isArray(value) && typeof value === 'object';
  }

  /**
   * Return true if the given value is an empty object.
   * @param value
   */
  isEmptyObject(value: any): boolean {
    return value && Object.values(value).every(val => val === null || val === '' || val === false || val === true);
  }

  /**
   * Return true if the given value is an object.
   * @param value
   */
  isObjectIncludesArray(value: any) {
    return value !== null && typeof value === 'object';
  }

  /**
   * Returns true if the given value is 'true' or 1 or '1' or 'yes' or true
   * Otherwise returns false
   * @param val any
   */
  isTrue(val: any) {
    if (typeof val === 'number' && val === 1) {
      return true;
    } else if (!this.isEmptyString(val as string)) {
      const strVal = (val as string).trim().toLowerCase();
      return ['true', 'yes', '1'].includes(strVal);
    } else if (val === true) {
      return true;
    }
    return false;
  }
  /**
   * Returns a new array consisting of only unique elements from the given input array
   * @param ipArray
   * @param fieldName
   * @param ignoreCase
   */
  getUniqueElements(ipArray: Array<any>, fieldName: string = '', ignoreCase = true) {
    let r: any[] = [];
    if (!Array.isArray(ipArray)) {
      return r;
    }
    r = [...ipArray];
    if (typeof fieldName === 'string' && fieldName.trim() !== '') {
      //Filter array of objects
      for (let i = 0; i < r.length; i++) {
        const obj1: any = r[i];
        if (obj1 === undefined || obj1 === null) {
          continue;
        }
        let val1 = this.getDeepValue(obj1, fieldName);
        if (val1 === undefined) {
          continue;
        }
        for (let j = i + 1; j < r.length; j++) {
          const obj2: any = r[j];
          if (!obj2) {
            continue;
          }
          let val2 = this.getDeepValue(obj2, fieldName);
          if (val2 === undefined) {
            continue;
          }
          val1 = val1 === null || val1 === undefined ? '' : val1;
          val2 = val2 === null || val2 === undefined ? '' : val2;
          //When field value is a string data
          if (ignoreCase && typeof val1 === 'string' && typeof val2 === 'string') {
            if (val1.trim().toLowerCase() === val2.trim().toLowerCase()) {
              r.splice(j, 1);
              j--;
            }
          } else {
            //When field value is a non-string data
            if (val1 === val2) {
              r.splice(j, 1);
              j--;
            }
          }
        }
      }
    } else {
      //Filter array of primary data types
      for (let i = 0; i < r.length; i++) {
        for (let j = i + 1; j < r.length; j++) {
          const val1 = r[i];
          const val2 = r[j];
          if (ignoreCase && typeof val1 === 'string' && typeof val2 === 'string') {
            if (val1.trim().toLowerCase() === val2.trim().toLowerCase()) {
              r.splice(j, 1);
              j--;
            }
          } else {
            if (val1 === val2) {
              r.splice(j, 1);
              j--;
            }
          }
        }
      }
    }
    return r;
  }

  getDeepValue(obj: any, fieldName: string): any {
    if (obj === null || obj === undefined || typeof obj !== 'object') {
      return obj;
    }
    if (typeof fieldName !== 'string') {
      return obj;
    }
    const i: number = fieldName.indexOf('.');
    if (i > -1) {
      return this.getDeepValue(obj[fieldName.substr(0, i)], fieldName.substr(i + 1));
    }
    return obj[fieldName];
  }

  /**
   * Determines if the phone number string of 000-000-0000 has
   * proper values for area code and phone number separated by '-' char
   * @param phoneNum string value for phone numbers that are text input
   */
  isUSAPhonePattern(phoneNum: string): boolean {
    // if parsed phone number does not follow 000-000-0000 format, return false
    if (!/^\d{3}\-\d{3}\-\d{4}$/.test(phoneNum)) {
      return false;
    }
    return true;
  }

  isValidPhoneNumber(phoneNbr: string): boolean {
    //When: phoneNbr is empty, null, undefined return false
    //When: phoneNbr is N/A return false
    //When: phoneNbr is "1-null" return false
    //
    phoneNbr = this.isEmptyString(phoneNbr) ? '' : phoneNbr.trim();
    if (phoneNbr === '911') {
      return true;
    }
    const regx: RegExp = /^[\d|\+|\(]{0,1}[\){0,1}\d\+{0,1}\-{0,1} {0,1}]{6,13}[\d{1}]$/gi;
    return regx.test(phoneNbr);
  }

  /**
   * Determines if given email id is valid and return true or false
   * @param email
   * @returns
   */
  isValidEmail(email: string): boolean {
    return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,63}(?:\.[a-zA-Z]{2})?)$/i.test(email) ? true : false;
  }

  /**
 * return the unique list for given key from collecton
 * @param {Array<Object>} collection The collection to iterate over.
 * @param {string} string unique key string
 * @returns {Array} Returns the unique values.
 */
  getUniqueValues<T>(collection: Array<T>, fieldName: string): Array<string> {
    const uniqueValues: Set<string> = new Set<string>();
    if (Array.isArray(collection) && !this.isEmptyString(fieldName))
      collection.forEach(
        (obj) => obj && obj[fieldName] && uniqueValues.add(obj[fieldName])
      );
    return Array.from(uniqueValues);
  }

  /**
   * return the unique Map for given collection as per the field
   * @param {Array<Object>} collection The collection to iterate over.
   * @param {string} key unique key string
   * @returns {Map<string, Object>} Returns the unique values.
  */
  groupByKey<T>(collection: Array<T>, key: string): Map<string, Array<T>> {
    const uniqueMap: Map<string, Array<T>> = new Map<string, Array<T>>();
    Array.isArray(collection) && collection.forEach((obj) => {
      if (obj && obj[key] && !uniqueMap.has(obj[key])) {
        uniqueMap.set(obj[key], [obj]);
      } else if(obj && obj[key]){
        const values = uniqueMap.get(obj[key]);
        uniqueMap.set(obj[key], [...values, obj]);
      }
    });
    return uniqueMap;
  }

  /**
   * return the new object with reqiest fields or return empty obj
   * @param {Object} object The source object.
   * @param {Array<string>} subProps The property paths to pick.
   * @returns {Object} Returns the new Object.
  */
  pick(object: object, subProps: Array<string>): {} {
      const picked = {};
      if (this.isEmptyObject(object)) {
        return picked;
      }
      (subProps || []).forEach((prop) => {
        picked[prop] = prop in object ? object[prop] : undefined;
      });
      return picked;
    }

  /**
   * return the unique object from the given array as per props
   * @param {Array<Object>} collection The collection to iterate over.
   * @param {Array<string>} props The props to filter.
   * @returns {Array<Object>} Returns unique array object.
  */
  uniqueObjectBy<T>(collection: Array<T>, props?: Array<string>): Array<T> {
    const finalCollection: Array<T> = [];
    collection?.forEach((current) => {
      const isMatched = finalCollection.some((obj) => {
        if (this.isNonEmptyArray(props)) {
          return props.every((propName) =>
            obj[propName] === current[propName]
          );
        } else {
          return Object.keys(current).every((propName) =>
            obj[propName] === current[propName]
          );
        }
      });
      if (!isMatched) {
        finalCollection.push(current);
      }
    });
    return finalCollection;
  }
}
