import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { visionDiscountServiceCode } from '../values/providerDetailsConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-fad-office-service-cmp',
  templateUrl: '../views/pfOfficeServiceCmp.html'
})
export class PFOfficeServiceComponent extends BaseComponent implements OnInit {
  @Input()
  officeService?: any;
  @Input()
  visionService?: Array<IBaseCode>

  constructor(
              @Inject(AppSession)
              private _appSession: AppSession,
              private _eventHandler: EventHandler,
              private _route: ActivatedRoute,
              @Inject(ContentHelper)
              private _contentHelper: ContentHelper) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  isServiceAvailableDiscountCode(visionServiceCode: String): boolean {
    return (visionServiceCode?.toUpperCase() === visionDiscountServiceCode);
  }
}
