import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { ContentHelper } from '../../../values/contentHelper';
import { BaseComponent } from '../../base-component/baseCmp';
import { PillItemLookupService } from '../services/pillItemLookupService';
import { PillItemCmp } from './pillItemCmp';

@Component({
  selector: 'app-pill-list2',
  templateUrl: './pillListCmp2.html'
})
export class PillListCmp2 extends BaseComponent implements OnInit {
  _selectionModel: SelectionModel<PillItemCmp>;
  get selected(): PillItemCmp[] | PillItemCmp {
    return this._selectionModel.selected;
  }
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _elementRef: ElementRef,
    private _pillItemLookupService: PillItemLookupService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (typeof this._elementRef.nativeElement !== 'undefined' && this._elementRef.nativeElement !== null &&
      typeof event !== 'undefined' && event !== null &&
      typeof event.target !== 'undefined' && event.target !== null &&
      !this._elementRef.nativeElement.contains(event.target) &&
      event.target.className !== 'tooltip-remove-icon fa fa-remove' &&
      (event.target.firstChild && event.target.firstChild.className !== 'tooltip-remove-icon fa fa-remove')) {
      this._pillItemLookupService.destoryComponent();
    }
  }
}
