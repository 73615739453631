import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { AppSession } from '../../values/appSession';
import { AppSSOFormComponent } from './components/appSsoFormCmp';
import { SsoLinkComponent } from './components/ssoLinkCmp';
import { SsoLinkDirective } from './directives/ssoLinkDir';
@NgModule({
    imports: [CommonModule, UxSharedModule],
    declarations: [SsoLinkComponent, AppSSOFormComponent, SsoLinkDirective],
    exports: [SsoLinkComponent, AppSSOFormComponent, SsoLinkDirective]
})
export class SsoModule {
  static forRoot(): ModuleWithProviders<SsoModule> {
    return {
      ngModule: SsoModule,
      providers: [AppSession]
    };
  }
}
