import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { FeedbackSidePanelModule } from '../feedback-sidepanel/feedbackSidePanelModule';
import { ActionItemCmp } from './components/actionItemCmp';
import { ActionItemLookupComponent } from './components/actionItemLookupCmp';
import { ActionItemLookupDirective } from './directives/actionItemLookupDirective';

@NgModule({
    declarations: [ActionItemCmp, ActionItemLookupDirective, ActionItemLookupComponent],
    exports: [
        ActionItemCmp,
        ActionItemLookupDirective,
        ActionItemLookupComponent
    ],
    imports: [
        CommonModule,
        ActionableHeaderModule,
        FeedbackSidePanelModule
    ]
})
export class ActionItemModule { }
