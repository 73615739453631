import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { HIGH_RANGE_AGE, LOW_RANGE_AGE } from '../../constants/compare';
import { ICompareProvider, IProviderDetails } from '../../interfaces/iCompareProvider';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-compare-cmp',
  templateUrl: './providerCompareCmp.html'
})
export class ProviderCompareComponent extends BaseComponent implements OnInit {
  compareProvidersContent = this.content?.result?.compareProvidersComponent;
  providerDetailsContent: IProviderDetails[];
  @Output() removeSelectedProvider: EventEmitter<IProvider> = new EventEmitter();
  @ViewChild('compareModal') compareModal: ModalComponent;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    route: ActivatedRoute,
    private _changeDetector: ChangeDetectorRef
  ) {
    super(route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngAfterViewInit() {
    this._changeDetector.detectChanges();
  }

  /** Function to open the modal */
  showModal(data): void {
    this.providerDetailsContent = data;
    this.compareModal.show();
  }

  /** Function to add another provider to the compare modal */
  addAnother(): void {
    this.closeModal();
  }

  /** Function to remove the provider from the modal */
  removeProvider(index: number, provider) {
    this.removeSelectedProvider.emit(provider);
    this.providerDetailsContent.splice(index, 1);
  }

  /** Function to close the modal */
  closeModal(): void {
    this.compareModal.hide();
  }

  /** Function to print data in the modal */
  onPrint(): void {
    setTimeout(() => {
      window.print();
    }, 0);
  }

  /** Function to get provider address text */
  getProviderAddress(address) {
    let addressText = '';
    let distance = '';
    addressText += address.addressOne + ' ' + address.addressTwo;
    distance = address.distance ? parseFloat(address.distance).toFixed(2) : '';
    if (distance) {
      return addressText + ' (' + distance + this.compareProvidersContent?.labels?.miles + ')';
    } else {
      return addressText;
    }
  }

  /** Functions to get provider name text */
  getProviderName(provider: ICompareProvider): string {
    return this.getProviderTitles(provider?.name?.titleList, provider?.networkRegisteredName, provider?.name?.providerName);
  }

  getProviderTitles(titleList: Array<string>, networkRegisteredName: string, providerName: string): string {
    const result = titleList?.slice(0, 4).join(', ');
    const remainingCount = titleList?.length - 4;
    return networkRegisteredName ? networkRegisteredName : providerName + (result ? `, ${result}${remainingCount > 0 ? `, +${remainingCount}` : ''}` : '');
  }

  /** Function to get gender preference text */
  getGenderPreference(genderPreferences: string): string {
    let genderPreference = '';
    if (typeof genderPreferences !== 'undefined' && genderPreferences !== null && genderPreferences.trim() !== '') {
      switch (genderPreferences.toUpperCase()) {
        case this.compareProvidersContent?.labels?.male:
        case this.compareProvidersContent?.labels?.maleTxt:
          genderPreference = this.compareProvidersContent?.labels?.maleOnlyText;
          break;
        case this.compareProvidersContent?.labels?.female:
        case this.compareProvidersContent?.labels?.femaleTxt:
          genderPreference = this.compareProvidersContent?.labels?.femaleOnlyText;
          break;
        case this.compareProvidersContent?.labels?.both:
        case this.compareProvidersContent?.labels?.bothTxt:
          genderPreference = this.compareProvidersContent?.labels?.bothText;
          break;
      }
    }
    return genderPreference;
  }

  /** Function to get IHSP text */
  getIhspPreference(ihsp: boolean): string {
    return ihsp ? this.compareProvidersContent?.labels?.yesText : this.compareProvidersContent?.labels?.noText;
  }

  /** Function to get Network status text */
  getNetworkStatusText(codes: Array<string>) {
    let networkStatusText = '';
    codes.forEach((code) => {
      switch (code) {
        case this.compareProvidersContent?.labels?.medical:
          networkStatusText += this.compareProvidersContent?.labels?.medicalNetwork + ', ';
          break;
        case this.compareProvidersContent?.labels?.dental:
          networkStatusText += this.compareProvidersContent?.labels?.dentalNetwork + ', ';
          break;
        case this.compareProvidersContent?.labels?.vision:
          networkStatusText += this.compareProvidersContent?.labels?.visionNetwork + ', ';
          break;
      }
    });
    networkStatusText = networkStatusText.slice(0, -2);
    return networkStatusText;
  }

  /** Function to get Age preference text */
  getAgePreference(lowRange: string, highRange: string): string {
    let agePreference = '';
    const lowRangeAge = lowRange !== undefined && lowRange !== '' ? Number(lowRange) : LOW_RANGE_AGE;
    const highRangeAge = highRange !== undefined && highRange !== '' ? Number(highRange) : LOW_RANGE_AGE;
    if (lowRangeAge > highRangeAge) {
      return agePreference;
    }
    if (lowRangeAge > LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
      agePreference = lowRangeAge.toString() + '-' + highRangeAge.toString() + this.compareProvidersContent?.labels?.yearsText;
    } else if (lowRangeAge > LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
      agePreference = lowRangeAge.toString() + ' ' + this.compareProvidersContent?.labels?.yearOlderText;
    } else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
      agePreference = this.compareProvidersContent?.labels?.birthToText + ' ' + highRangeAge.toString() + ' ' + this.compareProvidersContent?.labels?.yearsText;
    } else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
      agePreference = this.compareProvidersContent?.labels?.birthOlderText;
    }
    return agePreference;
  }

  /** Functiton toget speciality text */
  getSpecialtyText(specialty): string {
    let value = '';
    if (specialty && specialty.specialtyCodeName) {
      value = specialty.specialtyCodeName;
      if (specialty.boardCertificationCode) {
        switch (specialty.boardCertificationCode.toUpperCase()) {
          case this.compareProvidersContent?.labels?.boardCertified:
            value += this.compareProvidersContent?.labels?.boardCertifiedMsg; break;
          case this.compareProvidersContent?.labels?.notBoardCertified:
            value += this.compareProvidersContent?.labels?.notBoardCertifiedMsg; break;
          case this.compareProvidersContent?.labels?.naBoardCertified:
            value += this.compareProvidersContent?.labels?.naBoardCertifiedMsg; break;
        }
      }
    }
    return value;
  }
}
