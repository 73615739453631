import { Inject, Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { IPageAlert, IScenarioAlertRequest } from "../../../../common/interfaces/iScenarioAlert";
import { ProviderUtilityService } from "../../../../fad/search-providers/services/providerUtilitySvc";
import { AppSession } from "../../../values/appSession";
import { IScenarioAlertResponse } from './../../../interfaces/iScenarioAlert';
import { DataHelper } from './../../../services/dataHelper';

@Injectable({ providedIn: 'root' })
export class ScenarioAlertSvc {
  private _scenarioAlertList = new Subject<IPageAlert[]>();

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _dataHelper: DataHelper,
    private _providerUtilityService: ProviderUtilityService,
  ) {}

  setScenarioAlertList(list: IPageAlert[]) {
    this._scenarioAlertList.next(list);
  }

  get onScenarioAlertListChange() {
    return this._scenarioAlertList.asObservable();
  }

  async getScenarioBasedAlert(req: IScenarioAlertRequest) {
    const scenarioAlertResp :IScenarioAlertResponse = await this._providerUtilityService.getScenarioBasedAlert(req);
    this._appSession.scenarioAlerts = Array.isArray(scenarioAlertResp?.pageAlerts) ? scenarioAlertResp.pageAlerts : [];
    this.setScenarioAlertList(this._appSession.scenarioAlerts);
  }

  addScenarioAlertList(pageAlerts :Array<IPageAlert>) {
    if (Array.isArray(this._appSession.scenarioAlerts) && Array.isArray(pageAlerts)) {
      pageAlerts.forEach((pageAlert) => {
        if (!this._appSession.scenarioAlerts.some((message) => this._dataHelper.areEqualStrings(pageAlert.id, message.id))) {
          this._appSession.scenarioAlerts.unshift(pageAlert);
        }
      });
      this.setScenarioAlertList(this._appSession.scenarioAlerts);
    }
  }
}
