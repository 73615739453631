import { ISearchResult } from './../interfaces/iSearchResult';
import { AlphabetResultItem } from './alphabetResultItem';
import { Paginator } from './paginator';
import { ProcedureGroup } from './procedureGroup';

export class SearchByAlphabetsResult implements ISearchResult {
  query: string;
  title: string;
  paginator: Paginator = new Paginator();
  noResultsMessage: string;
  paginatedItems: Array<ProcedureGroup>;

  private _alphabetItems: Array<AlphabetResultItem> = [];
  private _allItems: Array<ProcedureGroup> = [];
  private _size: number = 0;

  add(alphabet: string, g: Array<ProcedureGroup>) {
    const a: AlphabetResultItem = new AlphabetResultItem();
    a.alphabet = alphabet;
    a.items = g ? g : [];
    this._alphabetItems.push(a);
    this._allItems.push(...g);
    this.paginator.totalItems = this.allItems.length;
  }

  get isResultsFound(): boolean {
    return this._allItems.length > 0;
  }

  get size(): number {
    return this._allItems.length;
  }

  get allItems(): Array<ProcedureGroup> {
    return this._allItems;
  }

  setToFirstPage(): void {
    this.paginator.first();
    this.updatePaginatedItems();
  }

  loadMore(): void {
    this.paginator.next();
    this.updatePaginatedItems();
  }

  clear() {
    this.query = '';
    this.title = '';
    this.noResultsMessage = '';
    this._alphabetItems.length = 0;
    this._allItems.length = 0;
    this.paginator.totalItems = 0;
  }

  private updatePaginatedItems() {
    this.paginatedItems = null;
    this.paginatedItems = [];

    const count: number = this.paginator.endIndex + 1;
    const r: Array<ProcedureGroup> = [];

    for (let i: number = 0; i < this._alphabetItems.length; i++) {
      const alphabetItem: AlphabetResultItem = this._alphabetItems[i];
      for (let j: number = 0; j < alphabetItem.items.length; j++) {
          r.push(alphabetItem.items[j]);
      }
    }

    this.paginatedItems = r;
  }
}
