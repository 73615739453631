import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IDropdownItem } from '@anthem/uxd/util';
import { Subscription } from 'rxjs';
import { EMPTY_SPACE } from '../../../fad/search-providers/values/providerSearchConstants';
import { WcsUtil } from '../../../fad/utilities/wcsUtil';
import { AppExternalCommands, DEEPLINK_ACTION } from '../../constants/app-constants';
import { AppNavigations } from '../../constants/app-navigations';
import { AppEvents } from '../../enums/appEvents';
import { IOptions } from '../../interfaces/iAppMetadata';
import { IEventDetail } from '../../interfaces/iEventDetail';
import { IAlertMessage } from '../../interfaces/iMessage';
import { IPCPInfo, IPCPRadioOption, IPcpReasonCodeContent } from '../../interfaces/iPCPInfo';
import { EventHandler } from '../../services/eventHandler';
import { NavigationService } from '../../services/navigationService';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { ChangePlanService } from '../change-plan/services/changePlanSvc';
import { ICareProviderDetails, IProvider, IProviderRequest } from './../../../care-circle/interfaces/iCareProvider';
import { PhonePatternPipe } from './../../../common/pipes/phonePatternPipe';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { IPCPRadioSideOptions, IPCPResponse } from './../../interfaces/iPcpDetails';
import { IPcpRequest } from './../../interfaces/iPcpRequest';
import { DataHelper } from './../../services/dataHelper';
import { PCPService } from './../../services/pcpSvc';
import { AssignPcpService } from './pfAssignPcpSvc';

declare let window: any;
declare let _satellite: any;
@Component({
  moduleId: module.id,
  selector: 'app-assign-pcp',
  templateUrl: './pfAssignPcpCmp.html'
})
export class AssignPcpComponent extends BaseComponent implements OnInit {
  @Output()
  refreshContainer: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('assignPcpModal')
  assignPcpModal: TemplateRef<HTMLElement>;
  @ViewChild('postPCPForm')
  postPCPForm: ElementRef<HTMLFormElement>;
  sidePanelRef: ModalRef<any, any>;
  pcpRadioSideOptions: Array<IPCPRadioSideOptions>;
  labelPCPRadioSideOptions: Array<IPCPRadioSideOptions>;
  changePcpDropDown: IDropdownItem[] = [];
  changeReasonPcp: string = '';
  pcpId: string = '';
  monoPCPInfo: IPCPRadioOption;
  professional: any;
  errorText: string = '';
  changePCP: boolean = true;
  pcpList: IPCPRadioOption[] = [];
  selectedMbrError: boolean = false;
  selectedPcpIdError: boolean = false;
  selectedReasonPcpError: boolean = false;
  assignPcpErrorMsg: boolean = false;
  selectedMbr: IOptions;
  mbrIdEmptyList: any;
  assignPcpShowPageProgress: boolean = false;
  showSucessAssignPCPMsg: boolean = false;
  _assignPCPSuccessText: string = '';
  isEmulation: boolean = false;
  pcpPageProgress: boolean = false;
  isRemovePCP: boolean = false;
  showCompletePCP: boolean = false;
  showApiErrCd: boolean = false;
  refreshParent: boolean = false;
  multiplePCP: boolean = false;
  selectedProvider: ICareProviderDetails;
  apiErrorCd: string = '';
  private changePlanSaveClickSubscription: Subscription;
  private _pfAppCommandEvent: EventEmitter<
    IEventDetail
  > = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  constructor(
    private _pcpSvc: PCPService,
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _contentHelper: ContentHelper,
    route: ActivatedRoute,
    private _sidePanel: SidePanel,
    private _phonePatternPipe: PhonePatternPipe,
    private _titlecasePipe: TitleCasePipe,
    private _navigationService: NavigationService,
    private _changePlanService: ChangePlanService,
    private _dataHelper: DataHelper,
    private assignPcpService: AssignPcpService
  ) {
    super(
      route,
      _eventHandler,
      _appSession,
      _contentHelper,
      'PFSearchContainerComponent'
    );
    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick
      .subscribe((path: AppNavigations) => {
        if ((path === AppNavigations.CARE_TEAM_PATH) || (path === AppNavigations.CARE_CIRCLE_PATH) || this._contentHelper.isSearchResultRoute(path)) {
          this.getPcpMemberInfo(true);
        }
      });
  }
  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    if (this._appSession && CommonUtil.isMemberSecure(this._appSession) && this._appSession.metaData
      && this._appSession.metaData.appContract
      && this._appSession.metaData.appContract.memberList
      && this._appSession.metaData.appContract.memberList.length > 0) {
      /* To get the emulated member value. if return true then disable the assign pcp button
      */
      this.isEmulation = this._appSession.isEmulation;
      this.getPcpMemberInfo(true);
    }
  }

  onRadioChangePCP(mbr: any) {
    this.selectedMbr = this.getSelectedMember(mbr.value);
    this.changePCP = true;
    this.errorText = this._assignPCPSuccessText = '';
    this.selectedMbrError = this.selectedReasonPcpError = this.selectedPcpIdError =
      this.showSucessAssignPCPMsg = this.assignPcpErrorMsg = false;
  }

  buildPCP(result: string[]) {
    if (!Array.isArray(result) || result.length === 0) {
      return;
    }
    const radioItems: IPCPRadioOption[] = [];
    result.forEach((value) => {
      const label = `<strong>${this.content.commonContents.labels.lblPcpID} ${value}</strong>`;
      radioItems.push({
        label,
        value,
        id: 'rdb-pcp-id-' + value,
        name: 'rdb-pcp-name-' + value,
        affiliationNames: [],
        ariaLabel: this.content.commonContents.labels.lblPcpID + value
      });
    });
    this.pcpList = radioItems;
    this.multiplePCP = this.pcpList.length > 1;
    this.monoPCPInfo = this.pcpList[0];
    this.pcpId = this.pcpList[0].value;
  }

  buildPCPInfoList(pcpInfoList: IPCPInfo[]) {
    if (!Array.isArray(pcpInfoList)) {
      return;
    }
    if (pcpInfoList.length === 0) {
      return;
    }

    const uniquePCPMap: { [id: string]: IPCPRadioOption } = {};
    const uniqueNamesMap: { [id: string]: Set<string> } = {};
    const radioItems: IPCPRadioOption[] = [];

    pcpInfoList.forEach((pcpInfo) => {
      if (!pcpInfo || !pcpInfo.id) {
        return;
      }

      let radioItem = uniquePCPMap[pcpInfo.id];
      let namesSet = uniqueNamesMap[pcpInfo.id];
      if (!radioItem) {
        let label = `<strong>${this.content.commonContents.labels.lblPcpID} ${pcpInfo.id}</strong>`;
        const value = pcpInfo.id;
        radioItem = {
          label,
          value,
          id: 'rdb-pcp-id-' + value,
          name: 'rdb-pcp-name-' + value,
          affiliationNames: null,
          ariaLabel: ''
        };
        uniquePCPMap[pcpInfo.id] = radioItem;
        uniqueNamesMap[pcpInfo.id] = namesSet = new Set<string>();
        radioItems.push(radioItem);
      }
      if (!this._dataHelper.isEmptyString(pcpInfo.affiliationName)) {
        namesSet.add(pcpInfo.affiliationName);
      }
      radioItem.affiliationNames = Array.from(namesSet);
      radioItem.ariaLabel = this.content.commonContents.labels.lblPcpID + ' ' + radioItem.value + ' ' + radioItem.affiliationNames;
    });

    this.pcpList = radioItems;
    this.multiplePCP = this.pcpList.length > 1;
    this.monoPCPInfo = this.pcpList[0];
    this.pcpId = this.pcpList[0].value;
  }

  getPcpSourceSystem(key: string) {
    if (!key) {
      throw new Error(('Empty/invalid source system'));
    }

    switch (key.toLocaleUpperCase()) {
      case 'NASCO':
        return 'NASCO';
      case 'CS90':
        return 'CS90';
      case 'MWMEDSYS':
        return 'MWMEDSYS';
      default:
        return 'ALL';
    }
  }

  /** Open Side Panel */
  openSidePanel(direction: any, professional: any, isResultsPage: boolean, removePCP: boolean = false) {
    this.showCompletePCP = false;
    this.isRemovePCP = removePCP;
    this.professional = professional;
    this.setReasonCodes();
    if (!removePCP) {
      if (isResultsPage) {
        if (professional?.pcpInfoList) {
          this.buildPCPInfoList(professional.pcpInfoList);
        } else {
          this.buildPCP(professional.pcpList);
        }
      } else {
        if (professional?.pcpInfoList) {
          this.buildPCPInfoList(professional.pcpInfoList);
        } else {
          this.buildPCP(professional.pcp?.identifier);
        }
      }
    }

    this.sidePanelRef = this._sidePanel.open(direction, this.assignPcpModal);
  }

  setReasonCodes() {
    const providerCategoryCd: string = this.professional.providerCategoryCode?.code ?? '';
    const reasonCodes: IDropdownItem[] = [];
    const reasons = this._dataHelper.getValueByKey<IPcpReasonCodeContent[]>(this._appSession.sourceSystem, this.content.commonContents.pcpReasonsList, this.content.commonContents.pcpReasonsList.ALL);

    reasons.forEach((reason) => {
      const isExcluded = reason.excludeCategoryCodes?.some((provCatCode) => {
        return this._dataHelper.areEqualStrings(provCatCode, providerCategoryCd);
      });
      if (!isExcluded) {
        reasonCodes.push({ label: reason.reason, value: reason.value });
      }
    });

    this.changePcpDropDown = reasonCodes;
  }

  getModalContentANP(content: string) {
    return content.replace(/{providerName}/gi, this.professional.providerName);
  }

  onNewPatientsClick() {
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    this._appSession.isOnlyAnpProvider = true;
    this.assignPcpService.isAnpProvider(true);
    this.sidePanelRef.close()
  }

  /** Event handler for click of close button  */
  onClose() {
    this.changeReasonPcp = this.pcpId = this.errorText = this._assignPCPSuccessText = '';
    this.pcpList = null;
    this.isRemovePCP = false;
    this.showApiErrCd = false;
    this.showCompletePCP = false;
    this.selectedMbrError = this.selectedReasonPcpError = this.showSucessAssignPCPMsg = this.selectedPcpIdError
      = this.assignPcpErrorMsg = this.assignPcpShowPageProgress = false;

    this.selectedMbr = this.getSelectedMember('');
    /* To get the emulated member value. if return true then disable the assign pcp button
      */
    this.isEmulation = this._appSession.isEmulation;
    if (this.refreshParent) {
      this.refreshContainer.emit();
    }
    this.sidePanelRef.close();
  }
  /** Event handler for click of Assign PCP button  */
  onAssignPCP() {
    //To post back the PCP info to client requesting using Deeplink.
    if (this.isPCPDeepLink) {
      this.postPCPInfo();
      return;
    }

    if (this.selectedMbr === null) {
      this.selectedMbrError = true;
      this.errorText = this.content.commonContents.alerts.lblErrorMbrMsg;
    } else if (this.pcpId === '') {
      this.selectedPcpIdError = true;
      this.selectedMbrError = false;
      this.errorText = this.content.commonContents.alerts.lblErrorPcpMsg;
    } else if (this.changeReasonPcp === '') {
      this.selectedReasonPcpError = true;
      this.selectedMbrError = this.selectedPcpIdError = false;
      this.errorText = this.content.commonContents.alerts.lblErrorChangeReasonPcpMsg;
    } else {
      this.selectedMbrError = this.selectedReasonPcpError = this.showSucessAssignPCPMsg =
        this.assignPcpErrorMsg = this.selectedPcpIdError = false;
      this.errorText = this._assignPCPSuccessText = '';
      this.assignPcpShowPageProgress = true;
      if (window && window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo
      ) {
        window.digitalData.page.pageInfo.changePcpCode = this.changeReasonPcp;
      }
      const pcpReq: IPcpRequest = {
        contractUid: this._appSession.metaData.appContract.contractUid,
        pcpInfo: {
          pcpId: this.pcpId,
          pcpTrmntnRsnCd: this.changeReasonPcp
        },
        selMbrUid: this.selectedMbr.mbrUid,
        providerName: this.professional.providerName
      };

      this._pcpSvc.modifyPCP(pcpReq).then(
        (result: any) => {
          this.showSucessAssignPCPMsg = true;
          this.assignPcpShowPageProgress = false;
          const SUCCESS_PCP_INFO_CONTENT: Array<IAlertMessage> = this._dataHelper.isEmptyString(result?.pcpEffectiveDt) ?
            this.content?.commonContents?.pcpSuccessInfo?.updatePCPWithOutEffectiveDate : this.content?.commonContents?.pcpSuccessInfo?.updatePCPWithEffectiveDate;
          if (SUCCESS_PCP_INFO_CONTENT && SUCCESS_PCP_INFO_CONTENT.length > 0) {
            let pcpAlertMsg: string = '';
            const doctorName = this.professional.providerName.toUpperCase();
            const memberName = this.titleCase(this.selectedMbr.firstName) + EMPTY_SPACE + this.titleCase(this.selectedMbr.lastName);
            const effectiveDate = this.titleCase(result?.pcpEffectiveDt);

            const wcsConstructedContent: string = WcsUtil.getInfoListContent(SUCCESS_PCP_INFO_CONTENT);
            if (wcsConstructedContent && wcsConstructedContent.length > 0) {
              pcpAlertMsg = wcsConstructedContent.replace(/{DOCTOR NAME}/gi, doctorName)
                .replace(/{MEMBER NAME}/gi, memberName)
                .replace(/{EFFECTIVE DATE}/gi, effectiveDate);
            }
            this._assignPCPSuccessText = pcpAlertMsg;
          }
          this.getPcpMemberInfo(false);
          this.showCompletePCP = true;
          if (typeof _satellite !== 'undefined') {
            _satellite.track('assignPCPSuccess');
          }
        },
        (error: any) => {
          this.showCompletePCP = false;
          this.assignPcpErrorMsg = true;
          this.assignPcpShowPageProgress = false;
          this.handleError(error?.error?.exceptions?.error?.[0].code);
          if (typeof _satellite !== 'undefined') {
            _satellite.track('assignPCPFailure');
          }
        }
      );
    }
  }

  handleError(errorCode: string) {
    let errMsg = '';
    if (errorCode) {
      this.content.commonContents.pcpErrorMessages.forEach((errCode) => {
        if (errCode.codes.includes(errorCode)) {
          errMsg = errCode.message;
        }
      });
    }
    if (this._dataHelper.isEmptyString(errMsg)) {
      errMsg = this.content.commonContents.pcpErrorList.updateFail;
    }
    errMsg = errMsg.replace(/{DOCTOR NAME}/gi, this.professional.providerName.toUpperCase())
      .replace(/{MEMBER NAME}/gi, (this.titleCase(this.selectedMbr.firstName)
        + ' ' + this.titleCase(this.selectedMbr.lastName)));
    this.errorText = errMsg;
    this.apiErrorCd = errorCode;
    this.showApiErrCd = Boolean(this.apiErrorCd);
  }

  private buildUpdateCareTeamRequest(_appSession: AppSession, providers: IProvider[]): IProviderRequest {
    const _updateRequest: IProviderRequest = {};
    _updateRequest.careTeam = providers;

    // mbrUid and contractUid is needed in request to pull loggedin or selected member info from cache.
    if (_appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.mbrUid) {
      _updateRequest.mbrUid = _appSession.metaData.appContract.mbrUid;
    }
    return _updateRequest;
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  buildPcpMemberOptions(result: any) {
    const pcpMemberOptions = [];
    result.forEach((x: any) => {
      pcpMemberOptions.push({
        label: (x.relationship && x.relationship !== null) ?
          this.titleCase(x.firstName) + ' ' + this.titleCase(x.lastName) + ' (' + this.titleCase(x.relationship) + ')'
          : this.titleCase(x.firstName) + ' ' + this.titleCase(x.lastName),
        value: x.mbrUid,
        id: 'rdb-mbr-id-' + x.mbrUid,
        name: 'rdb-mbr-name-' + x.mbrUid,
        pcp: x.pcp
      });
    });
    return pcpMemberOptions;
  }

  getPcpMemberInfo(showProgress: boolean) {
    this.pcpPageProgress = showProgress;
    const pcpReq: IPcpRequest = {
      contractUid: null,
      selMbrUid: this._appSession.metaData.appContract.mbrUid,
      pcpInfo: null
    };
    const _mbruid = (!showProgress && this.selectedMbr
      && this.selectedMbr.mbrUid) ? this.selectedMbr.mbrUid : '';
    this._pcpSvc.getPcpMemberInfo(pcpReq).then((result: IPCPResponse) => {
      this.processPcpMemberInfo(result, _mbruid);
      this.pcpPageProgress = false;
    },
      (error: any) => {
        this.pcpPageProgress = false;
        this.processPcpMemberInfo(null, _mbruid);
        if (error instanceof HttpErrorResponse) {
          throw error;
        }
      }
    );
  }

  processPcpMemberInfo(pcpResponse: IPCPResponse, mbrUid: string) {
    if (this._appSession && this._appSession.metaData && this._appSession.metaData.appContract
      && this._appSession.metaData.appContract.memberList
      && this._appSession.metaData.appContract.memberList.length > 0) {
      if (pcpResponse && pcpResponse.member && pcpResponse.member.length > 0) {
        for (const member of this._appSession.metaData.appContract.memberList) {
          member.pcp = null;
          const pcpMember = pcpResponse.member.filter((x) => x.mbrUid === member.mbrUid);
          if (pcpMember && pcpMember.length > 0) {
            for (const pcpMemberInfo of pcpMember) {
              const pcpDetails = pcpMemberInfo.pcp.filter((x) => x.contractUid
                === this._appSession.metaData.appContract.contractUid);
              if (pcpDetails && pcpDetails.length > 0 && pcpDetails[0].providerName) {
                member.pcp = {
                  pcpId: pcpDetails[0].pcpId,
                  providerName: pcpDetails[0].providerName,
                  addressOne: pcpDetails[0].addressOne,
                  addressTwo: pcpDetails[0].addressTwo,
                  phone: pcpDetails[0].phone
                };
              }
            }
          }
        }
      }
      const memberList =
        this._appSession.metaData.appContract.memberList.filter((x: any) => x.mbrUid !== '');
      if (memberList && memberList.length > 0) {
        this.pcpRadioSideOptions = this.buildPcpMemberOptions(memberList);
        //get the LoggedIn Member/ member selected from plan and set as default
        this.selectedMbr = this.getSelectedMember(mbrUid);
        this.labelPCPRadioSideOptions = this.pcpRadioSideOptions.filter((options: IPCPRadioSideOptions) => options.value === this.selectedMbr.mbrUid);
      }
    }
  }

  getSelectedMember(mbrUid: string) {
    let _selectedMember: IOptions;
    if (this._appSession && this._appSession.metaData
      && this._appSession.metaData.appContract
      && this._appSession.metaData.appContract.memberList
      && this._appSession.metaData.appContract.memberList.length > 0) {
      const _memberList = this._appSession.metaData.appContract.memberList;
      mbrUid = mbrUid === '' ? this._appSession.metaData.appContract.mbrUid : mbrUid;
      if (mbrUid) {
        const mbr = _memberList.filter((x: any) => x.mbrUid === mbrUid);
        if (mbr && mbr.length > 0) {
          _selectedMember = mbr[0];
        }
      }
      if (!_selectedMember) {
        _selectedMember = _memberList.filter((x: any) => x.isChecked === true)[0];
      }
    }
    return _selectedMember;
  }

  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  /** Event handler for click of Remove PCP button  */
  onRemovePCP() {
    if (this.selectedMbr === null) {
      this.selectedMbrError = true;
      this.errorText = this.content.handle.alerts.lblErrorMbrMsg;
    } else if (this.changeReasonPcp === '') {
      this.selectedReasonPcpError = true;
      this.selectedMbrError = this.selectedPcpIdError = false;
      this.errorText = this.content.handle.alerts.lblErrorChangeReasonPcpMsg;
    } else if (!(this.selectedMbr.pcp && this.selectedMbr.pcp.providerName)) {
      this.selectedMbrError = this.selectedReasonPcpError = false;
      this.assignPcpErrorMsg = true;
      this.errorText = this.content.handle.alerts.lblErrorNoPcpAssignedMsg;
    } else {
      this.selectedMbrError = this.selectedReasonPcpError = this.showSucessAssignPCPMsg =
        this.assignPcpErrorMsg = this.selectedPcpIdError = false;
      this.errorText = this._assignPCPSuccessText = '';
      this.assignPcpShowPageProgress = true;
      if (window && window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo
      ) {
        window.digitalData.page.pageInfo.changePcpCode = this.changeReasonPcp;
      }
      const pcpReq: IPcpRequest = {
        contractUid: this._appSession.metaData.appContract.contractUid,
        pcpInfo: {
          pcpId: '',
          pcpTrmntnRsnCd: this.changeReasonPcp
        },
        selMbrUid: this.selectedMbr.mbrUid,
      };
      this._pcpSvc.removePCP(pcpReq).then(
        (result: any) => {
          this.showSucessAssignPCPMsg = true;
          this.assignPcpShowPageProgress = false;
          const _removeSucess = this.content.handle.pcpErrorList.removeSuccess;
          const _providerName = (this.selectedMbr.pcp && this.selectedMbr.pcp.providerName) ?
            this.selectedMbr.pcp.providerName.toUpperCase() : '';
          this._assignPCPSuccessText = _removeSucess
            .replace(/{DOCTOR NAME}/gi, _providerName)
            .replace(/{MEMBER NAME}/gi, (this.titleCase(this.selectedMbr.firstName)
              + ' ' + this.titleCase(this.selectedMbr.lastName)));
          this.getPcpMemberInfo(false);
          this.refreshParent = true;
          if (typeof _satellite !== 'undefined') {
            _satellite.track('removePCPSuccess');
          }
        },
        (error: any) => {
          this.assignPcpErrorMsg = true;
          this.assignPcpShowPageProgress = false;
          this.showApiErrCd = false;
          const _removeFail = this.content.handle.pcpErrorList.removeFail;
          const _providerName = (this.selectedMbr.pcp && this.selectedMbr.pcp.providerName) ?
            this.selectedMbr.pcp.providerName.toUpperCase() : '';
          this.errorText = _removeFail
            .replace(/{DOCTOR NAME}/gi, _providerName)
            .replace(/{MEMBER NAME}/gi, (this.titleCase(this.selectedMbr.firstName)
              + ' ' + this.titleCase(this.selectedMbr.lastName)));
          if (typeof _satellite !== 'undefined') {
            _satellite.track('removePCPFailure');
          }
        }
      );
    }
  }

  //On Assign pcp sucess user will be taken to care team page
  onCompletePCP() {
    this.onClose();
    if (!this.refreshParent && (this._navigationService.currentRoute === AppNavigations.CARE_TEAM_PATH || this._navigationService.currentRoute === AppNavigations.CARE_CIRCLE_PATH)) {
      this.refreshContainer.emit();
    } else {
      this._navigationService.navigateByUrl(AppNavigations.CARE_TEAM_PATH);
    }
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }

  onRadioPCPID() {
    if (this.pcpId !== '') {
      this.selectedPcpIdError = false;
    }
  }

  onChangePcpReason($event) {
    if ($event.value !== '') {
      this.changeReasonPcp = $event.value;
      this.selectedReasonPcpError = false;
    }
  }

  private postPCPInfo() {
    // TODO Add code to post PCP info
    if (this.pcpId === '') {
      this.selectedPcpIdError = true;
      this.errorText = this.content.handle.alerts.lblErrorPcpMsg;
    } else {
      this.selectedPcpIdError = false;
      if (this.deepLinkActionUrl && this.deepLinkActionUrl !== '') {
        this.postPCPForm?.nativeElement?.submit();
      } else {
        const provider = {
          pcpId: this.pcpId,
          providerId: this.professional.providerIdentifier,
          providerName: this.professional.providerName,
          providerType: this.professional.providerTypeCodeList,
          addressId: this.professional.location.address.addressId,
          address: `${this.professional.location.address.addressOne},${this.professional.location.address.addressTwo}`,
          city: this.professional.location.address.city,
          state: this.professional.location.address.state,
          zip: this.professional.location.address.postalCode,
          county: this.professional.location.address.county,
          phone: this.professional.location.address.phone,
          acceptNewPatient: this.professional.acceptsNewPatients,
        };

        const eventDetail: IEventDetail = {
          object: provider,
          type: AppEvents[AppEvents.PF_APP_CMD_PROV_INFO],
          target: AppExternalCommands.PF_APP_CMD_PROV_INFO.CMD,
          message: AppExternalCommands.PF_APP_CMD_PROV_INFO.MSG,
          details: AppExternalCommands.PF_APP_CMD_PROV_INFO.MSG,
          errorCode: null,
        };

        this._pfAppCommandEvent.emit(eventDetail);
        this.sidePanelRef.close();
      }
    }
  }

  get pcpFormAttributes() {
    if (this.professional == null) {
      return [];
    }

    const provider = {
      pcpId: this.pcpId,
      providerId: this.professional.providerIdentifier,
      providerName: this.professional.providerName,
      providerType: this.professional.providerTypeCodeList[0],
      addressId: this.professional.location.address.addressId,
      address: `${this.professional.location.address.addressOne},${this.professional.location.address.addressTwo}`,
      city: this.professional.location.address.city,
      state: this.professional.location.address.state,
      zip: this.professional.location.address.postalCode,
      county: this.professional.location.address.county,
      phone: this.professional.location.address.phone,
      acceptNewPatient: this.professional.acceptsNewPatients,
    };

    return Object.keys(provider).map((keys) => ({
      name: keys,
      value: provider[keys],
    }));
  }

  get isPCPDeepLink() {
    return (
      this._appSession.isDeepLink &&
      this._appSession.deeplinkParams.action === DEEPLINK_ACTION.POST_PCP_INFO
    );
  }

  get deepLinkActionUrl() {
    return this._appSession.deeplinkParams.oncompleteurl;
  }

  getContactUsText(): string {
    return CommonUtil.isMemberSecure(this._appSession) ? this.content.commonContents.caHmoPCPDisclaimer.contactUsSecure : this.content.commonContents.caHmoPCPDisclaimer.contactUsPublic;
  }

  get isCACommercialPCPDisclaimer(): boolean {
    return this._appSession?.feature?.showCACommercialPCPDisclaimer || false;
  }
}
