import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppNavigations } from '../constants/app-navigations';
@Injectable()
export class RouteUtil {
  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
  }

  isSearchResultRoute(route: string | AppNavigations) {
    return route === AppNavigations.SEARCH_RESULTS_PATH || route === AppNavigations.SEARCH_RESULTS_NEXT || route === AppNavigations.FCR_RESULT_PATH;
  }
}
