import { Injectable } from '@angular/core';
import { IPerformance } from '../interfaces/iSearchStrategyResponse';

@Injectable({
  providedIn: 'root',
})
export class NetworkProgramSvc {
  getCount(performance: IPerformance): number {
    if (!performance) {
      return 0;
    }
    return (
      (performance.recognition || []).length +
      (performance.tier || []).length +
      (performance.indicator || []).length
    );
  }

  hasNetworkPrograms(performance: IPerformance): boolean {
    return this.getCount(performance) > 0;
  }
}
