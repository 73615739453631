import { IClinic } from "../../../../fad/search-results/interfaces/iSummaryResp";

export enum CardLinkType {
  CLINIC = 'clinic',
  PHONE = 'phone',
  ACTION = 'action'
}

export interface ICardData {
  sortOrder: 1 | 0;
  imgSrc: string;
  title: string;
  message: string;
  clinicLink?: ICardLink;
  phoneLink?: ICardLink;
  actionLink?: ICardLink;
  contentId?: string;
  clinic?: IClinic;
}

export interface ICardLink {
  type: CardLinkType;
  text: string;
  href: string;
  ariaLabel: string;
  analytics: string;
  id: string;
}
