import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFCostSearchContainerComponent } from './components/pfCostSearchContainerCmp';
import { ProcedureIndexModel } from './models/prodecureIndexModel';

@NgModule({
    imports: [UxSharedModule, FormsModule, CommonModule],
    declarations: [PFCostSearchContainerComponent],
    exports: [PFCostSearchContainerComponent],
    providers: [ProcedureIndexModel]
})
export class PFCostSearchModule {
  static forRoot(): ModuleWithProviders<PFCostSearchModule> {
    return {
      ngModule: PFCostSearchModule
    };
  }
}
