import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { INFOBOX_EVENTS } from './../../enums/infoBox';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { BaseComponent } from './../base-component/baseCmp';

@Component({
  selector: 'app-pf-ratings',
  templateUrl: './pfRatingsCmp.html'
})
export class PFRatingsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input()
  index: string | number;
  @Input()
  set provider(reviewRating: any) {
    this.ratings = reviewRating.starRating;
    this.totalReviews = reviewRating.totalReviews;
    //this.providerName = providerName;
    this.source = reviewRating.source;
    if (Boolean(reviewRating.pdtStrKey)) {
      this.providerPdtStrKey = reviewRating.pdtStrKey;
    }
    this.updatedDate = reviewRating.lastReviewTime ? reviewRating.lastReviewTime : '';
    this.name = reviewRating.name;
    this.toolTip = reviewRating.toolTip;
  }
  @Input()
  eventKey: string = '';
  @Input()
  providerName: string;
  @Input()
  showToolTip: boolean = true;

  stars: Array<string> = [];
  totalReviews: number;
  source: string;
  updatedDate: string = '';
  name: string;
  toolTip: string;
  //providerName: string;
  @ViewChild('reviewMapModal')
  reviewModal: ModalComponent;
  vitalsUrl: SafeResourceUrl;
  providerPdtStrKey: string;
  private count = 5;
  private ratings: number;

  private filledStar = 'motif-icon motif-filled-star';
  private halfEmptyStar = 'motif-icon motif-filled-star motif-star-half-empty';
  private emptyStar = 'motif-icon motif-star';
  private NOREVIEW = 'no review available';

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['eventKey'] !== 'undefined' &&
      changes['eventKey'] !== null &&
      typeof changes['eventKey'].currentValue !== 'undefined' &&
      changes['eventKey'].currentValue !== null &&
      changes['eventKey'].currentValue !== '') {

      const eventKey = changes['eventKey'].currentValue as string;

      this.handleEvents(eventKey);
    }
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    let rating = this.ratings;

    for (let i = 0; i < this.count; i++) {
      if (rating === .5) {
        this.stars.push(this.halfEmptyStar);
        rating--;
      } else if (rating > 0) {
        this.stars.push(this.filledStar);
        rating--;
      } else {
        this.stars.push(this.emptyStar);
      }
    }
  }
  ngAfterViewInit() {
    this.isEmptyModelHeader();
  }
  openReviewModal() {
    if ((this.totalReviews &&
      this.totalReviews.toString().toLocaleLowerCase() === this.NOREVIEW) || !this.isPDTRating) {
      return;
    }

    if (this.providerPdtStrKey) {
      this.vitalsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + this.providerPdtStrKey + '/en');
    }
    this.reviewModal.show();
  }

  closeModal() {
    this.reviewModal.hide();
  }

  getVitalsUrl() {
    return this.vitalsUrl;
  }

  getRatingsText() {
    const reviewText = this.content.summaryComponent.reviewText;
    const source = this.isPDTRating ? reviewText.bcbsa : (this.source ? this.source : reviewText.hcahps);
    if (this.totalReviews >= 1) {
      return `${this.totalReviews} ${source}`;
    } else {
      return `0 ${source}`;
    }
  }

  getAriaLabel() {
    const reviewText = this.content.summaryComponent.reviewText;
    let ariaLabel = this.content.summaryComponent.reviewInfo;
    if (this.totalReviews === 1) {
      ariaLabel = ariaLabel.replace(/{review}/gi, reviewText.review);
    } else {
      ariaLabel = ariaLabel.replace(/{review}/gi, reviewText.reviews);
    }
    return ariaLabel + ' ' + this.providerName + ' ' + this.getRatingsText();
  }

  getStarAriaLabel() {
    const reviewText = this.content.summaryComponent.reviewText;
    const ariaLabel = this.content.summaryComponent.starAriaLabel;
    const source = this.isPDTRating ? reviewText.bcbsa : (this.source ? this.source : reviewText.hcahps);
    return ariaLabel.replace(/{NAME}/gi, this.providerName).
      replace(/{RATING}/gi, this.ratings).
      replace(/{SRC}/gi, source);
  }

  get showStarRatings() {
    if (this.totalReviews &&
      this.totalReviews.toString().toLocaleLowerCase() === this.NOREVIEW) {
      return false;
    }
    return true;
  }

  isEmptyModelHeader() {
    if (this.reviewModal.titleElement.nativeElement &&
      this.reviewModal.titleElement.nativeElement.innerText === '') {
      this.reviewModal.titleElement.nativeElement.insertAdjacentHTML('beforeend', "<span class='sr-only'>title</span>");
    }
  }

  get isPDTRating() {
    return this.providerPdtStrKey && this.providerPdtStrKey !== '';
  }

  get tooltipContent() {
    return this.toolTip.replace(/{DATE}/gi, this.updatedDate);
  }

  private handleEvents(eventKey: string) {
    switch (eventKey) {
      case INFOBOX_EVENTS.RATINGS_REVIEWS:
        {
          this.openReviewModal();
          break;
        }
      default:
        break;
    }
  }
}
