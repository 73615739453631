import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IOption } from '../../common/interfaces/iOption';
import { IAppliedSearchFilter } from '../models/iAppliedSearchFilter';

@Injectable({ providedIn: 'root' })
export class AppliedSearchFilter {
  public appliedFilterItems: IAppliedSearchFilter[] = [];
  private appliedFilters = new BehaviorSubject<IAppliedSearchFilter[]>(this.appliedFilterItems);
  private remove = new BehaviorSubject<IAppliedSearchFilter>(undefined);

  get getAppliedFilters() {
    return this.appliedFilters.asObservable();
  }

  get appliedFilterRemove() {
    return this.remove.asObservable();
  }

  /**
   * Adds a filter item to the applied filter items list.
   * @param category The category of the filter.
   * @param filterOption The filter option to add.
   */
  addFilterOption(category: string, filterOption: IOption): void {
    if (!this.appliedFilterItems.some((item) => item.category === category && item.name === filterOption.label && item.value === filterOption.value)) {
      const filter: IAppliedSearchFilter = {
        category: category,
        value: filterOption.value,
        name: filterOption.label
      };
      this.appliedFilterItems.push(filter);
      this.appliedFilters.next(this.appliedFilterItems);
    }
  }

  /**
   * Removes a filter item from the applied filter items list.
   * @param category The category of the filter.
   * @param filterOption The filter option to remove.
   */
  removeFilterOption(category: string, filterOption: IOption): void {
    const index = this.appliedFilterItems.findIndex((option) => option.category === category && option.name === filterOption.label);
    if (index !== -1) {
      this.appliedFilterItems.splice(index, 1);
      this.appliedFilters.next(this.appliedFilterItems);
    }
  }

  /**
   * Adds filters for a specific category, replacing any existing filters for that category.
   * @param category The category of the filters.
   * @param filterOptions The filter options to add.
   */
  addCategoryFilters(category: string, filterOptions: IAppliedSearchFilter[]): void {
    // Remove existing filters for the specified category
    this.appliedFilterItems = this.appliedFilterItems.filter((item) => item.category !== category);

    // Add the new filter options for the specified category
    this.appliedFilterItems.push(...filterOptions);

    // Notify subscribers of the updated filter items
    this.appliedFilters.next(this.appliedFilterItems);
  }

  /**
   * Removes a specific filter from the applied filters.
   * @param filter The filter to remove.
   */
  removeFilter(filter: IAppliedSearchFilter): void {
    if (!filter) {
      return;
    }

    const index = this.appliedFilterItems.findIndex((option) => option.category === filter.category && option.name === filter.name);

    if (index !== -1) {
      this.appliedFilterItems.splice(index, 1);
      this.appliedFilters.next(this.appliedFilterItems);
      this.remove.next(filter);
    }
  }

  /**
   * Clears all filters or filters of a specific category.
   * @param category The category of filters to clear. If not provided, all filters are cleared.
   */
  clearFilters(category?: string): void {
    if (category) {
      this.appliedFilterItems = this.appliedFilterItems.filter((item) => item.category !== category);
    } else {
      this.appliedFilterItems = [];
    }
    this.appliedFilters.next(this.appliedFilterItems);
  }

  undoFilter(): void {
    const poppedFilter = this.appliedFilterItems.pop();
    this.remove.next(poppedFilter);
    this.appliedFilters.next(this.appliedFilterItems);
  }

  /**
   * Maps the applied filter items based on the category property.
   * @param category The category to filter by. If not provided, all categories are returned.
   * @returns An object with categories as keys and arrays of corresponding filter items as values.
   */
  mapAppliedFilterItemsByCategory(category?: string): { [key: string]: IAppliedSearchFilter[] } {
    if (category) {
      return this.appliedFilterItems
        .filter((item) => item.category === category)
        .reduce(
          (acc, item) => {
            if (!acc[item.category]) {
              acc[item.category] = [];
            }
            acc[item.category].push(item);
            return acc;
          },
          {} as { [key: string]: IAppliedSearchFilter[] }
        );
    } else {
      return this.appliedFilterItems.reduce(
        (acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        },
        {} as { [key: string]: IAppliedSearchFilter[] }
      );
    }
  }
}
