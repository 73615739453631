import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { ReportInvalidModule } from '../../../app/common/components/report-invalid/pfReportInvalidModule';
import { PFEssentialProviderModule } from '../../common/components/essential-provider/pfEssentialProviderModule';
import { FixedTrayModule } from '../../common/components/fixed-tray/fixedTrayModule';
import { HealthwiseModule } from '../../common/components/healthwise/healthwiseModule';
import { OnlinePharmacyLabelModule } from '../../common/components/online-pharmacy-label/onlinePharmacyLabelModule';
import { RatingsModule } from '../../common/components/ratings/pfRatingsModule';
import { ScenarioAlertModule } from '../../common/components/scenarioAlert/pfScenarioAlertModule';
import { VirtualCareLabelModule } from '../../common/components/virtual-care-label/virtualCareLabelModule';
import { AssignCareModule } from './../../../app/common/components/assign-care/assignCareModule';
import { DisclaimerModule } from './../../../app/common/components/disclaimers/pfDisclaimerModule';
import { HelpfulHintsModule } from './../../../app/common/components/helpfulhints/pfHelpfulHintsModule';
import { InfoListModule } from './../../../app/common/components/info-list/pfInfoListModule';
import { LeavingDisclaimerModule } from './../../../app/common/components/leaving-disclaimer/leavingDisclaimerModule';
import { MapModule } from './../../../app/common/components/map/pfMapModule';
import { ScheduleAppointmentModule } from './../../../app/common/components/schedule-appointment/scheduleAppointmentModule';
import { CommonAlertModule } from './../../common/components/common-alert/commonAlertModule';
import { PFCostInfoModule } from './../../common/components/cost-info/pfCostInfoModule';
import { AssignPcpModule } from './../../common/components/pcp/pfAssignPcpModule';
import { SsoModule } from './../../common/components/sso/ssoModule';
import { ToggleModule } from './../../common/components/toggle-control/toggleModule';
import { CustomPipesModule } from './../../common/pipes/customPipesModule';
import { PhonePatternPipe } from './../../common/pipes/phonePatternPipe';
import { PFCostDetailsModule } from './../../eyc/cost-details/pfCostDetailsModule';
import { PFAffiliationComponent } from './components/pfAffiliationCmp';
import { PFAffiliationsComponent } from './components/pfAffiliationsCmp';
import { PFDetailsComponent } from './components/pfDetailsCmp';
import { PFLocationsComponent } from './components/pfLocationsCmp';
import { PFOfficeServiceComponent } from './components/pfOfficeServiceCmp';
import { PFPairRecognitionsComponent } from './components/pfPairRecognitionsCmp';
import { PFPlansAcceptedComponent } from './components/pfPlansAcceptedCmp';
import { PFProviderDetailsContainerComponent } from './components/pfProviderDetailsContainerComponent';
import { PFRecognitionsComponent } from './components/pfRecognitionsCmp';
import { PFSatisfactionQualityComponent } from './components/pfSatisfactionQualityCmp';
import { ProviderDetailsService } from './services/providerDetailsSvc';

@NgModule({
  imports: [UxSharedModule, FormsModule, CommonModule, ToggleModule, DisclaimerModule, MapModule, HelpfulHintsModule,
    PFCostDetailsModule, LeavingDisclaimerModule, RatingsModule, FixedTrayModule, SsoModule, ScheduleAppointmentModule,
    AssignPcpModule, CustomPipesModule, AssignCareModule, InfoListModule, PFCostInfoModule, HealthwiseModule, ReportInvalidModule, VirtualCareLabelModule,
    CommonAlertModule, PFEssentialProviderModule, OnlinePharmacyLabelModule, ScenarioAlertModule],
  declarations: [PFDetailsComponent, PFLocationsComponent,
    PFPlansAcceptedComponent, PFAffiliationComponent, PFProviderDetailsContainerComponent,
    PFSatisfactionQualityComponent, PFRecognitionsComponent, PFOfficeServiceComponent, PFPairRecognitionsComponent, PFAffiliationsComponent],
  exports: [PFDetailsComponent, PFLocationsComponent, PFSatisfactionQualityComponent,
    PFPlansAcceptedComponent, PFAffiliationComponent, PFProviderDetailsContainerComponent,
    PFRecognitionsComponent, PFOfficeServiceComponent, PFPairRecognitionsComponent],
  providers: [PhonePatternPipe, ProviderDetailsService]
})
export class PFDetailsModule {
  static forRoot(): ModuleWithProviders<PFDetailsModule> {
    return {
      ngModule: PFDetailsModule,
      providers: []
    };
  }
}
