// Define the English content for the home page
export const HOME_CONTENT_EN = {
  title: 'Home',
  planSelection: {
    wlpLabel: {
      loginText: 'Log In',
      personalizedSearchDesc: "Find doctors, hospitals, and more in your plan's network. Get detailed estimates for procedures or services (not available with some plans)",
      defaultPrefixTextNew: 'Enter member ID number',
      idCardToolTipHeaderText: 'Use Your Identification Number to Search',
      idCardToolTipNoteText: ''
    },
    wlpMass: {
      findProvider: 'Find a Behavioral Health Provider',
      description:
        'Searching for a mental health or substance use disorder provider? Take full advantage of your benefits and use the Carelon Behavioral Health network. As a member with the <strong>Wellpoint Massachusetts</strong> plan, your coverage is highest when you use care providers in the Carelon Behavioral Health network.',
      health: 'Carelon Behavioral Health',
      externalLink: 'https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic/',
      searchDescription: 'Find out if a doctor, hospital, or other care provider is in-network for the <strong>Wellpoint Massachusetts</strong> plan.',
      basicSearch: 'Basic Search as a Guest',
      login: 'Log In',
      loginDescription: 'Find doctors, hospitals, and more in your <strong>Wellpoint Massachusetts</strong> plan. Get detailed estimates for health services based on your benefits.',
      guestSearch: 'Guest Search'
    },
    title: {
      findaDoctor: 'Find a Doctor',
      findaDoctorText: 'To search by selecting a plan, first narrow the list of plans and networks by selecting the type of care and state. Then, select a plan or network to search.'
    },
    labels: {
      typeOfCare: 'Select the type of plan or network',
      searchInState:
        "Select the state where the plan or network is offered. <span class='font-wt-normal'>(For employer-sponsored plans, select the state where your employer's plan is contracted in. Most of the time, it's where the headquarters is located.)</span>",
      planOrNetwork: 'Select a plan or network',
      defaultCareText: 'Select One',
      defaultStateText: 'Select One',
      defaultPlanOrText: 'Select One',
      planCategory: 'Select how you get health insurance',
      defaultPlanCategoryText: 'Select One',
      medicalCareHelpText: 'Care Providers for Behavioral Health & Substance Use Disorder Services are listed under Medical plan or network.',
      unicareMassHelpText: {
        msgText: 'Searching for a mental health or substance use disorder provider? Use the ',
        linkText: 'Carelon Behavioral Health',
        msgText2: ' network to receive the maximum benefits.',
        brandUrls: { unimass: 'https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic/' }
      },
      personalizedSearchTitle: 'Log in for Personalized Search',
      personalizedSearchDesc:
        "Find doctors, hospitals, and more in your plan's network. Get detailed estimates for procedures or services (not available with some plans). If you don't have an account, ",
      wlpLandingPagePersonalizedSearchDesc: "Find doctors, hospitals, and more in your plan's network. Get detailed estimates for procedures or services (not available with some plans)",
      memberIdSearchTitle: 'Use Member ID for Basic Search',
      memberIdSearchDesc: 'Find doctors, hospitals and more near you.',
      planSearchTitle: 'Basic search as a guest',
      planSearchDesc: 'Select a plan and find out if a doctor, hospital, or other care provider is in-network.',
      alphaPrefix: 'Identification Number or Prefix (first three values)',
      defaultPrefixText: 'Enter Identification Number or Prefix',
      searchTogglePlan: 'Search by Plan',
      searchToggleId: 'Search by Identification Number or Prefix',
      loginDescriptiveText: "Log in to find care providers in your plan. If you don't have an account,",
      registerNowText: 'register now',
      orText: 'or',
      searchAsGuestText: 'search as a guest.',
      loginText: 'Log In to Find Care',
      wlpLandingPageLoginText: 'Log In',
      loginDescriptiveText1: "If you're a member, ",
      loginDescriptiveText2: " and view personalized search results based on your plans and benefits.  If you don't have an account, ",
      findCareLogin: 'log in to Find Care',
      searchWithMemberIdText: 'Search your medical plan without logging in.',
      alphaPrefixNew: 'Member ID number or prefix (first three values)',
      defaultPrefixTextNew: 'ID number or prefix (first three values)',
      idCardToolTipHeaderText: 'Use Your Identification Number or Prefix (First Three Values) to Search',
      idCardToolTipNoteText:
        'Note: If you are a member with Medicaid or other state-sponsored programs, search with the prefix (first three values) of your identification number or search by selecting a plan/network.',
      idCardToolTipAltImgText: 'id card',
      idCArdToolTipAriaLabelText: 'ID card image',
      closeModal: 'button, select to close the modal',
      searchText: 'Search',
      headingText: 'Guests',
      headingDescription: 'Not a member? Browse our network directories.',
      tabDescription: 'Answer a few questions and then select a plan or network.',
      altBlueMemberIconText: 'blue member icon',
      altWhiteMemberIconText: 'white member icon',
      altMemberIcon: 'member icon',
      altGuestIcon: 'guest icon',
      memberHeadingText: 'Members',
      memberHeadingDescriptionText: 'Find doctors, hospitals and more in your plan.',
      memberWithMedicaid: 'Members with Medicaid',
      medicaidStatesCalifornia: 'California',
      medicaidStatesIndianapolis: 'Indiana',
      medicaidStatesKentucky: 'Kentucky',
      medicaidStatesNevada: 'Nevada',
      medicaidStatesVirginia: 'Virginia',
      medicaidStatesWisconsin: 'Wisconsin',
      medicaidStateNewyork: 'New York',
      medicaidHeaderText: 'Go to the Anthem Medicaid Find Care tool for your state.',
      medicaidEligibleAdultText: 'Combined Medicare and Medicaid coverage for eligible adults over age 21.',
      tooltipInfo: 'press enter for more information about '
    },
    alerts: {
      typeCare: 'Please enter or select type of care',
      searchState: 'Please enter or select a state.',
      plan: 'Please enter or select a plan/network.',
      category: 'Please enter or select type of plan',
      idOrPrefix: 'Please enter a member ID or prefix.',
      captchaRequiredError: 'Please complete the CAPTCHA verification to proceed.',
      hcidError: "We can't find that member ID or prefix.  Please check your ID card and try again.",
      invalidHCID: "Search isn't available using a member ID number or prefix. Please ",
      multiContractError: 'We’re sorry, but we can’t continue your search with that number because you have more than one plan.To search, please log in and pick the plan you want to search.'
    },
    apiError: "Sorry, looks like something isn't working. Please give us some time to fix it. Then, try again.",
    continue: 'Continue',
    cancel: 'Cancel',
    backToTop: 'Back to Top'
  }
};

//  Define a type based on the structure of the English content
export type HomeContent = typeof HOME_CONTENT_EN;
