import {
  Component, ElementRef, EventEmitter, HostListener, Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalRef } from "@anthem/uxd/modal";
import { isEmpty } from 'lodash';
import { IAddressForm } from "../interfaces/iAddressForm";
import { ISearchParameters } from "../interfaces/iSearchParameters";
import { ProviderUtilityService } from "../services/providerUtilitySvc";
import { BaseComponent } from "./../../../common/components/base-component/baseCmp";
import { ADDRESS_SELECT } from "./../../../common/constants/app-constants";
import { AppNavigations } from "./../../../common/constants/app-navigations";
import { AddressByQueryResponse } from "./../../../common/models/locationAddress";
import { DataHelper } from "./../../../common/services/dataHelper";
import { EventHandler } from "./../../../common/services/eventHandler";
import { NavigationService } from "./../../../common/services/navigationService";
import { AppSession } from "./../../../common/values/appSession";
import { ContentHelper } from "./../../../common/values/contentHelper";
@Component({
  moduleId: module.id,
  selector: "app-fad-pf-switchlocation-cmp",
  templateUrl: "../views/pfSwitchLoctionCmp.html",
  providers: [],
})
export class PFSwitchLocationComponent extends BaseComponent {
  @Input()
  searchParams: ISearchParameters;
  @ViewChild("openAddressSelection")
  changePlanSidePanel: TemplateRef<HTMLElement>;
  @Output()
  closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  search: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  setZipcode: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  reloadSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  searchCriteria: ISearchParameters;
  addressModel: string;
  findOtherOptionModel: string;
  customAddressSearch: boolean = this._appSession.customAddressSearch;
  clearForm: boolean = false;
  isRedBorderShow: boolean;
  showError: boolean;
  errorText: string;
  executionCount: number = 0;
  isSecureState: boolean = false;
  radioAddress = {
    isOptional: false,
    alignVertical: true,
    id: "addressGrp",
    name: "addressGrp",
    label: "",
    options: [],
  };
  homeLabel = {
    id: this.content?.addressTypes?.addressSection?.home?.code,
    label: this.content?.addressTypes?.addressSection?.home?.label,
    value: ADDRESS_SELECT.HOME,
  };
  workLabel = {
    id: this.content?.addressTypes?.addressSection?.work?.code,
    label: this.content?.addressTypes?.addressSection?.work?.label,
    value: ADDRESS_SELECT.WORK,
  };
  findOtherOption = {
    id: this.content?.addressTypes?.customSection?.customZip?.label?.code,
    label: this.content?.addressTypes?.customSection?.customZip?.label,
    value: ADDRESS_SELECT.CUSTOM_ADDRESS,

  };
  sidePanelRef: ModalRef<any, any>;
  showSpecialtyLoader: boolean = true;
  showProcedureLoader: boolean = true;
  showProviderLoader: boolean = true;
  specialtyMore: boolean = false;
  procedureMore: boolean = false;
  providerMore: boolean = false;
  providerNpiMore: boolean = false;
  providerLicenseMore: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _providerUtilityService: ProviderUtilityService,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _navigationService: NavigationService,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _elementRef: ElementRef,
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchContainerComponent"
    );
  }

  ngOnInit() {
    this.initialiseRadioForm();
    if (this._appSession?.customAddressSearch) {
      this.findOtherOptionModel = ADDRESS_SELECT.ZIPCODE
      this.addressModel = ADDRESS_SELECT.CUSTOM_ADDRESS;
    } else {
      this.addressModel = this._appSession.addressObj?.addressModel;
    }
    if (!this._appSession.isSecureState || this._appSession.addressObj?.addressModel === ADDRESS_SELECT.CUSTOM_ADDRESS || (this._appSession.isSecureState && this._appSession.isHcidSearch)) {
      this.onCustomAddressSearch();
    }
    this._appSession.addressObj.addressModel = this.addressModel;
    this.disableContinue();
  }

  ngOnChanges(args: any) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.setSearchParams(args['searchParams'].currentValue);
    }
  }

  setSearchParams(params: ISearchParameters) {
    this.searchCriteria = params;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.className !== 'fly-out look-up-container') {
      this.searchCtrlFocus();
      if (
        event.target.className !== "btn btn-primary" &&
        event.target.className !== "prLabel ng-star-inserted" &&
        event.target.className !== "prLabel" &&
        event.target.className != "result-link ng-star-inserted" &&
        event.target.className != "look-up-item look-up-item-border ng-star-inserted" &&
        !this.isLocationValid()
      ) {
        return false;
      }
    }
  }

  isLocationValid() {
    this.isRedBorderShow = false;
    let _isValid = true;
    if (!(this.searchCriteria.coordinates &&
      this.searchCriteria.coordinates.latitude !== '' &&
      this.searchCriteria.coordinates.longitude !== '')
    ) {
      _isValid = false;
    }
    return _isValid;
  }

  searchCtrlFocus() {
    this.isRedBorderShow = false;
  }

  reloadProviderSummary() {
    this.reloadSearch.emit(true);
  }

  onClose() {
    this.closeModal.emit();
    if (this._appSession?.addressObj?.customAddress && this._appSession?.addressObj?.addressModel !== ADDRESS_SELECT.CUSTOM_ADDRESS) {
      this.clearForm = true;
    }
    if (this.showError) {
      this.clearForm = true;
      this._appSession.addressObj.customAddress.streetAddress = '';
      this._appSession.addressObj.customAddress.addressLine2 = '';
      this._appSession.searchParams.streetAddress = '';
      this._appSession.searchParams.addressLine2 = '';
      this._appSession.searchParams.city = '';
    }
    this._appSession.customAddressSearch = false;
  }

  submitForm(data: IAddressForm) {
    if (this.customAddressSearch) {
      this._appSession.addressObj.customAddress = data;
      this._appSession.addressObj.isFormValid = this._appSession.addressObj.customAddress.isFormValid;
    }
  }

  onAddressChange(event:string) {
    this.customAddressSearch = false;
    this._appSession.customAddressSearch = false;
    this.findOtherOptionModel = "";
    this._appSession.addressObj.addressModel = event;
    this.disableContinue();
  }

  onCustomAddressSearch() {
    this.findOtherOptionModel = ADDRESS_SELECT.CUSTOM_ADDRESS;
    this.addressModel = ADDRESS_SELECT.CUSTOM_ADDRESS;
    this.customAddressSearch = true;
    this._appSession.customAddressSearch = true;
    this._appSession.addressObj.addressModel = this.addressModel;
  }

  initialiseRadioForm() {
    if (this._appSession.addressObj?.homeAddress && Object.keys(this._appSession?.addressObj?.homeAddress).length !== 0) {
      this.radioAddress.options.push(this.homeLabel);
    }
    if (this._appSession.addressObj?.workAddress && !this.isEmptyObject(this._appSession.addressObj.workAddress)) {
      this.radioAddress.options.push(this.workLabel);
    }
  }

  disableContinue() {
    if (this._appSession.addressObj.addressModel === ADDRESS_SELECT.HOME) {
      this._appSession.addressObj.isFormValid = this._appSession.addressObj?.homeAddress.isFormValid;
    }
    else if (this._appSession.addressObj.addressModel === ADDRESS_SELECT.WORK) {
      this._appSession.addressObj.isFormValid = this._appSession.addressObj?.workAddress.isFormValid;
    }
    return isEmpty(this._appSession.addressObj) || !this._appSession.addressObj.isFormValid;
  }

  async onContinue() {
    this._appSession.serviceAreaValidated = false;
    if (this.customAddressSearch && this._appSession.searchParams && this._appSession?.addressObj?.customAddress) {
      this._appSession.addressObj.addressModel = ADDRESS_SELECT.CUSTOM_ADDRESS;
      this._appSession.searchParams.streetAddress =
        this._appSession.addressObj.customAddress.streetAddress;
      this._appSession.searchParams.addressLine2 =
        this._appSession.addressObj.customAddress?.addressLine2;
      this._appSession.searchParams.city =
        this._appSession.addressObj.customAddress.city;
      this._appSession.searchParams.stateCd =
        this._appSession.addressObj.customAddress.state;
      this._appSession.searchParams.postalCode =
        this._appSession.addressObj.customAddress.zipCode;
      this._appSession.addressObj.customAddress.isEnterAddress = true;
      if (this._appSession.addressObj.workAddress) {
        this._appSession.addressObj.workAddress.isEnterAddress = false;
      }
      if (await this.getAddressCoordinates(this._appSession.addressObj.customAddress)) {
        this.showError = false;
        this.setZipcode.emit(this._appSession.addressObj.customAddress.zipCode);
      }
      else {
        this._appSession.customAddressSearch = false;
        this.showError = true;
        this.errorText = this.content.ErrorMsg;
      }
    } else {
      this._appSession.customAddressSearch = false;
      if (this.addressModel === ADDRESS_SELECT.HOME) {
        this._appSession.addressObj.addressModel = ADDRESS_SELECT.HOME;
        this._appSession.searchParams.streetAddress =
          this._appSession.addressObj.homeAddress.streetAddress;
        this._appSession.searchParams.addressLine2 =
          this._appSession.addressObj.homeAddress?.addressLine2;
        this._appSession.searchParams.city =
          this._appSession.addressObj.homeAddress.city;
        this._appSession.searchParams.stateCd =
          this._appSession.addressObj.homeAddress.state;
        this._appSession.searchParams.postalCode =
          this._appSession.addressObj.homeAddress.zipCode;
        this._appSession.addressObj.homeAddress.isHomeAddress = true;
        if (this._appSession.addressObj.workAddress) {
          this._appSession.addressObj.workAddress.isHomeAddress = false;
        }
        this.setZipcode.emit(this._appSession.addressObj.homeAddress.zipCode);
      }
      if (this.addressModel === ADDRESS_SELECT.WORK) {
        this._appSession.addressObj.addressModel = ADDRESS_SELECT.WORK;
        this._appSession.searchParams.streetAddress =
          this._appSession.addressObj.workAddress?.streetAddress;
        this._appSession.searchParams.addressLine2 =
          this._appSession.addressObj.workAddress?.addressLine2;
        this._appSession.searchParams.city =
          this._appSession.addressObj.workAddress?.city;
        this._appSession.searchParams.stateCd =
          this._appSession.addressObj.workAddress?.state;
        this._appSession.searchParams.postalCode =
          this._appSession.addressObj.workAddress?.zipCode;
        this._appSession.addressObj.homeAddress.isHomeAddress = false;
        this._appSession.addressObj.workAddress.isHomeAddress = true;
        this.setZipcode.emit(this._appSession.addressObj.workAddress.zipCode);
      }
      if (this.addressModel === ADDRESS_SELECT.UPDATE) {
        this._appSession.backToFindCareClicked = false;
        this.closeModal.emit();
      }
    }
    const _routeUrl: string = this._contentHelper.getResolvedUrl(
      this._route.snapshot
    );
    if (
      this._appSession &&
      this._contentHelper.isSearchResultRoute(_routeUrl) &&
      this.addressModel !== ADDRESS_SELECT.UPDATE && !this.showError
    ) {
      this.reloadProviderSummary();
    }
    if (
      this._appSession &&
      this._contentHelper.isSearchResultRoute(_routeUrl) &&
      this.addressModel === ADDRESS_SELECT.UPDATE
    ) {
      this._navigationService.navigateByUrl(
        AppNavigations.SEARCH_PROVIDERS_PATH
      );
    }
    if (!this.showError) {
      this.closeModal.emit();
    }
  }

  isEmptyObject(addressObj: IAddressForm): boolean {
    return addressObj && Object.values(addressObj).every(val => val === null || val === '' || val === false || val === true);
  }

  async getAddressCoordinates({
    city,
    state,
    zipCode,
    streetAddress,
    addressLine2
  }: IAddressForm): Promise<AddressByQueryResponse> {
    try {
      let address = '';
      address = streetAddress ?? '';
      address = addressLine2 ? address.concat(', ', addressLine2) : address;
      address = address?.concat(', ', city).concat(', ', state).concat(' ', zipCode);
      const { latitude, longitude } = (await this._providerUtilityService.getByQuery(address)) ?? {};
      if (latitude && longitude) {
        return {
          latitude,
          longitude
        };
       }
    } catch (error) {
      return Promise.resolve(null);
    }
  }

}
