export class Map<K, V> {
  /**
   * Used to hold any array of the map items.
   */
  private _keys: Array<K> = [];
  private _values: Array<V> = [];

  /**
   * Get an array of keys in the map.
   *
   */
  keys(): Array<K> {
    //return a copy of keys array
    return this._keys.slice();
  }

  /**
   * Get an array of the values in the map.
   *
   */
  values(): Array<V> {
    return this._values.slice();
  }

  /**
   * Check to see if an item in the map exists given it's key.
   *
   */
  has(key: K): boolean {
    return this._keys.indexOf(key) > -1;
  }

  /**
   * Get a specific item from the map given it's key.
   */
  get(key: K): V {
    const i: number = this._keys.indexOf(key);
    return i > -1 ? this._values[i] : undefined;
  }

  /**
   * Set a specific item in the map given it's key, automatically adds new items as needed.
   * Ovewrrites existing items
   *
   */
  set(key: K, value: V): Map<K, V> {
    // check if key exists and overwrite
    const i = this._keys.indexOf(key);
    if (i > -1) {
      this._values[i] = value;
    } else {
      this._keys.push(key);
      this._values.push(value);
    }
    return this;
  }

  /**
   * Provide a number representing the number of items in the map
   *
   */
  get size(): number {
    return this._keys.length;
  }

  /**
   * Clear all the contents of the map
   *
   */
  clear(): Map<K, V> {
    this._keys.length = this._values.length = 0;
    return this;
  }

  /**
   * Delete an item from the map given it's key
   *
   */
  delete(key: K): boolean {
    const i = this._keys.indexOf(key);
    if (i > -1) {
      this._keys.splice(i, 1);
      this._values.splice(i, 1);
      return true;
    }
    return false;
  }

  /**
   * Used to loop through the map.
   *
   */
  forEach(callbackfn: (value: V, key?: K, index?: number) => void): void {
    let i = 0;
    this._keys.forEach((v) => {
      callbackfn(this.get(v), v, i);
      i++;
    });
  }

  sort() {
    const quickSort = (lo: number, hi: number) => {
      if (lo < hi) {
        const p: number = partition(lo, hi);
        quickSort(lo, p - 1);
        quickSort(p + 1, hi);
      }
    };

    const partition = (lo: number, hi: number): number => {
      const pivot: K = this._keys[hi];
      let i = lo - 1;
      for (let j = lo; j <= hi - 1; j++) {
        if (this._keys[j] <= pivot) {
          i = i + 1;
          //swap
          swap(i, j);
        }
      }
      swap(i + 1, hi);
      return i + 1;
    };

    const swap = (i: number, j: number) => {
      //Swap arr[i] with arr[j]

      //1. Swap keys
      const tempKey: K = this._keys[i];
      this._keys[i] = this._keys[j];
      this._keys[j] = tempKey;
      //2. Swap values
      const tempVal: V = this._values[i];
      this._values[i] = this._values[j];
      this._values[j] = tempVal;
    };

    quickSort(1, this._keys.length - 1);
  }
}
