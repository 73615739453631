import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { EmulateCostComponent } from './../app-secure-cost/components/emulateCostCmp';
import { EmulateCostService } from './../app-secure-cost/services/emulateCostSvc';
import { SecureStateHandler } from './../app-secure/services/secureStateHandler';

@NgModule({
    declarations: [EmulateCostComponent],
    exports: [EmulateCostComponent],
    imports: [
        CommonModule,
        UxSharedModule.forRoot(),
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [SecureStateHandler, EmulateCostService]
})
export class AppSecureCostModule { }
