import { IAppContract } from "../interfaces/iAppMetadata";

export enum ChangePlanEvent {
  CHANGE_PLAN = 'changePlan'
}

export interface IChangePlanEvent {
  selectedContract: IAppContract;
  selectedMbrUid: string;
}
