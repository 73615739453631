import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFRatingsComponent } from './pfRatingsCmp';

@NgModule({
    declarations: [PFRatingsComponent],
    exports: [PFRatingsComponent],
    imports: [
        CommonModule,
        UxSharedModule,
    ],
    providers: []
})
export class RatingsModule { }
