import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AppNavigations, PfNavigationEvent } from '../../common/constants/app-navigations';
import { NavigationService } from '../../common/services/navigationService';
import { AppSession } from '../../common/values/appSession';

@Injectable({ providedIn: 'root' })
export class PFBackNavigationService implements OnDestroy {
  private navigationEventListener = null;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _navigationSvc: NavigationService
  ) {}

  updateNavigation() {
    this.resetNavigationDetails(this._navigationSvc?.currentNavigationRoute);
    if (this._appSession.metaData?.isBrowserNavigationEnabled) {
      this.navigationEventListener = new CustomEvent(PfNavigationEvent.BACK, { detail: { action: 'pf-back' } });
      this._appSession.navigationStackCount = this._appSession.navigationStackCount - 1;
      window.dispatchEvent(this.navigationEventListener);
    } else {
      this.navigationEventListener = new CustomEvent(PfNavigationEvent.PF_ROUTER_OUTLET_BACK);
      window.dispatchEvent(this.navigationEventListener);
    }
    this._appSession.initialSearch = true;
  }

  ngOnDestroy() {
    if (this.navigationEventListener) {
      window.removeEventListener(PfNavigationEvent.BACK, this.navigationEventListener);
      window.removeEventListener(PfNavigationEvent.PF_ROUTER_OUTLET_BACK, this.navigationEventListener);
      this.navigationEventListener = null;
      this._appSession.initialSearch = false;
    }
  }

  /**
   * Resets the navigation details based on the provided navigation type.
   * @param navigation - The navigation type.
   */
  resetNavigationDetails(navigation: AppNavigations) {
    switch (navigation) {
      case AppNavigations.PROVIDER_DETAILS_PATH:
      case AppNavigations.PROVIDER_DETAILS_OUTLET:
        this._appSession.selectedProvider = undefined;
        if (this._appSession.isSpecialtyOfficeVisitCost) {
          this._appSession.medicalCode = undefined;
          this._appSession.billingType = undefined;
          this._appSession.selectedPosCode = undefined;
          this._appSession.searchTerm = undefined;
          this._appSession.isSpecialtyOfficeVisitCost = false;
        }
        break;
      case AppNavigations.SEARCH_RESULTS_OUTLET:
      case AppNavigations.SEARCH_RESULTS_NEXT_OUTLET:
      case AppNavigations.SEARCH_RESULTS_PRINT_OUTLET:
      case AppNavigations.SEARCH_RESULTS_PATH:
      case AppNavigations.SEARCH_RESULTS_NEXT:
      case AppNavigations.SEARCH_RESULTS_PRINT_PATH:
        this._appSession.pageNumber = null;
        this._appSession.backToFindCareClicked = true;
        this._appSession.removedTaxonomies = '';
        this._appSession.isMedicalCodeSearch = false;
        this._appSession.searchParams.providerTypeCodeList = [];
        this._appSession.searchParams.ableToServePcp = false;
        break;
      default:
        break;
    }
  }
}
