import { Injectable } from '@angular/core';
import { RouteUtil } from '../utilities/routeUtil';

/**
 *  Content helper class can act as a global content store and register wcs json content againt unique key.
 */
@Injectable()
export class ContentHelper extends RouteUtil {
  protected _contentStore: { [name: string]: any } = {};
  protected _routeContentStore: { [name: string]: any } = {};

  /**
   *  Register/store json content for given key/name in local cache object.
   *
   *  @param name - content cache key/name
   *  @param content - json content string
   *  @return - returns registered content back.
   * */
  registerContent(name: string, content: any): any {
    this._contentStore[name] = typeof content !== 'string' ? content : this.parseJSON(content);
    return content;
  }

    /**
   *  Register/store json content for given key/name in local cache object.
   *
   *  @param path - route cache key/name
   *  @param wcsContent - json content string
   * */
  registerRouteContent(path: string, wcsContent: any) {
    if (this._routeContentStore
      && this._routeContentStore[path] === undefined) {
      this._routeContentStore[path] = wcsContent;
    }
  }

  /**
   *  Allow parsing JSON string to JSON object without JS errors.
   *  @param jsonCont - JSON string to be parsed as JSON object
   *  @return - return JSON parsed object or null if cannot be parsed.
   */
  parseJSON(jsonCont: string): any {
    if (typeof jsonCont !== 'string') {
      return undefined;
    }

    try {
      return JSON.parse(jsonCont);
    } catch (error) {
      console.error(error);
    }

    return undefined;
  }

  /**
   *  return cached JSON object for given key
   *  @param path - content key
   *  @return - return cached JSON object or undefined if not found.
   * */
  getContent(path: string): any {
    return this._contentStore[path];
  }

  /**
   *  return cached JSON object for given key
   *  @param name - route key
   *  @return - return cached JSON object or undefined if not found.
   * */
  getRouteContent(name: string): any {
    return this._routeContentStore[name];
  }

  /**
   *  return all cached JSON object
   *  @return - return cached JSON object or undefined if not found.
   * */
  getAllContent(): any {
    let content: any;

    for (const key of Object.keys(this._contentStore)) {
      const newContent = this._contentStore[key];

      if (content) {
        content = { ...content, ...newContent };
      } else {
        content = newContent;
      }
    }

    return content;
  }

  /**
   * Remove all cached content data
   */
  resetContent() {
    this._contentStore = {};
    this._routeContentStore = {};
  }
}
