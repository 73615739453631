import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { LocationModule } from './../location/locationModule';
import { ControlListComponent } from './controlListCmp';

@NgModule({
    declarations: [ControlListComponent],
    exports: [ControlListComponent],
    imports: [
        FormsModule,
        UxSharedModule,
        CommonModule,
        LocationModule
    ],
    providers: []
})
export class ControlListModule { }
