import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { RadioGroupComponent } from './radioGroupCmp';

@NgModule({
  imports: [
    CommonModule,
    UxSharedModule
  ],
  declarations: [RadioGroupComponent],
  exports: [RadioGroupComponent]
})
export class RadioGroupModule {

}
