import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { MemberKeyService } from './../../services/memberKeySvc';
import { ScheduleAppointmentComponent } from './scheduleAppointmentCmp';

@NgModule({
    declarations: [ScheduleAppointmentComponent],
    exports: [ScheduleAppointmentComponent],
    imports: [
        UxSharedModule,
        CommonModule
    ],
    providers: [MemberKeyService]
})
export class ScheduleAppointmentModule { }
