import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IFinalProvider } from '../interfaces/iCommonSearchResponse';

@Injectable({ providedIn: 'root' })
export class SliderService {
  private slider = new Subject<IFinalProvider>();
  private _locationSliderSubj = new Subject<IFinalProvider>();
  private _ratingSliderSubj = new Subject<string>();
  private _costSliderSubj = new Subject<string>();

  openSlideOut(provider: IFinalProvider): void {
    this.slider.next(provider);
  }

  openLocationSlideOut(provider: IFinalProvider): void {
    this._locationSliderSubj.next(provider);
  }

  openRatingSlideOut(): void {
    this._ratingSliderSubj.next();
  }

  openCostSlideOut(): void {
    this._costSliderSubj.next();
  }

  get onSlideOutOpen(): Observable<IFinalProvider> {
    return this.slider.asObservable();
  }

  get locationSliderObs(): Observable<IFinalProvider> {
    return this._locationSliderSubj.asObservable();
  }

  get ratingSliderObs(): Observable<string> {
    return this._ratingSliderSubj.asObservable();
  }

  get costSliderObs(): Observable<string> {
    return this._costSliderSubj.asObservable();
  }
}
