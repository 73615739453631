import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { BaseComponent } from './../base-component/baseCmp';

@Component({
  selector: 'app-fixed-tray',
  templateUrl: './fixed-tray.component.html'
})
export class FixedTrayComponent extends BaseComponent implements OnInit {
  @Input()
  openTray: boolean;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _contentHelper: ContentHelper
  ) {
    super( _route, _eventHandler, _appSession, _contentHelper, 'FixedTrayComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

}
