import { Injectable } from '@angular/core';
import { ISecureSpecialtySearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { IFinalProvider, IFinalSearchResponse } from '../../../common/interfaces/iCommonSearchResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecureSpecialtyEmailRequest } from '../../../common/interfaces/iSecureSpecialtyEmailRequest';
import {
  ISecureSpecialtySearchCriteria,
  ISecureSpecialtySearchRequest
} from '../../../common/interfaces/iSecureSpecialtySearchRequest';
import { ISecureSearchStrategyResponse } from '../../../common/interfaces/iSecureSpecialtySearchResponse';
import { BaseService } from '../../../common/services/baseService';
import { SecureV1Specialty } from '../../../common/services/secureV1SpecialtySvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { SearchRequestUtil } from '../../utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../utilities/searchResponseUtil';
import { CptSummaryModel } from '../models/cptSummaryModel';
import { API_NAME } from '../values/providerSearchConstants';
import { HttpClientService } from './../../../common/services/httpClientService';

@Injectable({
  providedIn: 'root',
})

export class SecureSpecialtyAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = this.appSession.appConfig?.paginationSize.summaryPrintPage;
  constructor(
    public cptSummaryModel: CptSummaryModel,
    appSession: AppSession,
    private _secureSpecialtySvc: SecureV1Specialty,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility, httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    const apiResponse = await this._secureSpecialtySvc.execute(transformedRequest);
    const transformedResponse = this.transformResponse(apiResponse);
    this.cptSummaryModel.setCptSummaryResponse(transformedResponse);
    return transformedResponse;
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ISecureSpecialtySearchRequest = { ...searchRequest };
    const apiName = SearchRequestUtil.getApiName(API_NAME.SECURE_SPECIALTY);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(email: string, content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ISecureSpecialtyEmailRequest = { ...searchRequest, email };
    const apiName = SearchRequestUtil.getApiName(API_NAME.SECURE_SPECIALTY);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ISecureSpecialtySearchFilterRequest {
    const publicSpecialtySearchFilterRequest = this.transformRequest(
      pageNumber,
      pageSize
    );
    const filterReq: ISecureSpecialtySearchFilterRequest = {
      ...publicSpecialtySearchFilterRequest,
      scenario: SEARCH_SCENARIOS.SECURE_V1_SPECIALTY,
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): ISecureSpecialtySearchRequest {
    const searchParams = this.appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(this.appSession, pageNumber, pageSize);
    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(this.appSession);
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const searchCriteria: ISecureSpecialtySearchCriteria = {
      miscFilter,
      demographicFilter,
      brandCode: this.appSession.metaData.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this.appSession),
      isInitialSearch: this.appSession?.initialSearch
    };

    if (this.appSession?.searchParams?.npi) {
      searchCriteria.npi = this.appSession.searchParams.npi;
    }
    if (this.appSession?.searchParams?.pcpId) {
      searchCriteria.pcpId = this.appSession.searchParams.pcpId;
    }

    return {
      ...commonSearchRequest,
      searchCriteria
    };
  }

  private transformResponse(apiResponse: ISecureSearchStrategyResponse) {
    const response = {} as IFinalSearchResponse;
    const finalProviders: IFinalProvider[] = [];
    if (apiResponse) {
      response.pageNumber = apiResponse.pageNumber;
      response.pageSize = apiResponse.pageSize;
      response.totalPages = apiResponse.totalPages;
      response.totalRecords = apiResponse.totalRecords;
      response.defaultSort = apiResponse.sortBy;
      response.isHPNPlan = apiResponse.isHPNPlan;
      response.isPrimeGroupSearchResponse = apiResponse.isPrimeGroupSearchResponse;
      response.ruleList = apiResponse.rules;
      response.sortOptions = apiResponse.sortOptions;
      response.criteriaChange = apiResponse.criteriaChange;
      response.onsiteClinics = apiResponse?.onsiteClinics ?? undefined;
      response.oonProviders = apiResponse?.oonProviders ?? false;
      if (apiResponse.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IFinalProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            finalProvider.costInfo = provider.costInfo;
            finalProviders.push(finalProvider);
          }
        });
      }
      response.providerList = finalProviders;
      response.sortIndicator = apiResponse.sortIndicator;
    }
    return response;
  }
}
