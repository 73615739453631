import { DISCLAIMER_CONTENT_EN } from './disclaimerContent';

// Define the English content for the home page
export const RESULT_CONTENT_EN = {
  title: 'Results',
  result: {
    labels: {
      viewMap: 'View Map',
      hideMap: 'Hide Map',
      results: 'Search Results'
    }
  },
  provider: {
    callAriaLabel: 'Call',
    phone: 'Press enter to call this provider',
    specialtiesFor: 'Specialties for ',
    costEstimate: 'Cost Estimate: '
  },
  onSiteClinicModal: {
    specialties: 'Specialties'
  },
  searchEmailComponent: {
    emailSearchHeader: 'Email  Search Results',
    emailSearchNote: 'Please note: This PDF only has up to the first 100 providers of your search results.This is not a list of all providers.',
    emailSearchMessage: 'Message: Provider search results from { provDate }'
  },
  providerMap: {
    searchThisArea: 'Search this area',
    recenterMapAltText: 'Recenter map',
    zoomInAltText: 'Zoom in',
    zoomOutAltText: 'Zoom out',
    chatLogoAltText: 'Chat logo'
  },
  memberPlanProgress: {
    remaining: 'remaining',
    available: 'available',
    spent: 'Spent',
    limit: '/ Limit',
    errorDesc: 'We’re having a problem displaying your data here.<br>If you have questions,<a class="ant-font-b" target="_blank" href=""> chat with a Live Agent.</a>',
    spendingAccntInfo: 'Spending account info is not available.',
    planProgressInfo: 'Plan progress info is not available.'
  },
  aria_label: {
    previousClick: 'Left Click',
    nextClick: 'Right Click'
  },
  cptSlideModalContent: {
    missToothClauseInd: 'This service/procedure is subject to a Missing Tooth Clause. Check with the Provider on current eligibility.',
    close: 'Close',
    closeCptSlideModalContent: 'close cpt slide out modal',
    aboutThisProcedureOrService: 'About This Procedure or Service',
    viewAll: {
      text: 'Important alerts about your search results.',
      link: 'View all'
    },
    tabNames: {
      summary: 'Summary',
      outOfPricing: 'Out of Network Pricing',
      alerts: 'Alerts'
    },
    summaryTab: {
      description: {
        label: 'Description',
        content:
          'Ultrasound, limited, joint or other nonvascular extremity structure(s), (e.g., joint space, peri-articular tendon(s), muscles(s), nerve(s), other soft tissue structure, or soft tissue mass(es), real-time with image documentation.'
      },
      secondDescription: {
        label: 'Searching by Medical Code vs Procedure',
        content:
          'When you search by medical code, the cost estimates are only for that one code. But when you visit a doctor or undergo a medical procedure, your treatment may include multiple codes and charges from both the doctor and the facility. Searching by procedure (for example, colonoscopy or knee replacement) instead of medical code will likely give you the best overall cost estimate. Before scheduling an appointment, please check with this care provider to confirm they perform this procedure or service.'
      },
      serviceLimits: {
        heading: 'Service Limits',
        content: "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost.",
        contentAdditionalLimits:
          "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost. This service has other limits. You can find more information about your plan's service limits in <a class=\"go-to-chat\" target='_blank' href=\"/member/benefits?covtype=med\" data-analytics= 'myPlansProcCodeModalFindCare'>My Plans</a>",
        showServiceLimitContent: "Your plan limits the amount covered or the number of times a year that you receive this service. If you go over the limit, you'll need to cover the cost.",
        limit: '<strong>Limit</strong>',
        serviceLimitValue: '{Limits} {LimitUnitDescription} <strong>{LimitsRemaining}</strong>',
        visitsTotalAndUsed: {
          paragraph1: [
            {
              label: '{TotalVisits} visits (',
              type: 'normal'
            },
            {
              label: '{UsedVisits} used ',
              type: 'normal'
            },
            {
              label: ')',
              type: 'normal'
            }
          ]
        }
      },
      preapproval: {
        heading: 'Preapproval is Required',
        content:
          'Preapproval, sometimes called "precertification," "preauthorization," or prior authorization" is the process used to confirm if a procedure is medically necessary. Except in an emergency, preapproval should generally occur before treatment is received.  The doctor who schedules an admission or orders the procedure is usually responsible for obtaining preapproval from the insurance company. Failure to secure preapproval when required may result in denial of payment of a claim.'
      }
    },
    alertTab: {
      alerts: 'Alerts',
      preventiveCareAlertMessage: 'If the purpose of this procedure or service is preventive care, and you use an in-network care provider, your share of the costs may be as low as $0.'
    },
    outOfNetworkTab: {
      oonStaticContent:
        "<strong>Coverage Outside of Your Plan's Network</strong><br><br/><p>Healthcare professionals and facilities not contracted with your health plan are considered out-of-network care providers. They can charge you an amount generally higher than what in-network providers charge. And, since the provider is out of network, you typically have to file your own claims and request preapprovals, if needed.</p><p>An out-of-network provider may bill you for the difference between what your plan has agreed to pay and the full amount charged for a service. This is called <strong>balance billing</strong>.</p><p>For example, suppose the provider charges <strong>$10,000 for a service</strong>, and the allowed by plan amount is <strong>$6,000</strong>. The provider may also bill you for the remaining <strong>$4,000</strong>.</p>",
      estimatedCostSharesHeading: 'Estimated Cost Shares',
      noOonCostShareDataMessage:
        'We\'re not able to show the estimated cost shares for this medical code.  If you have questions about your plan\'s benefits, please <a target="_blank" href="/member/contact-us" data-analytics=\'contactUsOutNetworkModalFindCare\'>contact us.</a>',
      planNotCoveredMessage:
        "Your plan doesn't offer out-of-network coverage for this procedure. This means you'll be responsible for 100% of non-emergency costs if you use an out-of-network care provider.",
      costInfoRadioButton: {
        professional: 'Professional Cost',
        institutional: 'Facility Cost'
      },
      costInfoRadioButtonAnalytics: {
        professional: 'profProcCodeModalFindCare',
        institutional: 'facilityProcCodeModalFindCare'
      },
      billFrom: {
        label: 'Bill From: ',
        value: {
          professional: 'Doctor or Healthcare Professional',
          institutional: 'Hospital or Facility'
        }
      },
      OutOfNetworkCostEstimateContent: {
        introContent:
          "These amounts are estimates for a single procedure or service. The amounts could vary based on your plan's benefits and what the care provider decides to bill. The following estimated cost shares also take into account:",
        costContent: {
          estimatedBill: {
            name: 'Allowed by Plan',
            description: "The most your plan would pay for this service. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim."
          },
          appliedDeductible: {
            name: 'Applied to Out-of-Network Deductible',
            description:
              'The amount that would count toward your deductible, which is the amount you pay for health services before your plan starts sharing the costs. The deductible you owe may be less based on the allowed-by-plan amount. Keep in mind that your out-of-network deductible is higher than your in-network deductible.'
          },
          coinsurance: {
            name: 'Coinsurance',
            description:
              "The amount you'd pay for a covered service after you’ve met your deductible. Your coinsurance is a percentage of the allowed-by-plan amount. Usually, that percentage is higher for out-of-network care than for in-network care."
          },
          copay: {
            name: 'Copay',
            description: "The flat-dollar amount you'd pay for certain health care services. This amount is what you'd pay each time this service is delivered."
          }
        },
        costContentForPercentage: {
          estimatedBill: {
            name: 'Allowed by Plan',
            description:
              "The allowed-by-plan amount is a percentage of the billed charges, which could vary. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim."
          }
        },
        professionals: 'Professionals: ',
        facilities: 'Facilities: ',
        selected: 'Selected',
        notSelected: 'Not Selected'
      },
      oonPricingStaticContent: {
        paragraph1: [
          {
            label: "Coverage Outside of Your Plan's Network",
            type: 'bold'
          }
        ],
        paragraph2: [
          {
            label:
              'Healthcare professionals and facilities not contracted with your health plan are considered out-of-network care providers. They can charge you an amount generally higher than what in-network providers charge. And, since the provider is out of network, you typically have to file your own claims and request preapprovals, if needed.',
            type: 'normal'
          }
        ],
        paragraph3: [
          {
            label: 'An out-of-network provider may bill you for the difference between what your plan has agreed to pay and the full amount charged for a service. This is called ',
            type: 'normal'
          },
          {
            label: 'balance billing.',
            type: 'bold'
          }
        ],
        paragraph4: [
          {
            label: 'For example, suppose the provider charges ',
            type: 'normal'
          },
          {
            label: '$10,000 for a service, ',
            type: 'bold'
          },
          {
            label: 'and the allowed by plan amount is ',
            type: 'normal'
          },
          {
            label: '$6000. ',
            type: 'bold'
          },
          {
            label: 'The provider may also bill you for the remaining ',
            type: 'normal'
          },
          {
            label: '$4,000.',
            type: 'bold'
          }
        ]
      }
    }
  },
  cptCostEstimateSidePanelContent: {
    serviceLimits: {
      heading: 'Service Limits',
      content: "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost.",
      contentAdditionalLimits:
        "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost. This service has other limits. You can find more information about your plan's service limits in <a class=\"go-to-chat\" target='_blank' href=\"/member/benefits?covtype=med\" data-analytics= 'myPlansProcCodeModalFindCare'>My Plans</a>",
      showServiceLimitContent: "Your plan limits the amount covered or the number of times a year that you receive this service. If you go over the limit, you'll need to cover the cost.",
      limit: '<strong>Limit</strong>',
      serviceLimitValue: '{Limits} {LimitUnitDescription} <strong>{LimitsRemaining}</strong>',
      visitsTotalAndUsed: {
        paragraph1: [
          {
            label: '{TotalVisits} visits (',
            type: 'normal'
          },
          {
            label: '{UsedVisits} used ',
            type: 'normal'
          },
          {
            label: ')',
            type: 'normal'
          }
        ]
      }
    },
    orthProcIndTrue:
      ' <strong>Important</strong> - This is the most current information based on claims processed.  Benefits are subject to the terms, limitations, and exclusions of your dental plan.  Your plan covers medically necessary orthodontic services, with specific contract limitations.  Eligibility and benefits are evaluated monthly.  If treatment continues past eligibility dates, no further payments will be available.',
    orthProcIndFalse:
      ' <strong>Important</strong> - This is the most current information based on claims processed.  Benefits are subject to the terms, limitations, and exclusions of your dental plan.  Your plan covers these dental services, with specific contract limitations.  If these contract limitations have been exceeded, you will need to cover the cost.',
    heading: 'Cost Estimate for Billing Code',
    introContent:
      "These amounts are estimates for a single procedure or service. We also take into account your plan's benefits and the rates of care providers in your area.<br><br>Keep in mind your treatment can include many codes. Each code can include charges from both a doctor and a facility. Other factors can impact your actual cost — including if your plan has a 'tiered' network and whether your care involves primary care vs. specialty care. We may not be able to show your cost-sharing responsibility for facility charges for all procedures and services. What you pay is also impacted by:",
    whatYouCouldPay: 'Cost Estimate for Office Visit',
    memberResponsibilityDesc:
      "The amount you'd owe after any discounts, savings, and plan payments have been applied.This amount also factors in how much is left to pay towards your deductible and out-of-pocket maximum.",
    officeVisitIntroContent:
      'Here\'s the cost breakdown for an <span class="ant-font-b">office visit for a current patient that lasts 30-39 minutes.</span> Keep in mind these amounts are estimates based on your plan\'s benefits. Your actual cost may be different. Labs, X-rays, and other tests or procedures during your visit could also increase your costs.',
    costContent: {
      estimatedBill: {
        name: 'Allowed by Plan',
        description: "The most your plan would pay for this service. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim."
      },
      planPays: {
        name: 'Plan Pays',
        description: 'The amount that would be covered by your plan for this service. This amount is calculated by applying any copay, deductible, and coinsurance to the allowed-by-plan amount.'
      },
      appliedDeductible: {
        name: 'Applied to In-Network Deductible',
        description:
          'The amount that would count toward your deductible, which is the amount you pay for health services before your plan starts sharing the costs. The deductible you owe may be less based on the allowed-by-plan amount.'
      },
      coinsurance: {
        name: 'Coinsurance',
        description: "The amount you'd pay for a covered service after you’ve met your deductible.  Your coinsurance is a percentage of the allowed-by-plan amount."
      },
      copay: {
        name: 'Copay',
        description: "The flat-dollar amount you'd pay for certain health care services."
      },
      memberResponsibility: {
        name: 'Cost Estimate',
        description:
          "The amount you'd owe after any discounts, savings, and plan payments have been applied. This amount also factors in how much is left to pay towards your out-of-pocket maximum and deductible. (To view your out-of-pocket and deductible progress, go to My Plans > Medical Benefits > Plan Progress)."
      }
    },
    costContentForPercentage: {
      estimatedBill: {
        name: 'Allowed by Plan',
        description:
          "The allowed-by-plan amount is a percentage of the billed charges, which could vary. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim."
      }
    },
    medicalCodeContent: {
      posCd: 'Where You Get Care: ',
      modifierCd: 'Medical Code Option: '
    }
  },
  dollarCptCostColumn: {
    estimatedBill: 'Allowed by Plan',
    planPays: 'Plan Pays',
    memberResponsibility: 'Cost Estimate'
  },
  costDetailSlideModalContent: {
    costEstimateProcedure: 'Cost Estimate for Procedure',
    whatYouCouldPay: 'Cost Estimate',
    costEstimate: 'Cost Estimate',
    costDetails: 'Cost Details',
    closeCostDetailsModal: 'Close Cost Details Modal',
    headerText1: "Here's how we arrived at the Cost Estimate amount. Keep in mind these amounts are estimates based on your plan's benefits. Your actual cost may be different.",
    headerText2: '<a class="go-to-chat" target="_blank" href="/member/benefits?covtype={{COVERAGE}}">Check your coverage details</a> to help avoid unexpected costs.',
    estimatedBillDescription: 'The average amount this care provider has agreed to bill someone with your plan for this service.',
    averageBillDescription: 'The average amount this care provider has agreed to bill for this service in your plan.',
    hraPaysDescription: 'The amount that would be paid by your Health Reimbursement Account.',
    hsaPaysDescription: 'The amount that would be paid by your Health Saving Account.',
    planPaysDescription: "The most your plan would pay for this service. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim.",
    appliedDeductibleDescription: 'The amount that would count toward your deductible, which is the amount you pay for health services before your plan starts sharing the costs.',
    coinsuranceDescription: "The amount you'd pay for a covered service after you’ve met your deductible. Your coinsurance is a percentage of the allowed-by-plan amount.",
    copayDescription: "The flat-dollar amount you'd pay for certain health care services.",
    youPayDescription:
      "The amount you'd owe after any discounts, savings, and plan payments have been applied. This is also known as out-of-pocket amount. This amount also factors in how much is left to pay towards your deductible and out-of-pocket maximum.",
    close: 'Close',
    notAvailable: 'Not available',
    noCost: 'Cost estimate not available',
    noBenefit: 'Your plan does not cover this procedure.',
    cdhpError: 'Your {{ACCOUNTTYPE}} funds could lower the amount you pay.'
  },
  eyc: {
    noData: 'No data',
    cdhpErrorText: 'Your spending account funds could lower the amount you pay.',
    cdhpError: 'We’re sorry, but we can’t get your {ACCOUNTTYPE} balance right now. {ACCOUNTTYPE} funds could lower the amount you pay.',
    youPay: 'What You Pay',
    estimatedCost: 'Estimated Bill',
    estimatedFacilityTHCCost: 'Estimates for this facility can be as high as:',
    estimatedFacilityTLCCost: 'Estimates for this facility can be as low as:',
    estimatedDoctorTHCCost: 'Estimates for this doctor can be as high as:',
    estimatedDoctorTLCCost: 'Estimates for this doctor can be as low as:',
    noCostDataText: "We're not able to show a cost estimate for this procedure.",
    coPayText: 'Copay',
    coInsuranceText: 'Coinsurance',
    overageChargeText: 'Overage',
    deductibleText: 'Applied to Deductible',
    planPaysText: 'Plan Pays',
    oopNotAvailableText: 'Plan Pays and What You Pay amounts aren’t available.',
    isBenefitDeniedMsg: 'We are sorry! Your plan does not cover this procedure.',
    totalEstimateCostText: 'Care Provider Bill',
    totalText: 'Est Total',
    averageCostText: 'Average Price',
    dentalAverageHover:
      'The estimated amount this care provider may bill if this service is covered by your plan. This is also known as the Allowed Amount. Your out-of-pocket costs will depend on your plan benefits, coinsurance, and deductible (if applicable).',
    rangeCountHover: 'The amount you pay could differ at each hospital. View those estimates below.',
    noCostHover: 'The total cost could <br>differ at each hospital. <br>View those estimates below.',
    costRange: 'Estimated bill for',
    costCDHPPaysText: '{ACCOUNTTYPE} Pays',
    costBeforeCDHPBalance: 'Your costs before {ACCOUNTTYPE} funds are used:',
    for: 'For',
    near: 'near',
    costRangeNew: 'Estimated bill for {PROCEDURENAME}: <span class="ant-font-b">{COSTRANGE}</span>',
    costRangeToolTipMsg:
      'This is the range of Estimated Bill amounts that we have cost data for. The Estimated Bill is the average amount a care provider has agreed to bill for this service in your plan.',
    forNew: 'For <span class="ant-font-b">{MEMBER}</span> ',
    nearNew: 'near <span class="ant-font-b">{ZIP}</span>',
    averagePay: 'Average Bill',
    hideAffiliation: 'Hide cost estimates at different hospitals',
    showAffiliation: 'View cost estimates at different hospitals',
    name: 'Name',
    spendingAccntInfo: 'Account Balance',
    youpaytooltip: 'Select to get your detail pay information',
    showPrices: 'Show More Prices',
    hidePrices: 'Hide More Prices',
    ariaLabel: {
      showPrices: 'Select enter to show more prices',
      hidePrices: 'Select enter to hide more prices'
    },
    selectforMoreInfo: 'Select for more information about',
    arialLblEsitmateBillHeader: 'Select for more information about Estimated bill for {PROCEDURENAME}',
    arialLblEsitmateCostHeader: 'Select for more information about Estimated cost for {PROCEDURENAME}',
    coldStateCostRangeTooltipMsg:
      'This cost estimate is based on claims for this procedure submitted to us by care providers in your area. Members should register and log in to get a more exact estimate, including out-of-pocket amounts based on their plan.',
    costRangeColdState: 'Estimated cost for {PROCEDURENAME}: <span class="ant-font-b">{COSTRANGE}</span>'
  },
  percentageCptCostColumn: {
    allowedByPlan: 'Allowed by Plan',
    youPay: 'What You Could Pay',
    costDetail: 'Cost Details'
  },
  cptMedicalSearchComponent: {
    hraBalance: ' HRA balance of ',
    errorScenarios: {
      noResultsMessage:
        '<span class="ant-font-b"> No Results.</span> Try a different ZIP code or use &quot;Back to Find Care&quot; to start over. <a target="_blank" href="/member/contact-us">Contact Us</a> if you have questions about your plan\'s benefits.',
      noResultsIncreasedRadiusMessage:
        '<span class="ant-font-b"> No results.</span> We\'ve expanded your search distance to <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span> but didn\'t find any results. <a target="_blank" href="/member/contact-us">Contact Us</a> if you have questions about your plan\'s benefits.',
      increasedRadiusMsgNew:
        'We increased the search distance to <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span> for <span class="ant-font-b">{USR} in </span><span class="ant-font-b">{PLN}</span> with remaining deductible of ',
      isBenefitDefinedScenario1: 'Unable to show some cost data',
      isBenefitDefinedScenario2: 'Unable to show cost data',
      planNotCovered: 'Your plan doesn\'t cover this procedure. <a target="_blank" href="/member/contact-us">Contact Us</a> if you have questions about your plan\'s benefits.',
      planNotCoveredAriaLabel: "Your plan doesn't cover this procedure. Contact Us if you have questions about your plan's benefits.",
      costInfoIsPlanNotCoveredScenario: [
        {
          id: 'cost-info-isplannotcovered-scenario',
          orderSequence: '1',
          displayText: "Your plan doesn't cover this procedure. {linkLabel} if you have questions about your plan's benefits.",
          ariaLabel: "Your plan doesn't cover this procedure. {linkLabel} if you have questions about your plan's benefits.",
          isAnchorText: true,
          inAppNavigation: true,
          mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
          navigationUrl: '',
          linkLabel: 'contact us.'
        }
      ]
    }
  },
  cptTooltips: {
    posCodes: "A procedure or service can have different costs depending on the setting where it's performed.",
    modifierCodes: 'A procedure or service can have variations that affect costs.',
    allowedByPlan: "The most your plan will pay for this service. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim."
  },
  healthwiseModal: {
    tabs: {
      summary: 'Summary',
      notices: 'Notices'
    },
    close: 'Close',
    closeHealthwiseModal: 'close healthwise modal',
    emptyRepsonseAlertMssg: 'We’re sorry. We don’t have an explanation of this term yet.',
    failureAlertMssg: 'We’re sorry. We would typically explain this term here, but our system isn’t working correctly right now. Please try again later.'
  },
  summaryComponent: {
    aboutYourSearch: 'About Your Search',
    noResults: 'No Results',
    notFindingFilterResults: "Not finding what you're looking for?",
    tryChangeFilter: 'Try changing the search filters.',
    learnMoreLabel: 'Learn More',
    moreDetails: 'Select enter for provider details',
    pageText: 'Page',
    firstPageText: 'First Page',
    lastPageText: 'Last Page',
    previousPageText: 'Previous Page',
    nextPageText: 'Next Page',
    callAriaLabel: 'Call',
    providingCareAt: 'Providing care at',
    performedBy: 'Performed by',
    countyText: 'County: ',
    viewInfo: 'View important information about this tool',
    miles: 'miles',
    getDirections: 'Get Directions',
    beFirstReview: 'Be the First to Review',
    reviewThisDoctor: 'Review This Doctor',
    addReview: 'Add a Review',
    noRating: 'No Rating Available',
    beFirstReviewDataAnalytics: 'beTheFirstToReviewResults',
    reviewThisDoctorDataAnalytics: 'reviewThisDoctorResults',
    mapmarker: 'Press Enter to get map tracker',
    phone: 'Press enter to call this doctor',
    phoneNumber: 'Phone number:',
    drivingDirectionAddrPlaceholder: 'with address of',
    drivingTime: 'Driving time ',
    filterChange: ' or refine your search results using any combination of filters below, then select Apply.',
    seeAll: ' View All',
    morePCP: 'Select enter for PCP details',
    viewMoreAddress: 'View More Addresses',
    noDetails: 'Details Unavailable',
    viewMoreAddrAriaLbl: 'Select enter to view more address details',
    hoursToday: 'Hours Today',
    open24Hours: 'Open 24 hours',
    servingCounty: 'County',
    servingText: 'Serving',
    medicalNetwork: 'In-Network Medical Plan',
    visionNetwork: 'In-Network Vision Plan',
    dentalNetwork: 'In-Network Dental Plan',
    liveHealthText: 'Your benefits include the option to visit a doctor 24/7 via LiveHealth Online.',
    liveHealthLink: 'Visit with a doctor now or schedule an appointment.',
    compareHintMsg: 'Select 2 to 3 Items to Compare',
    compareLabel: 'Compare',
    selectedProviderCountLabel: 'selected out of 3',
    addressNotAvailable: 'Address not available',
    resultsLabel: 'results',
    sortByDrivingDistance: 'sorted by driving distance',
    pinLabel: 'Click on a pin to see providers details',
    providerExperienceText: 'performed',
    providerExperienceToolTip: 'Based on member claims, we know this doctor has performed this procedure at least {experience} times in the last year.',
    onsiteClinicText: 'Your benefits include care from this clinic.',
    onsiteClinicPhone: ' Call {phone}.',
    onsiteClinicWeb:
      " <a target=\"_blank\" href=\"{weburl}\" id='lnk-clinic' class='ONSITECLINICS' aria-label='click here to navigate to Onsite clinic website'>Visit website.</a><span class='fa fa-external-link gray'></span>",
    topChoiceLabel: 'TOP CHOICES FOR YOU',
    topChoiceTooltipSydMedicaidLearnMore:
      'Using Personalized Match Phase 1 sorting, these are the top three choices for you.<a target="_blank" href="{pdfUrl}" aria-label=\'click here to navigate to Learn More\' data-analytics=\'provDetailsTopChoiceResultsFindCare\'> Learn More  </a>',
    topChoiceTooltipSydMedicaidLearnMoreNJ:
      'Using Personalized Match sorting, these are the top three choices for you.<a target="_blank" href="{pdfUrl}" aria-label=\'click here to navigate to Learn More\' data-analytics=\'provDetailsTopChoiceResultsFindCare\'> Learn More  </a>',
    topChoiceTooltipSydMedicaid: 'Using Personalized Match Phase 1 sorting, these are the top three choices for you.',
    topChoiceTooltipSydMedicaidNJ: 'Using Personalized Match sorting, these are the top three choices for you.',
    topChoiceTooltip:
      'According to our Personalized Match sorting, these are the top three choices for you. Personalized Match takes into account your health conditions, quality measures, and cost. <a target="_blank" href="{pdfUrl}" aria-label=\'click here to navigate to Learn More\' data-analytics=\'provDetailsTopChoiceResultsFindCare\'> Learn More </a>',
    moreOptionsTooltipSydMedicaid:
      "We've sorted these search results using Personalized Match Phase 1 to help you find providers that are a good fit for you based on quality measures and best value. In the future, Personalized Match will take into account your health conditions. You can re-sort these search results by distance or name.",
    moreOptionsTooltipSydMedicaidNJ:
      "We've sorted these search results using Personalized Match to help you find providers that are a good fit for you based on quality measures and best value. In the future, Personalized Match will take into account your health conditions. You can re-sort these search results by distance or name.",
    moreOptionsTooltipSydMedicaidLearnMore:
      "We've sorted these search results using Personalized Match Phase 1 to help you find providers that are a good fit for you based on quality measures and best value. In the future, Personalized Match will take into account your health conditions. You can re-sort these search results by distance or name. <a target=\"_blank\" href=\"{pdfUrl}\" aria-label='click here to navigate to Learn More' data-analytics='provDetailsMoreOptionsResultsFindCare'> Learn More </a>",
    moreOptionsTooltipSydMedicaidLearnMoreNJ:
      "We've sorted these search results using Personalized Match to help you find providers that are a good fit for you based on quality measures and best value. In the future, Personalized Match will take into account your health conditions. You can re-sort these search results by distance or name. <a target=\"_blank\" href=\"{pdfUrl}\" aria-label='click here to navigate to Learn More' data-analytics='provDetailsMoreOptionsResultsFindCare'> Learn More </a>",
    moreOptionsLabel: 'MORE OPTIONS',
    moreOptionsAriaLabel: 'Select for more information about More Options',
    topChoiceAriaLabel: 'Select for more information about Top Choices For You',
    moreOptionsTooltip:
      "We've sorted these search results using Personalized Match to help you find care providers who would be a good fit for you. Personalized Match takes into account your health conditions, quality measures, and cost.  You can always resort these search results by distance or name. <a target=\"_blank\" href=\"{pdfUrl}\" aria-label='click here to navigate to Learn More' data-analytics='provDetailsMoreOptionsResultsFindCare'> Learn More </a>",
    atLabel: 'at ',
    forLabel: 'for ',
    mapLabel: 'On The Map',
    virtualOptions: 'Virtual Options',
    virtualOptionsInfo: 'Care providers who are in your search results but only offered online.',
    officeLocation: 'Location',
    adaAccessibleTitle: 'ADA Accessible:',
    recognitionAdobeTag: 'recognitionsLinkResultsFindCare',
    providerRecognitionTab: 'providerTabRecognitionsModalFindCare',
    facilityRecognitionTab: 'facilityTabRecognitionsModalFindCare',
    personalizedMatch: {
      personalizedMatchTopChoice: 'Personalized Match: Top Choice',
      whatIsPersonalizedMatch: 'What is Personalized Match?',
      whatIsPersonalizedMatchSydMedicaid: 'What is Personalized Match Phase 1?',
      personalizedMatchMsgSydMedicaid:
        "We've sorted these search results using Personalized Match Phase 1 to help you find providers that are a good fit for you based on quality measures and best value. In the future, Personalized Match will take into account your health conditions. Our provider performance sorting tool uses quality and efficiency scores to help you make informed choices about your medical care. You can re-sort these search results by distance or name.",
      personalizedMatchMsgSydMedicaidNJ:
        "We've sorted these search results using Personalized Match to help you find providers that are a good fit for you based on quality measures and best value. In the future, Personalized Match will take into account your health conditions. Our provider performance sorting tool uses quality and efficiency scores to help you make informed choices about your medical care. You can re-sort these search results by distance or name.",
      personalizedMatchMsg:
        "We've sorted these search results using Personalized Match to help you find care providers who would be a good fit for you. Personalized Match takes into account your health conditions, quality measures, and cost.  You can always re-sort these search results by distance or name.",
      personalizedMatchPdfLink: 'https://findcare.anthem.com/assets/documents/personalizedprovidersearch.pdf',
      learnMore: ' Learn More',
      learnMoreAriaLabel: 'Learn More about personalized match.PDF'
    },
    personalizedMatchPdf: {
      snr: 'https://fadsecure.sit4.awse1.anthem.com/assets/documents/medicarePM_en.pdf',
      commercial: 'https://fadsecure.sit4.awse1.anthem.com/assets/documents/commercialPM_en.pdf'
    },
    filter: {
      cme: 'Centers Of Medical Excellence',
      showMoreFilters: 'Show {COUNT} more',
      showLess: 'Show less',
      filter: 'Filter',
      filterRemove: 'Filter remove',
      level: 'Level',
      preferred: 'Preferred',
      features: 'Features',
      type: 'Type',
      plan: 'Plan Network',
      pharmacyType: 'Pharmacy Type',
      refineResultsHeading: 'Refine Results',
      hospDoctText: 'Hospitals / Doctors',
      quality: 'Recognitions/Tier',
      specialty: 'Specialty ',
      subspecialty: 'Sub-Specialty',
      levelofcare: 'Level of Care',
      patientpreference: 'Patient Population',
      areaofexpertise: 'Area of Expertise',
      adaAccessible: 'ADA Accessible',
      officeService: 'Office Service',
      ethnicity: 'Ethnicity',
      languageSpoken: 'Other Languages',
      distance: 'Distance ',
      general: 'General',
      gender: 'Gender ',
      boardcertification: 'Board certified',
      boardcertification1: 'Board Certified',
      tier: 'Provider Tier',
      npi: 'National Provider Identifier(NPI)',
      licensetype: 'License Type',
      licensenumber: 'License Number',
      phone: 'Provider Phone',
      email: 'Provider Email',
      servicesAvailable: 'Services Available',
      hospitalAffiliation: 'Hospital Affiliation',
      groupAffiliation: 'Medical Group or IPA',
      yourMedicalGroup: 'Your Medical Group',
      yourHealthSystem: 'Your Health System',
      healthSystem: 'Health System: ',
      medicalGroup: 'Medical Group: ',
      selectOne: 'Select One',
      options: 'Other Options',
      profile: 'Profile',
      affiliation: 'Affiliations',
      pcp: 'ASSIGNED AS PCP',
      pcp1: 'Primary Care Physician',
      keyFilters: 'Key Filters',
      moreFilters: 'More Filters',
      viewMoreFilterOptions: 'View more filter options',
      ariaApply: 'Provider Search Apply',
      apply: 'Done',
      applyAriaLabel: 'Apply',
      close: 'Close',
      closeAriaLabel: 'Close selections',
      clear: 'Clear',
      clearall: 'Clear All',
      filterLabel: 'filter',
      providers: 'Providers',
      clearAllFilters: 'Clear Filters',
      enterNumber: 'Enter number',
      enterEmail: 'Enter email',
      clearfilter: 'Clear Filter',
      emailError: 'Enter a valid email address',
      phoneError: 'Enter a valid phone number',
      npiError: 'Enter a valid NPI number',
      noResultMsg: 'Nothing found. Please check your spelling or try another term',
      search: 'Search',
      hospitalSearch: 'Enter a hospital name',
      blueDistictionHelpText: 'Recognized for expertise in delivering specialty care',
      cmeText: 'Healthcare facilities recognized for meeting quality criteria for specialty care.',
      medicalGrpSearch: 'Enter a medical group or IPA (independent physician association)',
      adaAccessibleFilter: 'Accessibility information is reported to us by the care provider and can change.',
      searchHospAriaLabel: 'Enter 3 or more characters to update search results by selected Hospital Affiliation filter. Matching Hospital Affiliation filters will populate after the search box.',
      searchMedicalAriaLabel: 'Enter 3 or more characters to update search results by selected Medical Group or IPA filter. Matching Medical Group or IPA filters will populate after the search box.',
      bdc: 'Blue Distinction',
      bdcPlus: 'Blue Distinction Centers+',
      bdcDescription: 'Filter by expertise in delivering:',
      groupAffiliationDescription: 'Filter by medical group or independent physician association (IPA):',
      filter508header: 'Selecting any of the below filter controls, will refresh the search results grid with the new filtered results.',
      ariaLblForPillButton: 'By selecting this filter a list of options will be displayed. Navigate to the list to add or remove options',
      clearAriaLabel: 'Remove selections',
      recognition: 'Recognitions',
      clearAll: 'Clear All',
      bdcValue: {
        BDBAR: 'Bariatric Surgery',
        BDCANC: 'Cancer Care',
        BDCAR: 'Cardiac Care',
        BDCIT: 'Cellular Immunotherapy',
        BDFC: 'Fertility Care',
        BDGT: 'Gene Therapy',
        BDKAH: 'Knee & Hip Replacement',
        BDMAT: 'Maternity Care',
        BDSPS: 'Spine Surgery',
        BDTPT: 'Transplants',
        BDSTR: 'Substance Use Treatment'
      },
      labels: {
        medicalPlan: 'Medical Network',
        dentalPlan: 'Dental Network',
        visionPlan: 'Vision Network',
        allPlans: 'All Your Networks',
        oonPlan: 'Out of Network',
        scheduleAppointment: 'Appointment Scheduling',
        cooperativeCare: 'Cooperative Care',
        sosTier: 'Site of Service (SOS)',
        sosTier1: 'Site of Service',
        LGBTQAlly: 'LGBTQ+ Ally',
        greenRibbon: 'Green Ribbon',
        benefitTier1: 'Tier 1',
        bluePrecision: 'Blue Precision',
        blueDistinctionTotalCare: 'Total Care',
        enhancedPHC: 'Enhanced Personal Health Care',
        centerOfExcellence: 'Center of Excellence',
        providerOfDistinction: 'Provider of Distinction',
        msp: 'Designated Medical Specialty Pharmacy',
        valueBasedProvider: 'Value Based Providers',
        bvvProvider: 'Vision PLUS',
        hppProvider: 'HPP',
        ableToServePCP: 'Able to serve as Primary Care Physician (PCP)',
        integratedPCP: 'Can serve as Primary Care Physician (PCP)',
        integratedPCP2: 'Serve as PCP',
        ableToServePCP_ariaLabel: 'Primary Care Physician',
        acceptsMedicaid: 'Accepts Medicaid',
        ecpprovider: 'ECP Provider',
        omh: 'Office of Mental Health',
        oasas: 'Office of Alcoholism and Substance Abuse Services',
        bcp: 'Preferred Specialists',
        acceptNewPatient: 'Accepting new patients',
        acceptNewPatient1: 'Accepts New Patients',
        level1: 'Level 1',
        preferred1: 'Preferred',
        all: 'All Features of a Pharmacy',
        twentyfourhour: '24 Hour Services',
        rxMaintenance: 'RX Maintenance 90',
        drivethrough: 'Drive Through Service',
        onsiteMedical: 'Onsite Medical Clinic',
        bloodPressure: 'Blood Pressure Screening',
        fluShots: 'Flu Shots',
        homeDelivery: 'Home Delivery',
        vaccinations: 'Vaccinations',
        coumpoundMedications: 'Compound Medications',
        durableMedical: 'Durable Medical Equipment',
        allTypes: 'All Types of Pharmacies',
        communityRetail: 'Community / Retail',
        longTerm: 'Long Term Care',
        mailOrder: 'Mail Order',
        homeInfusion: 'Home Infusion Therapy Provider',
        nonPharmacy: 'Non-Pharmacy Dispensing Site',
        indianHealth: 'Indian Health Service / Tribal / Urban Indian Health (I / T / U)',
        deptOfVeterans: 'Department of Veterans Affairs (VA)',
        institutional: 'Institutional',
        managedCare: 'Managed Care Organization',
        dme: 'DME',
        clinic: 'Clinic',
        specialty: 'Specialty',
        nuclear: 'Nuclear',
        military: 'Military / U.S. Coast Guard',
        compounding: 'Compounding',
        showMore: 'Show More',
        showLess: 'Show Less',
        showAll: 'Show All',
        showMoreInfo: 'show more link will list all the matches ',
        showLessInfo: 'show less link will list the top 6 ',
        viewAllLocationsLabel: 'View All Locations',
        hospitalLocationsCountLabel: 'locations found for this hospital',
        medicalLocationsCountLabel: 'locations found for this Medical group or Ipa',
        close: 'Close',
        location: 'Location',
        acceptTeleHealth: 'Accepting Telehealth',
        acceptTeleHealth1: 'Telehealth',
        upswing: 'Upswing',
        snyDental: 'Dental Discount Program Providers',
        ccare: 'CCare',
        ccare_ariaLabel: 'CCare',
        ariaLabelForBDCTitle: 'Select enter for more information about',
        whatIsA: 'What is a',
        showFilters: 'Show Filters',
        extendedHours: 'Extended Hours',
        lowerCopayPCP: 'Lower Copay PCP'
      },
      vaccines: 'Vaccines',
      tooltips: {
        enhancedPHC: 'An approach where doctors spend more time with patients, coordinate care with other doctors and focus on the best way to help you get healthy and stay healthy.',
        blueDistinctionTotalCare:
          'Healthcare providers recognized for their commitment in coordinating total patient care - emphasizing prevention and wellness and helping patients better manage chronic conditions to achieve improved health outcomes.',
        bluePrecision:
          'Blue Precision designations are awarded to physicians who practice in the following specialties: Allergy/Immunology, Cardiovascular Disease, Endocrinology, Interventional Cardiology, Neurology, Obstetrics and Gynecology (OB/GYN), Orthopedic Surgery, Otolaryngology (ENT), Rheumatology and Pulmonary Medicine. Blue Precision designations may be found for providers practicing in Colorado, Connecticut, Georgia, Indiana, Kentucky, Maine, Missouri, New Hampshire, Nevada, New York, Ohio and Virginia. For additional details about Blue Precision, please click on the Blue Precision Designation Program link found on the Search Results Page of Provider Finder.',
        acceptNewPatient:
          'This information is reported by the doctor directly and updated as reported for each plan. To ensure accurate results, make sure you have a plan selected when using this option.',
        ableToServePCP:
          'Medical professionals other than doctors (like advanced nurse practitioners), may fulfill primary care roles in some areas. To ensure accurate results, make sure you have a plan selected when using this option.',
        acceptsMedicaid: 'Provider identified also accepts Medicaid.',
        ecpprovider: 'An Essential Community Provider (ECP) is a health care provider that serves high-risk, special needs, and under served individuals.',
        blueDistictionCenterPlus: 'Healthcare providers recognized for their expertise and cost efficiency in delivering specialty care.',
        blueDistictionCenter: 'Healthcare providers recognized for their expertise in delivering specialty care.',
        aoe: 'Provider reported proficiency and experience within a field.',
        loc: 'Services provided at a physical facility location.',
        patientPreference: 'Patient demographics a provider treats.',
        cooperativeCare:
          'Cooperative Care doctors are committed to providing a better patient experience including appointments within 24 hours, more ways to see a doctor or nurse, and help navigating the system making sure you get what you need from other doctors and specialists.',
        LGBTQAlly: 'This care provider has completed training and has demonstrated that they understand the needs of members that identify as LGBTQ',
        greenRibbon: 'Green Ribbon providers advance the integration of physical and behavioral health provider networks and facilitate navigation to these innovative care settings.',
        sosTier:
          'Using an SOS provider may result in lower costs to you for laboratory and imaging services. Not all plans offer a Site of Service benefit; please contact Member Services to confirm benefits. In addition, all sites may not provide all testing services; please contact the provider to confirm services.',
        integratedTier1: 'Tier 1 Preferred, which may result in lower costs for you',
        omh: 'The integration of primary care mental health services can help improve the overall quality of care for individuals with multiple health conditions by treating the whole person in a more comprehensive manner.',
        oasas:
          'The integration of substance use disorder services can help improve the overall quality of care for individuals with multiple health conditions by treating the whole person in a more comprehensive manner.',
        bcp: 'These specialists meet our highest standards for quality care and cost benchmarking. They are committed to helping you navigate the health care system by working with your primary care physician (PCP), giving you a better patient experience.',
        extendedHours: 'Open after 5 PM ( on weekdays) or Open during weekends ( Saturday and Sunday).',
        servicesAvailable: {
          VD: 'Offers additional discounts on non-covered services and supplies associated with your vision plan. Please talk with your provider regarding potential discounts.'
        },
        tooltipInfo: 'press enter for more information about ',
        acceptTeleHealth: 'This healthcare professional or location offers virtual, real-time access to some or all medical services typically delivered in the office.',
        upswing:
          'Upswing is an orthopedic management program. Upswing helps you understand what\'s causing your pain and then connects you with a live medical professional without leaving your home. To begin go to <a href="https://www.upswinghealth.com/CT/" target="_blank">www.upswinghealth.com/CT</a>.',
        snyDental: 'This dental provider may charge you less than their normal fee for services not reimbursed by the SEHP Dental Plan.',
        ccare: 'Members that participate in CCare can visit any T.J. Samson Primary Care Provider for a reduced copay, regardless of condition or diagnosis',
        msp: 'We work with facilities to lower the overall cost of certain specialty drugs and reduce out-of-pocket costs for our members. Facilities that agree to participate as a Designated Medical Specialty Pharmacy are considered preferred locations for our members receiving one of the drugs on this list in an outpatient setting. <a href="https://www.anthem.com/ms/pharmacyinformation/Designated-Medical-Specialty-Pharmacy-Drug-List-eff-07012022-AnthemBCBS.pdf" target="_blank">View Specialty Drug List (PDF)</a>',
        valueBasedProvider: 'Anthem Member cost share may be waived or reduced when services are performed by a Value Based Provider.',
        bvvProvider: 'Vision PLUS care providers may maximize your benefits with extra coverage to help you save even more.',
        hppProvider: 'High Performance Providers focus on delivering high-quality care, the right care at the right time, and following best practices for care management.',
        lowerCopayPCP: 'Using a Lower Copay PCP (Primary Care Physician) may result in lower cost for you.'
      },
      expand: {
        level: 'Press Enter to expand or collapse level filters',
        preferred: 'Press Enter to expand or collapse preferred filters',
        features: 'Press Enter to expand or collapse features filter',
        type: 'Press Enter to expand or collapse type filter',
        hospitals: 'Press Enter to expand or collapse hospitals or doctors filters',
        recoginition: 'Press Enter to expand or collapse recognitions filters',
        blueDistinctionCenters: 'Press Enter to expand or collapse Blue Distinction Centers filters',
        specialty: 'Press Enter to expand or collapse Specialty filters',
        subSpecialty: 'Press Enter to expand or collapse Sub Specialty filters',
        levelofcare: 'Press Enter to expand or collapse level of care filters',
        distance: 'Press Enter to expand or collapse Distance filters',
        gender: 'Press Enter to expand or collapse Gender filters',
        options: 'Press Enter to expand or collapse Options filters',
        servicesAvailable: 'Press Enter to expand or collapse Services Available filters',
        language: 'Press Enter to expand or collapse Language Spoken in addition to English filters',
        hospitalAffiliation: 'Press Enter to expand or collapse Hospital Affiliation filters',
        showAffiliation: 'Press Enter to expand or Medical group or IPA filters',
        ethnicity: 'Press Enter to expand or collapse ethnicity filters',
        areaofexpertise: 'Press Enter to expand or collapse Area of expertise filters',
        patientPopulation: 'Press Enter to expand or collapse  Patient Population filters',
        boardcertification: 'Press Enter to expand or collapse  board certification filters',
        tier: 'Press Enter to expand or collapse  tier filters',
        npi: 'Press Enter to expand or collapse  npi filters',
        licensetype: 'Press Enter to expand or collapse  license type filters',
        licensenumber: 'Press Enter to expand or collapse  license number filters',
        phone: 'Press Enter to expand or collapse  phone filters',
        email: 'Press Enter to expand or collapse  email filters',
        medicalGroup: 'Press Enter to expand or collapse Medical Group / IPA filters'
      }
    },
    genderList: {
      M: 'Male',
      F: 'Female'
    },
    adminOffice: 'Administrative Office Only',
    sortBy: 'Sort by',
    sortOptions: {
      distance: 'Distance',
      atoz: 'A - Z',
      ztoa: 'Z - A',
      atoz_eyc: 'A - Z',
      ztoa_eyc: 'Z - A',
      quality: 'Recognitions',
      tier: 'Benefit Tier',
      ppc: 'PPC(Preferred Primary Care)',
      blue_distinction: 'Blue Distinction',
      personalizedmatch: 'Personalized Match',
      personalizedmatch_eyc: 'Personalized Match',
      least_expensive: 'Your Cost (Low to High)',
      most_expensive: 'Your Cost (High to Low)',
      cooperative_care: 'Cooperative Care',
      preferred: 'Preferred',
      dh: 'Dignity Health',
      cost_low_to_high: 'Your Cost (Low to High)',
      cost_high_to_low: 'Your Cost (High to Low)'
    },
    sortByPlaceholder: 'Sort by: ',
    select: 'Select',
    physician: 'Provider',
    location: 'Location(s)',
    appointmentSchedule: 'Book an Appointment',
    selectPhysician: 'Select Provider',
    inNetwork: 'In Network',
    inNetworkLabel: "In Your Plan's Network",
    oonNetworkLabel: "Our system can't determine if this care provider is in your plan's network. Please contact this care provider to check network status.",
    balance: 'Balance:',
    remainingDeductible: 'Remaining Deductible: ',
    networkStatusSummary: {
      TP_INNETWORK: 'In-Network {PLANTYPE} Plan',
      TP_OUTNETWORK: 'Out-of-Network {PLANTYPE} Plan',
      TP_ASOF: 'In-Network {PLANTYPE} Plan starting {DATE}',
      TP_UNTIL: 'In-Network {PLANTYPE} Plan until {DATE}',
      NOT_INNTWK_ASOF: 'Not In-Network as of {DATE}'
    },
    networkStatus: {
      TP_INNETWORK: "In this plan's {PLANTYPE} network",
      TP_OUTNETWORK: "Not in this plan's {PLANTYPE} network",
      TP_ASOF: "In this plan's {PLANTYPE} network starting {DATE}",
      TP_UNTIL: "In this plan's {PLANTYPE} network until {DATE}",
      NOT_INNTWK_ASOF: 'Not In-Network as of {DATE}'
    },
    networkStatusSecured: {
      TP_INNETWORK: "In your {PLANTYPE} plan's network",
      TP_OUTNETWORK: "Not in your plan's network",
      TP_ASOF: "In your {PLANTYPE} plan's network starting {DATE}",
      TP_UNTIL: "In your {PLANTYPE} plan's network until {DATE}",
      NOT_INNTWK_ASOF: 'Not In-Network as of {DATE}'
    },
    reviewText: {
      bcbsa: 'BlueCross BlueShield',
      hcahps: 'CMS HCAHPS',
      reviews: 'Reviews',
      review: 'Review',
      tooltipReview:
        "HCAHPS (Hospital Consumer Assessment of Healthcare Providers and Systems) ratings are based on patient survey data from the Centers for Medicare & Medicaid Services. Learn more at <a target='_blank' href='https://www.medicare.gov/care-compare'>medicare.gov/care-compare.</a> Last updated {DATE}."
    },
    awardsCerts: 'Awards/Certifications',
    accessibility: 'Accessibility:',
    emailResults: 'Email Results',
    email: 'Email',
    printResults: 'Print Results',
    print: 'Print',
    downloadResults: 'Download Results',
    download: 'Download',
    back: 'Back',
    backCompareAriaLabel: 'Back button, Select here to go back to summary page',
    compareAriaLabel: 'Compare button, Select here to go to compare provider detail page',
    ariaLabelForRecognitionModal: 'Close button, Select to close the summary recognition modal',
    ariaLabelForTelehealthModal: 'Close button, click here to close the summary telehealth modal',
    ariaLabelForAwardModal: 'Close button, click here to close the summary award modal',
    backAriaLabel: 'Back button, Select here to go back to search criteria page',
    compare: 'Compare Now',
    emailModal: {
      success: 'Mail Sent Successfully',
      error: 'Unable to send email. Try again.',
      tooManyIds: 'Too many Email IDs entered',
      required: 'Email Field is required',
      validEmail: 'Invalid Email',
      emailAddress: 'Email Address',
      details: 'Details to be emailed:',
      max100: 'Please Note: The PDF only has up to the first 100 providers of your search results. This is not a list of all providers.',
      submit: 'Submit',
      cancel: 'Cancel',
      providerSearchResults: 'Provider Search Results',
      pdf: '.pdf'
    },
    close: 'Close',
    showMap: 'Show Map',
    hideMap: 'Hide Map',
    specialty: 'Specialty:',
    showDisclaimer: 'Show Disclaimers',
    hideDisclaimer: 'Hide Disclaimers',
    helpfulHintsHeading: 'Helpful Hints',
    backTosearchResult: 'Search Results',
    backToResults: 'Back to Results',
    backToCareTeam: 'Back to Care Team',
    backToFindCare: 'Back to Find Care',
    newFindCareSearch: 'New Find Care Search',
    backToModifySearch: 'Modify Search',
    compareProviderText: 'Compare Provider',
    availableServices: 'Services Available:',
    pcpid: 'PCP ID:',
    showMore: 'Show More',
    showLess: 'Show Less',
    resultsHeader: {
      plan: 'plan',
      prefix: 'prefix',
      noResultDefaultMsgNew: 'No results were found for {PVD} in the {PLAN} within {RDS} miles of {LOC}{MATCH}.',
      noResultDefaultMsgNewLine1: 'No results were found within <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span>{MATCH}.',
      noResultSummaryMsgCold:
        '<span class="ant-font-b">No Results.</span> We’ve automatically expanded your search but didn’t find any matches within <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span>. Try changing the ZIP code or <a target="_blank" href="/contact-us">Contact Us</a> if you have questions about your benefits.',
      noResultSummaryMsgHot:
        '<span class="ant-font-b">No Results.</span> We’ve automatically expanded your search but didn’t find any matches within <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span>. Try changing the ZIP code or <a target="_blank" href="/member/contact-us">Contact Us</a> if you have questions about your benefits.',
      noResultSummaryMsgHotMedicaid:
        '<span class="ant-font-b">No Results.</span> We’ve automatically expanded your search but didn’t find any matches within <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span>. Try changing the ZIP code or contact us if you have questions about your benefits.',
      noOONResultSummaryMsg:
        '<strong>This care provider isn\'t in your plan\'s network.</strong> We have automatically increased the search distance but no results were found for <span class="ant-font-b">{PVD}</span> in the <span class="ant-font-b">{PLAN}</span> within <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span>{MATCH}.',
      noOONResultSummaryMsgLine1:
        '<strong>This care provider isn\'t in your plan\'s network.</strong> We have automatically increased the search distance but no results were found within <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span>{MATCH}.',
      OONsearchNoResultSummaryMsg:
        'No Results. We’ve automatically expanded your search but didn’t find any matches within <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span>. Try changing the ZIP code or <a href="https://www.anthem.com/contact-us">Contact Us</a> if you have questions about your benefits.',
      summaryMsgNew: '{CNT} results in the {PLAN} within {RDS} miles of {LOC}{MATCH}. {REFINE}',
      selectedMemberPlanMsgNew: 'Finding Care for <b>{NAME}</b> in <span class="ant-font-b">{PLAN}</span>.',
      selectedMemberPlanNew: "Finding Care in <span>{NAME}</span>'s <span>{PLAN}</span>",
      selectedPlanMsgNew: 'Finding Care in <span class="ant-font-b">{PLAN}</span>.',
      selectedPlanMsg: 'Finding Care in <span>{PLAN}</span>',
      cptSummaryMsgLine1:
        '<span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span> for <span class="ant-font-b">{USR} in </span><span class="ant-font-b">{PLN}</span> with remaining deductable of ',
      cptSummary: "{CNT} Results in {USR}'s Medical Plan Network",
      summaryMsgLine1: '<span class="ant-font-b">{CNT}</span> results within <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span>{MATCH}',
      summaryMsgSubSpecRemoved:
        'We couldn’t find any results for that specialty, so we’ve expanded your search to include related specialties within <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span>. We found <span class="ant-font-b">{CNT}</span> results within that distance.',
      summaryMsgPrimeGroupRemoved:
        'No results were found, so we removed the Medical Group filter and increased the search radius to <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span>. We found <span class="ant-font-b">{CNT}</span> results within that distance {MATCH}. You will need a referral from your Primary Care Physician before receiving non-emergency services outside your Medical Group.',
      increasedRadiusMsgNew: 'No results were found, so we increased the search distance to {RDS} miles of {LOC}. We found {CNT} {PVD} in the {PLAN} within that distance {MATCH}. {REFINE}',
      increasedRadiusMsgNewLine1:
        'No results were found, so we increased the search distance to <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span>. We found <span class="ant-font-b">{CNT}</span> within that distance {MATCH}.',
      matchingNew: ' matching or similar to {NAM}',
      refineResultsNew: 'Refine your search results using any combination of filters.',
      noResultEycSummaryMsg: 'Sorry, there are no results for your search criteria. Please use BACK button to change your search location or distance and try again.',
      noResultEycSummaryMsgForUnimass:
        "No Results. Try a different ZIP code or use &quot;Back to Find Care&quot; to start over.  We're not able to show procedure cost estimates for care providers located outside of Massachusetts.  If you have any questions, please contact <a target='_blank' href='https://www.unicaremass.com/contact-us/'>Member Services</a>.",
      nonGAColdStateProcSearchMsg:
        "To find care providers for this procedure outside of Georgia, <a tabindex='0' aria-label='please login' target='_blank' href='https://www.anthem.com/login/?dplid={deeplinkid}'>please log in</a>.",
      hpnariaNoResultsMsg: 'Your benefits may be limited to urgent care or emergency care in this location. Please contact us if you have questions about your benefits.',
      hpnNoResultsMsg1: 'Your benefits may be limited to ',
      hpnNoResultsMsgUC: 'urgent care ',
      hpnNoResultsMsg2: 'or ',
      hpnNoResultsMsgEC: 'emergency care ',
      hpnNoResultsMsg3: 'in this location. Please ',
      hpnNoResultsMsgCU: 'contact us ',
      hpnNoResultsMsg4: 'if you have questions about your benefits.'
    },
    resultsProvType: {
      P: 'Doctor/Medical Professional',
      H: 'Hospitals and Facilities',
      B: 'Behavioral Health Professional',
      D: 'Dental Providers',
      DM: 'Medical Dental Providers',
      V: 'Vision Professional',
      VM: 'Medical Vision Professional',
      L: 'Labs, Pathologists, and/or Radiologists',
      C: 'Retail Health Clinic',
      A: 'Hearing Professional',
      U: 'Urgent Care Providers',
      M: 'Medical Equipment Suppliers',
      R: 'Pharmacy',
      O: 'Other Medical Services',
      G: 'Medical Groups'
    },
    pdfFileName: {
      medical_procedure: 'Medical_Procedure',
      dental_procedure: 'Dental_Procedure',
      medical_code: 'Medical_Code',
      search_on: '_Search_on_'
    },
    bookAppointment: 'Book Appointment',
    requestAppointment: 'Request Appointment',
    assignPCP: 'Assign as PCP',
    addCareTeam: 'Add to Care Team',
    viewDetails: 'View Details',
    summaryTabs: {
      listView: 'List View',
      tileView: 'Tile View',
      mapView: 'Map View'
    },
    detailsNotAvailable: 'Provider details not available',
    doingBusinessAs: 'Doing Business As:',
    showMoreRating: 'Show more ratings link.Selecting link will list all available ratings for ',
    showLessRating: 'Show less ratings link.Selecting link will collapse ratings. Only first rating will be listed',
    starAriaLabel: '{NAME} has a rating of {RATING} out of 5 for {SRC}',
    reviewInfo: 'Available {review} for Provider',
    telehealth: 'Telehealth',
    telehealthNote: 'This healthcare professional or location offers virtual, real-time access to some or all medical services typically delivered in the office.',
    selectProvider: 'Select Provider'
  },
  providerCardDetails: {
    tabs: {
      overView: 'Overview',
      moreDetails: 'More Details',
      costEstimate: 'Cost Estimate'
    },
    actions: {
      assignPCP: 'Assign as PCP',
      addCareTeam: 'Add to Care Team'
    },
    labels: {
      print: 'Print',
      viewLocation: 'View Locations',
      close: 'Close',
      county: 'County'
    },
    addToCareTeam: {
      outerBtnLabel: 'Add to Care Team',
      closeModal: 'button, select to close the modal',
      btnCancel: 'Cancel',
      addCareTeam: 'Add to Care Team',
      ariaLabelForAddCareTeam: 'Add to Care Team button add {PROVIDER} to {MEMBER} Care Team',
      addCareProviderContent:
        "Are you sure you want to add <strong class='text-uppercase'>{PROVIDER}</strong> to the Care Team of <strong>{MEMBER}</strong>? (You always can remove if you change your mind.)",
      addWhoseCareTeamHeader: 'Add to whose Care Team?',
      addCareProviderHeader: 'Add to Care Team',
      addCareTeamSuccessMsg:
        "You have successfully added <strong class='text-uppercase'>{PROVIDER}</strong> to the <a type='button' id='lnk-careteam' class='addcareteam pf-button' aria-label='click here to navigate to Care team page'>Care Team of <strong class='addcareteam pf-button'>{MEMBER}</strong></a>.",
      addCareTeamFailureMsg: "There was a problem and we weren't able to add <strong class='text-uppercase'>{PROVIDER}</strong> to the Care Team of <strong>{MEMBER}</strong>. Please try again later."
    },
    assignToPcp: {
      slideoutMsgCmpContent: {
        close: 'Close',
        title: "We're Having a Problem Assigning This Primary Care Physician",
        default: "To assign {DOCTOR NAME} as a Primary Care Physician, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        errNoPcpsInAffiliation: "To assign {DOCTOR NAME} as a Primary Care Physician, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        errPcpMbrEffDtPastContractEndDt:
          "We won't be able to assign {DOCTOR NAME} as the PCP for {MEMBER NAME} because your plan ends on {TERMINATION DATE}. If you think this information is wrong, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        errMbrAgeNotInRange:
          "Our system is telling us that {DOCTOR NAME} doesn't treat patients in {MEMBER NAME}'s age group. If you think this information is wrong, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        title_errPcpMbrEffDtPastContractEndDt: "Can't assign PCP before plan ends"
      }
    }
  },
  filter: {
    allFilters: 'All Filters',
    filterApplied: 'Filter Applied',
    clearFilter: 'Clear Filter',
    cancel: 'Cancel',
    undoFilter: 'Undo Filter',
    apply: 'Apply',
    showMoreFilters: 'Show {COUNT} more',
    filterRemove: 'Filter remove',
    filter: 'Filter',
    showLess: 'Show less',
    selected: 'selected',
    categories: {
      gender: 'Gender',
      specialty: 'Specialty',
      subSpecialty: 'Sub-Specialty',
      providerType: 'Type',
      languages: 'Other Languages',
      adaAccessible: 'ADA Accessible',
      areaofexpertise: 'Area of Expertise',
      billingCode: 'Billing Code'
    }
  },
  cptFilters: {
    medicalCodePlaceHolder: 'Billing Code',
    posPlaceHolder: 'Where You Get Care',
    modifiersPlaceHolder: 'Billing Code Options'
  },
  providerOverview: {
    actions: {
      appointment: 'Request Appointment'
    },
    tooltips: {
      acceptPatients:
        'This information is reported by the doctor directly and updated as reported for each plan. To ensure accurate results, make sure you have a plan selected when using this option.',
      pcpInfo:
        'Confirm this number by reviewing the information in the Affiliations section. PCPs in groups may have different IDs based on office location. Choose the ID that matches the office you want to visit.'
    },
    labels: {
      viewPcP: 'View All PCP IDs',
      acceptPatients: 'Accepting New Patients',
      addReview: 'Add a review',
      pcpInfo: 'PCP ID: ',
      pcp: 'PCP Info',
      providerImage: 'Provider Image',
      planType: 'Plan Type',
      providerType: 'Provider Type',
      tierType: 'Tier Type',
      review: 'Add Review',
      basicInfo: {
        address: 'Address',
        phone: 'Phone',
        email: 'Email',
        website: 'Website'
      },
      acceptNew: 'Accepting New Patients',
      notAcceptNew: 'Not Accepting New Patients',
      recognition: 'Recognitions',
      affiliation: 'Affiliations',
      virtualService: 'Virtual/Telehealth Services',
      noEthnicity: 'ethnicity not available',
      noGender: 'gender not available',
      noGenderOrEthnicity: 'Gender and ethnicity not available',
      noLanguages: 'Languages spoken not available',
      noEducation: 'Education not available',
      noSpeciality: 'Speciality not available',
      officeHours: 'Office Hours',
      open24Hours: 'Open 24 hours',
      hoursNotAvail: 'Office Hours Not Available',
      quickFacts: 'Quick Facts',
      networkNotAvail: 'Network Not Available',
      diamondProvider: 'Anthem Diamond Provider'
    },
    weekdays: {
      0: 'Monday',
      1: 'Tuesday',
      2: 'Wednesday',
      3: 'Thursday',
      4: 'Friday',
      5: 'Saturday',
      6: 'Sunday'
    },
    noAccordian: {
      recognitions: 'No information about recognitions is available for this care provider at this location.',
      affiliations: 'No information about affiliations is available for this care provider at this location.'
    },
    notAvail: 'Not Available',
    diamondCode: 'HPP50-LG'
  },
  providerMoreDetails: {
    labels: {
      accreditStatus: 'Accreditation Status:',
      adaAccess: 'ADA Accessible:',
      affiliation: 'Affiliations',
      careLevel: 'Level of Care:',
      culturalTraining: 'Cultural Competence Training:',
      expertise: 'Area of Expertise:',
      insurance: 'Insurance Plans',
      licenseNum: 'License Number:',
      licenseType: 'License Type:',
      locationLanguages: 'Languages Spoken at Location:',
      patientAgePref: 'Patient Age Preference:',
      patientGenderPref: 'Patient Gender Preference:',
      providerId: 'National Provider Identifier (NPI):',
      services: 'Services',
      speciality: 'Specialties:',
      viewAll: 'View All'
    },
    tooltips: {
      npi: 'National Provider Identifier (NPI) is a unique ten-digit ID number issued by the federal government to identify health care providers.',
      licenseNumber: 'Care providers who have been granted permission to practice medicine in a state are issued a license number for that state.',
      areaOfExpertise:
        'Areas of Expertise information is supplied on a voluntary basis by practitioners. Therefore practitioner searches based on these items may not result in a complete listing of all practitioners matching the specified area. Although this listing is continually updated, participation by practitioners as well as data regarding Areas of Expertise is not guaranteed and may be subject to change. Prior to receiving care, please ask your practitioner whether he/she is still participating in the network, accepting new patients, and providing services that meet the criteria you have selected.',
      competenceTraining: 'Culture competency training educates care providers on supporting the needs of diverse patients.',
      indianHSP:
        'A provider of contract health services operated by the Indian Health Service, an Indian tribe, tribal organization or urban Indian organization (known as I/T/Us). Services from these providers may be limited to tribal members only.',
      ada: 'ADA accessibility information is reported to us by the care provider and can change.'
    },
    noAccordian: {
      insurance: 'No information about insurance is available for this care provider at this location.',
      services: 'No information about services is available for this care provider at this location.'
    },
    preference: {
      birth: 'Birth',
      years: 'years',
      older: ' and older',
      to: 'to',
      both: 'Both',
      maleFemale: 'Male and Female'
    },
    notAvail: 'Not Available'
  },
  pcpList: {
    pcpId: 'PCP IDs',
    pcpNotAvail: 'PCP IDs Not Available',
    back: 'Back'
  },
  recognitionCard: {
    recognition: 'Recognitions',
    recognitionNotAvail: 'Recognitions Not Available',
    back: 'Back'
  },
  serviceCard: {
    services: 'Services Plans',
    back: 'Back'
  },
  insuranceCard: {
    insurance: 'Insurance Plans',
    back: 'Back'
  },
  affiliationCard: {
    affiliation: 'Affiliations',
    back: 'Back'
  },
  licenseCard: {
    license: 'License Numbers',
    back: 'Back'
  },
  specialityCard: {
    speciality: 'Speciality',
    back: 'Back'
  },
  pagination: {
    resultCountText: 'Showing <b>{providerCountFirst}-{providerCountLast}</b> of <b>{totalRecords} results</b><br/>within {searchMiles} miles of {searchLocation}',
    resultCountLessProviderText: '<b>{totalRecords}</b> results within <b>{searchMiles} miles</b> of <b>{searchLocation}</b>'
  },
  disclaimerComponent: {
    labels: {
      important: 'IMPORTANT',
      showMore: 'Show More',
      showLess: 'Show Less',
      showMoreDisclaimer: 'Select enter for  More disclaimer details',
      showLessDisclaimer: 'Select enter for  Less disclaimer details'
    },
    ariaLabel: {
      showMore: 'Show More refine filters',
      showLess: 'Show Less refine filters'
    },
    error: 'We cannot complete your request at this time. Please try again later.'
  },
  disclaimer: DISCLAIMER_CONTENT_EN,
  compareProvidersComponent: {
    actions: {
      print: 'Print',
      addAnother: 'Add another',
      addToCareTeam: 'Add to Care Team',
      remove: 'Remove',
      viewDetails: 'View details',
      reviews: 'reviews',
      replace: 'Replace'
    },
    labels: {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      ratings: 'ratings',
      notAvailable: 'Not available',
      compareResults: 'Compare Results',
      close: 'Close',
      memberCostEstimate: 'Member cost Estimate',
      providerBillEstimate: 'Provider Bill Estimate',
      gender: 'Gender',
      specialities: 'Specialties',
      locations: 'Location',
      map: 'Map',
      providerEmail: 'Email',
      providerWebsite: 'Provider website',
      officeHours: 'Office Hours',
      impairmentService: 'Impairment Service',
      culturalCompetenceTraining: 'Cultural competence training',
      aDAAccessible: 'ADA Accessbile',
      recognition: 'Recognition/Awards',
      rating: 'Ratings',
      pcpId: 'PCP ID',
      servicesAvailable: 'Services Available',
      acceptsNewPatients: 'Accepts New Patients',
      languagesSpoken: 'Languages Spoken',
      languagesSpokenAtPractice: 'Languages Spoken at the Practice',
      medicalSchoolEducation: 'Medical School Education',
      nationalProviderIdentifier: 'National Provider Identifier',
      familyAccreditation: 'Family Accreditation',
      areaOfExpertise: 'Area of Expertise',
      levelOfCare: 'Level of Care',
      ethnicity: 'Ethnicity',
      patienceAgePreference: 'Patient Age Preference',
      patienceGenderPreference: 'Patient Gender Preference',
      indianHealthServiceProvider: 'Indian Health Services Provider',
      licenseType: 'License Type',
      providerLicensingInfo: 'Provider Licensing Information',
      networkType: 'Network Type',
      affiliations: 'Affiliations',
      insurancePlans: 'Insurance Plans Accepted',
      maleOnlyText: 'Male Only',
      femaleOnlyText: 'Female Only',
      bothText: 'Male and Female',
      yesText: 'Yes',
      noText: 'No',
      yearsText: 'years',
      yearOlderText: 'years and older',
      birthToText: 'Birth to',
      birthOlderText: 'Birth and Older',
      medicalNetwork: 'In-Network Medical Plan',
      visionNetwork: 'In-Network Vision Plan',
      dentalNetwork: 'In-Network Dental Plan',
      boardCertifiedMsg: ' - Board Certified',
      notBoardCertifiedMsg: ' - Not Board Certified',
      naBoardCertifiedMsg: ' - Board Certification Not Applicable',
      miles: 'mi',
      male: 'M',
      maleTxt: 'MALE',
      female: 'F',
      femaleTxt: 'FEMALE',
      both: 'B',
      bothTxt: 'BOTH',
      dental: 'DENTAL',
      vision: 'VISION',
      medical: 'MEDICAL',
      boardCertified: 'BOARD CERTIFIED',
      notBoardCertified: 'NOT BOARD CERTIFIED',
      naBoardCertified: 'BOARD CERTIFICATION NOT APPLICABLE'
    }
  },
  pricingCmpContent: {
    pricingDropdown: [
      {
        label: 'Procedure',
        value: 'procedure'
      },
      {
        label: 'Medical Code',
        value: 'cptcode'
      }
    ],
    defaultPos: 'Select',
    selectPricingDropdown: 'Select a Medical Code',
    cptCode: 'Search for a Billing Code',
    procedureCode: 'Select a Procedure',
    headingProcedure: 'Estimated Price',
    defaultSelectedCode: 'Enter medical code or related term',
    defaultModifierCode: 'Not Applicable',
    posList: 'Where You Get Care',
    modifierList: 'Billing Code Options',
    modifierCodeDisclaimer: 'A procedure or service can have variations that affect costs.',
    posDisclaimer: "A procedure or service can have different costs depending on the setting where it's performed.",
    noMedicalCodeSelected: 'No Medical Code Selected',
    noMedicalCodeDesc: "Select a medical code and we'll display the price estimates for in-network services.",
    allowedByPlan: 'Allowed by Plan',
    allowedByPlanDesc: "The most your plan would pay for this service. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim.",
    planPay: 'Plan Pays',
    planPayDesc: 'The amount that would be covered by your plan for this service. This amount is calculated by applying any copay, deductible, and coinsurance to the allowed-by-plan amount.',
    whatYouPay: 'What You Could Pay',
    whatYouPayDesc:
      "The amount you'd owe after any discounts, savings, and plan payments have been applied. This amount also factors in how much is left to pay towards your out-of-pocket maximum and deductible. (To view your out-of-pocket and deductible progress, go to My Plans > Medical Benefits > Plan Progress).",
    costDetails: 'Cost Details',
    planNotCovered: 'Your plan doesn\'t cover this procedure. <a target="_blank" href="/member/contact-us">Contact Us</a> if you have questions about your plan\'s benefits.',
    planNotCoveredAriaLabel: "Your plan doesn't cover this procedure. Contact Us if you have questions about your plan's benefits.",
    benefitDenied: 'Unable to show some cost data',
    cptAlertMsg: 'Before scheduling an appointment, please check with this care provider to confirm they perform this procedure or service.',
    aboutThisBillingCode: 'About this billing code',
    cptPdtAlertMsg: 'We don’t have a cost estimate for this care provider performing this service.',
    backToProviderList: 'find care providers who perform this service.',
    dataAnalytics: {
      selectProcDropDetailFindCare: 'selectProcDropDetailFindCare',
      selectMedCodeDropDetailFindCare: 'selectMedCodeTypeDetailFindCare',
      selectProcMedDropDetailFindCare: 'selectProcMedDropDetailFindCare',
      selectPlaceServiceDropDetailFindCare: 'selectPlaceServiceDropDetailFindCare',
      selecteModifierDropDetailFindCare: 'selectMedCodeDropDetailFindCare',
      infoPlaceServiceDetailFindCare: 'infoPlaceServiceDetailFindCare',
      infoMedCodeDetailFindCare: 'infoMedCodeDetailFindCare',
      payCostDetailFindCare: 'payCostDetailFindCare'
    },
    noSuggestionsFound: 'No suggestions found'
  },
  eycProcedure: {
    eycCostinfoMsg: 'What is included in these estimated costs?',
    totalEstimatedCostText: "<strong>Total Bill.</strong> The amount you'd pay if you didn’t have insurance.",
    noBenefitsmsg: 'Because your plan information isn’t available, we can only show an estimated range and average.',
    planPaysMsg: "<strong>Plan Savings.</strong> The amount you'd save by being a member. This savings is a result of our ability to negotiate better prices.",
    youPayMsg: "<strong>Your Responsibility.</strong> The amount you'd owe after any discounts, savings and plan payments have been applied.",
    deductibleMsg:
      '<strong>Applied to Deductible.</strong> The amount remaining of your total deductible, which is a fixed dollar amount you are expected to pay prior to Anthem payment for all or part of covered services.',
    coinsuranceMsg: '<strong>Coinsurance.</strong> A fixed percentage of the cost of care you are expected to cover after your total deductible has been met. Your coinsurance is {COINSURANCE}%',
    defaultCoinsuranceMsg: '<strong>Coinsurance.</strong> A fixed percentage of the cost of care you are expected to cover after your total deductible has been met.',
    coPayMsg: "<strong>Copay.</strong> The flat dollar amount you'd pay for certain services.",
    overChargeMsg: '<strong>Overage.</strong> The amount of the bill not covered by your plan because it exceeds your benefit limits for this procedure or treatment. You must pay the overage charge.',
    costBeforeHRA:
      '<Strong>Cost before your {ACCOUNT} funds applied.</Strong > The amount you would cover. If available, your {ACCOUNT} spending account funds would be used. If not, you would have to pay this amount out-of-pocket.',
    hraCostText: '<Strong>{ACCOUNT} Pays. </Strong > The amount in your {ACCOUNT}  that would be applied to your bill.',
    hraNocostText: 'We’re sorry, but we can’t get your {ACCOUNT} balance right now. {ACCOUNT} funds could lower the amount you pay.',
    totalEstimatedCostRangeText:
      '<strong>Estimated Bill.</strong> The amount of the claim or bill from the doctor or hospital. (Anthem negotiates agreements with our providers to discount their billed amounts and to make sure you’re not billed for certain denied charges.) This amount may vary depending on where you choose to have the procedure done.',
    youPayRangeMsg: '<strong>Average Price.</strong> The amount you would have to cover out-of-pocket. This amount may vary depending on where you choose to have the procedure done.',
    tooltiptitle: {
      selectforMoreInfo: 'Select for more information about'
    }
  },
  eycPricing: {
    headingProcedure: 'Estimated Price by Procedure',
    arialabelServices: 'services provided by',
    arialabelIncluded: 'included services covered in',
    selectProcedure: 'Select a Procedure',
    services: 'Services',
    included: 'Included',
    estimatedPrice: 'Estimated Price',
    estimatedBill: 'Estimated Bill',
    estimatedBillDesc: 'The average amount this care provider has agreed to bill for this service in your plan. This is also known as the Allowed Amount.',
    totalBill: 'Total Bill',
    totalBillDesc: "The amount you'd pay if you didn't have insurance.",
    planSavings: 'Plan Pays',
    planSavingsDesc: 'The amount that would be covered by your plan for this service.',
    isBenefitDeniedMsg: 'We are sorry! Your plan does not cover this procedure.',
    yourResponsibility: 'What You Could Pay',
    yourResponsibilityDesc:
      "The amount you'd owe after any discounts, savings, and plan payments have been applied. This amount also factors in how much is left to pay towards your out-of-pocket maximum and deductible. (To view your out-of-pocket and deductible progress, go to My Plans > Medical Benefits > Plan Progress).",
    footerText: 'The What You Could Pay amount includes paying your remaining deductible and any coinsurance or copay.',
    remainingDeductible: 'Applied to Deductible',
    remainingDeductibleDesc: 'The amount that would count toward your remaining deductible, which is the amount you pay for health services before we start sharing the costs.',
    coinsurance: 'Coinsurance',
    coinsuranceDesc: "A fixed percentage of the cost of care you'd be expected to cover after your total deductible has been met. Your coinsurance is {COINSURANCEPRECENT}%.",
    defaultCoinsuranceDesc: "A fixed percentage of the cost of care you'd be expected to cover after your total deductible has been met.",
    copay: 'Copay',
    copayDesc: "The flat dollar amount you'd pay for certain services.",
    overage: 'Overage',
    overChargeDesc: 'The amount of the bill not covered by your plan because it exceeds your benefit limits for this procedure or treatment. You must pay the overage charge.',
    hraCost: '{ACCOUNT} pays',
    hraDesc: ' The amount in your {ACCOUNT} that would be applied to your bill.',
    averagePrice: 'Average Bill',
    averageDesc: 'The amount you would have to cover out-of-pocket. This amount may vary depending on where you choose to have the procedure done.',
    averageDentalDesc: 'The average amount this care provider has agreed to bill for this service in your plan.',
    benefitInfoError: "Because your plan information isn't available, we can only show an estimated range and average.",
    noCostDataError: "We're not able to show a cost estimate for this procedure.",
    airalblfooterInfoLink: 'press enter for more information about your responsibility',
    airalblfooterText: 'This also includes paying your remaining deductible and any coinsurance or copay.',
    closeModal: 'button, select to close the modal'
  },
  locationsComponent: {
    mapmarker: 'Press Enter to get map tracker',
    phone: 'Press enter to call this doctor',
    drivingTime: 'Driving time ',
    labels: {
      onlineLocation: 'Location - Online',
      accessibility: 'Accessibility',
      officeHours: 'Office Hours',
      open24Hours: 'Open 24 hours',
      miles: 'Miles',
      getDirections: 'Get Directions',
      drivingDirection: 'Driving Distance & Directions',
      email: 'Email:',
      provWebSiteUrl: 'Provider website/URL to Website:',
      locationCost: 'Specific to location',
      location: 'Address',
      adminOffice: 'Administrative Office Only',
      appointmentSchedule: 'Book an Appointment'
    },
    tooltips: {
      adaDisclaimer:
        'Handicapped accessibility information is voluntarily reported to us by the provider and is subject to change. Please contact the provider to confirm the content and accuracy of this information.',
      adaAccDisclaimer: 'ADA accessibility information is reported to us by the care provider and can change.',
      cctDisclaimer: 'Culture competency training educates care providers on supporting the needs of diverse patients.'
    }
  }
};
//  Define a type based on the structure of the English content
export type ResultContent = typeof RESULT_CONTENT_EN;
