import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppEvents } from './../../../common/enums/appEvents';
import { AppUtility } from './../../../common/utilities/appUtil';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { BaseComponent } from './../base-component/baseCmp';

@Component({
  selector: 'app-maintenance',
  templateUrl: './app-maintenance.component.html'
})
export class AppMaintenanceComponent extends BaseComponent implements OnInit, OnDestroy {
  isEyc: boolean = false;
  private _headerEvent: EventEmitter<string> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _appUtility: AppUtility
  ) {
    super( _route, _eventHandler, _appSession, _contentHelper, 'AppMaintenanceComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.onPreLoad();
    }
    this.isEyc = this._appSession && this._appSession.isEyc || false;
  }

  ngAfterViewInit() {
    if (this.content.globalHeaderComponent && this.content.globalHeaderComponent.maintenancePageTitle) {
      this._headerEvent.emit(this.content.globalHeaderComponent.maintenancePageTitle);
    }

  }

  decodeHTML(html) {
    return this.WcsUtility.decodeHTML(html);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  ngOnDestroy() {
    //resetting the flag on component destroy, the flag is used to fetch right maintenance message from WCS
    if (this._appSession && this._appSession.isEyc) {
      this._appSession.isEyc = false;
    }
  }

}
