import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { SearchSvc } from '../../../common/services/searchSvc';
import { UserImpressionUtil } from '../../../common/utilities/userImpressionUtil';
import { IRuleParameters } from '../../../fad/rules/interfaces/iRulesParameters';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { EycProcedureCriteria } from '../../../fad/search-results/interfaces/iSearchRequest';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { AppConstants, Locale } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { AppEvents } from './../../../common/enums/appEvents';
import { IOptions } from './../../../common/interfaces/iAppMetadata';
import { LocationResponse } from './../../../common/models/locationAddress';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { ILinkProcedure } from './../../../fad/search-providers/interfaces/iQuickLinks';
import { ISearchParameters } from './../../../fad/search-providers/interfaces/iSearchParameters';
import { FREQUENT_PROCEDURE_COUNT, PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from './../../../fad/search-providers/values/providerSearchConstants';
import { EycSearchParam } from './../../../fad/search-results/interfaces/iSearchRequest';
import { IKodiakProcedure } from './../interfaces/iKodiakProcedure';
import { PFPromotedProcedureService } from './../services/pfPromotedProcedureSvc';

@Component({
  moduleId: module.id,
  selector: 'app-promoted-procedures-cmp',
  templateUrl: '../views/pfPromotedProceduresContainerCmp.html'
})
export class PFPromotedProceduresContainerComponent extends BaseComponent implements OnInit {
  kodiakProcedures: IKodiakProcedure = {};
  isValidMember: boolean = false;
  isEmulation: boolean = false;
  viewMore: boolean = false;
  nonProcedureLength: number = 0;
  showBackToLink: boolean = false;
  showLoader: boolean = false;
  searchCriteria: ISearchParameters = {};
  noResults: boolean = false;
  showPlanUnknowErrorMsg: boolean = true;
  count: number = this.isShopForProcRedesignEnabled ? FREQUENT_PROCEDURE_COUNT.NINE : FREQUENT_PROCEDURE_COUNT.SIX;
  personalizedIndicator: number;

  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  private navigationSubscription;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _appUtility: AppUtility,
    private _navigationService: NavigationService,
    private _providerUtilityService: ProviderUtilityService,
    private _kodiakProcedureSvc: PFPromotedProcedureService,
    private _userImpressionUtil: UserImpressionUtil,
    private _eventHandler: EventHandler,
    private _searchService: SearchSvc
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFPromotedProceduresContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.showBackToLink = this._navigationService.previousRoute === AppNavigations.SEARCH_PROVIDERS_PATH || this._contentHelper.isSearchResultRoute(this._navigationService.previousRoute);
    this._appUtility.scrollToTop();
    this.onPreLoad();
    this.initCalls();
    this.setPageHeader();
  }

  /***
   * set the Page Header and Title dynamically based on pages
   */
  setPageHeader() {
    if (this.content.shopProcedures && this.content.shopProcedures.labels && this.content.shopProcedures.labels.title) {
      const pageFadObj = {
        header: this.content.shopProcedures.labels.title,
        title: this.content.shopProcedures.labels.title
      };
      this._headerEvent.emit(pageFadObj);
    }
  }

  initCalls() {
    this.kodiakProcedures = {};
    let _memberZip: string = null;
    this.isValidMember = CommonUtil.isMemberSecure(this._appSession) && this._appSession.hasEYC;
    this.isEmulation = this._appSession.isEmulation;

    let _index;
    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.memberList) {
      if (this._appSession.metaData.appContract.mbrUid) {
        _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
      } else {
        _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.isChecked === true);
      }
      if (_index > -1) {
        const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
        _memberZip = _member.address && _member.address.postalCode ? _member.address.postalCode : null;
      }
    }

    this.setSearchParams();

    if (this._appSession && this._appSession.searchParams) {
      this.retainSearchCriteriaValue();
    }

    if (_memberZip) {
      this.bindMemberLocation(_memberZip);
    }
    this.planUnknowError();

    if (this.isValidMember && !this.showPlanUnknowErrorMsg) {
      this.getKodiakProcedures();
    } else {
      this.goToSearch();
    }
  }

  getKodiakProcedures() {
    this.kodiakProcedures = {} as IKodiakProcedure;
    this.showLoader = true;
    let request: IRuleParameters = CommonUtil.buildRuleRequest(this._appSession.searchParams, this._appSession);
    request.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
    this._kodiakProcedureSvc.getPromotedProcedures(request).then(
      (result: IKodiakProcedure) => {
        if (result) {
          this.personalizedIndicator = result.personalizedIndicator;
          this.kodiakProcedures = result;
          this.viewMore = this.kodiakProcedures && this.kodiakProcedures.nonSuggestedProcedure && this.kodiakProcedures.nonSuggestedProcedure.length > this.count;
          this.setProcedureLength();
          this.noResults = !((result.nonSuggestedProcedure && result.nonSuggestedProcedure.length > 0) || (result.suggestedProcedure && result.suggestedProcedure.length > 0));
          this.postImpression(this.kodiakProcedures);
        } else {
          this.noResults = true;
        }
        this.showLoader = false;
      },
      (error: any) => {
        this.showLoader = false;
        this.noResults = true;
        this.onCategoryError('PROMOTEDPROCEDURE', error);
      }
    );
  }

  viewMoreProc() {
    this.viewMore = !this.viewMore;
    this.setProcedureLength();
    this.postImpression(this.kodiakProcedures);
  }

  planUnknowError() {
    let _planName = '';
    this.showPlanUnknowErrorMsg = false;
    if (this.searchCriteria.plan) {
      _planName = this.searchCriteria.plan.name;
      if (!_planName && this.searchCriteria.plan.securePlanLabelKey) {
        _planName = this.searchCriteria.plan.securePlanLabelKey;
      }

      if (_planName === this.content.shopProcedures.labels[AppConstants.PlanUnknown]) {
        _planName = this.content.shopProcedures.labels[AppConstants.PlanUnknown];
        this.showPlanUnknowErrorMsg = true;
      }
    } else {
      _planName = this.content.shopProcedures.labels[AppConstants.PlanUnknown];
      this.showPlanUnknowErrorMsg = true;
    }
  }

  setProcedureLength() {
    this.nonProcedureLength = this.viewMore ? this.count : this.kodiakProcedures && this.kodiakProcedures.nonSuggestedProcedure ? this.kodiakProcedures.nonSuggestedProcedure.length : 0;
  }

  goToSearch() {
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
  }

  bindMemberLocation(zipCode: string) {
    this.getGeoCoordinates(zipCode);
  }

  getGeoCoordinates(inputText: string) {
    if (inputText.match(/^[0-9]+$/) != null) {
      if (inputText.length >= 5) {
        this._providerUtilityService.getGeoLocation(inputText).then((result: LocationResponse[]) => {
          if (result && Object.keys(result).length > 0) {
            const results = this._appUtility.buildLocationResponse(result);
            const coordinates: any = results[Object.keys(results)[0]];
            this.searchCriteria.zip = inputText;
            this.searchCriteria.coordinates = {
              longitude: '',
              latitude: ''
            };
            this.searchCriteria.coordinates.latitude = coordinates.lat;
            this.searchCriteria.coordinates.longitude = coordinates.long;
            if (this._appSession.metaData && this._appSession.metaData.appContract) {
              const _county = this._appSession.metaData.appContract.memberCounty;
              if (!_county || _county === '') {
                this._appSession.metaData.appContract.memberCounty = coordinates.county;
              }
            }
            this.searchCriteria.stateCd = {
              code: coordinates.stateCd
            };
          }

          this.searchCriteria.city = '';
          this.searchCriteria.countyCode = null;
          this._appSession.resetGeoLocation();
        });
      }
    }
  }

  searchByProcedure(procedure: ILinkProcedure) {
    if (this.isLocationValid()) {
      this.searchCriteria.typeSelectNm = '';
      this._appSession.searchParams.coverageTypeCode = '';
      this._appSession.searchParams.specialtySelectNm = [];
      this._appSession.searchParams.taxonomySelectNm = [];
      this._appSession.searchParams.providerTypeCodeList = [];

      const eycCriteria = new EycProcedureCriteria();
      const eycSearchParam = new EycSearchParam();

      eycCriteria.procedureCode = procedure.procedureCode && procedure.procedureCode.code ? procedure.procedureCode.code : '';
      eycCriteria.providerCategoryCode = procedure.providerCategoryCode && procedure.providerCategoryCode.code ? procedure.providerCategoryCode.code : '';
      eycCriteria.procedureName = procedure.procedureCode && procedure.procedureCode.name ? procedure.procedureCode.name : '';
      eycSearchParam.procedureDescription = procedure.procedureCode && procedure.procedureCode.description ? procedure.procedureCode.description : '';
      eycSearchParam.eycProcedureCriteria = eycCriteria;
      this.searchCriteria.providerTypeCodeList = [];

      this.searchCriteria.eycSearchParam = eycSearchParam;
      this.searchCriteria.brandCode = this._appSession.metaData.brandCd;

      // set current scenario on search-service
      this._searchService.currentScenario = this._appSession.isSecureState
        ? CommonUtil.getSecureProcedureScenario(procedure.providerCategoryCode?.code as PROVIDER_TYPE)
        : SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL;

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
        const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
        if (_index > -1) {
          const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
          this.searchCriteria.selectedMem = _member.firstName + ' ' + _member.lastName;
        }
        this.searchCriteria.memberSeqNum = this._appSession.metaData.appContract.memberSeqNum;
      }

      //this.setAnalytics(procedure.name, true);
      this.resetAppSessionParams(true);
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
  }

  isLocationValid() {
    let _isValid = true;
    if (!(this.searchCriteria && this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude !== '' && this.searchCriteria.coordinates.longitude !== '')) {
      _isValid = false;
    }
    return _isValid;
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  resetAppSessionParams(eyc: boolean = false) {
    this.searchCriteria.provNm = '';
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
  }

  setSearchParams() {
    this._appSession.isCareProvider = false;
    this._appSession.filterSearchParams = undefined;
    this.searchCriteria = {
      stateCd: {
        code: ''
      },
      typeSelectNm: 'P',
      specialtySelectNm: [],
      coordinates: this.searchCriteria.coordinates,
      acceptingNewPatients: false,
      ableToServePcp: false,
      medicaidInd: false,
      ecpprovider: false,
      cooperativeCareOnly: false,
      sosOnly: false,
      bluePrecision: false,
      blueDistinct: '',
      blueDistinctPlus: false,
      enhancedPersonalCare: false,
      zip: '',
      genderCd: '',
      provNm: '',
      speicalityDdlValues: null,
      providerTypeDdlValues: null,
      subSpeicalityDdlValues: null,
      categoryDescription: {},
      plan: {},
      distanceNm: PROVIDER_SEARCH_DISTANCE,
      brandCode: this._appSession.metaData && this._appSession.metaData.brandCd ? this._appSession.metaData.brandCd : 'ABCBS',
      typeOfCareNm: '',
      stateDdlValues: [],
      planCategoryDdlValues: [],
      planDdlValues: [],
      coverageTypeCode: '',
      boardCertification: false,
      providerTier: '',
      visionSrvcAvailableList: [],
      blueDistinctionCenter: [],
      blueDistinctionType: '',
      blueDistinctionTypePlus: '',
      hasPersonalizedMatch: false,
      centerOfExcellence: false,
      providerOfDistinction: false,
      includePrimeGroupSearch: true,
      msp: false,
      valueBasedProvider: false
    };
  }

  retainSearchCriteriaValue() {
    this.searchCriteria.plan = this._appSession.searchParams.plan;
    this.searchCriteria.provNm = this._appSession.searchParams.provNm ? this._appSession.searchParams.provNm : '';
    this.searchCriteria.zip = this._appSession.searchParams.zip && this._appSession.searchParams.zip.trim() ? this._appSession.searchParams.zip.trim() : '';
    this.searchCriteria.typeSelectNm = this._appSession.searchParams.typeSelectNm;
    this.searchCriteria.providerTypeCodeList = [];
    this.searchCriteria.specialtySelectNm = this._appSession.searchParams.specialtySelectNm;
    this.searchCriteria.distanceNm = this._appSession.searchParams.distanceNm;
    this.searchCriteria.stateCd = this._appSession.searchParams.stateCd;
    this.searchCriteria.typeOfCareNm = this._appSession.searchParams.typeOfCareNm;
    this.searchCriteria.stateDdlValues = this._appSession.searchParams.stateDdlValues;
    this.searchCriteria.planCategoryDdlValues = this._appSession.searchParams.planCategoryDdlValues;
    this.searchCriteria.planDdlValues = this._appSession.searchParams.planDdlValues;
    if (this._appSession.deeplinkParams && this._appSession.deeplinkParams.pcponly && this._appSession.deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      this.searchCriteria.ableToServePcp = this._appSession.searchParams.ableToServePcp;
    }
    this.searchCriteria.city = this._appSession.searchParams.city ? this._appSession.searchParams.city : '';
    if (this.searchCriteria.coordinates && this._appSession.searchParams.coordinates) {
      this.searchCriteria.coordinates.latitude = this._appSession.searchParams.coordinates.latitude.toString();
      this.searchCriteria.coordinates.longitude = this._appSession.searchParams.coordinates.longitude.toString();
    }
    this.searchCriteria.coverageTypeCode = this._appSession.searchParams.coverageTypeCode;
    this.searchCriteria.visionBVV = this._appSession.searchParams.visionBVV;
    this.searchCriteria.hearUSAEGR = this._appSession.searchParams.hearUSAEGR;
    this.searchCriteria.identificationNumber = this._appSession.searchParams.identificationNumber;
    this.searchCriteria.countyCode = this._appSession.searchParams.countyCode;
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchOption = '';
    this._appSession.hospSearchTerm = '';
    this._appSession.medicalGrpSearchTerm = '';
  }

  postImpression(procedure: IKodiakProcedure) {
    let kodiakProcedures: ILinkProcedure[] = [];
    if (procedure?.suggestedProcedure) {
      kodiakProcedures = procedure.suggestedProcedure;
    }
    if (procedure?.nonSuggestedProcedure) {
      const nonSuggestedProcedure = procedure.nonSuggestedProcedure.slice(0, this.nonProcedureLength);
      kodiakProcedures = kodiakProcedures.length ? kodiakProcedures.concat(nonSuggestedProcedure) : nonSuggestedProcedure;
    }
    this._userImpressionUtil.postShopForProcedureImpression(kodiakProcedures, procedure.personalizedIndicator);
  }
}
