import { Component } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-pf-assign-pcp-container-cmp',
  templateUrl: '../views/pfAssignPcpContainerCmp.html'
})
export class PFAssignPcpContainerComponent extends BaseComponent {

  backText: string = '';

  constructor(route: ActivatedRoute, eventHandler: EventHandler, public _appSession: AppSession,
    private _apputil: AppUtility,
    contentHlpr: ContentHelper, private _navSvc: NavigationService) {
    super(route, eventHandler, _appSession, contentHlpr, 'PFAssignPcpContainerComponent', 'assignPcpContainerCmpContent');
  }

  ngOnInit(): void {
    const prevPagPath = this._navSvc.previousRoute + '';
    this.backText = this.content.back[prevPagPath];
    this._apputil.scrollToTop();
  }

  onGoBackClick() {
    this._navSvc.navigateByUrl(this._navSvc.previousRoute);
  }

  /**
   * On successful completion of Assign PCP process
   */
  onComplete() {
    this._navSvc.navigateByUrl(AppNavigations.CARE_CIRCLE_PATH);
  }
}
