import { Injectable } from '@angular/core';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IConfiguredSearchResponse } from '../../search-providers/interfaces/iPreconfigSearch';
import { IRuleParameters } from '../interfaces/iRulesParameters';
import { IRulesResponse } from '../interfaces/iRulesResponse';
import { apiNameList } from './../../../../environments/api-name-list';
import { BaseService } from './../../../common/services/baseService';

@Injectable()
export class RulesService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getRules(reqObj: IRuleParameters): Promise<IRulesResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.rule,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getAppInitializationRules(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.appInitializationRules,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getPreconfiguredSearchRule(reqObj: IRuleParameters): Promise<IConfiguredSearchResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.preConfigureSearch,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
