import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IFilter } from "../interfaces/iAppliedFilters";

@Injectable({ providedIn: "root" })
export class AppliedFilterService {
  public filterItems: Map<string, IFilter[]> = new Map<string, IFilter[]>();
  private appliedFilters = new BehaviorSubject<any>(this.filterItems);
  private remove = new BehaviorSubject<IFilter>(undefined);

  get getAppliedFilters() {
    return this.appliedFilters.asObservable();
  }

  get removeAppliedFilter() {
    return this.remove.asObservable();
  }

  addFilter(category: string, values: IFilter[]) {
    if (this.filterItems.has(category)) {
      this.filterItems.delete(category);
    }
    if (values?.length) {
      this.filterItems.set(category, values);
    }
    this.appliedFilters.next(this.filterItems);
  }

  removeFilter(filter: IFilter) {
    if (filter && this.filterItems?.has(filter?.category)) {
      let values: IFilter[] = [];
      values = this.filterItems.get(filter.category);
      values = values.filter((item) => item.value !== filter.value);
      if (values?.length) {
        this.filterItems.set(filter.category, values);
      } else {
        this.filterItems.delete(filter.category);
      }
      this.appliedFilters.next(this.filterItems);
    }
    this.remove.next(filter);
  }

  clearFilters(category?: string) {
    if (category) {
      this.filterItems.delete(category);
    } else {
      this.filterItems.clear();
    }
    this.appliedFilters.next(this.filterItems);
  }

  getFilterKeys(): string[] {
    return Array.from(this.filterItems?.keys());
  }
}
