import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { GlobalFooterComponent } from './globalFooter';

@NgModule({
    declarations: [GlobalFooterComponent],
    exports: [GlobalFooterComponent],
    imports: [
        CommonModule,
        UxSharedModule.forRoot(),
        FormsModule
    ],
    providers: []
})
export class GlobalFooterModule { }
