import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { ActionableHeaderModule } from '../../common/components/actionable-header/actionableHeaderModule';
import { PFSearchCostEstimateSlideOutModule } from '../../common/components/cost-slideout-panel/pfSearchCostEstimateSlideOutModule';
import { HealthwiseModule } from '../../common/components/healthwise/healthwiseModule';
import { PFCostInfoModule } from './../../common/components/cost-info/pfCostInfoModule';
import { PFCptCostInfoComponent } from './components/pfCptCostInfoCmp';
import { PFPricingDetailContainerComponent } from './components/pfPricingDetailContainerCmp';
import { PFProcedureCostInfoComponent } from './components/pfProcedureCostInfoCmp';
import { PFProcedureDetailsComponent } from './components/pfProcedureDetailsCmp';

@NgModule({
    imports: [UxSharedModule, FormsModule, CommonModule, PFCostInfoModule, HealthwiseModule, PFSearchCostEstimateSlideOutModule, ActionableHeaderModule],
    declarations: [PFProcedureDetailsComponent,PFPricingDetailContainerComponent, PFProcedureCostInfoComponent, PFProcedureDetailsComponent, PFCptCostInfoComponent],
    exports: [PFProcedureDetailsComponent, PFPricingDetailContainerComponent, PFProcedureCostInfoComponent, PFCptCostInfoComponent],
    providers: []
})
export class PFCostDetailsModule {
  static forRoot(): ModuleWithProviders<PFCostDetailsModule> {
    return {
      ngModule: PFCostDetailsModule
    };
  }
}
