import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFToggleComponent } from './pfToggleComponent';

@NgModule({
    declarations: [PFToggleComponent],
    exports: [PFToggleComponent],
    imports: [
        CommonModule,
        UxSharedModule.forRoot()
    ],
    providers: []
})
export class ToggleModule { }
