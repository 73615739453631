import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFCostInfoComponent } from './pfCostInfoCmp';

@NgModule({
    declarations: [PFCostInfoComponent],
    exports: [PFCostInfoComponent],
    imports: [UxSharedModule, FormsModule, CommonModule],
    providers: []
})
export class PFCostInfoModule {
  static forRoot(): ModuleWithProviders<PFCostInfoModule> {
    return {
      ngModule: PFCostInfoModule
    };
  }
}
