import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { LookUpContainerCmpComponent } from './lookUpContainerCmp';

@NgModule({
  declarations: [LookUpContainerCmpComponent],
  imports: [CommonModule, UxSharedModule, FormsModule],
  exports: [LookUpContainerCmpComponent]
})
export class LookUpContainerModule {}
