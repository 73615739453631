import { AppSession } from '../../../common/values/appSession';
import { FindCareFeature } from '../enums/findCareFeature';
import { FindCareFlag } from '../enums/findCareFlag';

export class FeatureManagerUtility {
  /**
   * Evaluates whether a given feature is available under appSession.bootstrap.features.
   * @returns true if feature available for the currently selected contractUid-MbrUid, otherwise false. @default false.
   */
  static isFeatureLive(value: FindCareFeature | Array<FindCareFeature>, appSession: AppSession, logicBetween: 'AND' | 'OR' = 'OR'): boolean {
    const _features = appSession?.bootstrap?.features || [];
    if (_features.length === 0 || !value) {
      return false;
    }
    if (Array.isArray(value)) {
      if (logicBetween === 'AND') {
        return value.every((v) => _features.includes(v));
      } else {
        return value.some((v) => _features.includes(v));
      }
    } else if (typeof value === 'string') {
      return _features.includes(value);
    } else {
      return false;
    }
  }

  /**
   * Evaluates whether a given flag is available under appSession.appFlags.
   * @returns true if flag available for the currently selected contractUid-MbrUid, otherwise false. This also evaluates productFlags. @default false.
   */
  static isFlagLive(value: FindCareFlag | Array<FindCareFlag>, appSession: AppSession, logicBetween: 'AND' | 'OR' = 'OR'): boolean {
    const _flags = appSession?.appFlags || [];
    if (_flags.length === 0 || !value) {
      return false;
    }
    if (Array.isArray(value)) {
      if (logicBetween === 'AND') {
        return value.every((v) => _flags.includes(v));
      } else {
        return value.some((v) => _flags.includes(v));
      }
    } else if (typeof value === 'string') {
      return _flags.includes(value);
    } else {
      return false;
    }
  }

  /** Check whether FCR is available for the current system state. */
  static isFcrEnabled(appSession: AppSession): boolean {
    return FeatureManagerUtility.isFlagLive(FindCareFlag.FCR, appSession) && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_V5, appSession);
  }
}
