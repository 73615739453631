import { CommonModule, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { ChangePlanModule } from '../common/components/change-plan/pfChangePlanModule';
import { CommonAlertModule } from '../common/components/common-alert/commonAlertModule';
import { EmailModule } from '../common/components/email/pfEmailModule';
import { InfoListModule } from '../common/components/info-list/pfInfoListModule';
import { MapModule } from '../common/components/map/pfMapModule';
import { OnlinePharmacyLabelModule } from '../common/components/online-pharmacy-label/onlinePharmacyLabelModule';
import { AssignPcpModule } from '../common/components/pcp/pfAssignPcpModule';
import { ScheduleAppointmentModule } from '../common/components/schedule-appointment/scheduleAppointmentModule';
import { VirtualCareLabelModule } from '../common/components/virtual-care-label/virtualCareLabelModule';
import { CustomPipesModule } from '../common/pipes/customPipesModule';
import { CareCircleAdvocateDetailsComponent } from './components/careCircleAdvocateDetailsCmp';
import { CareCircleContainerComponent } from './components/careCircleContainerCmp';
import { CareCircleListComponent } from './components/careCircleListCmp';

@NgModule({
  imports: [UxSharedModule, FormsModule, ReactiveFormsModule, CommonModule, ChangePlanModule,
    AssignPcpModule, MapModule, EmailModule, ScheduleAppointmentModule, InfoListModule, VirtualCareLabelModule,
    CustomPipesModule, CommonAlertModule, OnlinePharmacyLabelModule],
  declarations: [CareCircleContainerComponent, CareCircleListComponent, CareCircleAdvocateDetailsComponent],
  exports: [CareCircleContainerComponent, CareCircleListComponent, CareCircleAdvocateDetailsComponent]
})
export class CareCircleModule {
  static forRoot(): ModuleWithProviders<CareCircleModule> {
    return {
      ngModule: CareCircleModule,
      providers: [TitleCasePipe]
    };
  }
}
