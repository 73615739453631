import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'highlightText' })
export class HighlightPipe implements PipeTransform {
    /* use this for single match search */
    static SINGLE_MATCH: string = 'Single-Match';
    /* use this for single match search with a restriction that target should start with search string */
    static SINGLE_AND_STARTS_WITH_MATCH: string = 'Single-And-StartsWith-Match';
    /* use this for global search */
    static MULTI_MATCH: string = 'Multi-Match';
    static HASH_WITH_SPACE  = ' ### ';
    static BACKSLASH = '/';

    transform(
        contentString: string = null,
        stringToHighlight: string = null,
        option: string = 'Single-And-StartsWith-Match',
        caseSensitive: boolean = false,
        highlightStyleName: string = 'search-highlight'
    ): SafeHtml {
        if (stringToHighlight && contentString && option) {
            const _regex: Array<any> = [];
            const caseFlag: string = !caseSensitive ? 'i' : '';
            switch (option) {
                case 'Single-Match': {
                    const regex: any = {
                        subregex: '',
                        regex: ''
                    };
                    regex.subregex = new RegExp(stringToHighlight, caseFlag);
                    regex.regex = new RegExp('[\>][^\<\>.]*' + stringToHighlight + '[^\<\>.]*[^\<]', caseFlag);
                    _regex.push(regex);
                    break;
                }
                case 'Single-And-StartsWith-Match': {
                    const regex: any = {
                        subregex: '',
                        regex: ''
                    };
                    regex.subregex = new RegExp('^' + stringToHighlight, caseFlag);
                    regex.regex = new RegExp('[\>][^\<\>.]*' + stringToHighlight + '[^\<\>.]*[^\<]', caseFlag);
                    _regex.push(regex);
                    break;
                }
                case 'Multi-Match': {
                    const regex: any = {
                        subregex: '',
                        regex: ''
                    };
                    regex.subregex = new RegExp(stringToHighlight, 'g' + caseFlag);
                    regex.regex = new RegExp('[\>][^\<\>.]*' + stringToHighlight + '[^\<\>.]*[^\<]', 'g' + caseFlag);
                    _regex.push(regex);
                    break;
                }
                case 'Multi-Word-Match': {
                    stringToHighlight = stringToHighlight.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
                    const _keywords = stringToHighlight.split(' ').map((c) => c.trim());
                    for (const key of _keywords) {
                        let _key = key.replace(/(?<=\S)\W(?=\S)/g, ''); // remove inbetween special chars from 'key'
                        _key = _key.replace(/^[^\w]+|[^\w]+$/g, ''); // Remove starting and trailing special characters from '_key'
                        if (_key.length > 2) {
                            const regex: any = {
                                subregex: '',
                                regex: ''
                            };
                            regex.subregex = new RegExp(_key, 'g' + caseFlag);
                            regex.regex = new RegExp('[\>][^\<\>.]*' + _key + '[^\<\>.]*[^\<]', 'g' + caseFlag);
                            _regex.push(regex);
                        }
                    }
                    break;
                }
                default: {
                    // default will be a global case-insensitive match
                    const regex: any = {
                        subregex: '',
                        regex: ''
                    };
                    regex.regex = new RegExp('[\>][^\<\>.]*' + stringToHighlight + '[^\<\>.]*[^\<]', 'gi');
                    regex.subregex = new RegExp(stringToHighlight, 'gi');
                    _regex.push(regex);
                }
            }

            let replaced = contentString.replace(HighlightPipe.BACKSLASH, HighlightPipe.HASH_WITH_SPACE).split(' ');
            let indexArray = [];
            for (const x of _regex) {
                const indexToBeUpdated = replaced.findIndex((val) => x.subregex.test(val));
                if (indexToBeUpdated !== -1) {
                    indexArray.push(indexToBeUpdated);
                }
            }
            indexArray.forEach((val) => {
                const valToReplace = replaced[val];
                replaced[val] = replaced[val].replace(valToReplace, `<span class="${highlightStyleName}">${valToReplace}</span>`)
            });
            return replaced.join(' ').replace(HighlightPipe.HASH_WITH_SPACE, HighlightPipe.BACKSLASH);
        } else {
            return contentString;
        }
    }
}
