import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationTrackingService } from '../../../app-elements/provider-finder/navigationTrackingSvc';
import { PFBackNavigationService } from '../../../app-elements/provider-finder/pfBackNavigationService';
import { EventHandler } from '../../services/eventHandler';
import { NavigationService } from '../../services/navigationService';
import { AppUtility } from '../../utilities/appUtil';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
@Component({
  selector: 'app-global-back-button',
  templateUrl: './globalBackBtnCmp.html',
  animations: [
    trigger('scrollAnimation', [
      state('top', style({
        position: 'sticky',
        border: 'none',
        width: '100%'
      })),
      state('scrolled', style({
        transform: '{{translateY}}',
        position: 'fixed',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        width: '{{stickyBarWidth}}'
      }), { params: { translateY: 'translateY(6rem)', stickyBarWidth:'100%' } }),
      transition('top => scrolled', animate('1ms ease-out')),
      transition('scrolled => top', animate('1ms ease-in'))
    ]),
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void => *', animate('2000ms ease-in'))
    ])
  ]
})
export class GlobalBackButtonComponent extends BaseComponent {
  animationState: string = 'top';
  globalContentLabels = {};
  translateY: string = 'translateY(6rem)';
  stickyBarWidth: string = '100%';
  isIntegratedSecureState: boolean = false;
  isEmulatedMode: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private ngZone: NgZone,
    private navigationTrackingService: NavigationTrackingService,
    private pfBackNavigationService: PFBackNavigationService,
    private _navigationSvc: NavigationService,
    private _appUtility: AppUtility
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper);
  }
  onScroll(event: Event): void {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition > 50) {
      const scrollTop = (document.getElementsByClassName('mbr-top-of-page-wrapper')[0] as HTMLElement)?.offsetHeight + 'px' ?? '6rem';
      this.translateY = `translateY(${scrollTop})`;
      this.animationState = 'scrolled';
      this.fixBrowserFullScreenIssue();
    } else {
      this.animationState = 'top';
    }
  }

  fixBrowserFullScreenIssue() {
    const browser = this._appUtility.getBrowserName();
    if (this.isIntegratedSecureState) {
      switch (browser) {
        case 'chrome':
        case 'safari':
          this.stickyBarWidth = '-webkit-fill-available';
          break;
        case 'firefox':
          this.stickyBarWidth = '-moz-available';
          break;
        case 'edge':
          this.stickyBarWidth = 'fill-available';
          break;
        default:
          this.stickyBarWidth = '100%';
      }
    } else {
      this.stickyBarWidth = '100%';
    }
  }

  ngOnInit(): void {
    window.addEventListener('scroll', (event: CustomEvent) => {
      this.onScroll(event);
    });
  }
  ngAfterContentChecked() {
    this.isIntegratedSecureState = this._appSession.isIntegratedMode && this._appSession.isSecureState && !this._appSession.isHcidSearch && !this.isEmulatedMode;
    this.isEmulatedMode = (this._appSession.isEmulation || (this._appSession.metaData && this._appSession.metaData.pfEmMode));
  }
  ngOnDestroy(): void {
    this._appSession.navigationStackCount = 0;
    this._appSession.navigationStack = [];
    window.removeEventListener('scroll', this.onScroll);
  }
  showBackButton() {
    this.globalContentLabels = this._contentHelper.getContent('PFSearchContainerComponent')??  this._contentHelper.getContent('VirtualCareContainerComponent');
    const countToBeComparedToDisplayBackBtn = 1;
    return this.navigationTrackingService.getNavigationHistory()
      && (this._appSession.navigationStackCount > countToBeComparedToDisplayBackBtn)
      && this.isBBBFeatureEnabled
      && !this._appSession.isGlobalBackBtnHidden;
  }
  onBackButtonClick() {
    this.pfBackNavigationService.updateNavigation();
  }
}
