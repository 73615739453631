import { CareNowCard } from '../interfaces/iCareNow';

export const CARE_NOW_CARDS_CRITERIA: CareNowCard[] = [
  {
    id: 'virtualCare',
    searchCriteria: {
      virtualOnly: true
    }
  },
  {
    id: 'retailHealthClinic',
    searchCriteria: {
      providerTypeCodeList: ['C', 'U'],
      specialtyCategoryCodes: ['219'],
      sortBy: 'distance'
    }
  },
  {
    id: 'urgentCareCenter',
    searchCriteria: {
      providerTypeCodeList: ['U'],
      sortBy: 'distance'
    }
  },
  {
    id: 'emergencyRoom',
    searchCriteria: {
      providerTypeCodeList: ['H', 'U'],
      specialtyCategoryCodes: ['28', '237'],
      taxonomyCodes: ['261QE0002X', '282N00000X'],
      sortBy: 'distance'
    }
  }
];
