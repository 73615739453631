import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper } from '@anthem/uxd/alert';
import { ProviderDetailsNavigationService } from './../../../fad/provider-details/services/providerDetailsNavigationSvc';
import { PROVIDER_CATEGORY_CODE } from './../../../fad/search-providers/values/providerSearchConstants';
import { ILocation, IProvider } from './../../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { INFOBOX_EVENTS } from './../../enums/infoBox';
import { PhonePatternPipe } from './../../pipes/phonePatternPipe';
import { EventHandler } from './../../services/eventHandler';
import { NavigationService } from './../../services/navigationService';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { BaseComponent } from './../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-provider-info-cmp',
  templateUrl: './pfProviderInfo.html'
})
export class PFProviderInfoComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  set providerInfo(value: any) {
    this.professional = value;
    this.buildCompareCheckBox(this.professional);
    this.location = CommonUtil.swapOfficeLocation(this.showSPFI, this.professional);
  }
  @Input()
  selectedChkBox: string[] = [];
  @Input()
  set index(value: number) {
    this.i = value;
  }
  @Input()
  eventKey: string = '';
  @Output()
  providerSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  scheduleAppointment: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  assignCare: EventEmitter<any> = new EventEmitter<any>();


  professional: any;
  location: ILocation;
  isMemberSecure: boolean = false;
  showBeFirstToReview: boolean = true;
  isShowDistance: boolean = true;
  proSearchResultsCheckboxs: any[] = [];
  vitalsSsoConfig: any;
  i: number = 0;
  showMoreLink: boolean = false;
  private _inNetwork: string = 'TP_INNETWORK';

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _phonePatternPipe: PhonePatternPipe,
    private _navigationService: NavigationService,
    private _alertHlpr: AlertHelper,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    //TODO:
    this.isShowDistance = (this._appSession && this._appSession.searchParams && this._appSession.searchParams.coordinates &&
      ((this._appSession.searchParams.coordinates.latitude === "0" && this._appSession.searchParams.coordinates.longitude === "0")
        || (this._appSession && this._appSession.searchParams && typeof this._appSession.searchParams.coordinates.latitude === 'undefined'
          && typeof this._appSession.searchParams.coordinates.longitude === 'undefined'))
      ? false : true);
    this.isMemberSecure = this.showBeFirstToReview = CommonUtil.isMemberSecure(this._appSession);
  }

  ngAfterViewInit() {
    //TODO:
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['eventKey'] !== 'undefined' &&
      changes['eventKey'] !== null &&
      typeof changes['eventKey'].currentValue !== 'undefined' &&
      changes['eventKey'].currentValue !== null &&
      changes['eventKey'].currentValue !== '') {

      const eventKey = changes['eventKey'].currentValue as string;

      this.handleEvents(eventKey);
    }
  }

  onCheckboxClick(event: any, provider: any, id: string) {
    const chkBox = event.target as HTMLInputElement;
    if (chkBox != null && chkBox.checked !== undefined) {
      const isChecked = chkBox.checked;
      if (isChecked && this.selectedChkBox.length === 3) {
        const t = this._alertHlpr.openAlert(
          this.content.alerts.compareInfo,
          'information',
          10000
        );
        this.proSearchResultsCheckboxs.find((cb) => cb.id === id).value = false;
        return false;
      }
      this.providerSelect.emit({
        Checked: isChecked, CtrlId: id, Provider: provider
      });
      return true;
    }
  }

  showAffiliation(professional: IProvider) {
    return this.isEYC && this.showSPFI && professional && professional.eycAffliation && professional.eycAffliation.affiliationList[0] && professional.eycAffliation.isVisibleAffiliation;
  }

  getPairedProviderLabel(code: string): string {
    if (code) {
      return code === PROVIDER_CATEGORY_CODE.Individual ? this.content.summaryComponent.providingCareAt : this.content.summaryComponent.performedBy;
    }
    return '';
  }

  onAppointmentSchedule(data: any) {
    this.scheduleAppointment.emit(data);
  }

  onAssignCare(data: any) {
    this.assignCare.emit(data);
  }

  showInNetworkLabel(professional: any): boolean {
    if (professional && professional.providerNetworkStatus &&
      professional.providerNetworkStatus !== '' &&
      professional.providerNetworkStatus.statusCode !== '' &&
      professional.providerNetworkStatus?.statusCode?.toLocaleLowerCase() !== this._inNetwork.toLocaleLowerCase()) {
      return true;
    }
    return false;
  }

  getGenderText(genderCode: any) {
    return this.WcsUtility.getGenderText(genderCode);
  }

  getPCPLabelText(professional: any): string {
    return (professional && professional.gender && professional.gender !== '') ?
      ', ' + this.content.summaryComponent.pcpid : this.content.summaryComponent.pcpid;
  }

  showProviderDetails(professional: any) {
    if (this.isGreyedout(professional, true)) {
      return;
    }

    this._providerDetailsNavigationService.navigateToProviderDetails(professional);
  }

  showReviewLink(totalReviews: any) {
    return CommonUtil.showReviewLink(totalReviews);
  }

  isGreyedout(professional: any, enableFutureProvider: boolean): boolean {
    // Feature flag to disable future effective provider navigation to details page.
    if (!this.isFutureEffectiveProviderEnabled) {
      enableFutureProvider = false;
    }

    if ((professional && professional.eycCostInfo && professional.eycCostInfo.isGreyedout)) {
      return true;
    } else if (!enableFutureProvider && (professional && professional.isFutureEffective)) {
      return true;
    }
    return false;
  }

  vitalsSsoConfigValue(professional: any) {
    this.vitalsSsoConfig = this.WcsUtility.getVitalsSsoConfig(professional);
    return this.vitalsSsoConfig;
  }

  administrativeOffice(activeFlagList: Array<string>): boolean {
    return this.WcsUtility.isAdministrativeOffice(activeFlagList);
  }

  formatDistance(distance: string) {
    return (distance ? parseFloat(distance).toFixed(2) : '');
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  showAssignPCPBtn(professional: IProvider): boolean {
    if ((professional && professional.visibilityRule && professional.visibilityRule.showAssignPCP)
      && (this._appSession.feature?.customizedFeatures?.showAssignPCPBtn)) {
      return true;
    }
    return false;
  }

  showAddCareTeamBtn(professional: any): boolean {
    if (this.isAddCareTeamEnabled &&
      professional && professional.visibilityRule && professional.visibilityRule.showAddCareTeam) {
      return true;
    }
    return false;
  }

  get isEYC(): boolean {
    return this._appSession.isEyc;
  }

  private handleEvents(eventKey: string) {
    switch (eventKey) {
      case INFOBOX_EVENTS.PROVIDER_NAME:
      case INFOBOX_EVENTS.SEE_ALL:
        {
          this.showProviderDetails(this.professional);
          break;
        }
      case INFOBOX_EVENTS.SHOW_MORE:
        this.showMoreLink = !this.showMoreLink;
        break;
      case INFOBOX_EVENTS.FACILITY_NAME:
        {
          if (this.professional && this.professional.eycAffliation && this.professional.eycAffliation.affiliationList[0]) {
            this.showProviderDetails(this.professional.eycAffliation.affiliationList[0]);
          }
          break;
        }
      default:
        break;
    }
  }

  private buildCompareCheckBox(professional: any) {
    const thisCheckbox = {
      id:
        'pfSearchResultsCb-' +
        this.i,
      name:
        'pfSearchResultsCb-' +
        this.i,
      label: '',
      trueValue: professional.providerIdentifier,
      falseValue: '',
      value: '',
      show: false,
      analyticsText: 'selectPhysicianResults'
    };
    this.proSearchResultsCheckboxs.push(thisCheckbox);
    if (this.selectedChkBox.length === 0) { return; }
    this.proSearchResultsCheckboxs
      .filter((cb: any) => this.selectedChkBox.some((id: string) => cb.id === id))
      .forEach((x: any) => x.value = x.trueValue);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }
}
