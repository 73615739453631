import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProviderDetailHandler } from '../classes/providerDetailHandler';
import { IProviderOverview } from '../interfaces/iProviderDetail';

@Injectable({
  providedIn: 'root'
})
export class ProviderDetail {
  private providerDetailSubject = new BehaviorSubject<IProviderOverview | undefined>(undefined);

  details = this.providerDetailSubject.asObservable();

  constructor(private detailHandler: ProviderDetailHandler) {}

  async initialize(providerIdentifier: string, addressIdentifier: string): Promise<void> {
    const currentProviderDetail = this.providerDetailSubject.getValue()?.providerDetail;
    if (currentProviderDetail?.providerIdentifier !== providerIdentifier || currentProviderDetail?.address.addressId !== addressIdentifier) {
      const providerDetail = await this.detailHandler.getProviderDetail(providerIdentifier, addressIdentifier);
      this.setDetail(providerDetail);
    }
  }

  reset(): void {
    this.providerDetailSubject.next(undefined);
  }

  private setDetail(providerDetail: IProviderOverview): void {
    if (providerDetail) {
      this.providerDetailSubject.next(providerDetail);
    }
  }
}
