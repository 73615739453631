import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { ViewHealthwiseComponent } from '../../../common/components/healthwise/pfViewHealthwiseCmp';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IHealthwiseAdobeAnalyticsObj } from '../../../fad/search-results/interfaces/iSummaryResp';
import { PFCostInfoComponent } from './../../../common/components/cost-info/pfCostInfoCmp';
import { Locale } from './../../../common/constants/app-constants';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { IProcedureCostEst, ProviderProcedureInfo } from './../../../fad/provider-details/models/iProcedureCostEst';
import { ProviderOrchestrationService } from './../../../fad/provider-details/services/providerOrchestrationSvc';
import { PROVIDER_TYPE } from './../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from './../../../fad/utilities/commonUtil';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-pf-procedure-details-cmp',
  templateUrl: '../views/pfProcedureDetailsCmp.html'
})
export class PFProcedureDetailsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input()
  costInfo: any = {};
  @Input()
  printPageprocedureCode: string;
  @Input()
  selectedProviderProcedure: any = {};
  @Input()
  showCostLoader: boolean = true;
  @ViewChild('costDetailModal')
  costDetailModal: PFCostInfoComponent;
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;
  @Output()
  procedureCodeData: EventEmitter<string> = new EventEmitter<string>();

  costDetails: any = {};
  showCostError: boolean = false;
  selectedProvider: any;
  costBeforeHRA = '';
  hraCostText = '';
  hraNocostText = '';
  isShowCostDetail: boolean = false;
  procedureDdlValues: any = [];
  selectedProcedure: string = '';
  selectedProcedureObj: any = {};
  coinsuranceMsg: string = '';
  hraCost: string = '';
  hraDesc: string = '';
  coinsuranceDesc: string = '';
  costEst: any;
  expansion: string = '';
  hraCostAriaLabel: string = '';
  hraDescAriaLabel: string = '';
  procedureName: string = '';
  noProvProcedureInfoData: boolean = false;
  copay: string = '';
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _providerOrchestrationService: ProviderOrchestrationService,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _currencyPipe: CurrencyPipe,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.expansion = CommonUtil.getAccountExpansion(this.costInfo.accntType);
    const regx = /{ACCOUNT}/gi;
    const regCoinsurance = /{COINSURANCE}/gi;
    const regCoinsurancePercent = /{COINSURANCEPRECENT}/gi;
    this.costBeforeHRA = this.content.eycProcedure.costBeforeHRA.replace(regx, this.costInfo.accntType || '');
    this.hraCostText = this.content.eycProcedure.hraCostText.replace(regx, this.costInfo.accntType || '');
    this.hraNocostText = this.content.eycProcedure.hraNocostText.replace(regx, this.costInfo.accntType || '');
    if (!(this.costInfo.coinsurancePercent < 0)) {
      this.coinsuranceMsg = this.content.eycProcedure.coinsuranceMsg.replace(regCoinsurance, this.costInfo.coinsurancePercent || '0');
    } else {
      this.coinsuranceMsg = this.content.eycProcedure.defaultCoinsuranceMsg;
    }
    if (!(this.costInfo.coinsurancePercent < 0)) {
      this.costInfo.hideNegative = false;
      this.coinsuranceDesc = this.content.eycPricing.coinsuranceDesc.replace(regCoinsurancePercent, this.costInfo.coinsurancePercent || '0');
    } else {
      this.costInfo.hideNegative = true;
      this.coinsuranceDesc = this.content.eycPricing.defaultCoinsuranceDesc;
    }
    this.costDetails.hraCostAriaLabel = this.content.eycPricing.hraCost.replace(regx, this.expansion || '');
    this.costDetails.hraCost = this.content.eycPricing.hraCost.replace(regx, this.costInfo.accntType || '');
    this.costDetails.hraDesc = this.content.eycPricing.hraDesc.replace(regx, this.costInfo.accntType || '');
    this.costDetails.hraDescAriaLabel = this.content.eycPricing.hraDesc.replace(regx, this.expansion || '');
    this.selectedProvider = this._appSession.selectedProvider;
    this.selectedProcedure = this.costInfo.procedureCode || '';
    if (this.selectedProviderProcedure && this.selectedProviderProcedure.providerCosts && this.selectedProviderProcedure.providerCosts.length > 0) {
      this.buildProcedureDropDown(this.selectedProviderProcedure.providerCosts);
      this.onProcedureChange(this.selectedProcedure);
    } else {
      this.showCostLoader = false;
      this.costEst = this.costInfo;
      this.noProvProcedureInfoData = true;
    }
    this.copay = CommonUtil.getCopayWithPeriod(this.costEst);
  }

  ngOnChanges(args: SimpleChanges) {
    if (
      args['printPageprocedureCode'] &&
      args['printPageprocedureCode'].currentValue &&
      args['printPageprocedureCode'].previousValue &&
      args['printPageprocedureCode'].currentValue !== args['printPageprocedureCode'].previousValue
    ) {
      this.onProcedureChange(args['printPageprocedureCode'].currentValue);
    }
  }

  buildProcedureDropDown(result: any) {
    const noDuplicate = this.removeDuplicates(result); /* Remove duplicate procedures */
    const nonZeroCost = this.removeZeroCostProcedures(noDuplicate); /*Removed procedure with zero cost */
    const sorted = nonZeroCost.sort((a, b) => {
      if (a.procedureName < b.procedureName) {
        return -1;
      }
      return a.procedureName > b.procedureName ? 1 : 0;
    });
    let _index = 0;
    for (const obj of sorted) {
      const objProcedures = {
        label: obj.procedureName,
        value: obj.procedureCode
      };

      if (obj.procedureCode === this.selectedProcedure) {
        this.procedureName = obj.procedureName;
      }

      this.procedureDdlValues.push(objProcedures);
      _index++;
    } //end for loop
    // no procedure selected scenario. go with default procedure.
    if (!this.selectedProcedure && sorted && sorted.length > 0) {
      this.selectedProcedure = sorted[0].procedureCode;
      this.procedureName = sorted[0].procedureName;
    }
  }

  removeDuplicates(procedureArr) {
    const filteredArr = procedureArr.reduce((acc, current) => {
      const match = acc.find((item) => item.procedureCode === current.procedureCode);
      if (!match) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return filteredArr;
  }

  removeZeroCostProcedures(procedureArr) {
    const nonZeroArr = [];
    procedureArr.forEach((obj) => {
      if (obj.procedureCode === this.selectedProcedure) {
        nonZeroArr.push(obj);
      } else {
        const checkNonZero = Object.values(obj.costInfo).some((val) => val !== '0');
        if (checkNonZero) {
          nonZeroArr.push(obj);
        }
      }
    });
    return nonZeroArr;
  }

  removeDuplicateProcedureServicesNames(procedureService: ProviderProcedureInfo) {
    const emptyObject = {};
    if (procedureService && procedureService.procedureServicesList) {
      this.selectedProcedureObj.procedureServicesList = procedureService.procedureServicesList.filter((serviceName) => !emptyObject[serviceName.name] && (emptyObject[serviceName.name] = true));
    }
  }

  onProcedureChange(selectedProcedureCode) {
    if (selectedProcedureCode === '') {
      selectedProcedureCode = this.selectedProcedureObj.procedureCode;
    }
    this.showCostLoader = true;
    if (this.selectedProviderProcedure && this.selectedProviderProcedure.providerCosts.length > 0) {
      let costInfoArr = [];
      this.selectedProviderProcedure.providerCosts.forEach((obj) => {
        if (obj.procedureCode === selectedProcedureCode) {
          this.selectedProcedureObj = obj;
          costInfoArr = [...costInfoArr, obj.costInfo];
        }
      });
      this.selectedProcedureObj.costInfoArr = costInfoArr;
      this.procedureName = this.selectedProcedureObj.procedureName;
      this.removeDuplicateProcedureServicesNames(this.selectedProcedureObj);
      if ('procedureCode' in this.costInfo && this.selectedProcedureObj.procedureCode === this.selectedProcedure) {
        this.showCostLoader = false;
        this.costEst = this.costInfo;
        const regCoinsurancePercent = /{COINSURANCEPRECENT}/gi;
        if (!(this.costInfo.coinsurancePercent < 0)) {
          this.costInfo.hideNegative = false;
          this.coinsuranceDesc = this.content.eycPricing.coinsuranceDesc.replace(regCoinsurancePercent, this.costInfo.coinsurancePercent || '0');
        } else {
          this.costInfo.hideNegative = true;
          this.coinsuranceDesc = this.content.eycPricing.defaultCoinsuranceDesc;
        }
      } else {
        this.getEstimateCost(selectedProcedureCode);
      }
      //set adobe analytics for pricing dropdown change
      this.setPricingTabProcedureDDAnalytics(this.costEst);
      this.procedureCodeData.emit(selectedProcedureCode);
    }
  }

  getEstimateCost(procedureCode) {
    this.showCostLoader = true;
    const costEstimateParams = this.constructReqParams(procedureCode);
    this._providerOrchestrationService.getProviderCostEstimate(costEstimateParams).then(
      (result: any) => {
        if (result && result.providerEycCostInfo && result.providerEycCostInfo.length > 0) {
          this.onSuccess(result.providerEycCostInfo[0]);
        }
      },
      (error: any) => {
        try {
          this.onError(error);
        } catch (e) {}
      }
    );
  }

  onError(error) {
    this.showCostLoader = false;
    this.showCostError = true;
    throw error;
  }

  onSuccess(costEst) {
    this.showCostLoader = false;
    this.showCostError = false;
    this.costEst = costEst;
    this.coinsurancePercentage();
  }
  coinsurancePercentage() {
    const regCoinsurancePercent = /{COINSURANCEPRECENT}/gi;
    if (this.costEst && this.costEst.coinsurancePercent === 0) {
      this.costEst.coinsurancePercent = '0';
    }
    if (!(this.costInfo.coinsurancePercent < 0)) {
      this.costInfo.hideNegative = false;
      this.coinsuranceDesc = this.content.eycPricing.coinsuranceDesc.replace(
        regCoinsurancePercent,
        this.costEst.coinsurancePercent ? this.costEst.coinsurancePercent : this.costInfo.coinsurancePercent || '0'
      );
    } else {
      this.costInfo.hideNegative = true;
      this.coinsuranceDesc = this.content.eycPricing.defaultCoinsuranceDesc;
    }
  }
  constructReqParams(selectedProcedureCode) {
    const procedureCostEstReq: IProcedureCostEst = {
      providerIdentifier: this.selectedProvider.providerIdentifier,
      addressIdentifier: this.selectedProvider.location && this.selectedProvider.location.address ? this.selectedProvider.location.address.addressId : '',
      isFuturePlan: this._appSession.isFutureCoverage,
      professionalSequenceNumber: this.selectedProvider.sequenceNumber,
      pdtKey: this.selectedProvider.pdtKey,
      locale: this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH,
      eycProcedureCriteria: { procedureCode: selectedProcedureCode },
      providerCostInfo: [],
      providerSituations: this.selectedProviderProcedure.providerSituation ? this.selectedProviderProcedure.providerSituation : '',
      providerTier: this.selectedProviderProcedure.providerTier ? this.selectedProviderProcedure.providerTier : '',
      oopEligible: this._appSession.oopEligible ? this._appSession.oopEligible : false,
      providerProcedureInfo: {
        procedureCode: selectedProcedureCode,
        // procedureServicesList: this.selectedProcedureObj.procedureServicesList,
        // procedureName: this.selectedProcedureObj.procedureName,
        professionalSequenceNumber: this.selectedProvider && this.selectedProvider.sequenceNumber ? this.selectedProvider.sequenceNumber : '',
        providerCosts: this.selectedProcedureObj && this.selectedProcedureObj.costInfoArr && this.selectedProcedureObj.costInfoArr.length > 0 ? this.selectedProcedureObj.costInfoArr : []
      },
      isDentalEyc:
        this._appSession.hasDentalEYC &&
        this._appSession.searchParams &&
        this._appSession.searchParams.eycSearchParam &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL,
      provCategoryCode: this.selectedProvider.providerCategoryCode
    };

    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
      procedureCostEstReq.mbrUid = this._appSession.metaData.appContract.mbrUid;
    }
    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid) {
      procedureCostEstReq.contractUid = this._appSession.metaData.appContract.contractUid;
    }
    if (this.selectedProcedureObj.costInfoArr && this.selectedProcedureObj.costInfoArr.length > 0) {
      const costArr = [];
      this.selectedProcedureObj.costInfoArr.map((costInfoObj) => {
        const costInfo = {
          inpatientLowCost: costInfoObj.costILC,
          inpatientHighCost: costInfoObj.costIHC,
          typicalLowCost: costInfoObj.costTLC,
          typicalHighCost: costInfoObj.costTHC,
          avgAllowedCost: costInfoObj.costTAC
        };
        costArr.push(costInfo);
      });
      procedureCostEstReq.providerCostInfo = costArr;
    }
    return procedureCostEstReq;
  }

  onShowCostDetails() {
    this.costDetails.costEstimate = this.costEst;
    this.costDetails.costInfo = this.costInfo;
    this.costDetails.coinsuranceDesc = this.coinsuranceDesc;
    this.costDetails.copay = this.copay;
    this.costDetailModal.openSidePanel('right', this.costDetails);
  }

  get isDentalSearch(): boolean {
    return (
      this._appSession.hasDentalEYC &&
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL
    );
  }

  //Sets the Analytics query for EYC cost search - Only on RCP integrated page
  setPricingTabProcedureDDAnalytics(costEst) {
    if (Object(costEst) && Object(costEst).length === 0) {
      return;
    }
    if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo && window.digitalData.page.pageInfo.procedureName) {
      window.digitalData.page.pageInfo.procedureName = costEst.procedureName;
    }
  }

  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: false
  };

  /** Open healthwise side Panel */
  openHealthwiseSidePanel(procedurecode: string) {
    this._adobeAnalyticsObj.procedureName = this.procedureName;
    this.viewHealthwiseContentModal.openSidePanel('right', null, null, procedurecode, this._adobeAnalyticsObj);
  }
}
