import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { PFCostInfoComponent } from '../../../common/components/cost-info/pfCostInfoCmp';
import { ViewHealthwiseComponent } from '../../../common/components/healthwise/pfViewHealthwiseCmp';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { ProviderProcedureInfo } from '../../../fad/provider-details/models/iProcedureCostEst';
import { ICostInfo, IHealthwiseAdobeAnalyticsObj } from '../../../fad/search-results/interfaces/iSummaryResp';
import { IProviderCostDetails } from '../models/iProviderCostDetails';
import { IProvider } from './../../../fad/search-results/interfaces/iSummaryResp';

@Component({
  moduleId: module.id,
  selector: 'app-pf-procedure-cost-info-cmp',
  templateUrl: '../views/pfProcedureCostInfoCmp.html'
})
export class PFProcedureCostInfoComponent extends BaseComponent {
  @Input()
  costInfo: ICostInfo = {} as ICostInfo;
  @Input()
  costEst: ICostInfo = {} as ICostInfo;
  @Input()
  costDetails: IProviderCostDetails = {} as IProviderCostDetails;
  @Input()
  selectedProcedureObj: ProviderProcedureInfo = {} as ProviderProcedureInfo;
  @Input()
  hraNocostText: string;
  @Input()
  coinsuranceDesc: string;
  @Input()
  showCostError: boolean;
  @Input()
  showCostLoader: boolean;
  @Input()
  selectedProvider: IProvider = {} as IProvider;

  @ViewChild('costDetailModal')
  costDetailModal: PFCostInfoComponent;
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;

  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: false
  }
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession,
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  onShowCostDetails() {
    this.costDetails.costEstimate = this.costEst;
    this.costDetails.costInfo = this.costInfo;
    this.costDetails.coinsuranceDesc = this.coinsuranceDesc;
    this.costDetailModal.openSidePanel('right', this.costDetails);
  }

  /** Open healthwise side Panel */
  openHealthwiseSidePanel(procedurecode: string) {
    this._adobeAnalyticsObj.procedureName = this.selectedProcedureObj.procedureName;
    this.viewHealthwiseContentModal.openSidePanel('right', null, null, procedurecode, this._adobeAnalyticsObj);
  }
}
