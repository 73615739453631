import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { CustomPipesModule } from './../../pipes/customPipesModule';
import { TrimText } from './../../pipes/trimText';
import { DisclaimerService } from './../../services/disclaimerService';
import { PFCostDisclaimerComponent } from './pfCostDisclaimerCmp';
import { PFCostDisclaimerTabComponent } from './pfCostDisclaimerTabContentCmp';
import { PFDisclaimerComponent } from './pfDisclaimerCmp';

@NgModule({
    declarations: [PFDisclaimerComponent, PFCostDisclaimerComponent, PFCostDisclaimerTabComponent],
    exports: [PFDisclaimerComponent, PFCostDisclaimerComponent],
    imports: [
        UxSharedModule,
        CommonModule,
        CustomPipesModule
    ],
    providers: [DisclaimerService, TrimText]
})
export class DisclaimerModule { }
