import { isEmpty, merge } from 'lodash';
import { AppConstants } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { PROVIDER_TYPE } from '../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtility } from './commonUtil';

export class ContentUtility {
  private _content: any;
  private _appSession: AppSession;
  isMemberSecure = false;

  constructor(content: any, appSession: AppSession) {
    this._content = content;
    this._appSession = appSession;
    if (this._appSession) {
      this.isMemberSecure = CommonUtility.isMemberSecure(this._appSession);
    }
  }

  /**
   * Retrieves the supported brand label for a given brand.
   * @param brand - The brand to retrieve the supported brand label for.
   * @param content - The content object to check for the supported brand label.
   * @returns {string} The supported brand label if found, otherwise an empty string.
   */
  getSupportedBrandLabel(brand: string, content: any): string {
    // Find the supported brand label for the given brand
    const supportedBrandLabel = AppConstants.BRAND.find((b) => b.brand === brand)?.supportedBrandLabel;

    // Check if the supported brand label exists and is present in the content object
    if (supportedBrandLabel && content && content[supportedBrandLabel]) {
      return supportedBrandLabel;
    }

    // Return an empty string if the supported brand label is not found or not present in the content object
    return '';
  }

  /**
   * Returns the Spanish name for a given plan category code.
   * If the category code is not recognized, it returns the provided category name.
   * @param catCode - The category code.
   * @param catName - The default category name.
   * @returns {string} The Spanish name for the category or the default category name if the code is not recognized.
   */
  getSpanishPlanCategoryName(catCode: string, catName: string): string {
    // Convert the category code to uppercase for comparison
    const upperCatCode = catCode.toLocaleUpperCase();

    // Map of category codes to their Spanish names
    const categoryMap: { [key: string]: string } = {
      NET: 'Red médica',
      NON: 'Redes Médicas en Intercambio',
      IND: 'Plan médico (individual y familiar)',
      EMP: 'Plan médico (patrocinado por el empleador)',
      MCR: 'Medicare',
      MCD: 'Medicaid',
      FEP: 'Programa para empleados federales',
      INT: 'Internacional',
      STU: 'Médico (salud estudiantil)',
      DEN: 'Cuidado dental',
      VIS: 'Cuidado de la visión'
    };

    // Return the Spanish name if the category code is recognized, otherwise return the default category name
    return categoryMap[upperCatCode] || catName;
  }

  /**
   * Retrieves the unknown network message for a given provider type.
   * @param providerType - The provider type to retrieve the unknown network message for.
   * @returns {string} The unknown network message for the provider type.
   */
  getUnknownNetworkMsg(providerType: PROVIDER_TYPE): string {
    switch (providerType) {
      case PROVIDER_TYPE.DOCTOR_PROFESSIONAL:
        return this._content.searchCriteriaComponent.alerts.networkUnknown
          .replace(/{TYPEOFCARE}/g,
            this._content.searchCriteriaComponent.providerTypes[PROVIDER_TYPE.DOCTOR_PROFESSIONAL]);
      case PROVIDER_TYPE.DENTAL:
        return this._content.searchCriteriaComponent.alerts.networkUnknown
          .replace(/{TYPEOFCARE}/g, this._content.searchCriteriaComponent.providerTypes[PROVIDER_TYPE.DENTAL]);
      case PROVIDER_TYPE.VISION:
        return this._content.searchCriteriaComponent.alerts.networkUnknown
          .replace(/{TYPEOFCARE}/g, this._content.searchCriteriaComponent.providerTypes[PROVIDER_TYPE.VISION]);
    }
  }
  /**
   * Overrides the original content with the updated content if both are not empty.
   * @param originalContent - The original content object.
   * @param updatedContent - The updated content object.
   * @returns {any} The merged content if both original and updated content are not empty, otherwise the original content.
   */
  overrideContent(originalContent: any, updatedContent: any): any {
    // Check if both original and updated content are not empty
    if (!isEmpty(originalContent) && !isEmpty(updatedContent)) {
      // Merge the original content with the updated content
      return merge(originalContent, updatedContent);
    }
    // Return the original content if either is empty
    return originalContent;
  }
}
