export enum DeeplinkUrl {
  SEARCH_PROVIDERS = 'fad.search-providers',
  SEARCH_RESULTS = 'fad.search-results',
  COST_SEARCH = 'eyc.cost-search',
  CARE_TEAM = 'care.care-providers',
  CARE_CIRCLE = 'fad.care-circle-provider',
  PROMOTED_PROCEDURES = 'kodiak.promoted-procedures',
  VIRTUAL_CARE = 'virtual.virtual-care',
  PROVIDER_DETAILS = 'fad.provider-details',
  CARE_NOW = 'fad.care-now',
}
