import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { IVirtualProviderConfig } from '../../../care-circle/interfaces/iCareProvider';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { BILLING_TYPE, Locale, NETWORK_TYPECODE, OFFICE_VISIT_MEDICALCODE, VIRTUAL_CARE_HUB } from '../../../common/constants/app-constants';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { AppEvents } from '../../../common/enums/appEvents';
import { IAppointmentLoader } from '../../../common/interfaces/iAppointment';
import { IAssignCareLoader } from '../../../common/interfaces/iAssignCare';
import { IFinalProvider, IVitalsSsoConfig } from '../../../common/interfaces/iCommonSearchResponse';
import { HealthwiseSidePanelRequest, SidePanelDirections } from '../../../common/interfaces/iCptMedicalResponse';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { IVirtualVisitSSO } from '../../../common/interfaces/iMessage';
import { IProvider, IRecognition } from '../../../common/interfaces/iSearchStrategyResponse';
import { DataService } from '../../../common/services/dataService';
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { ISelectedProvider } from '../../provider-details/models/iSelectedProvider';
import { ProviderDetailsNavigationService } from '../../provider-details/services/providerDetailsNavigationSvc';
import { PHARMACY_TYPE } from '../../search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../utilities/commonUtil';
import { OUTNETWORK } from '../values/providerSearchConstants';

@Component({
  selector: 'app-fad-search-result-provider-list',
  templateUrl: '../views/pfSearchResultProviderListCmp.html'
})
export class PFSearchResultProviderListCmp extends BaseComponent {
  @Input()
  professional: IProvider;
  @Input()
  idSuffix: number;
  private _pfOfficeVisitCost: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_CPT_OFC_VISIT_COST]);
  @Output()
  scheduleAppointment: EventEmitter<IAppointmentLoader> = new EventEmitter<IAppointmentLoader>();
  @Output()
  assignCare: EventEmitter<IAssignCareLoader> = new EventEmitter<IAssignCareLoader>();
  @Output()
  openRecognition: EventEmitter<IRecognition> = new EventEmitter<IRecognition>();
  @Output()
  openPairRecognition: EventEmitter<IProvider> = new EventEmitter<IProvider>();
  @Output()
  openHealthWiseContentModal: EventEmitter<HealthwiseSidePanelRequest> = new EventEmitter<HealthwiseSidePanelRequest>();
  @Output()
  openMapModal: EventEmitter<IProvider> = new EventEmitter<IProvider>();
  @Output()
  assignPcpModal: EventEmitter<object> = new EventEmitter<object>();
  networkTypeConst = NETWORK_TYPECODE;
  isMemberSecure: boolean;
  showBeFirstToReview = true;
  outNetworkCode: string = OUTNETWORK;
  vitalsSsoConfig: IVitalsSsoConfig;
  hasTalkSpaceSSO: boolean;
  ssoConfig: IVirtualVisitSSO;
  private _inNetwork: string = 'TP_INNETWORK';
  hasCostInfo: boolean = false;
  private pfOfficeVisitCostSubscription: Subscription;

  constructor(
    private _dataService: DataService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _appSession: AppSession,
    route: ActivatedRoute,
    private _eventHandler: EventHandler,
    contentHlpr: ContentHelper,
    private _navigationService: NavigationService
  ) {
    super(route, _eventHandler, _appSession, contentHlpr, 'PFSearchResultsContainerComponent');
    this.pfOfficeVisitCostSubscription = this._pfOfficeVisitCost.subscribe((resp) => {
      const costDetails = resp.object;
      if (costDetails.providerList) {
        for (const provider of costDetails.providerList) {
          if (provider.providerKey === this.professional?.providerIdentifier && provider.addressKey === this.professional?.location?.address?.addressId) {
            this.professional = { ...this.professional, costInfo: provider.costInfo };
            break;
          }
        }
      }
      this.hasCostInfo = this.professional && CommonUtil.isValidString(this.professional.costInfo?.planPays) ? true : false;
    });
  }

  ngOnInit() {
    if (this.professional?.costInfo) {
      this.hasCostInfo = true;
    }
    this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    this.hasTalkSpaceSSO = this._appSession.isSecureState && this._appSession.hasTalkSpaceSSO;
  }

  ngOnDestroy(): void {
    if (this.pfOfficeVisitCostSubscription) {
      this.pfOfficeVisitCostSubscription.unsubscribe();
    }
  }

  openHealthwiseSidePanel({ specialty, provider }: HealthwiseSidePanelRequest) {
    this.openHealthWiseContentModal.emit({ specialty, provider });
  }

  showProviderDetails(professional: IProvider) {
    let selectedProvider = {} as ISelectedProvider;
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };

    if (!isEmpty(professional)) {
      selectedProvider = {
        providerIdentifier: professional.providerIdentifier,
        providerTypeCodeList: professional.providerTypeCodeList,
        providerName: professional.providerName,
        pdtStrKey: professional.pdtStrKey || '',
        providerPlanType: professional.providerPlanType || undefined
      } as ISelectedProvider;
      if (professional.location?.address) {
        selectedProvider.location = {
          address: {
            addressId: professional.location.address.addressId,
            city: professional.location.address.city,
            state: professional.location.address.state ?? '',
            postalCode: professional.location.address.postalCode,
            latitude: professional.location.address.latitude,
            longitude: professional.location.address.longitude
          }
        };
      }
    }
    if (professional?.costInfo?.isOfficeVisitCost && CommonUtil.isValidString(professional.costInfo?.copay)) {
      this._appSession.medicalCode = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
      this._appSession.billingType = BILLING_TYPE.CPT;
      this._appSession.selectedPosCode = this._appSession.metaData?.locale === Locale.ENGLISH ? OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_EN : OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_ES;
      this._appSession.searchTerm = OFFICE_VISIT_MEDICALCODE.SEARCH_TERM;
      this._appSession.isSpecialtyOfficeVisitCost = true;
    }

    this._dataService.setPageTitle(pageFadObj);
    this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
  }

  openAssignPcpModal(direction: SidePanelDirections, professional: IProvider) {
    this.assignPcpModal.emit({ direction, professional });
  }

  onAppointmentSchedule(data: IAppointmentLoader) {
    this.scheduleAppointment.emit(data);
  }

  onAssignCare(data: IAssignCareLoader) {
    this.assignCare.emit(data);
  }

  openLocationMapModal(professional: IProvider) {
    this.openMapModal.emit(professional);
  }

  showInNetworkLabel(professional: IProvider): boolean {
    if (
      professional.providerNetworkStatus &&
      professional.providerNetworkStatus.statusCode !== '' &&
      professional.providerNetworkStatus.statusCode.toLocaleLowerCase() !== this._inNetwork.toLocaleLowerCase()
    ) {
      return true;
    }
    return false;
  }

  showAssignPCPBtn(professional: IProvider): boolean {
    if (professional && professional.visibilityRule && professional.visibilityRule.showAssignPCP && this.customizedFeatures.showAssignPCPBtn) {
      return true;
    }
    return false;
  }

  openRecognitionModal(recognition: IRecognition) {
    this.openRecognition.emit(recognition);
  }

  openPairRecognitionModal(professional: IProvider) {
    this.openPairRecognition.emit(professional);
  }

  isCallToActionInternal(callToAction: IVirtualProviderConfig) {
    return !isEmpty(callToAction) && callToAction?.internalRedirectId === VIRTUAL_CARE_HUB;
  }

  isCallToActionEnabled(callToAction: IVirtualProviderConfig) {
    return !isEmpty(callToAction);
  }

  virtualURlSsoConfigValue(callToAction: IVirtualProviderConfig): IVirtualVisitSSO {
    this.ssoConfig = this.WcsUtility.getVirtualCareUrlSsoConfig(callToAction.ssoKey);
    return this.ssoConfig;
  }

  handleVirtualProviderCallToAction(callToAction: IVirtualProviderConfig) {
    if (callToAction?.internalRedirectId && callToAction.internalRedirectId === VIRTUAL_CARE_HUB) {
      this.goToVirtualCare();
    } else if (callToAction?.virtualCareUrl) {
      this.goToPublicUrl(callToAction.virtualCareUrl);
    }
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  goToVirtualCare() {
    this._navigationService.navigateByUrl(AppNavigations.VIRTUAL_CARE_PATH);
  }

  goToPublicUrl(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  showAddCareTeamBtn(professional: IProvider): boolean {
    if (this.isAddCareTeamEnabled && professional && professional.visibilityRule && professional.visibilityRule.showAddCareTeam && this.customizedFeatures.showAdddCareTeam) {
      return true;
    }
    return false;
  }

  showAddReviewButton(professional: IFinalProvider): boolean {
    if (professional?.reviewRatings?.length && !isEmpty(professional.reviewRatings[0])) {
      return (
        this.checkIndividualProvWithProvTypeP(professional.providerCategoryCode?.code, professional.providerTypeCodeList) &&
        professional.reviewRatings[0].pdtStrKey &&
        this.showBeFirstToReview &&
        !this.isGreyedOut(professional, false)
      );
    }
    return false;
  }

  vitalsSsoConfigValue(professional: IFinalProvider, showAddReviewLabel?: boolean, useClassForAddReview?: boolean) {
    this.vitalsSsoConfig = this.WcsUtility.getVitalsSsoConfig(professional, showAddReviewLabel, useClassForAddReview);
    return this.vitalsSsoConfig;
  }

  checkOutNetworkStatus(statusCode: string): boolean {
    return statusCode === this.outNetworkCode;
  }

  isGreyedOut(professional: IFinalProvider, enableFutureProvider: boolean): boolean {
    // Feature flag to disable future effective provider navigation to details page.
    if (professional?.pharmacyType === PHARMACY_TYPE.AHD && professional?.isShipDirectToDoor) {
      return true;
    }

    if (!this.isFutureEffectiveProviderEnabled) {
      enableFutureProvider = false;
    }
    return !!(professional?.eycCostInfo?.isGreyedout || (!enableFutureProvider && professional?.isFutureEffective));
  }

  checkIndividualProvWithProvTypeP(provCategoryCode: string, providerTypeCodeList: Array<string>) {
    return CommonUtil.checkIndividualProvWithProvTypeP(provCategoryCode, providerTypeCodeList);
  }
}
