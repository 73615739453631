import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base-component/baseCmp';
import { EventHandler } from './../../../services/eventHandler';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';
import { ClearButton, PillActions } from './../values/pillActions';

export interface IPillCmpEvent {
  /** The chip the event was fired on. */
  chip: PillItemCmp;
}
@Component({
  selector: 'app-pill-item',
  templateUrl: './pillItemCmp.html'
})
export class PillItemCmp extends BaseComponent implements OnInit {
  @Output() readonly actions: EventEmitter<PillActions> =
    new EventEmitter<PillActions>();
  @Output() readonly isLookupLoad: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() readonly removed: EventEmitter<IPillCmpEvent> = new EventEmitter<IPillCmpEvent>();
  @Input() id: any = 'pill-id-';
  @Input() pillTitle: string;
  @Input() showHeader: boolean = false;
  @Input() lookupCSS: string = '';
  @Input()
  get selectedCount(): number { return this._count; }
  set selectedCount(value: number) {
    if (value && value > 0) {
      this.highlightPill = true;
      this._count = value;
    } else {
      this.highlightPill = false;
      this._count = undefined;
    }
  }
  @Input() showButton: boolean = false;
  @Output()
  onPillItemFocus = new EventEmitter()
  @ViewChild('pillChip')
  pillChip: ElementRef;

  /** Whether the pill is selected. */
  @Input()
  get selected(): boolean { return this._selected; }
  set selected(value: boolean) {
    const coercedValue = coerceBooleanProperty(value);

    if (coercedValue !== this._selected) {
      this._selected = coercedValue;
    }
  }

  @Input()
  get removable(): boolean { return this._removable; }
  set removable(value: boolean) {
    this._removable = coerceBooleanProperty(value);
  }

  @HostBinding('class.pillItemCmp') pillItemCls = true;
  parentCmp = this;
  highlightPill: boolean = false;
  protected _removable: boolean = true;
  protected _selected: boolean = false;
  protected _count: number;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  /** Toggles the current selected state of this chip. */
  toggleSelected() {
    this._selected = !this.selected;
    return this.selected;
  }

  remove(): void {
    if (this.removable) {
      this.removed.emit({chip: this});
    }
  }

  pillFocus() {
    if (this.pillTitle === this.content.summaryComponent.filter.type) {
      this.onPillItemFocus.emit();
    }
  }

  handleClick(event: Event) {
    this.toggleSelected();
    this.actions.emit(new PillActions(this.pillChip, this.selected));
  }

  onClearButtonClick(event: Event) {
    this.actions.emit(new ClearButton(this.pillChip, this.selected));
  }

  onLookupLoad(event: boolean) {
    this.isLookupLoad.emit(event);
  }

  handleKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Esc':
      case 'Escape':
        // If we are removable, remove the focused chip
        this.remove();
        // Always prevent so page navigation does not occur
        event.preventDefault();
        break;
      case 'Enter':
      case ' ':
        this.toggleSelected();
        this.actions.emit(new PillActions(this.pillChip, this.selected));
        // Always prevent space from scrolling the page since the list has focus
        event.preventDefault();
        break;
    }
  }
}
