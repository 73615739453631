import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EventHandler } from "../../../../../common/services/eventHandler";
import { AppSession } from "../../../../../common/values/appSession";
import { IProviderList } from "../../../../result/interfaces/iProvider";
import { BaseComponent } from "../../core/baseCmp";


@Component({
  moduleId: module.id,
  selector: 'app-out-of-network-pricing-tab-cmp',
  templateUrl: './outOfNetworkPricingTabCmp.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutOfNetworkPricingComponent extends BaseComponent {
  @Input() cptSearchResult: IProviderList;

  constructor(
    route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession) {
    super(route, eventHandler, appSession);
  }

  print() {
    window.print();
  }

}
