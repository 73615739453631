import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { FlyOutBoxComponent } from './flyOutBoxCmp';
import { LookUpContainerCmpComponent } from './lookUpContainerCmp';

@NgModule({
  declarations: [FlyOutBoxComponent, LookUpContainerCmpComponent],
  imports: [
    CommonModule,
    UxSharedModule,
    FormsModule,
  ],
  exports: [
    FlyOutBoxComponent,
    LookUpContainerCmpComponent
  ]
})
export class ActionableHeaderModule { }
