import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeedbackSidePanelModule } from '../feedback-sidepanel/feedbackSidePanelModule';
import { ActionItemCmp } from './components/actionItemCmp';
import { ActionItemLookupComponent } from './components/actionItemLookupCmp';
import { ActionItemLookupDirective } from './directives/actionItemLookupDirective';

@NgModule({
  declarations: [ActionItemCmp, ActionItemLookupDirective, ActionItemLookupComponent],
  exports: [ActionItemCmp, ActionItemLookupDirective, ActionItemLookupComponent],
  imports: [CommonModule, FeedbackSidePanelModule]
})
export class ActionItemModule {}
