import { Injectable } from '@angular/core';
import { HttpMethod } from '../../common/enums/httpMethodEnum';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { apiNameList } from './../../../environments/api-name-list';
import { BaseService } from './../services/baseService';
import { HttpClientService } from './httpClientService';

@Injectable()
export class DisclaimerService extends BaseService {
  private disclaimerPromise: Promise<any>;

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  loadDisclaimers(reqObj: any): Promise<any> {
    return this.httpClientSvc
      .request({
        cancellable: true,
        method: HttpMethod.Post,
        url: this.baseURL + apiNameList.restApi.disclaimers,
        data: reqObj,
        headers: this.getHeaders()
      });
  }
}
