import { Injectable } from "@angular/core";
import { IFinalSearchResponse } from "../../../common/interfaces/iCommonSearchResponse";
import { ISecureSearchStrategyResponse } from '../../../common/interfaces/iSecureSpecialtySearchResponse';
import { ISummaryAPIResp } from './../interfaces/iSummaryResp';
import { SortModel } from "./sortModel";

@Injectable()
export class SummaryModel {
  private _sumResp: ISummaryAPIResp | ISecureSearchStrategyResponse | IFinalSearchResponse;

  constructor(private _sortModel: SortModel) {
  }

  set summaryResponse(res: ISummaryAPIResp | ISecureSearchStrategyResponse | IFinalSearchResponse) {
    //validate the incoming data
    if (!res) {
      return;
    }
    this._sumResp = res;
    //Initialize SortModel
    this._sortModel.init(res.sortOptions, res.defaultSort ?? res.sortBy);
  }
  get summaryResponse(): ISummaryAPIResp | ISecureSearchStrategyResponse | IFinalSearchResponse {
    return this._sumResp;
  }
}
