export interface RedirectUrlResponse {
  magicLink?: string;
  sentMemberInfoToKHealth?: boolean;
  additionalParams?: RedirectURLSSOResponse,
  samlAssertion?: string;
  vendorUrl?: string;
}
export interface RedirectURLSSOResponse {
  RelayState?: string;
  destination_type?: string;
  destination_data?: string;
}
export interface MagicLinkRequest {
  sentMemberInfoToKHealth: boolean;
  digitalData?: any;
  destination?: {
    type: VirtualCareEnums;
    data: string;
  };
  idToken?: string;
  isNewKHealthSSO?: boolean;
}

export enum VirtualCareEnums {
  SYMPTOMCHECKER = "symptomchecker",
  MYCARE = "my-care",
  SYMPTOMSEARCH = "symptom-search",
  CHAT = "chat",
  PAGE = "page",
  PRIMARYCARE = "primary_care",
}
