export class LocationResponse {
  lat: string;
  long: string;
  name: string;
  city: string;
  stateCd: string;
  county: string;
}

export class AddressByLatLongResponse {
  zipcode: string;
  formattedAddress: string;
}

export class AddressByQueryResponse {
  latitude: string;
  longitude: string;
}
export class Coordinates {
  name?: string;
  stateCd?: string;
}
export class LocationDetails {
  name?: string;
  lat?: string;
  long?: string;
  city?: string;
  stateCd?: string;
  county?: string;
}
export class ILocationDetails {
  key?: string;
  value?: LocationDetails
}

export interface ICountyLookupApiRequest {
  searchTerm: string;
  locale: string;
}
export interface ICountyLookupApiResp  {
  counties?: ICounty[];
}

export interface ICounty {
  countyCode?: string;
  countyName?: string;
  stateCode?: string;
  stateName?: string;
  fipsStateCode?: string;
  stateCountyCode?: string;
}

export interface ILocationDropDown {
  label: string;
  value: string;
}

export interface IDrivingModal {
  id: string;
  title?: string;
  label?: string;
  name?: string;
  value?: boolean,
}

export interface IGeoTravelTime {
  travelDuration: ITravelDuration[];
}

export interface ITravelDuration {
  latitude: number;
  longitude: number;
  travelTime: number;
}
