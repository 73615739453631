export enum ResultActionType {
  Print = 'Print',
  Email = 'Email',
  ContactUs = 'ContactUs',
  HpnSearch = 'HpnSearch',
  None = 'None'
}

export class ResultActions {
  get getType(): ResultActionType {
    return ResultActionType.None;
  }
}

export class Print extends ResultActions {
  get getType(): ResultActionType {
    return ResultActionType.Print;
  }
}

export class Email extends ResultActions {
  get getType(): ResultActionType {
    return ResultActionType.Email;
  }
}

export class ContactUs extends ResultActions {
  get getType(): ResultActionType {
    return ResultActionType.ContactUs;
  }
}

export class HpnSearch extends ResultActions {
  get getType(): ResultActionType {
    return ResultActionType.HpnSearch;
  }
  navigationType: string;
}
