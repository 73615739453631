import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { TabbedViewComponent } from '@anthem/uxd/tabs';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IDeeplink } from '../../../../common/components/app-secure/models/iDeeplink';
import { IMetadata } from '../../../../common/components/app-secure/models/iMetadata';
import { SecureStateHandler } from '../../../../common/components/app-secure/services/secureStateHandler';
import { ScenarioAlertSvc } from '../../../../common/components/scenarioAlert/services/pfScenarioAlertSvc';
import { AppConstants, AppExternalCommands, BRANDCODE, Locale } from '../../../../common/constants/app-constants';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IAdobe, IAdobePlanDetail } from '../../../../common/interfaces/iAdobe';
import { IBaseCode } from '../../../../common/interfaces/iBaseCode';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { IWindow } from '../../../../common/interfaces/iWindow';
import { DataHelper } from '../../../../common/services/dataHelper';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AbstractSearchStrategy } from '../../../../common/services/strategySvc';
import { AppUtility } from '../../../../common/utilities/appUtil';
import { AppSession } from '../../../../common/values/appSession';
import { IRulesResponse } from '../../../../fad/rules/interfaces/iRulesResponse';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { DropDownItem } from './../../../../common/models/dropDownItem';

import { RulesService } from '../../../../fad/rules/services/RulesSvc';
import { IAddressDetails } from '../../../../fad/search-providers/interfaces/iAddressForm';
import { IGenericDataResponse } from '../../../../fad/search-providers/interfaces/iGenericDataResponse';
import { IPlan, IPlanCategory } from '../../../../fad/search-providers/interfaces/iPlan';
import { ICobrandRule } from '../../../../fad/search-providers/interfaces/iPlanRule';
import { IPublicPlan } from '../../../../fad/search-providers/interfaces/iPublicPlan';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { ITranslation } from '../../../../fad/search-providers/interfaces/iTranslation';
import { ProviderUtilityService } from '../../../../fad/search-providers/services/providerUtilitySvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE_CARE, PROVIDER_TYPE_OF_CARE, SPANISH_PROVIDER_TYPE_CARE, UNIMASS_BRAND } from '../../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { FindCareFeature } from '../../../common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../../common/utilities/featureManagerUtil';
import { SearchEventType } from '../../constants/home';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fc-plan-selection-cmp',
  templateUrl: './planSelectionCmp.html',
  providers: []
})
export class PlanSelectionComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  gotoSearchCriteria: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  showSearchLoader = true;
  typeCareDropDown = PROVIDER_TYPE_CARE;
  brand = '';
  domain = '';
  href = '';
  errorText: string;
  planChanged = false;
  alphaPrefix = '';
  searchStrategy: AbstractSearchStrategy | undefined;
  alphaPrefixError = false;
  metaData = {} as IMetadata;
  metadataError = false;
  careDisplay = '';
  selectedCareError = false;
  stateDisplay = '';
  selectedStateError = false;
  categoryDisplay = '';
  selectedCategoryError = false;
  planDisplay = '';
  selectedPlanError = false;
  locale: Locale;
  searchType: string = 'plan';
  showEBCBlock = false;
  @ViewChild('landingTabCtrl')
  landingTabCtrl: TabbedViewComponent;
  @ViewChild('planSelectionSidePanel')
  planSelectionSidePanel: TemplateRef<HTMLElement>;
  stateDisabled = true;
  planDisabled = true;
  networkDisabled = true;
  continueDisabled = true;
  showCaptcha = false;
  googleCaptchaFeature = false;
  searchEventType = SearchEventType;
  protected readonly environment = environment;

  radioObjectPlan = {
    label: 'Search by Plan',
    id: 'searchByPlan',
    name: 'searchOption',
    value: 'plan'
  };
  radioObjectID = {
    label: 'Search by ID Number',
    id: 'searchIDNumber',
    name: 'searchOption',
    value: 'idNumber',
    analyticsText: ''
  };

  /* FTB-276: cleaned up radioMedicaid and labels constants declarations */

  locateRCPLoginUrl = '';
  locateRCPRegisterUrl = '';
  landingPage: string;
  unimassBrand: boolean;
  sidePanelRef: ModalRef<HTMLElement>;
  readonly BRANDCODE = BRANDCODE;

  private _onPfMemberSummaryErrorSub: Subscription;
  private _pfMemberSummaryErrorEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.MEMBER_SUMMARY_ERR]);
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  get isIntegratedMode() {
    return this._appSession.isIntegratedMode;
  }

  get showMemberTab() {
    return !this._appSession.isPlanUnknown;
  }

  get showLoginSection(): boolean {
    return this._appSession.isPlanUnknown !== true;
  }

  constructor(
    private _providerUtilityService: ProviderUtilityService,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _route: ActivatedRoute,
    private _secureStateHandler: SecureStateHandler,
    private _eventHandler: EventHandler,
    private _appUtility: AppUtility,
    private _ruleService: RulesService,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _sidePanel: SidePanel,
    private _scenarioAlertSvc: ScenarioAlertSvc
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appSession.isHcidSearch = false;
    this._appSession.mbu = '';
    this._appSession.underwritingStateCode = '';
    this.googleCaptchaFeature = FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_GOOGLE_RECAPTCHA, this._appSession);
    if (this._appSession.changePlanMetaData && this._appSession.changePlanMetaData?.identificationNumber) {
      this._appSession.changePlanMetaData.identificationNumber = '';
    }
    if (this._appSession.searchParams && this._appSession.searchParams?.identificationNumber) {
      this._appSession.searchParams.identificationNumber = '';
    }
    this._appUtility.scrollToTop();
    this.radioObjectPlan.label = this.content.home.planSelection.labels.searchTogglePlan;
    this.radioObjectID.label = this.content.home.planSelection.labels.searchToggleId;
    this.brand = this._appSession.metaData.integratedBrandCode || this._appSession.metaData.brandCd;
    this.unimassBrand = this.brand.toLowerCase() === UNIMASS_BRAND;
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale : Locale.ENGLISH;
    if (this._appSession.isDeepLink) {
      this.validateDeeplink();
    } else if (this.googleCaptchaFeature && this._appSession?.deeplinkParams?.hcid) {
      this.alphaPrefix = this._appSession.deeplinkParams.hcid;
      this.showCaptcha = true;
    } else if (!this.googleCaptchaFeature && this._appSession?.deeplinkParams?.hcid) {
      this.validateHcid(this._appSession.deeplinkParams.hcid);
    } else {
      this.showSearchLoader = false;
      this.initializeLabels();
      this.setDefault();
      if (this.isMemberSecure && this._appSession.isPlanUnknown === true) {
        if (this._appSession && this._appSession.searchParams && this._appSession.searchParams.typeOfCareNm) {
          this.getStateDropdown(this._appSession.searchParams.typeOfCareNm);
        }
      }
    }
    this.handleHcidError();
    this.getRCPLoginAndRegisterUrl();
    this.getBrandEBCBlock();
    //When cobrandRule exists, remove it.
    if (this._appSession.planRule?.cobrandRule) {
      this.removeCobrandRule();
    }
    this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]).emit(this.brand);
    let supportedBrandLabel = this.contentUtility.getSupportedBrandLabel(this.brand, this.content);
    if (!this._dataHelper.isEmptyString(supportedBrandLabel)) {
      this.content.home.planSelection.labels = this.contentUtility.overrideContent(this.content.home.planSelection.labels, this.content[supportedBrandLabel]);
    }
  }

  /**
   * Initializes the labels for the plan selection component based on the locale.
   */
  initializeLabels() {
    if (this._appSession.metaData.locale === Locale.SPANISH) {
      this.typeCareDropDown = SPANISH_PROVIDER_TYPE_CARE;
    }
    const labels = this.content.home.planSelection.labels;
    this.careDisplay = labels.defaultCareText;
    this.stateDisplay = labels.defaultStateText;
    this.categoryDisplay = labels.defaultPlanCategoryText;
    this.planDisplay = labels.defaultPlanOrText;
  }

  onGuestSearchClick() {
    this.alphaPrefixError = false;
    this.errorText = '';
    this.showCaptcha = false;
    this.openPlanSearchPanel();
  }

  /**
   * Checks if the current brand is WLP (WellPoint).
   * @returns {boolean} True if the brand is WLP, otherwise false.
   */
  isWLPBrand(): boolean {
    return this.brand === BRANDCODE.WLP;
  }

  ngAfterViewInit() {
    if (this.searchParams?.stateDdlValues?.length) {
      this.stateDisabled = false;
      this.planDisabled = false;
      this.networkDisabled = false;
      this.continueDisabled = false;
    }
    setTimeout(() => {
      if (this.showMemberTab && this.landingTabCtrl) {
        this.landingTabCtrl.activeTab = this._appSession.landingTab;
      }
    });
  }

  setDefault() {
    if (this.searchParams.typeOfCareNm && this.searchParams.typeOfCareNm.length > 0) {
      this.typeCareDropDown.options.forEach((data) => {
        if (this.searchParams.typeOfCareNm.toLowerCase() === data.value) {
          this.careDisplay = data.label;
        }
      });
    }

    if (this.searchParams.stateDdlValues && this.searchParams.stateDdlValues.length > 0) {
      this.searchParams.stateDdlValues.forEach((data: DropDownItem) => {
        if (this.searchParams.plan && this.searchParams.plan.stateCd === data.value.trim()) {
          this.stateDisplay = data.label.trim();
        }
      });
    }

    if (this.searchParams.planCategoryDdlValues && this.searchParams.planCategoryDdlValues.length > 0) {
      this.searchParams.planCategoryDdlValues.forEach((data: DropDownItem) => {
        if (!this._dataHelper.isEmptyString(this.searchParams.plan.category.catCode) && this.searchParams.plan.category.catCode === data.value.trim()) {
          this.categoryDisplay = data.label.trim();
        }
      });
    }

    if (this.searchParams.planDdlValues && this.searchParams.planDdlValues.length > 0) {
      this.searchParams.planDdlValues.forEach((data: DropDownItem) => {
        if (this.searchParams.plan && this.searchParams.plan.name + '__' + this.searchParams.plan.identifier === data.value) {
          this.planDisplay = data.label;
        }
      });
    }
  }

  /**
   * Handles the navigation to the search criteria based on the event type.
   * @param eventType - The type of search event.
   */
  goToSearchCriteria(eventType: SearchEventType) {
    this._appSession.backToFindCareClicked = false;

    // Initialize address object if no addresses are available in metaData
    if (!this._appSession?.metaData?.addresses?.length) {
      this._appSession.addressObj = {} as IAddressDetails;
    }

    // Handle the event when the prefix is provided
    if (eventType === SearchEventType.PREFIX) {
      this.handlePrefixEvent();
    } else {
      this.handlePlanSearchEvent();
    }
  }

  getStateDropdown(careType: string) {
    if (careType !== '') {
      this.formReset();
      this.searchParams.typeOfCareNm = careType;
      this.getStateList();
      this.setDefault();
    }
  }

  getStateList() {
    this.showSearchLoader = true;
    this._providerUtilityService.getStateList(this.getBrand(), this.searchParams.typeOfCareNm).then(
      (result) => {
        if (result?.data?.length) {
          this.buildStateDropDown(result.data);
          this.stateDisabled = false;
        } else {
          this.stateDisabled = true;
        }
        this.planDisabled = true;
        this.networkDisabled = true;
        this.continueDisabled = true;
        this.showSearchLoader = false;
      },
      (error: unknown) => {
        this.showSearchLoader = false;
        this.showApiError(error);
      }
    );
  }

  getPlanCategoryDropDown(state: string) {
    if (state !== '') {
      this.selectedStateError = false;
      this.resetCategoryDD();
      this.resetPlanDD();
      const _state = state.trim();
      const _care = this.searchParams.typeOfCareNm;
      this.showSearchLoader = true;
      this.searchParams.plan.stateCd = _state;
      this.setDefault();
      this._providerUtilityService.getPlanCategoryList(_state, this.getBrand(), _care).then(
        (result: IGenericDataResponse<Array<ITranslation>>) => {
          if (result && result.data) {
            this.buildPlanCategoryDropDown(result.data);
            this.planDisabled = false;
            this.networkDisabled = true;
            this.continueDisabled = true;
          }
          this.showSearchLoader = false;
        },
        (error: unknown) => {
          this.showSearchLoader = false;
          this.showApiError(error);
        }
      );
    }
  }

  getPlanDropDown(category: string) {
    if (category !== '') {
      this.selectedCategoryError = false;
      let _categoryList: DropDownItem[];
      let _planCategory: DropDownItem;
      const _care = this.searchParams.typeOfCareNm;
      const _state = this.searchParams.plan && this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd : '';
      const _category = category.trim();
      this.showSearchLoader = true;
      this.resetPlanDD();
      _categoryList = this.searchParams.planCategoryDdlValues;
      _planCategory = _categoryList.filter((c) => {
        return c.value === _category;
      })[0];
      if (_planCategory) {
        this.searchParams.plan.category = {
          catCode: _planCategory.value,
          catName: _planCategory.label
        };
      }
      this.setDefault();
      this._providerUtilityService.getPlanList(_state, this.getBrand(), _care, category).then(
        (result: IGenericDataResponse<Array<IPublicPlan>>) => {
          this.buildPlanDropDown(result.data);
          this.networkDisabled = false;
          this.continueDisabled = this.planDisplay === this.content.home.planSelection.labels.defaultPlanOrText;
          this.showSearchLoader = false;
        },
        (error: unknown) => {
          this.showSearchLoader = false;
          this.showApiError(error);
        }
      );
    }
  }

  buildPlanCategoryDropDown(planCategoryList: Array<ITranslation>) {
    planCategoryList.forEach((planCategory: ITranslation) => {
      this.searchParams.planCategoryDdlValues.push({
        label: this.locale === Locale.SPANISH ? this.contentUtility.getSpanishPlanCategoryName(planCategory.code, planCategory.name) : planCategory.name,
        value: planCategory.code
      });
    });

    // Pre-select the plan category if the plan category dropdown having only single item.
    if (CommonUtil.isEmpty(this.searchParams.plan?.category?.catCode) && this.searchParams.planCategoryDdlValues.length === 1) {
      this.categoryDisplay = this.searchParams.planCategoryDdlValues[0].label.trim();
      // Bind Plan options.
      this.getPlanDropDown(this.searchParams.planCategoryDdlValues[0].value.trim());
    }
  }

  buildPlanDropDown(planList: Array<IPublicPlan>) {
    planList.forEach((plan: IPublicPlan) => {
      this.searchParams.planDdlValues.push({
        label: plan.name,
        value: plan.name + '__' + plan.id,
        networklist: plan.networkList,
        nationalPlanIndicator: plan.isNationalPlan,
        urlRedirect: plan.urlRedirect,
        alphaPrefix: plan.prefix,
        planCategoryList: plan.planCategoryList && plan.planCategoryList.length > 0 ? plan.planCategoryList : []
      });
    });

    // Pre-select the plan if the plan dropdown having only single item.
    if (CommonUtil.isEmpty(this.searchParams.plan?.identifier) && this.searchParams.planDdlValues.length === 1) {
      this.planDisplay = this.searchParams.planDdlValues[0].label.trim();
      // Bind selected Plan.
      this.setPlanDetails(this.searchParams.planDdlValues[0].value.trim());
    }
  }

  buildStateDropDown(result: Array<IBaseCode>) {
    result.forEach((state: IBaseCode) => {
      this.searchParams.stateDdlValues.push({ label: state.name, value: state.code });
    });

    // Pre-select the state if the state dropdown having only single item.
    if (CommonUtil.isEmpty(this.searchParams.plan?.stateCd) && this.searchParams.stateDdlValues.length === 1) {
      this.stateDisplay = this.searchParams.stateDdlValues[0].label.trim();
      // Bind Plan category options.
      this.getPlanCategoryDropDown(this.searchParams.stateDdlValues[0].value.trim());
    }
  }

  formReset() {
    this.alphaPrefix = '';
    this.resetSearchParams();
    this.resetCareTypeDD();
    this.resetStateDD();
    this.resetCategoryDD();
    this.resetPlanDD();
    this._appSession.isDentalStandAlone = false;
    this._appSession.isVisionStandAlone = false;
    this._appSession.isPharmacyStandAlone = false;
  }

  resetCareTypeDD() {
    this.selectedCareError = false;
    this.careDisplay = this.content.home.planSelection.labels.defaultCareText;
    this.searchParams.typeOfCareNm = '';
    this.searchParams.plan = {};
  }

  resetStateDD() {
    this.selectedStateError = false;
    this.stateDisplay = this.content.home.planSelection.labels.defaultStateText;
    this.searchParams.stateDdlValues = [];
    this.searchParams.plan = {};
  }

  resetCategoryDD() {
    this.selectedCategoryError = false;
    this.categoryDisplay = this.content.home.planSelection.labels.defaultPlanCategoryText;
    this.searchParams.planCategoryDdlValues = [];

    const _state = this.searchParams.plan && this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd : '';
    this.searchParams.plan = {};
    if (_state !== '') {
      this.searchParams.plan.stateCd = _state;
    }
  }

  /**
   * Retrieves the Unicare Massachusetts help text content with the appropriate brand URL and link text.
   * @returns {string} The formatted Unicare Massachusetts help text content.
   */
  getUnimassContent(): string {
    const { unicareMassHelpText } = this.content.home.planSelection.labels;
    const brand = (this.brand || '').toLowerCase();
    const brandUrl = unicareMassHelpText.brandUrls[brand];
    const { msgText, linkText, msgText2 } = unicareMassHelpText;

    // Format the help text content with the brand URL and link text
    return `${msgText}<a target='_blank' class='landing-link no-underline' href='${brandUrl}'>${linkText}</a>${msgText2}`;
  }

  /**
   * Resets the plan dropdown and related search parameters.
   */
  resetPlanDD() {
    this.selectedPlanError = false;
    this.planDisplay = this.content.home.planSelection.labels.defaultPlanOrText;
    this.searchParams.planDdlValues = [];
    const currentState = this.searchParams.plan?.stateCd;
    const currentCategory = this.searchParams.plan?.category;

    this.searchParams.plan = {};
    if (currentState) {
      this.searchParams.plan.stateCd = currentState;
    }
    if (currentCategory?.catCode) {
      this.searchParams.plan.category = currentCategory;
    }
  }

  resetSearchParams() {
    this.searchParams.stateCd = '';
    this.searchParams.provNm = '';
    this.searchParams.zip = '';
    this.searchParams.typeSelectNm = '';
    this.searchParams.providerTypeCodeList = [];
    this.searchParams.specialtySelectNm = [];
    this.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this.searchParams.city = '';
  }

  showApiError(error: unknown) {
    //to do
    throw error;
  }

  /**
   * Sets the plan details based on the provided plan details string.
   *
   * @param planDetails - The plan details string in the format "planName__planIdentifier".
   */
  setPlanDetails(planDetails: string) {
    // Initialize variables
    let planNetworkList: any[] = [];
    let nationalPlanIndicator = false;
    let url = '';
    let prefix = '';
    let planCategory: IPlanCategory = null;
    this.planChanged = true;
    const currentCategoryCode = this.searchParams.plan?.category?.catCode || '';
    this.selectedPlanError = false;

    // Check if plan details are provided
    if (planDetails) {
      // Check if plan dropdown values are available
      if (this.searchParams.planDdlValues && this.searchParams.planDdlValues.length > 0) {
        const networkArray = this.searchParams.planDdlValues;
        const plan = networkArray.find((c: DropDownItem) => c.value === planDetails);

        // If the plan is found, extract its details
        if (plan) {
          url = plan.urlRedirect?.trim() || '';
          prefix = plan.alphaPrefix?.trim() || '';
          if (plan.planCategoryList && plan.planCategoryList.length > 0) {
            planCategory = {
              catCode: currentCategoryCode,
              catName: this.searchParams.plan?.category?.catName || '',
              productPlanTypeCode: plan.planCategoryList[0].productPlanTypeCode,
              productTypeList: plan.planCategoryList
            };
          }
          nationalPlanIndicator = plan.nationalPlanIndicator;
          if (plan.networklist && plan.networklist.length > 0) {
            planNetworkList = plan.networklist;
          }
        }
      }

      // Reset the selected plan error flag
      this.selectedPlanError = false;

      // Split the plan details string to extract plan name and identifier
      const planInformation = planDetails.split('__');
      const selectedPlan: IPlan = {
        identifier: planInformation[1],
        name: planInformation[0],
        isNationalPlan: nationalPlanIndicator,
        stateCd: this.searchParams.plan?.stateCd || '',
        networkList: planNetworkList,
        urlRedirect: url,
        careType: this.searchParams.typeOfCareNm,
        alphaPrefix: prefix,
        category: planCategory
      };

      // Update the search parameters with the selected plan
      this.searchParams.plan = selectedPlan;
      this._appSession.networkList = planNetworkList;

      // Clear deeplink parameters if client is not present
      if (this._appSession.deeplinkParams && !this._appSession.deeplinkParams.client) {
        this._appSession.deeplinkParams = {} as IDeeplink;
      }

      // Enable the continue button
      this.continueDisabled = false;
    }

    // Set default values for the search parameters
    this.setDefault();
  }

  /**
   * Validates the deeplink parameters and performs the appropriate actions based on the plan state or alpha prefix.
   */
  validateDeeplink() {
    // Check if the deeplink parameters contain a plan state
    if (this._appSession.deeplinkParams.planstate) {
      this.handlePlanStateDeeplink();
    } else if (this._appSession.deeplinkParams.alphaprefix) {
      // Validate the alpha prefix if present in the deeplink parameters
      this.validateAlphaPrefix(this._appSession.deeplinkParams.alphaprefix);
    }
  }

  /**
   * Sets the search parameters from the deeplink parameters in the session.
   */
  setSearchParamsfromDeeplink() {
    const deeplinkParams = this._appSession.deeplinkParams;

    // Check if the deeplink parameter for PCP only is set to 'y'
    if (deeplinkParams.pcponly && deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      this.searchParams.ableToServePcp = true;
    }

    // Check if the deeplink parameter for provider type is present
    if (deeplinkParams.provtype) {
      // Split the provider type string by '|' and convert each type to uppercase
      this._appSession.searchParams.providerTypeCodeList = deeplinkParams.provtype.split('|').map((type) => type.toLocaleUpperCase());
    }

    // Check if the deeplink parameter for ZIP code is present
    if (deeplinkParams.zipcode) {
      this.searchParams.zip = deeplinkParams.zipcode;
    }
  }

  resetDeeplink() {
    this._appSession.isDeepLink = false;
    this._appSession.deeplinkParams = {} as IDeeplink;
  }

  /**
   * Validates the provided alpha prefix and updates the search parameters accordingly.
   * @param alphaprefix - The alpha prefix to validate.
   */
  validateAlphaPrefix(alphaprefix: string) {
    this._appSession.deeplinkParams.alphaprefix = alphaprefix;

    this._providerUtilityService.getAlphaPrefix(alphaprefix, Locale.ENGLISH).then(
      (result: any) => {
        if (result && result.codeTypeList && result.codeTypeList.length > 0) {
          const networkCode = result.codeTypeList[0].code;
          const networkName = result.codeTypeList[0].name;

          const selectedPlan: IPlan = {
            identifier: '',
            name: networkName || networkCode,
            isNationalPlan: null,
            stateCd: '',
            networkList: [],
            alphaPrefix: alphaprefix
          };

          this._appSession.networkList = [];
          this.searchParams.plan = selectedPlan;

          // Set search parameters from deeplink if applicable
          if (this._appSession.isDeepLink) {
            this.setSearchParamsfromDeeplink();
          }

          this.alphaPrefixError = false;
          this.errorText = '';
          this._appSession.showPrefixPopup = true;
          this._appSession.searchParams = this.searchParams;

          // Fetch rules and scenario-based alerts
          this.getRules();
          this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));

          // Emit the search criteria event
          this.gotoSearchCriteria.emit();
        } else {
          // Handle the case where the alpha prefix is not found
          this.handleAlphaPrefixError(this.content.home.planSelection.alerts.hcidError);
        }
      },
      (error: unknown) => {
        // Handle API error
        this.handleAlphaPrefixError(this.content.home.planSelection.alerts.idOrPrefix, error);
      }
    );
  }

  /**
   * Validates the plan result and updates the search parameters accordingly.
   * @param result - The generic data response containing an array of public plans.
   * @param planResult - The index of the plan result to validate.
   */
  validatePlanResult(result: IGenericDataResponse<Array<IPublicPlan>>, planResult: number) {
    let url = '';
    let prefix = '';
    let planCategory: IPlanCategory;

    // Check if the result data is valid and matches the deeplink plan type
    if (this.isValidPlanResult(result, planResult)) {
      const plan = result.data[planResult];
      this.searchParams.typeOfCareNm = plan.careType.trim().toUpperCase();

      // Extract plan details
      url = this.getTrimmedValue(plan.urlRedirect);
      prefix = this.getTrimmedValue(plan.prefix);
      planCategory = this.getPlanCategory(plan);
      const nationalPlanIndicator = plan.isNationalPlan;
      const planNetworkList = plan.networkList || [];

      // Create the selected plan object
      const selectedPlan: IPlan = {
        identifier: plan.id,
        name: plan.name,
        isNationalPlan: nationalPlanIndicator,
        stateCd: this._appSession.deeplinkParams.planstate.trim(),
        networkList: planNetworkList,
        urlRedirect: url,
        careType: this.searchParams.typeOfCareNm,
        alphaPrefix: prefix,
        category: planCategory
      };

      // Update search parameters
      this.searchParams.plan = selectedPlan;
      if (this._appSession.isDeepLink) {
        this.setSearchParamsfromDeeplink();
      }
      this.setStandAloneFlag(this.searchParams.plan.careType);
      this.gotoSearchCriteria.emit();
    } else {
      this.showSearchLoader = false;
    }
  }

  /**
   * Validates the HCID (Health Care Identification) number and updates the session metadata accordingly.
   * @param identificationNumber - The HCID number to validate.
   */
  validateHcid(identificationNumber: string) {
    // Retrieve the brand code from the session metadata or use 'ABCBS' as the default
    const brandCd = this._appSession?.metaData?.brandCd || BRANDCODE.ABCBS;

    // Reset error text and alpha prefix error flag
    this.errorText = '';
    this.alphaPrefixError = false;

    // Update the metadata with the provided identification number and other properties
    this.metaData = {
      ...this.metaData,
      dplUrl: this._appSession.deeplinkParams.deeplinkUrl,
      hcid: identificationNumber,
      brand: brandCd,
      isCastlightTransparency: false,
      isEmulateFlag: false,
      isSecureState: false
    };

    // Update the session properties
    this._appSession.isSecureState = false;
    this._secureStateHandler.pfEmMode = false;
    this._appSession.isIdentifactionSearch = true;
    this._appSession.isFirstTimeLoad = false;
    this._appSession.providerTypes = [];
    this._appSession.networkList = [];

    // Update the captcha token if the Google Captcha feature is enabled
    if (this.googleCaptchaFeature) {
      (this.metaData as any).siteKey = this._appSession.captchaToken;
    }

    // Initialize the secure state with the updated metadata
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  handleHcidError() {
    if (!this.isMemberSecure) {
      this._onPfMemberSummaryErrorSub = this._pfMemberSummaryErrorEvent.subscribe((detail: IEventDetail) => {
        this.errorText = detail.message;
        this.alphaPrefixError = true;
        this.showSearchLoader = false;
      });
    }
  }

  /**
   * Retrieves the image path for the ID card based on the brand code in the session metadata.
   * @returns {string} The URL of the ID card image.
   */
  getIDCardImagePath(): string {
    let imageName = '';

    if (this._appSession.metaData?.brandCd) {
      // Find the index of the brand in the brand constants array
      const index = AppConstants.BRAND.findIndex((x) => x.brand === this._appSession.metaData.brandCd.toLocaleUpperCase());

      // If the brand is found, retrieve the ID card image name
      if (index >= 0) {
        imageName = AppConstants.BRAND[index].idCardImg;
      }
    }

    // Return the full URL of the ID card image
    return this.getCommonImageURL(imageName);
  }

  onCancelButtonClick() {
    this.formReset();

    if (this._appSession.isSecureState === false) {
      const eventDetail = {} as IEventDetail;

      eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
      eventDetail.message = AppExternalCommands.RCP_PF_LANDING.MSG;
      eventDetail.target = AppExternalCommands.RCP_PF_LANDING.CMD;

      this._pfAppCommandEvent.emit(eventDetail);
    }
  }

  //Alpha prefix search on press enterkey events
  onAlphaPrefixKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.goToSearchCriteria(SearchEventType.PREFIX);
    }
    this.showCaptcha = this.alphaPrefix?.length > 4;
  }

  //Sets the Analytics query for plan by search - Only on RCP integrated page
  setPlanDetailsAnalyticsQuery() {
    if (window.digitalData && window.digitalData.page && window.digitalData.page.search) {
      const _adobePlan = {} as IAdobe;
      const _planDetails = {} as IAdobePlanDetail;
      _planDetails.careType = this.searchParams.typeOfCareNm;
      _planDetails.category = this.categoryDisplay;
      _planDetails.planName = this.searchParams.plan.name;
      _planDetails.state = this.searchParams.plan.stateCd;
      _adobePlan.planDetail = _planDetails;
      window.digitalData.page.search.planDetailsSearchQuery = CommonUtil.getPlanDetailsAnalyticsQuery(_adobePlan);
    }
  }

  /**
   * Sets the standalone flag in the session based on the provided care type.
   * @param careType - The type of care to set the standalone flag for.
   */
  setStandAloneFlag(careType: string) {
    const _careType = careType ? careType.toUpperCase().trim() : '';

    // Reset all standalone flags to false
    this._appSession.isDentalStandAlone = false;
    this._appSession.isVisionStandAlone = false;
    this._appSession.isPharmacyStandAlone = false;

    // Set the appropriate standalone flag based on the care type
    switch (_careType) {
      case PROVIDER_TYPE_OF_CARE.DENTAL:
        this._appSession.isDentalStandAlone = true;
        break;
      case PROVIDER_TYPE_OF_CARE.VISION:
        this._appSession.isVisionStandAlone = true;
        break;
      case PROVIDER_TYPE_OF_CARE.PHARMACY:
        this._appSession.isPharmacyStandAlone = true;
        break;
    }
  }

  goToRCPLogin(url: string) {
    if (typeof url !== 'undefined' && url !== null) {
      this._window.open(url, '_self');
    }
  }

  /**
   * Retrieves the RCP (Remote Care Platform) login and register URLs based on the domain and brand.
   */
  getRCPLoginAndRegisterUrl() {
    const domain = 'anthembluecross';
    const hasMatchingDomain = this.hasMatchingDomain(domain);

    if (hasMatchingDomain) {
      this.locateRCPLoginUrl = environment.login[this.locale].brand[domain];
      this.locateRCPRegisterUrl = environment.register[this.locale].brand[domain];
    } else {
      const brandLowerCase = this.brand.toLowerCase();
      this.locateRCPLoginUrl = environment.login[this.locale].brand[brandLowerCase];
      this.locateRCPRegisterUrl = environment.register[this.locale].brand[brandLowerCase];
    }
  }

  hasMatchingDomain(domain: string): boolean {
    this.href = window.location.href.toLowerCase();
    return this.href.indexOf(domain) >= 0;
  }

  getBrandEBCBlock() {
    const index = AppConstants.BRAND.findIndex((x) => x.brand === this._appSession.metaData.brandCd.toLocaleUpperCase());
    if (index >= 0) {
      this.showEBCBlock = AppConstants.BRAND[index].showSixStateMedicaid;
      /* FTB-276: cleaned up radioMedicaid options code */
    }
  }

  /**
   * Retrieves the rules based on the current search parameters and updates the session with the retrieved rules.
   */
  getRules() {
    this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
      (data: IRulesResponse) => {
        if (data) {
          this._appSession.feature = data;
        }
      },
      (error: unknown) => {
        throw error;
      }
    );
  }

  emitCobrandEvent() {
    //Emit PF_APP_COMMAND event to notify the Consumer Public page to refresh the logo with cobrand
    const eventDetail = {} as IEventDetail;
    const cobrandRule: ICobrandRule = this._appSession.planRule.cobrandRule;
    eventDetail.target = cobrandRule.target;
    eventDetail.type = cobrandRule.type;
    eventDetail.message = cobrandRule.message;
    eventDetail.object = cobrandRule.object;
    this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]).emit(eventDetail);
  }

  closePlanSearchPanel() {
    this.sidePanelRef.close();
  }

  openPlanSearchPanel() {
    // open side panel - plan selection section
    this.sidePanelRef = this._sidePanel.open('right', this.planSelectionSidePanel);
  }

  resolvedCaptcha($event: string) {
    if ($event) {
      this._appSession.captchaToken = $event;
    } else {
      this._appSession.captchaToken = '';
    }
  }

  ngOnDestroy() {
    if (this._onPfMemberSummaryErrorSub) {
      this._onPfMemberSummaryErrorSub.unsubscribe();
    }
  }

  /**
   * Handles the event when the prefix is provided.
   */
  private handlePrefixEvent() {
    if (this.alphaPrefix) {
      this.showSearchLoader = true;
      if (this.alphaPrefix.length <= 4) {
        this.validateAlphaPrefix(this.alphaPrefix);
      } else {
        if (!(this.googleCaptchaFeature && !this._appSession.captchaToken)) {
          this.validateHcid(this.alphaPrefix);
        } else {
          this.alphaPrefixError = true;
          this.showCaptcha = false;
          this.errorText = this.content.home.planSelection.alerts.captchaRequiredError;
        }
      }
      this.formReset();
    } else {
      // Show an error message when a member in public find care landing page hits search button with empty value.
      this.alphaPrefixError = true;
      this.errorText = this.content.home.planSelection.alerts.idOrPrefix;
    }
  }

  /**
   * Handles the search event when no prefix is provided.
   */
  private handlePlanSearchEvent() {
    if (this.searchParams.typeOfCareNm === '') {
      this.selectedCareError = true;
      this.errorText = this.content.home.planSelection.alerts.typeCare;
    } else if (this.searchParams.plan.stateCd === undefined || this.searchParams.plan.stateCd === '') {
      this.selectedStateError = true;
      this.errorText = this.content.home.planSelection.alerts.searchState;
    } else if (this.searchParams.plan.category === undefined || (this.searchParams.plan.category && this.searchParams.plan.category.catCode === '')) {
      this.selectedCategoryError = true;
      this.errorText = this.content.home.planSelection.alerts.category;
    } else if (this.searchParams.plan.name === undefined || this.searchParams.plan.name === '') {
      this.selectedPlanError = true;
      this.errorText = this.content.home.planSelection.alerts.plan;
    } else {
      this.resetErrors();
      this.showSearchLoader = true;
      this.setPlanDetailsAnalyticsQuery();
      this.closePlanSearchPanel();
      if (this.searchParams.plan.urlRedirect && this.searchParams.plan.urlRedirect !== '') {
        this.showSearchLoader = false;
        this.leavingDisclaimer.emit(this.searchParams.plan.urlRedirect);
      } else {
        if (this.planChanged) {
          this.resetSearchParams();
        }
        this.setStandAloneFlag(this.searchParams.plan.careType);
        this._appSession.searchParams = this.searchParams;
        this.getRules();
        this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));
        this.gotoSearchCriteria.emit();
      }
    }
  }

  /**
   * Resets the error flags and error text.
   */
  private resetErrors() {
    this.selectedCareError = false;
    this.selectedStateError = false;
    this.selectedPlanError = false;
    this.selectedCategoryError = false;
    this.errorText = '';
  }

  /**
   * Removes the cobrand rule and updates the brand configuration.
   */
  private removeCobrandRule() {
    const allBrands = AppConstants.BRAND;
    const brandConfig = allBrands.find((b) => this._dataHelper.areEqualStrings(b.brand, this._appSession.metaData.brandCd));

    if (brandConfig) {
      this._appSession.planRule.cobrandRule.isCobrand = false;
      this._appSession.planRule.cobrandRule.object.cobrand = '';
      this._appSession.planRule.cobrandRule.object.imageName = brandConfig.logo;
      this.brand = brandConfig.brand;
      this.emitCobrandEvent();
    }
  }

  /**
   * Retrieves the brand code, converting ABCNY to ABCBS if necessary.
   * @returns {string} The brand code.
   */
  private getBrand(): string {
    return this.brand === BRANDCODE.ABCNY ? BRANDCODE.ABCBS : this.brand;
  }

  /**
   * Checks if the plan result is valid and matches the deeplink plan type.
   * @param result - The generic data response containing an array of public plans.
   * @param planResult - The index of the plan result to validate.
   * @returns True if the plan result is valid, otherwise false.
   */
  private isValidPlanResult(result: IGenericDataResponse<Array<IPublicPlan>>, planResult: number): boolean {
    return (
      result.data &&
      result.data[planResult] &&
      result.data[planResult].planType &&
      result.data[planResult].planType.toLocaleLowerCase() === this._appSession.deeplinkParams.plantype.toLocaleLowerCase()
    );
  }

  /**
   * Trims the value if it is not null or empty.
   * @param value - The value to trim.
   * @returns The trimmed value or an empty string.
   */
  private getTrimmedValue(value: string): string {
    return value && value.trim() !== '' ? value.trim() : '';
  }

  /**
   * Extracts the plan category from the plan.
   * @param plan - The plan object.
   * @returns The plan category object.
   */
  private getPlanCategory(plan: IPublicPlan): IPlanCategory {
    if (plan.planCategoryList && plan.planCategoryList.length > 0) {
      return {
        catCode: plan.planCategoryList[0],
        catName: plan.planCategoryList[0],
        productPlanTypeCode: plan.planType
      };
    }
    return null;
  }

  /**
   * Handles the deeplink validation when a plan state is provided.
   */
  private handlePlanStateDeeplink() {
    this._providerUtilityService.getAllPlanList(this._appSession.deeplinkParams.planstate, this.getBrand()).then(
      (result: IGenericDataResponse<Array<IPublicPlan>>) => {
        const planResult: number = (result?.data || []).findIndex((item) => {
          return item.name.replace(/[<>;,()]/g, '').toLocaleLowerCase() === this._appSession.deeplinkParams.planname.replace(/[<>;,()]/g, '').toLocaleLowerCase();
        });

        if (planResult !== -1) {
          this.validatePlanResult(result, planResult);
        } else {
          // Reset to plan selection page since plan name is not matching.
          this.showSearchLoader = false;
          this.resetDeeplink();
          this.initializeLabels();
          this.setDefault();
        }
      },
      (error: unknown) => {
        this.showSearchLoader = false;
        this.showApiError(error);
      }
    );
  }

  /**
   * Handles the alpha prefix error by updating the error text and flags.
   *
   * @param errorText - The error text to display.
   * @param error - Optional error object for API errors.
   */
  private handleAlphaPrefixError(errorText: string, error?: unknown) {
    this.searchType = 'idNumber';
    this.errorText = errorText;
    this.alphaPrefixError = true;
    this.showSearchLoader = false;
    if (error) {
      this.showApiError(error);
    }
  }
}
