import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { Locale } from '../../../common/constants/app-constants';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { IBaseCode } from '../../../common/interfaces/iBaseCode';
import { IScenarioAlertRequest, IScenarioAlertResponse } from '../../../common/interfaces/iScenarioAlert';
import { ValidateTaxonomyCodesRequest, ValidateTaxonomyCodesResponse } from '../../../common/interfaces/iTaxonomyDeeplink';
import { HttpClientService } from '../../../common/services/httpClientService';
import { IRuleParameters } from '../../rules/interfaces/iRulesParameters';
import { IHealthWiseRequest, IHealthWiseResponse } from '../../search-results/interfaces/iSearchRequest';
import { IAdaListResponse } from '../interfaces/iAdaListResp';
import { IGenericDataResponse } from '../interfaces/iGenericDataResponse';
import { IPrefixMetadataRequest, IPrefixMetadataResponse } from '../interfaces/iPrefixMetadata';
import { IProviderMetadataRequest } from '../interfaces/iProviderMetadataRequest';
import { IProviderMetadataResponse } from '../interfaces/iProviderMetadataResponse';
import { IPublicPlan } from '../interfaces/iPublicPlan';
import { IServiceAreaValidationRequest, IServiceAreaValidationResponse } from '../interfaces/iServiceAreaValidation';
import { ITranslation } from '../interfaces/iTranslation';
import { IZipCodeListRequest, IZipCodesList } from '../interfaces/iZipcodeList';
import { AddressByLatLongResponse, AddressByQueryResponse, ICountyLookupApiRequest, ICountyLookupApiResp, LocationResponse } from './../../../common/models/locationAddress';
import { BaseService } from './../../../common/services/baseService';
import { MessagingHandler } from './../../../common/services/messagingHandler';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { CommonUtil } from './../../utilities/commonUtil';
import { IProvTypeSpecialtyUtilityResponse } from './../interfaces/iProvTypeSpecialtyUtility';

/**
 * Provider code list service will return provider types, languages, etinicities,
 * alpha prefix, patient preferences, areas of expertise and WLP states.
 */
@Injectable()
export class ProviderUtilityService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility,
    private _messagingHandler: MessagingHandler
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  getDataModified(mbrUid: string = '', userName: string = '', appName?: any): Promise<any> {
    let apiUrl = apiNameList.restApi.dataModifiedPublic;
    let params = [];
    const header = [];

    if (appName && appName !== undefined) {
      header.push({
        name: 'pf-app-name',
        value: appName
      });
    }

    if (mbrUid) {
      apiUrl = apiNameList.restApi.dataModifiedSecure;
      params = [
        {
          name: 'mbrUid',
          value: mbrUid,
          isQueryParam: false
        }
      ];

      header.push({
        name: 'SMUNIVERSALID',
        value: userName
      });

      if (this.appSession.metaData.pfEmMode) {
        header.push({
          name: 'pf-em-user',
          value: 'emulate'
        });
      }
    }

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiUrl,
      urlParams: params,
      headers: header
    });
  }

  getCodesList(type: string, locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.providerTypes,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getProviderCodesList(reqObj: any): Promise<any> {
    const promise = this.getProviderTypes(reqObj);

    // call provider types API for English content if locale is not en_US
    if (CommonUtil.isMemberSecure(this.appSession)) {
      if (reqObj && this.appSession.metaData && this.appSession.metaData.locale && this.appSession.metaData.locale.toString().toLocaleLowerCase() !== Locale.ENGLISH.toLocaleLowerCase()) {
        reqObj.locale = Locale.ENGLISH;
        const promise = this.getProviderTypes(reqObj);
        this._messagingHandler.setProviderTypePromise(promise);
      } else {
        this._messagingHandler.setProviderTypePromise(promise);
      }
    }

    return promise;
  }

  getSpecialityList(type: string, locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.specialityList,
      urlParams: [
        {
          name: 'providertype',
          value: type,
          isQueryParam: true
        },
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getSpecialityCodesList(reqObj: IRuleParameters): Promise<IProvTypeSpecialtyUtilityResponse> {
    const promise = this.getSpecialities(reqObj);

    // call speciality types API for English content if locale is not en_US
    if (CommonUtil.isMemberSecure(this.appSession)) {
      if (reqObj && this.appSession.metaData && this.appSession.metaData.locale && this.appSession.metaData.locale.toString().toLocaleLowerCase() !== Locale.ENGLISH.toLocaleLowerCase()) {
        reqObj.locale = Locale.ENGLISH;
        const _spltyPromise = this.getSpecialities(reqObj);
        this._messagingHandler.setSpecialtyTypePromise(_spltyPromise);
      } else {
        this._messagingHandler.setSpecialtyTypePromise(promise);
      }
    }

    return promise;
  }

  getStateList(brand: string, care?: string): Promise<IGenericDataResponse<Array<IBaseCode>>> {
    let params = [];
    params = [
      {
        name: 'brand',
        value: brand,
        isQueryParam: false
      }
    ];
    if (care) {
      params.push({
        name: 'care',
        value: care,
        isQueryParam: true
      });
    }
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      urlParams: params,
      url: this.baseURL + apiNameList.restApi.stateList
    });
  }

  validateServiceArea(reqObj: IServiceAreaValidationRequest): Promise<IServiceAreaValidationResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.serviceAreaValidation,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getTaxonomyCodes(reqObj: ValidateTaxonomyCodesRequest): Promise<ValidateTaxonomyCodesResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.taxonomyCodes,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getZipcodeList(reqObj: IZipCodeListRequest): Promise<IZipCodesList> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.getZipcodeList,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getPlanCategoryList(selectedState: string, brand: string, care: string): Promise<IGenericDataResponse<Array<ITranslation>>> {
    let params = [];
    params = [
      {
        name: 'brand',
        value: brand,
        isQueryParam: false
      },
      {
        name: 'state',
        value: selectedState,
        isQueryParam: false
      },
      {
        name: 'care',
        value: care,
        isQueryParam: true
      }
    ];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      urlParams: params,
      url: this.baseURL + apiNameList.restApi.planCategoryList
    });
  }

  getPlanList(selectedState: string, brand: string, care: string, category: string): Promise<IGenericDataResponse<Array<IPublicPlan>>> {
    let params = [];
    params = [
      {
        name: 'brand',
        value: brand,
        isQueryParam: false
      },
      {
        name: 'state',
        value: selectedState,
        isQueryParam: false
      },
      {
        name: 'category',
        value: category,
        isQueryParam: false
      },
      {
        name: 'care',
        value: care,
        isQueryParam: true
      }
    ];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      urlParams: params,
      url: this.baseURL + apiNameList.restApi.planList
    });
  }

  getAllPlanList(selectedState: string, brand: string): Promise<IGenericDataResponse<Array<IPublicPlan>>> {
    let params = [];
    params = [
      {
        name: 'brand',
        value: brand,
        isQueryParam: false
      },
      {
        name: 'state',
        value: selectedState,
        isQueryParam: false
      }
    ];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      urlParams: params,
      url: this.baseURL + apiNameList.restApi.allPlanList
    });
  }

  getAlphaPrefix(alphaPrefix: string, locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.alphaPrefix,
      urlParams: [
        {
          name: 'code',
          value: alphaPrefix,
          isQueryParam: true
        },
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getPatientPreferencesList(locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.patientPreferences,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }
  /**
   * get the Level of care list
   * @param locale
   */
  getLOCList(locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.loc,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getAOEList(locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.aoe,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getADAList(locale: string): Promise<IAdaListResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.ada,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getOfcSrvcList(locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.officeService,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getEthnicityList(locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.ethnicities,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getLanguageList(locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.languages,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getPharmacyLanguageList(locale: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.pharmacyLanguages,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }
      ]
    });
  }

  getCountyBystate(state: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.countyByState,
      urlParams: [
        {
          name: 'statecode',
          value: state,
          isQueryParam: true
        }
      ]
    });
  }

  getProcedureLookupList(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + (this.forceFuzzyLookupForSpltyAndProcedure ? apiNameList.restApi.procedureLookupv2 : apiNameList.restApi.procedureLookup),
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getSpecialtyLookupList(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + (this.forceFuzzyLookupForSpltyAndProcedure ? apiNameList.restApi.specialtyLookupv2 : apiNameList.restApi.specialtyLookup),
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getAoeLookupList(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.aoeLookup,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getOfcSrvcLookupList(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.ofcSrvcLookup,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getCountyLookupList(reqObj: ICountyLookupApiRequest): Promise<ICountyLookupApiResp> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.countyLookup,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getAffiliationLocationsList(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.affiliationLocations,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  /**
   * this method will call Node API to get Geo Location
   * @param request
   */
  getGeoLocation(locaton: string): Promise<LocationResponse[]> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getGeoLocation,
      urlParams: [
        {
          name: 'input',
          value: locaton,
          isQueryParam: true
        }
      ]
    });
  }

  /**
   * this method will call Node API to get Address by Lat Long
   * @param request
   */
  getAddressByLatLong(lat: string, long: string): Promise<AddressByLatLongResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getAddressbylatlong,
      urlParams: [
        {
          name: 'latitude',
          value: lat,
          isQueryParam: true
        },
        {
          name: 'longitude',
          value: long,
          isQueryParam: true
        }
      ]
    });
  }

  /**
   * this method will call Node API to get Geo Location by Address
   * @param request
   */
  getAddressByQuery(address: string): Promise<AddressByQueryResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getAddressbyQuery,
      urlParams: [
        {
          name: 'query',
          value: address,
          isQueryParam: true
        }
      ]
    });
  }

  /**
   * this method will call Node API to get provider list
   * @param request
   */
  private getProviderTypes(request: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerTypesFilter,
      data: request,
      headers: this.getHeaders()
    });
  }

  /**
   * Makes a POST request to the specialtyFilterV1 API to get the specialities.
   * @param {IRuleParameters} request - The request parameters for the API.
   * @returns {Promise<IProvTypeSpecialtyUtilityResponse>} A promise that resolves to the response of the API.
   */
  private getSpecialities(request: IRuleParameters): Promise<IProvTypeSpecialtyUtilityResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.specialtyFilterV1,
      data: request,
      headers: this.getHeaders()
    });
  }

  /**
   * Return flag which determines whether fuzzy logic has to be applied on specialty and procedure lookup
   */
  private get forceFuzzyLookupForSpltyAndProcedure(): boolean {
    return true;
  }

  /**
   * this method will call Node API to get procedure and specialities healthwise data
   * @param healthwiseReqObj
   */
  getHealthwiseProcedureAndSpecialityList(healthwiseReqObj: IHealthWiseRequest): Promise<IHealthWiseResponse> {
    const header = this.getHeaders();
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.getProcedueAndSpecialityList,
      data: healthwiseReqObj,
      headers: header
    });
  }

  /**
   * this method will call Node API to get prefix enabled state information data
   * @param prefixMetaDataReqObj
   */
  getPrefixMetadata(prefixMetaDataReqObj: IPrefixMetadataRequest): Promise<IPrefixMetadataResponse> {
    const header = this.getHeaders();
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.prefixMetadata,
      data: prefixMetaDataReqObj,
      headers: header
    });
  }

  getScenarioBasedAlert(reqObj: IScenarioAlertRequest): Promise<IScenarioAlertResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.scenarioAlert,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getByQuery(address: string): Promise<AddressByQueryResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getByQuery,
      urlParams: [
        {
          name: 'query',
          value: address,
          isQueryParam: true
        }
      ]
    });
  }

  getProviderMetaDataLookupList(reqObj: IProviderMetadataRequest): Promise<IProviderMetadataResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerMetaDataLookup,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
