import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AssignPcpService {

  public anpSubject = new Subject<boolean>();

  isAnpProvider(data: boolean) {
    //passing the data as the next observable
    this.anpSubject.next(data);
  }

}
