import { Injectable } from '@angular/core';
import { ISecureTccMedicalSearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { IFinalProvider, IFinalSearchResponse } from '../../../common/interfaces/iCommonSearchResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecureTccMedicalEmailRequest } from '../../../common/interfaces/iSecureTccMedicalEmailRequest';
import { IMedicalDemographicFilter, IMedicalEycProcedureCriteriaRequest, ISecureTccMedicalOutputFlags, ISecureTccMedicalSearchCriteria, ISecureTccMedicalSearchRequest } from '../../../common/interfaces/iSecureTccMedicalSearchRequest';
import { ISecureTccMedicalSearchResponse } from '../../../common/interfaces/iSecureTccMedicalSearchResponse';
import { BaseService } from '../../../common/services/baseService';
import { SecureV1TccMedical } from '../../../common/services/secureV1TccMedicalSvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { CommonUtil } from '../../utilities/commonUtil';
import { SearchRequestUtil } from '../../utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../utilities/searchResponseUtil';
import { IEycAffliation } from '../interfaces/iSummaryResp';
import { API_NAME } from '../values/providerSearchConstants';
import { HttpClientService } from './../../../common/services/httpClientService';

@Injectable({
  providedIn: 'root',
})
export class SecureTccMedicalAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = this.appSession.appConfig?.paginationSize.summaryPrintPage;

  constructor(
    appSession: AppSession,
    private _secureTccMedicalSvc: SecureV1TccMedical,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility, httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    const apiResponse = await this._secureTccMedicalSvc.execute(transformedRequest);
    return this.transformResponse(apiResponse);
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ISecureTccMedicalSearchRequest = { ...searchRequest };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_MEDICAL);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(email: string, content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ISecureTccMedicalEmailRequest = { ...searchRequest, email };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_MEDICAL);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ISecureTccMedicalSearchFilterRequest {
    const tccMedicalSecureSearchReq = this.transformRequest(
      pageNumber,
      pageSize
    );
    const filterReq:ISecureTccMedicalSearchFilterRequest = {
      ...tccMedicalSecureSearchReq,
      scenario: SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL,
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): ISecureTccMedicalSearchRequest {
    const searchParams = this.appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(
      this.appSession,
      pageNumber,
      pageSize
    );
    // remove plan and planNames from request payload
    commonSearchRequest.plan = undefined;
    commonSearchRequest.planNames = undefined;
    // redefine required output-flags
    const {
      imposeAddressConsolidation,
      improveSearchCriteria,
      includeDisclaimerRules,
      includeProviderNetworks,
      includeReviewRatings,
      includeTaxonomy,
      includeVisibilityRules,
    } = commonSearchRequest.outputFlags || {};
    const outputFlags: ISecureTccMedicalOutputFlags = {
      imposeAddressConsolidation,
      improveSearchCriteria,
      includeDisclaimerRules,
      includeProviderNetworks,
      includeReviewRatings,
      includeTaxonomy,
      includeVisibilityRules,
    };
    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(
      this.appSession
    );
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const returnTypeofProvider = CommonUtil.getProcedureProviderReturnType(searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode);

    const searchCriteria: ISecureTccMedicalSearchCriteria = {
      brandCode: this.appSession?.metaData?.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this.appSession),
      isInitialSearch: this.appSession?.initialSearch,
      miscFilter: {
        extendedHoursOnly: miscFilter?.extendedHoursOnly,
        patientPopulationList: miscFilter?.patientPopulationList,
        recognition: {
          blueDistinction: false,
          blueDistinctionCodes: miscFilter?.recognition?.blueDistinctionCodes,
          bluePrecision: miscFilter?.recognition?.bluePrecision,
          patientCenteredCare: searchParams?.enhancedPersonalCare,
          providerTier: searchParams?.providerTier
        },
        recognitionV2: { recognitionCodes: miscFilter?.recognitionV2?.recognitionCodes },
        smartSearchRequestId: miscFilter?.smartSearchRequestId
      },
    };
    // binding demogrphic filters if available
    if (demographicFilter) {
      // initialize demographicFilter in searchCriteria
      searchCriteria.demographicFilter = this.bindDemographicFilter(demographicFilter);
    }
    const eycProcedureCriteria: IMedicalEycProcedureCriteriaRequest = {
      procedureCode:
        searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode,
      providerCategoryCode:
        searchParams?.eycSearchParam?.eycProcedureCriteria
          ?.providerCategoryCode,
      returnTypeofProvider,
    };
    const request: ISecureTccMedicalSearchRequest = {
      ...commonSearchRequest,
      eycProcedureCriteria,
      searchCriteria,
      alphaPrefix: undefined,
      outputFlags,
    };
    return request;
  }

  private bindDemographicFilter(demographicFilter): IMedicalDemographicFilter {
    const searchCriteriaDemographic: IMedicalDemographicFilter = {};
    // filter - Key Filters
    if (demographicFilter.ableToServeAsPcp) {
      searchCriteriaDemographic.ableToServeAsPcp = demographicFilter.ableToServeAsPcp;
    }
    if(demographicFilter.anp) {
      searchCriteriaDemographic.anp = demographicFilter.anp;
    }
    // filter - Profile
    if (demographicFilter.telehealth) {
      searchCriteriaDemographic.telehealth = demographicFilter.telehealth
    }
    if (demographicFilter.boardCertification) {
      searchCriteriaDemographic.boardCertification = demographicFilter.boardCertification;
    }
    // filter - Area of Expertise (aoe) []
    if (demographicFilter.aoeCodes?.length) {
      searchCriteriaDemographic.aoeCodes = demographicFilter.aoeCodes;
    }
    // filter - Type []
    if (demographicFilter.typeCodes?.length) {
      searchCriteriaDemographic.typeCodes = demographicFilter.typeCodes;
    }
    // filter - Ethnicity []
    if (demographicFilter.ethnicityCodes?.length) {
      searchCriteriaDemographic.ethnicityCodes = demographicFilter.ethnicityCodes;
    }// filter - Gender []
    if (demographicFilter.genderCodes?.length) {
      searchCriteriaDemographic.genderCodes = demographicFilter.genderCodes;
    }
    //filter - Specialty []
    if (demographicFilter.specialtyCategoryCodes?.length) {
      searchCriteriaDemographic.specialtyCategoryCodes = demographicFilter.specialtyCategoryCodes;
    }
    //filter - Sub Specialty []
    if (demographicFilter.taxonomyCodes?.length) {
      searchCriteriaDemographic.taxonomyCodes = demographicFilter.taxonomyCodes;
    }
     // filter - Level Of care []
     if (demographicFilter.levelOfCareCodes?.length) {
      searchCriteriaDemographic.levelOfCareCodes = demographicFilter.levelOfCareCodes;
    }
    // filter - ofcSrvcCodes []
    if (demographicFilter.ofcSrvcCodes?.length) {
      searchCriteriaDemographic.ofcSrvcCodes = demographicFilter.ofcSrvcCodes;
    }
    if (demographicFilter.languageCodes?.length) {
      searchCriteriaDemographic.languageCodes = demographicFilter.languageCodes;
    }
    // filter - adaAccessibility []
    if (demographicFilter.adaAccessibilityList?.length) {
      searchCriteriaDemographic.adaAccessibilityList = demographicFilter.adaAccessibilityList;
    }
    return searchCriteriaDemographic;
  }

  private transformResponse(apiResponse: ISecureTccMedicalSearchResponse): IFinalSearchResponse {
    const response = {} as IFinalSearchResponse;
    const finalProviders: IFinalProvider[] = [];
    if (apiResponse) {
      response.pageNumber = apiResponse.pageNumber;
      response.pageSize = apiResponse.pageSize;
      response.totalPages = apiResponse.totalPages;
      response.totalRecords = apiResponse.totalRecords;
      response.defaultSort = apiResponse.sortBy;
      response.accntBalanceInfo = apiResponse.accntBalanceInfo;
      response.oopEligible = apiResponse.oopEligible;
      response.costRange = apiResponse.costRange;
      response.memberDeductibleAmount = apiResponse.memberDeductibleAmount;
      response.ruleList = apiResponse.rules;
      response.sortOptions = apiResponse.sortOptions;
      response.criteriaChange = apiResponse.criteriaChange;
      response.returnTypeofProvider = apiResponse.returnTypeofProvider;
      response.onsiteClinics = apiResponse?.onsiteClinics ?? undefined;
      if (apiResponse.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IFinalProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            finalProvider.eycCostInfo = provider.eycCostInfo;
            finalProvider.isRecordFromSPFI = provider.isRecordFromSPFI;
            if (provider.eycAffliation) {
              const facilityInfo = {} as IEycAffliation;
              facilityInfo.isVisibleAffiliation = provider.eycAffliation.isVisibleAffiliation;
              facilityInfo.affiliationList = [];
              (provider.eycAffliation.affiliationList || []).forEach((facility) => {
                const facilityProvider = SearchResponseUtil.getCommonProviderInformation(facility);
                facilityProvider.eycCostInfo = facility.eycCostInfo;
                facilityInfo.affiliationList.push(facilityProvider);
              });
              finalProvider.eycAffliation = facilityInfo;
            }
            finalProviders.push(finalProvider);
          }
        });
      }
      response.providerList = finalProviders;
      response.sortIndicator = apiResponse.sortIndicator;
    }
    return response;
  }

}
