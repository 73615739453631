import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { DisclaimerService } from './../../services/disclaimerService';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-cost-tab-disc-content',
  moduleId: module.id,
  template: `<div *ngIf="tabData?.message?.length > 0 ">
  <div class="print-disclaimer-heading" id="txt-cost-disc-heading">
  <strong>{{tabData.heading}}</strong></div>
    <div *ngFor="let tab of (tabData.message || []); let i = index">
      <div class="print-cost-disclaimer-tab-content" [innerHTML]="tab"></div>
      <div class="cost-disclaimer-tab-content">
        <div [hidden]= "i > 0 && !tabData.isVisible" [innerHTML]="tab"></div>
        <div [hidden]= "tabData.isVisible" id="txt-cost-disc-showmore"
          *ngIf="i==0 && tabData.message.length > 1" class="show-more show-more-btn body-sm">
        <a href="javascript:void(0);" id="lnk-cost-disc-showmore"
        [innerText]="content.disclaimerComponent.labels.showMore"
        [attr.aria-label]="content.disclaimerComponent.labels.showMoreDisclaimer"
        (click)="toggleDisclaimer(tabData)"
        [attr.data-analytics]="screenName"></a>
        </div>
        <div [hidden]= "!tabData.isVisible" id="txt-cost-disc-showless"
        *ngIf="i>0 && tabData.message.length > 1" class="show-more show-more-btn body-sm">
        <a href="javascript:void(0);" [innerText]="content.disclaimerComponent.labels.showLess"
        [attr.aria-label]="content.disclaimerComponent.labels.showLessDisclaimer"
        id="lnk-cost-disc-showless" (click)="toggleDisclaimer(tabData)"></a>
        </div>
      </div>
    </div>
  </div>`
})
export class PFCostDisclaimerTabComponent extends BaseComponent implements OnInit {
  @Input() tabData: any;
  @Input() screenName;

  constructor(private _disclaimer: DisclaimerService,
              private _eventHandler: EventHandler,
              @Inject(AppSession)
              private _appSession: AppSession,
              private _route: ActivatedRoute,
              @Inject(ContentHelper)
    private _contentHelper: ContentHelper) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');

  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  toggleDisclaimer(data) {
    data.isVisible = !data.isVisible;
  }
}
