//Api expecting lower case values.
import { ICptCostInfo } from "../../../common/interfaces/iCptMedicalResponse";

export enum ActionTaken {
  ARCHIVED = 'archived',
  AKNOWLEDGED = 'acknowledged',
  PENDING = 'pending'
}

export const FINDCARE_PAGE = 'findcarepage';
export const NBADESTINATION = 'nbaDestination';
export const MEMBER = 'member';

export const COST_ESTIMATE_ORDER: { [key in keyof Partial<ICptCostInfo>]: number } = {
  estimatedBill: 0,
  planPays: 1,
  appliedDeductible: 2,
  coinsurance: 3,
  copay: 4,
  memberResponsibility: 5
}

export enum COST_SCENARIO {
  DOLLAR = 'Dollar',
  PERCENTAGE = 'Percentage',
}

export enum ActionType {
  SEARCH = 'SEARCH',
  SSO = 'SSO',
  EXT_NAV = 'EXT_NAV',
  INT_RCP_NAV = 'INT_RCP_NAV',
  INT_NAV = 'INT_NAV'
}

export enum CodeType {
  LHO = "LHO",
  EYEMED = "EYEMED",
  DENTAQUEST = "DENTAQUEST",
  VIRTUAL_CARE = "VIRTUAL_CARE"
}
