import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { IHealthwiseBaseCode, IHealthWiseRequest, IHealthWiseResponse } from '../../../fad/search-results/interfaces/iSearchRequest';
import { IAdobeFindcareResults, IHealthwiseAdobeAnalyticsObj, IProvider, ISpecialty } from '../../../fad/search-results/interfaces/iSummaryResp';
import { Locale } from '../../constants/app-constants';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-view-health-wise-cmp',
  templateUrl: './pfViewHealthwiseCmp.html'
})
export class ViewHealthwiseComponent extends BaseComponent implements OnInit {
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement>;
  isApiError: boolean = false;
  showhealthwiseLoader: boolean = true;
  showTab: boolean = false;
  providerCategoryCode: string;
  innerHTML: string = '';
  healthwiseContentResp: Array<IHealthwiseBaseCode> = [];
  _healthwiseContentObj: IHealthwiseBaseCode = {} as IHealthwiseBaseCode;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this._appSession.isEyc || this._appSession.isMedicalCodeSearch) {
      this.showTab = true;
    }
  }

  /** Open Side Panel */
  openSidePanel(direction: any, specialty?: ISpecialty, providerResponseObj?: IProvider, procedureCode?: string, adobeTags?: IHealthwiseAdobeAnalyticsObj) {
    this.showhealthwiseLoader = true;
    const healthWiseReq: IHealthWiseRequest = {
      procedureCategoryCode: [],
      specialtyCategoryList: [],
      subSpecialtyList: [],
      taxonomyList: [],
      specialtyCategoryFilter: [],
      providerTypeCodeList: []
    };
    this.setAdobeAnalyticsQuery(adobeTags);

    if (specialty && specialty.specialtyCode && providerResponseObj && providerResponseObj.providerTypeCodeList && providerResponseObj.specialtyCategory) {
      healthWiseReq.providerTypeCodeList = providerResponseObj.providerTypeCodeList;
      healthWiseReq.specialtyCategoryFilter = providerResponseObj.specialtyCategory;
      healthWiseReq.taxonomyList = [specialty.specialtyCode];
    }
    if (procedureCode && procedureCode.length > 0) {
      healthWiseReq.procedureCategoryCode = [procedureCode];
    }
    healthWiseReq.locale = this._appSession.metaData?.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;

    this._providerUtilityService.getHealthwiseProcedureAndSpecialityList(healthWiseReq).then(
      (healthWiseResponse: IHealthWiseResponse) => {
        this.showhealthwiseLoader = false;
        if (healthWiseResponse && healthWiseResponse.hwContentDesciptionList && healthWiseResponse.hwContentDesciptionList.length > 0) {
          this.healthwiseContentResp = healthWiseResponse.hwContentDesciptionList;
          this.healthwiseContentObj = healthWiseResponse.hwContentDesciptionList[0];
        } else {
          this.healthwiseContentResp = [];
          this.healthwiseContentObj = null;
        }
      },
      (error: any) => {
        this.showhealthwiseLoader = false;
        this.healthwiseContentResp = [];
        this.healthwiseContentObj = null;
        this.isApiError = true;
      }
    );

    this.sidePanelRef = this._sidePanel.open(direction, this.viewHealthwiseContentModal);
  }

  /** Event handler for click of close button  */
  onClose() {
    this.isApiError = false;
    this.showhealthwiseLoader = true;
    this.healthwiseContentResp = [];
    this.healthwiseContentObj = null;
    this.sidePanelRef.close();
  }

  set healthwiseContentObj(healthwiseContentResp: IHealthwiseBaseCode) {
    this._healthwiseContentObj = healthwiseContentResp;
  }

  get healthwiseContentObj(): IHealthwiseBaseCode {
    return this._healthwiseContentObj;
  }

  setAdobeAnalyticsQuery(adobeTagData: IHealthwiseAdobeAnalyticsObj) {
    if (adobeTagData && adobeTagData.isSummary) {
      if (adobeTagData && adobeTagData.procedureName) {
        if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
          if (typeof window.digitalData.page.pageInfo.findCareResults === 'undefined' || Object.keys(window.digitalData.page.pageInfo.findCareResults).length === 0) {
            window.digitalData.page.pageInfo.findCareResults = {} as IAdobeFindcareResults;
          }
          window.digitalData.page.pageInfo.findCareResults.procedure = adobeTagData.procedureName;
        }
      }

      if (adobeTagData && adobeTagData.specialtyName) {
        if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
          if (typeof window.digitalData.page.pageInfo.findCareResults === 'undefined' || Object.keys(window.digitalData.page.pageInfo.findCareResults).length === 0) {
            window.digitalData.page.pageInfo.findCareResults = {} as IAdobeFindcareResults;
          }
          window.digitalData.page.pageInfo.findCareResults.specialty = adobeTagData.specialtyName;
        }
      }
    } else {
      if (adobeTagData && adobeTagData.procedureName) {
        if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
          if (typeof window.digitalData.page.pageInfo.providerDetail === 'undefined' || Object.keys(window.digitalData.page.pageInfo.providerDetail).length === 0) {
            window.digitalData.page.pageInfo.providerDetail = {} as IAdobeFindcareResults;
          }
          window.digitalData.page.pageInfo.providerDetail.procedure = adobeTagData.procedureName;
        }
      }

      if (adobeTagData && adobeTagData.specialtyName) {
        if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
          if (typeof window.digitalData.page.pageInfo.providerDetail === 'undefined' || Object.keys(window.digitalData.page.pageInfo.providerDetail).length === 0) {
            window.digitalData.page.pageInfo.providerDetail = {} as IAdobeFindcareResults;
          }
          window.digitalData.page.pageInfo.providerDetail.specialty = adobeTagData.specialtyName;
        }
      }
    }
  }
}
