export interface IGeoCoordinates {
  latitude?: string;
  longitude?: string;
}

export interface IGeoLocationInfo extends IGeoCoordinates {
  name?: string;
  city?: string;
  stateCd?: string;
  stateName?: string;
  county?: string;
  zipcode?: string;
  formattedAddress?: string;
  countyCode?: string;
  addressLine1?: string;
  addressLine2?: string;
}

export interface IGeoLocation extends IGeoLocationInfo {
  isGeoCodeAllowed?: boolean;
  isGeoCodeValid?: boolean;
  isZipStateCountySearch?: boolean;
  isGeoCodeSearch?: boolean;
  isHomeAddressSearch?: boolean;
  isUserLocationValid?: boolean;
  isOtherAddressSearch?: boolean;
  isCountySearch?: boolean;
  isCitySearch?: boolean;
  isValidZipCode?: boolean;
  isZipcodeSearch?: boolean;
  searchText?: string;
  defaultSearchType?: GEO_LOCATION_SEARCH_TYPE;
  searchType?:  GEO_LOCATION_SEARCH_TYPE;
}

export enum GEO_LOCATION_SEARCH_TYPE {
  ZIP_CITY_COUNTY = 'zipOrCityOrCounty',
  CURRENT_LOCATION = 'currentLocation',
  HOME_ADDRESS = 'homeAddress',
  OTHER_ADDRESS = 'otherAddress',
  UNKNOWN = 'UNKNOWN'
}

export interface IGeoLocationInfoWithCounties {
  cities?: IGeoLocationInfo[];
  counties?: IGeoLocationInfo[];
}

export interface ITranslatedBingResponse extends IGeoLocationInfo {
  friendlyText?: string;
}

export interface IGeoLocationRequest {
  zipCode?: string;
  cityName?: string;
  stateCode?: string;
  countyName?: string;
  countyCode?: string;
  latitude?: string;
  longitude?: string;
  addressLine1?: string;
  addressLine2?: string;
  type: 'ZIP' | 'CITY_STATE' | 'COUNTY_SEARCH' | 'COUNTY_STATE' | 'LAT_LONG' | 'FIPS_COUNTY_CODE' | 'CITY_SEARCH' | 'UNKNOWN';
  misc?: string;
  ex?: string;
}

export const SEARCH_INPUT_TYPE = {
  ZIP_CODE: 'ZIP_CODE',
  COUNTY: 'COUNTY',
  CITY: 'CITY',
  COUNTY_STATE: 'COUNTY_STATE',
  CITY_STATE: 'CITY_STATE'
};

export interface ILocationSearchRequest {
  city?: string;
  county?: string;
  state?: string;
  zipcode?: string;
  addressLine1?: string;
  addressLine2?: string;
  misc?: string;
  patternEnum?: string;
  ex?: string;
  addressQuery?: string;
}


export const REGEX_MATCH_PATTERN = {
  COORDINATES: /^(-?(90|[0-8]?\d)(\.\d+)|-?(180|1[0-7]\d|\d?\d)(\.\d+)?)$/,
  BING_QUERY: /^[a-zA-Z0-9\s.,#'-]*$/,
  BING_LOCATION_STRING: /([a-z0-9\s?]{1,})$/gi,
  COUNTY_CODE: /^(\+?\d{3,4})$/,
  COUNTY_NAME: /(\s+county|\s+co\.)/gi,
  POSTAL_CODE: /^\d{5}(-\d{4})?$/,
  CITY_STATE_STRING: /(^([A-Z][a-z]{1,}\s?)+)(\,?\s?)?([A-Z]{2})$/gi,
  FULL_ADRS_STRING: /^([A-Za-z0-9\s,]+\s)(#\d*)?[\,]?(\s+)?((?:[\w+\s*-])+)[\,]\s?([a-zA-Z]{2})\s+([0-9a-zA-Z]+)$/gi,
  BING_AMBIGUOUS_LOCATION_STRING_ENDING: /([\,\s]{1,})$/gi,
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER_ONLY: /^\d+$/,
  STRING_BY_SPACE: /^(\S+)\s(.*)/,
  SPECIAL_CHARACTERS: /[^\w\s]/gi,
  MULTIPLE_WHITE_SPACE: /\s\s+/g,
  DIRECTORY_PREFIX: /^([\d\w]{3,10})$/,
  FOUR_DIGIT_NUMBER: /^\d{1,4}$/,
  ZIP_CODE: /^[0-9]{5}$/
};

export enum SEARCH_INPUT {
  LOW = 3,
  HIGH = 100
}

export enum LOOKUP_MIN_COUNT {
  minCount = 3
}

export interface IlocationForm {
  searchType?: string,
  zipOrCityOrCounty?: {
    zipCityCounty: string
  },
  currentLocation?: {
    cityState: string,
    zipCode: string
  },
  otherAddress?: {
    addressLine1: string,
    addressLine2?: string,
    zipCode: string,
    cityOrCountyState: string

  }
}

export interface IOtherAddress {
  cityInput?: IGeoLocationInfo;
  zipInput?: IGeoLocationInfo;
  isValidZipSelected: boolean;
}
