import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { ActionKey } from '../enums/findCareFlag';
import { IRedisCacheResponse } from '../interfaces/iRedisCacheResponse';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';

@Injectable({
  providedIn: 'root'
})
export class ActionService extends BaseService {
  onSearchClick = new Subject<boolean>();
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async setRedisCache(actionKey: ActionKey): Promise<IRedisCacheResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.action,
      headers: this.getHeaders(),
      urlParams: [
        {
          name: 'key',
          value: actionKey,
          isQueryParam: true
        }
      ]
    });
  }

  setSearchClick(value: boolean) {
    this.onSearchClick.next(value);
  }

  getSearchClick() {
    return this.onSearchClick.asObservable();
  }
}
