// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    :host {
      cursor: pointer;
    }
  `, "",{"version":3,"sources":["webpack://./src/app/findcare/common/components/shareable/cost-slide-out/costSlideOutCmp.ts"],"names":[],"mappings":";IACI;MACE,eAAe;IACjB","sourcesContent":["\n    :host {\n      cursor: pointer;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
