import { Component } from '@angular/core';
import { LocationComponent } from './locationCmp';

@Component({
  moduleId: module.id,
  selector: 'app-location-v2-cmp',
  templateUrl: './locationV2Cmp.html'
})
export class LocationV2Component extends LocationComponent {

}
