import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { apiNameList } from './../../../../environments/api-name-list';
import { HttpMethod } from './../../../common/enums/httpMethodEnum';
import { BaseService } from './../../../common/services/baseService';
import { IRuleParameters } from './../../../fad/rules/interfaces/iRulesParameters';
import { IKodiakProcedure } from './../interfaces/iKodiakProcedure';

/**
 * Care Team service will return Care Team providers.
 */
@Injectable()
export class PFPromotedProcedureService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getPromotedProcedures(reqObj: IRuleParameters): Promise<IKodiakProcedure> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.promotedProcedure,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

}
