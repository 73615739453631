import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { SsoModule } from './../common/components/sso/ssoModule';
import { PFCareNowContainerComponent } from './components/pfCareNowContainerCmp';

@NgModule({
  imports: [UxSharedModule, FormsModule, ReactiveFormsModule, CommonModule, SsoModule],
  declarations: [PFCareNowContainerComponent],
  exports: [PFCareNowContainerComponent]
})
export class PFCareNowModule {
  static forRoot(): ModuleWithProviders<PFCareNowModule> {
    return {
      ngModule: PFCareNowModule,
      providers: []
    };
  }
}
