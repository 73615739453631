import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PcpDetailsUtil } from '../../../common/utilities/pcpDetailsUtil';
import { IPcpEligibleMemberContract } from '../models/iMemberPcpDetails';
import { DATE_FORMAT, ERR_INVALID_PCP_INFO_API_RESP, ERR_MBR_AGE_NOT_IN_RANGE, PCP_REQUIRED, SOURCE_SYSTEM } from './../../../common/constants/app-constants';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { IMbrAgeLimitResp } from './../../../common/interfaces/iMbrAgeLimit';
import { IAffiliationRadioInput, IAffliliationsModel, IPcpInfoRequest, IPcpInfoResponse } from './../../../common/interfaces/iPCPInfo';
import { ACTIVE_COVERAGE, BOTH_COVERAGES, FUTURE_COVERAGE } from './../../../common/interfaces/iPcpRequest';
import { ISelectedPcp } from './../../../common/interfaces/iSelectedPcp';
import { ApiHelper } from './../../../common/services/apiHelper';
import { DataHelper } from './../../../common/services/dataHelper';
import { PCPService } from './../../../common/services/pcpSvc';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { PROVIDER_CATEGORY_CODE } from './../../search-providers/values/providerSearchConstants';
import { MemberPcpDetailsApiHelper } from './memberPcpDetailsApiHlpr';

@Injectable()
export class ProviderPcpInfoApiHelper extends ApiHelper<IPcpInfoRequest, IPcpInfoResponse> {
  apiResp: IPcpInfoResponse = null;

  protected _professional: ISelectedPcp;
  protected _activePlanAfflns: Array<IAffiliationRadioInput> = [];
  protected _futurePlanAfflns: Array<IAffiliationRadioInput> = [];
  protected _bothPlansAfflns: Array<IAffiliationRadioInput> = [];

  constructor(private _pcpSvc: PCPService,
    private _mbrPcpDtlsApi: MemberPcpDetailsApiHelper,
    private _appSession: AppSession, protected _dataHlpr: DataHelper, protected _datePipe: DatePipe,
    private _contentHlpr: ContentHelper, protected _pcpUtil: PcpDetailsUtil) {
    super();
  }

  getContent(): any {
    const content = this._contentHlpr.getContent('PFAssignPcpContainerComponent').assignPcpCmpContent;
    return content;
  }

  invokeByProvider(professional: ISelectedPcp): Promise<IPcpInfoResponse> {
    if (!professional) {
      return Promise.reject('Invalid provider');
    }

    this.reset();
    this.isProgress = true;

    let providerIdentifier: string = professional.providerIdentifier;
    if (this._dataHlpr.isEmptyString(providerIdentifier)) {
      providerIdentifier = professional.id;
    }
    if (!providerIdentifier) {
      return Promise.reject('providerIdentifier is not given.');
    }

    const addressIdentifier = professional.location?.address?.addressId;
    if (!addressIdentifier) {
      return Promise.reject('addressIdentifier is not given.');
    }
    const defaultContractUid = this._appSession.metaData.appContract.contractUid;
    const mbrUid = this._appSession.metaData.appContract.mbrUid;
    this._professional = professional;
    let apiCallPromise: Promise<IPcpInfoResponse>;
    //BPO and HMO scenario ( Should have 2 contracts. One is PCP Required, another one is PCP Optional)
    apiCallPromise = this.execute({ providerIdentifier, addressIdentifier, defaultContractUid, mbrUid });

    return new Promise<IPcpInfoResponse>((resolve, reject) => {
      (async () => {
        try {
          const resp = await apiCallPromise;
          this.isProgress = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.onSuccess(resp);
          resolve(resp);
        } catch (error) {
          this.delegateOnFailure(error);
          reject(error);
        }
      })();
    });
  }

  /**
   * @deprecated This method should not be used. Use `invokeByProvider()` instead.
   * @param request
   */
  invoke(request: IPcpInfoRequest): Promise<IPcpInfoResponse> {
    throw Promise.reject(new Error('This method should not be used. Use `invokeByProvider()` instead.'));
  }

  onSuccess(resp: IPcpInfoResponse, req?: IPcpInfoRequest): void {
    this.validateResp(resp);
    this.apiResp = resp;
  }

  onFailure(errResp: any): void {
    this.alert.alertContent = '';
  }

  validateResp(resp: IPcpInfoResponse) {
    const error: IException = { code: ERR_INVALID_PCP_INFO_API_RESP, message: 'There are no pcp info received' };
    if (!resp) {
      throw error;
    }
  }

  createAffliationRadioInputs(mbrPcpContracts: IPcpEligibleMemberContract[]) {
    //Reset Affiliation Radio Inputs arrays
    this._activePlanAfflns.length = 0;
    this._futurePlanAfflns.length = 0;
    this._bothPlansAfflns.length = 0;

    if (!mbrPcpContracts || !Array.isArray(mbrPcpContracts) || mbrPcpContracts.length === 0) {
      return;
    }
    const content = this.getContent();
    const activeCovAfflnsMap: { [key: string]: IAffiliationRadioInput } = {};//key: affiliationName or ProviderName
    const futureCovAfflnsMap: { [key: string]: IAffiliationRadioInput } = {};
    const bothCovAfflnsMap: { [key: string]: IAffiliationRadioInput } = {};

    let activeAfflnRadioCounter: number = 0;
    let futureAfflnRadioCounter: number = 0;
    let bothAfflnRadioCounter: number = 0;

    this.apiResp.pcpInfos.forEach((planPcp) => {
      const pcpInfoList = planPcp.pcpInfoList;
      if (!Array.isArray(pcpInfoList) || pcpInfoList.length === 0) {
        return;
      }
      const contract = mbrPcpContracts.find((c) => {
        return this._dataHlpr.areEqualStrings(c.contractUid, planPcp.contractUid);
      });
      if (!contract) {
        return;
      }
      const contractUid = contract.contractUid;
      const plan = contract.plans[0];
      const isActive = this._dataHlpr.areEqualStrings(contract.contractStatus, ACTIVE_COVERAGE);
      const isFuture = this._dataHlpr.areEqualStrings(contract.contractStatus, FUTURE_COVERAGE);

      pcpInfoList.forEach((pcpInfo) => {
        let afflnNm = pcpInfo.affiliationName;
        if (this._dataHlpr.isEmptyString(afflnNm)) {
          afflnNm = this._professional.providerName;
        }
        const afflnKey = afflnNm.trim().split(' ').join('-').toLowerCase();

        let activeAfflnRadioInput = activeCovAfflnsMap[afflnKey];
        let futureAfflnRadioInput = futureCovAfflnsMap[afflnKey];
        let bothAfflnRadioInput = bothCovAfflnsMap[afflnKey];

        if (!activeAfflnRadioInput && isActive) {
          activeAfflnRadioInput = this.createAffiliation(afflnKey, afflnNm, ACTIVE_COVERAGE, activeAfflnRadioCounter);
          activeCovAfflnsMap[afflnKey] = activeAfflnRadioInput;
          this._activePlanAfflns.push(activeAfflnRadioInput);
          activeAfflnRadioCounter++;
        }

        if (!futureAfflnRadioInput && isFuture) {
          futureAfflnRadioInput = this.createAffiliation(afflnKey, afflnNm, FUTURE_COVERAGE, futureAfflnRadioCounter);
          futureCovAfflnsMap[afflnKey] = futureAfflnRadioInput;
          this._futurePlanAfflns.push(futureAfflnRadioInput);
          futureAfflnRadioCounter++;
        }

        if (!bothAfflnRadioInput) {
          bothAfflnRadioInput = this.createAffiliation(afflnKey, afflnNm, BOTH_COVERAGES, bothAfflnRadioCounter);
          bothCovAfflnsMap[afflnKey] = bothAfflnRadioInput;
          this._bothPlansAfflns.push(bothAfflnRadioInput);
          bothAfflnRadioCounter++;
        }
        const pcpId = pcpInfo.id;
        let pcpIdLbl = content.lblPcpID + pcpId;
        let planNameLbl = plan.planNm;
        if (isActive) {
          const contractSuffixLbl = content.activeStatus;
          //Create a PCP ID entry for Active coverage
          activeAfflnRadioInput.activePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: ACTIVE_COVERAGE, showPlanName: false });
          bothAfflnRadioInput.activePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: ACTIVE_COVERAGE, showPlanName: true });
        }
        if (isFuture) {
          const startingDate = this._datePipe.transform(plan.planStartDate, DATE_FORMAT);
          const contractSuffixLbl = content.futureStatus.replace(/{DATE}/ig, startingDate);
          //Create a PCP ID entry for Future coverage
          futureAfflnRadioInput.futurePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: FUTURE_COVERAGE, showPlanName: false });
          bothAfflnRadioInput.futurePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: FUTURE_COVERAGE, showPlanName: true });
        }
      });
    });

    //Eliminate affiliations from bothPlansAfflns that are not having PCPIDs for ACTIVE and FUTURE contratcs.
    this._bothPlansAfflns = this._bothPlansAfflns.filter((affln) => {
      return affln.activePcpIds.length > 0 && affln.futurePcpIds.length > 0;
    });
  }

  getAffliationRadioInputs(contractStatus: string): IAffiliationRadioInput[] {
    if (this._dataHlpr.areEqualStrings(contractStatus, ACTIVE_COVERAGE)) {
      return this._activePlanAfflns;
    }
    if (this._dataHlpr.areEqualStrings(contractStatus, FUTURE_COVERAGE)) {
      return this._futurePlanAfflns;
    }
    if (this._dataHlpr.areEqualStrings(contractStatus, BOTH_COVERAGES)) {
      return this._bothPlansAfflns;
    }
    throw new Error(`Invalid contractStatus '${contractStatus}'`);
  }

  getAllContractsAfflns(): IAffliliationsModel {
    return {
      activeAffiliations: this._activePlanAfflns,
      futureAffiliations: this._futurePlanAfflns,
      bothAffiliations: this._bothPlansAfflns
    };
  }

  /**
   * When Single contract and single user scenario,
   * if the member's age is not within the supported age limit range of the provider,
   * then this method will throw an Error.
   *
   * In other cases this method will not throw any error.
   */
  verifyMbrAge() {
    if (this._mbrPcpDtlsApi.hasBothContracts) {
      return;
    }
    const contract = this._mbrPcpDtlsApi.apiResp.contracts?.[0];
    if (!contract) {
      return;
    }
    const mbrsModel = this._mbrPcpDtlsApi.getMembersModel(contract.contractStatus);
    if (mbrsModel.selectableMbrs.length === 1) {
      const mbr = mbrsModel.selectableMbrs[0];
      this.isWithinAgeRange(contract.contractStatus, mbr.ageAsOnPcpEffDt);
    }
  }

  /**
   * When Multi Contract (Active & Future)  & there is only one selectable member in the selected contract (Active, Future or Both).
   * In this scenario, if that member's age is not within the supported age limit range of the provider,
   * then this method will throw an Error.
   *
   * In other cases this method will not throw any error.
   * @param contractStatusCd
   */
  verifyMbrAgeByContractStatus(contractStatusCd: string) {
    if (this._dataHlpr.isEmptyString(contractStatusCd)) {
      throw new Error('Invalid contractStatusCd ' + contractStatusCd);
    }
    if (this._mbrPcpDtlsApi.isSingleContract) {
      //Call this method only when there are multiple contracts
      throw new Error('Invalid operation');
    }

    const mbrsModel = this._mbrPcpDtlsApi.getMembersModel(contractStatusCd);
    if (mbrsModel.selectableMbrs.length === 1) {
      const mbr = mbrsModel.selectableMbrs[0];
      this.isWithinAgeRange(contractStatusCd, mbr.ageAsOnPcpEffDt);
    }
  }

  verifyMbrAgeBySelectedMbr(contractStatusCd: string, mbrUid: string) {
    if (this._dataHlpr.isEmptyString(contractStatusCd)) {
      throw new Error('Invalid contractStatusCd ' + contractStatusCd);
    }
    if (this._dataHlpr.isEmptyString(mbrUid)) {
      throw new Error('Invalid member id ' + mbrUid);
    }

    const mbrsModel = this._mbrPcpDtlsApi.getMembersModel(contractStatusCd);
    const mbr = mbrsModel.selectableMbrs.find((mbr) => {
      return mbr.mbrUid === mbrUid;
    });
    if (!mbr) {
      throw new Error('Member not found in contract status ' + contractStatusCd);
    }
    this.isWithinAgeRange(contractStatusCd, mbr.ageAsOnPcpEffDt);
  }

  protected execute(request: IPcpInfoRequest): Promise<IPcpInfoResponse> {
    return this._pcpSvc.getProviderPcpInfo(request);
  }

  protected createAffiliation(value: string, label: string, contractStatus: string, counter: number): IAffiliationRadioInput {
    const id = `pcp-affln-rb-id-${contractStatus}-${counter}`;
    const name = `pcp-affln-rb-name-${contractStatus}-${counter}`;
    return { id, value, label, name, activePcpIds: [], futurePcpIds: [], contractStatus };
  }

  private isWithinAgeRange(contractStatus: string, ageAsOnPcpEffDt: number): boolean {
    if (ageAsOnPcpEffDt === undefined || ageAsOnPcpEffDt === null) {
      //When we dont receive the property ageAsOnPcpEffDt frlom API then do not check for age limit.
      return;
    }

    const excpn: IException = { code: ERR_MBR_AGE_NOT_IN_RANGE, message: '' };
    let isWithinRange = true;
    if (this._dataHlpr.areEqualStrings(contractStatus, BOTH_COVERAGES)) {
      const ageRange1 = this.getAllowedAgeRange(ACTIVE_COVERAGE);
      const ageRange2 = this.getAllowedAgeRange(FUTURE_COVERAGE);
      const isWithinRange1 = ageAsOnPcpEffDt >= ageRange1.lowAgeRangeValue && ageAsOnPcpEffDt <= ageRange1.highAgeRangeValue;
      const isWithinRange2 = ageAsOnPcpEffDt >= ageRange2.lowAgeRangeValue && ageAsOnPcpEffDt <= ageRange2.highAgeRangeValue;
      isWithinRange = isWithinRange1 && isWithinRange2;

      //Error message for debugging purpose
      const messages: string[] = [];
      if (!isWithinRange1) {
        messages.push(`Member Age-as-on-PCP-Effective-Date '${ageAsOnPcpEffDt}' is not within the range of Active Contract ${ageRange1.lowAgeRangeValue}-${ageRange1.highAgeRangeValue}.`);
      }
      if (!isWithinRange2) {
        messages.push(`Member Age-as-on-PCP-Effective-Date '${ageAsOnPcpEffDt}' is not within the range of Future Contract ${ageRange2.lowAgeRangeValue}-${ageRange2.highAgeRangeValue}.`);
      }
      excpn.message = messages.join(' ');

    } else {
      const ageRange = this.getAllowedAgeRange(contractStatus);
      isWithinRange = ageAsOnPcpEffDt >= ageRange.lowAgeRangeValue && ageAsOnPcpEffDt <= ageRange.highAgeRangeValue;

      //Error message for debugging purpose
      excpn.message = `Member Age-as-on-PCP-Effective-Date '${ageAsOnPcpEffDt}' is not within the range of Contract '${contractStatus}' ${ageRange.lowAgeRangeValue}-${ageRange.highAgeRangeValue}.`
    }
    if (!isWithinRange) {
      throw excpn;
    }
    return true;
  }

  /**
   * Return the allowed member Age Limit Range received from pcpInfo API based on given contract status code.
   * When there is no data received from API then return default values.
   * @param contractStatus
   * @returns
   */
  private getAllowedAgeRange(contractStatus: string): IMbrAgeLimitResp {
    const defaultRange = { lowAgeRangeValue: 0, highAgeRangeValue: 999 };
    const pcpInfos = this.apiResp?.pcpInfos ?? [];
    //When contractStatus is 'A' active or 'F' future
    const contract = this._mbrPcpDtlsApi.getContractByStatus(contractStatus);
    const isIndividual = this._dataHlpr.areEqualStrings(this._professional.providerCategoryCode?.code, PROVIDER_CATEGORY_CODE.Individual);
    const isPCPRequired = this._dataHlpr.areEqualStrings(contract.pcpIndicator, PCP_REQUIRED);
    const isWGS = this._dataHlpr.areEqualStrings(contract.sourceSystem, SOURCE_SYSTEM.WGS);
    const isISG = this._dataHlpr.areEqualStrings(contract.sourceSystem, SOURCE_SYSTEM.STAR);

    const canAgeVerificationPerformed = isIndividual && isPCPRequired && (isWGS || isISG);

    if (canAgeVerificationPerformed) {
      const pcpPlanInfo = pcpInfos.find((con) => {
        return con.contractUid === contract.contractUid;
      });
      return pcpPlanInfo?.allowedAgeRange ?? defaultRange;
    }
    return defaultRange;
  }
}
