import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ControlType } from '../../enums/controlType';
import { IWindow } from '../../interfaces/iWindow';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { ViewMoreLocationsComponent } from '../location/pfViewMoreLocationsCmp';
import { IAffiliationOption } from './interfaces/iAffiliationOption';

@Component({
  moduleId: module.id,
  selector: 'app-control-list',
  templateUrl: './controlListCmp.html'
})

export class ControlListComponent extends BaseComponent implements OnInit {

  @Input()
  options: any;
  @Input()
  controlType: ControlType;
  @Input()
  category: string;
  @Input()
  selectedItem: string = '00';
  @Input()
  customCSSClass: string = 'searchFilterItem';
  @Input()
  controlListData: any;

  @ViewChild('viewMoreLocationsModal')
  viewMoreLocationsModal: ViewMoreLocationsComponent;

  @Output()
  itemChange: EventEmitter<any> = new EventEmitter<any>();

  type = ControlType;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  onItemChange(data) {
    this.selectedItem = data;
    this.itemChange.emit(data);
  }

    /** Open Side Panel */
    openSidePanel(direction: string = 'right', controlListItem: IAffiliationOption, category: string) {
      this.viewMoreLocationsModal.openSidePanel(direction, controlListItem, category);
    }

  removeBracesForFilterItems(value: any) {
    return value.toString().slice(0, value.length - 1) + ' ' + this.content.summaryComponent.filter.providers + ')';
  }
}
