import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUserInteraction } from '../../../../../app/common/interfaces/iUserInteraction';
import { IActionReasonCodes } from '../../../../../app/fad/search-providers/interfaces/iQuickLinks';
import { CommonUtil } from '../../../../../app/fad/utilities/commonUtil';
import { AppNavigations } from '../../../constants/app-navigations';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { ContentHelper } from '../../../values/contentHelper';
import { BaseComponent } from '../../base-component/baseCmp';
import { FeedBackSidePanelComponent } from '../../feedback-sidepanel/feedbackSidePanelCmp';
import { ActionItemLookupService } from '../services/actionItemLookupService';
import { SIDE_PANEL_STATUS } from '../../../../../app/common/constants/app-constants';

export interface IPillCmpEvent {
  /** The chip the event was fired on. */
  chip: ActionItemCmp;
}

declare let _satellite: any;
declare let window: any;

@Component({
  selector: 'app-action-item',
  templateUrl: './actionItemCmp.html'
})
export class ActionItemCmp extends BaseComponent implements OnInit {
  @Output() readonly changePCP: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() readonly careItemDismiss: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output() readonly removeFromCareTeam: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output() readonly memberSvcChangePCP: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output() readonly bookAppointment: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Input() items: Array<string>;
  @Input() interactionPayload: IUserInteraction;
  @Input() actionReasonCodes: IActionReasonCodes;
  @Input() parentComponent: ActionItemCmp;
  @Input()
  get selected(): boolean { return this._selected; }
  set selected(value: boolean) {
    const coercedValue = coerceBooleanProperty(value);
    if (coercedValue !== this._selected) {
      this._selected = coercedValue;
    }
  }
  parentCmp = this;
  protected _removable: boolean = true;
  protected _selected: boolean = false;
  @ViewChild('feedbackSidePanel') feedbackSidePanel: FeedBackSidePanelComponent;
  isFeedbackAction = false;
  @Input() identifier = 'action-item-btn';
  @Input() dataAnalytics: string;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _elementRef: ElementRef,
    private _contentHelper: ContentHelper,
    private _actionItemLookupService: ActionItemLookupService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper,"PFSearchContainerComponent");
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.getItemsForReasonCodes();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (typeof this._elementRef.nativeElement !== 'undefined' && this._elementRef.nativeElement !== null &&
      typeof event !== 'undefined' && event !== null &&
      typeof event.target !== 'undefined' && event.target !== null &&
      !this._elementRef.nativeElement.contains(event.target) &&
      (event.target.className !== 'action-item-button')) {
      this._actionItemLookupService.destoryComponent();
    }
  }

  handleClick(event: Event) {
    this._selected = !this.selected;
    // remove ellipsis from focus
    if (this._selected && this.identifier) {
      const ellipsisButton = this._document.getElementById(this.identifier);
      ellipsisButton?.blur();
    }
    return this.selected;
  }

  onActionItemClick(item: string) {
    let path = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (window?.digitalData?.page?.pageInfo) {
      window.digitalData.page.pageInfo.FindCareTiles = this.dataAnalytics;
    }
    switch (item) {
      case this.content.newLandingComponent.actionItemsLabel.dismissOption: {
        this.careItemDismiss.emit();
        break;
      }
      case this.content.newLandingComponent.actionItemsLabel.removeFromCareTeam: {
        this.removeFromCareTeam.emit();
        break;
      }
      case this.content.newLandingComponent.actionItemsLabel.changePCP: {
        this.changePCP.emit();
        break;
      }
      case this.content.newLandingComponent.actionItemsLabel.memberSvcChangePCP: {
        this.memberSvcChangePCP.emit();
        break;
      }
      case this.content.newLandingComponent.actionItemsLabel.bookAppointment: {
        this.bookAppointment.emit();
        break;
      }
      case this.content.hideQuickLinkShopForProcedure.iAmInterested.name: {
        (path === AppNavigations.SEARCH_PROVIDERS_PATH)
          ? this.getDataAnalytics(
              this.content.hideQuickLinkShopForProcedure.iAmInterested
                .dataAnalytics.interestedOptionFindCare
            )
          : this.getDataAnalytics(
              this.content.hideQuickLinkShopForProcedure.iAmInterested
                .dataAnalytics.interestedOptionShopProcFindCare
            );
        this.feedbackSidePanel.openSlideOutPanel(this.actionReasonCodes.positive, true);
        break;
      }
      case this.content.hideQuickLinkShopForProcedure.notInterested.name: {
        (path === AppNavigations.SEARCH_PROVIDERS_PATH)
          ? this.getDataAnalytics(
              this.content.hideQuickLinkShopForProcedure.notInterested
                .dataAnalytics.notInterestedOptionFindCare
            )
          : this.getDataAnalytics(
              this.content.hideQuickLinkShopForProcedure.notInterested
                .dataAnalytics.notInterestedOptionShopProcFindCare
            );
        this.feedbackSidePanel.openSlideOutPanel(this.actionReasonCodes.negative, false);
        break;
      }
    }
  }

  getAriaLabel(): string {
    if (this.items) {
      const ariaLabel = this.content.newLandingComponent.ariaLabel.actionItemButton.
      replace(/{ACTION}/gi, this.items);
    return ariaLabel;
    }
  }

  getItemsForReasonCodes() {
    let reasonCodeItems: Array<string> = [];
    if (this.actionReasonCodes) {
      if (this.actionReasonCodes?.positive?.length) {
        reasonCodeItems.push(this.content.hideQuickLinkShopForProcedure.iAmInterested.name);
      }
      if (this.actionReasonCodes?.negative?.length) {
        reasonCodeItems.push(this.content.hideQuickLinkShopForProcedure.notInterested.name);
      }
      this.isFeedbackAction = CommonUtil.isNonEmptyArrayOfStrings(reasonCodeItems);
      this.items = reasonCodeItems;
    }
  }

  getDataAnalytics(analyticsTag: string) {
    if (typeof _satellite !== "undefined") {
      _satellite.track(analyticsTag);
    }
  }

  onFeedbackSidePanelStatusChange(status: SIDE_PANEL_STATUS): void {
    if (this.identifier && status === SIDE_PANEL_STATUS.CLOSE) {
      const ellipsisButton = this._document.getElementById(this.identifier);
      ellipsisButton?.focus();
    }
  }
}
