import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { IUrlParam } from '../../../common/interfaces/iUrlParam';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { FINDCARE_PAGE, NBADESTINATION } from '../enums/personalizationEnums';
import { BaseService } from './../../../common/services/baseService';
import {
  IPersonActionRequest,
  IPersonActions,
  IPersonFeedbackToAction
} from './../interfaces/iPersonalizationChecklist';
@Injectable()
export class PersonalizationChecklistSvc extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }
  /**
   * Pull Active recommended SEEDs , Acknowledge or Dismiss SEED.
   * @param httpMethod - GET - Pull Acrive Recommended SEEDs | POST -  Aknowledge or Dismiss SEED  (CTA)
   * @param body Contains the actionId being aknowledged or dismissed.
   */
  public getActions(body: IPersonActionRequest): Promise<IPersonActions> {
    const urlParams: IUrlParam[] = [
      { name: NBADESTINATION, value: FINDCARE_PAGE, isQueryParam: true },
    ];

    return this.httpClientSvc.request({
      cacheable: false,
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.personalizedActions,
      data: body,
      urlParams,
      headers: this.getHeaders()
    });
  }

  /**
   * Acknowledge or Dismiss SEED.
   * @param body Contains the actionId being aknowledged or dismissed.
   */
  public updateActionApi(body: IPersonFeedbackToAction) {
    return this.httpClientSvc.request({
      cacheable: false,
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.personalizationUpdateAction,
      data: body,
      headers: this.getHeaders()
    });
  }
}
