import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ITabDisclaimer } from '../../../interfaces/iDisclaimer';
import { BaseComponent } from './../../../../common/components/core/baseCmp';

@Component({
  selector: 'app-cost-disclaimers-tab',
  moduleId: module.id,
  templateUrl: './costDisclaimersTabCmp.html'
})
export class CostDisclaimersTabCmp extends BaseComponent implements OnInit {
   @Input() tabData: ITabDisclaimer;
   @Input() adobeTag:string;

  constructor(private _eventHandler: EventHandler,
              @Inject(AppSession)
              private _appSession: AppSession,
              private _route: ActivatedRoute) {
    super(_route, _eventHandler, _appSession);

  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  toggleDisclaimer(data) {
    data.isVisible = !data?.isVisible;
  }
}
