import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ICptLookUpList } from '../interfaces/iCptLookup';
import { ILookUpRequest } from '../interfaces/iLookUpRequest';

@Injectable({
  providedIn: 'root'
})
export class CptLookupService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getCptLookupList(reqObj: ILookUpRequest): Promise<ICptLookUpList> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.cptLookup,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
