import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-fad-provider-recognitions-cmp',
  templateUrl: '../views/pfRecognitionsCmp.html'
})
export class PFRecognitionsComponent extends BaseComponent implements OnInit {
  @Input()
  recognition: any;
  @Input()
  showTier: boolean = false;
  isImgLoadError: boolean = false;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  getRecognitionImageURL(filename: string) {
    return this.getQualityImageURL(filename);
  }

  decodeHTML(html) {
    return this.WcsUtility.decodeHTML(html);
  }
}
