import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { LeavingDisclaimerComponent } from './leavingDisclaimerCmp';

@NgModule({
    declarations: [LeavingDisclaimerComponent],
    exports: [LeavingDisclaimerComponent],
    imports: [
        UxSharedModule,
        CommonModule
    ],
    providers: []
})
export class LeavingDisclaimerModule { }
