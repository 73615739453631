import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhonePatternPipe } from '../../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from './../../../../common/components/core/baseCmp';
import { IProvider } from './../../../../result/interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-phone-cmp',
  templateUrl: './providerPhoneCmp.html'
})
export class ProviderPhoneComponent extends BaseComponent {
  @Input() provider: IProvider;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _phonePatternPipe: PhonePatternPipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Formats the given phone number using the PhonePatternPipe.
   * @param phone The phone number to format.
   * @returns The formatted phone number.
   */
  formatPhoneNumber(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }
}
