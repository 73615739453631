import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../common/values/appSession';
import { CareTeamActionType } from '../../../../../../findcare/common/constants/common';
import { BaseComponent } from '../../../core/baseCmp';
import { ActionItemCmp } from './actionItemCmp';

@Component({
  selector: 'app-fc-action-item-look-up-cmp',
  templateUrl: './actionItemLookupCmp.html'
})
export class ActionItemLookupComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() parentComponent: ActionItemCmp;
  @Input() items: Array<string> = [];
  @Input() isFeedbackMenu = false;
  @Output() readonly actionItemClick: EventEmitter<string> = new EventEmitter<string>();
  feedBackType = this.content?.common?.hideQuickLinkShopForProcedure;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  /**
   * Method to handle the getDataAnalytics for the action items
   * @param items - action items label
   * @returns - string - analytics data
   */
  getDataAnalytics(items: string): string {
    const actionItemsLabel = this.content?.landing?.careTeams?.actionItemsLabel;
    const analyticsMap: { [key: string]: string } = {
      [actionItemsLabel?.removeFromCareTeam]: CareTeamActionType.REMOVE_CARE_TEAM_MENU_FIND_CARE,
      [actionItemsLabel?.changePCP]: CareTeamActionType.CHANGE_PCP_TEAM_MENU_FIND_CARE,
      [actionItemsLabel?.memberSvcChangePCP]: CareTeamActionType.CHANGE_PCP_TEAM_MENU_FIND_CARE,
      [actionItemsLabel?.bookAppointment]: CareTeamActionType.BOOK_APPT_TEAM_MENU_FIND_CARE
    };

    return analyticsMap[items] || '';
  }

  /**
   * Method to handle the moreOptionClick for the action items
   * @param item - action items label
   * @returns - void
   * @emits - actionItemClick
   */
  moreOptionClick(item: string) {
    this.actionItemClick.emit(item);
  }

  /**
   * Method to handle the ngOnDestroy for the action items
   * @returns - void
   * @emits - selected - boolean value to check if the action item is selected or not selected
   */
  ngOnDestroy() {
    this.parentComponent.selected = this.parentComponent ? false : true;
  }
}
