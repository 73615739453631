import { CommonModule, TitleCasePipe } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UxSharedModule } from "@anthem/uxd-shared";
import { SsoModule } from './../common/components/sso/ssoModule';
import { PFVirtualCareContainerComponent } from './components/pfVirtualCareContainerCmp';
import { PFVirtualCareLandingComponent } from './components/pfVirtualCareLandingCmp';
import { PFVirtualCareRecentActivityComponent } from './components/pfVirtualCareRecentActivityCmp';


@NgModule({
  imports: [
    UxSharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SsoModule,
  ],
  declarations: [
    PFVirtualCareContainerComponent,
    PFVirtualCareLandingComponent,
    PFVirtualCareRecentActivityComponent,
  ],
  exports: [
    PFVirtualCareContainerComponent,
    PFVirtualCareLandingComponent,
    PFVirtualCareRecentActivityComponent,
  ],
})

export class PFVirtualCareModule {
  static forRoot(): ModuleWithProviders<PFVirtualCareModule> {
    return {
      ngModule: PFVirtualCareModule,
      providers: [TitleCasePipe]
    };
  }
}
