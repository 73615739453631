import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../common/values/contentHelper';
import { ACCOUNT_TYPE } from '../../../../common/constants/common';
import { ICostDisclaimer, ITabDisclaimer, ITabView } from '../../../interfaces/iDisclaimer';
import { BaseComponent } from './../../../../common/components/core/baseCmp';

@Component({
  selector: 'app-cost-disclaimers',
  templateUrl: './costDisclaimersContainerCmp.html'
})
export class CostDisclaimersContainerCmp extends BaseComponent implements OnInit{
  @Input() costDisclaimers: ICostDisclaimer;
  @Input() isEycDetails: boolean = false;
  @Input() adobeTag: string;

  tabView: ITabView = {
    tabs: [],
    color: 'teal'
  }
  dataDisclaimersArr : Array<string> =[]

  constructor(
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this.tabView.tabs = Object.assign([], this.costDisclaimers?.tabDisclaimer || []);
    this.tabView.tabs = this.tabView.tabs.map((tab:ITabDisclaimer, index:number) => {
      tab.isActive = index === 0;
      tab.isVisible = false;
      return tab;
    });

    this.dataDisclaimersArr = this.costDisclaimers?.dataDisclaimer || [];
  }

  ngAfterViewInit() {
    this.addAriaLabelTab();
  }

  addAriaLabelTab() {
    if (this.costDisclaimers) {
      //TabDisclaimers
      const tabTitle = document.querySelector('#costDisclaimersTab').querySelectorAll('.viewButton');
      if (tabTitle && tabTitle !== undefined && tabTitle.length > 0) {
        for (let i = 0; i < tabTitle.length; i++) {
          tabTitle[i].setAttribute('aria-label', this.tabView.tabs[i]?.heading || '');
        }
      }
      //Data Disclaimers
      const dataDiscMsg = Array.from(document.getElementsByClassName('cost-disclaimer-msg'));

      dataDiscMsg.forEach((_, index) => {
        const costDisclaimer = document.getElementById('cost-disclaimer' + index);
        costDisclaimer?.childNodes.forEach((costDisc:any ) => {
          if (costDisc && !costDisc.ariaLabel) {
            const text = this.costDisclaimerAriaLabel(costDisc.innerText);
            costDisc.ariaLabel = text;
          }
        });
      });
    }
  }

  costDisclaimerAriaLabel(disclaimer: string) {
    if (disclaimer && ((disclaimer.indexOf(ACCOUNT_TYPE.HIA) > -1) || (disclaimer.indexOf(ACCOUNT_TYPE.HRA) > -1) || (disclaimer.indexOf(ACCOUNT_TYPE.HSA) > -1))) {
     disclaimer= disclaimer.replace(new RegExp(ACCOUNT_TYPE.HIA, 'g'), 'H I A')
        .replace(new RegExp(ACCOUNT_TYPE.HRA, 'g'), 'H R A')
        .replace(new RegExp(ACCOUNT_TYPE.HSA, 'g'), 'H S A')
    }
    return disclaimer;
  }
}
