import { Inject, Injectable } from '@angular/core';
import { Locale } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { IOutputFlag } from '../../common/interfaces/iOutputFlag';
import { ProviderAffiliationService } from '../../common/services/providerAffiliationSvc';
import { IProvider } from '../interfaces/iProvider';
import { IAffiliationProvider, IAffiliationRequest, IAffiliationResponse } from '../interfaces/iProviderAffiliation';

@Injectable({
  providedIn: 'root'
})
export class ProviderAffiliationHandler {
  locale: string;

  constructor(
    private _providerAffiliationSvc: ProviderAffiliationService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  async getProviderAffiliation(provider: IProvider): Promise<Map<string, IAffiliationProvider[]>> {
    try {
      const affiliationRequest = this.buildRequest(provider);
      const apiResponse = await this._providerAffiliationSvc.getProviderAffiliation(affiliationRequest);
      return this.filterAffiliations(apiResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private buildRequest(provider: IProvider): IAffiliationRequest {
    const { searchParams, metaData, isMemberMedicalGrpSearch, primeGroupAffiliations } = this._appSession;
    const alphaPrefix = searchParams.plan?.alphaPrefix ? [searchParams.plan.alphaPrefix] : [];
    const locale = metaData.locale?.toString() || Locale.ENGLISH.toString();

    const affiliationRequest: IAffiliationRequest = {
      providerIdentifier: provider?.providerIdentifier,
      addressIdentifier: provider?.addressSummary?.addressIdentifier,
      networkList: searchParams.plan?.networkList || [],
      alphaPrefixList: alphaPrefix,
      providerTypeCodeList: provider.providerTypeCodeList,
      locale: locale
    };

    if (metaData.appContract) {
      affiliationRequest.mbrUid = metaData.appContract.mbrUid;
      affiliationRequest.contractUid = metaData.appContract.contractUid;
      if (isMemberMedicalGrpSearch && primeGroupAffiliations?.affiliationList?.length) {
        affiliationRequest.outputFlags = { includePrimeGroupSearch: true } as IOutputFlag;
      }
    }

    return affiliationRequest;
  }

  private filterAffiliations(affiliations: IAffiliationResponse): Map<string, IAffiliationProvider[]> {
    const affiliationMap = new Map<string, IAffiliationProvider[]>();

    (affiliations?.providerList || []).forEach((provider) => {
      provider.provTypeCodeList.forEach((type) => {
        if (!affiliationMap.has(type.name)) {
          affiliationMap.set(type.name, []);
        }
        affiliationMap.get(type.name)?.push(provider);
      });
    });

    return affiliationMap;
  }
}
