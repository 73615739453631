import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { ISearchFilterRequest } from '../../../common/constants/strategy-search-constant';
import { HttpClientService } from '../../../common/services/httpClientService';
import { FindCareFeature } from '../../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../../findcare/common/utilities/featureManagerUtil';
import { IProviderV1LookupRequest } from '../../search-providers/interfaces/iProviderV1LookupRequest';
import { IProviderV1LookupResponse } from '../../search-providers/interfaces/iProviderV1LookupResponse';
import { IDynamicFilters } from '../interfaces/iDynamicFilters';
import { ISearchRequest } from '../interfaces/iSearchRequest';
import { ISummaryAPIResp } from '../interfaces/iSummaryResp';
import { apiNameList } from './../../../../environments/api-name-list';
import { HttpMethod } from './../../../common/enums/httpMethodEnum';
import { BaseService } from './../../../common/services/baseService';
import { MessagingHandler } from './../../../common/services/messagingHandler';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { IProviderLookup } from './../../search-providers/interfaces/iProviderLookup';
/**
 * Provider code list service will return provider types, languages, etinicities,
 * alpha prefix, patient preferences, areas of expertise and WLP states.
 */
@Injectable()
export class ProviderSummaryService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    _appSession: AppSession,
    _appUtility: AppUtility,
    private _messagingHandler: MessagingHandler
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }
  getProfessionals(reqObj: any): Promise<ISummaryAPIResp> {
    const header = this.getHeaders();
    if (FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_EVENT_INTERACTION, this.appSession)) {
      this._messagingHandler.postSearchMessage(reqObj);
    }

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.summary,
      data: reqObj,
      headers: header
    });
  }

  getGeoTravelTime(reqObj: any): Promise<any> {
    const header = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.traveltime,
      data: reqObj,
      headers: header
    });
  }

  getAffiliationOptions(reqObj: any): Promise<any> {
    const header = this.getHeaders();

    const _url = this.baseURL + apiNameList.restApi.affiliationOptions;

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: _url,
      data: reqObj,
      headers: header
    });
  }

  getProviderOptions(reqObj: any): Promise<any> {
    const header = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerOptions,
      data: reqObj,
      headers: header
    });
  }

  getProviderLookUp(providerLookupReq: ISearchRequest): Promise<IProviderLookup> {
    const header = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerLookUp,
      data: providerLookupReq,
      headers: header
    });
  }

  getPrecareProviderLookUp(providerLookupReq: IProviderV1LookupRequest): Promise<IProviderV1LookupResponse> {
    const header = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerLookupV1,
      data: providerLookupReq,
      headers: header
    });
  }

  getProviderFeatureLookUp(reqObj: any): Promise<any> {
    const header = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerFeatureLookUp,
      data: reqObj,
      headers: header
    });
  }

  getFilterGroups(reqObj: any): Promise<any> {
    const header = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.summaryFilter,
      data: reqObj,
      headers: header
    });
  }

  getDynamicFilters(reqObj: ISearchFilterRequest): Promise<IDynamicFilters> {
    const header = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.dynamicFilter,
      data: reqObj,
      headers: header
    });
  }

  /**
   * To get the timezone from the client
   * example return as "America/New_York"
   */
  private getTimeZone(): string {
    return moment.tz.guess();
  }
}
