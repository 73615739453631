import { ChangeDetectorRef, Component, Input, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioInputGroupComponent } from '@anthem/uxd/radio';
import { IRadioGroupModel, IRadioGroupOption } from './iRadioItem';

/**
 * Extended version of data-uxd-radio-input-group-cmp
 */

@Component({
  selector: '[app-radio-group-cmp]',
  template: ` <fieldset class="pfRadio">
                <legend [id]="'lgnd_'+radioGroup.id" class="" [class.ant-span-label]="radioGroup.showLegendAsLbl" [class.sr-only]="radioGroup.legendSrOnly" [innerHTML]="radioGroup.label"></legend>
                  <div [class.align-vertical]="radioGroup.alignVertical">
                  <ng-container *ngFor="let o of radioGroup.options; let pIdx = index;">
                    <input [attr.aria-label]="o.ariaLabel" (focus)="onItemFocus(o)" (blur)="onItemBlur(o)" (click)="onItemClick(o)" [checked]="o.isSelected" class="prOption" type="radio" [name]="radioGroup.name" [id]="radioGroup.id+pIdx" [value]="o.value" [attr.value]="o.value" [attr.data-analytics] = "o.analytics" [disabled]="o.disabled"/>
                    <ng-container *ngIf="!radioGroup.templateRef">
                      <label [class.focus]="o.isFocused" [class.disabled]="o.disabled" [id]="'lbl_'+radioGroup.id+pIdx" [class.active]="o.isSelected"  class="prLabel" [attr.for]="radioGroup.id+pIdx" [innerHTML]="o.label" [attr.data-analytics] = "o.analyticsText"></label>
                    </ng-container>
                    <ng-container *ngIf="radioGroup.templateRef">
                      <label [class.focus]="o.isFocused" [id]="'lbl_'+radioGroup.id+pIdx" [class.active]="o.isSelected"  class="prLabel" [attr.for]="radioGroup.id+pIdx" [attr.data-analytics] = "o.analyticsText">
                        <ng-container *ngTemplateOutlet="radioGroup.templateRef; context: {context: o.context}"></ng-container>
                      </label>
                    </ng-container>
                  </ng-container>
                  </div>
                  <input class="sr-only" [disabled]="disabled" *ngIf="radioGroup.isOptional" (focus)="onOptionalFocus()" [checked]="optionalSelected" class="prOption" type="radio" [name]="radioGroup.name" [id]="radioGroup.id+'_opt'" [value]="" [attr.value]="" />
              </fieldset>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ]
})
export class RadioGroupComponent extends RadioInputGroupComponent {
  @Input()
  radioGroup: IRadioGroupModel<IRadioGroupOption> = {
    id: 'radio-group',
    name: 'radio-group',
    label: '',
    ariaLabel: '',
    options: [],
    isOptional: false,
    legendSrOnly: false,
    showLegendAsLbl: false,
    alignVertical: false,
    templateRef: undefined
  };

  constructor(renderer: Renderer2, private _cdr: ChangeDetectorRef) {
    super(renderer);
  }

  writeValue(v: unknown) {
    super.writeValue(v);
    this._cdr.detectChanges();
  }
}
