import { Component, Inject, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IServiceLimit } from "../../../../../common/interfaces/iCptMedicalResponse";
import { EventHandler } from "../../../../../common/services/eventHandler";
import { AppSession } from "../../../../../common/values/appSession";
import { IProviderList } from "../../../../result/interfaces/iProvider";
import { BaseComponent } from "../../core/baseCmp";


@Component({
  selector: 'app-service-limit-cmp',
  templateUrl: './procServiceLimitCmp.html'
})
export class ProcServiceLimitCmp  extends BaseComponent {

  @Input() cptSearchResult: IProviderList;
  @Input() serviceLimits: Array<IServiceLimit>;
  constructor(
    route: ActivatedRoute, eventHandler: EventHandler, @Inject(AppSession)
    private _appSession: AppSession) {
    super(route, eventHandler, _appSession);
  }

}
