import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  selector: 'app-look-up-container-cmp',
  templateUrl: './lookUpContainerCmp.html'
})
export class LookUpContainerCmpComponent extends BaseComponent implements OnInit {

  @Input() parentWidth: HTMLElement;
  @Input() customCSS: string = '';
  @Input() warningMessage: boolean = false;
  warningMessageData:string
  boxWidth: number;

  constructor(
    @Inject(AppSession)
    _appSession: AppSession,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    this.warningMessageData = this.content.searchCriteriaComponent.alerts.zipcodeEmptyError;
    if (this.waitUntilAppReload) {
      return;
    }

    if (typeof this.parentWidth !== 'undefined' && this.parentWidth !== null) {
      this.boxWidth = this.parentWidth.clientWidth + 25;
    }
  }

}
