import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { AppExternalCommands } from '../../../../../common/constants/app-constants';
import { AppEvents } from '../../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../../common/interfaces/iEventDetail';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../common/values/contentHelper';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-email-cmp',
  templateUrl: './emailCmp.html'
})
export class EmailComponent extends BaseComponent implements OnInit {
  @ViewChild('fcEmailModal')
  emailModal: ModalComponent;
  emailAddress = '';
  errorText: string;
  emailError: boolean = false;
  emailStatus: boolean = false;
  emailMessageSent: string = '';
  private _pfAppCommandEvent: EventEmitter<
    IEventDetail
    > = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  @Input() emailSearchNote: string = '';
  @Input() modalHeader: string = '';

  @Output()
  sendEmail = new EventEmitter<string>();

  private get emailRegex(): RegExp {
    return new RegExp(
      // tslint:disable-next-line:max-line-length
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper
  ) {
    super(
      _route,
      _eventHandler,
      _appSession
    );
  }
  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }
  onSendEmail() {
    //To do email sending logic
    this.emailMessageSent = this.content?.common?.alerts?.emailSending;
    this.validateEmail();

    if (this.emailError) {
      return;
    }
    this.emailStatus = true;
    this.emitEmail();
    this.sendEmail.emit(this.emailAddress);
  }
  validateEmail() {
    const email = this.emailAddress = this.emailAddress.trim();
    if (!email || email === '') {
      this.errorText = this.content.common.alerts.emailAddress;
      this.emailError = true;
    } else {
      if (email !== '' && !this.emailRegex.test(email)) {
        this.errorText = this.content.common.alerts.invalidemailAddress;
        this.emailError = true;
      } else {
        this.emailError = false;
      }
    }
  }

  show() {
    this.clear();
    if (typeof this.emailModal.config === 'undefined') {
      this.emailModal.config = { backdrop: false };
    }
    this.emailModal.show();
  }
  hide() {
    this.emailModal.hide();
    this.clear();
  }
  clear() {
    this.emailAddress = this.errorText = '';
    this.emailError = this.emailStatus = false;
  }
  emitEmail() {
    const objEmail = {
      email: this.emailAddress.trim()
    };

    const eventDetail: IEventDetail = {
      object: objEmail,
      type: AppEvents[AppEvents.PF_APP_CMD_EMAIL],
      target: AppExternalCommands.PF_APP_CMD_EMAIL.CMD,
      message: AppExternalCommands.PF_APP_CMD_EMAIL.MSG,
      details: AppExternalCommands.PF_APP_CMD_EMAIL.MSG,
      errorCode: null,
    };

    this._pfAppCommandEvent.emit(eventDetail);
  }
}
