import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from '../../../common/constants/app-constants';
import { PROVIDER_TYPE } from '../../search-providers/values/providerSearchConstants';
import { TravelTimeRequest } from '../../search-results/interfaces/iSearchRequest';
import { IDetailsParameters } from '../models/iDetailsParameters';
import { ILocation } from '../models/iLocation';
import { ILocationAddress } from '../models/iLocations';
import { IPlanList } from '../models/iPlanList';
import { ProviderOrchestrationService } from '../services/providerOrchestrationSvc';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { AppEvents } from './../../../common/enums/appEvents';
import { PhonePatternPipe } from './../../../common/pipes/phonePatternPipe';
import { CommmunicationService } from './../../../common/services/communicationSvc';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { RETAIL_PHARMACY } from './../../search-providers/values/providerSearchConstants';

@Component({
  moduleId: module.id,
  selector: 'app-fad-provider-locations-cmp',
  templateUrl: '../views/pfLocationsCmp.html'
})
export class PFLocationsComponent extends BaseComponent implements OnInit {
  @Input()
  showLocationsLoader: boolean = true;
  @Input()
  showLocationsError: boolean;
  @Input()
  selectedProvider: any;
  @Output()
  openDomain: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  locationData: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  selectedAddressId?: any;
  @Input()
  activeFlagList: Array<string> = [];
  locations: any = [];
  @Input()
  locationsList: Array<ILocationAddress>;
  mapLocations: any = [];
  selectedAddress: string = '';
  isEycSearch: boolean = false;
  isShowDistance: boolean = true;
  isMemberSecure: boolean = false;
  detailVisibility: any;
  providerLocations: any = [];
  isAdminOffice: boolean = false;
  travelTime: number;
  private _mapDataEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.MAP_DATA_LOADED]);
  private _driveDirectionEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.SHOW_DRIVE_DIRECTION]);
  constructor(
    private _providerOrchestrationService: ProviderOrchestrationService,
    private _commmunicationService: CommmunicationService,
    private _phonePatternPipe: PhonePatternPipe,
    private _appUtility: AppUtility,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    if (this._appSession) {
      this.isShowDistance =
        this._appSession &&
        this._appSession.searchParams &&
        this._appSession.searchParams.coordinates &&
        ((this._appSession.searchParams.coordinates.latitude === '0' && this._appSession.searchParams.coordinates.longitude === '0') ||
          (this._appSession &&
            this._appSession.searchParams &&
            typeof this._appSession.searchParams.coordinates.latitude === 'undefined' &&
            typeof this._appSession.searchParams.coordinates.longitude === 'undefined'))
          ? false
          : true;
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    }
  }
  ngOnChanges(args: any) {
    if (args['selectedAddressId'] && args['selectedAddressId'].currentValue !== args['selectedAddressId'].previousValue) {
      this.filterLocations(args['selectedAddressId'].currentValue);
    }
    if (args['activeFlagList'] && args['activeFlagList'].currentValue !== args['activeFlagList'].previousValue) {
      this.administrativeOffice(args['activeFlagList'].currentValue);
    }
  }
  buildDetailsRequest() {
    const loc: ILocation = {
      latitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.latitude : '',
      longitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.longitude : ''
    };
    const plan: IPlanList = {
      identifier: this._appSession.searchParams.plan && this._appSession.searchParams.plan.identifier ? this._appSession.searchParams.plan.identifier.trim() : null,
      isNationalPlan: this._appSession.searchParams.plan.isNationalPlan
    };
    const netwrkList: any[] = [];
    for (const network of this._appSession.searchParams.plan.networkList || []) {
      const net = {
        code: network
      };
      netwrkList.push(net);
    }
    const detailsParams: IDetailsParameters = {
      providerIdentifier: this.selectedProvider.providerIdentifier,
      addressIdentifier: this.selectedAddress,
      brandCode: this._appSession.metaData.brandCd,
      state: undefined,
      distance: this._appSession.searchParams.distanceNm ? parseInt(this._appSession.searchParams.distanceNm.trim(), 10) : 0,
      planStateCode: this._appSession.searchParams.plan.stateCd,
      rxChoiceNtwkIndicator: '',
      specialtyCategoryList:
        this._appSession.searchParams.specialtySelectNm && this._appSession.searchParams.specialtySelectNm.length > 0
          ? this._appSession.searchParams.specialtySelectNm.filter((x) => x !== '00')
          : null,
      networkList: netwrkList,
      alphaPrefix: CommonUtil.getAlphaPrefix(this._appSession),
      location: loc,
      locale: (this._appSession?.metaData?.locale || '').toString() || Locale.ENGLISH,
      planList: new Array(plan)
    };

    if (this.selectedProvider.providerTypeCodeList) {
      detailsParams.providerTypeCodeList = this.selectedProvider.providerTypeCodeList;
    }

    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
      detailsParams.mbrUid = this._appSession.metaData.appContract.mbrUid;
    }

    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid) {
      detailsParams.contractUid = this._appSession.metaData.appContract.contractUid;
    }

    // Add aditional parameters for CVS Pharmacy API.
    if (this.selectedProvider.providerTypeCodeList && this.selectedProvider.providerTypeCodeList.length === 1 && this.selectedProvider.providerTypeCodeList[0] === PROVIDER_TYPE.PHARMACY) {
      detailsParams.providerTypeCode = PROVIDER_TYPE.PHARMACY;
      detailsParams.isShipDirectToDoor = this.selectedProvider.isShipDirectToDoor;
    }
    if (detailsParams && detailsParams.providerTypeCode === PROVIDER_TYPE.PHARMACY && this._appSession.searchParams.coverageTypeCode === RETAIL_PHARMACY) {
      detailsParams.pharmacyName = this.selectedProvider.providerName;
      detailsParams.pharmacyNumber = this.selectedProvider.providerIdentifier;
      detailsParams.coverageTypeCode = this._appSession.searchParams.coverageTypeCode;
      let location = this.selectedProvider.location;
      if (location && location.address && location.address.state) {
        detailsParams.state = location.address.state;
      } else if (this._appSession.searchParams.stateCd && this._appSession.searchParams.stateCd.code) {
        detailsParams.state = this._appSession.searchParams.stateCd.code;
      }

      if (location && location.address && location.address.postalCode) {
        detailsParams.zipCode = location.address.postalCode;
      } else if (this._appSession.searchParams.zip) {
        detailsParams.zipCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
      }

      if (location && location.address && location.address.city) {
        detailsParams.city = location.address.city;
      } else if (this._appSession.searchParams.city) {
        detailsParams.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
      }
    } else {
      //Need to add this info for constructing redis cache for location details
      if (this._appSession.searchParams.stateCd && this._appSession.searchParams.stateCd.code) {
        detailsParams.state = this._appSession.searchParams.stateCd.code;
      }
      if (this._appSession.searchParams.zip) {
        detailsParams.zipCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
      }
      if (this._appSession.searchParams.city) {
        detailsParams.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
      }
    }
    if ((CommonUtil.isMemberSecure(this._appSession) || this._appSession?.searchParams?.identificationNumber) && this._appSession?.isFutureCoverage) {
      detailsParams.isFutureCoverage = this._appSession?.isFutureCoverage;
    }
    return detailsParams;
  }

  get showProviderServiceLocationName(): boolean {
    return this._appSession?.feature?.showServiceLocationName || false;
  }

  // Get travelTime based on users lat/long and provider lat/long
  getTravelTime(result: any, providerName: string) {
    try {
      let travelTimeRequest: TravelTimeRequest = new TravelTimeRequest();
      if (
        this._appSession.searchParams &&
        this._appSession.searchParams.coordinates &&
        this._appSession.searchParams.coordinates.latitude &&
        this._appSession.searchParams.coordinates.latitude.toString() !== '0' &&
        this._appSession.searchParams.coordinates.latitude.toString() !== '' &&
        this._appSession.searchParams.coordinates.longitude &&
        this._appSession.searchParams.coordinates.longitude.toString() !== '0' &&
        this._appSession.searchParams.coordinates.longitude.toString() !== ''
      ) {
        travelTimeRequest.origins = this._appSession.searchParams.coordinates.latitude + ',' + this._appSession.searchParams.coordinates.longitude;
      }
      travelTimeRequest.destinations = '';
      if (result && result.length) {
        result.map((location, index) => {
          if (location && location.latitude && location.latitude !== '' && location.longitude && location.longitude !== '') {
            let locationlatlong = parseFloat(location.latitude).toString() + ',' + parseFloat(location.longitude).toString();
            // binding distinct destinations lat/long
            if (!(travelTimeRequest.destinations.indexOf(locationlatlong) > -1)) {
              let seperator = index > 0 ? ';' : '';
              travelTimeRequest.destinations += seperator;
              travelTimeRequest.destinations += locationlatlong;
            }
          }
        });
        this.bindLocationTravelTime(result, providerName);
        if (travelTimeRequest.origins && travelTimeRequest.origins !== '' && travelTimeRequest.destinations && travelTimeRequest.destinations !== '') {
          this._providerOrchestrationService.getGeoTravelTime(travelTimeRequest).then(
            (travelTimeResp: any) => {
              if (travelTimeResp && travelTimeResp.travelDuration && travelTimeResp.travelDuration.length > 0) {
                let drivingDurationResp = this.bindTravelTime(travelTimeResp.travelDuration, result);
                this.bindLocationTravelTime(drivingDurationResp, providerName);
              } else {
                this.bindLocationTravelTime(result, providerName);
              }
            },
            (error: any) => {
              this.bindLocationTravelTime(result, providerName);
            }
          );
        } else {
          this.bindLocationTravelTime(result, providerName);
        }
      }
    } catch (error) {
      this.bindLocationTravelTime(result, providerName);
    }
  }

  bindLocationTravelTime(result: any, providerName: string) {
    let drivingDurationResp = result;
    this.providerLocations = drivingDurationResp;
    this.onLocationsSuccess(drivingDurationResp);
    const locationObj = CommonUtil.buildMapDataFromLocation(providerName, drivingDurationResp ? drivingDurationResp : []);
    this._mapDataEvent.emit(CommonUtil.setMapRequest(locationObj, true, this.showSPFI));
  }

  bindTravelTime(travelTimeResp: any, result: any): any {
    result.map((location) => {
      if (location && location.latitude && location.latitude !== '' && location.longitude && location.longitude !== '') {
        const matchedResult = travelTimeResp.filter((resp) => {
          return resp.latitude.toString() === parseFloat(location.latitude).toString() && resp.longitude.toString() === parseFloat(location.longitude).toString();
        });
        if (matchedResult && matchedResult.length > 0) {
          this.travelTime = matchedResult[0].travelTime;
        } else {
          this.travelTime = 0;
        }
      }
    });
    return result;
  }

  filterLocations(changedAddressId) {
    const addrId = (this.selectedProvider.location && this.selectedProvider.location.address && this.selectedProvider.location.address.addressId) || '';
    this.selectedAddress = changedAddressId === '' ? addrId : changedAddressId;
    // const provierName = this.selectedProvider.providerName ? this.selectedProvider.providerName : '';
    if (this.providerLocations && this.providerLocations.length > 0) {
      this.showLocationsLoader = false;
      this.locations = this.providerLocations.filter((x) => {
        return x.addressId === this.selectedAddress;
      });
      /* const locationObj = CommonUtil.buildMapDataFromLocation(provierName, this.providerLocations);
      this._mapDataEvent.emit(CommonUtil.setMapRequest(locationObj, true)); */
      this.setLocations();
      this.locationData.emit(this.locations);
      this.administrativeOffice(this.activeFlagList);
    } else {
      this.getLocations(this.selectedAddress);
    }
  }
  onLocationsSuccess(result: any) {
    this.showLocationsLoader = false;
    if (result == null) {
      this.showLocationsError = true;
      return;
    }
    this.setLocations();
    this.locations = this.providerLocations.filter((x) => {
      return x.addressId === this.selectedAddress;
    });
    this.detailVisibility = result.visibilityRule;
    this.administrativeOffice(this.activeFlagList);
    this.locationData.emit(this.locations);
  }

  onError(error: any) {
    this.showLocationsLoader = false;
    this.showLocationsError = true;
    throw error;
  }

  formatAfterHoursPhone(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }

  setLocations() {
    const provierName = this.selectedProvider.providerName ? this.selectedProvider.providerName : '';
    const locationObj = CommonUtil.buildMapDataFromLocation(provierName, this.providerLocations);
    const mapReq = CommonUtil.setMapRequest(locationObj, true, this.showSPFI);

    this.mapLocations = mapReq.filter((x) => {
      return x.addressId === this.selectedAddress;
    });
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  loadDrivingDirection(location) {
    const provider = {
      location: {
        address: location
      },
      providerName: this.selectedProvider.providerName ? this.selectedProvider.providerName : ''
    };
    this._driveDirectionEvent.emit(provider);
  }

  mapPinClickEvent(event: any) {
    // TO DO:
  }

  openDomainDisclaimerModal(url: any) {
    this.openDomain.emit(url);
  }
  administrativeOffice(activeFlagList: Array<string>): boolean {
    const flag = this.WcsUtility.isAdministrativeOffice(activeFlagList);
    if (flag) {
      this.isShowDistance = false;
      this.isAdminOffice = true;
    }
    return flag;
  }

  onDownloadVcard() {
    const detailsParams = this.buildDetailsRequest();
    this._commmunicationService.getVcard(detailsParams).then((result: any) => {
      this._appUtility.saveVcard(result, this.selectedProvider.providerIdentifier);
    });
  }
  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  getLocations(changedAddressId) {
    this.isEycSearch = this._appSession.isEyc;
    const providerName = this.selectedProvider.providerName ? this.selectedProvider.providerName : '';
    const addrId = (this.selectedProvider.location && this.selectedProvider.location.address && this.selectedProvider.location.address.addressId) || '';
    this.selectedAddress = changedAddressId === '' ? addrId : changedAddressId;

    if (this.locationsList && this.locationsList.length) {
      this.getTravelTime(this.locationsList, providerName);
    }
  }
}
