import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalRef } from "@anthem/uxd/modal";
import { BaseComponent } from "./../../../common/components/base-component/baseCmp";
import { EventHandler } from "./../../../common/services/eventHandler";
import { AppSession } from "./../../../common/values/appSession";
import { ContentHelper } from "./../../../common/values/contentHelper";

@Component({
  moduleId: module.id,
  selector: "app-fad-pf-fts-moreExamples-cmp",
  templateUrl: "../views/pfFtsMoreExamplesCmp.html",
})
export class PFFtsMoreExamplesComponent extends BaseComponent {
  sidePanelRef: ModalRef<HTMLElement>;
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper);
  }

  onClose() {
    this.closeModal.emit();
  }
}
