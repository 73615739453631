import { EventEmitter, Inject, Injectable, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomizedFeatures } from '../../../../app/fad/rules/models/customizedFeatures';
import { IProvider } from '../../../fad/search-results/interfaces/iSummaryResp';
import { Y_STR } from '../../../fad/search-results/values/providerSearchConstants';
import { FindCareFeature } from '../../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../../findcare/common/utilities/featureManagerUtil';
import {
  AOE_OFCE_SRVC_FWD_LOOKUP,
  DATA_OVERFLOW_SLIDER_V4,
  DISPLAY_MACRO_COST,
  DYNAMIC_FILTER,
  ENABLE_MULTI_COVERAGE_SELECTION,
  ENABLE_PF_BBB_NAVIGATION,
  EYC_DYNAMIC_FILTER,
  FTS_PROCEDURE_LOOKUP,
  HIDE_QUICKLINKS_SHOPFORPROCEDURE,
  NETWORK_PROGRAM_REFERENCE_V2,
  PUBLIC_SPECIALTY_SEARCH,
  SECURE_RX_SEARCH,
  SECURE_SPECIALTY_SEARCH,
  SMART_SEARCH
} from '../../constants/featureFlags';
import { IRebrandConfig } from '../../interfaces/iBrandConfig';
import { IFinalProvider } from '../../interfaces/iCommonSearchResponse';
import { FeatureFlagService } from '../../services/featureFlagInitializer';
import { UserInteractionUtil } from '../../utilities/userInteractionUtil';
import { AppConfig } from '../../values/appConfig';
import { WcsUtil } from './../../../fad/utilities/wcsUtil';
import { AppConstants, AppExternalCommands, AppInfoMsg, CAHMODMHC, Locale, USER_INTERACTION } from './../../constants/app-constants';
import { AppEvents } from './../../enums/appEvents';
import { IEventDetail } from './../../interfaces/iEventDetail';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';

declare let window: any;

@Injectable()
export class BaseComponent implements OnInit {

  get content(): any {
    return this._content;
  }

  get WcsUtility(): WcsUtil {
    return this._wcsUtil;
  }

  get userInteractionUtil(): UserInteractionUtil {
    return this._userInteractionUtil;
  }

  get baseURL(): string {
    return this._appConfig?.baseUrl[this.appSession.state];
  }

  get baseSSOURL(): string {
    return this._appConfig?.sso.baseUrl;
  }

  get waitUntilAppReload(): boolean {
    return this.appSession.waitUntilAppReLoad || this.waitUntilTokenInitialized();
  }

  get rebrandConfig(): IRebrandConfig {
    return this.appSession.rebrandConfig;
  }

  /***
   * To check the application is accessed through mobile webview or not.
   * if Mobile web view is true then disable the print icon.
   * previously PF used this._appUtility.isWebkitBrowser(),
   * but it was not identifying all mobile web views so modified the logic
   * a bit to know weather accessing device is mobile or not by using
   * this.isMobileWebBrowser() or by using isWebView flag
   * isWebView value is receive from RCP metaData
   */
  get isMobileView(): boolean {
    return this.appSession.isWebView || this.isMobileWebBrowser();
  }
  /***
   *  get the Assign Pcp value from environment config
   * if true, we will show the assign pcp in UI
  */
  get isAssignPcpEnabled(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
   *  get the Integrated search value from feature flag config
   * if true, we will enable the Integrated search in UI
  */
  get isIntegratedSearchEnabled(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  get isNewLocationFlowEnable(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
   *  get the CareTeam value from feature flag config
   * if true, we will enable the CareTeam in UI
  */
  get isCareTeamEnabled(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
   *  get the AddCareTeam value from feature flag config
   * if true, we will enable the AddCareTeam in UI
  */
  get isAddCareTeamEnabled(): boolean {
    return this.isFeatureLive(FindCareFeature.FC_CARE_TEAM_ADD) && !this.isFeatureLive(FindCareFeature.HAS_CASTLIGHT);
  }

  /***
   *  get the EycPricing value from feature flag config
   * if true, we will enable the EycPricing changes in UI
  */
  get isEycPricingEnabled(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
   *  get the FutureEffectiveProvider value from feature flag config
   * if true, we will enable the details page for future effective provider
  */
  get isFutureEffectiveProviderEnabled(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
 *  get the print pdf value from feature flag config
 * if true, we will enable the print pdf functionality in UI
*/
  get showPrintPDF(): boolean {
    return this.isFeatureLive(FindCareFeature.FC_EXPORT_PDF);
  }

  /***
   *  get the pznNBAWidgetFindcare value from feature flag config
   * if true, we will enable the pznNBAWidgetFindcare functionality in UI
   */
  get showPznNBAWidgetFindcare(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
*  get the email pdf value from feature flag config
* if true, we will enable the email pdf functionality in UI
*/
  get showEmailPDF(): boolean {
    return this.isFeatureLive(FindCareFeature.FC_EXPORT_EMAIL);
  }

  get showPreConfiguredLink(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
*  get the vcard value from feature flag config
* if true, we will enable the save contact info as vcard functionality in details page
*/
  get showVcard(): boolean {
    return this.isFeatureLive(FindCareFeature.FC_PROVIDER_VCARD);
  }

  /***
  *  get feature flag from config for plan filter
  * if true, UI will display plan filter in results page
  */
  get showPlanFilter(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
  *  get the liveHealth value from feature flag config
  * if true, we will enable the LHO link functionality in results page
  */
  get showLHO(): boolean {
    return this.isFeatureLive(FindCareFeature.HAS_LHO);
  }

  get showOfficeVisitCost(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    // The implementation was decommissioned since NCCT was not providing enough info and re-instated using Medical Code search using feature FC_SEARCH_SPCLTY_OFFICE_COST
    return false;
  }

  /***
  *  get the enableTeleHealth value from feature flag config
  * if false, we will enable the telehealth functionality in UI
  */
  get showTeleHealth(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
   *  get the Dental DDS value from feature flag config
   * if false, we will enable the Dental DDS functionality in UI
   */
  get showDentalDDS(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
   *  get the Onsite clinics value from feature flag config
   * if false, we will enable the Onsite clinics functionality in UI
   */
  get showOnsiteClinics(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /***
   *  Returns tru if paired provider should be presented in the UI based on response from SPFi.
   */
  get showSPFI(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  /**
  * get the healthwise Integration value from feature flag config
  * if false, we will enable the healthwise content functionality in UI
  */
  get isHealthwiseContentEnabled(): boolean {
    return this.isFeatureFlagEnabled('healthwiseIntegration');
  }

  /**
   * Enable/disable machine readable links in UI
   */
  get showMachineReadable(): boolean {
    return this.isFeatureLive(FindCareFeature.FC_MACHINE_READABLE);
  }

  get isCostTransparency(): boolean {
    return this.isFeatureFlagEnabled('costTransparency');
  }

  get isCostTransparencyDetailsPage(): boolean {
    return this.isFeatureFlagEnabled('costTransparencyDetailsPage');
  }
  /**
   * Enable/disable Kodiak Features in UI
   */
  get isKodiakFeatureEnabled(): boolean {
    return this.isFeatureFlagEnabled('kodiakProcedures');
  }
  /**
   * Enable/disable Shop for procedure page redesign Features in UI
   */
  get isShopForProcRedesignEnabled(): boolean {
    return this.isFeatureFlagEnabled('shopForProcedureRedesign');
  }

  get isANPSidePanelEnabled(): boolean {
    return this.isFeatureFlagEnabled('showANPSidePanel');
  }
  /**
   * Enable/disable GA Terminating Providers Care gap notification message Features in UI
   */
  get showGATerminatingProviders(): boolean {
    return this.isFeatureFlagEnabled('gaTerminatingProviders');
  }
  /**
   * Enable/disable health system details in UI
   */
  get isHealthSystemEnabled(): boolean {
    return this.isFeatureFlagEnabled('showFounderInfo');
  }

  get customizedFeatures(): CustomizedFeatures {
    const { customizedFeatures } = this.appSession.feature;
    return customizedFeatures;
  }

  get isAoeOfcSrvcLookupEnabled(): boolean {
    return this.isFeatureFlagEnabled('aoeOfcSrvcLookup');
  }

  get isBBBFeatureEnabled(): boolean {
    return this.isFeatureFlagEnabled(ENABLE_PF_BBB_NAVIGATION);
  }

  /**
  * This method will return cold state EYC status
  */
  get isColdStateEYC(): boolean {
    return Boolean(this.appSession.hasColdStateEYC && !this.appSession.isSecureState);
  }

  /**
   * Getter for the isFtsEnabled property.
   * @returns {boolean} True if language english, isFtsSearchEnabled and appSession.isSecureState are true, false otherwise.
   */
  get isFtsEnabled(): boolean {
    return (
      this.appSession.metaData.locale === Locale.ENGLISH &&
      FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_FTS, this.appSession) &&
      FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_FC_FTS, this.appSession)
    );
  }

  get isFindCareLiveChatEnabled(): boolean {
    return this.appSession.hasLiveChatSupport;
  }

  /**
   * Getter for the isFtsFeatureLive property of the appSession object.
   * @returns {boolean} The current state of the free text search mode.
   */
  get isFtsFeatureLive(): boolean {
    return this.appSession.isFtsFeatureLive;
  }

  /**
   * Enable/disable filter Deselection in UI
   */
    get isFilterDeselectionEnabled(): boolean {
    return this.isFeatureFlagEnabled('filterDeselection');
  }

  /***
   * get the EnableDynamicFilter value from feature flag config
   * enable/disable Dynamic filters in Provider results filter section
  */
   get isDynamicFilterEnabled(): boolean {
    return this.isFeatureFlagEnabled(DYNAMIC_FILTER);
  }

  /***
   * get the enableEYCDynamicFilter value from feature flag config
   * enable/disable EYC Dynamic filters in Provider results filter section
  */
  get isEYCDynamicFilterEnabled(): boolean {
    return this.isFeatureFlagEnabled(EYC_DYNAMIC_FILTER);
  }

   // Enable/disable assigned as PCP badge in summary and details pages
  get showAssignedAsPCPBadge(): boolean {
     return this.isFeatureFlagEnabled('enableAssignedAsPCPBadge');
  }

  get showSmartSearch(): boolean {
    return this.isFeatureFlagEnabled(SMART_SEARCH );
  }

  get ftsProcedureLookup(): boolean {
    return this.isFeatureFlagEnabled(FTS_PROCEDURE_LOOKUP);
  }

  get showMacroCost(): boolean {
    return this.isFeatureFlagEnabled(DISPLAY_MACRO_COST);
  }

    /**
   * get the secureSpecialtyApiSegregation value from feature flag config
   * enable/disable secure specialty refractor
   */
  get isSecureSpecialtySearchEnabled(): boolean {
    return this.isFeatureFlagEnabled(SECURE_SPECIALTY_SEARCH);
  }

  get multiCoverageEnabled(): boolean{
    return this.isFeatureFlagEnabled(ENABLE_MULTI_COVERAGE_SELECTION);
  }

  get isOfficeVisitCost(): boolean {
    return this.appSession.isOfficeVisitCost;
  }

    /**
   * get the secureRxApiSegregation value from feature flag config
   * enable/disable secure RX refractor
   */
  get isSecureRxSearchEnabled(): boolean {
    return this.isFeatureFlagEnabled(SECURE_RX_SEARCH);
  }

    /**
   * get the publicSpecialtyApiSegregation value from feature flag config
   * enable/disable public specialty refractor
   */
  get isPublicSpecialtySearchEnabled(): boolean {
    return this.isFeatureFlagEnabled(PUBLIC_SPECIALTY_SEARCH);
  }

  get dataOverFlowSliderV4(): boolean {
    return this.isFeatureFlagEnabled(DATA_OVERFLOW_SLIDER_V4);
  }

  get networkProgramReferenceV2(): boolean {
    return this.isFeatureFlagEnabled(NETWORK_PROGRAM_REFERENCE_V2);
  }

  get client(): string {
    return this.appSession.deeplinkParams.client;
  }

  /***
   *  get the hidequicklinkshopforprocedure search value from feature flag config
   * if true, we will enable the hidequicks and shop for procedure search in UI
  */
 get isHideQuickLinksShopForProcedureEnabled(): boolean {
  return this.isFeatureFlagEnabled(HIDE_QUICKLINKS_SHOPFORPROCEDURE);
  }

  get isAoeOfceSrvcLookupEnabled(): boolean {
    return this.isFeatureFlagEnabled('aoeOfcSrvcLookup');
  }

  get pageNumber(): number {
    return (!this.appSession.pageNumber)
      ? 1 : +this.appSession.pageNumber;
  }
  userInteraction = USER_INTERACTION;
  private _content: any;
  private _componentName: string = '';
  private _subComponentName: string = '';
  private _wcsUtil: WcsUtil;
  private _userInteractionUtil: UserInteractionUtil;
  private _pfLoadSuccessEvent: EventEmitter<IEventDetail>
    = this.eventHandler.get(AppEvents[AppEvents.PF_APP_LOAD_SUCCESS]);
  private _pfAppCommandEventForProvider: EventEmitter<
    IEventDetail
  > = this.eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  private _appConfig: AppConfig;

  constructor(
    private route: ActivatedRoute,
    private eventHandler: EventHandler,
    @Inject(AppSession)
    private appSession: AppSession,
    @Inject(ContentHelper)
    private contentHelper: ContentHelper,
    @Inject('componentName')
    componentName: string = '',
    @Optional()
    @Inject('subComponentName')
    subComponentName?: string
  ) {
    this._appConfig = this.appSession.appConfig;
    this._componentName = componentName;
    this._subComponentName = subComponentName;
    this.route?.data?.subscribe((data: { content: any }) => {
        if (data.content) {
          this._content = this.setJsonContent(data.content);

          if (!this._content && this._content === undefined && this._componentName) {
            this._content = this.contentHelper.getContent(this._componentName);
          } else if (!this._content && this._content === undefined) {
            this._content = this.contentHelper.getAllContent();
          }

          this._wcsUtil = new WcsUtil(this._content, this.appSession);
          this._userInteractionUtil = new UserInteractionUtil(this.appSession)
        }
      });
  }

  ngOnInit() {
    // TO DO:
  }
  /**
   * Identify browser software is mobile web browser
   */
  isMobileWebBrowser() {
    try {
      const agent = window.navigator.userAgent.toLowerCase();
      // tslint:disable-next-line: max-line-length
      if (/android|webos|iphone|ipad|ipod|pocket|psp|kindle|avantgo|blazer|midori|palm|maemo|plucker|phone|blackberry|symbian|iemobile|mobile|zunewp7|windows phone|opera mini/i.test(agent)) {
       return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  getStripedHtml(htmlString): string {
    return this.WcsUtility.getStripedHtml(htmlString);
  }

  getCommonImageURL(imageName: string) {
    if (imageName) {
      return this.baseURL + this._appConfig?.images.commonBaseUrl + imageName;
    } else {
      return '';
    }
  }

  getQualityImageURL(imageName: string) {
    if (imageName) {
      return this.baseURL + this._appConfig?.images.qualityBaseUrl + imageName;
    } else {
      return '';
    }
  }

  showCareTeam(): boolean {
    return this.customizedFeatures.showAdddCareTeam && this.isCareTeamEnabled
  }

  showAddReview(): boolean {
    return this.customizedFeatures.showAddReviewButton
  }


  /** Method to get Brand specific append URL. */
  getBrandAppendURL() {
    const _brand = this.appSession.metaData.brandCd;
    let _appendUrl = '';
    const _index = AppConstants.BRAND.findIndex((x) => x.brand === _brand);
    if (_index >= 0) {
      _appendUrl = AppConstants.BRAND[_index].appendUrl;
    }
    return _appendUrl;
  }

  getDocumentURL(content: string) {
    if (!content || typeof content != 'string' ) {
      return ;
    }
    let _content = content;
    const _lnkPattrn = / href='(.*?)'/g;
    const _urlPattrn = /{BRAND_URL}/gi;
    const _appendUrl = this.getBrandAppendURL();

    /** Content to bind Brnad specific url. eg: ABC band url append '/ca' */
    while (true) {
      const _match = _lnkPattrn.exec(content);
      if (!_match) {
        break;
      }
      if (_match && _match.length > 0) {
        const _url = _match[1].replace(_urlPattrn, _appendUrl);
        _content = _content.replace(_match[1], _url);
      }
    }

    return _content.replace(/\/assets\/documents\//g, this.baseURL + '/assets/documents/');
  }

  onPreLoad() {
    if (this.appSession.isFirstTimeLoad) {
      const eventDetail = {} as IEventDetail;

      eventDetail.type = AppEvents[AppEvents.PF_APP_LOAD_SUCCESS];
      eventDetail.details = eventDetail.message =
        this.appSession.isEyc ? AppInfoMsg.EYC_APP_LOADED : AppInfoMsg.FAD_APP_LOADED;

      this._pfLoadSuccessEvent.emit(eventDetail);

      this.appSession.isFirstTimeLoad = false;
    }
  }

  isFeatureFlagEnabled(key: string): boolean {
    const switches = FeatureFlagService.getKillSwitches();
    if (switches) {
      return !switches[key];
    }

    return false;
  }

  isFeatureLive(value: FindCareFeature | Array<FindCareFeature>, logicBetween: 'AND' | 'OR' = 'OR'): boolean {
    return FeatureManagerUtility.isFeatureLive(value, this.appSession, logicBetween);
  }

  selectProvider(professional: IProvider) {
    const eventDetail: IEventDetail = {
      object: professional,
      type: AppEvents[AppEvents.PF_APP_CMD_PROV_INFO],
      target: AppExternalCommands.PF_APP_CMD_PROV_INFO.CMD,
      message: AppExternalCommands.PF_APP_CMD_PROV_INFO.MSG,
      details: AppExternalCommands.PF_APP_CMD_PROV_INFO.MSG,
      errorCode: null,
    };
    this._pfAppCommandEventForProvider.emit(eventDetail);
  }

  emitLiveChatEvent() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_FIND_CARE_LIVE_CHAT.MSG;
    eventDetail.target = AppExternalCommands.RCP_FIND_CARE_LIVE_CHAT.CMD;
    this._pfAppCommandEventForProvider.emit(eventDetail);
  }

  loadcaHmoDmhc(): boolean {
    return this.isFeatureFlagEnabled(CAHMODMHC);
  }

  get isAoeOfceSrvcForwardLookupEnabled(): boolean {
    return this.isFeatureFlagEnabled(AOE_OFCE_SRVC_FWD_LOOKUP);
  }

  showPhoneNumber(professional: IFinalProvider): boolean {
    return !this.WcsUtility.isAdministrativeOffice(professional?.activeFlagList) && professional?.onlineProvider !== Y_STR && !professional?.location?.address?.homeHlthCareIndc && typeof professional?.location?.address?.phone !== 'undefined' && professional?.location?.address?.phone !== '';
  }

  /**
   * Toggles the free text search mode in the application session.
   * If `isFtsFeatureLive` is currently true, it will be set to false, and vice versa.
   */
  switchFtsPilot() {
    this.appSession.isFtsFeatureLive = !this.appSession.isFtsFeatureLive;
    if (window?.digitalData?.page?.pageInfo) {
      if (this.appSession.isFtsFeatureLive) {
        window.digitalData.page.pageInfo.findCareExperience = 'new';
      } else {
        window.digitalData.page.pageInfo.findCareExperience = "old";
      }
    }
  }

  /**
 * This function is used to get the current geolocation of the user.
 * It returns a Promise that resolves with the GeolocationPosition object if the geolocation is successfully obtained,
 * or rejects with an error if the geolocation fails.
 *
 * @param {boolean} highAccuracy - Whether to use high-accuracy mode. Default is true.
 * @param {number} timeout - The maximum length of time (in milliseconds) that is allowed to pass from the call to getCurrentPosition() until the corresponding success callback is invoked. Default is 5000.
 * @param {number} maximumAge - Indicates the maximum age in milliseconds of a possible cached position that the application will accept. Default is 0.
 * @returns {Promise<GeolocationPosition>} - A Promise that resolves with the GeolocationPosition object if the geolocation is successfully obtained, or rejects with an error if the geolocation fails.
 */
  getCurrentGeoPosition(highAccuracy: boolean = true, timeout: number = 5000, maximumAge: number = 0): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error),
        {
          enableHighAccuracy: highAccuracy,
          timeout: timeout,
          maximumAge: maximumAge
        }
      );
    });
  }

  private setJsonContent(wcsContent: any) {
    const templatePath: string = this.contentHelper.getResolvedUrl(this.route.snapshot);
    this.contentHelper.registerRouteContent(templatePath, wcsContent);

    for (const element of wcsContent.widgets) {
      const widgetComponentName = element.widgetHeader.dataWidgetComponentName;

      if (this._componentName && this._componentName === widgetComponentName) {
        const wcsData: any = element.widgetContent;

        if (!this.contentHelper.getContent(widgetComponentName)
          && this.contentHelper.getContent(widgetComponentName) === undefined) {
          this.contentHelper.registerContent(widgetComponentName, wcsData);
        }

        if (this._subComponentName && wcsData[this._subComponentName]) {
          return wcsData[this._subComponentName];
        } else {
          return this.contentHelper.getAllContent();
        }
      }
    }
  }

  private waitUntilTokenInitialized() {
    if (!this.appSession.provDataModifiedOn && this.appSession.isIntegratedMode === true) {
      return true;
    }
    return false;
  }
}
