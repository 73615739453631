import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { PROVIDER_NAME } from '../../constants/app-constants';
import { AppNavigations } from '../../constants/app-navigations';
import { EventHandler } from '../../services/eventHandler';
import { NavigationService } from '../../services/navigationService';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { AssignPcpService } from '../pcp/pfAssignPcpSvc';

/**
 * Use this component to display an alert in a Panel overlay of any page
 */
@Component({
  moduleId: module.id,
  selector: 'app-common-anp-side-panel-cmp',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './anpSidePanelCmp.html'
})
export class AnpSidePanelComponent extends BaseComponent {
  modalRef: ModalRef<HTMLElement>;
  @ViewChild('assignPcpAnpModal')
  alertTemplateRef: TemplateRef<HTMLElement>;
  constructor(private _sidePanel: SidePanel, private _navigationService: NavigationService,
    private _assignPcpService: AssignPcpService,@Inject(AppSession)
    private _appSession: AppSession,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper) {
      super(
        _route,
        _eventHandler,
        _appSession,
        _contentHelper,
        'PFSearchContainerComponent'
      );
  }

  openSidePanel() {
    this.modalRef = this._sidePanel.open('right', this.alertTemplateRef);
  }

  onClose() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  getModalContentANP(content: string) {
    return content.replace(
      PROVIDER_NAME,
      this._appSession.selectedProvider.providerName
    );
  }

  onNewPatientsClick() {
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    this._appSession.isOnlyAnpProvider = true;
    this._assignPcpService.isAnpProvider(true);
    this.modalRef.close();
  }
}
