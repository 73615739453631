import { HomeContent } from "./homeContent";

// Define the Spanish content for the home page, ensuring each key in the English content has a corresponding key in the Spanish content
export const HOME_CONTENT_ES: HomeContent = {
  title: 'Inicio',
  planSelection: {
    wlpLabel: {
      loginText: 'Iniciar sesión',
      personalizedSearchDesc: 'Encuentre médicos, hospitales y más en la red de su plan. Obtenga estimados detallados para procedimientos o servicios (no disponible con algunos planes)',
      defaultPrefixTextNew: 'Ingresa el número de identificación de miembro',
      idCardToolTipHeaderText: 'Usa tu número de identificación para buscar',
      idCardToolTipNoteText: ''
    },
    wlpMass: {
      findProvider: 'Encontrar un proveedor de salud conductual',
      description:
        '¿Busca un proveedor de salud mental o trastorno por uso de sustancias? Aproveche al máximo sus beneficios y utilice la red Carelon Behavioral Health. Como miembro del plan <strong>Wellpoint Massachusetts</strong>, su cobertura es más alta cuando utiliza proveedores de cuidado dentro de la red Carelon Behavioral Health.',
      health: 'Carelon Behavioral Health',
      externalLink: 'https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic/',
      searchDescription: 'Descubra si un médico, hospital u otro proveedor de cuidado está dentro de la red del plan <strong>Wellpoint Massachusetts.</strong>',
      basicSearch: 'Búsqueda básica como Invitado',
      login: 'Iniciar sesión',
      loginDescription: 'Encuentre médicos, hospitales y más en su plan <strong>Wellpoint Massachusetts.</strong> Obtenga estimados detallados de los servicios de salud según sus beneficios.',
      guestSearch: 'Búsqueda como invitado'
    },
    title: {
      findaDoctor: 'Encontrar un doctor ',
      findaDoctorText: 'Para buscar seleccionando un plan, primero reduce la lista de planes y redes seleccionando el tipo de cuidado médico y el estado. Luego, selecciona un plan o red para buscar.'
    },
    labels: {
      typeOfCare: 'Selecciona el tipo de plan o red',
      searchInState:
        "Selecciona el estado donde se ofrece el plan o la red. <span class='font-wt-normal'>(Para los planes patrocinados por el empleador, selecciona el estado en el que se contratada el plan de tu empleador. La mayoría de las veces es donde se encuentra la sede).</span>",
      planOrNetwork: 'Selecciona un plan o red',
      defaultCareText: 'Selecciona uno',
      defaultStateText: 'Selecciona uno',
      defaultPlanOrText: 'Selecciona uno',
      planCategory: 'Selecciona ¿Cómo obtienes el seguro de salud',
      defaultPlanCategoryText: 'Selecciona uno',
      medicalCareHelpText: 'Los proveedores de cuidado para servicios de salud conductual y trastornos por uso de sustancias se enumeran en el Plan médico o red.',
      unicareMassHelpText: {
        msgText: '¿Estás buscando un proveedor de salud mental o de trastornos de abuso de sustancias? Usa la red de ',
        linkText: 'Carelon Behavioral Health',
        msgText2: ' para recibir los máximos beneficios.',
        brandUrls: { unimass: 'https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic/' }
      },
      personalizedSearchTitle: 'Inicia sesión para una búsqueda personalizada',
      personalizedSearchDesc:
        'Encuentra médicos, hospitales y más en la red de tu plan. Obtén estimados detallados para procedimientos o servicios (no disponible con algunos planes). Si no tienes una cuenta, ',
      wlpLandingPagePersonalizedSearchDesc: 'Encuentre médicos, hospitales y más en la red de su plan. Obtenga estimados detallados para procedimientos o servicios (no disponible con algunos planes)',
      memberIdSearchTitle: 'Usa la identificación de miembro para una búsqueda básica',
      memberIdSearchDesc: 'Encuentra médicos, hospitales y más cerca de ti.',
      planSearchTitle: 'Búsqueda básica como invitado',
      planSearchDesc: 'Selecciona un plan y averigua si un médico, hospital o algún otro proveedor de cuidado médico está dentro de la red.',
      alphaPrefix: 'Número de identificación o prefijo (los primeros tres valores)',
      defaultPrefixText: 'Ingresa Número de identificación o prefijo',
      searchTogglePlan: 'Buscar por plan',
      searchToggleId: 'Buscar por Número de identificación o prefijo',
      loginDescriptiveText: 'Inicia sesión para buscar proveedores de cuidado médico en tu plan. Si no tienes una cuenta,',
      registerNowText: 'regístrate ahora',
      orText: 'o',
      searchAsGuestText: 'busca como invitado',
      loginText: 'Inicia sesión para Encontrar cuidado médico',
      wlpLandingPageLoginText: 'Iniciar sesión',
      loginDescriptiveText1: 'Si eres miembro, ',
      loginDescriptiveText2: ' para ver resultados de búsqueda personalizados según tus planes y beneficios. Si no tienes una cuenta, ',
      findCareLogin: 'inicia sesión para Encontrar cuidado médico',
      searchWithMemberIdText: 'Busca tu plan médico sin iniciar sesión.',
      alphaPrefixNew: 'Número de identificación de miembro o prefijo (primeros tres valores)',
      defaultPrefixTextNew: 'No. de id. o prefijo (primeros 3 valores)',
      idCardToolTipHeaderText: 'Ingresa el número de identificación de miembro o el prefijo (primeros tres valores) para buscar',
      idCardToolTipNoteText:
        'Nota: Si eres miembro de Medicaid o de otros programas patrocinados por el estado, busca con el prefijo (primeros tres valores) de tu número de identificación o busca seleccionando un plan/ red.',
      idCardToolTipAltImgText: 'tarjeta de identificación',
      idCArdToolTipAriaLabelText: 'tarjeta de identificación imagen',
      closeModal: 'botón, seleccione para cerrar el modal',
      searchText: 'Buscar',
      headingText: 'Invitado',
      headingDescription: '¿No eres un miembro? Explora nuestros directorios dentro de la red.',
      tabDescription: 'Responde algunas preguntas y luego selecciona un plan o red.',
      altBlueMemberIconText: 'icono azul de miembro',
      altWhiteMemberIconText: 'icono blanco de miembro',
      altMemberIcon: 'icono de miembro',
      altGuestIcon: 'icono de Invitado',
      memberHeadingText: 'Miembros',
      memberHeadingDescriptionText: 'Encuentra médicos, hospitales y más en tu plan.',
      memberWithMedicaid: 'Miembros con Medicaid',
      medicaidStatesCalifornia: 'California',
      medicaidStatesIndianapolis: 'Indiana',
      medicaidStatesKentucky: 'Kentucky',
      medicaidStatesNevada: 'Nevada',
      medicaidStatesVirginia: 'Virginia',
      medicaidStatesWisconsin: 'Wisconsin',
      medicaidStateNewyork: 'New York',
      medicaidHeaderText: 'Ve a la herramienta Encontrar cuidado médico de Anthem Medicaid para tu estado.',
      medicaidEligibleAdultText: 'Cobertura combinada de Medicare y Medicaid para adultos elegibles mayores de 21 años.',
      tooltipInfo: 'presiona Enter para obtener más información sobre'
    },
    alerts: {
      typeCare: 'Por favor ingresa o selecciona un tipo de cuidado',
      searchState: 'Por favor ingresa o selecciona un estado',
      plan: 'Por favor selecciona un plan/red.',
      category: 'Por favor selecciona el tipo de plan',
      idOrPrefix: 'Ingresa una identificación de miembro o prefijo.',
      captchaRequiredError: 'Complete la verificación CAPTCHA para continuar.',
      hcidError: 'No podemos encontrar esa identificación de miembro o prefijo. Revisa tu tarjeta de identificación y vuelve a intentar.',
      invalidHCID: 'La búsqueda no está disponible usando un número de identificación de miembro p un prefijo. ',
      multiContractError: 'Lo sentimos, pero no podemos continuar tu búsqueda con ese número porque tienes más de un plan. Para buscar, inicia sesión y elige el plan en el que deseas buscar.'
    },
    apiError: 'Lo sentimos, parece que algo no está funcionando. Danos un poco de tiempo para solucionarlo. Luego, intenta de nuevo.',
    continue: 'Continuar',
    cancel: 'Cancelar',
    backToTop: 'Volver arriba'
  }
};
