export const MemberPlanProgressLabels = {
  IND_DEDUCTIBLE_IN: 'In-Network Deductible',
  IND_OUTOFPOCKET_IN: 'In-Network Out-of-Pocket Max',
  IND_DEDUCTIBLE_OUT: 'Out-of-Network Deductible',
  IND_OUTOFPOCKET_OUT: 'Out-of-Network Out-of-Pocket Max',
  FAM_DEDUCTIBLE_IN: 'Family In-Network Deducible',
  FAM_OUTOFPOCKET_IN: 'Family In-Network Out-of-Pocket Max',
  FAM_DEDUCTIBLE_OUT: 'Family Out-of-Network Deductible',
  FAM_OUTOFPOCKET_OUT: 'Family Out-of-Network Out-of-Pocket Max',
  SPENDING_ACCOUNT_HSA: 'Health Savings Account (HSA)',
  SPENDING_ACCOUNT_HRA: 'Health Reimbursement Arrangement (HRA)',
  SPENDING_ACCOUNT_FSA: 'Flexible Spending Account (FSA)'
};

export const BenefitType = {
  ERROR: 'error',
  BENEFIT: 'benefit'
}

export const BenefitTypeKey = {
  SPENDING: 'SPENDING',
  FAMILY: 'FAM',
  IND: 'IND'
}
