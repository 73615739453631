import { Component } from '@angular/core';
import { PFMoreOptionsComponent } from './pfMoreOptionsCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-more-options-v2-cmp',
  templateUrl: '../views/pfMoreOptionsV2Cmp.html',
  providers: [],
})
export class PFMoreOptionsV2Component extends PFMoreOptionsComponent {

}
