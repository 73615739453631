import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { IHttpHeader } from './../interfaces/iHttpHeader';
import { AppUtility } from './../utilities/appUtil';
import { AppSession } from './../values/appSession';
import { HttpClientService } from './httpClientService';

@Injectable()
export class BaseService {

  constructor(@Inject(AppSession) public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) { }

  get baseURL(): string {
    let baseUrl = this.appSession.appConfig?.providerFinder
      .baseUrl[this.appSession.state][this.appUtility.getBrandFromUrl().toLocaleLowerCase()];

    if (!baseUrl && typeof baseUrl === 'undefined') {
      baseUrl = this.appSession.appConfig?.providerFinder
        .baseUrl[this.appSession.state]['abcbs'];
    }

    return baseUrl;
  }

  protected get timeZone() {
    return moment.tz.guess();
  }

  /**
   *
   * @returns default headers Array for an API call
   */
  getHeaders(): IHttpHeader[] {
    return [
      {
        name: 'Content-Type',
        value: 'application/json'
      }
    ]
  }
}
