import { SlicePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customSlice',
  pure: false
})
export class CustomSlicePipe implements PipeTransform {
  private slicePipe: SlicePipe;

  constructor() {
    this.slicePipe = new SlicePipe();
  }

  transform(value: any, start: number, end?: number): any {
    if (Array.isArray(value)) {
      // Handle array
      return this.slicePipe.transform(value, start, end);
    } else if (typeof value === 'string') {
      // Handle string
      return this.slicePipe.transform(value, start, end);
    } else {
      // Handle other types
      return value;
    }
  }

}
