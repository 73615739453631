import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IDisclaimerRequest, IDisclaimerResponse } from '../interfaces/iDisclaimer';
import { BaseService } from './../../../common/services/baseService';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerSvc extends BaseService{

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }
/**
 * Loads the disclaimer IDs based on the provided request object.
 *
 * @param {IDisclaimerRequest} reqObj - The request object containing the necessary parameters like rules and language.
 * @returns {Promise<IDisclaimerResponse>}
 **/
  loadDisclaimerIDs(reqObj: IDisclaimerRequest): Promise<IDisclaimerResponse> {
    return this.httpClientSvc
      .request({
        cancellable: true,
        method: HttpMethod.Post,
        url: this.baseURL + apiNameList.restApi.disclaimersV1,
        data: reqObj,
        headers: this.getHeaders()
      });
  }
}
