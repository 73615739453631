import { Injectable } from '@angular/core';
import { DataHelper } from '../../../common/services/dataHelper';
import { IAlphabetItem } from '../interfaces/iAlphabetItem';
import { ISearchResult } from '../interfaces/iSearchResult';
import { Map } from './Map';
import { ProcedureGroup } from './procedureGroup';
import { SearchByAlphabetsResult } from './searchByAlphabetsResult';
import { SearchByWordsResult } from './searchByWordsResult';

@Injectable()
export class ProcedureIndexModel {
  alphabetRanges: Array<IAlphabetItem> = [];
  private _procedureGroupsMap: Map<string, ProcedureGroup> = new Map<string, ProcedureGroup>();
  private _letterGroupsMap: Map<string, Array<ProcedureGroup>> = new Map<string, Array<ProcedureGroup>>();
  private _suggestions: Array<string> = [];

  private _wordsResult: SearchByWordsResult = new SearchByWordsResult();
  private _alphabetsResult: SearchByAlphabetsResult = new SearchByAlphabetsResult();
  private _result: ISearchResult;
  private _content: any;

  constructor(protected dataHlpr: DataHelper) {
    this.resetModel();
  }

  resetModel() {
    this._procedureGroupsMap.clear();
    this._letterGroupsMap.clear();
    this._wordsResult.clear();
    this._alphabetsResult.clear();
    this._suggestions.length = 0;
    this.alphabetRanges = [];
    this._result = null;
  }

    /**
   * Get all the procedures by the given word containing in their name or keywords array
   * @param word
   */
  findByKeyWord(word: string) {
    if (typeof word === 'string' && word.trim() === '') {
      //Dont perform search for empty word
      return;
    }

    if (this._result && typeof word === 'string' && word.toLowerCase() === this._result.query.toLowerCase()) {
      //Dont perform the same search again and again
      return;
    }

    this._wordsResult.clear();
    this._alphabetsResult.clear();

    //IMPORTANT: Make wordsResult as current result object
    this._result = this._wordsResult;
    this._wordsResult.query = word;
    this._wordsResult.title = this._content.procedureSelectionComponent.wordResultTitle.replace('[[queryWord]]', word);
    this._wordsResult.noResultsMessage = this._content.procedureSelectionComponent.noResultsFound
      .replace('{{searchValue}}', word);

    this._procedureGroupsMap.forEach((group, key) => {
      group.isRelated = false;
      const srcStr: string = group.title.toLowerCase();
      word = word.toLowerCase();

      //Check whether the given word is found in the procedure desc
      if (srcStr.includes(word, 0)) {
        this._wordsResult.add(group);
      } else {
        //Check whether the given word is found in any of the keywords array of proceduresItem
        for (const h of group.hierarchies) {
          const n: number = h.keyWords.length;
          for (let i: number = 0; i < n; i++) {
            const kw: string = h.keyWords[i];
            group.isRelated = kw.toLowerCase().indexOf(word.toLowerCase()) > -1;
            if (group.isRelated) {
              break;
            }
          }
        }
        if (group.isRelated) {
          this._wordsResult.addRelated(group);
        }
      }
    });

    this.result.setToFirstPage();
  }

  /**
   * Get all the procedures starting with given letter OR set of letters seperated by comma
   * @param char
   */
  findByAlphabet(alphabet: IAlphabetItem) {
    if (!alphabet) {
      return;
    }
    if (typeof alphabet.value !== 'string') {
      return;
    }
    if (alphabet.value.trim() === '') {
      //Dont perform search for empty alphabet key
      return;
    }
    if (this._result && alphabet.value.trim().toLowerCase() === this._result.query.toLowerCase()) {
      //Dont perform the same search again and again
      return;
    }

    this._wordsResult.clear();
    this._alphabetsResult.clear();

    //IMPORTANT: Make alphabetsResults as current result object
    this._result = this._alphabetsResult;
    const chars: Array<string> = alphabet.value.split(',').map((c) => c.trim());
    this._alphabetsResult.query = chars.join(', ');
    this._alphabetsResult.title = this._content.procedureSelectionComponent.alphaResTitle
      .replace('[[alphabetValue]]', alphabet.label);
    this._alphabetsResult.noResultsMessage = this._content.procedureSelectionComponent.noResultsFound
      .replace('{{searchValue}}', alphabet.label);
    chars.forEach((c) => {
      const groups = this._letterGroupsMap.get(c);
      this._alphabetsResult.add(c, groups);
    });

    this.result.setToFirstPage();
  }

  get procedureGroupsMap(): Map<string, ProcedureGroup> {
    return this._procedureGroupsMap;
  }

  get ltrGroupsMap(): Map<string, Array<ProcedureGroup>> {
    return this._letterGroupsMap;
  }
  /**
   * Add an array of words to suggestions array
   * @param words
   */
  addSuggestions(words: Array<string>) {
    if (words instanceof Array && words.length > 0) {
      words.forEach((word) => {
        this.addSuggestion(word);
      });
    }
  }

  /**
   * Add a single suggestion word to suggestions array
   * @param word
   */
  addSuggestion(word: string) {
    if (this.dataHlpr.isEmptyString(word)) {
      //Dont add empty strings as suggestion
      return;
    }

    //Avoid extra spaces on the begining and end of the word
    word = word.trim();

    const isValid: boolean = /\w/.test(word);
    if (isValid && this._suggestions.indexOf(word) === -1) {
      this._suggestions.push(word);
    }
  }

  get suggestions(): Array<string> {
    return this._suggestions;
  }

  get result(): ISearchResult {
    return this._result;
  }

  set result(value) {
    this._result = value;
  }

  set content(value) {
    this._content = value;
  }
}
