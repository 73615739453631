import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from './../../../../common/components/core/baseCmp';
import { IProvider } from './../../../../result/interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-specialty-cmp',
  templateUrl: './providerSpecialtyCmp.html'
})
export class ProviderSpecialtyComponent extends BaseComponent {
  @Input() provider: IProvider;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Opens the Healthwise side panel.
   * TODO: Implement the logic to open the Healthwise side panel.
   */
  openHealthwiseSidePanel(): void {
    // TODO: Implement this method
  }
}
