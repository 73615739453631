import { Component, Input } from "@angular/core";
import { IMemberPcp } from "../models/iMemberPcpDetails";
import { DataHelper } from './../../../common/services/dataHelper';

@Component({
  moduleId: module.id,
  selector: "[app-pf-mbr-pcp-cmp]",
  template: `
    <ng-container *ngIf="pcp">
      <strong [innerHTML]="title" class="mbr-pcp-title" *ngIf="showTitle"></strong>
      <div *ngIf="hasName"
        [innerHTML]="pcpName | uppercase"
      ></div>

      <ng-container *ngIf="hasAddress1">
        <div
          [innerText]="pcp.addressOne | titlecase"
          class="mbr-pcp-address-one"
        ></div>
      </ng-container>

      <ng-container *ngIf="hasAddress2">
        <div
          [innerText]="pcp.addressTwo | titlecase"
          class="mbr-pcp-address-two"
        ></div>
      </ng-container>

      <div>
        <span *ngIf="hasCity" [innerText]="pcp.city + ', '" class="mbr-pcp-city"></span>
        <span *ngIf="hasState" [innerText]="pcp.state.code" class="mbr-pcp-state"></span>
      </div>

      <div *ngIf="hasZip">
        <span [innerText]="pcp.zip" class="mbr-pcp-zip"></span>
      </div>

      <ng-container *ngIf="hasPhone">
        <div [innerText]="pcp.phone | phonePattern" class="mbr-pcp-phone"></div>
      </ng-container>

      <div
        *ngIf="hasPcpId"
        [innerText]="labelID + pcp.pcpId"
        class="mbr-pcp-id"
      ></div>
    </ng-container>
  `
})
export class MemberPcpComponent {
  private _pcp: IMemberPcp = null;
  @Input() labelID: string = "";
  @Input() title: string = "";

  showTitle = false;
  hasPcpId = false;
  hasName = false;
  hasAddress1 = false;
  hasAddress2 = false;
  hasCity = false;
  hasState = false;
  hasZip = false;
  hasPhone = false;

  pcpName: string = '';

  constructor(private _dataHlpr: DataHelper) {
  }

  @Input()
  set pcp(value: IMemberPcp) {
    if (this._pcp === value) {
      return;
    }
    value = value ?? {} as IMemberPcp;
    this._pcp = value;

    this.hasPcpId = !this._dataHlpr.isEmptyString(value.pcpId);
    this.pcpName = `${value.pcpFirstName ?? ''} ${value.pcpLastName ?? ''}`.trim();
    this.hasName = this.pcpName.length > 0;
    this.hasAddress1 = !this._dataHlpr.isEmptyString(value.addressOne);
    this.hasAddress2 = !this._dataHlpr.isEmptyString(value.addressTwo);
    this.hasCity = !this._dataHlpr.isEmptyString(value.city);
    this.hasState = !this._dataHlpr.isEmptyString(value.state?.code);
    this.hasZip = !this._dataHlpr.isEmptyString(value.zip);
    this.hasPhone = !this._dataHlpr.isEmptyString(value.phone);

    this.showTitle = this.hasName || this.hasAddress1 || this.hasAddress2 || this.hasCity || this.hasState || this.hasZip || this.hasPhone;
  }
  get pcp(): IMemberPcp {
    return this._pcp;
  }
}
