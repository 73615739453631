import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

/**
 *  Person name (first last) input field validator
 *  set validNoSpecialChar when input contain forbidden special chars
 *
 *  ###Example
 *
 *  ```html
 *  <form>
 *      <input type="text" name="txtEmail" data-tcp-name-valid-dir/>
 *      <span *ngIf="txtEmail.errors.validNoSpecialChar">should not have special chars</span>
 *  </form>
 *  ```
 */
@Directive({
  selector: '[name-valid-dir]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NameValidatorDirective), multi: true }]
})
export class NameValidatorDirective implements Validator {
  @Input()
  disableapostrophe: boolean = false;

  charReg = new RegExp('^[^|;$%()+,~<>="&*?#@^@^”’\\\\/\'"\\[""\\]"]+$');

  validate(c: FormControl) {
    if (this.disableapostrophe) {
      this.charReg = new RegExp('^[^|;$%()+,~<>="&*?#@^@^”’\\\\/"\\[""\\]"]+$');
    }
    const result: any = {};
    if (this.validateNoSpecialChar(c)) {
      result.validNoSpecialChar = true;
    }

    return result;
  }

  private validateNoSpecialChar(c: FormControl): any {
    return !(!c.value || this.charReg.test(c.value));
  }
}
