import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-fad-search-home-cmp',
  template: ` <app-fad-pf-search-v2-cmp></app-fad-pf-search-v2-cmp> `
})
export class PFSearchHomeComponent extends BaseComponent {
  constructor(route: ActivatedRoute, evtHandler: EventHandler, appSession: AppSession, contentHlpr: ContentHelper) {
    super(route, evtHandler, appSession, contentHlpr, 'PFSearchContainerComponent');
  }
}
