import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CareCircleService } from '../../../care-circle/services/careCircleSvc';
import { MemberPCPHelper } from '../../../care-circle/services/memberPCPHelper';
import { ChangePlanService } from '../../../common/components/change-plan/services/changePlanSvc';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { IWindow } from '../../../common/interfaces/iWindow';
import { DataHelper } from '../../../common/services/dataHelper';
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { UserImpressionUtil } from '../../../common/utilities/userImpressionUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { ProcedureCategoryService } from '../../../eyc/cost-search/services/procedureCategorySvc';
import { RulesService } from '../../rules/services/RulesSvc';
import { ProviderSummaryService } from '../../search-results/services/providerSummarySvc';
import { CareActionMessageSvc } from '../services/careActionMessageSvc';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { QuickLinksService } from '../services/quickLinksSvc';
import { IApiStatusEvent } from './../../../common/interfaces/iEvent';
import { PFDynamicSearchLinksComponent } from './pfDynamicSearchLinksCmp';
import { PFFreeTxtSearchComponent } from './pfFtsCmp';
import { PFIntegratedSearchComponent } from './pfIntegratedSearchCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-dynamic-search-links-v2-cmp',
  templateUrl: '../views/pfDynamicSearchLinksV2Cmp.html',
  providers: [],
})
export class PFDynamicSearchLinksV2Component extends PFDynamicSearchLinksComponent {
  @Input() showChineseLink: boolean = false;
  @Input() isIntegratedMode: boolean = false;
  @Input() isShowChinese: boolean = false;
  @Input() planSelection: boolean = false;
  @Input() searchCriteria: boolean = false;
  @Output() onChangeLanguageEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() onMotionPointEvent: EventEmitter<string> = new EventEmitter<string>();

  hideCareTeamCard: boolean = false;
  showCareTeamApiLoader: boolean = false;

  constructor(
    _providerSummarySvc: ProviderSummaryService,
    _changeDetectorRef: ChangeDetectorRef,
    @Inject(AppSession)
    _appSession: AppSession,
    @Inject("Window")
    _window: IWindow,
    _providerUtilityService: ProviderUtilityService,
    _procedureSvc: ProcedureCategoryService,
    _route: ActivatedRoute,
    _navigationService: NavigationService,
    _ruleService: RulesService,
    _careActionMsgService: CareActionMessageSvc,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    @Inject(DataHelper)
    _dataHelper: DataHelper,
    _eventHandler: EventHandler,
    _appUtility: AppUtility,
    _changePlanService: ChangePlanService,
    _titleCasePipe: TitleCasePipe,
    _infoListSvc: InfoListService,
    _userImpressionUtil: UserImpressionUtil,
    _careCricleSvc: CareCircleService,
    _memberPCPHelper: MemberPCPHelper,
    _quickLinksService: QuickLinksService
  ) {
    super(
      _providerSummarySvc,
      _changeDetectorRef,
      _appSession,
      _window,
      _providerUtilityService,
      _procedureSvc,
      _route,
      _navigationService,
      _ruleService,
      _careActionMsgService,
      _contentHelper,
      _dataHelper,
      _eventHandler,
      _appUtility,
      _changePlanService,
      _titleCasePipe,
      _infoListSvc,
      _userImpressionUtil,
      _careCricleSvc,
      _memberPCPHelper,
      _quickLinksService
    );
  }

  @Output()
  loadCahmoPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('integratedsearch')
  integratedSearchCmp: PFIntegratedSearchComponent;
  @ViewChild('ftSearch') //FreeTextSearch
  ftSearchCmp: PFFreeTxtSearchComponent;

  relayLoadCaHmoPageEvent() {
    this.loadCahmoPage.emit(true);
  }

  // In the new UI we are not displaying Member name along with Plan name.
  // So, Override the parent method and Do Not join Member name.
  joinMemberNameWithPlanName(planTitleContent: string, planNm: string, memberNm: string) {
    this.selectedPlanText = planNm;
  }

  onChangeLanguageClick(language: string) {
    this.onChangeLanguageEvent.emit(language);
  }

  onChineseLanguageClick(language: string) {
    this.onMotionPointEvent.emit(language);
  }

  onCareCircleApiStatusChange(event: IApiStatusEvent) {
    this.showCareTeamApiLoader = event.isProgress;
    this.hideCareTeamCard = !event.hasValidData;
  }
}
