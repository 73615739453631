import { TitleCasePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AlertHelper } from "@anthem/uxd/alert";
import { ModalComponent } from "@anthem/uxd/deprecated";
import { ModalRef, SidePanel } from "@anthem/uxd/modal";
import { Subscription } from "rxjs";
import { ClinicalProgramNavigator } from "../../../app/common/services/clinicalProgramNav";
import { IRulesResponse } from "../../../app/fad/rules/interfaces/iRulesResponse";
import { EmailComponent } from "../../common/components/email/pfEmailCmp";
import { ProviderUtilityService } from "../../fad/search-providers/services/providerUtilitySvc";
import {
  ADDCARETEAM_MESSAGE,
  ALERT_TYPE,
  PROVIDER_TYPE
} from "../../fad/search-providers/values/providerSearchConstants";
import { CommonUtil } from "../../fad/utilities/commonUtil";
import { ResultsUtil } from "../../fad/utilities/resultsUtil";
import { CARE_TEAM_TYPE } from '../constants/careCircleConstants';
import { IAdvocateDetails, ICareCircle } from "../interfaces/iCareCircle";
import {
  ICareProvider,
  ICareTeamEmailRequest,
  IProvider
} from "../interfaces/iCareProvider";
import { CareCircleService } from "../services/careCircleSvc";
import { MemberPCPHelper } from "../services/memberPCPHelper";
import { CareCircleUtil } from "../utilities/careCircleUtil";
import { BaseComponent } from "./../../common/components/base-component/baseCmp";
import { ChangePlanService } from "./../../common/components/change-plan/services/changePlanSvc";
import { InfoListService } from "./../../common/components/info-list/services/infoListSvc";
import { PFDrivingDirectionComponent } from "./../../common/components/map/pfDrivingDirectionCmp";
import {
  AppConstants,
  AppExternalCommands,
  OPTIONAL,
  REQUIRED,
  RIGHT
} from "./../../common/constants/app-constants";
import { AppNavigations } from "./../../common/constants/app-navigations";
import { SEARCH_SCENARIOS } from "./../../common/constants/strategy-search-constant";
import { AppEvents } from "./../../common/enums/appEvents";
import {
  IOptions,
  RelationshipStatus
} from "./../../common/interfaces/iAppMetadata";
import { IEventDetail } from "./../../common/interfaces/iEventDetail";
import { IMessage } from "./../../common/interfaces/iMessage";
import { LocationResponse } from "./../../common/models/locationAddress";
import { EventHandler } from "./../../common/services/eventHandler";
import { NavigationService } from "./../../common/services/navigationService";
import { SearchSvc } from "./../../common/services/searchSvc";
import { AppUtility } from "./../../common/utilities/appUtil";
import { AppSession } from "./../../common/values/appSession";
import { ContentHelper } from "./../../common/values/contentHelper";
import { RulesService } from "./../../fad/rules/services/RulesSvc";
import { SAVNavigationService } from "./../../fad/search-providers/services/savNavigationSvc";
import {
  PROVIDER_SEARCH_DISTANCE,
  REMOVECARETEAM_FAILURE_MESSAGE,
  REMOVECARETEAM_SUCCESS_MESSAGE
} from "./../../fad/search-providers/values/providerSearchConstants";

declare let _satellite: any;

@Component({
  moduleId: module.id,
  selector: "app-care-circle-cmp",
  templateUrl: "../views/careCircleContainerCmp.html",
})
export class CareCircleContainerComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @ViewChild("careMapModal")
  careMapModal: ModalComponent;
  @ViewChild("DrivingDirections")
  drivingDirection: PFDrivingDirectionComponent;
  @ViewChild("removeProviderModal")
  removeProviderModal: TemplateRef<HTMLElement>;
  @ViewChild("emailModal")
  emailModal: EmailComponent;
  @ViewChild("founderInfoPanelTmplt")
  founderInfoPanel: TemplateRef<HTMLElement>;
  @ViewChild("viewPCPDescription")
  viewPCPDescription: TemplateRef<HTMLElement>;

  drivingModalTitle: any = {
    id: "map-details-modal",
    title: this.content.mapModal.directions,
  };
  noPrint: boolean = false;
  showLoader: boolean = true;
  isSecureState: boolean = false;
  showPlanUnknownError: boolean = false;
  noResults: boolean = false;
  careProviders: Array<ICareProvider> | Array<IAdvocateDetails> = [];
  selectedPlanText: string = "";
  planUnknownErrorMsg: string = "";
  memberName: string = "";
  sortSelected: string = "3";
  sortOrder = false;
  selectedSortOption = 4;
  removeProvider: any;
  removeProviderConfirm: string = "";
  removeProviderPanelRef: ModalRef<any, any>;
  showBackToLink: boolean = true;
  showPageLoader: boolean = false;
  selectedMbr: IOptions;
  isEmulation: boolean = false;
  founderInfo: ICareProvider;

  sortFilter: any = [
    {
      label: this.content.careProvidersComponent.labels.sortByFirstName,
      value: 1,
    },
    {
      label: this.content.careProvidersComponent.labels.sortByLastName,
      value: 2,
    },
    {
      label: this.content.careProvidersComponent.labels.sortByDateService,
      value: 3,
    },
    {
      label: this.content.careProvidersComponent.labels.sortByNickname,
      value: 4,
    },
  ];

  private _headerEvent: EventEmitter<any> = this._eventHandler.get(
    AppEvents[AppEvents.APP_HEADER]
  );
  private navigationSubscription;
  private changePlanSaveClickSubscription: Subscription;
  sidePanelRef: ModalRef<HTMLElement>;
  hasPCPOptionalIndicator = false;
  hasPCPRequiredIndicator = false;
  hasPCP = false;
  pcpName = '';
  hasPCPFeature = false;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private searchService: SearchSvc,
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    private _careCircleSvc: CareCircleService,
    private _changePlanService: ChangePlanService,
    private _appUtility: AppUtility,
    private _titlecasePipe: TitleCasePipe,
    private _sidePanel: SidePanel,
    private _infoListSvc: InfoListService,
    private _alertHlpr: AlertHelper,
    private _ruleService: RulesService,
    private _providerUtilityService: ProviderUtilityService,
    private _savNavigationService: SAVNavigationService,
    private _clinicalProgramNav: ClinicalProgramNavigator,
    private _memberPCPHelper: MemberPCPHelper
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "CareCircleContainerComponent"
    );

    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initializeInvites();
      }
    });

    this.changePlanSaveClickSubscription =
      this._changePlanService.onChangePlanSaveClick.subscribe(
        (path: AppNavigations) => {
          if (path === AppNavigations.CARE_CIRCLE_PATH) {
            this.ngOnInit();
          }
        }
      );
  }

  initializeInvites() {
    this.setPageHeader();
  }

  /***
   * set the Page Header and Title dynamically based on pages
   */
  setPageHeader() {
    if (
      this.content.globalHeaderComponent &&
      this.content.globalHeaderComponent.pageHeader &&
      this.content.globalHeaderComponent.pageHeader.carePageTitle
    ) {
      const pageFadObj = {
        header: this.content.globalHeaderComponent.pageHeader.carePageTitle,
        title: this.content.globalHeaderComponent.pageTitle.careProviders,
      };
      this._headerEvent.emit(pageFadObj);
    }
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.showBackToLink =
        !this._appSession.isFirstTimeLoad &&
        (this._navigationService.previousRoute ===
          AppNavigations.SEARCH_PROVIDERS_PATH ||
          this._navigationService.previousRoute ===
          AppNavigations.SEARCH_RESULTS_PATH ||
          this._navigationService.previousRoute ===
          AppNavigations.PROVIDER_DETAILS_PATH ||
          this._navigationService.previousRoute ===
          AppNavigations.ASSIGN_PCP_PATH);
      this.onPreLoad();
    }
    this._appUtility.scrollToTop();
    this.initCalls();
    this.getRules();
    if (this._appSession.metaData.appContract.hasCastlightTransparency) {
      this.showBackToLink = false;
    }
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }

  /// Write all ngOnInit call methods here
  initCalls() {
    this._infoListSvc.reset();
    this.showPlanUnknownError = false;
    this.careProviders = [];
    let _planName = "";
    let _memberName = "";
    let _memberNameText = "";
    let _memberZip = null;
    this.memberName = this.content.careProvidersComponent.labels.myProviders;
    let _selectedPlanText = this.content.careProvidersComponent.labels.selectedPlan;
    const _searchParams = this._appSession.searchParams;
    this.isSecureState = CommonUtil.isMemberSecure(this._appSession);
    this.selectedMbr = null;
    this.isEmulation = this._appSession.isEmulation;

    let _index;
    if (
      this._appSession.metaData &&
      this._appSession.metaData.appContract &&
      this._appSession.metaData.appContract.memberList
    ) {
      if (this._appSession.metaData.appContract.mbrUid) {
        _index = this._appSession.metaData.appContract.memberList.findIndex(
          (x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid
        );
      } else {
        _index = this._appSession.metaData.appContract.memberList.findIndex(
          (x) => x.isChecked === true
        );
      }
      if (_index > -1) {
        const _member: IOptions =
          this._appSession.metaData.appContract.memberList[_index];
        this.selectedMbr = _member;
        const relation =
          _member && _member.relationship
            ? this.getRelationshipStatus(_member.relationship.toLowerCase())
            : "";
        _memberName =
          this.titleCase(_member.firstName);
        _memberNameText = this.titleCase(
          _member.firstName + " " + _member.lastName
        );
        this.memberName =
          this.content.careProvidersComponent.labels.careTeamFor.replace(
            /{NAME}/gi,
            _memberName.trim()
          );
        _memberZip =
          _member.address && _member.address.postalCode
            ? _member.address.postalCode
            : null;
      }
    }

    if (_searchParams.plan) {
      _planName = _searchParams.plan.name;
      if (!_planName && _searchParams.plan.securePlanLabelKey) {
        _planName = _searchParams.plan.securePlanLabelKey;
      }

      if (
        _planName ===
        this.content.searchCriteriaComponent.labels[
        AppConstants.PlanUnknown
        ] ||
        _searchParams.plan === undefined
      ) {
        _planName =
          this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown];
        this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
        this.showPlanUnknownError = true;
      } else {
        if (this.isSecureState) {
          const _plan = ResultsUtil.getPlanNetworkPrefixText(this._appSession);
          if (_plan) {
            _planName = _plan;
          }
          _selectedPlanText = this.content.careProvidersComponent.labels.selectedMemberPlan;
        }
      }
    } else {
      _planName =
        this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown];
      this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
      this.showPlanUnknownError = true;
    }

    if (_memberZip) {
      this.bindMemberLocation(_memberZip);
    }
    this.selectedPlanText = _selectedPlanText
      .replace(/{PLAN}/gi, _planName)
      .replace(/{NAME}/gi, _memberNameText.trim());

    if (this.isSecureState) {
      this.getCareTeam();
    }
  }

  getRelationshipStatus(relation: string): string {
    let relationshipStatus = "";
    if (relation) {
      if (RelationshipStatus.Subscriber === relation) {
        relationshipStatus =
          this.content.careProvidersComponent.labels.subscriber;
      } else if (RelationshipStatus.Spouse === relation) {
        relationshipStatus = this.content.careProvidersComponent.labels.spouse;
      } else if (RelationshipStatus.Dependent === relation) {
        relationshipStatus =
          this.content.careProvidersComponent.labels.dependent;
      } else {
        relationshipStatus = relation;
      }
    }

    return relationshipStatus;
  }

  getCareTeam() {
    this.careProviders = [];
    this.showLoader = true;
    this._careCircleSvc
      .getCareCircle(
        CareCircleUtil.buildCareCircleRequest(
          this._appSession,
          CARE_TEAM_TYPE.SELECTED
        )
      )
      .then(
        (result: ICareCircle) => {
          this.showLoader = false;
          this.pcpName = '';
          this.hasPCP = false;
          this.hasPCPFeature = false;
          this.hasPCPOptionalIndicator = this._appSession?.pcpProductType === OPTIONAL;
          this.hasPCPRequiredIndicator = this._appSession?.pcpProductType === REQUIRED;
          if (
            result &&
            result.pcp &&
            result.pcp.data &&
            result.pcp.data.length > 0
          ) {
            const pcpList = CareCircleUtil.bindCareProviders(result.pcp);
            this.careProviders = [...this.careProviders, ...pcpList];
            this.bindFutureEffectiveInfoLabel();
            this.pcpName = result.pcp.data[0].provider?.fullNm;
            this.hasPCP = true;
          }
          if (result?.pcp?.hasPCPFeature) {
            this.hasPCPFeature = true;
          }
          if (
            result &&
            result.advocates &&
            result.advocates.data &&
            result.advocates.data.length > 0
          ) {
            const advocateList = CareCircleUtil.bindAdvocates(
              result.advocates.data
            );
            this.careProviders = [...this.careProviders, ...advocateList];
          }
          if (
            result &&
            result.managers &&
            result.managers.data &&
            result.managers.data.length > 0
          ) {
            const managerList = CareCircleUtil.bindManagers(
              result.managers.data
            );
            this.careProviders = [...this.careProviders, ...managerList];
          }
          if (
            result &&
            result.careTeam &&
            result.careTeam.data &&
            result.careTeam.data.length > 0
          ) {
            result.careTeam.hasPCPFeature = result.pcp?.hasPCPFeature;
            result.careTeam.hasPCPRemoveFeature =
              result.pcp?.hasPCPRemoveFeature;
            const careTeamList = CareCircleUtil.bindCareProviders(
              result.careTeam
            );
            this.careProviders = [...this.careProviders, ...careTeamList];
          }
          if (this.careProviders.length > 0) {
            this.noResults = false;
          } else {
            this.noResults = true;
          }
          CareCircleUtil.showOnlinePharmacy(this.careProviders);
          this._memberPCPHelper.updatePcpProviderSession(
            result
          );
        },
        (error: any) => {
          this.showLoader = false;
          this.noResults = true;
          this.onCategoryError("CARETEAM", error);
        }
      );
  }

  bindFutureEffectiveInfoLabel() {
    const _memberName =
      this.titleCase(this.selectedMbr?.firstName) +
      " " +
      this.titleCase(this.selectedMbr?.lastName);

    (this.careProviders as any[]).map((provider) => {
      let futureEffectiveDateTooltip =
        this.content.careProvidersComponent.labels.pcpProviderToolTip;
      futureEffectiveDateTooltip =
        _memberName && _memberName !== ""
          ? futureEffectiveDateTooltip.replace(/{MEMBER NAME}/gi, _memberName)
          : futureEffectiveDateTooltip.replace(/{MEMBER NAME}/gi, "");

      if (provider && provider.isPCP && provider.isFuturePCP) {
        futureEffectiveDateTooltip =
          provider && provider.fullNm && provider.fullNm !== ""
            ? futureEffectiveDateTooltip.replace(
              /{DOCTOR NAME}/gi,
              provider.fullNm
            )
            : futureEffectiveDateTooltip.replace(/{DOCTOR NAME}/gi, "");

        futureEffectiveDateTooltip =
          provider &&
            provider.mbrPcpEffectiveDt &&
            provider.mbrPcpEffectiveDt !== ""
            ? futureEffectiveDateTooltip.replace(
              /{EFFECTIVE DATE}/gi,
              provider.mbrPcpEffectiveDt
            )
            : futureEffectiveDateTooltip.replace(/{EFFECTIVE DATE}/gi, "");

        provider.futureEffectiveDateTooltip = futureEffectiveDateTooltip;
      }
      return provider;
    });
  }

  updateCareTeam(providerObj: IProvider) {
    const disclaimerCodes: IMessage[] = [];
    if (providerObj) {
      const _isRemoved = providerObj.isRemoved;
      this._careCircleSvc
        .updateCareTeam(
          CareCircleUtil.buildUpdateCareTeamRequest(this._appSession, [
            providerObj,
          ])
        )
        .then(
          (result: any) => {
            if (_isRemoved) {
              const _message = {
                contentId: REMOVECARETEAM_SUCCESS_MESSAGE,
                content: this.buildMessage(true),
                type: ALERT_TYPE.POSITIVE,
                focus: true,
              } as IMessage;

              disclaimerCodes.push(_message);
              this._infoListSvc.setInfoList(disclaimerCodes);
              this.removeProvider = null;

              //After remove care team providers reload.
              this.getCareTeam();
            }
          },
          (error: any) => {
            if (_isRemoved) {
              const _message = {
                contentId: REMOVECARETEAM_FAILURE_MESSAGE,
                content: this.buildMessage(false),
                type: ALERT_TYPE.NEGATIVE,
                focus: true,
              } as IMessage;

              disclaimerCodes.push(_message);
              this._infoListSvc.setInfoList(disclaimerCodes);
              this.removeProvider = null;
            }
            this.onCategoryError("UPDATE_CARETEAM", error);
          }
        );
    }
  }

  buildMessage(isSuccess: boolean) {
    let _message = "";
    if (this.selectedMbr) {
      const _memberName =
        this.titleCase(this.selectedMbr.firstName) +
        " " +
        this.titleCase(this.selectedMbr.lastName);
      const _providerName = this.titleCase(this.removeProvider.fullNm.trim());

      if (isSuccess) {
        _message =
          this.content.commonContents.labels[REMOVECARETEAM_SUCCESS_MESSAGE];
      } else {
        _message =
          this.content.commonContents.labels[REMOVECARETEAM_FAILURE_MESSAGE];
      }
      _message = _message
        .replace(/{MEMBER}/gi, _memberName.trim())
        .replace(/{PROVIDER}/gi, _providerName.trim());
    }

    return _message;
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  onChangePlanClick() {
    this._changePlanService.setPlanSelectionLinkClick(true);
  }

  getMedicalUnknownNetworkMsg() {
    this.showPlanUnknownError = true;
    const planUnknownMsg = this.customizedFeatures.showChangePlan
      ? this.content.searchCriteriaComponent.alerts.networkUnknownMsg
      : this.WcsUtility.getUnknownNetworkMsg(PROVIDER_TYPE.DOCTOR_PROFESSIONAL);
    return planUnknownMsg;
  }

  onSortOrderClick() {
    this.sortOrder = !this.sortOrder;
    this.filterSortCareTeam();
  }

  onSortOptionChange(event: any) {
    this.selectedSortOption = event.value;
    this.filterSortCareTeam();
  }

  filterSortCareTeam() {
    let tempData = [...this.careProviders];
    let sortKey = `${this.sortOrder ? "-" : "+"}`;

    switch (this.selectedSortOption) {
      case 1:
        sortKey += "firstNm";
        break;
      case 2:
        sortKey += "lastNm";
        break;
      case 3:
        // dates are sorted a-z -> latest to oldest
        sortKey = `${this.sortOrder ? "+" : "-"}`;
        sortKey += "lastVisitDate";
        break;
      case 4:
        sortKey += "nickName";
        break;
    }

    tempData = CareCircleUtil.Sort(tempData, [sortKey]);
    this.careProviders = tempData;
  }

  onNicknameUpdate(careTeam: any) {
    const _provider = {
      nickName: careTeam.modifiedNickname,
      providerId: careTeam.id,
    } as IProvider;
    this.updateCareTeam(_provider);
  }

  updateProviderRemoval(provider: any) {
    const _memberName =
      this.titleCase(this.selectedMbr?.firstName) +
      " " +
      this.titleCase(this.selectedMbr?.lastName);
    this.removeProviderConfirm =
      this.content.careProvidersComponent.labels.removeCareProviderConfirm
        .replace(/{NAME}/gi, provider.fullNm.trim())
        .replace(/{MEMBER}/gi, _memberName.trim());
    this.removeProvider = provider;
    this.removeProviderPanelRef = this._sidePanel.open(
      "right",
      this.removeProviderModal
    );
  }

  removeCareProvider() {
    const _provider = {
      isRemoved: this.removeProvider.isRemoved,
      providerId: this.removeProvider.id,
    } as IProvider;
    this.updateCareTeam(_provider);
    if (typeof _satellite !== "undefined") {
      _satellite.track("removeCareTeamModalFindCare");
    }
    this.removeProviderPanelRef.close();
  }

  closeRemoveProviderModal() {
    if (typeof _satellite !== "undefined") {
      _satellite.track("cancelRemoveCareTeamModalFindCare");
    }
    this.removeProviderPanelRef.close();
  }

  onPrintPage() {
    this.noPrint = false;
    setTimeout(() => {
      window.print();
    }, 300);
  }

  onEmailClick() {
    // Email
    this.emailModal.show();
  }

  onSendEmail(emailId: string) {
    const emailRequest: ICareTeamEmailRequest =
      CareCircleUtil.buildCareTeamEmailRequest(this._appSession, emailId);
    this._careCircleSvc.sendCareTeamEmail(emailRequest).then(
      () => {
        // onSuccess
      },
      () => {
        // onError
      }
    );
    this.showLoader = false;
  }

  addCareTeam() {
    const _message = {
      contentId: ADDCARETEAM_MESSAGE,
      type: ALERT_TYPE.INFORMATION,
    } as IMessage;
    this._infoListSvc.setInfoList([_message]);
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
  }

  goToSearch() {
    this._infoListSvc.reset();
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
  }

  getDirection(careTeam: ICareProvider) {
    if (careTeam.address) {
      const _careTeamAddress = careTeam.address;
      const _addressOne = _careTeamAddress.addressLineOne;
      const _addressTwo =
        (_careTeamAddress.city ? _careTeamAddress.city + ", " : "") +
        (_careTeamAddress.stateCd ? _careTeamAddress.stateCd.code : "") +
        (_careTeamAddress.zipCd ? " " + _careTeamAddress.zipCd : "");
      const _address = {
        addressOne: _addressOne,
        addressTwo: _addressTwo,
        latitude: "",
        longitude: "",
      };
      const _provider = {
        location: {
          address: _address,
        },
      };
      this.careMapModal.show();
      this.drivingDirection.openMapModal(_provider);
    }
  }

  closeModal(modal: string) {
    this.noPrint = false;
    this.careMapModal.hide();
  }

  printDrivingDirection() {
    this.noPrint = true;
    setTimeout(() => {
      window.print();
    }, 300);
  }

  refreshCareteam() {
    this.getCareTeam();
  }

  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  bindMemberLocation(zipCode) {
    this.getGeoCoordinates(zipCode);
  }

  getGeoCoordinates(inputText) {
    if (inputText.match(/^[0-9]+$/) != null) {
      if (inputText.length >= 5) {
        this._providerUtilityService
          .getGeoLocation(inputText)
          .then((result: LocationResponse[]) => {
            if (result && Object.keys(result).length) {
              const results = this._appUtility.buildLocationResponse(result);
              const coordinates: any = results[Object.keys(results)[0]];
              this._appSession.searchParams.zip = inputText;
              this._appSession.searchParams.coordinates = {
                longitude: "",
                latitude: "",
              };
              this._appSession.searchParams.coordinates.latitude =
                coordinates.lat;
              this._appSession.searchParams.coordinates.longitude =
                coordinates.long;
              if (
                this._appSession.metaData &&
                this._appSession.metaData.appContract
              ) {
                const _county =
                  this._appSession.metaData.appContract.memberCounty;
                if (!_county || _county === '') {
                  this._appSession.metaData.appContract.memberCounty =
                    coordinates.county;
                }
              }
              this._appSession.searchParams.stateCd = {
                code: coordinates.stateCd,
              };
              this._appSession.resetGeoLocation();
            }

            this._appSession.searchParams.city = "";
            this._appSession.searchParams.countyCode = null;
          });
      }
    }
  }

  getEmailPopupHeader() {
    return this.content.careProvidersComponent.labels.emailModalHeader;
  }

  onAppointmentSchedule(data: any) {
    if (data && data.loader) {
      this.showPageLoader = true;
    } else {
      this.showPageLoader = false;
      if (data && data.apiError) {
        this._alertHlpr.openAlert(this.content.alerts.apiError, 'negative');
      }
    }
  }

  getRules() {
    this._ruleService
      .getRules(
        CommonUtil.buildRuleRequest(
          this._appSession.searchParams,
          this._appSession
        )
      )
      .then(
        (data: IRulesResponse) => {
          if (data) {
            this._appSession.feature = data;
          }
        },
        (error: any) => {
          throw error;
        }
      );
  }

  showFounderDetail(founderInfo: ICareProvider) {
    this.founderInfo = founderInfo;
    this.removeProviderPanelRef = this._sidePanel.open(
      "right",
      this.founderInfoPanel
    );
  }

  closeFounderSlider() {
    if (typeof _satellite !== "undefined") {
      _satellite.track("cancelFounderSliderFindCare");
    }
    this.removeProviderPanelRef?.close();
  }

  onContactUsClick() {
    this.closeFounderSlider();
    const appCmdEvt: EventEmitter<IEventDetail> = this._eventHandler.get(
      AppEvents[AppEvents.PF_APP_COMMAND]
    );
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_CONTACT_US.MSG;
    eventDetail.target = AppExternalCommands.RCP_CONTACT_US.CMD;
    appCmdEvt.emit(eventDetail);
  }

  searchByProviderCode(code: string) {
    code = code?.trim().toUpperCase();
    if (!code) {
      return;
    }
    this.closeFounderSlider();
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchParams.typeSelectNm = code;
    this._appSession.searchParams.coverageTypeCode = '';
    this._appSession.searchParams.providerTypeCodeList = [code];
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.searchParams.specialtySelectNm = [];
    this._appSession.searchParams.taxonomySelectNm = [];

    this._appSession.filterSearchParams = undefined;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
    this._appSession.isMedicalCodeSearch = false;
    this.searchService.currentScenario = CommonUtil.isMemberSecure(this._appSession) ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this._savNavigationService.navigate();
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  navigateToProgram(programId: string) {
    this.closeFounderSlider();
    this._clinicalProgramNav.navigateToClinicalProgram(programId);
  }

  // Add pcp link will navigate to results page with pcp rules applied
  onAddPCPClick() {
    if (this._appSession?.searchParams?.plan?.securePlanLabelKey === AppConstants.MyPlan) {
      this.searchService.currentScenario = CommonUtil.isMemberSecure(this._appSession) ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      this._appSession.searchParams.typeSelectNm = '';
      this._appSession.searchParams.providerTypeCodeList = [];
      this._appSession.searchParams.ableToServePcp = true;
      this.resetAppSessionParams();
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
    this.closeSlider();
  }

  resetAppSessionParams() {
    this._appSession.searchParams.provNm = '';
    this._appSession.searchParams.coverageTypeCode = '';
    this._appSession.searchParams.taxonomySelectNm = [];
    this._appSession.searchParams.standAloneDental = null;
    this._appSession.searchParams.standAloneVision = null;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
  }

  openSlider() {
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.viewPCPDescription);
  }

  closeSlider() {
    this.sidePanelRef?.close();
  }
}
