import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { AssignCareComponent } from './assignCareCmp';

@NgModule({
    declarations: [AssignCareComponent],
    exports: [AssignCareComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        UxSharedModule,
        CommonModule
    ]
})
export class AssignCareModule { }
