import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-pf-cost-info-cmp',
  templateUrl: './pfCostInfoCmp.html'
})
export class PFCostInfoComponent extends BaseComponent {
  @ViewChild('costDetailModal')
  costDetailModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<any, any>;
  costInfo: any = {};
  costEst: any = {};
  coinsuranceDesc: string;
  hraCost: string;
  hraCostAriaLabel: string;
  hraDesc: string;
  hraDescAriaLabel: string;
  copay: string;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _sidePanel: SidePanel,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession) {
    super(_route, _eventHandler, _appSession, _contentHelper);
  }

  openSidePanel(direction, costDetails) {
    this.costEst = costDetails.costEstimate;
    this.costInfo = costDetails.costInfo;
    this.coinsuranceDesc = costDetails.coinsuranceDesc;
    this.hraCost = costDetails.hraCost;
    this.hraCostAriaLabel = costDetails.hraCostAriaLabel;
    this.hraDesc = costDetails.hraDesc;
    this.hraDescAriaLabel = costDetails.hraDescAriaLabel;
    this.copay = costDetails.copay;
    this.sidePanelRef = this._sidePanel.open(direction, this.costDetailModal);
  }

  onClose() {
    this.sidePanelRef.close();
  }

}
