import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { SsoModule } from '../components/sso/ssoModule';
import { RatingsSlideOutComponent } from './ratingsSlideOutCmp';
import { VitalsSSOLinkComponent } from './vitalsSSOLinkCmp';

@NgModule({
  declarations: [RatingsSlideOutComponent, VitalsSSOLinkComponent],
  exports: [RatingsSlideOutComponent, VitalsSSOLinkComponent],
  imports: [CommonModule, UxSharedModule, SsoModule]
})
export class RatingsSlideOutModule { }
