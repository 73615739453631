import { TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, HostListener, Inject, Injectable, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper, AlertService, SnackbarAlertContainerComponent } from '@anthem/uxd/alert';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { cloneDeep, isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { MemberPCPHelper } from '../../../care-circle/services/memberPCPHelper';
import { IDeeplink } from '../../../common/components/app-secure/models/iDeeplink';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { ChangePlanService } from '../../../common/components/change-plan/services/changePlanSvc';
import { OONDetailsComponent } from '../../../common/components/common-alert/oonDetailsCmp';
import { PFDisclaimerComponent } from '../../../common/components/disclaimers/pfDisclaimerCmp';
import { EmailComponent } from '../../../common/components/email/pfEmailCmp';
import { ViewHealthwiseComponent } from '../../../common/components/healthwise/pfViewHealthwiseCmp';
import { IHelpfulHint } from '../../../common/components/helpfulhints/IPfHelpfulHints';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { LeavingDisclaimerComponent } from '../../../common/components/leaving-disclaimer/leavingDisclaimerCmp';
import { PFDrivingDirectionComponent } from '../../../common/components/map/pfDrivingDirectionCmp';
import { RecognitionSlideOutComponent } from '../../../common/components/search-slide-out/recognitionSlideOutCmp';
import { CptSearchSlideOutComponent } from '../../../common/components/search-slide-out/searchSlideOutCmp';
import { CptSearchSlideOutTabs } from '../../../common/components/search-slide-out/searchSlideOutConstants';
import {
  AppErrorMsg,
  AppExternalCommands,
  BRANDCODE,
  CONCATE_SYMBOL,
  ContentLanguageCode,
  ERROR_CODES,
  Locale,
  PLANTYPE_OON,
  PROCEDURE_LIST_TYPE,
  RIGHT,
  SNACKBAR,
  STATE_NJ,
  TILDE_SYMBOL
} from '../../../common/constants/app-constants';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { ISearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../common/enums/appEvents';
import { IException } from '../../../common/interfaces/iAppExceptions';
import { IAppContract, IOptions } from '../../../common/interfaces/iAppMetadata';
import { IFinalProvider, ISearchProvider } from '../../../common/interfaces/iCommonSearchResponse';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { IMessage, MsgCategory } from '../../../common/interfaces/iMessage';
import { IRecognition } from '../../../common/interfaces/iSearchStrategyResponse';
import { ISecureTccMedicalSearchRequest } from '../../../common/interfaces/iSecureTccMedicalSearchRequest';
import { DataHelper } from '../../../common/services/dataHelper';
import { DataService } from '../../../common/services/dataService';
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { SearchSvc } from '../../../common/services/searchSvc';
import { SecureV1CptMedical } from '../../../common/services/secureV1CptMedicalSvc';
import { SecureV1TccMedical } from '../../../common/services/secureV1TccMedicalSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IPillFilterOrder, IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { PFFreeTxtSearchComponent } from '../../search-providers/components/pfFtsCmp';
import { PFIntegratedSearchComponent } from '../../search-providers/components/pfIntegratedSearchCmp';
import { ProcedureList } from '../../search-providers/interfaces/ftsProcedureList';
import { ITranslation } from '../../search-providers/interfaces/iAdaListResp';
import { ICptCode } from '../../search-providers/interfaces/iCptLookup';
import { IFtsSuggestion } from '../../search-providers/interfaces/iFtsClientInteraction';
import { IProcedureLookup } from '../../search-providers/interfaces/iProcedureLookup';
import { IProviderSearchDdlValues } from '../../search-providers/interfaces/iProviderSearchDdlValues';
import { ISearchParameters } from '../../search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from '../../search-providers/services/providerUtilitySvc';
import { SAVNavigationService } from '../../search-providers/services/savNavigationSvc';
import {
  ALERT_TYPE,
  BCP_MESSAGE,
  CLINICAL_ADOBE_TRACKS,
  DENTALPROCEDURE_MESSAGE,
  EYC_RETURN_PROVIDER_TYPE,
  INDICATORS,
  INITIAL_VISIT_COST_MESSAGE,
  MORE_THAN_ONE_VISIT_COST_MESSAGE,
  NCCT_FAILOVER_MESSAGE,
  NCCT_FAIL_OVER,
  PRIME_GROUP_SEARCH_MESSAGE,
  PROVIDER_SEARCH_DISTANCE,
  PROVIDER_TYPE,
  PROVIDER_TYPE_OF_CARE,
  SPECIALTIES,
  SUB_SPECIALTIES,
  VISITS
} from '../../search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../utilities/commonUtil';
import { ResultsUtil } from '../../utilities/resultsUtil';
import { IFilter } from '../interfaces/iAppliedFilters';
import { IDynamicFilters } from '../interfaces/iDynamicFilters';
import { IMapRequest } from '../interfaces/iMapRequest';
import { TravelTimeRequest } from '../interfaces/iSearchRequest';
import { IClinic, IField, IHealthwiseAdobeAnalyticsObj, IPairPerformance, IProvider, IRule, ISortOption, ISpecialty, ISummaryAPIResp } from '../interfaces/iSummaryResp';
import { CptSummaryModel } from '../models/cptSummaryModel';
import { SummaryModel } from '../models/summaryModel';
import { AppliedFilterService } from '../services/appliedFilterSvc';
import { FilterCategoryService } from '../services/filterCategorySvc';
import { ProviderCptOfficeVisitCostSvc } from '../services/providerCptOfficeVisitCostSvc';
import { ProviderSummaryService } from '../services/providerSummarySvc';
import { PublicSpecialtyAdapterSvc } from '../services/publicSpecialtyAdapterSvc';
import { PublicTccMedicalAdapterSvc } from '../services/publicTccMedicalAdapterSvc';
import { SecureCptAdapterSvc } from '../services/secureCptAdapterSvc';
import { SecureDentalProcedureAdapterSvc } from '../services/secureDentalProcedureAdapterSvc';
import { SecureRxAdapterSvc } from '../services/secureRxAdapterSvc';
import { SecureSpecialtyAdapterSvc } from '../services/secureSpecialtyAdapterSvc';
import { SecureTccMedicalAdapterSvc } from '../services/secureTccMedicalAdapterSvc';
import { COMMA, DOLLAR_SYMBOL, HYPHEN, OON_ADOBE_TAGS, PDF_FILE_NAME, PILL_ID, SEARCH_IMPROVEMENTS, UNDERSCORE, YES } from '../values/providerSearchConstants';
import { Email, HpnSearch, Print, ResultActionType, ResultActions } from '../values/resultActions';
import { DrivingDirectionSlideOutComponent } from './../../../common/components/map/drivingDirectionSlideOutCmp';
import { ScenarioAlertSvc } from './../../../common/components/scenarioAlert/services/pfScenarioAlertSvc';
import { CLIENTS } from './../../../common/constants/app-constants';
import { IFinalSearchResponse } from './../../../common/interfaces/iCommonSearchResponse';
import { CommmunicationService } from './../../../common/services/communicationSvc';
import { IPlanRule } from './../../search-providers/interfaces/iPlanRule';
import { PFFtsAvailableSuggestionComponent } from './pfFtsAvailableSuggestionCmp';
import { PFSearchResultsFilterComponent2 } from './pfSearchResultsFilterCmp2';

declare let window: any;
declare let _satellite: any;

@Component({
  moduleId: module.id,
  selector: 'app-fad-search-cmp2',
  templateUrl: '../views/pfSearchResultsContainerCmp2.html',
  providers: []
})
@Injectable({
  providedIn: 'root'
})
export class PFSearchResultsContainerComponent2 extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  currentIndex: number = 0;
  get pageNumber(): number {
    return this.isPrint || this._appSession.pageNumber === null || this._appSession.pageNumber === undefined ? this.defaultPageNumber : +this._appSession.pageNumber;
  }

  get compareProviders(): any[] {
    if (this.selectedProviders.length === 0) {
      return [];
    }
    return this.selectedProviders.map((x: any) => x.Provider);
  }

  get showCompareButton(): boolean {
    if (
      typeof this.summaryResponse === 'undefined' ||
      this.summaryResponse === null ||
      (this.summaryResponse && this.summaryResponse.providerList && this.summaryResponse.providerList.length <= 1) ||
      this.isCptSearch
    ) {
      return false;
    } else {
      return !this.isMapViewSelected;
    }
  }

  get selectedChkBox(): string[] {
    if (this.selectedProviders.length === 0) {
      return [];
    }
    return this.selectedProviders.map((x: any) => x.ChkBoxId);
  }

  get isInitialSearch() {
    return this._appSession.initialSearch;
  }

  get getPromises(): any[] {
    return this._promises;
  }

  set resetPromises(object: any[]) {
    this._promises = object;
  }

  get isEyc() {
    return this._appSession.isEyc;
  }

  get hasCostTransparency() {
    return this._appSession.hasCostTransparency;
  }

  get showLiveHealthSSOLink(): boolean {
    return !this._appSession.hasVirtualCareHubWeb && this._appSession.isSecureState === true && this._appSession.feature && this._appSession.feature.showLiveHealth && this.showLHO;
  }

  get showPreFilterBCPMsg(): boolean {
    return this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly && this._appSession.feature.showPreFilterBCP;
  }

  get isSNYMember(): boolean {
    return this._appSession?.feature?.isSNYMember;
  }

  get isEYC(): boolean {
    return this._appSession.isEyc;
  }

  get providerTypeText(): string {
    return ResultsUtil.getProviderTypeText(this._appSession, this.content) + (this.isSecureState ? ' ' + this.content.searchCriteriaComponent.labels.inYourPlan : '');
  }

  get procedureNameText(): string {
    if (
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName
    ) {
      return this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName + (this.isSecureState ? ' ' + this.content.searchCriteriaComponent.labels.inYourPlan : '');
    }
    return '';
  }

  get cptNameText(): string {
    return this.cptHeaderText;
  }

  set cptNameText(cptHeader: string) {
    this.cptHeaderText = cptHeader;
  }

  get procedureName(): string {
    if (
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName
    ) {
      return this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
    }
    return '';
  }

  get hwProcedureName(): string {
    if (this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureName) {
      if (this.content?.summaryComponent?.filter?.labels?.whatIsA) {
        return this.content.summaryComponent.filter.labels.whatIsA + ' ' + this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName + '?';
      }
      return this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
    }
    return '';
  }

  get cptName(): string {
    if (this._appSession.hasCostTransparency && this.isCostTransparency) {
      return this.content.cptSlideModalContent.aboutThisProcedureOrService;
    }
    return '';
  }

  get procedureNameCode(): string {
    return this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
  }

  get ariaLblForEstimateBill(): string {
    return this.content.eyc.arialLblEsitmateBillHeader.replace(/{PROCEDURENAME}/gi, this.procedureName);
  }

  get ariaLblForEstimateCost(): string {
    return this.content.eyc.arialLblEsitmateCostHeader.replace(/{PROCEDURENAME}/gi, this.procedureName);
  }

  get searchParams() {
    return this._appSession?.searchParams;
  }

  get isServiceAreaValidated(): boolean {
    return this._appSession.serviceAreaValidated;
  }

  get costRange(): string {
    if (this.summaryResponse && this.summaryResponse.costRange) {
      return !this.isColdStateEYC
        ? this.content.eyc.costRangeNew.replace(/{COSTRANGE}/gi, this.summaryResponse.costRange).replace(/{PROCEDURENAME}/gi, this.procedureName)
        : this.content.eyc.costRangeColdState.replace(/{COSTRANGE}/gi, this.summaryResponse.costRange).replace(/{PROCEDURENAME}/gi, this.procedureName);
    }
    return this.content.eyc.costRangeNew.replace(/{COSTRANGE}/gi, '').replace(/{PROCEDURENAME}/gi, this.procedureName);
  }

  private get pdfFileName(): string {
    const now = new Date(Date.now());
    const date = `${now.getMonth() + 1}${now.getDate()}${now.getFullYear()}`;
    const code = this._appSession.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
    const medical_code = this._appSession.billingType + HYPHEN + this._appSession.medicalCode;
    if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
      const dental_procedure_content = this.content.summaryComponent.pdfFileName[PDF_FILE_NAME.DENTAL_PROCEDURE];
      return this.getPdfFileName(dental_procedure_content, code, date);
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL || this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
      const medical_procedure_content = this.content.summaryComponent.pdfFileName[PDF_FILE_NAME.MEDICAL_PROCEDURE];
      return this.getPdfFileName(medical_procedure_content, code, date);
    } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
      const medical_code_content = this.content.summaryComponent.pdfFileName[PDF_FILE_NAME.MEDICAL_CODE];
      return this.getPdfFileName(medical_code_content, medical_code, date);
    } else {
      return `${ResultsUtil.getProviderTypeText(this._appSession, this.content)}_Search_on_${date}`;
    }
  }

  get backNavText() {
    if (this.showCompare) {
      return this.content.summaryComponent.backToResults;
    } else if (this._navigationService.rootRoute === AppNavigations.CARE_TEAM_PATH) {
      return this.content.summaryComponent.backToCareTeam;
    } else if (this._contentHelper.isSearchResultRoute(this._navigationService.rootRoute)) {
      return this.content.summaryComponent.newFindCareSearch;
    } else {
      return this.content.summaryComponent.backToFindCare;
    }
  }

  _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  @Input()
  hasApplyError: boolean;
  @ViewChild('summaryAwardsModal')
  summaryAwardsModal: ModalComponent;
  @ViewChild('reviewMapModal')
  reviewModal: ModalComponent;
  @ViewChild('summaryRecognition')
  summaryRecognition: ModalComponent;
  @ViewChild('pairedRecognition')
  pairedRecognition: ModalComponent;
  @ViewChild('leavingDisclaimerModal')
  leavingDisclaimerModal: LeavingDisclaimerComponent;
  @ViewChild('summaryMapModal')
  summaryMapModal: ModalComponent;
  @ViewChild('DrivingDirections')
  drivingDirection: PFDrivingDirectionComponent;
  @ViewChild('disclaimers')
  disclaimers: PFDisclaimerComponent;
  @ViewChild('emailModalNew')
  emailModalNew: EmailComponent;
  @ViewChild('integratedsearch')
  integratedsearchCmp: PFIntegratedSearchComponent;
  @ViewChild('freeTxtsearch')
  freeTxtsearchCmp: PFFreeTxtSearchComponent;
  @ViewChild('ftsAvailableSuggestion')
  ftsAvailableSuggestionCmp: PFFtsAvailableSuggestionComponent;
  @ViewChild('providerFilter2')
  providerFilterCmp2: PFSearchResultsFilterComponent2;
  @ViewChild('summaryTelehealth')
  summaryTelehealth: ModalComponent;
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;
  @ViewChild('viewCptSlideContentModal')
  viewCptSlideContentModal: CptSearchSlideOutComponent;
  @ViewChild('snackbarModal')
  snackbarModal: SnackbarAlertContainerComponent;
  @ViewChild('noResultsAlertTemplate', { static: false })
  noResultsAlertTemplate: TemplateRef<any>;
  @ViewChild('viewRecognitionSlideContentModal')
  viewRecognitionSlideContentModal: RecognitionSlideOutComponent;
  @ViewChild('viewDrivingDirectionSlideContentModal')
  drivingDirectionSlider: DrivingDirectionSlideOutComponent;
  @ViewChild('oonLearnMore')
  oonLearnMoreSlider: OONDetailsComponent;
  filterApplied: boolean = false;
  feature: IRulesResponse;
  helpfulHints: IHelpfulHint[];
  isSecureState: boolean = false;
  showSummaryLoader: boolean = true;
  showPageProgress: boolean = false;
  selectedMember: string = '';
  memberZip: string = '';
  isVitalsRequired: boolean = false;
  noResults: boolean;
  results = false;
  adobeTrackingResults: string[] = [];
  providerTotalCount = 0;
  vitalsUrl: SafeResourceUrl;
  specialties: IProviderSearchDdlValues = {
    title: 'Specialty',
    options: []
  };
  ruleList: any = [];
  showSearchLoader: boolean;
  toggleMapBool: boolean = false;
  showMapCmp: boolean = false;
  mapData: IMapRequest[] = [];
  virtualProviders: IFinalProvider[];
  performance: any;
  providerPerformance: IPairPerformance;
  recognition: any = [];
  showResults = true;
  showCompare = false;
  selectedProviders = [];
  disclaimerUrl: any;
  showPersonalizedMatchMsg = false;
  learnMorePDFlink: boolean = true;
  showPersonalizedMatchEycDisclaimer = false;
  showFilterOptions: boolean = false;
  showHelpfulHints: boolean = false;
  summaryResponse: any;
  drivingTimeProvList: ISearchProvider[];
  showResultsContainer = false;
  showNoResultMsg: boolean = false;
  isMapViewSelected = false;

  resultsHeaderText: string = '';
  resultsHeaderTextForPrint: string = '';
  technicalErrorText: string = '';
  distance: string = '';
  defaultPageNumber = 1;
  pageSize = 0;
  totalPages = 0;
  isPrint = false;
  selectedPageNumber = 1;
  showPagination: boolean = false;
  drivingModalTitle: any = {
    id: 'map-details-modal',
    title: this.content.mapModal.directions
  };
  recognitionModalTitle: any = { id: 'pf-recognition-modal', title: '' };
  pairRecognitionModalTitle: any = {
    id: 'pf-pair-recognition-modal',
    title: ''
  };
  filterGroupList = [];
  forwardfilterRule = {};
  showFilterSlide: boolean = false;
  pcpProviderCount: number;
  ecpProviderCount: number;
  medicaidProviderCount: number;
  anpProviderCount: number;
  noPrint: boolean = false;
  isHPNPlan: boolean = false;
  oonProviders: boolean = false;
  isOONSearch: boolean = false;
  hasOonProviders: boolean = false;
  hasCastlightTransparency: boolean = false;
  resultsHeaderLine1: string = '';
  resultsHeaderLine2: string = '';
  ssoConfig: any;
  isNavigatedToSearchCriteria: boolean = false;
  showPairRecognitionModal: boolean = false;
  minnesotaDisclaimer: boolean = false;
  showDetailPharmacyDisclaimer: boolean = false;
  showResultInMobileView: boolean = false;
  isCptSearch: boolean = false;
  showCptAlert: boolean = false;
  cptDeductibleAmount: string = '';
  isCaHmoDmhc: boolean = false;
  languageCd: string = ContentLanguageCode.ENGLISH;
  fileExist: boolean = false;
  isCptAlertActive: boolean = true;
  cptHeaderText: string = '';
  dollarSymbol: string = DOLLAR_SYMBOL;
  cptAccountBalance: string;
  readonly cptSearchSlideOutTabs = CptSearchSlideOutTabs;
  @ViewChild('PMSidePanel')
  personalizedMatchSidePanel: TemplateRef<HTMLElement>;
  removeSidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  personalizedMatchPdfLink: string = '';
  private _promises: any[] = [];
  private wcsApi: string;
  whatIsPersonalizedMatch: string;
  personalizedMatchMsg: string;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  private readonly changePlanSaveClickSubscription: Subscription;
  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: true
  };
  adobeTags = OON_ADOBE_TAGS;

  constructor(
    private _http: HttpClient,
    private searchService: SearchSvc,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _alertHlpr: AlertHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _providerSummaryService: ProviderSummaryService,
    private _commmunicationService: CommmunicationService,
    private _sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _appUtility: AppUtility,
    private _dataService: DataService,
    private _changePlanService: ChangePlanService,
    private _ruleService: RulesService,
    private _titleCasePipe: TitleCasePipe,
    private _dataHlpr: DataHelper,
    public sumModel: SummaryModel,
    public cptSummaryModel: CptSummaryModel,
    private _cptMedicalSvc: SecureV1CptMedical,
    private _secureSpecialtySvc: SecureSpecialtyAdapterSvc,
    private _publicSpecialtySvc: PublicSpecialtyAdapterSvc,
    private _secureTccDentalSvc: SecureDentalProcedureAdapterSvc,
    private _securePharmacySvc: SecureRxAdapterSvc,
    private _secureTccMedicalSvc: SecureTccMedicalAdapterSvc,
    private _publicTccMedicalSvc: PublicTccMedicalAdapterSvc,
    private _secureCptSvc: SecureCptAdapterSvc,
    private _secureV1TccMedical: SecureV1TccMedical,
    private _sidePanel: SidePanel,
    private _infoListSvc: InfoListService,
    private _alertService: AlertService,
    private _appliedFilterService: AppliedFilterService,
    private _savNavigationService: SAVNavigationService,
    private _filterCategoryService: FilterCategoryService,
    private _scenarioAlertSvc: ScenarioAlertSvc,
    private _memberPCPHelper: MemberPCPHelper,
    private _providerOfcVisitCostSvc: ProviderCptOfficeVisitCostSvc
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
    this.showSearchLoader = false;
    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick.subscribe((path: AppNavigations) => {
      if (this._contentHelper.isSearchResultRoute(path)) {
        this._appSession.initialSearch = true;
        this.ngOnInit();
      }
    });

    this.cptSummaryModel.cptSummaryResponse.subscribe((data) => {
      if (data) {
        this.cptDeductibleAmount = data?.providerList?.length ? data.remainingDeductible?.toString() : '';
      }
    });
  }

  getNoCostRangeInfoMsg(): string {
    return CommonUtil.getProcedureDeeplinkUrl(this.content?.alerts?.noCostRangeInfo, this._appSession);
  }

  ngOnInit() {
    if (this._appSession) {
      this.isSecureState = CommonUtil.isMemberSecure(this._appSession);
      this.isNavigatedToSearchCriteria = false;
      this.isCaHmoDmhc = this._appSession.isCaHmoDmhc;
      if (this.isSecureState && CommonUtil.isMultiContractAllowedClient(this._appSession.deeplinkParams?.client)) {
        this._memberPCPHelper.checkAndRetrievePcpData(this._appSession);
      }
    }
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.onPreLoad();
    }
    this._appSession.isGlobalBackBtnHidden = this.showCompare;
    this._appUtility.scrollToTop();
    this.initialDataLoading();
    this.hasCastlightTransparency = this._appSession.metaData.appContract.hasCastlightTransparency;
    this.languageCd = this._appSession.metaData.locale?.toString() === Locale.SPANISH ? ContentLanguageCode.SPANISH : ContentLanguageCode.ENGLISH;
    this.getPersonalizedMatchContent();
    this.getPersonalizedMatchPdfLink();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event?.target?.parentNode?.id && event.target.parentNode.id === 'pf-pair-recognition-modal_close') {
      this.showPairRecognitionModal = false;
    }
  }

  ngAfterViewInit() {
    this.setPageHeader();
    this.isEmptyModalHeader();
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }

  emailBody(): string {
    return this.content.searchEmailComponent.emailSearchNote;
  }

  getEmailPopupHeader(): string {
    return this.content.searchEmailComponent.emailSearchHeader;
  }

  getHasCptSearch(): boolean {
    return CommonUtil.isCptSearch(this._appSession);
  }

  /***
   * set the Page Header and Title dynamically based on pages
   */
  setPageHeader() {
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.searchResults
    };
    if (this.content.globalHeaderComponent && this.content.globalHeaderComponent.pageHeader && this.content.globalHeaderComponent.pageHeader.fadPageTitle) {
      this._headerEvent.emit(pageFadObj);
    }
  }

  closeFaqsContent() {
    this.showFilterSlide = false;
  }

  // Integrated search - method to reload search.
  reloadSearch() {
    this.clearSnackbarAlert();
    this.filterApplied = false;
    this.showSummaryLoader = true;
    this.ruleList = [];
    this.selectedProviders = [];
    this.mapData = [];
    this.summaryResponse = undefined;
    this.drivingTimeProvList = [];
    this.showPagination = false;
    this.showNoResultMsg = false;
    this.isHPNPlan = false;
    this.oonProviders = false;
    this.isOONSearch = false;
    this.hasOonProviders = false;
    this._appUtility.scrollToTop();
    this._appSession.pageNumber = null;
    this._appSession.searchParams.selectedSortCriteria = null;
    this.isCptSearch = false;
    this.showCptAlert = false;
    this.initialDataLoading();
  }

  initialDataLoading() {
    this._appliedFilterService.clearFilters();
    this.resetPromises = [];
    this.pageSize = this._appSession.appConfig?.paginationSize.summaryPage;
    this.helpfulHints = this.getHelpfulHintsContent();
    this.showResultsContainer = false;
    if (this._appSession?.searchParams) {
      // Set selected member search param.
      if (CommonUtil.isMemberSecure(this._appSession)) {
        this.isOONSearch = this._appSession.searchParams.planTypeList?.includes(PLANTYPE_OON);
        // To retain oon search if it is from cta link
        this.oonProviders = this._appSession?.filterSearchParams?.planTypeList?.includes(PLANTYPE_OON) || this.isOONSearch ? true : false;
        if (this._appSession.metaData.appContract.mbrUid) {
          const index = this._appSession.metaData.appContract?.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
          if (index > -1) {
            const member: IOptions = this._appSession.metaData.appContract.memberList[index];
            this._appSession.searchParams.selectedMem = member.firstName + ' ' + member.lastName;
          }
          this._appSession.searchParams.memberSeqNum = this._appSession.metaData.appContract.memberSeqNum;
        }
      }
      this.getRules();
      // To do check and change it here
      if (this._appSession.isEyc) {
        this.executeSearch(this.pageNumber);
      } else {
        // Invoking Provider Summary on success of all service calls.
        Promise.all(this.getPromises).then(() => {
          if (this._appSession.feature?.showSearch) {
            this.executeSearch(this.pageNumber);
          } else {
            this.showSummaryLoader = false;
            this.summaryResponse = undefined;
            this.showResultsContainer = false;
            this.results = false;
            this.showPagination = false;
            this._appSession.initialSearch = false;
          }
        });
      }
    }
  }

  getRules(result?: ISummaryAPIResp | IFinalSearchResponse) {
    const ruleRequest = CommonUtil.buildRuleRequest(this._appSession.searchParams, this._appSession);
    const rules = this._ruleService.getRules(ruleRequest).then(
      (data: IRulesResponse) => {
        this.showDetailPharmacyDisclaimer = data?.showDetailPharmacyDisclaimer || false;
        if (data && !this.isNavigatedToSearchCriteria) {
          this.feature = this._appSession.feature = data;
          if (data.pcpRule) {
            this._appSession.pcpRule = data.pcpRule;
          }
          this._appSession.pillFilterOrder = data.pillFilter ? data.pillFilter : ({} as IPillFilterOrder);
          this._infoListSvc.reset();
          if (data.disclaimer?.length) {
            for (const disclaimer of data.disclaimer) {
              this._infoListSvc.addMsgById(disclaimer);
              if (!this.results) {
                this.adobeTrackingResults.push(disclaimer);
              }
            }
          }
          // TODO - New Search
          if (result?.ruleList) {
            this.setNCCTFailoverMsg(result.ruleList, result.providerList.length);
          }
          //set Medical Group Banner
          if (result?.isPrimeGroupSearchResponse && this._appSession.primeGroupAffiliations?.affiliationList?.length) {
            const primeGroupSearchMsg: IMessage = {
              contentId: PRIME_GROUP_SEARCH_MESSAGE,
              content: this._appSession.hasHealthSystem
                ? this.content.searchCriteriaComponent.alerts.yourMedicalHealthSystemMsg
                : this.content.searchCriteriaComponent.alerts.yourMedicalGroupSearchMsg,
              type: ALERT_TYPE.INFORMATION
            };
            this._infoListSvc.addMessage(primeGroupSearchMsg);
          }
          this._infoListSvc.update();
          this.setDentalProcMsg();
        }
      },
      (error: unknown) => {
        this.onError('RuleResponse', error);
      }
    );
    this._promises.push(rules);
  }

  // To track adobe analytics values when no providers found
  adobeTracking() {
    if (!this.adobeTrackingResults.length) {
      return;
    }
    for (const disclaimer of this.adobeTrackingResults) {
      if (disclaimer in CLINICAL_ADOBE_TRACKS && typeof _satellite !== 'undefined') {
        _satellite.track(CLINICAL_ADOBE_TRACKS[disclaimer]);
      }
    }
    this.adobeTrackingResults = [];
  }

  onSelectMapView(event: boolean) {
    this.isMapViewSelected = event;
  }

  getHelpfulHintsContent() {
    const helpfulHints: IHelpfulHint[] = [];
    if (isEmpty(this._appSession.searchParams)) {
      return helpfulHints;
    }
    if (this._appSession.searchParams?.providerTypeCodeList && this._appSession.searchParams.providerTypeCodeList.length > 0) {
      for (const providerType of this._appSession.searchParams.providerTypeCodeList || []) {
        const helpfulHint = this.WcsUtility.getHelpfulHintscontent(this.content.helpfulhintsForSearch, providerType);
        helpfulHints.push(...helpfulHint);
      }
    } else {
      return this.WcsUtility.getHelpfulHintscontent(this.content.helpfulhintsForSearch, this._appSession.searchParams.typeSelectNm);
    }
    return helpfulHints;
  }

  callSecureMedicalSearch(searchRequest: ISecureTccMedicalSearchRequest) {
    // TODO: Ignoring the promise as of now since UI adapter is not done yet
    this._secureV1TccMedical.execute(searchRequest);
  }

  getDynamicFilters(searchRequest: ISearchFilterRequest) {
    this._providerSummaryService.getDynamicFilters(searchRequest).then(
      (result: IDynamicFilters) => {
        this._filterCategoryService.updateDynamicFilters(result);
      },
      (error: any) => {
        this.onError('SUMMARY_DYNAMIC_FILTER', error);
      }
    );
  }

  onProfessionalSummarySuccess(result: ISummaryAPIResp | IFinalSearchResponse, selTypeOfProvider?: string) {
    this.isOONSearch = this._appSession.searchParams.planTypeList?.includes(PLANTYPE_OON);
    this._scenarioAlertSvc.getScenarioBasedAlert(CommonUtil.buildScenarioAlertRequest(this._appSession, this.searchParams));
    if (!result) {
      this.hasApplyError = true;
      return;
    }

    this.hasOonProviders = result?.oonProviders;

    // condition introduced else error message once displayed is not cleared
    this.hasApplyError = false;
    if (result?.ruleList?.length > 0) {
      this.setVitalsIndicator(result.providerList);
      this.ruleList = result.ruleList;
      if (this.checkSubSpecImprovedSearchCriteria(this.ruleList)) {
        this.getRules(result);
      }
    } else {
      this.ruleList = [];
    }
    this.sumModel.summaryResponse = result;
    this.summaryResponse = result;
    if (this.isCptSearch && this.summaryResponse?.accntBalanceInfo?.accountBalanceAmount?.length > 0) {
      this.cptAccountBalance = COMMA + this.content.cptMedicalSearchComponent.hraBalance + this.summaryResponse.accntBalanceInfo.accountBalanceAmount;
    }

    if (this.summaryResponse.spfRankApplied) {
      this.showPersonalizedMatchEycDisclaimer = true;
    }
    this.getTravelTime(result);
    // Adode Analytics: Initial search track record count
    if (this._appSession.initialSearch) {
      const _totalRecords = result.totalRecords ? result.totalRecords : 0;
      this.setResultsCountAnalytics(_totalRecords);
    }

    // smartSearchRequestId property of the searchParams object is being reset after the initial load.
    this._appSession.searchParams.smartSearchRequestId = undefined;

    if (result.providerList?.length) {
      this.providerTotalCount = result.totalRecords;
      this.totalPages = result.totalPages;
      this.selectedPageNumber = result.pageNumber;
      this.showPagination = true;
      this.results = true;
      this._appSession.oopEligible = result.oopEligible;
      this._appSession.initialSearch = false;
      this.isHPNPlan = false;
      if (!this.isNavigatedToSearchCriteria) {
        this.setPreFilterBCP();
      }
    } else {
      //this._appSession.initialSearch = true;
      this.openSnackbarAlert();
      this.results = false;
      this.showPagination = false;
      if (result.isHPNPlan) {
        this.isHPNPlan = result.isHPNPlan;
      }
      this.adobeTracking();
    }

    //Set Onsite clinics
    if (result && result.onsiteClinics && result.onsiteClinics.length > 0 && this.showOnsiteClinics && !this.isNavigatedToSearchCriteria) {
      this.setOnsiteClinics(result.onsiteClinics);
    } else {
      this._infoListSvc.removeMsgByCategory(MsgCategory.ONSITE_CLINICS);
    }

    // Identify EYC auto search is applied from Node and set provider type accordingly.
    if (this._appSession.isEyc === true) {
      if (this.summaryResponse.eycHospitalSearch === true && this.summaryResponse.eycDoctorSearch === false) {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.HOSPITAL;
      } else if (this.summaryResponse.eycHospitalSearch === false && this.summaryResponse.eycDoctorSearch === true) {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
      }

      // When: User selected Hospitals but API returns Doctors because of NCCT Failover
      const resultReturnType: string = result.returnTypeofProvider ? result.returnTypeofProvider.toLowerCase() : '';
      if (selTypeOfProvider?.toLowerCase() === EYC_RETURN_PROVIDER_TYPE.HOSPITAL.toLowerCase() && resultReturnType === EYC_RETURN_PROVIDER_TYPE.DOCTOR_PROFESSIONAL.toLowerCase()) {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
        this._appSession.searchParams.providerTypeCodeList = [PROVIDER_TYPE.DOCTOR_PROFESSIONAL];
        if (this.providerFilterCmp2) {
          this.providerFilterCmp2.searchCriteria.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
          this.providerFilterCmp2.searchCriteria.providerTypeCodeList = [PROVIDER_TYPE.DOCTOR_PROFESSIONAL];
          this.providerFilterCmp2.eycShowGender();
        }
      }
    }

    this.setPlanName();
    this.setSearchResultsMsg();
    this.setMapObj();
    this.setEYCPMFlag(result.defaultSort);
    if (!this.isNavigatedToSearchCriteria) {
      this.setNCCTFailoverMsg(result.ruleList, result.providerList?.length);
      this.setInitialVisitCostMessage(result?.providerList);
    }
    this.showSummaryLoader = false;
    this.showResultsContainer = this.isInitialSearch === false;
  }

  setPlanName() {
    if (this._appSession.isSecureState) {
      if (
        this._appSession.searchParams &&
        this._appSession.searchParams.planTypeList &&
        (this._appSession.searchParams.planTypeList.includes(PROVIDER_TYPE_OF_CARE.DENTAL) ||
          this._appSession.searchParams.planTypeList.includes(PROVIDER_TYPE_OF_CARE.VISION) ||
          this._appSession.searchParams.planTypeList.includes(PROVIDER_TYPE_OF_CARE.MEDICAL))
      ) {
        this._changePlanService.setPlanNetworkLinkDisplay(this._appSession.searchParams.planTypeList);
      } else {
        this._changePlanService.setPlanNetworkLinkDisplay();
      }
    }
  }

  setOnsiteClinics(clinics: IClinic[]): void {
    for (const clinic of clinics || []) {
      const displayText: string = this.WcsUtility.getOnsiteClinicsText();
      const msg = this._infoListSvc.addMsgByContent(displayText, MsgCategory.ONSITE_CLINICS, ALERT_TYPE.INFORMATION, clinic);
      if (msg.category in CLINICAL_ADOBE_TRACKS && typeof _satellite !== 'undefined') {
        _satellite.track(CLINICAL_ADOBE_TRACKS[msg.category]);
      }
    }
    this._infoListSvc.update();
  }

  // Get travelTime based on users lat/long and provider lat/long
  getTravelTime(result: any) {
    const travelTimeRequest: TravelTimeRequest = new TravelTimeRequest();
    if (
      this._appSession.searchParams &&
      this._appSession.searchParams.coordinates &&
      this._appSession.searchParams.coordinates.latitude &&
      this._appSession.searchParams.coordinates.latitude.toString() !== '0' &&
      this._appSession.searchParams.coordinates.latitude.toString() !== '' &&
      this._appSession.searchParams.coordinates.longitude &&
      this._appSession.searchParams.coordinates.longitude.toString() !== '0' &&
      this._appSession.searchParams.coordinates.longitude.toString() !== ''
    ) {
      travelTimeRequest.origins = this._appSession.searchParams.coordinates.latitude + ',' + this._appSession.searchParams.coordinates.longitude;
    }
    travelTimeRequest.destinations = '';
    if (result && result.providerList && result.providerList.length > 0) {
      result.providerList.map((provider, index) => {
        const location = CommonUtil.swapOfficeLocation(this.showSPFI, provider);
        if (provider && location && location.address && location.address.latitude && location.address.latitude !== '' && location.address.longitude && location.address.longitude !== '') {
          const providerlatlong = parseFloat(location.address.latitude).toString() + ',' + parseFloat(location.address.longitude).toString();
          // binding distinct destinations lat/long
          if (!(travelTimeRequest.destinations.indexOf(providerlatlong) > -1)) {
            const seperator = index > 0 && travelTimeRequest.destinations !== '' ? ';' : '';
            travelTimeRequest.destinations += seperator;
            travelTimeRequest.destinations += providerlatlong;
          }
        }
      });
      if (travelTimeRequest.origins && travelTimeRequest.origins !== '' && travelTimeRequest.destinations && travelTimeRequest.destinations !== '') {
        this._providerSummaryService.getGeoTravelTime(travelTimeRequest).then(
          (travelTimeResp: any) => {
            if (travelTimeResp && travelTimeResp.travelDuration && travelTimeResp.travelDuration.length > 0) {
              this.bindTravelTime(travelTimeResp.travelDuration, result);
            }
          },
          () => {
            const drivingTimeResp = Object.assign({}, result);
            this.bindDrivingTimeProvList(drivingTimeResp);
          }
        );
      } else {
        const drivingTimeResp = Object.assign({}, result);
        this.bindDrivingTimeProvList(drivingTimeResp);
      }
    }
  }

  bindTravelTime(travelTimeResp: any, result: any) {
    result.providerList.map((provider) => {
      const location = CommonUtil.swapOfficeLocation(this.showSPFI, provider);
      if (provider && location && location.address && location.address.latitude && location.address.latitude !== '' && location.address.longitude && location.address.longitude !== '') {
        const matchedResult = travelTimeResp.filter((resp) => {
          return resp.latitude.toString() === parseFloat(location.address.latitude).toString() && resp.longitude.toString() === parseFloat(location.address.longitude).toString();
        });
        if (matchedResult && matchedResult.length > 0) {
          location.address.travelTime = matchedResult[0].travelTime;
        } else {
          location.address.travelTime = 0;
        }
      }
      if (this.showSPFI && provider.eycAffliation && provider.eycAffliation.affiliationList && provider.eycAffliation.affiliationList.length > 0) {
        provider.eycAffliation.affiliationList[0].location = location;
      } else {
        provider.location = location;
      }
    });
    const drivingTimeResp = Object.assign({}, result);
    this.bindDrivingTimeProvList(drivingTimeResp);
    this.summaryResponse = result;
  }

  setPreFilterBCP() {
    if (this.showPreFilterBCPMsg) {
      this._infoListSvc.addMsgById(BCP_MESSAGE);
    } else {
      this._infoListSvc.removeMsgById(BCP_MESSAGE);
    }
    this._infoListSvc.update();
  }

  setDentalProcMsg() {
    if (this.feature && this.feature.showDentalProcDisclaimer) {
      this._infoListSvc.addMsgById(DENTALPROCEDURE_MESSAGE);
    } else {
      this._infoListSvc.removeMsgById(DENTALPROCEDURE_MESSAGE);
    }
    this._infoListSvc.update();
  }

  setEYCPMFlag(selectedSort: string) {
    this.showPersonalizedMatchMsg = selectedSort === 'PersonalizedMatch';
    this.showPersonalizedMatchEycDisclaimer = selectedSort === 'personalizedmatch_eyc';
    this._appSession.hasPersonalizedMatch = this.showPersonalizedMatchMsg || this.showPersonalizedMatchEycDisclaimer;
  }

  setNCCTFailoverMsg(rules: IRule[], numOfProviders: number = 0) {
    if (!Array.isArray(rules)) {
      return;
    }
    const indicator: IRule = rules.find((rule) => {
      return rule && rule.code === INDICATORS;
    });
    if (!indicator || !Array.isArray(indicator.fieldList)) {
      return;
    }
    const ncctField: IField = indicator.fieldList.find((field) => {
      return field && this._dataHlpr.areEqualStrings(field.name, NCCT_FAIL_OVER);
    });

    const isFailover: boolean = ncctField && this._dataHlpr.isTrue(ncctField.value);
    if (isFailover && numOfProviders > 0) {
      this._infoListSvc.addMsgById(NCCT_FAILOVER_MESSAGE);
    } else {
      this._infoListSvc.removeMsgById(NCCT_FAILOVER_MESSAGE);
    }
    this._infoListSvc.update();
  }

  setMapObj() {
    const resp: IFinalSearchResponse = this.summaryResponse;
    if (Array.isArray(resp?.providerList) && resp.providerList.length > 0) {
      this.mapData = CommonUtil.setMapRequest(resp.providerList, true, this.showSPFI);

      //Pick virtual providers from API response
      const virtualProviders = resp.providerList.filter((provider) => {
        return provider?.isVirtualProvider;
      });

      this.virtualProviders = virtualProviders;
    }
  }

  openMapModal(provider: any) {
    this.drivingDirectionSlider.openSlider(cloneDeep(provider));
  }

  openDomainDisclaimerModal(url: any) {
    this.disclaimerUrl = url;
    this.leavingDisclaimerModal.show();
  }

  closeModal(modal: string) {
    if (modal === 'award') {
      this.summaryAwardsModal.hide();
    } else if (modal === 'map') {
      this.noPrint = false;
      this.summaryMapModal.hide();
    } else if (modal === 'review') {
      this.reviewModal.hide();
    } else if (modal === 'recognition') {
      this.summaryRecognition.hide();
      this.showPairRecognitionModal = false;
      this.pairedRecognition.hide();
    } else if (modal === 'telehealth') {
      this.summaryTelehealth.hide();
    } else {
      return;
    }
  }

  /**
   * Function will take care of all messages to be displayed after FAD and EYC search.
   * Scenarios covered:-
   * 1) Error while calling PF API
   * 2) Increased radius scenario
   * 3) No results after Hospital and Doctor EYC search
   * 4) Message with selected search crietria
   */
  setSearchResultsMsg() {
    const _provName = this._appSession.searchTerm ? this._appSession.searchTerm.trim() : '';
    let _summaryMsg = '';
    let _summaryMsgLine1 = '';
    let _distance;
    this._appSession.increasedRadius = false;
    const isSubSpecRemoved = this.checkSubSpecImprovedSearchCriteria(this.ruleList);
    const isPrimeGroupRemoved: boolean = this.checkPrimeGroupImprovedSearchCriteria(this.ruleList);
    if (this.isCptSearch && this.summaryResponse?.hasErrors && this.serviceUnavailable(this.summaryResponse?.errorCodes)) {
      this.hasApplyError = true;
      this.technicalErrorText = AppErrorMsg.DEFAULT_ERROR_MSG;
    } else if (
      (this.summaryResponse?.increasedRadius || this.summaryResponse?.criteriaChange?.increasedRadius) &&
      (parseInt(this.summaryResponse?.increasedRadius, 10) > parseInt(this._appSession.searchParams.distanceNm, 10) ||
        this.summaryResponse?.criteriaChange?.increasedRadius > parseInt(this._appSession.searchParams.distanceNm, 10))
    ) {
      this._appSession.increasedRadius = true;
      this.distance =
        _distance =
        this._appSession.searchParams.distanceNm =
          this.summaryResponse?.increasedRadius ? this.summaryResponse?.increasedRadius : (this.summaryResponse?.criteriaChange?.increasedRadius).toString();
      _summaryMsg = isSubSpecRemoved === true ? this.content.summaryComponent.resultsHeader.summaryMsgSubSpecRemoved : this.content.summaryComponent.resultsHeader.increasedRadiusMsgNew;
      _summaryMsgLine1 = isSubSpecRemoved === true ? this.content.summaryComponent.resultsHeader.summaryMsgSubSpecRemoved : this.content.summaryComponent.resultsHeader.increasedRadiusMsgNewLine1;
      if (this.isCptSearch && this.summaryResponse?.totalRecords > 0) {
        _summaryMsg = _summaryMsgLine1 = this.content.cptMedicalSearchComponent.errorScenarios.increasedRadiusMsgNew;
      }
      if (this.isCptSearch && this.summaryResponse?.hasErrors) {
        _summaryMsg = _summaryMsgLine1 = this.content.cptMedicalSearchComponent.errorScenarios.noResultsIncreasedRadiusMessage;
        this.showNoResultMsg = true;
        this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      }
    } else {
      if (this.summaryResponse.providerList && this.summaryResponse.providerList.length > 0) {
        this.distance = _distance = this._appSession.searchParams.distanceNm;
        _summaryMsg = isSubSpecRemoved === true ? this.content.summaryComponent.resultsHeader.summaryMsgSubSpecRemoved : this.content.summaryComponent.resultsHeader.summaryMsgNew;
        _summaryMsgLine1 = isSubSpecRemoved === true ? this.content.summaryComponent.resultsHeader.summaryMsgSubSpecRemoved : this.content.summaryComponent.resultsHeader.summaryMsgLine1;
        if (this.isCptSearch) {
          _summaryMsgLine1 = this.content.summaryComponent.resultsHeader.cptSummaryMsgLine1;
          if (this.summaryResponse?.criteriaChange?.increasedRadius) {
            _summaryMsgLine1 = this.content.cptMedicalSearchComponent.errorScenarios.increasedRadiusMsgNew;
          }
        }
        if (isPrimeGroupRemoved) {
          _summaryMsg = this.content.summaryComponent.resultsHeader.summaryMsgPrimeGroupRemoved;
          _summaryMsgLine1 = this.content.summaryComponent.resultsHeader.summaryMsgPrimeGroupRemoved;
        }
      } else {
        if (typeof _satellite !== 'undefined') {
          _satellite.track('noSearchResultsFindCare');
        }
        if (((this._appSession.isEyc || this.isCptSearch) && this._appSession.isSecureState) || (!this._appSession.isSecureState && this.isColdStateEYC)) {
          if ((this.summaryResponse.eycHospitalSearch && this.summaryResponse.eycDoctorSearch) || (this.summaryResponse.providerList && this.summaryResponse.providerList.length === 0)) {
            const noResultEycMessage = this.noResultEycMessage(this._appSession.metaData.brandCd);
            this.resultsHeaderText = this.resultsHeaderLine1 = noResultEycMessage;
            _summaryMsg = _summaryMsgLine1 = noResultEycMessage;
          }
        } else if (this._appSession.isEyc && !this._appSession.isSecureState && !this.isColdStateEYC) {
          if ((this.summaryResponse.eycHospitalSearch && this.summaryResponse.eycDoctorSearch) || (this.summaryResponse.providerList && this.summaryResponse.providerList.length === 0)) {
            this.resultsHeaderText = CommonUtil.getProcedureDeeplinkUrl(this.content?.summaryComponent?.resultsHeader?.nonGAColdStateProcSearchMsg, this._appSession);
          }
        } else {
          _distance = '100';
          this.distance = this._appSession.searchParams.distanceNm;
          if (_provName !== '' && _distance !== this._appSession.searchParams.distanceNm) {
            if (this.isOONSearch) {
              _summaryMsg = this.content.summaryComponent.resultsHeader.OONsearchNoResultSummaryMsg;
            } else {
              _summaryMsg = this.content.summaryComponent.resultsHeader.noOONResultSummaryMsg;
            }
            _summaryMsgLine1 = this.content.summaryComponent.resultsHeader.noOONResultSummaryMsgLine1;
          } else if (_distance !== this._appSession.searchParams.distanceNm) {
            _summaryMsg = this.isSecureState
              ? this.client === CLIENTS.SYDNEYMEDICAID
                ? this.content.summaryComponent.resultsHeader.noResultSummaryMsgHotMedicaid
                : this.content.summaryComponent.resultsHeader.noResultSummaryMsgHot
              : this.content.summaryComponent.resultsHeader.noResultSummaryMsgCold;
            _summaryMsgLine1 = this.isSecureState
              ? this.client === CLIENTS.SYDNEYMEDICAID
                ? this.content.summaryComponent.resultsHeader.noResultSummaryMsgHotMedicaid
                : this.content.summaryComponent.resultsHeader.noResultSummaryMsgHot
              : this.content.summaryComponent.resultsHeader.noResultSummaryMsgCold;
          } else {
            _summaryMsg = this.content.summaryComponent.resultsHeader.noResultDefaultMsgNew;
            _summaryMsgLine1 = this.content.summaryComponent.resultsHeader.noResultDefaultMsgNewLine1;
          }
        }
        this.showNoResultMsg = true;
      }
    }

    if (_summaryMsg) {
      const _memberName = this.setMemberName();
      this.providerTotalCount = this.isCptSearch ? this.summaryResponse.totalRecords : this.providerTotalCount;
      this.resultsHeaderText = _summaryMsg
        .replace(/{RDS}/gi, _distance)
        .replace(/{LOC}/gi, ResultsUtil.getLocationText(this._appSession))
        .replace(/{CNT}/gi, this.providerTotalCount?.toString())
        .replace(/{PVD}/gi, ResultsUtil.getProviderTypeText(this._appSession, this.content))
        .replace(/{PLAN}/gi, ResultsUtil.getPlanNetworkPrefixText(this._appSession, this.content))
        .replace(/{MATCH}/gi, _provName && _provName.length > 0 ? this.content.summaryComponent.resultsHeader.matchingNew.replace(/{NAM}/gi, _provName) : '')
        .replace(/{REFINE}/gi, this.content.summaryComponent.resultsHeader.refineResultsNew);

      this.resultsHeaderLine1 = _summaryMsgLine1
        .replace(/{RDS}/gi, _distance)
        .replace(/{LOC}/gi, ResultsUtil.getLocationText(this._appSession))
        .replace(/{CNT}/gi, this.providerTotalCount?.toString())
        .replace(/{MATCH}/gi, _provName && _provName.length > 0 ? this.content.summaryComponent.resultsHeader.matchingNew.replace(/{NAM}/gi, _provName) : '');

      if (this.isCptSearch) {
        this.cptNameText = this.isSecureState ? this.content.summaryComponent.resultsHeader.cptSummary.replace(/{CNT}/gi, this.providerTotalCount?.toString()).replace(/{USR}/gi, _memberName) : '';
        if (this.summaryResponse?.providerList?.length) {
          this.resultsHeaderLine1 = _summaryMsgLine1
            .replace(/{RDS}/gi, _distance)
            .replace(/{CNT}/gi, this.providerTotalCount?.toString())
            .replace(/{LOC}/gi, ResultsUtil.getLocationText(this._appSession))
            .replace(/{USR}/gi, _memberName)
            .replace(/{MATCH}/gi, _provName && _provName.length > 0 ? this.content.summaryComponent.resultsHeader.matchingNew.replace(/{NAM}/gi, _provName) : '')
            .replace(/{PLN}/gi, ResultsUtil.getPlanNetworkPrefixText(this._appSession, this.content));
        }
      }

      this.resultsHeaderLine2 =
        _memberName && _memberName.length > 0
          ? this.content.summaryComponent.resultsHeader.selectedMemberPlanNew.replace(/{NAME}/gi, _memberName).replace(/{PLAN}/gi, ResultsUtil.getPlanNetworkPrefixText(this._appSession, this.content))
          : this.content.summaryComponent.resultsHeader.selectedPlanMsg.replace(/{PLAN}/gi, ResultsUtil.getPlanNetworkPrefixText(this._appSession, this.content));

      this.resultsHeaderTextForPrint = _summaryMsg
        .replace(/{RDS}/gi, _distance)
        .replace(/{LOC}/gi, ResultsUtil.getLocationText(this._appSession))
        .replace(/{CNT}/gi, this.providerTotalCount?.toString())
        .replace(/{PVD}/gi, ResultsUtil.getProviderTypeText(this._appSession, this.content))
        .replace(/{PLAN}/gi, ResultsUtil.getPlanNetworkPrefixText(this._appSession, this.content))
        .replace(/{MATCH}/gi, _provName && _provName.length > 0 ? this.content.summaryComponent.resultsHeader.matchingNew.replace(/{NAM}/gi, _provName) : '')
        .replace(/{REFINE}/gi, ' ');
    }
  }

  checkSubSpecImprovedSearchCriteria(ruleList: Array<IRule>) {
    if (ruleList && ruleList.length > 0) {
      const improvedSearchCriteria = ruleList.find((rule: IRule) => rule.code === SEARCH_IMPROVEMENTS.IMPROVED_SEARCH_CRITERIA);
      if (improvedSearchCriteria?.fieldList?.length > 0) {
        const increasedRadius = improvedSearchCriteria.fieldList.find((radius: IField) => radius.name === SEARCH_IMPROVEMENTS.INCREASED_RADIUS);
        if (increasedRadius?.valueList?.length) {
          this._appSession.searchParams.distanceNm = increasedRadius.valueList[0];
          this._appSession.increasedRadius = true;
        } else {
          this._appSession.increasedRadius = false;
        }
        const subSpecRemoved = improvedSearchCriteria.fieldList.find((field: IField) => field.name === SEARCH_IMPROVEMENTS.REMOVED_SUBSPECIALTIES);
        if (subSpecRemoved && subSpecRemoved.valueList && subSpecRemoved.valueList.length > 0) {
          subSpecRemoved.valueList.map((subSpecCode: string) => {
            const index = this._appSession.searchParams.taxonomySelectNm?.indexOf(subSpecCode) || -1;
            if (index !== -1) {
              this._appSession.searchParams.taxonomySelectNm.splice(index, 1);
            }
            const filter: IFilter = {
              category: PILL_ID.subSpeciality.label,
              value: subSpecCode
            };
            this._appliedFilterService.removeFilter(filter);
            this._appSession.removedTaxonomies = subSpecCode;
          });
          return true;
        }
      }
      return false;
    }
  }

  onError(type: string, error: any) {
    this.showSummaryLoader = false;
    this.showPageProgress = false;
    this.noResults = true;
    this.results = false;
    // restriction to provide error message only when summary api fails
    if (type === 'PROFESSIONAL_SUMMARY' || type === 'CPT_MEDICAL_SUMMARY') {
      this.hasApplyError = true;
      this.technicalErrorText = AppErrorMsg.DEFAULT_ERROR_MSG;
    }
    throw error;
  }

  onSortOptionChange(sortOption: ISortOption) {
    this.selectedProviders = [];
    this.mapData = [];
    this.summaryResponse = undefined;
    this.drivingTimeProvList = [];
    this.showPagination = false;
    this._appSession.pageNumber = null;
    if (this._appSession.feature?.showSearch) {
      this.executeSearch(this.pageNumber);
    }
  }

  openRecognitionModal(recognition: IRecognition) {
    this.recognition = [];
    this.recognitionModalTitle.title = recognition.name;
    this.recognition.push(recognition);
    this.summaryRecognition.show();
  }

  openPairRecognitionModal(professional: IProvider): void {
    this.getPairPerformance(professional);
    this.viewRecognitionSlideContentModal.openSidePanel(RIGHT);
  }

  getPairPerformance(professional: IProvider): void {
    this.providerPerformance = {} as IPairPerformance;
    if (professional && professional.performance) {
      this.providerPerformance.provider = professional.performance;
    }
  }

  openTelehealthModal() {
    this.summaryTelehealth.show();
  }

  openRewardsModal(performance: any) {
    this.performance = performance;
    this.summaryAwardsModal.show();
  }

  openReviewModal(professional: any) {
    if (professional.reviewRatings.pdtStrKey) {
      this.vitalsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + professional.reviewRatings.pdtStrKey + '/en');
    }
    this.reviewModal.show();
    if (this.reviewModal.modal.title === '') {
      document.getElementById('pf-reviewModal-modal_title').remove();
    }
  }

  applyFilterSearch(searchCriteria: ISearchParameters) {
    this.clearSnackbarAlert();
    this.filterApplied = true;
    this.selectedProviders = [];
    this.mapData = [];
    this.summaryResponse = undefined;
    this.drivingTimeProvList = [];
    this.showPagination = false;
    this._appSession.pageNumber = null;
    if (this._appSession.feature?.showSearch) {
      this.executeSearch(this.pageNumber);
    }
    this.helpfulHints = this.getHelpfulHintsContent();
  }

  // Clear the Snackbar alerts.
  clearSnackbarAlert() {
    this.snackbarModal?.alerts?.forEach((alert) => {
      this.snackbarModal.dismissAlert(alert);
    });
  }

  // Open the Snackbar no results alert.
  openSnackbarAlert() {
    if (this.filterApplied && !this.snackbarModal?.alerts?.length) {
      this._alertService.info(this.noResultsAlertTemplate, {
        regionName: SNACKBAR,
        isDissmisable: true
      });
    }
  }

  bindDynamicFilter(searchParam: ISearchParameters) {
    this._appSession.searchParams = searchParam;
    let filterReq: ISearchFilterRequest;
    switch (this.searchService.currentScenario) {
      case SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY:
        filterReq = this._publicSpecialtySvc.transformSearchFilterRequest(this.defaultPageNumber, this.pageSize);
        break;
      case SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL:
        filterReq = this._publicTccMedicalSvc.transformSearchFilterRequest(this.defaultPageNumber, this.pageSize);
        break;
      case SEARCH_SCENARIOS.SECURE_V1_SPECIALTY:
        filterReq = this._secureSpecialtySvc.transformSearchFilterRequest(this.defaultPageNumber, this.pageSize);
        break;
      case SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL:
        filterReq = this._secureTccMedicalSvc.transformSearchFilterRequest(this.defaultPageNumber, this.pageSize);
        break;
      case SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL:
        filterReq = this._secureTccDentalSvc.transformSearchFilterRequest(this.defaultPageNumber, this.pageSize);
        break;
      case SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL:
        filterReq = this._secureCptSvc.transformSearchFilterRequest(this.defaultPageNumber, this.pageSize);
        break;
      case SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY:
        filterReq = this._securePharmacySvc.transformSearchFilterRequest(this.defaultPageNumber, this.pageSize);
        break;
      default:
        filterReq = ResultsUtil.buildForwardFilterRequest(this._appSession, this.defaultPageNumber, this.pageSize);
    }
    this.getDynamicFilters(filterReq);
  }

  onBackNavClick() {
    if (this.showCompare) {
      this.backToResults();
    } else {
      this.goToNewSearch();
    }
  }

  onSuggestionSelect(suggestion: IFtsSuggestion<ITranslation>) {
    const searchTerm = this.ftsAvailableSuggestionCmp?.displaySearchCriteria(suggestion);
    this.freeTxtsearchCmp.searchTerm = searchTerm;
    this.freeTxtsearchCmp.bindSuggestionsFromRelatedSearchesClick(searchTerm, suggestion);
  }

  onAlternateProcedureSelect(procedure: ProcedureList) {
    if (procedure.category === PROCEDURE_LIST_TYPE.TCC) {
      this.freeTxtsearchCmp.bindProcedures(procedure.metadata as IProcedureLookup);
    }
    if (procedure.category === PROCEDURE_LIST_TYPE.CPT) {
      this.freeTxtsearchCmp.getMedicalCodeLookup(procedure.metadata as ICptCode);
    }
  }

  goToNewSearch() {
    this._appSession.pageNumber = null;
    this._appSession.backToFindCareClicked = true;
    this._appSession.removedTaxonomies = '';
    this._appSession.isMedicalCodeSearch = false;
    this.isNavigatedToSearchCriteria = this._contentHelper.isSearchResultRoute(this._navigationService.rootRoute) || this._navigationService.rootRoute === AppNavigations.SEARCH_PROVIDERS_PATH;
    if (this._appSession.isEyc && !this._appSession.integratedSearch) {
      this._navigationService.navigateByUrl(AppNavigations.COST_SEARCH_PATH);
    } else if (this._contentHelper.isSearchResultRoute(this._navigationService.rootRoute)) {
      // new search scenario
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
    } else if (this._navigationService.rootRoute) {
      // default route scenario
      this._navigationService.navigateByUrl(this._navigationService.rootRoute);
    } else {
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
    }
  }

  onProviderSelect(event: any) {
    if (event.checked) {
      this.selectedProviders.push({
        Provider: event.provider,
        ChkBoxId: event.ctrlId
      });
    } else {
      this.selectedProviders = this.selectedProviders.filter((x: any) => x.ChkBoxId !== event.ctrlId);
    }
  }

  expandDisclaimer() {
    if (typeof this.disclaimers !== 'undefined' && this.disclaimers !== null) {
      this.disclaimers.showMoreItems();
    }
  }

  compareProviderDetails() {
    if (this.selectedProviders.length <= 1) {
      this._alertHlpr.openAlert(this.content.alerts.compareAlert, 'negative');
      this.showCompare = false;
    } else {
      this.showResults = false;
      this.showCompare = true;
      this._appSession.initialSearch = true;
      const pageFadObj = {
        header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
        title: this.content.globalHeaderComponent.pageTitle.compare
      };
      this._dataService.setPageTitle(pageFadObj);
    }
    this._appSession.isGlobalBackBtnHidden = this.showCompare;
    if (this.isVitalsRequired) {
      this.setVitalsIndicator(this.compareProviders);
    }
  }

  setVitalsIndicator(value: IProvider[] | IFinalProvider[]) {
    this.isVitalsRequired = value.some((provider) => !isEmpty(provider.pdtKey));
  }

  backToResults() {
    this.showCompare = false;
    this._appSession.isGlobalBackBtnHidden = this.showCompare;
    this.showResults = true;
    this.ruleList = [];
    this.selectedProviders = [];
    this.mapData = [];
    this.summaryResponse = undefined;
    this.drivingTimeProvList = [];
    this.showPagination = false;
    //this.showAssignPCPMsg = false;
    if (this._appSession.feature?.showSearch) {
      this.executeSearch(this.pageNumber);
    }
  }

  onPageChanged(pageNumber: number) {
    this.mapData = [];
    this.summaryResponse = undefined;
    this.drivingTimeProvList = [];
    this._appUtility.scrollToTop();
    this._appSession.pageNumber = pageNumber.toString();
    if (this._appSession.feature?.showSearch) {
      this.executeSearch(this.pageNumber);
    }
  }

  onPrintPage() {
    if (this.showCompare) {
      this.printWindow();
    } else if (this.showResults) {
      this.showPageProgress = true;
      this.generatePdf('');
    }
  }

  onResultLoadedEvent() {
    if (this.isPrint) {
      this.printWindow();
    }
  }

  scrolltoDisclaimer() {
    const el = document.getElementById('disclaimer-position-to-scroll');
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.expandDisclaimer();
  }

  onEmail() {
    setTimeout(() => {
      this.emailModalNew.show();
    }, 300);
  }

  onSendEmail(emailId: string) {
    this.generatePdf(emailId);
  }

  onScheduleAppointment(data: any) {
    if (data?.loader) {
      this.showPageProgress = true;
    } else {
      this.showPageProgress = false;
      if (data?.apiError) {
        this._alertHlpr.openAlert(this.content.alerts.apiError, 'negative');
      }
    }
  }

  onAssignCare(data: any) {
    this.showPageProgress = !!(data && data.loader);
  }

  /**
   * show alert when error occur in info list component
   * @param error
   */
  onInfoListError(error: boolean) {
    if (error) {
      this._alertHlpr.openAlert(this.content.alerts.apiError, 'negative');
    }
  }

  //Sets the Adobe query for search results count - Only on RCP integrated page
  setResultsCountAnalytics(recordCount: number) {
    const searchAnalytics = window?.digitalData?.page?.search;
    if (searchAnalytics) {
      if (searchAnalytics.eycSearchResults) {
        searchAnalytics.eycSearchResults = '';
      }
      if (searchAnalytics.doctorDetailsSearchResults) {
        searchAnalytics.doctorDetailsSearchResults = '';
      }
      if (searchAnalytics.procedureSearchResults) {
        searchAnalytics.procedureSearchResults = '';
      }
      if (searchAnalytics.providerSearchResults) {
        searchAnalytics.providerSearchResults = '';
      }
      if (searchAnalytics.specialtySearchResults) {
        searchAnalytics.specialtySearchResults = '';
      }

      if (this.isIntegratedSearchEnabled) {
        if (this._appSession.isEyc) {
          searchAnalytics.procedureSearchResults = recordCount;
        } else {
          if (this._appSession.searchParams?.specialtySelectNm?.length > 0) {
            searchAnalytics.specialtySearchResults = recordCount;
          } else {
            searchAnalytics.providerSearchResults = recordCount;
          }
        }
      } else {
        if (this._appSession.isEyc) {
          searchAnalytics.eycSearchResults = recordCount;
        } else {
          searchAnalytics.doctorDetailsSearchResults = recordCount;
        }
      }
    }
  }

  isEmptyModalHeader() {
    if (this.summaryRecognition?.titleElement?.nativeElement?.innerText === '') {
      this.summaryRecognition.titleElement.nativeElement.insertAdjacentHTML('beforeend', "<span class='sr-only'>title</span>");
    }
  }

  printDrivingDirection() {
    this.noPrint = true;
    setTimeout(() => {
      window.print();
    }, 300);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  onActions(action: ResultActions) {
    if (action.getType === ResultActionType.Print) {
      this.onPrintPage();
    } else if (action.getType === ResultActionType.Email) {
      this.onEmail();
    } else if (action.getType === ResultActionType.ContactUs) {
      this.contactUs();
    } else if (action.getType === ResultActionType.HpnSearch) {
      const hpnObj = action as HpnSearch;
      this.invokeHPNSearch(hpnObj.navigationType);
    }
  }

  // Contact us link will navigate to rcp contact us page
  contactUs() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_CONTACT_US.MSG;
    eventDetail.target = AppExternalCommands.RCP_CONTACT_US.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  /* For HPN members when getting zero results two links will be provided for alternate search
  1. type=uc - search will be conducted with urgent care provider type
  2. type=ec - search will be conducted for emergecy care with specility code Acute Hospital
  */
  invokeHPNSearch(type: string) {
    if (this._appSession.searchParams.coordinates && this._appSession.searchParams.coordinates.latitude !== '' && this._appSession.searchParams.coordinates.longitude !== '') {
      this._appSession.searchParams = this.clearSearchCriteria();
      this.resetAppSession();
      if (type === 'uc') {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.URGENT_CARE;
        this._appSession.searchParams.providerTypeCodeList = [PROVIDER_TYPE.URGENT_CARE];
      } else if (type === 'ec') {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.HOSPITAL;
        this._appSession.searchParams.providerTypeCodeList = [PROVIDER_TYPE.HOSPITAL];
        this._appSession.searchParams.specialtySelectNm = [SPECIALTIES.GENERAL_ACUTE_CARE_HOSPITAL];
        this._appSession.searchParams.taxonomySelectNm = [SUB_SPECIALTIES.ACUTE_CARE_HOSPITAL];
      }
      this.reloadSearch();
    }
  }

  clearSearchCriteria() {
    const _searchCriteria: ISearchParameters = {} as ISearchParameters;
    _searchCriteria.plan = this._appSession.searchParams.plan;
    _searchCriteria.zip = this._appSession.searchParams.zip;
    _searchCriteria.stateCd = this._appSession.searchParams.stateCd;
    _searchCriteria.city = this._appSession.searchParams.city;
    if (this._appSession.searchParams.countyCode) {
      _searchCriteria.countyCode = this._appSession.searchParams.countyCode;
    }
    _searchCriteria.coordinates = this._appSession.searchParams.coordinates;
    _searchCriteria.typeOfCareNm = this._appSession.searchParams.typeOfCareNm;
    _searchCriteria.stateDdlValues = this._appSession.searchParams.stateDdlValues;
    _searchCriteria.planCategoryDdlValues = this._appSession.searchParams.planCategoryDdlValues;
    _searchCriteria.planDdlValues = this._appSession.searchParams.planDdlValues;
    _searchCriteria.coverageTypeCode = this._appSession.searchParams.coverageTypeCode;
    if (this._appSession.searchParams.identificationNumber) {
      _searchCriteria.identificationNumber = this._appSession.searchParams.identificationNumber;
    }
    _searchCriteria.distanceNm = PROVIDER_SEARCH_DISTANCE;
    _searchCriteria.typeSelectNm = '';
    _searchCriteria.specialtySelectNm = [];
    _searchCriteria.taxonomySelectNm = [];
    _searchCriteria.providerTypeCodeList = [];
    _searchCriteria.blueDistinctionCenter = [];
    _searchCriteria.categoryDescription = {};
    _searchCriteria.visionSrvcAvailableList = [];
    _searchCriteria.provNm = '';
    _searchCriteria.nationalProviderIdentifier = '';
    if (this._appSession.deeplinkParams && this._appSession.deeplinkParams.pcponly && this._appSession.deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      _searchCriteria.ableToServePcp = this._appSession.searchParams.ableToServePcp;
    }
    _searchCriteria.includePrimeGroupSearch = true;
    return _searchCriteria;
  }

  resetAppSession() {
    this._appSession.filterSearchParams = undefined;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchOption = '';
    this._appSession.hospSearchTerm = '';
    this._appSession.medicalGrpSearchTerm = '';
    if (this.isFtsFeatureLive) {
      this.freeTxtsearchCmp.resetforHPN();
    } else {
      this.integratedsearchCmp.resetforHPN();
    }
    this._appSession.increasedRadius = false;
  }

  bindDrivingTimeProvList(result: any) {
    const response = Object.assign({}, result);
    if (response && response.providerList && response.providerList.length > 0) {
      response.providerList.map((provider, index) => (provider.actualIndex = index));
      this.drivingTimeProvList = Object.assign(
        [],
        response.providerList.sort((prov1: any, prov2: any) => {
          if (prov1.location && prov1.location.address && prov1.location.address.travelTime && prov2.location && prov2.location.address && prov2.location.address.travelTime) {
            return prov1.location.address.travelTime >= prov2.location.address.travelTime ? 1 : -1;
          } else {
            return 1;
          }
        })
      );
    }
  }

  closePmSidePanel() {
    this.removeSidePanelRef.close();
  }

  openPmSidePanelMsg() {
    this.removeSidePanelRef = this._sidePanel.open('right', this.personalizedMatchSidePanel);
  }

  showResultInMobile() {
    this.showResultInMobileView = !this.showResultInMobileView;
    this._appUtility.scrollToTop();
  }

  goToPlanSelection() {
    if (this._appSession?.deeplinkParams?.hcid) {
      this._appSession.deeplinkParams = {} as IDeeplink;
    }
    this._appSession.isChangePlan = true;
    if (!CommonUtil.isMemberSecure(this._appSession) && this._appSession?.searchParams) {
      this._appSession.searchParams.zip = '';
      this._appSession.searchParams.stateCd = '';
      this._appSession.searchParams.city = '';
      //To Be Cleared only on Cold State Change Plan
      this._appSession.planRule = {} as IPlanRule;
      this._appSession.metaData.appContract = {} as IAppContract;
    }
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
  }

  onPrintClick() {
    this.onActions(new Print());
  }

  onEmailClick() {
    this.onActions(new Email());
  }

  navigateToRcpBenefit() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.target = AppExternalCommands.RCP_PLAN_PROGRESS.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  /** Open healthwise side Panel */
  openHealthwiseSidePanel(specialty?: ISpecialty, provider?: IProvider, procedureCode?: string) {
    if (procedureCode) {
      this._adobeAnalyticsObj.procedureName = this.procedureName;
    }
    if (specialty && specialty.specialtyCodeName) {
      this._adobeAnalyticsObj.specialtyName = specialty.specialtyCodeName;
    }
    this.viewHealthwiseContentModal.openSidePanel('right', specialty, provider, procedureCode, this._adobeAnalyticsObj);
  }

  openCptSidePanel(tabName?: CptSearchSlideOutTabs) {
    this.viewCptSlideContentModal.openSidePanel('right', tabName);
  }

  getCompareNowActionId(): string {
    if (this.selectedProviders.length > 1) {
      let compareNowActionId: string = '';
      for (let i = 0; i < this.selectedProviders.length; i++) {
        const providerAddressValue: string = this.selectedProviders[i].Provider.providerIdentifier + TILDE_SYMBOL + this.selectedProviders[i].Provider.location.address.addressId;
        compareNowActionId += (i !== 0 ? CONCATE_SYMBOL : '') + providerAddressValue;
      }
      return compareNowActionId ? this.userInteractionUtil.getUserInteraction(compareNowActionId, this.userInteraction.providerSummaryPage.compareNowLink) : undefined;
    }
  }

  goToServiceAreaValidation(): void {
    this._savNavigationService.navigate();
  }

  async executeSearch(pageNumber: number) {
    this.selectedMember = this._appSession.searchParams.selectedMem;
    this.showSummaryLoader = true;
    this.showNoResultMsg = false;
    this.isHPNPlan = false;
    if (this.isSecureState) {
      if (this.isSecureSpecialtySearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_SPECIALTY) {
        this._secureSpecialtySvc
          .search(pageNumber, this.pageSize)
          .then((response) => {
            this.onProfessionalSummarySuccess(response);
            this._providerOfcVisitCostSvc.getProviderCptOfficeVisitCost(response.providerList);
          })
          .catch((error) => this.onError('PROFESSIONAL_SUMMARY', error));
      } else if (this.isSecureRxSearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY) {
        this._securePharmacySvc
          .search(pageNumber, this.pageSize)
          .then((response) => this.onProfessionalSummarySuccess(response))
          .catch((error) => this.onError('PROFESSIONAL_SUMMARY', error));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
        this._secureTccDentalSvc
          .search(pageNumber, this.pageSize)
          .then((response) => this.onProfessionalSummarySuccess(response))
          .catch((error) => this.onError('PROFESSIONAL_SUMMARY', error));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
        this._secureTccMedicalSvc
          .search(pageNumber, this.pageSize)
          .then((response) => this.onProfessionalSummarySuccess(response, response.returnTypeofProvider))
          .catch((error) => this.onError('PROFESSIONAL_SUMMARY', error));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL && this.getHasCptSearch()) {
        this.isCptSearch = true;
        this._secureCptSvc
          .search(pageNumber, this.pageSize)
          .then((response) => {
            this.showCptAlert = response?.isPreventiveCare;
            this.onProfessionalSummarySuccess(response);
            this._appSession.medicalModifierOptionCode = response.modifier?.code;
            this._appSession.medicalPosCode = response.pos?.code;
          })
          .catch((error) => this.onError('PROFESSIONAL_SUMMARY', error));
      }
    } else {
      if (this.isPublicSpecialtySearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY) {
        this._publicSpecialtySvc
          .search(pageNumber, this.pageSize)
          .then((response) => this.onProfessionalSummarySuccess(response))
          .catch((error) => this.onError('PROFESSIONAL_SUMMARY', error));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
        this._publicTccMedicalSvc
          .search(pageNumber, this.pageSize)
          .then((response) => this.onProfessionalSummarySuccess(response))
          .catch((error) => this.onError('PROFESSIONAL_SUMMARY', error));
      }
    }
  }

  async executeOnPrint() {
    this.showPageProgress = true;
    if (this.isSecureState) {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
        this._secureTccMedicalSvc
          .print(this.content)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
        this._secureTccDentalSvc
          .print(this.content)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.isSecureRxSearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY) {
        this._securePharmacySvc
          .print(this.content)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.isSecureSpecialtySearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_SPECIALTY) {
        this._secureSpecialtySvc
          .print(this.content)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
        this._secureCptSvc
          .print(this.content)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      }
    } else {
      if (this.isPublicSpecialtySearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY) {
        this._publicSpecialtySvc
          .print(this.content)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
        this._publicTccMedicalSvc
          .print(this.content)
          .then((response) => this.onPrintSuccess(response))
          .catch((err) => this.onPrintFailure(err));
      }
    }
  }

  async executeOnEmail(email: string) {
    if (this.isSecureState) {
      if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL) {
        this._secureTccMedicalSvc
          .email(email, this.content)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL) {
        this._secureTccDentalSvc
          .email(email, this.content)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.isSecureRxSearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY) {
        this._securePharmacySvc
          .email(email, this.content)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.isSecureSpecialtySearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_SPECIALTY) {
        this._secureSpecialtySvc
          .email(email, this.content)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL) {
        this._secureCptSvc
          .email(email, this.content)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      }
    } else {
      if (this.isPublicSpecialtySearchEnabled && this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY) {
        this._publicSpecialtySvc
          .email(email, this.content)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      } else if (this.searchService.currentScenario === SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL) {
        this._publicTccMedicalSvc
          .email(email, this.content)
          .then(() => this.onEmailSuccess())
          .catch(() => this.onEmailFailure());
      }
    }
  }

  onPrintSuccess(printResponse: Buffer) {
    this.downloadPdf(printResponse);
    this.showPageProgress = false;
  }

  onPrintFailure(error) {
    this._appSession.initialSearch = false;
    this.onError('PROFESSIONAL_SUMMARY', error);
    this.showPageProgress = false;
  }

  onEmailSuccess() {
    this.emailModalNew.emailMessageSent = this.content.commonContents.alerts.emailSent;
  }

  onEmailFailure() {
    this.emailModalNew.emailMessageSent = this.content.commonContents.alerts.emailFailed;
  }

  dismissCptAlert() {
    this.isCptAlertActive = !this.isCptAlertActive;
  }

  noResultEycMessage(brand: string): string {
    return brand?.toUpperCase() === BRANDCODE.UNIMASS
      ? this.content.summaryComponent.resultsHeader.noResultEycSummaryMsgForUnimass
      : this.isCptSearch
        ? this.content.cptMedicalSearchComponent.errorScenarios.noResultsMessage
        : this.content.summaryComponent.resultsHeader.noResultEycSummaryMsg;
  }

  serviceUnavailable(exceptions: IException[]): boolean {
    return this._dataHlpr.areEqualStrings(exceptions?.[0]?.code, ERROR_CODES.SERVICE_UNAVAILABLE.toString());
  }

  getPersonalizedMatchPdfLink() {
    if (this.client !== CLIENTS.SYDNEYMEDICAID) {
      this.personalizedMatchPdfLink = this._dataHlpr.getValueByKey<string>(
        this._appSession.mbu,
        this.content?.summaryComponent?.personalizedMatchPdf,
        this.content?.summaryComponent?.personalizedMatchPdf?.commercial
      );
    } else {
      const sydMedicaidState = `medicaid${this._appSession?.searchParams?.stateCd?.code}${this._appSession?.searchParams?.brandCode}_${this._appSession?.metaData?.locale}`;
      const baseUrl = this._dataHlpr.getValueByKey<string>(sydMedicaidState, this.content?.summaryComponent?.medicaidStateByPersonalizedMatchPdf);
      if (baseUrl) {
        this.personalizedMatchPdfLink = CommonUtil.personalizedMatchPdfLink(this._appSession, baseUrl);
      } else {
        this.learnMorePDFlink = false;
      }
    }
  }

  private checkPrimeGroupImprovedSearchCriteria(ruleList: IRule[]): boolean {
    let isPrimeGroupRemoved = false;
    if (ruleList?.length) {
      const improvedSearchCriteria = ruleList.find((rule: IRule) => rule.code === SEARCH_IMPROVEMENTS.IMPROVED_SEARCH_CRITERIA);
      if (improvedSearchCriteria?.fieldList?.length) {
        const removedPrimeGroup = improvedSearchCriteria.fieldList.find((field: IField) => field.name === SEARCH_IMPROVEMENTS.REMOVED_PRIMEGROUP);
        if (removedPrimeGroup?.value === YES) {
          isPrimeGroupRemoved = true;
          const increasedRadius = improvedSearchCriteria.fieldList.find((radius: IField) => radius.name === SEARCH_IMPROVEMENTS.INCREASED_RADIUS);
          if (increasedRadius?.value?.length) {
            this._appSession.searchParams.distanceNm = increasedRadius.value;
            this._appSession.increasedRadius = true;
          } else {
            this._appSession.increasedRadius = false;
          }
        }
      }
    }
    return isPrimeGroupRemoved;
  }

  private generatePdf(emailId: string) {
    if (!CommonUtil.isValidString(emailId)) {
      this.executeOnPrint();
    } else {
      this.executeOnEmail(emailId);
    }
  }

  private downloadPdf(pdf: any) {
    if (this._appUtility.isWebkitBrowser()) {
      // Download for webkit based browser
      let binary = '';
      const bytes = new Uint8Array(pdf);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const base64 = 'data:' + 'application/pdf' + ';base64,' + window.btoa(binary);
      const fileURL = encodeURI(base64);
      let windowParams: string;
      windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
      const winArgs = [fileURL, '_blank'];
      if (windowParams) {
        winArgs.push(windowParams);
      }
      window.open.apply(window, winArgs);
    } else {
      const file = new Blob([pdf], { type: 'application/octet-stream' });
      //Download for other browsers
      const lnkDownload = document.createElement('a');
      lnkDownload.style.display = 'none';
      const fileURL = URL.createObjectURL(file);
      lnkDownload.href = fileURL;
      lnkDownload.download = `${this.pdfFileName}.pdf`;
      document.body.appendChild(lnkDownload);
      lnkDownload.click();
      // To make work with Firefox
      setTimeout(() => {
        document.body.removeChild(lnkDownload);
        window.URL.revokeObjectURL(fileURL);
      }, 100);
    }
  }

  private printWindow() {
    this.noPrint = false;
    setTimeout(() => {
      window.print();
    }, 300);
  }

  /**
   * This method will add initial visit cost message to Info List component
   * @param providerList
   */
  private setInitialVisitCostMessage(providerList: Array<IProvider>): void {
    if (!this._appSession?.feature?.customizedFeatures?.showInitialVisitCostMsg) {
      return;
    }

    if ((providerList || []).some((prov) => prov?.eycCostInfo?.noOfVisits === VISITS.INITIAL)) {
      this._infoListSvc.addMsgById(INITIAL_VISIT_COST_MESSAGE);
    } else {
      this._infoListSvc.removeMsgById(INITIAL_VISIT_COST_MESSAGE);
    }

    if ((providerList || []).some((prov) => prov?.eycCostInfo?.noOfVisits === VISITS.SUBSEQUENT)) {
      const noOfVisitsValue = providerList[0]?.eycCostInfo?.noOfVisitsVal;
      this._infoListSvc.addMsgById(MORE_THAN_ONE_VISIT_COST_MESSAGE, ALERT_TYPE.INFORMATION, noOfVisitsValue);
    } else {
      this._infoListSvc.removeMsgById(MORE_THAN_ONE_VISIT_COST_MESSAGE);
    }
    this._infoListSvc.update();
  }

  private setMemberName(): string {
    if (this.isSecureState) {
      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
        const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
        if (_index > -1) {
          const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
          return this._titleCasePipe.transform(_member.firstName + ' ' + _member.lastName);
        }
      }
    }
  }

  private getPdfFileName(content: string, code: string, date: string): string {
    const search_on = this.content.summaryComponent.pdfFileName[PDF_FILE_NAME.SEARCH_ON];
    return content + UNDERSCORE + code + search_on + `${date}`;
  }

  getPersonalizedMatchContent(): void {
    if (this.client === CLIENTS.SYDNEYMEDICAID) {
      this.whatIsPersonalizedMatch =
        this._appSession?.searchParams?.stateCd?.code === STATE_NJ
          ? this.content.summaryComponent.personalizedMatch.whatIsPersonalizedMatch
          : this.content.summaryComponent.personalizedMatch.whatIsPersonalizedMatchSydMedicaid;
      this.personalizedMatchMsg =
        this._appSession?.searchParams?.stateCd?.code === STATE_NJ
          ? this.content.summaryComponent.personalizedMatch.personalizedMatchMsgSydMedicaidNJ
          : this.content.summaryComponent.personalizedMatch.personalizedMatchMsgSydMedicaid;
    } else {
      this.whatIsPersonalizedMatch = this.content.summaryComponent.personalizedMatch.whatIsPersonalizedMatch;
      this.personalizedMatchMsg = this.content.summaryComponent.personalizedMatch.personalizedMatchMsg;
    }
  }

  openOONProvidersLearnMore(): void {
    this.oonLearnMoreSlider.openSidePanel();
  }
}
