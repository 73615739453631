import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import {
  IPairPerformance,
  IRecognition
} from '../../../fad/search-results/interfaces/iSummaryResp';
import { SidePanelDirections } from '../../interfaces/iCptMedicalResponse';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-recognition-slide-out-cmp',
  templateUrl: './recognitionSlideOutCmp.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecognitionSlideOutComponent extends BaseComponent {
  @Input()
  providerPerformance: IPairPerformance;
  @ViewChild('viewRecognitionSlider')
  viewRecognitionSlider: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement>;

  constructor(
    route: ActivatedRoute,
    eventHandler: EventHandler,
    appSession: AppSession,
    contentHelper: ContentHelper,
    private _sidePanel: SidePanel
  ) {
    super(
      route,
      eventHandler,
      appSession,
      contentHelper,
      'PFSearchResultsContainerComponent'
    );
  }

  get networkRecognitions(): IRecognition[] {
    let recognitions: IRecognition[] = [];
    if (this.providerPerformance?.provider?.indicator?.length) {
      recognitions.push(...this.providerPerformance.provider.indicator);
    }
    if (this.providerPerformance?.provider?.recognition?.length) {
      recognitions.push(...this.providerPerformance.provider.recognition);
    }
    return recognitions;
  }

  openSidePanel(direction: SidePanelDirections): void {
    this.sidePanelRef = this._sidePanel.open(
      direction,
      this.viewRecognitionSlider
    );
  }

  onClose(): void {
    this.sidePanelRef?.close();
  }

  decodeHTML(hoverText: string): string {
    return this.WcsUtility.decodeHTML(hoverText);
  }
}
