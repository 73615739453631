import { EventEmitter, Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { NETWORK_TYPECODE, OFFICE_VISIT_MEDICALCODE, PROCEDURE_LIST_TYPE } from '../../../common/constants/app-constants';
import { AppEvents } from '../../../common/enums/appEvents';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { IProvider } from '../../../common/interfaces/iSearchStrategyResponse';
import { BaseService } from '../../../common/services/baseService';
import { EventHandler } from '../../../common/services/eventHandler';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { FindCareFeature } from '../../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../../findcare/common/utilities/featureManagerUtil';
import { PROVIDER_CATEGORY_CODE } from '../../search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../utilities/commonUtil';
import { IProviderCptOfficeVisitRequest, PCP_INDICATOR } from '../interfaces/iProviderCptOfficeVisitRequest';

@Injectable({ providedIn: 'root' })
export class ProviderCptOfficeVisitCostSvc extends BaseService {
  private _pfOfficeVisitCost: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_CPT_OFC_VISIT_COST]);
  constructor(
    _appSession: AppSession,
    _appUtility: AppUtility,
    _httpClientSvc: HttpClientService,
    private _eventHandler: EventHandler
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  async getProviderCptOfficeVisitCost(providerList: IProvider[]): Promise<void> {
    const req = this.buildRequest(providerList);
    const apiUrl = apiNameList.restApi.providerCptOffiveVisitCost;
    if (req.providerList.length && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_SEARCH_SPCLTY_OFFICE_COST, this.appSession)) {
      let resp = await this.httpClientSvc.request({
        cancellable: true,
        method: HttpMethod.Post,
        url: this.baseURL + apiUrl,
        data: req,
        headers: this.getHeaders()
      });
      const eventDetail = {} as IEventDetail;
      eventDetail.type = AppEvents[AppEvents.PF_CPT_OFC_VISIT_COST];
      eventDetail.object = resp;
      if (resp) {
        this.appSession.isOfficeVisitCost = true;
        this._pfOfficeVisitCost.emit(eventDetail);
      }
    }
  }

  buildRequest(provierList: IProvider[]): IProviderCptOfficeVisitRequest {
    const req = { providerList: [] } as IProviderCptOfficeVisitRequest;
    req.medicalCodeCriteria = {
      code: OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE,
      billingType: PROCEDURE_LIST_TYPE.CPT
    };
    req.isFuturePlan = this.appSession.isFutureCoverage;
    req.locale = this.appSession.metaData?.locale?.toString();
    req.location = {
      state: this.appSession.searchParams?.stateCd?.code
    };
    req.memberCriteria = {
      contractUid: this.appSession.metaData?.appContract?.contractUid,
      mbrUid: this.appSession.metaData?.appContract?.mbrUid
    };
    (provierList || []).forEach((provider) => {
      if (provider && provider.categoryCode === PROVIDER_CATEGORY_CODE.Individual && provider?.providerNetworkStatus?.typeCode?.includes(NETWORK_TYPECODE.MEDICAL)) {
        req.providerList.push({
          providerKey: provider.providerIdentifier,
          addrKey: provider.location?.address?.addressId,
          pcpIndicator: provider.pcpInfoList?.length ? PCP_INDICATOR.PCP : PCP_INDICATOR.SPECIALIST,
          bcbsa5PartKey: ['PDT'].includes(provider.sourceSystemName) ? CommonUtil.getPdtStrKey(provider) : undefined
        });
      }
    });

    return req;
  }
}
