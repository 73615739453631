import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OverrideType } from '../../../common/utilities/types';
import { ISelectedProvider } from '../models/iSelectedProvider';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { Stack } from './../../../common/models/stack';
import { NavigationService } from './../../../common/services/navigationService';
import { AppSession } from './../../../common/values/appSession';

@Injectable({ providedIn: 'root' })
export class ProviderDetailsNavigationService {
  private _onProviderLinkClick = new Subject<any>();
  private _onProviderMapTileHover = new Subject<any>();
  private _onProviderMapTileHoverOut = new Subject<any>();
  private _onMapPushpinClick = new Subject<any>();
  private _selectedProviders: any;
  private MAX_SIZE = 1;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _navigationService: NavigationService) {

      this.initializeVariables();
  }

  navigateToProviderDetails(value: OverrideType<ISelectedProvider, {addressIdentifier?: string}>, reset?:boolean) {
    if (this._selectedProviders.isFull) {
      this.resetSelectedProviders();
    }

    if (typeof this._appSession.selectedProvider === 'undefined' ||
      this._appSession.selectedProvider === null ||
      this._appSession.selectedProvider === '' ||
      !Boolean(this._appSession.selectedProvider?.providerIdentifier)) {

      this._appSession.selectedProvider = value;
      this._navigationService.navigateByUrl(AppNavigations.PROVIDER_DETAILS_PATH);
    } else {
      if (!reset) {
        this._selectedProviders.push(this._appSession.selectedProvider);
      }
      this._appSession.selectedProvider = value;
      this._onProviderLinkClick.next(this._appSession.selectedProvider);
    }
  }

  navigateToPreviousProvider() {
    this._appSession.selectedProvider = this._selectedProviders.pop();
    this._onProviderLinkClick.next(this._appSession.selectedProvider);
  }

  highlightMapMarker(professional: any) {
    this._onProviderMapTileHover.next(professional);
  }

  hideMapMarker(professional: any) {
    this._onProviderMapTileHoverOut.next(professional);
  }

  mapPushpinClicked(professional: any) {
    this._onMapPushpinClick.next(professional);
  }

  resetSelectedProviders() {
    this.initializeVariables();
    this._appSession.selectedProvider = undefined;
  }

  get onProviderLinkClick() {
    return this._onProviderLinkClick.asObservable();
  }

  get onProviderMapTileHover() {
    return this._onProviderMapTileHover.asObservable();
  }

  get onProviderMapTileHoverOut() {
    return this._onProviderMapTileHoverOut.asObservable();
  }

  get onMapPushpinClick() {
    return this._onMapPushpinClick.asObservable();
  }

  get showProviderLink() {
    return !this._selectedProviders.isFull;
  }

  get showResultsPage() {
    return this._selectedProviders.isEmpty;
  }

  private initializeVariables() {
    this._selectedProviders = new Stack<any>(this.MAX_SIZE);
  }
}
