import { Injectable } from '@angular/core';
import { ISecureDentalSearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import {
  IFinalProvider,
  IFinalSearchResponse
} from '../../../common/interfaces/iCommonSearchResponse';
import { ISecureDentalRequest } from '../../../common/interfaces/iDentalProcedureSearchRequest';
import { IDentalProcedureResponse } from '../../../common/interfaces/iDentalProcedureSearchResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecureTccDentalEmailRequest } from '../../../common/interfaces/iSecureTccDentalEmailRequest';
import { BaseService } from '../../../common/services/baseService';
import { SecureV1TccDental } from '../../../common/services/secureV1TccDentalSvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { SearchRequestUtil } from '../../utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../utilities/searchResponseUtil';
import { API_NAME } from '../values/providerSearchConstants';
import { HttpClientService } from './../../../common/services/httpClientService';


@Injectable({
  providedIn: 'root',
})
export class SecureDentalProcedureAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = this.appSession.appConfig?.paginationSize.summaryPrintPage;
  constructor(
    appSession: AppSession,
    private _secureTccDentalSvc: SecureV1TccDental,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility, httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    const apiResponse = await this._secureTccDentalSvc.execute(
      transformedRequest
    );
    return this.transformResponse(apiResponse);
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ISecureDentalRequest = { ...searchRequest };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_DENTAL);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent; }>(email: string, content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ISecureTccDentalEmailRequest = { ...searchRequest, email };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_DENTAL);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ISecureDentalSearchFilterRequest {
    const tccDentalSecureSearchReq = this.transformRequest(
      pageNumber,
      pageSize
    );
    const filterReq:ISecureDentalSearchFilterRequest = {
      ...tccDentalSecureSearchReq,
      scenario: SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL,
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): ISecureDentalRequest {
    return SearchRequestUtil.buildDentalProcedureRequest(this.appSession, pageNumber, pageSize);
  }

  private transformResponse(apiResponse: IDentalProcedureResponse) {
    const response = {} as IFinalSearchResponse;
    const finalProviders: IFinalProvider[] = [];
    if (apiResponse) {
      response.pageNumber = apiResponse.pageNumber;
      response.pageSize = apiResponse.pageSize;
      response.totalPages = apiResponse.totalPages;
      response.totalRecords = apiResponse.totalRecords;
      response.defaultSort = apiResponse.sortBy;
      response.isHPNPlan = apiResponse.isHPNPlan;
      response.isPrimeGroupSearchResponse =
        apiResponse.isPrimeGroupSearchResponse;
      response.ruleList = apiResponse.rules;
      response.sortOptions = apiResponse.sortOptions;
      response.criteriaChange = apiResponse.criteriaChange;
      response.costRange = apiResponse.costRange;
      response.onsiteClinics = apiResponse?.onsiteClinics ?? undefined;
      if (apiResponse.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IFinalProvider;
          if (provider) {
            finalProvider =
            SearchResponseUtil.getCommonProviderInformation(provider);
            finalProvider.eycCostInfo = provider.eycCostInfo;
            finalProviders.push(finalProvider);
          }
        });
      }
      response.providerList = finalProviders;
      response.sortIndicator = apiResponse.sortIndicator;
    }
    return response;
  }

}
