import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private changePin = new BehaviorSubject<boolean>(false);

  setpinCode(zip:any) {
    this.changePin.next(zip);
  }

  get pinCode() {
    return this.changePin.asObservable();
  }
}
