import { TitleCasePipe } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertHelper } from "@anthem/uxd/alert";
import { ModalRef, SidePanel } from "@anthem/uxd/modal";
import { Subscription } from "rxjs";
import { IOptions } from "../../../common/interfaces/iAppMetadata";
import { IMessage } from "../../../common/interfaces/iMessage";
import { DataService } from "../../../common/services/dataService";
import { SearchSvc } from "../../../common/services/searchSvc";
import { ISelectedProvider } from "../../provider-details/models/iSelectedProvider";
import { ProviderDetailsNavigationService } from "../../provider-details/services/providerDetailsNavigationSvc";
import { CommonUtil } from "../../utilities/commonUtil";
import { ISearchParameters } from "../interfaces/iSearchParameters";
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE, REMOVECARETEAM_FAILURE_MESSAGE, REMOVECARETEAM_SUCCESS_MESSAGE, RETAIL_PHARMACY } from "../values/providerSearchConstants";
import { CARE_TEAM_TYPE } from './../../../care-circle/constants/careCircleConstants';
import { ICareCircle } from './../../../care-circle/interfaces/iCareCircle';
import { CareTeamProvider, CareTeamResponse, ICareProvider, IProvider } from "./../../../care-circle/interfaces/iCareProvider";
import { CareCircleService } from "./../../../care-circle/services/careCircleSvc";
import { MemberPCPHelper } from './../../../care-circle/services/memberPCPHelper';
import { CareCircleUtil } from "./../../../care-circle/utilities/careCircleUtil";
import { BaseComponent } from "./../../../common/components/base-component/baseCmp";
import { ChangePlanService } from './../../../common/components/change-plan/services/changePlanSvc';
import { AppConstants, AppExternalCommands, ORIGIN } from "./../../../common/constants/app-constants";
import { AppNavigations } from "./../../../common/constants/app-navigations";
import { AppEvents } from "./../../../common/enums/appEvents";
import { IAppointment, IAppointmentLoader } from "./../../../common/interfaces/iAppointment";
import { IApiStatusEvent } from './../../../common/interfaces/iEvent';
import { IEventDetail } from "./../../../common/interfaces/iEventDetail";
import { IMemberKeyRequest } from "./../../../common/interfaces/iMemberKey";
import { PhonePatternPipe } from "./../../../common/pipes/phonePatternPipe";
import { EventHandler } from "./../../../common/services/eventHandler";
import { MemberKeyService } from "./../../../common/services/memberKeySvc";
import { NavigationService } from "./../../../common/services/navigationService";
import { AppUtility } from "./../../../common/utilities/appUtil";
import { RouteUtil } from "./../../../common/utilities/routeUtil";
import { AppSession } from "./../../../common/values/appSession";
import { ContentHelper } from "./../../../common/values/contentHelper";

@Component({
  moduleId: module.id,
  selector: "[app-fad-pf-dynamic-care-team-cmp]",
  templateUrl: "../views/pfDynamicCareTeamCmp.html",
  providers: [],
})
export class PFDynamicCareTeamComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild("PCP")
  PCP: TemplateRef<HTMLElement>;
  @Input()
  searchParams: ISearchParameters;
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();
  sidePanelRef: ModalRef<any, any>;
  careProviders: any = [];
  showPCPInfo: boolean = false;
  showCareTout: boolean = true;
  showCareTeamLoader: boolean = false;
  showReadOnlyMessage: boolean = false;
  careTeamResponse: CareTeamResponse;
  private _pfAppCommandEvent: EventEmitter<IEventDetail> =
    this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  @Output()
  loader: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  data = new EventEmitter<{ showCareTout: boolean, showCareTeamLoader: boolean }>();//TODO Remove this data event and start using careCircleApiStatusEvt from May 2024 release.
  @Output()
  careCircleApiStatusEvt: EventEmitter<IApiStatusEvent> = new EventEmitter<IApiStatusEvent>();
  @ViewChild("removeProviderModal")
  removeProviderModal: TemplateRef<HTMLElement>;
  removeProviderConfirm: string = "";
  selectedMbr: IOptions;
  removeProviderPanelRef: ModalRef<any, any>;
  removeProvider: ICareProvider;
  private _header: string;
  @Input() set header(value: string) {
    this._header = value;
  }
  get header(): string {
    return this._header && this._header.length > 0
      ? this._header
      : this.content.searchCriteriaComponent.labels.mostRecentCareTeam;
  }
  isMemberSecure: boolean = false;
  showPageLoader: boolean = false;
  private changePlanSaveClickSubscription: Subscription;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _phonePatternPipe: PhonePatternPipe,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    private _appUtility: AppUtility,
    private _dataService: DataService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _sidePanel: SidePanel,
    private _titlecasePipe: TitleCasePipe,
    private _careTeamSvc: CareCircleService,
    private _routeUtil: RouteUtil,
    private _memberKeySvc: MemberKeyService,
    private _memberPCPHelper: MemberPCPHelper,
    private _alertHlpr: AlertHelper,
    private searchService: SearchSvc,
    private _changePlanService: ChangePlanService
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchContainerComponent"
    );
    this.changePlanSaveClickSubscription =
    this._changePlanService.onChangePlanSaveClick.subscribe(
      (path: AppNavigations) => {
        if (path === AppNavigations.SEARCH_PROVIDERS_PATH) {
          this.ngOnInit();
        }
      }
    );
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    if (this._appSession) {
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    }
    this.getCareCircleInfo();
  }

  ngOnChanges(args: SimpleChanges) {
    if (args["careTeamObj"] && args["careTeamObj"].currentValue) {
      const careTeamResponse = args["careTeamObj"].currentValue;
      this.careProviders = [];
      if (careTeamResponse) {
        if (careTeamResponse.careTeam && careTeamResponse.careTeam.length > 0) {
          let _careProviders = careTeamResponse.careTeam;
          if (this.isCareTeamEnabled && _careProviders.length > 5) {
            _careProviders = _careProviders.slice(0, 5);
          }
          this.careProviders = _careProviders;
        }
        this.managePCPMessage(careTeamResponse);
      }
    }
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }

  viewFullCareTeam() {
    this._navigationService.navigateByUrl(AppNavigations.CARE_CIRCLE_PATH);
  }

  managePCPMessage(careTeamResponse: CareTeamResponse) {
    this.showPCPInfo = false;
    if (careTeamResponse.hasPCPFeature) {
      if (careTeamResponse.isPCPReadOnly) {
        this.showPCPInfo =
          careTeamResponse.careTeam.findIndex((x) => x.isPCP) < 0;
        this.showReadOnlyMessage = true;
      } else if (careTeamResponse.careTeam.findIndex((x) => x.isPCP) < 0) {
        this.showPCPInfo = true;
        this.showReadOnlyMessage = false;
      }
    }
  }

  removeCareTeamProvider(provider: ICareProvider) {
    provider.isRemoved = true;
    this.removeProviderConfirm = "";
    this.selectedMbr = null;
    const member = this._appSession.metaData.appContract.memberList;
    let _index;
    if (
      this._appSession.metaData &&
      this._appSession.metaData.appContract &&
      this._appSession.metaData.appContract.memberList
    ) {
      if (this._appSession.metaData.appContract.mbrUid) {
        _index = this._appSession.metaData.appContract.memberList.findIndex(
          (x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid
        );
      } else {
        _index = this._appSession.metaData.appContract.memberList.findIndex(
          (x) => x.isChecked === true
        );
      }
      if (_index > -1) {
        const _member: IOptions =
          this._appSession.metaData.appContract.memberList[_index];
        this.selectedMbr = _member;
      }
    }
    this.updateProviderRemoval(provider);
  }

  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  updateProviderRemoval(provider: ICareProvider) {
    const _memberName =
      this.titleCase(this.selectedMbr.firstName) +
      " " +
      this.titleCase(this.selectedMbr.lastName);
    this.removeProviderConfirm =
      this.content.newLandingComponent.labels.removeCareProviderConfirm
        .replace(/{NAME}/gi, provider.fullNm.trim())
        .replace(/{MEMBER}/gi, _memberName.trim());
    this.removeProvider = provider;
    this.removeProviderPanelRef = this._sidePanel.open(
      "right",
      this.removeProviderModal
    );
  }

  removeCareProvider() {
    const _provider = {
      isRemoved: this.removeProvider.isRemoved,
      providerId: this.removeProvider.id,
    } as IProvider;
    this.updateCareTeam(_provider);
  }

  closeRemoveProviderModal() {
    this.removeProviderPanelRef.close();
  }

  updateCareTeam(providerObj: IProvider) {
    const disclaimerCodes: IMessage[] = [];
    let msg = "";
    if (providerObj) {
      this.removeProviderConfirm = msg;
      const _isRemoved = providerObj.isRemoved;
      this._careTeamSvc
        .updateCareTeam(
          CareCircleUtil.buildUpdateCareTeamRequest(this._appSession, [
            providerObj,
          ])
        )
        .then(
          (result: any) => {
            if (_isRemoved) {
              msg = this.buildMessage(true);
              this.removeProviderConfirm = msg;
              this.removeProvider = null;
            }
          },
          (error: any) => {
            if (_isRemoved) {
              msg = this.buildMessage(false);
              this.removeProviderConfirm = msg;
              this.removeProvider = null;
            }
          }
        );
    }
  }

  buildMessage(isSuccess: boolean) {
    let _message = "";
    if (this.selectedMbr) {
      const _memberName =
        this.titleCase(this.selectedMbr.firstName) +
        " " +
        this.titleCase(this.selectedMbr.lastName);
      const _providerName = this.titleCase(this.removeProvider.fullNm.trim());
      if (isSuccess) {
        _message =
          this.content.commonContents.labels[REMOVECARETEAM_SUCCESS_MESSAGE];
      } else {
        _message =
          this.content.commonContents.labels[REMOVECARETEAM_FAILURE_MESSAGE];
      }
      _message = _message
        .replace(/{MEMBER}/gi, _memberName.trim())
        .replace(/{PROVIDER}/gi, _providerName.trim());
    }
    return _message;
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  // Contact us link will navigate to rcp contact us page
  onContactUsClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_CONTACT_US.MSG;
    eventDetail.target = AppExternalCommands.RCP_CONTACT_US.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  // Add pcp link will navigate to results page with pcp rules applied
  onAddPCPClick() {
    if (
      this.isLocationValid() &&
      this._appSession.searchParams.plan.securePlanLabelKey ===
        AppConstants.MyPlan
    ) {
      this._appSession.searchParams.typeSelectNm = '';
      this._appSession.searchParams.ableToServePcp = true;

      this.resetAppSessionParams();
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
    this.onClose();
  }

  isLocationValid() {
    let _isValid = true;
    if (
      !(
        this.searchParams &&
        this.searchParams.coordinates &&
        this.searchParams.coordinates.latitude !== "" &&
        this.searchParams.coordinates.longitude !== ""
      )
    ) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  resetAppSessionParams() {
    this.searchParams.provNm = "";
    this.searchParams.coverageTypeCode = "";
    this.searchParams.taxonomySelectNm = [];
    this.searchParams.standAloneDental = null;
    this.searchParams.standAloneVision = null;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchParams;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
  }

  showProviderDetails(careTeam: CareTeamProvider) {
    let address = {};
    const provider = careTeam.provider;
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.carePageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details,
    };
    this._dataService.setPageTitle(pageFadObj);
    this._appSession.isCareProvider = true;
    if (provider.address.latitude && provider.address.longitude) {
      this._appSession.searchParams.coordinates = {
        longitude: "",
        latitude: "",
      };
      this._appSession.searchParams.coordinates.latitude =
        provider.address.latitude;
      this._appSession.searchParams.coordinates.longitude =
        provider.address.longitude;
    }
    if (
      careTeam.providerTypes &&
      careTeam.providerTypes.length === 1 &&
      careTeam.providerTypes[0] === PROVIDER_TYPE.PHARMACY
    ) {
      this._appSession.searchParams.coverageTypeCode = RETAIL_PHARMACY;
      if (provider.address) {
        let provAddress = provider.address;
        address = {
          addressId: provAddress.addressId,
          city: provAddress.city,
          state: provAddress.stateCd ? provAddress.stateCd.code : "",
          postalCode: provAddress.zipCd,
        };
      }
    } else {
      this._appSession.searchParams.coverageTypeCode = "";
      if (provider.address) {
        address = {
          addressId: provider.address.addressId,
        };
      }
    }
    const selectedProvider = {
      providerIdentifier: provider.providerId,
      providerTypeCodeList: careTeam.providerTypes,
      providerName: provider.fullNm,
      location: {
        address: address,
      },
      isShipDirectToDoor: careTeam.isShipDirectToDoor
    } as ISelectedProvider;
    this._providerDetailsNavigationService.navigateToProviderDetails(
      selectedProvider
    );
  }

  getActionItems(careTeam: CareTeamProvider): Array<string> {
    const actionItems: Array<string> = [];
    if (careTeam.isPCP && !this.showReadOnlyMessage) {
      actionItems.push(
        this.content.newLandingComponent.actionItemsLabel.changePCP
      );
    }
    if (careTeam.isPCP && this.showReadOnlyMessage) {
      actionItems.push(
        this.content.newLandingComponent.actionItemsLabel.memberSvcChangePCP
      );
    }
    if (
      careTeam?.appointmentAvailable &&
      careTeam?.provider?.address?.addressId &&
      this.isMemberSecure &&
      !this._appSession.isEyc &&
      !this._appSession.isChangePCP &&
      this._appSession.feature.showScheduleAppointment &&
      this.customizedFeatures.showScheduleAppointment
    ) {
      actionItems.push(
        this.content.newLandingComponent.actionItemsLabel.bookAppointment
      );
    }
    if (!careTeam.isPCP) {
      actionItems.push(
        this.content.newLandingComponent.actionItemsLabel.removeFromCareTeam
      );
    }
    return actionItems;
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  /** Event handler for click of close button  */
  onClose() {
    this.sidePanelRef.close();
  }

  appointmentSchedule(careTeam: CareTeamProvider) {
    const eventDetail = {} as IEventDetail;
    const appointmentObj = {} as IAppointment;
    const appointmentLoaderObj = {} as IAppointmentLoader;
    appointmentLoaderObj.loader = true;
    appointmentLoaderObj.apiError = false;
    this.showPageLoader = true;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.details = eventDetail.message =
      AppExternalCommands.RCP_SCHEDULER_LANDING.MSG;
    eventDetail.target = AppExternalCommands.RCP_SCHEDULER_LANDING.CMD;
    const _memberKeyReq = {} as IMemberKeyRequest;
    if (careTeam?.npiList) {
      _memberKeyReq.npi = careTeam.npiList;
    }
    if (careTeam?.provider?.address?.addressId) {
      _memberKeyReq.facilityId = careTeam.provider.address.addressId;
    }
    if (this._appSession.metaData.idToken) {
      _memberKeyReq.idToken = this._appSession.metaData.idToken;
    }
    /** Search params excluding the drop-down object values */
    const _appSession: AppSession = Object.assign({}, this._appSession);
    _appSession.searchParams = this._appSession.searchParams;
    const _searchParams: ISearchParameters = Object.assign(
      {},
      _appSession.searchParams
    );
    _searchParams.providerTypeDdlValues = null;
    _searchParams.speicalityDdlValues = null;
    _searchParams.subSpeicalityDdlValues = null;
    _searchParams.categoryDescription = null;
    _memberKeyReq.searchParameters = _searchParams;
    let _routeUrl = "";
    if (this._appSession.isCareProvider) {
      _routeUrl = AppNavigations.CARE_TEAM_PATH;
    } else {
      _routeUrl = this._routeUtil.getResolvedUrl(this._route.snapshot);
    }
    _memberKeyReq.routeUrl = _routeUrl;

    this._memberKeySvc.getMemberKey(_memberKeyReq).then(
      (resp: any) => {
        if (resp && resp.memberkey) {
          appointmentObj.appKey = resp.memberkey;
          if (resp.cacheKey) {
            appointmentObj.cacheKey = resp.cacheKey;
          }
          appointmentObj.origin = this.getOrigin(_routeUrl);
          eventDetail.object = appointmentObj;
          appointmentLoaderObj.loader = false;
          this.showPageLoader = false;
          this._pfAppCommandEvent.emit(eventDetail);
        } else {
          this.showPageLoader = false;
        }
      },
      (error: any) => {
        this.showPageLoader = false;
        this._alertHlpr.openAlert(
          this.content.planSelectionComponent.apiError,
          "negative"
        );
      }
    );
  }

  /**
   * return page origin
   * @param routeUrl
   */
  private getOrigin(routeUrl: string) {
    switch (routeUrl) {
      case AppNavigations.CARE_TEAM_PATH:
        return ORIGIN.CARE_TEAM;
      case AppNavigations.CARE_CIRCLE_PATH:
        return ORIGIN.CARE_CIRCLE;
      default:
        return ORIGIN.SEARCH_RESULTS;
    }
  }

  private openSidePanel(direction: "right") {
    this.sidePanelRef = this._sidePanel.open(direction, this.PCP);
  }
  private getCareCircleInfo() {
    const evt: IApiStatusEvent = {
      isProgress: true,
      isSuccess: false,
      isFailure: false,
      hasValidData: false,
    };
    this.careCircleApiStatusEvt.emit(evt);
    this.showCareTeamLoader = true;
    this.showCareTout = false;
    this._careTeamSvc
      .getCareCircle(
        CareCircleUtil.buildCareCircleRequest(
          this._appSession,
          CARE_TEAM_TYPE.SELECTED
        )
      )
      .then(
        (result: ICareCircle) => {
          evt.isProgress = false;
          evt.isSuccess = true;
          this.careTeamResponse = {} as CareTeamResponse;
          if (result && result.pcp) {
            this.careTeamResponse.careTeam = [...result.pcp.data];
            this.careTeamResponse.hasPCPFeature = result.pcp.hasPCPFeature;
            this.careTeamResponse.hasPCPRemoveFeature = result.pcp.hasPCPRemoveFeature;
            this.careTeamResponse.isPCPReadOnly = result.pcp.isPCPReadOnly;
          }
          if (result && result.careTeam) {
            this.careTeamResponse.careTeam = [...this.careTeamResponse.careTeam, ...result.careTeam.data];
          }
          if (
            this.careTeamResponse &&
            ((this.careTeamResponse.careTeam && this.careTeamResponse.careTeam.length) ||
              this.careTeamResponse.hasPCPFeature)
          ) {
            this.showCareTout = false;
            this.showCareTeamLoader = false;
            evt.hasValidData = true;
          } else {
            this.showCareTout = true;
            evt.hasValidData = false;
          }
          this.careProviders = [];
          if (this.careTeamResponse) {
            if (this.careTeamResponse.careTeam && this.careTeamResponse.careTeam.length > 0) {
              let _careProviders = this.careTeamResponse.careTeam;
              if (this.isCareTeamEnabled && _careProviders.length > 5) {
                _careProviders = _careProviders.slice(0, 5);
              }
              this.careProviders = _careProviders;
              CareCircleUtil.showOnlinePharmacy(this.careProviders);
            }
          }
          this.managePCPMessage(this.careTeamResponse);
          this._memberPCPHelper.updatePcpProviderSession(result);
          this.data.emit({ showCareTout: this.showCareTout, showCareTeamLoader: this.showCareTeamLoader });
          this.careCircleApiStatusEvt.emit(evt);
        },
        (error: unknown) => {
          evt.isProgress = false;
          evt.isFailure = true;
          this.showCareTeamLoader = false;
          this.data.emit({ showCareTout: this.showCareTout, showCareTeamLoader: this.showCareTeamLoader });
          this.careCircleApiStatusEvt.emit(evt);
        }
      );
  }
}
