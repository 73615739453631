import { Inject, Injectable } from '@angular/core';
import { REGEX_MATCH_PATTERN } from '../components/geoLocation/models/geoLocation';
import { ResponseType } from '../enums/responseTypeEnum';
import { AppConfig } from '../values/appConfig';
import { AppSession } from '../values/appSession';
import { HttpClientService } from './../../common/services/httpClientService';
import { HttpMethod } from './../enums/httpMethodEnum';

/**
 *  BING Map Geocode API
 */
@Injectable()
export class GeocodeApi {
  private _appConfig: AppConfig;

  constructor(@Inject(AppSession) private _appSession: AppSession,
    private _httpClientService: HttpClientService) {
      this._appConfig = this._appSession.appConfig;
  }

  getAddressByZip(location: string): Promise<any> {
    // sanitizing user input
    location = location.replace(/,\s*$/, '');
    let _parameter = this.identifySearchParameter(location);
    _parameter = this.identifySearchPattern(_parameter);

    const p = this._httpClientService.request({
      method: HttpMethod.Get,
      url: `${this._appConfig?.bingMaps.baseUrl}${this._appConfig?.bingMaps.locations}`,
      urlParams: [
        {
          name: 'output',
          value: 'json',
          isQueryParam: true
        },
        {
          name: 'postalCode',
          value: location,
          isQueryParam: true
        },
        {
          name: 'key',
          value: this._appConfig?.bingMaps.apiKey,
          isQueryParam: true
        }
      ],
      responseType: ResponseType.Json,
      jsonpCallback: 'jsonp'
    });

    return p.then((result) => {
      return this.populateGeoSuggestion(result, _parameter);
    });
  }

  getAddressByCity(location: string): Promise<any> {
    // sanitizing user input
    location = location.replace(/,\s*$/, '');
    let _parameter = this.identifySearchParameter(location);
    _parameter = this.identifySearchPattern(_parameter);

    const params = this.getParams();

    if (_parameter.patternEnum === 'CITY') {
      params.push(
        {
          name: 'locality',
          value: _parameter.city,
          isQueryParam: true
        },
        {
          name: 'maxRes',
          value: '15',
          isQueryParam: true
        },
        {
          name: 'inclnb',
          value: '1',
          isQueryParam: true
        });
    } else if (_parameter.patternEnum === 'CITY_STATE') {
      params.push(
        {
          name: 'adminDistrict',
          value: _parameter.state,
          isQueryParam: true
        },
        {
          name: 'locality',
          value: _parameter.city,
          isQueryParam: true
        },
        {
          name: 'maxRes',
          value: '15',
          isQueryParam: true
        },
        {
          name: 'inclnb',
          value: '1',
          isQueryParam: true
        });
    } else if (_parameter.patternEnum === 'FULL_ADDRESS') {
      params.push(
        {
          name: 'addressLine',
          value: _parameter.street,
          isQueryParam: true
        },
        {
          name: 'locality',
          value: _parameter.city,
          isQueryParam: true
        },
        {
          name: 'adminDistrict',
          value: _parameter.state,
          isQueryParam: true
        },
        {
          name: 'maxRes',
          value: '8',
          isQueryParam: true
        });
    } else if (_parameter.patternEnum === 'COUNTY' || _parameter.patternEnum === 'COUNTY_STATE') {
      params.push(
        {
          name: 'adminDistrict',
          value: _parameter.county + '  Co.',
          isQueryParam: true
        },
        {
          name: 'maxRes',
          value: '15',
          isQueryParam: true
        });
    }

    const p = this._httpClientService.request({
      method: HttpMethod.Get,
      url: `${this._appConfig?.bingMaps.baseUrl}${this._appConfig?.bingMaps.locations}`,
      urlParams: params,
      responseType: ResponseType.Json,
      jsonpCallback: 'jsonp'
    });

    return p.then((result) => {
      const data: any = result || {};

      return this.populateGeoSuggestion(data, _parameter);
    });
  }

  getAddressByState(location: string): Promise<any> {
    const p = this._httpClientService.request({
      method: HttpMethod.Get,
      url: `${this._appConfig?.bingMaps.baseUrl}${this._appConfig?.bingMaps.locations}`,
      urlParams: [
        {
          name: 'output',
          value: 'json',
          isQueryParam: true
        },
        {
          name: 'CountryRegion',
          value: 'US',
          isQueryParam: true
        },
        {
          name: 'adminDistrict',
          value: location,
          isQueryParam: true
        },
        {
          name: 'inclnb',
          value: '0',
          isQueryParam: true
        },
        {
          name: 'key',
          value: this._appConfig?.bingMaps.apiKey,
          isQueryParam: true
        }
      ],
      responseType: ResponseType.Json,
      jsonpCallback: 'jsonp'
    });

    return p.then((result) => {
      const data: any = result || {};

      return this.populateGeoSuggestion(data, '');
    });
  }

  getAddressByQuery(query: string): Promise<any> {
    return this._httpClientService.request({
      method: HttpMethod.Get,
      url: `${this._appConfig?.bingMaps.baseUrl}${this._appConfig?.bingMaps.locations}`,
      urlParams: [
        {
          name: 'output',
          value: 'json',
          isQueryParam: true
        },
        {
          name: 'q',
          value: query,
          isQueryParam: true
        },
        {
          name: 'key',
          value: this._appConfig?.bingMaps.apiKey,
          isQueryParam: true
        }
      ],
      responseType: ResponseType.Json,
      jsonpCallback: 'jsonp'
    });
  }

  getAddressByLatLong(latitude: any, longitude: any): Promise<any> {
    const query = `${latitude},${longitude}`;
    const p = this._httpClientService.request({
      method: HttpMethod.Get,
      url: `${this._appConfig?.bingMaps.baseUrl}${this._appConfig?.bingMaps.locations}/${query}`,
      urlParams: [
        {
          name: 'output',
          value: 'json',
          isQueryParam: true
        },
        {
          name: 'key',
          value: this._appConfig?.bingMaps.apiKey,
          isQueryParam: true
        }
      ],
      responseType: ResponseType.Json,
      jsonpCallback: 'jsonp'
    });

    return p.then((data) => {
      let addressByLatLong = {
        zipcode: '',
        formattedAddress: ''
      };

      if (data && data.resourceSets
        && data.resourceSets.length > 0
        && data.resourceSets[0].resources
        && data.resourceSets[0].resources.length > 0
        && data.resourceSets[0].resources[0]
        && data.resourceSets[0].resources[0].address
        && data.resourceSets[0].resources[0].address.postalCode
        && data.resourceSets[0].resources[0].address.postalCode !== '') {
        addressByLatLong.zipcode = data.resourceSets[0].resources[0].address.postalCode;
        addressByLatLong.formattedAddress = (data.resourceSets[0].resources[0].address.formattedAddress
          && data.resourceSets[0].resources[0].address.formattedAddress !== '')
          ? data.resourceSets[0].resources[0].address.formattedAddress : '';
      }

      return addressByLatLong;
    });
  }

  private populateGeoSuggestion(data: any, parameter: any) {
    const geoSuggestionArr: any = [];
    let _neighborhood = '';
    let _locality = '';

    if (data.resourceSets
      && data.resourceSets.length > 0
      && data.resourceSets[0].resources
      && data.resourceSets[0].resources.length > 0) {

      for (let i = 0; i < data.resourceSets[0].resources.length; i++) {

        let noValidData = false;

        if (parameter.patternEnum === 'ZIP_CODE') {
          noValidData = (data.resourceSets[0].resources[i].address.postalCode == null
            || data.resourceSets[0].resources[i].address.postalCode === '');
        }

        if (noValidData) {
          continue;
        }

        if ((data.resourceSets[0].resources[i].address.locality === undefined
          || data.resourceSets[0].resources[i].address.formattedAddress === undefined)
          && data.resourceSets[0].resources[i].address.neighborhood === undefined
          && parameter.patternEnum !== 'COUNTY' && parameter.patternEnum !== 'COUNTY_STATE'
          && parameter.patternEnum !== 'ZIP_CODE') {
          continue;
        }

        if ((data.resourceSets[0].resources[i].address.countryRegion === 'United States'
          || data.resourceSets[0].resources[i].address.countryRegion === 'Puerto Rico'
          || data.resourceSets[0].resources[i].address.countryRegion === 'US Virgin Islands'
          || data.resourceSets[0].resources[i].address.countryRegion === 'Guam'
          || data.resourceSets[0].resources[i].address.countryRegion === 'American Samoa') === false
          || !data.resourceSets[0].resources[i].geocodePoints) {
          continue;
        }

        _neighborhood = '';
        _locality = '';

        if (typeof data.resourceSets[0].resources[i].address.neighborhood !== undefined
          && data.resourceSets[0].resources[i].address.neighborhood) {
          _neighborhood = data.resourceSets[0].resources[i].address.neighborhood.trim();
        }

        if (typeof data.resourceSets[0].resources[i].address.locality !== undefined
          && data.resourceSets[0].resources[i].address.locality) {
          _locality = data.resourceSets[0].resources[i].address.locality.trim();
        }
        const addInfo: any = data.resourceSets[0].resources[i].address.formattedAddress.split(',');
        const _adminDistrict2 = data.resourceSets[0].resources[i].address.adminDistrict2;
        let _county = '';
        if (_adminDistrict2 && _adminDistrict2.includes('County')) {
          _county = _adminDistrict2.replace(/county/gi, '').trim();
        }
        const mapObj = {
          lat: data.resourceSets[0].resources[i].geocodePoints[0].coordinates[0],
          long: data.resourceSets[0].resources[i].geocodePoints[0].coordinates[1],
          name: data.resourceSets[0].resources[i].name,
          city: addInfo[0],
          state: addInfo[1] ? addInfo[1].trim() : '',
          // **Ln-321 Need to remove or re-implement in DSEPRECARE-3708
          stateCd: addInfo[1] ? this.formatStateCode(addInfo[1]) : '',
          friendlyText: '',
          county: _county,
          isCounty: false
        };

        if (parameter.patternEnum === 'CITY'
          && typeof data.resourceSets[0].resources[i].address.adminDistrict !== undefined
          && data.resourceSets[0].resources[i].address.adminDistrict) {

          if (typeof data.resourceSets[0].resources[i].address.formattedAddress !== undefined
            && data.resourceSets[0].resources[i].address.formattedAddress) {
            mapObj.friendlyText = data.resourceSets[0].resources[i].address.formattedAddress;
          } else {
            mapObj.friendlyText = (_locality != null && _locality !== '') ?
              _neighborhood : _locality + ', ' + data.resourceSets[0].resources[i].address.adminDistrict.trim();
          }

        } else if (parameter.patternEnum === 'FULL_ADDRESS'
          && typeof data.resourceSets[0].resources[i].address.addressLine !== undefined
          && data.resourceSets[0].resources[i].address.addressLine) {

          const localitytemp = (_locality == null || _locality === '') ? _neighborhood : _locality;

          mapObj.friendlyText = data.resourceSets[0].resources[i].address.addressLine.trim() + ', \
            ' + localitytemp + ', ' + data.resourceSets[0].resources[i].address.adminDistrict.trim();

        } else if ((parameter.patternEnum === 'COUNTY' || parameter.patternEnum === 'COUNTY_STATE')
          && typeof data.resourceSets[0].resources[i].address.adminDistrict2 !== undefined
          && data.resourceSets[0].resources[i].address.adminDistrict2) {

          mapObj.friendlyText = data.resourceSets[0].resources[i].address.adminDistrict2
            .replace(' Co.', '').trim() + ', ' + data.resourceSets[0].resources[i].address.adminDistrict.trim();
          data.resourceSets[0].resources[i].name = mapObj.name = mapObj.friendlyText;
          mapObj.isCounty = true;
          mapObj.state = data.resourceSets[0].resources[i].address.adminDistrict.trim();
          mapObj.stateCd = data.resourceSets[0].resources[i].address.adminDistrict.trim();
          data.resourceSets[0].resources[i].name = mapObj.name = mapObj.friendlyText;
        }

        if (geoSuggestionArr[data.resourceSets[0].resources[i].name] === undefined) {
          geoSuggestionArr[data.resourceSets[0].resources[i].name] = mapObj;
        }
      }
    }

    return geoSuggestionArr;
  }

  identifySearchParameter(searchValue: string) {
    // expectation is for user to seperate CITY, COUNTY, STATE with ","
    const searchStrings = (searchValue.indexOf(',') < 0) ? [searchValue] : searchValue.split(',');

    // defining parameter object structure
    const parameter = {
      city: '',
      county: '',
      state: '',
      zipcode: '',
      street: '',
      misc: '',
      patternEnum: '',
      bingquerystring: '',
      locationbox: '',
      ex: ''
    };
    let param2 = '';
    let param1 = '';
    const zipRegex = new RegExp('^\\d{5}(-\\d{4})?$');

    // checking if CITY, STATE or STATE, CITY or COUNTY, STATE or STATE, COUNTY
    if (searchStrings.length === 2) {
      param1 = searchStrings[0].toUpperCase().trim();
      param2 = searchStrings[1].toUpperCase().trim();

      // COUNTY scenario
      if (param1.indexOf(' COUNTY') >= 0 || param2.indexOf('COUNTY') >= 0) {
        // Scenario: COUNTY, STATE
        if (param1.indexOf('COUNTY') >= 0) {
          parameter.county = param1.replace(' COUNTY', '');
          parameter.state = param2;
        } else {
          // Scenario: STATE, COUNTY
          parameter.county = param2.replace(' COUNTY', '');
          parameter.state = param1;
        }
      } else {
        // Scenario: CITY, STATE
        if (param2.length === 2) {
          parameter.city = param1;
          parameter.state = param2;
        } else {
          // Scemario: STATE, CITY
          parameter.city = param1;
          parameter.state = param2;

        }
      }
    } else if (searchStrings.length === 1) {
      // Scenario: Where single value entered could be ZIPCODE or COUNTY or CITY (not expecting state here)
      param1 = (searchStrings[0]).toUpperCase().trim();
      let regex = /\D/; // County or State muste have atleast one alphabet
      if (zipRegex.test(param1)) {
        parameter.zipcode = param1;
      } else if (param1.indexOf(' COUNTY') >= 0) {
        parameter.county = param1.replace(' COUNTY', '');
      } else if (!REGEX_MATCH_PATTERN.FOUR_DIGIT_NUMBER.test(param1) && regex.test(param1)) {
        parameter.city = param1;
      } else if (!REGEX_MATCH_PATTERN.FOUR_DIGIT_NUMBER.test(param1)) {
        /**
         * when search text have digits only and exceed the zip code limit
         * **/
        parameter.misc = param1;
        parameter.ex = parameter.ex + 'Issue with location entered in textbox \n';
      }
    } else if (searchStrings.length === 3 && searchStrings[2].trim().length === 2) {
      // Scenario: FULL ADDRESS
      parameter.street = searchStrings[0].toUpperCase().trim();
      parameter.city = searchStrings[1].toUpperCase().trim();
      parameter.state = searchStrings[2].toUpperCase().trim();
    } else {
      // Scenario: UNDEFINED failing scenario.
      parameter.misc = searchValue.trim();
      parameter.ex = parameter.ex + 'Issue with location entered in textbox \n';
    }

    return parameter;
  }

  /* Tempory fix to handle issue with Puerto Rico
      which is returned as state code. Should remove the
      code while implementing DSEPRECARE-3708
  */
  private formatStateCode(stateCode): string {
    let stateCd = stateCode.trim();
    const matchedText = stateCd.match(/\b(\w)/g);
    if (matchedText.length > 1) {
      stateCd = matchedText.join('');
    }
    return stateCd;
  }

  identifySearchPattern(parameter: any) {
    const _parameter = parameter;
    if (parameter.zipcode != null && parameter.zipcode !== '') {
      _parameter.patternEnum = 'ZIP_CODE';
    } else if (parameter.state != null && parameter.state !== '') {
      if ((parameter.street != null && parameter.street !== '') && (parameter.city != null && parameter.city !== '')) {
        _parameter.patternEnum = 'FULL_ADDRESS';
      } else if (parameter.city != null && parameter.city !== '') {
        _parameter.patternEnum = 'CITY_STATE';
      } else if (parameter.county != null && parameter.county !== '') {
        _parameter.patternEnum = 'COUNTY_STATE';
      } else {
        _parameter.ex = parameter.ex + 'Issue identifying patter City, State OR County, State \n';
      }
    } else if (parameter.city != null && parameter.city !== '') {
      _parameter.patternEnum = 'CITY';
    } else if (parameter.county != null && parameter.county !== '') {
      _parameter.patternEnum = 'COUNTY';
    } else if (parameter.misc != null && parameter.misc !== '') {
      _parameter.patternEnum = 'UNKNOWN';
    } else {
      _parameter.ex = parameter.ex + 'Issue identifying exact pattern for search \n';
    }

    return _parameter;
  }

  private getParams() {
    const urlParams = [
      {
        name: 'output',
        value: 'json',
        isQueryParam: true
      },
      {
        name: 'CountryRegion',
        value: 'US',
        isQueryParam: true
      },
      {
        name: 'key',
        value: this._appConfig?.bingMaps.apiKey,
        isQueryParam: true
      }
    ];

    return urlParams;
  }

}
