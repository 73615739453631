import { Injectable } from '@angular/core';
import { DOCTOR_NAME, MEMBER_NAME } from './../../../common/constants/app-constants';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { IPCPValidateResponse } from './../../../common/interfaces/iPcpDetails';
import { IPcpRequest, IPcpValidateRequest } from './../../../common/interfaces/iPcpRequest';
import { ApiHelper } from './../../../common/services/apiHelper';
import { DataHelper } from './../../../common/services/dataHelper';
import { PCPService } from './../../../common/services/pcpSvc';
import { ContentHelper } from './../../../common/values/contentHelper';

@Injectable()
export class MemberPcpValidateApiHelper extends ApiHelper<IPcpValidateRequest, IPCPValidateResponse>{

  memberName: string = '';
  providerName: string;

  constructor(private _pcpSvc: PCPService, private _dataHlpr: DataHelper, private _contentHlpr: ContentHelper) {
    super();
  }

  execute(request: IPcpValidateRequest): Promise<IPCPValidateResponse> {
    if (!request || !Array.isArray(request.pcpIdItems) || request.pcpIdItems.length === 0) {
      return Promise.reject(new Error('Invalid request param'));
    }
    this.alert.alertContent = '';
    let validatePcp1Promise: Promise<IPCPValidateResponse>;
    let validatePcp2Promise: Promise<IPCPValidateResponse>;
    const validatePcp1Req: IPcpRequest = {
      contractUid: request.pcpIdItems[0].contractUid,
      multi_updates: request.pcpIdItems[0].multi_updates,
      pcpInfo: {
        pcpId: request.pcpIdItems[0].pcpId,
        pcpTrmntnRsnCd: request.otherReasonCd
      },
      selMbrUid: request.selMbrUid
    };
    if (request.pcpIdItems.length === 2) {
      //Call validate API 2 times
      const validatePcp2Req: IPcpRequest = {
        contractUid: request.pcpIdItems[1].contractUid,
        multi_updates: request.pcpIdItems[1].multi_updates,
        pcpInfo: {
          pcpId: request.pcpIdItems[1].pcpId,
          pcpTrmntnRsnCd: request.otherReasonCd
        },
        selMbrUid: request.selMbrUid
      };
      validatePcp1Promise = this._pcpSvc.validateModifyPCP(validatePcp1Req);
      validatePcp2Promise = this._pcpSvc.validateModifyPCP(validatePcp2Req);
    } else if (request.pcpIdItems.length === 1) {
      //Call validate API only once
      validatePcp1Promise = this._pcpSvc.validateModifyPCP(validatePcp1Req);
      validatePcp2Promise = Promise.resolve(null);
    }

    const resultPromise = new Promise<IPCPValidateResponse>((resolve, reject) => {
      (async () => {
        try {
          const validateApiResponses = await Promise.all([validatePcp1Promise, validatePcp2Promise]);
          const combinedResp: IPCPValidateResponse = {
            results: []
          };
          validateApiResponses.forEach((resp) => {
            if (resp?.results) {
              combinedResp.results.push(...resp.results);
            }
          });
          resolve(combinedResp);
        } catch (error) {
          reject(error);
        }
      })();
    });

    return resultPromise;
  }

  onSuccess(resp: IPCPValidateResponse): void {
    //Process the failure messages
    const errors: IException[] = [];
    resp.results.forEach((result) => {
      if (!this._dataHlpr.isEmptyString(result.errorCode)) {
        errors.push({
          code: result.errorCode,
          message: result.msg
        });
      }
    });

    if (errors.length >= 1) {
      const errorResp = {
        error: {
          exceptions: {
            error: errors
          }
        }
      }
      throw errorResp;
    }
  }

  onFailure(errResp): void {
    const content = this._contentHlpr.getContent('PFAssignPcpContainerComponent').assignPcpCmpContent;
    let errors: IException[] = errResp?.error?.exceptions?.error;

    if (errResp instanceof Error) {
      errors = [this.getException(errResp.message)]
    }

    let message = '';
    if (errors) {
      //Consider only the 0th error to display in UI
      const error = errors[0];
      message = this._dataHlpr.getValueByKey<string>(error.code, content.validateApiErrors, error.message);
      message = message.replace(DOCTOR_NAME, this.providerName);
      message = message.replace(MEMBER_NAME, this.memberName);
    }

    this.alert.alertContent = message;
  }
}
