import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../home/components/homeCmp';
import { LandingComponent } from '../landing/components/landingCmp';
import { ResultComponent } from '../result/components/resultCmp';
import { AuthGuardService } from './../../common/services/authGuardService';
import { ContentResolver } from './services/contentResolver';

const routes: Routes = [
  {
    path: 'fc-home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    resolve: { content: ContentResolver }
  },
  {
    path: 'fc-landing',
    component: LandingComponent,
    canActivate: [AuthGuardService],
    resolve: { content: ContentResolver }
  },
  {
    path: 'fc-result',
    component: ResultComponent,
    canActivate: [AuthGuardService],
    resolve: { content: ContentResolver }
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class FindCareRoutingModule {}
