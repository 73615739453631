import { Directive, HostListener } from '@angular/core';
import { UxdTooltipDirective } from '@anthem/uxd/tooltip';

@Directive({
  selector: '[app-hover-tooltip-dir]'
})
export class HoverTooltipDirective extends UxdTooltipDirective {
  @HostListener('mouseenter')
  onMouseEnter() {
    super.show();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hide(false);
  }

  show(): void {
    // Do nothing
  }
}
