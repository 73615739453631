import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IActionId, IUserInteraction } from '../../../common/interfaces/iUserInteraction';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { TAB_KEYCODE } from './../../../common/constants/app-constants';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { ILinkProcedure } from './../../../fad/search-providers/interfaces/iQuickLinks';
import { FREQUENT_PROCEDURE_DESC_CHARLIMIT } from './../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from './../../../fad/utilities/commonUtil';

@Component({
  moduleId: module.id,
  selector: 'app-promoted-procedures-tile-cmp',
  templateUrl: '../views/pfPromotedProceduresTileCmp.html'
})
export class PFPromotedProceduresTileComponent extends BaseComponent implements OnInit {

  @Input()
  procedureList: Array<ILinkProcedure>;
  @Input()
  interactionEvent: IUserInteraction;
  @Input()
  end: number;
  @Input()
  personalizedIndicator: number;
  @Output()
  procedureSearch: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('disclaimerSidePanel') disclaimerSidePanel: TemplateRef<HTMLElement>;
  disclaimerSidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  procedureName: string = '';
  procedureDesc: string = '';
  sidePanelTabKeyListener: { (): void };
  @Input() feedbackOptionIdentifier: string;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _renderer: Renderer2,
    private _sidePanel: SidePanel) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFPromotedProceduresContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  getRange(min: string, max: string): string {
    let range: string = '';
    if (min && max) {
      range = this.content.shopProcedures.labels.range.replace(/{min}/gi, min).replace(/{max}/gi, max);
    }
    return range;
  }

  searchByProcedure(card: ILinkProcedure) {
    this.procedureSearch.emit(card);
  }

  closeDisclaimerSidePanel() {
    this.disclaimerSidePanelRef.close();
    this.sidePanelTabKeyListener();
  }

  openDisclaimerSidePanelMsg(name: string, desc: string) {
    this.procedureName = name;
    this.procedureDesc = desc;
    this.disclaimerSidePanelRef = this._sidePanel.open('right', this.disclaimerSidePanel);
    this.sidePanelTabKeyListener = this._renderer.listen('document', 'keydown', (event) => {
      if (event.keyCode === TAB_KEYCODE) {
        event.preventDefault();
      }
    });
  }

  getLimitedDisclaimerMsg(desc:string): string {
    return CommonUtil.getLimitedMessage(desc, FREQUENT_PROCEDURE_DESC_CHARLIMIT);
  }

  getProcedureCompareInteractionEvent(procedure: ILinkProcedure): string {
    const actionObj = this.getActionIdObj(procedure);
    return this.userInteractionUtil.getUserInteraction(actionObj, this.interactionEvent);
  }

  getActionIdObj(procedure: ILinkProcedure): IActionId {
    const actionIdObj = {
      actionId: CommonUtil.isValidString(procedure?.modelId) ? procedure.modelId : procedure.procedureCode.code,
      personalizedIndicator: this.personalizedIndicator,
      rankPercentile: procedure.rankPercentile
    } as IActionId;
    return actionIdObj;
  }

  getInteractionPayload(procedure: ILinkProcedure): IUserInteraction {
    const actionObj = this.getActionIdObj(procedure);
    const userInteraction = {} as IUserInteraction;
    userInteraction.actionId = actionObj.actionId;
    userInteraction.personalizedIndicator = actionObj.personalizedIndicator;
    userInteraction.rankPercentile = actionObj.rankPercentile;
    userInteraction.eventType = this.interactionEvent.eventType;
    return userInteraction;
  }
}
