import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { PfLookUpListComponent } from './pfLookUpListCmp';

@NgModule({
    declarations: [PfLookUpListComponent],
    exports: [PfLookUpListComponent],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: []
})
export class LookUpListModule { }
