import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { AppUtility } from '../../../../../../../common/utilities/appUtil';
import { AppSession } from '../../../../../../../common/values/appSession';
import { ISearchParameters } from '../../../../../../../fad/search-providers/interfaces/iSearchParameters';
import { BaseComponent } from '../../../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-care-plan-wrapper-cmp',
  templateUrl: './carePlanWrapperCmp.html'
})
export class CarePlanWrapperComponent extends BaseComponent implements OnInit {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  contractChanged: EventEmitter<void> = new EventEmitter<void>();

  isMultipleMember = false;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _appUtility: AppUtility
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appUtility.scrollToTop();
  }

  showContractChangeAlert() {
    this.contractChanged.emit();
  }
}
