import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalRef, SidePanel } from "@anthem/uxd/modal";
import { AFFILIATION_GROUP, AFFILIATION_LABEL, AFFILIATION_NAME, ID, RIGHT } from "../../constants/app-constants";
import { IPCPInfo, IPCPInfoUIData } from "../../interfaces/iPCPInfo";
import { DataHelper } from "../../services/dataHelper";
import { EventHandler } from "../../services/eventHandler";
import { PcpDetailsUtil } from "../../utilities/pcpDetailsUtil";
import { AppSession } from "../../values/appSession";
import { ContentHelper } from "../../values/contentHelper";
import { BaseComponent } from "../base-component/baseCmp";

@Component({
  moduleId: module.id,
  selector: "app-pcp-id-slide-out-cmp",
  templateUrl: "./pcpIdSlideOutCmp.html",
})
export class PCPIdSlideOutComponent extends BaseComponent implements OnInit {
  @ViewChild("viewPCPIdsSlider")
  viewPCPIdsSlider: TemplateRef<HTMLElement>;
  @Input()
  pcpInfoList: IPCPInfo[] = [];
  @Input()
  index: number;
  sidePanelRef: ModalRef<HTMLElement>;
  pcpIdInfoList: IPCPInfoUIData[] = [];

  constructor(
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _dataHlpr: DataHelper,
    private _pcpUtils: PcpDetailsUtil
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchResultsContainerComponent"
    );
  }

  ngOnInit() {
    this.createPCPInfoListData();
  }

  openPCPIdInfoSlider(): void {
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.viewPCPIdsSlider);
  }

  onClose(): void {
    this.sidePanelRef?.close();
  }

  private createPCPInfoListData(): void {
    this.pcpIdInfoList = this._pcpUtils.groupByPcpId(this.pcpInfoList, ID, [AFFILIATION_NAME, AFFILIATION_GROUP, AFFILIATION_LABEL], this.content.pcpIdInfoSlideModalContent.partof);
  }
}
