import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PaginationComponent } from './paginationCmp';
@NgModule({
    declarations: [PaginationComponent],
    exports: [PaginationComponent],
    imports: [
        CommonModule,
        UxSharedModule
    ],
    providers: []
})
export class PaginationModule { }
