import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { Subscription } from 'rxjs';
import { IAlertMessage } from '../../../../app/common/interfaces/iMessage';
import { IWindow } from '../../../common/interfaces/iWindow';
import { IPlanRuleParameters, IRuleParameters } from '../../rules/interfaces/iRulesParameters';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { IPlanRule } from '../../search-providers/interfaces/iPlanRule';
import { ProviderSummaryService } from '../../search-results/services/providerSummarySvc';
import { ResultsUtil } from '../../utilities/resultsUtil';
import { WcsUtil } from '../../utilities/wcsUtil';
import { IProviderType } from '../interfaces/iProviderType';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import {
  AUDIOLOGIST_SPECIALTY,
  DENTALNETWORK_URL,
  DENTAL_QUEST_URL,
  DENTAL_ROUTINE_COVERAGETYPE,
  DENTAL_VSP_URL,
  HCS_URL,
  HEARING_ROUTINE_COVERAGETYPE,
  LIBERTY_DENTAL_URL,
  MEDICAL_COVERAGETYPE,
  MEDICAL_PHARMACY,
  PROVIDER_MESSAGES,
  PROVIDER_SEARCH_DISTANCE,
  PROVIDER_SEARCH_DISTANCE_FILTER,
  PROVIDER_TYPE,
  PROVIDER_TYPE_OF_CARE,
  PROVIDER_TYPE_PHARMACY_VISIT,
  RETAIL_PHARMACY,
  SPANISH_PROVIDER_SEARCH_DISTANCE_FILTER,
  TRANSPORTATION_SPECIALTY,
  VEP,
  VEP_BEH_HEALTH,
  VISION_ROUTINE_COVERAGETYPE
} from '../values/providerSearchConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { IFilterChangePlan } from './../../../common/components/change-plan/models/iChangePlanParam';
import { ChangePlanService } from './../../../common/components/change-plan/services/changePlanSvc';
import { AppConstants, Locale } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { IAdobe, IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { LocationResponse } from './../../../common/models/locationAddress';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppConfig } from './../../../common/values/appConfig';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { IMemberRequest } from './../../rules/interfaces/iRulesParameters';
import { DEFAULT_TEXT_LIST } from './../../search-results/values/providerSearchConstants';
import { CommonUtil } from './../../utilities/commonUtil';
import { IProvTypeSpecialtyUtilityResponse } from './../interfaces/iProvTypeSpecialtyUtility';
import { ISearchParameters } from './../interfaces/iSearchParameters';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-search-criteria-cmp',
  templateUrl: '../views/pfSearchCriteriaCmp.html',
  providers: []
})
export class PFSearchCriteriaComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  gotoPlanSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('location')
  location: ElementRef;
  @ViewChild('transportationModal')
  transportationModal: ModalComponent;
  @ViewChild('homeHealthCareModal')
  homeHealthCareModal: ModalComponent;
  @ViewChild('medicareSupplementModal')
  medicareSupplementModal: ModalComponent;
  @Input()
  url: any;

  isMemberSecure: boolean = false;
  myNexusHeader: string;
  myNexusContent: string;
  myNexusClose: string;
  planRule: IPlanRule;
  isDentalStandAlone: boolean = false;
  isVisionStandAlone: boolean = false;
  isPharmacyStandAlone: boolean = false;
  showSearchLoader: boolean = true;
  typeSelectDisplay: string = '';
  focusName: boolean = false;
  showSpeciality: boolean = false;
  speciialityDescription: string = '';
  focusZip: boolean = false;
  providerNamesArr: any = [];
  geoSuggestionArr: any = [];
  provDisclaimer: any = [];
  specialtySelectDisplay: string = '';
  showAdditionalOption: boolean = false;
  specialtySubSelectDisplay: string = '';
  distanceFilter: any = PROVIDER_SEARCH_DISTANCE_FILTER;
  locationError: boolean = false;
  errorText: string;
  defaultDoctorSpecialty: string = '25';
  defaultHospitalSpecialty: string = '28';
  defaultSpecialty: string = '00';
  hideSpecialty: boolean = false;
  showDental: boolean = false;
  showVision: boolean = false;
  showHearing: boolean = false;
  showPharmacy: boolean = false;
  locale: string;
  //showHearingDisclaimer: boolean = false;
  feature: IRulesResponse;
  planFeature: any;
  specialtyCategoryList: any;
  transportationLocation: any;
  appConfig: AppConfig;
  apiAlert: any = {
    hasError: false,
    alertType: '',
    alertContent: ''
  };
  hasCastlightTransparency: boolean = false;
  hasMedicalPlan: boolean = false;
  hasDentalPlan: boolean = false;
  hasVisionPlan: boolean = false;
  showPlanUnknownError: boolean = false;
  showMemberPlanError: boolean = false;
  planUnknownErrorMsg: string = '';
  memeberPlanErrorMsg: string = '';
  memeberPlanErrorLinkMsg: string = '';
  pharmacyVisit: any = PROVIDER_TYPE_PHARMACY_VISIT;
  showPharmacyDisclaimer: boolean = false;
  locatePharmacyBtn: boolean = false;
  showSearchButton: boolean = true;
  isCvsPlanIndicator: boolean;
  isEsiPlanIndicator: boolean;
  isEsiOrCVSPlanIndicator: boolean;
  isPharmacyPlanNoIndicator: boolean;
  isRxMedical: boolean = true;
  indicator: string;
  locatePharmacyUrl: string;
  dentalNetworkUrl: any = DENTALNETWORK_URL;
  isSecureState: boolean = false;
  rgDentalRadioObject1: any = {
    label: this.content.searchCriteriaComponent.labels.dentalRoutine,
    id: 'rbDentalRoutine',
    name: 'rgDental',
    value: PROVIDER_TYPE.DENTAL
  };
  rgDentalRadioObject2: any = {
    label: this.content.searchCriteriaComponent.labels.dentalMedical,
    id: 'rbDentalMedical',
    name: 'rgDental',
    value: PROVIDER_TYPE.MEDICAL_EQUIPMENT,
    analyticsText: ''
  };

  rgHearingRadioObject1: any = {
    label: this.content.searchCriteriaComponent.labels.hearingRoutine,
    id: 'rbHearingRoutine',
    name: 'rgHearing',
    value: PROVIDER_TYPE.HEARING
  };
  rgHearingRadioObject2: any = {
    label: this.content.searchCriteriaComponent.labels.hearingMedical,
    id: 'rbHearingMedical',
    name: 'rgHearing',
    value: PROVIDER_TYPE.MEDICAL_EQUIPMENT,
    analyticsText: ''
  };

  rgVisionRadioObject1: any = {
    label: this.content.searchCriteriaComponent.labels.visionRoutine,
    id: 'rbVisionRoutine',
    name: 'rgVision',
    value: PROVIDER_TYPE.VISION
  };
  rgVisionRadioObject2: any = {
    label: this.content.searchCriteriaComponent.labels.visionMedical,
    id: 'rbVisionMedical',
    name: 'rgVision',
    value: PROVIDER_TYPE.MEDICAL_EQUIPMENT,
    analyticsText: ''
  };

  rgVisionBvvRadioObject1: any = {
    label: this.content.searchCriteriaComponent.labels.yes,
    id: 'rbVisionBvvYes',
    name: 'rgVisionBvv',
    value: 'Y'
  };
  rgVisionBvvRadioObject2: any = {
    label: this.content.searchCriteriaComponent.labels.no,
    id: 'rbVisionBvvNo',
    name: 'rgVisionBvv',
    value: 'N',
    analyticsText: ''
  };

  rgHearUSAEGRRadioObject1: any = {
    label: this.content.searchCriteriaComponent.labels.yes,
    id: 'rbHearUSAEGRYes',
    name: 'rgHearUSAEGR',
    value: 'Y'
  };
  rgHearUSAEGRRadioObject2: any = {
    label: this.content.searchCriteriaComponent.labels.no,
    id: 'rbHearUSAEGRNo',
    name: 'rgHearUSAEGR',
    value: 'N',
    analyticsText: ''
  };
  private changePlanSaveClickSubscription: Subscription;

  constructor(
    private _providerSummarySvc: ProviderSummaryService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _providerUtilityService: ProviderUtilityService,
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    private _ruleService: RulesService,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _eventHandler: EventHandler,
    private _appUtility: AppUtility,
    private _changePlanService: ChangePlanService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');

    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick.subscribe((path: AppNavigations) => {
      if (path === AppNavigations.SEARCH_PROVIDERS_PATH) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.appConfig = this._appSession.appConfig;
    this._appUtility.scrollToTop();
    this.isSecureState = this._appSession && this._appSession.isSecureState;
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
    if (this._appSession.metaData.locale === Locale.SPANISH) {
      this.distanceFilter = SPANISH_PROVIDER_SEARCH_DISTANCE_FILTER;
    }
    this.getProviderTypes();
    if (this._appSession.showPrefixPopup) {
      this.showSpecialPrefixPopup();
    }
    if (this._appSession && this._appSession.pageNumber) {
      this._appSession.pageNumber = '';
    }
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }

  /// Write all ngOnInit call methods here
  initCalls() {
    if (this.searchParams.plan) {
      this.getPlanRuleResponse();
    } else {
      this.bindProviderTypes();
    }

    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.hasCastlightTransparency) {
      this.hasCastlightTransparency = this._appSession.metaData.appContract.hasCastlightTransparency;
    }

    if (this.searchParams.zip || (this._appSession.searchParams && this._appSession.searchParams.zip)) {
      this.getGeoCoordinates(true);
    }
    this.bindGeoLocation();
  }
  setRxIndiactor() {
    if (this._appSession && this._appSession.hasPharmacyPlan && this.searchParams.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.isCvsPlanIndicator = this._appSession.hasRxCvsIndicator;
      this.isEsiPlanIndicator = this._appSession.hasRxEsiIndicator;
      this.isEsiOrCVSPlanIndicator = this._appSession.hasRxEsiIndicator === true || this._appSession.hasRxCvsIndicator === true;
      this.isPharmacyPlanNoIndicator = !this._appSession.hasRxCvsIndicator && !this._appSession.hasRxEsiIndicator;

      this.pharmacyVisitInit();
    }
  }
  getPlanRuleResponse() {
    this.showSearchLoader = true;
    this._ruleService.getAppInitializationRules(this.buildPlanRuleRuleRequest()).then(
      (result: any) => {
        if (result) {
          this.planRule = {
            isLiberty: result.isLiberty ? result.isLiberty : false,
            isBVV: result.isBVV ? result.isBVV : false,
            isBVVI: result.isBVVI ? result.isBVVI : false,
            isHCS: result.isHCS ? result.isHCS : false,
            isVSP: result.isVSP ? result.isVSP : false,
            isEyeQuest: result.isEyeQuest ? result.isEyeQuest : false,
            isSuperior: result.isSuperior ? result.isSuperior : false,
            isEyeMed: result.isEyeMed ? result.isEyeMed : false,
            isTransportation: result.isTransportation ? result.isTransportation : false,
            isDentaQuest: result.isDentaQuest ? result.isDentaQuest : false,
            isIndividual: result.isIndividual ? result.isIndividual : false,
            isGroup: result.isGroup ? result.isGroup : false,
            isMedicaid: result.isMedicaid ? result.isMedicaid : false,
            isMedicareGov: result.isMedicareGov ? result.isMedicareGov : false,
            cobrandRule: result.cobrandRule,
            isOhEyeMed: result.isOhEyeMed ? result.isOhEyeMed : false
          };
          if (this.planRule.isTransportation && result.transportationLocation) {
            this.transportationLocation = this.planRule.transportationLocation = result.transportationLocation;
          }
          this.planFeature = this._appSession.planRule = this.planRule;
          this.bindProviderTypes();
        }
      },
      (error: any) => {
        this.onCategoryError('MEDICARE_RULE', error);
      }
    );
  }

  showMedicareSupplementModal() {
    if (
      this.planRule &&
      this.planRule?.isMedicareGov &&
      this.searchParams.coverageTypeCode !== VISION_ROUTINE_COVERAGETYPE &&
      this.searchParams.coverageTypeCode !== HEARING_ROUTINE_COVERAGETYPE &&
      this.searchParams.coverageTypeCode !== RETAIL_PHARMACY
    ) {
      this.medicareSupplementModal.show();

      return true;
    }
    return false;
  }

  buildPlanRuleRuleRequest() {
    let _plan = {} as IPlanRuleParameters;

    if (this.searchParams.plan) {
      _plan = {
        state: this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd : '',
        brand: this._appSession.metaData.brandCd,
        prefix: this.searchParams.plan.alphaPrefix ? this.searchParams.plan.alphaPrefix : '',
        planCategory: this.searchParams.plan.category ? this.searchParams.plan.category.catCode : '',
        productType:
          this._appSession.isSecureState === true &&
          this._appSession.metaData &&
          this._appSession.metaData.appContract &&
          this._appSession.metaData.appContract.coverageDetails &&
          this._appSession.metaData.appContract.coverageDetails.productType
            ? this._appSession.metaData.appContract.coverageDetails.productType
            : '',
        locale: Locale.ENGLISH // Send always english as we do not have Spanish rules
      };
    }

    return _plan;
  }
  getProviderTypes() {
    this._providerUtilityService.getProviderCodesList(CommonUtil.buildPrefixRuleRequest(this.searchParams, this._appSession)).then(
      (result: any) => {
        this.populateProviderTypes(result);
      },
      (error: any) => {
        this.onCategoryError('PROVIDER_TYPES', error);
      }
    );
  }

  populateProviderTypes(data: any) {
    let code: any;
    const providerTypes: IProviderType[] = [];
    code = data.codeTypeList;
    for (let i = 0; i < code.length; i++) {
      const _codeName: IProviderType = {
        code: code[i].code,
        name: code[i].name,
        licenseList: code[i].licenseCodeList && code[i].licenseCodeList.length > 0 ? code[i].licenseCodeList : [],
        isChecked: code[i].isChecked ? code[i].isChecked : false
      };
      providerTypes.push(_codeName);
    }
    this._appSession.providerTypes = providerTypes;
    this.initCalls();
  }

  buildPrefixRuleRequest() {
    const _prefix = CommonUtil.getPrefix(this.searchParams, this._appSession);

    let memberDetails: IMemberRequest;

    if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
      memberDetails = {} as IMemberRequest;

      memberDetails.mbrUid = this._appSession.metaData.appContract.mbrUid;
    }

    const ruleRequest: IRuleParameters = {
      brand: this._appSession.metaData.brandCd,
      prefix: _prefix,
      providerType: this.searchParams.typeSelectNm,
      isLevelofCare: '',
      locale: this.locale,
      network: this.searchParams.plan && this.searchParams.plan.networkList && this.searchParams.plan.networkList.length > 0 ? this.searchParams.plan.networkList : [],
      identification: this._appSession.searchParams && this._appSession.searchParams.identificationNumber ? this._appSession.searchParams.identificationNumber : undefined,
      isPCP: this._appSession.isChangePCP ? 'Y' : undefined,
      contractUid: this._appSession.metaData && this._appSession.metaData.appContract ? this._appSession.metaData.appContract.contractUid : undefined,
      member: memberDetails,
      isDeepLink: this._appSession.isDeepLink,
      deeplinkAction: this._appSession.deeplinkParams?.action,
      ableToServePcp: this.searchParams.ableToServePcp
    };

    // Rule request set plan care type.
    if (this.searchParams.plan && this.searchParams.plan.careType) {
      ruleRequest.careType = this.searchParams.plan.careType;
    }

    return ruleRequest;
  }

  showSpecialPrefixPopup() {
    const prefix = CommonUtil.getPrefix(this.searchParams, this._appSession);
    if (prefix && prefix.toUpperCase() === VEP) {
      this._appSession.showPrefixPopup = false;
      this.leavingDisclaimer.emit(VEP_BEH_HEALTH);
    }
  }

  bindProviderTypes() {
    if (this._appSession.providerTypes && this._appSession.providerTypes.length > 0) {
      this.searchParams.providerTypeDdlValues.title = 'Types';
      this.searchParams.providerTypeDdlValues.options = [];
      let _index = 0;
      for (const providerType of this._appSession.providerTypes || []) {
        if (this._appSession.planRule && !this._appSession.planRule.isHCS && providerType.code.toUpperCase().trim() === PROVIDER_TYPE.HEARING) {
          continue;
        }
        if (
          this._appSession.isSecureState &&
          providerType.code.toUpperCase().trim() === PROVIDER_TYPE.PHARMACY &&
          this._appSession.metaData.appContract.coverageDetails &&
          !this._appSession.metaData.appContract.coverageDetails.hasPharmacyPlan
        ) {
          continue;
        }
        const objProviderTypes = {
          label: providerType.name,
          value: providerType.code,
          id: 'cB_Provider_' + _index,
          name: 'cB_Provider_' + _index,
          trueValue: providerType.code,
          falseValue: '',
          isChecked: providerType.isChecked
        };
        _index++;
        if (providerType.isChecked === true) {
          this.searchParams.typeSelectNm = providerType.code;
        }
        this.searchParams.providerTypeDdlValues.options.push(objProviderTypes);
      }
      if (this._appSession.providerTypes.length === 1) {
        this.searchParams.typeSelectNm = this._appSession.providerTypes[0].code;
      }
    }
    this.setDefaultProviderType();
  }

  setDefaultProviderType() {
    this.isDentalStandAlone = this._appSession.isDentalStandAlone;
    this.isVisionStandAlone = this._appSession.isVisionStandAlone;
    this.isPharmacyStandAlone = this._appSession.isPharmacyStandAlone;
    if (!this.searchParams.typeSelectNm || this.searchParams.typeSelectNm === '') {
      if (this.searchParams.plan && this.searchParams.plan.careType) {
        const _careType = this.searchParams.plan.careType.toUpperCase().trim();
        switch (_careType) {
          case PROVIDER_TYPE_OF_CARE.DENTAL: {
            this.searchParams.typeSelectNm = PROVIDER_TYPE.DENTAL;
            this._appSession.isDentalStandAlone = this.isDentalStandAlone = true;
            break;
          }
          case PROVIDER_TYPE_OF_CARE.VISION: {
            this.searchParams.typeSelectNm = PROVIDER_TYPE.VISION;
            this._appSession.isVisionStandAlone = this.isVisionStandAlone = true;
            break;
          }
          case PROVIDER_TYPE_OF_CARE.PHARMACY: {
            this.searchParams.typeSelectNm = PROVIDER_TYPE.PHARMACY;
            this._appSession.isPharmacyStandAlone = this.isPharmacyStandAlone = true;
            break;
          }
          default: {
            this.searchParams.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
            break;
          }
        }
      } else {
        this.searchParams.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
      }
    }
    this.onTypeChange(this.searchParams.typeSelectNm, true);
  }

  onTypeChange($event: any, init: boolean) {
    if ($event !== '') {
      this.searchParams.typeSelectNm = $event;
      this.typeSelectDisplay = CommonUtil.getDropDownSelectedText(this.searchParams.providerTypeDdlValues, $event);

      if (!init) {
        this.searchParams.coverageTypeCode = '';
      }

      this.getSpecialties(init);
      this.setHearingControls();
      this.setVisionControls();
      this.setDentalControls();
      if (this._appSession && this._appSession.hasPharmacyPlan) {
        this.setRxIndiactor();
        this.setPharmacyControls();
      } else if (this.searchParams.typeSelectNm === PROVIDER_TYPE.PHARMACY && this._appSession.isSecureState === false) {
        this.searchParams.coverageTypeCode = RETAIL_PHARMACY;
      }

      if (this._appSession.isSecureState === true) {
        this.setSecureStateControls($event);
      } else {
        // hasMedicalPlan, hasDentalPlan and hasVisionPlan fields are used in secure state
        // and need to override in public state to get the correct experience.
        // Better to re-visit criteria page logic later.
        this.hasDentalPlan = this.showDental;
        this.hasVisionPlan = this.showVision;
        this.hasMedicalPlan = true;
        // This condition check for alpha prefix search
        if (this.searchParams.plan === undefined) {
          this.memeberPlanErrorMsg = this.getMemberPlanErrorMsg();
          this.memeberPlanErrorLinkMsg = this.content.searchCriteriaComponent.alerts.memberPlanErrorLink;
        }
        // if (this.searchParams.typeOfCareNm &&
        //   this.searchParams.typeOfCareNm.toLocaleUpperCase() === COVERAGE_TYPE.MEDICAL) {
        //   this.hasMedicalPlan = true;
        // }
      }
      this.getRuleResponse(init);
    }
  }

  setSecureStateControls(providerType: string) {
    this.showPlanUnknownError = false;
    if (this._appSession.metaData.appContract && this._appSession.metaData.appContract.coverageDetails) {
      this.hasVisionPlan = this._appSession.metaData.appContract.coverageDetails.hasVisionPlan;
      this.hasDentalPlan = this._appSession.metaData.appContract.coverageDetails.hasDentalPlan;
    }
    if (this.searchParams.plan && !this._dataHelper.isEmptyString(this.searchParams.plan.alphaPrefix)) {
      this.hasMedicalPlan = true;
    }
    switch (providerType) {
      case PROVIDER_TYPE.DENTAL: {
        this.setDentalControls();
        if (
          this.searchParams.coverageTypeCode === DENTAL_ROUTINE_COVERAGETYPE &&
          this._appSession.metaData.appContract.coverageDetails &&
          this._appSession.metaData.appContract.coverageDetails.hasDentalPlan === true &&
          this._appSession.metaData.appContract.coverageDetails.hasDentalNetwork === false &&
          this._appSession.metaData.appContract.coverageDetails.isMedicare === false
        ) {
          this.planUnknownErrorMsg = this.getDentalUnknownNetworkMsg();
        } else if (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.hasDentalPlan === false) {
          // Medsupp scenario
          if (
            this._appSession.metaData.appContract.coverageDetails &&
            this._appSession.metaData.appContract.coverageDetails.hasMedicalPlan &&
            this.showDental &&
            this.planRule &&
            this.planRule &&
            this.planRule?.isMedicareGov
          ) {
            this.hasDentalPlan = this.hasMedicalPlan = true;
          } else if (this.planRule && (this.planRule.isDentaQuest || this.planRule.isLiberty)) {
            // Medicare scenario
            if (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.hasMedicalPlan && this.showDental) {
              this.hasDentalPlan = this.hasMedicalPlan = true;
            }
          } else if (
            this.searchParams.plan.name === this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown] ||
            // Plan unknown scenario
            this._dataHelper.isEmptyString(this.searchParams.plan.alphaPrefix)
          ) {
            this.showDental = false;
            this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
          } else {
            this.searchParams.coverageTypeCode = MEDICAL_COVERAGETYPE;
          }
        }
        break;
      }
      case PROVIDER_TYPE.VISION: {
        this.setVisionControls();
        if (
          this.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE &&
          this._appSession.metaData.appContract.coverageDetails &&
          this._appSession.metaData.appContract.coverageDetails.hasVisionPlan === true &&
          this._appSession.metaData.appContract.coverageDetails.hasVisionNetwork === false &&
          this._appSession.metaData.appContract.coverageDetails.isMedicare === false
        ) {
          this.planUnknownErrorMsg = this.getVisionUnknownNetworkMsg();
        } else if (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.hasVisionPlan === false) {
          // Medsupp scenario
          if (
            this._appSession.metaData.appContract.coverageDetails &&
            this._appSession.metaData.appContract.coverageDetails.hasMedicalPlan &&
            this.showVision &&
            this.planRule &&
            this.planRule?.isMedicareGov
          ) {
            this.hasVisionPlan = this.hasMedicalPlan = true;
          } else if (this.planRule && (this.planRule.isBVV || this.planRule.isBVVI)) {
            // Medicare scenario
            if (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.hasMedicalPlan && this.showVision) {
              this.hasVisionPlan = this.hasMedicalPlan = true;
            }
          } else if (this.searchParams.plan.name === this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown] || this._dataHelper.isEmptyString(this.searchParams.plan.alphaPrefix)) {
            // Plan unknown scenario
            this.showVision = false;
            this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
          } else {
            this.searchParams.coverageTypeCode = MEDICAL_COVERAGETYPE;
          }
        }
        break;
      }
      case PROVIDER_TYPE.PHARMACY: {
        break;
      }
      default: {
        if (this.searchParams.plan) {
          if (this.searchParams.plan.name === this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown] || this._dataHelper.isEmptyString(this.searchParams.plan.alphaPrefix)) {
            this.searchParams.plan.name = this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown];
            this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
          }
        } else {
          this.memeberPlanErrorMsg = this.getMemberPlanErrorMsg();
          this.memeberPlanErrorLinkMsg = this.content.searchCriteriaComponent.alerts.memberPlanErrorLink;
        }
      }
    }
  }

  getSpecialties(init: boolean) {
    this.showSearchLoader = true;
    this._providerUtilityService.getSpecialityCodesList(this.buildPrefixRuleRequest()).then(
      (result: IProvTypeSpecialtyUtilityResponse) => {
        this.onSpecialtySuccess(result, false, init);
      },
      (error: any) => {
        this.onCategoryError('SPECIALTY_CATEGORIES', error);
      }
    );
  }

  onSpecialtySuccess(data: IProvTypeSpecialtyUtilityResponse, triggerRule: boolean, init: boolean) {
    if (data) {
      let _specialtyCategoryList: any = [];
      if (this.searchParams.typeSelectNm === PROVIDER_TYPE.OTHER_MEDICAL && this._appSession.planRule && this._appSession.planRule.isTransportation === false) {
        _specialtyCategoryList = data.specialtyCategoryList.filter((x) => x.code !== TRANSPORTATION_SPECIALTY);
      } else {
        _specialtyCategoryList = data.specialtyCategoryList;
      }
      this.specialtyCategoryList = _specialtyCategoryList;
      // Adding default value for specialty
      if (!init || !this.searchParams.specialtySelectNm || (this.searchParams.specialtySelectNm && this.searchParams.specialtySelectNm.length === 0)) {
        this.searchParams.specialtySelectNm = [];
      }

      const _allSpecialty = this.WcsUtility.getDefaultText(DEFAULT_TEXT_LIST.SPECIALTY);
      this.searchParams.speicalityDdlValues = CommonUtil.getSpecialty(_specialtyCategoryList, _allSpecialty, true);
      const specialty = this.searchParams.specialtySelectNm.length > 1 ? this.searchParams.specialtySelectNm[1] : this.searchParams.specialtySelectNm[0];
      this.onSpecialtyChange(specialty, triggerRule);
    }
    this.showSearchLoader = false;
  }

  onSpecialtyChange($event: any, triggerRule: boolean) {
    if ($event !== '') {
      this.searchParams.specialtySelectNm = [];
      this.searchParams.specialtySelectNm.push($event);
      this.specialtySelectDisplay = CommonUtil.getDropDownSelectedText(this.searchParams.speicalityDdlValues, $event);
      const _allSubSpecialty = this.WcsUtility.getDefaultText(DEFAULT_TEXT_LIST.SUBSPECIALTY);
      this.searchParams.subSpeicalityDdlValues = CommonUtil.getSubSpecialty(this.specialtyCategoryList, this.searchParams.specialtySelectNm);
      this.setSpecialityDescription();
      if (triggerRule) {
        this.getRuleResponse();
      }
      this.showSpeciality = false;
      this.setTransposrtationControls();
    }
  }

  // Function to set description for speciality.
  setSpecialityDescription() {
    if (this.specialtyCategoryList && this.searchParams.specialtySelectNm && this.searchParams.specialtySelectNm.length > 0) {
      const specialty = this.specialtyCategoryList.filter((x) => x.code === this.searchParams.specialtySelectNm[0]);
      if (specialty && specialty.length > 0) {
        this.speciialityDescription = specialty[0].description ? specialty[0].description.replace(/\r\n/gi, ' ') : '';
      }
    }
  }

  toggleSpeciality() {
    this.showSpeciality = !this.showSpeciality;
  }

  setTransposrtationControls() {
    if (this.isOtherMedicalServiceTransportation()) {
      const _planState = this.searchParams.plan && this.searchParams.plan.stateCd ? this.searchParams.plan.stateCd.toUpperCase().trim() : '';
    }
  }

  setHearingControls() {
    this.showHearing = this.searchParams.typeSelectNm === PROVIDER_TYPE.HEARING;
    if (this.showHearing && this.searchParams.coverageTypeCode === '') {
      this.searchParams.hearUSAEGR = 'Y';
      this.searchParams.coverageTypeCode = HEARING_ROUTINE_COVERAGETYPE;
    }
  }

  showSpecialityDropdown() {
    // Additional logic for hearing provider type to hide speciality.
    // This is added to avoid additional rule API call.
    if (this.searchParams && this.searchParams.typeSelectNm === PROVIDER_TYPE.HEARING) {
      return this.searchParams.coverageTypeCode === HEARING_ROUTINE_COVERAGETYPE && this.searchParams.hearUSAEGR === 'N' && this.feature && this.feature.showSpeciality;
    }
    return this.feature && this.feature.showSpeciality;
  }

  showQuestionnaire() {
    // Additional logic for hearing provider type to hide questionnaire.
    // This is added to avoid additional rule API call.
    if (this.searchParams.typeSelectNm === PROVIDER_TYPE.HEARING) {
      return this.searchParams.coverageTypeCode === HEARING_ROUTINE_COVERAGETYPE && this.feature && this.feature.showQuestionnaire;
    }
    return this.feature && this.feature.showQuestionnaire;
  }

  isSearchRedirect(): boolean {
    let _showMessage = false;
    const _provType = this.searchParams.typeSelectNm;
    switch (_provType) {
      case PROVIDER_TYPE.DENTAL: {
        if (this.searchParams.coverageTypeCode === DENTAL_ROUTINE_COVERAGETYPE && this.planRule) {
          _showMessage = this.planRule.isLiberty || this.planRule.isDentaQuest;
        }
        break;
      }
      case PROVIDER_TYPE.VISION: {
        if (this.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE && this.planRule) {
          _showMessage = this.planRule.isVSP || this.planRule.isEyeQuest || this.planRule.isSuperior;
        }
        break;
      }
      case PROVIDER_TYPE.HEARING: {
        _showMessage = this.searchParams.coverageTypeCode === HEARING_ROUTINE_COVERAGETYPE && this.searchParams.hearUSAEGR === 'Y';
        if (this.searchParams.coverageTypeCode === MEDICAL_COVERAGETYPE) {
          this.searchParams.specialtySelectNm = [AUDIOLOGIST_SPECIALTY];
          const _allSubSpecialty = this.WcsUtility.getDefaultText(DEFAULT_TEXT_LIST.SUBSPECIALTY);
          this.searchParams.subSpeicalityDdlValues = CommonUtil.getSubSpecialty(this.specialtyCategoryList, this.searchParams.specialtySelectNm);
        }
        break;
      }
      case PROVIDER_TYPE.PHARMACY: {
        /* if (
          this.isPharmacyPlanNoIndicator &&
          this.searchParams.coverageTypeCode === RETAIL_PHARMACY
        ) {
          _showMessage = false;
        } else {
          _showMessage = this.searchParams.coverageTypeCode === RETAIL_PHARMACY;
        } */
        _showMessage = false;
        break;
      }
    }
    return _showMessage;
  }

  isOtherMedicalServiceTransportation(): boolean {
    return this.searchParams.typeSelectNm === PROVIDER_TYPE.OTHER_MEDICAL && this.searchParams.specialtySelectNm && this.searchParams.specialtySelectNm.indexOf(TRANSPORTATION_SPECIALTY) > -1;
  }

  setVisionControls() {
    this.showVision = !this.isVisionStandAlone && this.searchParams.typeSelectNm === PROVIDER_TYPE.VISION;
    if (this.showVision && this._dataHelper.isEmptyString(this.searchParams.coverageTypeCode)) {
      this.searchParams.visionBVV = 'Y';
      this.searchParams.coverageTypeCode = VISION_ROUTINE_COVERAGETYPE;
    }
    this.searchParams.coverageTypeCode = this.isVisionStandAlone ? VISION_ROUTINE_COVERAGETYPE : this.searchParams.coverageTypeCode;
  }

  setDentalControls() {
    this.showDental = !this.isDentalStandAlone && this.searchParams.typeSelectNm === PROVIDER_TYPE.DENTAL;
    if (this.showDental && this._dataHelper.isEmptyString(this.searchParams.coverageTypeCode)) {
      this.searchParams.coverageTypeCode = DENTAL_ROUTINE_COVERAGETYPE;
    }
    this.searchParams.coverageTypeCode = this.isDentalStandAlone ? DENTAL_ROUTINE_COVERAGETYPE : this.searchParams.coverageTypeCode;
    this.customVisibility(this.searchParams.coverageTypeCode);
  }
  setPharmacyControls() {
    /* this.showPharmacy =
      this.searchParams.typeSelectNm === PROVIDER_TYPE.PHARMACY; */
    if (this.searchParams.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      const appSessionPharmacyEmpty = this._appSession.searchParams.coverageTypeCode === '' || this._appSession.searchParams.coverageTypeCode === undefined;
      const appSessionPharmacyValue = this._appSession.searchParams.coverageTypeCode;
      if (this.isCvsPlanIndicator) {
        if (appSessionPharmacyEmpty === true) {
          this.searchParams.coverageTypeCode = RETAIL_PHARMACY;
        } else {
          this.searchParams.coverageTypeCode = appSessionPharmacyValue === RETAIL_PHARMACY ? RETAIL_PHARMACY : MEDICAL_PHARMACY;
        }
      } else if (this.isEsiPlanIndicator) {
        if (appSessionPharmacyEmpty === true) {
          this.searchParams.coverageTypeCode = RETAIL_PHARMACY;
        } else {
          this.searchParams.coverageTypeCode = appSessionPharmacyValue === RETAIL_PHARMACY ? RETAIL_PHARMACY : MEDICAL_PHARMACY;
        }
      } else if (this.isPharmacyPlanNoIndicator) {
        if (appSessionPharmacyEmpty === true) {
          this.searchParams.coverageTypeCode = MEDICAL_PHARMACY;
        } else {
          this.searchParams.coverageTypeCode = appSessionPharmacyValue === RETAIL_PHARMACY ? RETAIL_PHARMACY : MEDICAL_PHARMACY;
        }
      }
      this.pharamacyindicatorCheckor();
    } else {
      if (this._appSession && this._appSession.searchParams) {
        this._appSession.searchParams.coverageTypeCode = '';
      }
      this.showSearchButton = true;
      this.isSearchRedirect();
    }
  }
  pharmacyVisitInit() {
    /*     for (const option of this.pharmacyVisit.options) {
          if (option.value === RETAIL_PHARMACY) {
            option.label = this.content.searchCriteriaComponent.labels.retailPharmacy;
            option.tooltip = this.content.searchCriteriaComponent.tooltips.retailPharmacyHelp;
          } else if (option.value === MEDICAL_PHARMACY) {
            option.label = this.content.searchCriteriaComponent.labels.medicalPharmacy;
            option.tooltip = this.content.searchCriteriaComponent.tooltips.medicalPharmacyHelp;
          }
        } */
    //page Onload
    this.searchParams.coverageTypeCode = this.isCvsPlanIndicator || this.isEsiPlanIndicator ? RETAIL_PHARMACY : MEDICAL_PHARMACY;
  }

  pharmacyVisitRadioChange($event: any) {
    this.pharamacyindicatorCheckor();
  }
  pharamacyindicatorCheckor() {
    if (this.searchParams.coverageTypeCode === RETAIL_PHARMACY) {
      // this.isRxMedical = false;
      // this.showSearchButton = (this.isPharmacyPlanNoIndicator ? true : false);
      // this.locatePharmacyBtn = (this.isPharmacyPlanNoIndicator ? false : true);
      // this.showPharmacyDisclaimer = (this.isEsiPlanIndicator ? true : false);
      this.locatePharmacyBtn = false;
      this.showPharmacyDisclaimer = false;
      this.showSearchButton = true;
      this.isRxMedical = true;
      this.isSearchRedirect();
    } else {
      this.locatePharmacyBtn = false;
      this.showPharmacyDisclaimer = false;
      this.showSearchButton = true;
      this.isRxMedical = true;
    }
  }
  locatePharmacy() {
    const appBrand = this._appSession.metaData.brandCd;
    if (this.isEsiOrCVSPlanIndicator) {
      this.validateBrand(appBrand);
      this.openExternalUrl(this.locatePharmacyUrl);
    } else if (this.isPharmacyPlanNoIndicator) {
      if (this.searchParams.coverageTypeCode === RETAIL_PHARMACY && this.locatePharmacyBtn !== true) {
        this.validateBrand(appBrand);
        this.openExternalUrl(this.locatePharmacyUrl);
      }
    }
  }
  validateBrand(brandUrl) {
    switch (brandUrl.toUpperCase()) {
      case 'ABC':
        this.locatePharmacyUrl = this.isCvsPlanIndicator
          ? this.appConfig?.cvsPharmacy['ABC']
          : this.isEsiPlanIndicator
            ? this.appConfig?.esiPharmacy['ABC']
            : this.isPharmacyPlanNoIndicator
              ? this.appConfig?.cvsPharmacy['ABC']
              : '';
        return this.locatePharmacyUrl;
      case 'ABCBS':
        this.locatePharmacyUrl = this.isCvsPlanIndicator
          ? this.appConfig?.cvsPharmacy['ABCBS']
          : this.isEsiPlanIndicator
            ? this.appConfig?.esiPharmacy['ABCBS']
            : this.isPharmacyPlanNoIndicator
              ? this.appConfig?.cvsPharmacy['ABCBS']
              : '';
        return this.locatePharmacyUrl;
      case 'EBC':
      case 'EBCBS':
        this.locatePharmacyUrl = this.isCvsPlanIndicator
          ? this.appConfig?.cvsPharmacy['EBC']
          : this.isEsiPlanIndicator
            ? this.appConfig?.esiPharmacy['EBC']
            : this.isPharmacyPlanNoIndicator
              ? this.appConfig?.cvsPharmacy['EBC']
              : '';
        return this.locatePharmacyUrl;
    }
  }
  openExternalUrl(url: any) {
    let windowParams: string;
    this.url = url;
    windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
    this.url = this.url.indexOf('://') <= 0 ? 'http://' + this.url : this.url;
    const winArgs = [this.url, '_blank'];
    if (windowParams) {
      winArgs.push(windowParams);
    }
    this._window.open.apply(this._window, winArgs);
  }
  onDentalRadioChange($event: any) {
    this.onSpecialtyChange('00', false);
    this.customVisibility($event);
    if (this._appSession.isSecureState === true) {
      if ($event === MEDICAL_COVERAGETYPE) {
        if (this.searchParams.plan && !this._dataHelper.isEmptyString(this.searchParams.plan.alphaPrefix)) {
          this.showPlanUnknownError = false;
        } else {
          this.getDentalUnknownNetworkMsg();
        }
      } else if ($event === PROVIDER_TYPE.DENTAL) {
        if (
          (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.hasDentalNetwork === true) ||
          this._appSession.metaData.appContract.coverageDetails.isMedicare === true
        ) {
          this.showPlanUnknownError = false;
        } else {
          this.getDentalUnknownNetworkMsg();
        }
      }
    }
  }

  onVisionRadioChange($event: any) {
    this.getRuleResponse();
    if (this._appSession.isSecureState === true) {
      if ($event === MEDICAL_COVERAGETYPE) {
        if (this.searchParams.plan && !this._dataHelper.isEmptyString(this.searchParams.plan.alphaPrefix)) {
          this.showPlanUnknownError = false;
        } else {
          this.getMedicalUnknownNetworkMsg();
        }
      } else if ($event === PROVIDER_TYPE.VISION) {
        if (
          (this._appSession.metaData.appContract.coverageDetails && this._appSession.metaData.appContract.coverageDetails.hasVisionNetwork === true) ||
          this._appSession.metaData.appContract.coverageDetails.isMedicare === true
        ) {
          this.showPlanUnknownError = false;
        } else {
          this.getVisionUnknownNetworkMsg();
        }
      }
    }
  }

  customVisibility(type: string) {
    if (this.feature && this.searchParams.typeSelectNm === PROVIDER_TYPE.DENTAL) {
      // Temp solution for handling visiblity based on radio button selction for dental provider type.
      // Speciality and subspeciality need to be visible only for Routine dental visit.
      // To do need to move this as part of rule framework
      this.feature.showSpeciality = this.feature.showSubSpeciality = type === DENTAL_ROUTINE_COVERAGETYPE && !this.isSearchRedirect();
      this._appSession.feature = this.feature;
    }
  }

  setDistance($event) {
    this.setProviderName();
    this.searchParams.distanceNm = $event.value;
  }

  toggleFocusNameFn() {
    const _providerNamesArr = this.providerNamesArr;
    if (this.focusName === true && _providerNamesArr.length === 0) {
      this.focusName = false;
    } else {
      this.focusName = true;
    }
    if (_providerNamesArr.length > 0) {
      const name = this.matchItem(_providerNamesArr, this.searchParams.provNm);
      if (name) {
        this.setProviderName(name);
      }
    }
  }

  toggleFocusFn() {
    const geoKeys = Object.keys(this.geoSuggestionArr);
    if (this.focusZip === true && geoKeys.length === 0) {
      this.focusZip = false;
    } else {
      this.focusZip = true;
    }
    if (geoKeys.length > 0) {
      const name = this.matchItem(geoKeys, this.searchParams.zip);
      if (name) {
        this.setCoordinates(this.geoSuggestionArr[name]);
      }
    }
  }

  getGeoCoordinates(isInit: boolean = false) {
    if (!isInit) {
      this.setProviderName();
    }
    this.geoSuggestionArr = [];
    this.searchParams.coordinates.latitude = '';
    this.searchParams.coordinates.longitude = '';
    this.locationError = false;

    if (this.searchParams.zip.match(/^[0-9]+$/) != null) {
      if (this.searchParams.zip.length >= 5) {
        this._providerUtilityService.getGeoLocation(this.searchParams.zip).then((result: LocationResponse[]) => {
          if (result && Object.keys(result).length > 0) {
            const results = this._appUtility.buildLocationResponse(result);
            this.searchParams.coordinates.latitude = results[Object.keys(results)[0]].lat;
            this.searchParams.coordinates.longitude = results[Object.keys(results)[0]].long;
            this.searchParams.stateCd = {
              code: results[Object.keys(results)[0]].stateCd
            };
            if (this._appSession.metaData && this._appSession.metaData.appContract) {
              const _county = this._appSession.metaData.appContract.memberCounty;
              if (!_county || _county === '') {
                this._appSession.metaData.appContract.memberCounty = results[Object.keys(results)[0]].county;
              }
            }

            this.locationError = false;
          } else {
            this.setLocationError();
          }

          this.searchParams.city = '';
          this.searchParams.countyCode = null;
        });
      }
    } else {
      if (this.searchParams.zip.length > 3) {
        this._providerUtilityService.getGeoLocation(this.searchParams.zip).then((result: LocationResponse[]) => {
          if (this.searchParams.zip.length > 3) {
            const results = this._appUtility.buildLocationResponse(result);
            if (isInit && results && Object.keys(results).length > 0) {
              const coordinates: any = results[this.searchParams.zip];
              if (coordinates) {
                this.setCoordinates(coordinates);
              } else {
                this.setLocationError();
              }
            } else {
              if (isInit && results && Object.keys(results).length === 0) {
                this.setLocationError();
              } else {
                this.geoSuggestionArr = results;
              }
            }
          } else {
            this.geoSuggestionArr = [];
          }
        });
      }
    }
  }

  setLocationError() {
    this.locationError = true;
    this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
  }

  setCoordinates(coordinates: any) {
    this.searchParams.zip = coordinates.name;
    this.searchParams.coordinates.latitude = coordinates.lat;
    this.searchParams.coordinates.longitude = coordinates.long;
    this.searchParams.coordinates.countyLatitude = '0';
    this.searchParams.coordinates.countyLongitude = '0';
    this.searchParams.city = coordinates.city;
    this.searchParams.stateCd = {
      code: coordinates.stateCd
    };
    this.geoSuggestionArr = [];
    this.focusZip = false;
    if (coordinates.isCounty === true) {
      const county = this.searchParams.city.replace(' County', '');
      this.getCountyCode(coordinates.stateCd, county);
      this.searchParams.coordinates.latitude = '0';
      this.searchParams.coordinates.longitude = '0';
      // Regional, Remote providers - Zoom map for county search.
      this.searchParams.coordinates.countyLatitude = coordinates.lat;
      this.searchParams.coordinates.countyLongitude = coordinates.long;
    } else {
      this.searchParams.countyCode = null;
    }
  }
  getCountyCode(statecode: any, county: any) {
    this._providerUtilityService.getCountyBystate(statecode).then(
      (result: any) => {
        const countyResult = result.countyCode.filter((x) => x.name === county);
        if (countyResult.length > 0) {
          this.searchParams.countyCode = countyResult[0].code;
        }
      },
      (error: any) => {
        //to do
        throw error;
      }
    );
  }

  onProviderNameChange() {
    this.providerNamesArr = [];
    const _locNotValid = this._dataHelper.isEmptyString(this.searchParams.zip) || this.searchParams.coordinates.latitude === '' || this.searchParams.coordinates.longitude === '';

    if (!_locNotValid && this.searchParams.provNm.length >= 3) {
      this._appSession.searchParams = this.searchParams;
      const searchRequest = ResultsUtil.buildProviderRequest(this._appSession, '1', '', this.searchParams.typeSelectNm);
      this._providerSummarySvc.getProviderOptions(searchRequest).then((result: any) => {
        if (result && result.providerList && result.providerList.length > 0) {
          this.providerNamesArr = result.providerList;
        }
      });
    }
  }

  setProviderName(provName: string = '') {
    this.searchParams.provNm = provName;
    this.providerNamesArr = [];
  }

  toggleAdditionalOption() {
    if (this.showAdditionalOption === true) {
      this.showAdditionalOption = false;
    } else {
      this.showAdditionalOption = true;
    }
  }

  onChangePlanClick() {
    if (!CommonUtil.isMemberSecure(this._appSession) || (this._appSession.isPlanUnknown && CommonUtil.hasMultipleUnknownPrefix(this._appSession))) {
      this.goToPlanSelection();
    } else {
      this._changePlanService.setPlanSelectionLinkClick(true);
    }
  }

  goToPlanSelection() {
    this.resetSearchParams();
    this._appSession.searchParams = this.searchParams;
    this.gotoPlanSelection.emit();
  }

  resetSearchParams() {
    this.searchParams.stateCd = '';
    this.searchParams.provNm = '';
    this.searchParams.zip = '';
    this.searchParams.typeSelectNm = '';
    this.searchParams.specialtySelectNm = [];
    this.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this.searchParams.city = '';
    this._appSession.isDentalStandAlone = false;
    this._appSession.isPharmacyStandAlone = false;
    this._appSession.isVisionStandAlone = false;
    this._appSession.filterChangePlan = {} as IFilterChangePlan;
    this._appSession.filterChangePlan.isPreviouState = false;
  }

  onSearch(searchParams: ISearchParameters) {
    this.searchParams = searchParams;
    if (this._appSession.isEyc) {
      this.searchEYCProviders();
    } else {
      this.searchProviders();
    }
  }

  searchEYCProviders() {
    //searchEYCProviders
    alert('EYC Providers...');
  }

  searchProviders() {
    this.clearAlerts();

    // Medicare supplement plans should redirect to Medicare.gov except for BVV, HCS and retail pharmacy
    if (this.showMedicareSupplementModal()) {
      return;
    }

    if (this.searchParams.coverageTypeCode === HEARING_ROUTINE_COVERAGETYPE && this.planRule.isHCS && this.searchParams.hearUSAEGR === 'Y') {
      this.leavingDisclaimer.emit(HCS_URL);
    } else if (this.searchParams.coverageTypeCode === DENTAL_ROUTINE_COVERAGETYPE && this.planRule.isDentaQuest) {
      this.leavingDisclaimer.emit(DENTAL_QUEST_URL);
    } else if (this.searchParams.coverageTypeCode === DENTAL_ROUTINE_COVERAGETYPE && this.planRule.isLiberty) {
      this.leavingDisclaimer.emit(LIBERTY_DENTAL_URL);
    } else if (this.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE && this.planRule.isVSP) {
      this.leavingDisclaimer.emit(DENTAL_VSP_URL);
    } else if (this.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE && this.planRule.isEyeQuest) {
      this.leavingDisclaimer.emit(this.content.searchCriteriaComponent.labels.eyeQuestVisionUrl);
    } else if (this.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE && this.planRule.isSuperior) {
      this.leavingDisclaimer.emit(this.content.searchCriteriaComponent.labels.superiorVisionUrl);
    } else if (this.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE && this.planRule.isEyeMed) {
      this.leavingDisclaimer.emit(this.content.searchCriteriaComponent.labels.eyeMedVisionUrl);
    } else if (this.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE && this.planRule.isOhEyeMed) {
      this.leavingDisclaimer.emit(this.content.searchCriteriaComponent.labels.oheyeMedVisionUrl);
    } else if (this.isOtherMedicalServiceTransportation()) {
      this.transportationModal.show();
    } else {
      if (this.showPlanUnknownError === true) {
        return;
      } else if (this._dataHelper.isEmptyString(this.searchParams.zip) || this.searchParams.coordinates.latitude === '' || this.searchParams.coordinates.longitude === '') {
        this.locationError = true;
        this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
      } else {
        if (this.feature && this.feature.isMyNexus) {
          if (this._appSession.isSecureState) {
            this.myNexusHeader = this.content.homeHealthCareContent.secureHeader;
            const myNexusSecureContent: Array<IAlertMessage> = this.content?.homeHealthCareSecureContentInfo?.MY_NEXUS_SECURE_CONTENT;
            this.myNexusContent = WcsUtil.getInfoListContent(myNexusSecureContent);
            this.myNexusClose = this.content.homeHealthCareContent.Ok;
          } else {
            this.myNexusHeader = this.content.homeHealthCareContent.publicHeader;
            this.myNexusContent = this.content.homeHealthCareContent.publicContent;
            this.myNexusClose = this.content.homeHealthCareContent.Ok;
          }
          this.homeHealthCareModal.show();
        } else {
          this._appSession.searchParams = this.searchParams;
          this._appSession.initialSearch = true;
          this.setDoctorDetailsAnalyticsQuery();
          this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
        }
      }
    }
  }

  clearAlerts() {
    this.apiAlert.hasError = false;
    this.apiAlert.alertType = '';
    this.apiAlert.alertContent = '';

    this.locationError = false;
    this.errorText = '';
  }

  onCategoryError(type: string, error: any) {
    this.showSearchLoader = false;
    throw error;
  }

  getRuleResponse(isInit: boolean = false) {
    this.showSearchLoader = true;
    if (!isInit) {
      this.setProviderName();
    }
    this.provDisclaimer = [];
    this._appSession.integratedSearch = false;
    this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
      (result: IRulesResponse) => {
        const _result = result;
        if (_result && _result.disclaimer && _result.disclaimer.length > 0) {
          for (const provDisc of PROVIDER_MESSAGES || []) {
            const _index = _result.disclaimer.indexOf(provDisc);
            if (_index > -1) {
              const _item = _result.disclaimer[_index];
              this.provDisclaimer.push(_item);
              _result.disclaimer = _result.disclaimer.filter((x) => x !== _item);
            }
          }
        }
        this.feature = this._appSession.feature = _result;
        this.customVisibility(this.searchParams.coverageTypeCode);
        this.showSearchLoader = false;

        if (this.feature && this.feature.showMedSuppDisclaimerOnLoad === true) {
          this.showMedicareSupplementModal();
        }
      },
      (error: any) => {
        this.onCategoryError('RULE', error);
      }
    );
  }

  closeModal() {
    this.transportationModal.hide();
  }

  closeHomeHealthCareModal() {
    this.homeHealthCareModal.hide();
  }

  redirectHomeHealthCareModal() {
    this.closeHomeHealthCareModal();
    const _url = this.content.homeHealthCareContent.url;
    const winArgs = [_url];
    this._window.open.apply(this._window, winArgs);
  }

  closeMedicareSupplementModal() {
    this.medicareSupplementModal.hide();

    if (this.feature && this.feature.showPlanSelectionPage === true) {
      this.gotoPlanSelection.emit();
    }
  }

  redirectMedicareSupplementModal() {
    this.medicareSupplementModal.hide();
    const _url = this.content.medicareSupplementContent.url;
    const windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
    const winArgs = [_url, '_blank'];
    if (windowParams) {
      winArgs.push(windowParams);
    }
    this._window.open.apply(this._window, winArgs);
  }

  printTransportation() {
    window.print();
  }

  bindGeoLocation() {
    if (!this._appSession.isSecureState && (!this.searchParams.zip || this.searchParams.zip === '') && this._appSession.defaultLocation) {
      this.searchParams.coordinates.latitude = this._appSession.defaultLocation.latitude;
      this.searchParams.coordinates.longitude = this._appSession.defaultLocation.longitude;
      this.searchParams.zip = this._appSession.defaultLocation.zip;
      this.searchParams.stateCd = this._appSession.defaultLocation.state;
    }
  }

  getMedicalUnknownNetworkMsg() {
    this.showPlanUnknownError = true;
    const planUnknownMsg = this.customizedFeatures.showChangePlan
      ? this.content.searchCriteriaComponent.alerts.networkUnknownMsg
      : this.WcsUtility.getUnknownNetworkMsg(PROVIDER_TYPE.DOCTOR_PROFESSIONAL);
    return planUnknownMsg;
  }

  getMemberPlanErrorMsg() {
    this.showMemberPlanError = true;
    return this.content.searchCriteriaComponent.alerts.memberPlanError;
  }

  getDentalUnknownNetworkMsg() {
    this.showPlanUnknownError = true;
    const planUnknownMsg = this.customizedFeatures.showChangePlan ? this.content.searchCriteriaComponent.alerts.networkUnknownMsg : this.WcsUtility.getUnknownNetworkMsg(PROVIDER_TYPE.DENTAL);

    return planUnknownMsg;
  }

  getVisionUnknownNetworkMsg() {
    this.showPlanUnknownError = true;

    const planUnknownMsg = this.customizedFeatures.showChangePlan ? this.content.searchCriteriaComponent.alerts.networkUnknownMsg : this.WcsUtility.getUnknownNetworkMsg(PROVIDER_TYPE.VISION);

    return planUnknownMsg;
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  embedBaseUrl(content: string) {
    return this.getDocumentURL(content);
  }
  //Sets the Analytics query for provider search criteria - Only on RCP integrated page
  setDoctorDetailsAnalyticsQuery() {
    if (window.digitalData && window.digitalData.page && window.digitalData.page.search) {
      const _adobePlan = {} as IAdobe;
      const _searchDetails = {} as IAdobeSearchDetail;
      _searchDetails.distance = this.searchParams.distanceNm;
      _searchDetails.provName = this.searchParams.provNm;
      _searchDetails.provType = this.searchParams.typeSelectNm;
      _searchDetails.speciality = this.searchParams.specialtySelectNm;
      _searchDetails.zip = this.searchParams.zip;
      _adobePlan.searchDetail = _searchDetails;
      window.digitalData.page.search.doctorDetailsSearchQuery = CommonUtil.getDoctorDetailsAnalyticsQuery(_adobePlan);
    }
  }

  private matchItem(keys: string[], name: string): string {
    //add single space after comma in city name.
    name = name.replace(/,\s*/g, ', ').toLocaleLowerCase();
    //find the matching city name in the geokeys
    const index = keys.findIndex((key) => key.toLocaleLowerCase() === name);
    if (index >= 0) {
      return keys[index];
    }
    return null;
  }

  get leavingDisclaimerContent(): string {
    if (this.content && this.content.leavingDisclaimerComponent && this.content.leavingDisclaimerComponent.content1) {
      return this.content.leavingDisclaimerComponent.content1.replace(/{BRAND}/gi, this._appSession.metaData.brandName);
    }
    return '';
  }

  get leavingDisclaimerTermsOfUse(): string {
    if (this.content && this.content.leavingDisclaimerComponent && this.content.leavingDisclaimerComponent.content2) {
      return this.content.leavingDisclaimerComponent.content2.replace(/{BRAND}/gi, this._appSession.metaData.brandName);
    }
    return '';
  }

  get isIntegratedMode() {
    return this._appSession.isIntegratedMode;
  }

  get showBackButton() {
    const isPlanUnknown =
      !CommonUtil.isMemberSecure(this._appSession) || this.showMemberPlanError || (this.showPlanUnknownError && !this.showDental && !this.showVision && this.planRule && !this.planRule?.isMedicareGov);

    if (CommonUtil.isMemberSecure(this._appSession)) {
      this._appSession.isPlanUnknown = isPlanUnknown;
    }

    return isPlanUnknown;
  }
}
