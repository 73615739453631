import { Component, Inject, Input, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { IMessage } from "../../../../../app/common/interfaces/iMessage";
import { ISelectedProvider } from "../../../../../app/fad/provider-details/models/iSelectedProvider";
import { ALERT_TYPE, REPORT_INVAID_EMAIL_SUCCESS } from "../../../../../app/fad/search-providers/values/providerSearchConstants";
import { EventHandler } from "../../../services/eventHandler";
import { AppSession } from "../../../values/appSession";
import { ContentHelper } from "../../../values/contentHelper";
import { BaseComponent } from "../../base-component/baseCmp";
import { InfoListService } from "../../info-list/services/infoListSvc";
import { IReportValues } from "../interfaces/iReportValues";
import { ReportInvalidService } from "../services/reportInvalidSvc";
import { PFReportInvalidSidePanelComponent } from "./pfReportInvalidSidePanelCmp";

@Component({
  moduleId: module.id,
  selector: 'app-report-invalid',
  templateUrl: '../views/pfReportInvalidCmp.html'
})
export class PFReportInvalidComponent extends BaseComponent implements OnDestroy {

  @Input()
  reportValues: IReportValues;
  @Input()
  selectedProvider: ISelectedProvider;
  @Input()
  selectedAddressId: string;
  @ViewChild('reportInvalidSidePanelCmp')
  reportInvalidSidePanelCmp: PFReportInvalidSidePanelComponent;
  private reportInvalidSvcSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _contentHelper: ContentHelper,
    private _reportInvalidService: ReportInvalidService,
    private _infoListSvc: InfoListService
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      'PFProviderDetailsContainerComponent'
    );

    this.reportInvalidSvcSubscription = this._reportInvalidService.onEmailSent.subscribe((message: string) => {
      const emailSuccess: IMessage = {
        contentId: REPORT_INVAID_EMAIL_SUCCESS,
        content: message,
        type: ALERT_TYPE.INFORMATION,
        focus: true
      } as IMessage;
      this._infoListSvc.addMessage(emailSuccess);
    });
  }

  ngOnDestroy(): void {
    this.reportInvalidSvcSubscription?.unsubscribe();
  }

  /**
   * This method will open the side panel
   * @param event
   * @returns
   */
   openSidePanel(): void {
    this.reportInvalidSidePanelCmp?.openSidePanel();
  }
}
