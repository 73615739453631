import { ComponentRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class PillItemLookupService {
  private components: ComponentRef<any>[] = [];
  private subject = new Subject<boolean>();

  registerComponent(component: ComponentRef<any>) {
    if (typeof this.components !== 'undefined' &&
      this.components !== null) {
      this.components.push(component);
    }
  }

  destoryComponent() {
    if (typeof this.components !== 'undefined' &&
      this.components !== null &&
      this.components.length > 0) {
      this.components.forEach((c) => c.destroy());
    }
  }

  onLookupDestroy(): Observable<any> {
    return this.subject.asObservable();
  }
}
