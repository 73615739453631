import { COMMON_CONTENT_EN } from './common/commonContent';
import { COMMON_CONTENT_ES } from './common/commonContent.es';
import { HOME_CONTENT_EN } from './home/homeContent';
import { HOME_CONTENT_ES } from './home/homeContent.es';
import { LANDING_CONTENT_EN } from './landing/landingContent';
import { LANDING_CONTENT_ES } from './landing/landingContent.es';
import { RESULT_CONTENT_EN } from './result/resultContent';
import { RESULT_CONTENT_ES } from './result/resultContent.es';

// Define the English labels
const EN = {
  common: COMMON_CONTENT_EN,
  home: HOME_CONTENT_EN,
  landing: LANDING_CONTENT_EN,
  result: RESULT_CONTENT_EN
};

// Define a type based on the structure of the English labels.
export type FcLabel = typeof EN;

// Define the Spanish labels, ensuring they match the structure of the English labels
const ES: FcLabel = {
  common: COMMON_CONTENT_ES,
  home: HOME_CONTENT_ES,
  landing: LANDING_CONTENT_ES,
  result: RESULT_CONTENT_ES
};

// Export an object that maps locale codes to their respective label sets
export const labels = {
  en_US: EN,
  es_US: ES
};
