import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IRadioInput } from '@anthem/uxd/util';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { Locale } from '../../../common/constants/app-constants';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IFeedbackRequest } from '../../search-providers/interfaces/iFeedback';
import { FeedbackService } from '../../search-providers/services/feedbackSvc';
import { FEEDBACK_INTENDED_TASK_OPTIONS } from '../values/providerSearchConstants';

@Component({
  selector: 'app-pf-feedback-cmp',
  templateUrl: '../views/pfFeedbackCmp.html',
  providers: []
})
export class PFFeedbackComponent extends BaseComponent implements OnInit {
  maxFeedbackLength: number = 2000;
  feedbackImprovement = '';
  feedbackExperience = '';
  sidePanelRef: ModalRef<any, any>;
  feedbackMessage: IFeedbackRequest;
  @ViewChild('feedbackInput')
  feedbackInput: ElementRef;
  @ViewChild('openFeedbackSlidePanel')
  feedbackSlidePanel: TemplateRef<HTMLElement>;
  selectedIntendedTaskOption: string = 'Y';
  intendedTaskOptions: Array<IRadioInput> = FEEDBACK_INTENDED_TASK_OPTIONS.options;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _feedbackSvc: FeedbackService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    if (this._appSession.metaData.locale === Locale.SPANISH) {
      this.intendedTaskOptions = this.intendedTaskOptions.map((option) => {
        return {
          ...option,
          label: option.label.replace('Yes', this.content.feedback.yes).replace('No', this.content.feedback.no)
        };
      });
    }
  }

  onSendFeedback() {
    const feedbackMessage = {
      cT: this.selectedIntendedTaskOption.toUpperCase() === 'Y',
      wDL: encodeURIComponent(this.feedbackExperience),
      wTI: encodeURIComponent(this.feedbackImprovement)
    };
    this._feedbackSvc.submitV1Feedback(feedbackMessage);
    this.closeFeedback();
  }

  closeFeedback() {
    this.sidePanelRef.close();
  }

  onFeedbackSlideOut() {
    this.feedbackExperience = '';
    this.feedbackImprovement = '';
    this.sidePanelRef = this._sidePanel.open('right', this.feedbackSlidePanel);
  }

  getRemainingCharacterCountMsg(feedback: string): string {
    const count = this.maxFeedbackLength - feedback.length;
    return this.content?.feedback?.remainingCharacters?.replace(/{charLength}/gi, () => count.toString());
  }
}
