import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Observable } from 'rxjs';
import { map } from "rxjs/internal/operators/map";
import { CptSummaryModel } from '../../../fad/search-results/models/cptSummaryModel';
import { HYPHEN } from '../../../fad/search-results/values/providerSearchConstants';
import { IFinalSearchResponse } from '../../interfaces/iCommonSearchResponse';
import { ICptMedicalResponse, SidePanelDirections } from '../../interfaces/iCptMedicalResponse';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { CptSearchSlideOutTabs } from "./searchSlideOutConstants";

@Component({
  moduleId: module.id,
  selector: 'app-search-slide-out-cmp',
  templateUrl: './searchSlideOutCmp.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CptSearchSlideOutComponent extends BaseComponent {
  cptSearchResult: Observable<ICptMedicalResponse | IFinalSearchResponse> = this._cptSummaryModel.cptSummaryResponse.pipe(
    map((value: ICptMedicalResponse | IFinalSearchResponse) => {
        this.medicalCodeHeader = value?.medicalCode?.billingType + HYPHEN + value?.medicalCode?.code;
      return value;
    })
  );
  tabIndex: number = 0;
  @ViewChild('viewCptSlideContentModal')
  viewCptSlideContentModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement>;
  medicalCodeHeader: string;
  constructor(
    private _sidePanel: SidePanel,
    private _cptSummaryModel: CptSummaryModel,
    route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession, contentHelper: ContentHelper) {
    super(route, eventHandler, appSession, contentHelper, 'PFSearchResultsContainerComponent');
  }

  openSidePanel(direction: SidePanelDirections, tabIndex?: CptSearchSlideOutTabs) {
    this.tabIndex =  tabIndex ?? 0;
    this.sidePanelRef = this._sidePanel.open(direction, this.viewCptSlideContentModal);
  }

  onClose() {
    this.sidePanelRef?.close();
  }

}
