import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../common/components/base-component/baseCmp';
import { AppEvents } from '../../common/enums/appEvents';
import { EventHandler } from '../../common/services/eventHandler';
import { AppSession } from '../../common/values/appSession';
import { ContentHelper } from '../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-virtual-care-container-cmp',
  templateUrl: '../views/pfVirtualCareContainerCmp.html',
  providers: []
})

export class PFVirtualCareContainerComponent extends BaseComponent implements OnInit {
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  constructor(
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'VirtualCareContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.onPreLoad();
    }
  }

  setPageHeader() {
    const pagevcObj = {
      header: this.content.pageHeader.vcScreen,
      title: this.content.pageHeader.vcScreen
    };

    if (this.content.pageHeader && this.content.pageHeader.vcScreen) {
      this._headerEvent.emit(pagevcObj);
    }
  }

  ngAfterViewInit() {
    this.setPageHeader();
  }
}
