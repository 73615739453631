import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IWindow } from '../../../common/interfaces/iWindow';
import { RulesService } from '../../rules/services/RulesSvc';
import { IPlanRule } from '../../search-providers/interfaces/iPlanRule';
import { ProviderSummaryService } from '../../search-results/services/providerSummarySvc';
import { ResultsUtil } from '../../utilities/resultsUtil';
import { ProcedureCategoryService } from '.././../../eyc/cost-search/services/procedureCategorySvc';
import { IProviderType } from '../interfaces/iProviderType';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { ADDCARETEAM_MESSAGE, ALERT_TYPE, ARIALABEL_MESSAGE, PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE, VEP, VEP_BEH_HEALTH } from '../values/providerSearchConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { IFilterChangePlan } from './../../../common/components/change-plan/models/iChangePlanParam';
import { ChangePlanService } from './../../../common/components/change-plan/services/changePlanSvc';
import { InfoListService } from './../../../common/components/info-list/services/infoListSvc';
import { AppConstants, Locale, MODAL_TYPE } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { IOptions } from './../../../common/interfaces/iAppMetadata';
import { IMessage } from './../../../common/interfaces/iMessage';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { CommonUtil } from './../../utilities/commonUtil';
import { ISearchParameters } from './../interfaces/iSearchParameters';

declare let window: any;

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-search-links-cmp',
  templateUrl: '../views/pfSearchLinksCmp.html',
  providers: []
})
export class PFSearchLinksComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  gotoPlanSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  planError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  modal: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  analytics: EventEmitter<IAdobeSearchDetail> = new EventEmitter<IAdobeSearchDetail>();

  selectedPlanText: string = '';
  careTeamResponse: any;
  providerTypeList: IProviderType[] = [];
  procedureList: any = [];
  locale: string;
  isSecureState: boolean = false;
  showPlanUnknownError: boolean = false;
  planUnknownErrorMsg: string = '';
  planRule: IPlanRule;
  showCareTaut: boolean = true;
  showProviderProcedureLoader: boolean = false;
  showCareTeamLoader: boolean = false;
  careTeamHeading: string = '';
  hasEYC: boolean = false;
  private changePlanSaveClickSubscription: Subscription;

  constructor(
    private _providerSummarySvc: ProviderSummaryService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _providerUtilityService: ProviderUtilityService,
    private _procedureSvc: ProcedureCategoryService,
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    private _ruleService: RulesService,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _eventHandler: EventHandler,
    private _appUtility: AppUtility,
    private _changePlanService: ChangePlanService,
    private _titleCasePipe: TitleCasePipe,
    private _infoListSvc: InfoListService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');

    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick.subscribe((path: AppNavigations) => {
      if (path === AppNavigations.SEARCH_PROVIDERS_PATH) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appUtility.scrollToTop();

    if (this._appSession) {
      this._appSession.pageNumber = null;
      this.locale = this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
      this.isSecureState = CommonUtil.isMemberSecure(this._appSession);
      this.hasEYC = this.isSecureState && (this._appSession.hasEYC || (this._appSession.hasDentalEYC && this.showDentalDDS));

      if (this._appSession.showPrefixPopup) {
        this.showSpecialPrefixPopup();
      }

      this.getProviderProcedureTypes();
      const _disclaimerCodes = this._infoListSvc.disclaimerCodes;
      this._infoListSvc.reset();
      if (this.isSecureState) {
        // Handle Info box in search criteria.
        const disclaimerCodes: IMessage[] = [];
        if (this.isAddCareTeamEnabled) {
          const index = _disclaimerCodes.findIndex((x) => x.contentId === ADDCARETEAM_MESSAGE);
          if (index > -1) {
            const _message = {
              contentId: ADDCARETEAM_MESSAGE,
              type: ALERT_TYPE.INFORMATION
            } as IMessage;
            disclaimerCodes.push(_message);
          }
        }
        this._infoListSvc.setInfoList(disclaimerCodes);
      }
    }
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }

  /// Write all ngOnInit call methods here
  initCalls() {
    // Handle Urgent care deep link Medsupp popup
    if (this._appSession.searchParams && this._appSession.searchParams.typeSelectNm === PROVIDER_TYPE.URGENT_CARE && this._appSession?.planRule && this._appSession?.planRule?.isMedicareGov) {
      this.openModal(this.modal.emit(MODAL_TYPE.MEDICARE_SUPPLEMENT));
    }

    this.careTeamHeading = '';
    this.showPlanUnknownError = false;
    let _planName = '';
    let _memberName = '';
    let _selectedPlanText = this.content.searchCriteriaComponent.labels.selectedPlan;

    if (this.searchParams.plan) {
      _planName = this.searchParams.plan.name;
      if (!_planName && this.searchParams.plan.securePlanLabelKey) {
        _planName = this.searchParams.plan.securePlanLabelKey;
      }

      if (_planName === this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown] || this.searchParams.plan === undefined) {
        _planName = this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown];
        if (this.planRule && !this.planRule?.isMedicareGov) {
          this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
          this.showPlanUnknownError = true;
        }
        this.goToPlanSelection();
      } else {
        if (this.isSecureState) {
          const _plan = ResultsUtil.getPlanNetworkPrefixText(this._appSession, '', this.searchParams);
          if (_plan) {
            _planName = _plan;
          }
          _selectedPlanText = this.content.searchCriteriaComponent.labels.selectedMemberPlan;
          if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
            const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
            if (_index > -1) {
              const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
              _memberName = this.titleCase(_member.firstName + ' ' + _member.lastName);
              this.careTeamHeading = this.content.searchCriteriaComponent.labels.careTeamFor.replace(/{NAME}/gi, _memberName.trim());
            }
          }
        }
      }
    } else {
      _planName = this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown];
      this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
      this.showPlanUnknownError = true;
      this.goToPlanSelection();
    }

    this.selectedPlanText = _selectedPlanText.replace(/{PLAN}/gi, _planName).replace(/{NAME}/gi, _memberName.trim());

    this.setPlanUnknown();
    if (this.showPlanUnknownError) {
      this.planError.emit(true);
    }
    if (this.isSecureState && !this.showPlanUnknownError && this.showPreConfiguredLink) {
      this.getPreConfigureSearchRule();
    }
  }

  getPreConfigureSearchRule() {
    this._ruleService.getPreconfiguredSearchRule(CommonUtil.buildPreConfigSearchRequest(this._appSession.searchParams, this._appSession)).then(
      (data: any) => {
        if (data) {
          if (data && data.configuredSearchRules) {
            this._appSession.preConfigureSearchRule = data.configuredSearchRules;
          } else {
            this._appSession.preConfigureSearchRule = undefined;
          }

          const preConfigRules = this._infoListSvc.disclaimerCodes;
          for (const rule of data.configuredSearchRules || []) {
            const _infoMessage = {
              contentId: rule.contentId,
              contentAriaLabelId: rule.contentId + ARIALABEL_MESSAGE,
              type: ALERT_TYPE.INFORMATION
            } as IMessage;
            preConfigRules.push(_infoMessage);
          }

          this._infoListSvc.setInfoList(preConfigRules);
        }
      },
      (error: any) => {
        try {
          this.onError('PreConfigSearchRuleResponse', error);
        } catch (e) {}
      }
    );
  }

  setPreconfiguredSearch($event) {
    if (
      this.isLocationValid() &&
      this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.MyPlan &&
      this._appSession.preConfigureSearchRule &&
      this._appSession.preConfigureSearchRule.length > 0 &&
      this._appSession.preConfigureSearchRule[0].deeplink.providerTypeCodeList.length > 0
    ) {
      this.searchParams.typeSelectNm = this._appSession.preConfigureSearchRule[0].deeplink.providerTypeCodeList[0];
      this.searchParams.providerTypeCodeList = this._appSession.preConfigureSearchRule[0].deeplink.providerTypeCodeList;
      this.searchParams.specialtySelectNm = this._appSession.preConfigureSearchRule[0].deeplink.specialtyCategoryList;
      if (this._appSession.preConfigureSearchRule[0].deeplink.sosOnly) {
        this.searchParams.sosOnly = this._appSession.preConfigureSearchRule[0].deeplink.sosOnly;
      }
      if (this._appSession.preConfigureSearchRule[0].deeplink.acceptingNewPatients) {
        this.searchParams.acceptingNewPatients = this._appSession.preConfigureSearchRule[0].deeplink.acceptingNewPatients;
      }
      //if (this._appSession.preConfigureSearchRule[0].deeplink.ableToServePcp) {
      //  this.searchParams.ableToServePcp = this._appSession.preConfigureSearchRule[0].deeplink.ableToServePcp;
      //}
      this.resetAppSessionParams();
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
  }

  isLocationValid() {
    let _isValid = true;
    if (!(this.searchParams && this.searchParams.coordinates && this.searchParams.coordinates.latitude !== '' && this.searchParams.coordinates.longitude !== '')) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  resetAppSessionParams() {
    this.searchParams.provNm = '';
    this.searchParams.coverageTypeCode = '';
    this.searchParams.taxonomySelectNm = [];
    this.searchParams.standAloneDental = null;
    this.searchParams.standAloneVision = null;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchParams;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
  }

  onError(type: string, error: any) {
    throw error;
  }
  setPlanUnknown() {
    const isPlanUnknown =
      CommonUtil.isMemberSecure(this._appSession) && this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.PlanUnknown && this.planRule && !this.planRule?.isMedicareGov;

    if (CommonUtil.isMemberSecure(this._appSession)) {
      this._appSession.isPlanUnknown = isPlanUnknown;
    }
  }

  // Plan Rule
  getPlanRuleResponse() {
    this._ruleService.getAppInitializationRules(CommonUtil.buildPlanRuleRequest(this.searchParams, this._appSession)).then(
      (result: any) => {
        if (result) {
          this.planRule = {
            isLiberty: result.isLiberty ? result.isLiberty : false,
            isBVV: result.isBVV ? result.isBVV : false,
            isBVVI: result.isBVVI ? result.isBVVI : false,
            isHCS: result.isHCS ? result.isHCS : false,
            isVSP: result.isVSP ? result.isVSP : false,
            isEyeQuest: result.isEyeQuest ? result.isEyeQuest : false,
            isSuperior: result.isSuperior ? result.isSuperior : false,
            isEyeMed: result.isEyeMed ? result.isEyeMed : false,
            isTransportation: result.isTransportation ? result.isTransportation : false,
            isDentaQuest: result.isDentaQuest ? result.isDentaQuest : false,
            isIndividual: result.isIndividual ? result.isIndividual : false,
            isGroup: result.isGroup ? result.isGroup : false,
            isMedicaid: result.isMedicaid ? result.isMedicaid : false,
            isMedicareGov: result.isMedicareGov ? result.isMedicareGov : false,
            transportationLocation: result.transportationLocation,
            cobrandRule: result.cobrandRule,
            isOhEyeMed: result.isOhEyeMed ? result.isOhEyeMed : false
          };
          this._appSession.planRule = this.planRule;

          this.initCalls();
        }
      },
      (error: any) => {
        try {
          this.onCategoryError('PLAN_RULE', error);
        } catch (e) {}
      }
    );
  }

  getMedicalUnknownNetworkMsg() {
    this.showPlanUnknownError = true;
    const planUnknownMsg = this.customizedFeatures.showChangePlan
      ? this.content.searchCriteriaComponent.alerts.networkUnknownMsg
      : this.WcsUtility.getUnknownNetworkMsg(PROVIDER_TYPE.DOCTOR_PROFESSIONAL);
    return planUnknownMsg;
  }

  getProviderProcedureTypes() {
    this.showProviderProcedureLoader = true;
    this._procedureSvc.getQuickLinksList(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
      (result: any) => {
        if (result && result.procedures && result.procedures.length > 0 && (this._appSession.hasEYC || (this._appSession.hasDentalEYC && this.showDentalDDS))) {
          this.procedureList = result.procedures;
        }
        if (result && result.providers && result.providers.length > 0) {
          // this.providerTypeList = result.providers;
          this.populateProviderTypes(result.providers);
        }
        this.showProviderProcedureLoader = false;
      },
      (error: any) => {
        try {
          this.showProviderProcedureLoader = false;
          this.onCategoryError('PROVIDERS_PROCEDURES', error);
        } catch (e) {}
      }
    );
  }

  populateProviderTypes(data: any) {
    this.providerTypeList = this._appSession.providerTypes = CommonUtil.populateProviderTypes(data, this._appSession, true);

    if (this._appSession.providerTypes.length === 1) {
      this.searchParams.typeSelectNm = this._appSession.providerTypes[0].code;
    }

    this.getPlanRuleResponse();
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  showSpecialPrefixPopup() {
    const prefix = CommonUtil.getPrefix(this.searchParams, this._appSession);
    if (prefix && prefix.toUpperCase() === VEP) {
      this._appSession.showPrefixPopup = false;
      this.leavingDisclaimer.emit(VEP_BEH_HEALTH);
    }
  }

  onChangePlanClick() {
    if (!CommonUtil.isMemberSecure(this._appSession)) {
      this.goToPlanSelection();
    } else {
      this._changePlanService.setPlanSelectionLinkClick(true);
    }
  }

  goToPlanSelection() {
    const isPlanUnknown =
      CommonUtil.isMemberSecure(this._appSession) && this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.PlanUnknown && this.planRule && !this.planRule?.isMedicareGov;

    if (CommonUtil.isMemberSecure(this._appSession)) {
      this._appSession.isPlanUnknown = isPlanUnknown;
    }
    if (!CommonUtil.isMemberSecure(this._appSession) && !this._appSession.isPlanUnknown) {
      this.resetSearchParams();
    }
    if (this._appSession && this._appSession.searchParams && this._appSession.searchParams.plan && this._appSession.searchParams.plan.securePlanLabelKey) {
      this.searchParams.securePlanLabelKey = this._appSession.searchParams.plan.securePlanLabelKey;
    }
    this._appSession.searchParams = this.searchParams;
    this.gotoPlanSelection.emit();
  }

  resetSearchParams() {
    this.searchParams.stateCd = '';
    this.searchParams.provNm = '';
    this.searchParams.zip = '';
    this.searchParams.typeSelectNm = '';
    this.searchParams.providerTypeCodeList = [];
    this.searchParams.specialtySelectNm = [];
    this.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this.searchParams.city = '';
    this.searchParams.coordinates = {
      latitude: '',
      longitude: ''
    };
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchOption = '';
    this._appSession.isDentalStandAlone = false;
    this._appSession.isPharmacyStandAlone = false;
    this._appSession.isVisionStandAlone = false;
    this._appSession.filterChangePlan = {} as IFilterChangePlan;
    this._appSession.filterChangePlan.isPreviouState = false;
  }

  onLocationError() {
    this.locationError.emit(true);
  }

  openModal(event: any) {
    this.modal.emit(event);
  }

  onAnalytics(event: IAdobeSearchDetail) {
    this.analytics.emit(event);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  titleCase(name: string) {
    return this._titleCasePipe.transform(name);
  }
}
