import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { SearchSlideOutModule } from '../search-slide-out/searchSlideOutModule';
import { CostSlideOutComponent } from './costSlideOutCmp';

@NgModule({
  declarations: [CostSlideOutComponent],
  exports: [CostSlideOutComponent],
  imports: [CommonModule, UxSharedModule, SearchSlideOutModule]
})
export class CostSlideOutModule { }
