import { CommonModule, KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPipesModule } from '../../pipes/customPipesModule';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { LookUpListModule } from '../lookup-list/pfLookUpListModule';
import { MapModule } from '../map/pfMapModule';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { LocationComponent } from './locationCmp';
import { LocationV2Component } from './locationV2Cmp';
import { ViewMoreLocationsComponent } from './pfViewMoreLocationsCmp';

@NgModule({
    declarations: [LocationComponent, ViewMoreLocationsComponent, LocationV2Component],
    exports: [LocationComponent, ViewMoreLocationsComponent, LocationV2Component],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule,
        LookUpListModule,
        MapModule,
        CustomPipesModule
    ],
    providers: [KeyValuePipe]
})
export class LocationModule { }
