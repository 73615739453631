import { ContractCoverageType } from '../constants/common';
import { IContract } from '../interfaces/iContract';

export class ContractUtility {
  /**
   * Retrieves unique coverage types from the contract.
   * @param contract The bootstrap contract.
   * @returns An array of unique coverage types.
   */
  static getCoverages(contract: IContract): string[] {
    if (!contract?.coverages) {
      return [];
    }

    const coverages = contract.coverages.flatMap((coverage) => coverage.coverageTypes);
    const uniqueCoverages = [...new Set(coverages)];

    return uniqueCoverages.map((type) => ContractCoverageType[type] ?? type);
  }

  /**
   * Retrieves unique coverage plans from the contract.
   * @param contract The bootstrap contract.
   * @returns An array of unique coverage plans.
   */
  static getCoveragePlans(contract: IContract): string[] {
    if (!contract?.coverages) {
      return [];
    }

    const plans = contract.coverages.map((coverage) => coverage.planNm);
    return Array.from(new Set(plans));
  }
}
