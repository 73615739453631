import { Component } from '@angular/core';
import { PFIntegratedSearchComponent } from './pfIntegratedSearchCmp';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-integrated-search-v2-cmp',
  templateUrl: '../views/pfIntegratedSearchV2Cmp.html',
  providers: []
})
export class PFIntegratedSearchV2Component extends PFIntegratedSearchComponent {

}
