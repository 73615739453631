import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFHelpfulHintsComponent } from './pfHelpfulHintsCmp';
@NgModule({
    declarations: [PFHelpfulHintsComponent],
    exports: [PFHelpfulHintsComponent],
    imports: [
        CommonModule,
        UxSharedModule,
    ],
    providers: []
})
export class HelpfulHintsModule { }
