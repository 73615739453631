import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from '../../../common/constants/app-constants';
import { IProvider } from '../../search-results/interfaces/iSummaryResp';
import { CommonUtil } from '../../utilities/commonUtil';
import { IAffiliationRequest } from '../models/iAffiliationRequest';
import { IProviderLocation } from '../models/iProviderLocation';
import { ISelectedProvider } from '../models/iSelectedProvider';
import { ProviderOrchestrationService } from '../services/providerOrchestrationSvc';
import { AffiliationConstants } from '../values/affiliationConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { ProviderDetailsUtils } from './../../utilities/providerDetailsUtils';
import { IAddress } from './../models/iAddress';
import { AffiliationResponseV2, IProviderV2 } from './../models/iAffiliationsResponse';
import { IOutputFlags } from './../models/iDetailsParameters';
import { ProviderDetailsNavigationService } from './../services/providerDetailsNavigationSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fad-provider-affiliations-cmp',
  templateUrl: '../views/pfAffiliationsCmp.html'
})
export class PFAffiliationsComponent extends BaseComponent implements OnInit {
  @Input()
  selectedProvider: IProvider;
  @Input()
  inNetworkList: any;
  @Input()
  selectedAddressId?: string;
  @Input()
  affiliationResponse: AffiliationResponseV2;
  @Output()
  affiliationLoaded: EventEmitter<AffiliationResponseV2> = new EventEmitter<AffiliationResponseV2>();
  types = [{ label: this.content.affiliationComponent.labels.affiliationsTab.all, value: this.content.affiliationComponent.labels.affiliationsTab.all, type: true }];
  specialities = [{ label: this.content.affiliationComponent.labels.affiliationsTab.all, value: this.content.affiliationComponent.labels.affiliationsTab.all }];
  specailitiesMap = {};
  selectedType = this.content.affiliationComponent.labels.affiliationsTab.all;
  selectedSpeciality = this.content.affiliationComponent.labels.affiliationsTab.all;
  _type: String;
  _disabled: Boolean = false;
  showAffialitionsLoader: boolean = true;
  affiliations: AffiliationResponseV2 = { providerList: [] };
  specialityProviders = [];
  filteredProviders = [];
  showMore: boolean = false;
  label: string = this.content.affiliationComponent.labels.affiliationsTab.all;
  noAffiliations = false;
  showAffiliationsError: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _navigationService: NavigationService,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _providerOrchestrationService: ProviderOrchestrationService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit(): void {
    if (this.waitUntilAppReload) {
      return;
    }

    if (this.affiliationResponse) {
      this.onAffiliationsSuccess(this.affiliationResponse);
    } else {
      this.getAffiliations();
    }
  }

  ngOnChanges(args: unknown): void {
    if (!this.affiliationResponse) {
      if (ProviderDetailsUtils.hasValueChanged(args, 'selectedAddressId') || ProviderDetailsUtils.hasValueChanged(args, 'selectedProvider', 'providerIdentifier')) {
        this.getAffiliations();
      }
    }
  }

  getAffiliations() {
    const alphaPrefix = [];
    if (this._appSession.searchParams.plan && this._appSession.searchParams.plan.alphaPrefix) {
      alphaPrefix.push(this._appSession.searchParams.plan.alphaPrefix);
    }
    const locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
    let reqObj = {
      providerIdentifier: this.selectedProvider.providerIdentifier,
      addressIdentifier: this.selectedAddressId,
      networkList: this._appSession.searchParams.plan.networkList || [],
      alphaPrefixList: alphaPrefix,
      inNetworkList: this.inNetworkList,
      providerTypeCodeList: this.selectedProvider.providerTypeCodeList,
      locale: locale
    } as IAffiliationRequest;
    if (this._appSession.metaData?.appContract) {
      reqObj.mbrUid = this._appSession.metaData.appContract.mbrUid;
      reqObj.contractUid = this._appSession.metaData.appContract.contractUid;
      if (this._appSession.isMemberMedicalGrpSearch && this._appSession.primeGroupAffiliations?.affiliationList?.length) {
        reqObj.outputFlags = { includePrimeGroupSearch: true } as IOutputFlags;
      }
    }

    let affiliations: AffiliationResponseV2;
    this._providerOrchestrationService
      .getAffiliations(reqObj)
      .then(
        (resp: AffiliationResponseV2) => {
          affiliations = resp;
          this.onAffiliationsSuccess(resp);
        },
        (error: any) => {
          this.onError(error);
        }
      )
      .finally(() => {
        this.affiliationLoaded.emit(affiliations);
      });
  }

  onAffiliationsSuccess(result: AffiliationResponseV2): void {
    this.showAffialitionsLoader = false;
    this.affiliations = result;
    this.noAffiliations = this.affiliations?.providerList?.length === 0 || false;
    this.filterSpecialities(null);
  }

  onError(error: any) {
    this.showAffiliationsError = true;
    this.showAffialitionsLoader = false;
    throw error;
  }
  filterSpecialities(event: any) {
    let specialities = [];
    var filteredProvider = [];
    const providerTypes = [];
    const providerKeys: String[] = [];
    var specialityProvider = {};
    var providerTypeSpeciality = {};
    const _speciality = {} as any;
    _speciality.label = this.content.affiliationComponent.labels.affiliationsTab.all;
    _speciality.value = this.content.affiliationComponent.labels.affiliationsTab.all;
    specialities.push(_speciality);
    const _providerType = {} as any;
    _providerType.label = this.content.affiliationComponent.labels.affiliationsTab.all;
    _providerType.value = this.content.affiliationComponent.labels.affiliationsTab.all;
    _providerType.type = true;
    providerTypes.push(_providerType);
    this.affiliations?.providerList.forEach((_provider) => {
      _provider?.specialtyList?.forEach((speciality) => {
        const _speciality = {} as any;
        _speciality.label = speciality.name;
        _speciality.value = speciality.code;
        _speciality.showMore = false;

        if (specialityProvider.hasOwnProperty(speciality.code)) {
          specialityProvider[speciality.code].providers.push(_provider);
        } else {
          const specialityProviderMap = {
            code: speciality.code,
            name: speciality.name,
            specialityDescription: speciality.description,
            providers: [_provider]
          };
          specialityProvider[speciality.code] = specialityProviderMap;
        }
        _speciality.totalProviders = specialityProvider[speciality.code].providers.length;
        this.specailitiesMap[speciality.code] = _speciality;
      });
      _provider?.provTypeCodeList?.forEach((providerType) => {
        const _providerType = {} as any;
        _providerType.label = providerType.name;
        _providerType.value = providerType.code;
        _providerType.type = true;
        if (!providerKeys.includes(providerType.code)) {
          providerKeys.push(providerType.code);
          providerTypes.push(_providerType);
        }
        if ((event?.value && providerKeys.includes(event.value)) || event?.value === this.content.affiliationComponent.labels.affiliationsTab.all) {
          this._type = event.value;
          this._disabled = this._type.toLocaleUpperCase() === AffiliationConstants.P ? true : false;
        }
        this.types = providerTypes;
        if (providerTypeSpeciality.hasOwnProperty(providerType.code)) {
          _provider.specialtyList.forEach((speciality) => {
            providerTypeSpeciality[providerType.code].specialities[speciality.code] = speciality;
          });
          providerTypeSpeciality[providerType.code].providers.push(_provider);
        } else {
          const providerSpecialityMap = {
            code: providerType.code,
            name: providerType.name,
            providers: [_provider],
            specialities: {}
          };
          _provider.specialtyList.forEach((speciality) => {
            providerSpecialityMap.specialities[speciality.code] = speciality;
          });
          providerTypeSpeciality[providerType.code] = providerSpecialityMap;
        }
        this.specailitiesMap[providerType.code] = {
          showMore: false,
          totalProviders: providerTypeSpeciality[providerType.code].providers.length
        };
      });
    });
    if (this.selectedSpeciality === this.content.affiliationComponent.labels.affiliationsTab.all && this.selectedType === this.content.affiliationComponent.labels.affiliationsTab.all) {
      for (var key in providerTypeSpeciality) {
        if (key !== AffiliationConstants.P) {
          filteredProvider.push(providerTypeSpeciality[key]);
        } else {
          Object.keys(providerTypeSpeciality[AffiliationConstants.P].specialities).forEach((key) => {
            filteredProvider.push(specialityProvider[key]);
          });
        }
      }
    } else if (this.selectedType !== this.content.affiliationComponent.labels.affiliationsTab.all && this.selectedSpeciality === this.content.affiliationComponent.labels.affiliationsTab.all) {
      if (this.selectedType !== AffiliationConstants.P) {
        filteredProvider.push(providerTypeSpeciality[this.selectedType]);
      } else {
        this._disabled = true;
        Object.keys(providerTypeSpeciality[AffiliationConstants.P].specialities).forEach((key) => {
          specialities.push(this.specailitiesMap[key]);
          filteredProvider.push(specialityProvider[key]);
        });
      }
    }
    if (this.selectedSpeciality !== this.content.affiliationComponent.labels.affiliationsTab.all) {
      Object.keys(providerTypeSpeciality[AffiliationConstants.P].specialities).forEach((key) => {
        specialities.push(this.specailitiesMap[key]);
      });
      filteredProvider.push(specialityProvider[this.selectedSpeciality]);
    }
    filteredProvider = this.sortArray(filteredProvider, 'name');
    filteredProvider.forEach((filterVal) => {
      if (filterVal.providers) this.sortArray(filterVal.providers, 'providerName');
    });
    this.specialityProviders = filteredProvider;
    this.filterProviders();
    this.types = this.sortArray(this.types, 'label');
    specialities = this.sortArray(specialities, 'label');
    this.specialities = specialities;
    if (event && event.type) {
      this.selectedSpeciality = this.content.affiliationComponent.labels.affiliationsTab.all;
    }
  }

  hasMemberMedicalGroup(provider: IProviderV2): boolean {
    let hasMemberMedicalGroup = false;
    const primeGrpAffiliations = this._appSession.primeGroupAffiliations?.affiliationList;
    if (provider && this._appSession.isMemberMedicalGrpSearch && primeGrpAffiliations && primeGrpAffiliations.length > 0) {
      hasMemberMedicalGroup =
        provider.providerIdentifier === primeGrpAffiliations[0].providerIdentifier && provider.addressSummary?.addressIdentifier === primeGrpAffiliations[0].addressSummary?.addressIdentifier;
    }
    return hasMemberMedicalGroup;
  }

  sortArray(arryaVal: Array<any>, sortVal: string) {
    return arryaVal.sort((a, b) => (a[sortVal] > b[sortVal] ? 1 : b[sortVal] > a[sortVal] ? -1 : 0));
  }

  toggleShowMore(speciality?: any) {
    this.specailitiesMap[speciality.code].showMore = !this.specailitiesMap[speciality.code].showMore;
    this.filterProviders();
  }
  filterProviders() {
    let providers = JSON.parse(JSON.stringify(this.specialityProviders));
    providers.forEach((_speciality) => {
      if (!this.specailitiesMap[_speciality.code].showMore) {
        _speciality.providers = _speciality.providers.slice(0, 10);
      }
    });
    this.filteredProviders = providers;
  }

  showProviderDetails(professional: any) {
    const selectedProvider = {} as ISelectedProvider;
    selectedProvider.addressIdentifier = professional.addressIdentifier;
    selectedProvider.providerIdentifier = professional.providerIdentifier;
    selectedProvider.providerName = professional.providerName;
    selectedProvider.networkRegisteredName = professional.networkRegisteredName;
    selectedProvider.titleList = professional.titleList;
    selectedProvider.providerTypeCodeList = professional.provTypeCodeList.map((providerType) => providerType.code);
    if (professional.addressSummary) {
      selectedProvider.location = {} as IProviderLocation;
      selectedProvider.location.address = {} as IAddress;
      selectedProvider.location.address.addressId = professional.addressSummary.addressIdentifier;
      selectedProvider.location.address.latitude = professional.addressSummary.latitude;
      selectedProvider.location.address.longitude = professional.addressSummary.longitude;
    }
    this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider, true);
    this.selectedSpeciality = this.content.affiliationComponent.labels.affiliationsTab.all;
    this.selectedType = this.content.affiliationComponent.labels.affiliationsTab.all;
  }

  getProviderName(provider: IProviderV2): string {
    return CommonUtil.getProviderTitles(provider?.titleList, provider?.networkRegisteredName, provider?.providerName);
  }
}
