import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMoreOption } from '../interfaces/iMoreOptions';

@Injectable({
  providedIn: 'root'
})
export class MoreOptionsService {
  private moreOptionsListSubject = new BehaviorSubject<Array<IMoreOption>>([]);
  moreOptionsList = this.moreOptionsListSubject.asObservable();

  setMoreOptionsList(options: Array<IMoreOption>) {
    this.moreOptionsListSubject.next(options);
  }
}
