import { Inject, Injectable } from '@angular/core';
import { IPublicTccMedicalSearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import {
  IFinalProvider,
  IFinalSearchResponse
} from '../../../common/interfaces/iCommonSearchResponse';
import { IEmailResponse } from '../../../common/interfaces/iEmailResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import {
  IPublicMedicalDemographicFilter, IPublicMedicalEycProcedureCriteriaRequest,
  IPublicTccMedicalEmailRequest,
  IPublicTccMedicalOutputFlags,
  IPublicTccMedicalPrintRequest,
  IPublicTccMedicalSearchCriteria,
  IPublicTccMedicalSearchRequest
} from '../../../common/interfaces/iPublicTccMedicalRequest';
import { IPublicTccMedicalSearchResponse } from '../../../common/interfaces/iPublicTccMedicalSearchResponse';
import { PublicV1TccMedical } from '../../../common/services/publicV1TccMedicalSvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppSession } from '../../../common/values/appSession';
import { CommonUtil } from '../../utilities/commonUtil';
import { SearchRequestUtil } from '../../utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../utilities/searchResponseUtil';
import { API_NAME } from '../values/providerSearchConstants';

@Injectable({ providedIn: 'root' })
export class PublicTccMedicalAdapterSvc {
  private pdfPageNumber = 1;
  private pdfPageSize: number;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _publicV1TccMedicalSvc: PublicV1TccMedical,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc
  ) {
    this.pdfPageSize = this._appSession.appConfig?.paginationSize.summaryPrintPage;
  }

  async search(
    pageNumber: number,
    pageSize: number
  ): Promise<IFinalSearchResponse> {
    const searchRequest = this.transformRequest(pageNumber, pageSize);
    const response = await this._publicV1TccMedicalSvc.execute(searchRequest);
    return this.transformResponse(response);
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent }>(
    content: T
  ): Promise<Buffer> {
    const searchRequest = this.transformRequest(
      this.pdfPageNumber,
      this.pdfPageSize
    );
    const printRequest: IPublicTccMedicalPrintRequest = {
      ...searchRequest
    };
    const endPoint = SearchRequestUtil.getApiName(API_NAME.PUBLIC_TCC_MEDICAL);
    return this._summaryPrintSvc.execute(printRequest, endPoint);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent }>(
    email: string,
    content: T
  ): Promise<IEmailResponse> {

    const searchRequest = this.transformRequest(
      this.pdfPageNumber,
      this.pdfPageSize
    );
    const emailRequest: IPublicTccMedicalEmailRequest = {
      ...searchRequest,
      email,
    };
    const endPoint = SearchRequestUtil.getApiName(API_NAME.PUBLIC_TCC_MEDICAL);
    return this._summaryEmailSvc.execute(emailRequest, endPoint);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): IPublicTccMedicalSearchFilterRequest {
    const tccMedicalPublicSearchReq = this.transformRequest(
      pageNumber,
      pageSize
    );
    const filterReq: IPublicTccMedicalSearchFilterRequest = {
      ...tccMedicalPublicSearchReq,
      scenario: SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL,
    };
    return filterReq;
  }

  private transformRequest(
    pageNumber: number,
    pageSize: number
  ): IPublicTccMedicalSearchRequest {
    const searchParams = this._appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(
      this._appSession,
      pageNumber,
      pageSize
    );
    // remove plan and planNames from request payload
    commonSearchRequest.plan = undefined;
    commonSearchRequest.planNames = undefined;
    // redefine required output-flags
    const {
      includeDisclaimerRules,
      includeTaxonomy,
      includeReviewRatings,
      includeVisibilityRules,
      imposeAddressConsolidation,
      improveSearchCriteria,
    } = commonSearchRequest.outputFlags || {};
    const outputFlags: IPublicTccMedicalOutputFlags = {
      includeColdStateProcedureSearch: true,
      includeDisclaimerRules,
      includeReviewRatings,
      includeTaxonomy,
      includeVisibilityRules,
      imposeAddressConsolidation,
      improveSearchCriteria,
    };
    let returnTypeofProvider: string;
    returnTypeofProvider = CommonUtil.getProcedureProviderReturnType(searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode);

    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(
      this._appSession
    );
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const searchCriteria: IPublicTccMedicalSearchCriteria = {
      brandCode: this._appSession?.metaData?.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this._appSession),
      integratedBrandCode: this._appSession.metaData.integratedBrandCode || undefined
    };
    // binding demogrphic filters if available
    if (demographicFilter) {
      searchCriteria.demographicFilter = this.bindDemographicFilter(demographicFilter);
    }
    // binding misc filters if available
    if (miscFilter) {
      searchCriteria.miscFilter = miscFilter;
    }

    const eycProcedureCriteria: IPublicMedicalEycProcedureCriteriaRequest = {
      procedureCode:
        searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode,
      providerCategoryCode:
        searchParams?.eycSearchParam?.eycProcedureCriteria
          ?.providerCategoryCode,
      returnTypeofProvider,
      eycPrefix: searchParams?.eycPrefix,
    };
    const searchRequest: IPublicTccMedicalSearchRequest = {
      ...commonSearchRequest,
      searchCriteria,
      eycProcedureCriteria,
      memberCriteria: undefined,
      outputFlags,
    };
    return searchRequest;
  }

  private bindDemographicFilter(demographicFilter): IPublicMedicalDemographicFilter {
    const searchCriteriaDemographic: IPublicMedicalDemographicFilter = {};
    // filter - Key Filters
    if (demographicFilter.ableToServeAsPcp) {
      searchCriteriaDemographic.ableToServeAsPcp = demographicFilter.ableToServeAsPcp;
    }
    if(demographicFilter.anp) {
      searchCriteriaDemographic.anp = demographicFilter.anp;
    }
    // filter - Profile
    if (demographicFilter.telehealth) {
      searchCriteriaDemographic.telehealth = demographicFilter.telehealth
    }
    if (demographicFilter.boardCertification) {
      searchCriteriaDemographic.boardCertification = demographicFilter.boardCertification;
    }
    // filter - Area of Expertise (aoe) []
    if (demographicFilter.aoeCodes?.length) {
      searchCriteriaDemographic.aoeCodes = demographicFilter.aoeCodes;
    }
    // filter - Type []
    if (demographicFilter.typeCodes?.length) {
      searchCriteriaDemographic.typeCodes = demographicFilter.typeCodes;
    }
    // filter - Ethnicity []
    if (demographicFilter.ethnicityCodes?.length) {
      searchCriteriaDemographic.ethnicityCodes = demographicFilter.ethnicityCodes;
    }
    // filter - Gender []
    if (demographicFilter.genderCodes?.length) {
      searchCriteriaDemographic.genderCodes = demographicFilter.genderCodes;
    }
    // filter - Specialty []
    if (demographicFilter.specialtyCategoryCodes?.length) {
      searchCriteriaDemographic.specialtyCategoryCodes = demographicFilter.specialtyCategoryCodes;
    }
    // filter - Sub Specialty []
    if (demographicFilter.taxonomyCodes?.length) {
      searchCriteriaDemographic.taxonomyCodes = demographicFilter.taxonomyCodes;
    }
    // filter - Level Of care []
    if (demographicFilter.levelOfCareCodes?.length) {
      searchCriteriaDemographic.levelOfCareCodes = demographicFilter.levelOfCareCodes;
    }
    // filter - ofcSrvcCodes []
    if (demographicFilter.ofcSrvcCodes?.length) {
      searchCriteriaDemographic.ofcSrvcCodes = demographicFilter.ofcSrvcCodes;
    }
    if (demographicFilter.languageCodes?.length) {
      searchCriteriaDemographic.languageCodes = demographicFilter.languageCodes;
    }
    // filter - adaAccessibility []
    if (demographicFilter.adaAccessibilityList?.length) {
      searchCriteriaDemographic.adaAccessibilityList = demographicFilter.adaAccessibilityList;
    }
    return searchCriteriaDemographic;
  }

  private transformResponse(apiResponse: IPublicTccMedicalSearchResponse) {
    const response = {} as IFinalSearchResponse;
    const finalProviders: IFinalProvider[] = [];
    if (apiResponse) {
      response.pageNumber = apiResponse.pageNumber;
      response.pageSize = apiResponse.pageSize;
      response.totalPages = apiResponse.totalPages;
      response.totalRecords = apiResponse.totalRecords;
      response.defaultSort = apiResponse.sortBy;
      response.isHPNPlan = apiResponse.isHPNPlan;
      response.costRange = apiResponse.costRange;
      response.ruleList = apiResponse.rules;
      response.sortOptions = apiResponse.sortOptions;
      response.criteriaChange = apiResponse.criteriaChange;
      if (apiResponse.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IFinalProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            finalProviders.push(finalProvider);
          }
        });
      }
      response.providerList = finalProviders;
      response.sortIndicator = apiResponse.sortIndicator;
    }
    return response;
  }
}
