import { Injectable } from "@angular/core";
import { BaseService } from "../../../common/services/baseService";
import { AppUtility } from "../../../common/utilities/appUtil";
import { AppSession } from "../../../common/values/appSession";
import { ProviderDetailsUtils } from "../../utilities/providerDetailsUtils";
import { IDetailsResponse } from "../models/iDetailsResponse";
import { IProviderDetailsRequest } from "../models/iProviderDetailsRequest";
import { IProviderDetailsResponse } from "../models/iProviderDetailsResponse";
import { ISelectedProvider } from "../models/iSelectedProvider";
import { HttpClientService } from './../../../common/services/httpClientService';
import { ProviderDetailsV1Service } from "./providerDetailsV1Svc";

@Injectable({
  providedIn: "root",
})
export class ProviderDetailsService extends BaseService {
  constructor(
    _appSession: AppSession,
    private _v1ProviderDetails: ProviderDetailsV1Service,
    _httpClientService: HttpClientService,
    appUtility: AppUtility
  ) {
    super(_appSession, appUtility, _httpClientService);
  }

  async search(
    selectedProvider: ISelectedProvider,
    selectedAddressId: string,
    defaultAddressId: string
  ): Promise<IDetailsResponse> {
    try {
      const transformedRequest: IProviderDetailsRequest =
        ProviderDetailsUtils.buildDetailsRequest(
          this.appSession,
          selectedProvider,
          selectedAddressId,
          defaultAddressId
        );
      const apiResponse: IProviderDetailsResponse =
        await this._v1ProviderDetails.execute(transformedRequest);
      return ProviderDetailsUtils.buildDetailsResponse(apiResponse);
    } catch (error) {
      Promise.reject(error);
    }
  }
}
