import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { DOLLAR_SYMBOL } from '../../../constants/common';
import { FormattedMbrPlanProgress } from '../../../interfaces/iMemberPlanProgressResponse';
import { BaseComponent } from '../../core/baseCmp';


@Component({
  moduleId: module.id,
  selector: 'app-fc-member-plan-progress-cmp',
  templateUrl: './memberPlanProgressCmp.html'
})
export class MemberPlanProgressComponent extends BaseComponent {
  @Input() formattedMbrPlanProgressList: FormattedMbrPlanProgress[];
  currentIndex: number = 0;
  dollarSymbol: string = DOLLAR_SYMBOL;

  constructor(
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler
  ) {
    super(_route, _eventHandler, _appSession);
  }

  previousMbrBenefit() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.formattedMbrPlanProgressList.length - 1;
    }
  }

  nextMbrBenefit() {
    this.currentIndex++;
    if (this.currentIndex >= this.formattedMbrPlanProgressList.length) {
      this.currentIndex = 0;
    }
  }

  getCommonImagePath(imageName: string): string {
    return this.getCommonImageURL(imageName);
  }

  calculatePercentage(): number {
    if (this.formattedMbrPlanProgressList[this.currentIndex].limit === 0) {
      return 0;
    }
    return (this.formattedMbrPlanProgressList[this.currentIndex].spent / this.formattedMbrPlanProgressList[this.currentIndex].limit) * 100;
  }
}
