export class Paginator {
  private _itemsPerPage: number = 20;
  private _totalItems: number;
  private _totalPages: number;
  private _pageIndex: number;

  /**
   * A positive integer value. Based on this value the totalPages value is calculated.
   * Only positive integers are allowed. When floats are given they will be modified to integer.
   */
  set totalItems(v: number) {
    v = Math.trunc(v);
    if (v > -1 && this._totalItems !== v) {
      this._totalItems = v;
      this.reCalculate();
    }
  }

  get totalItems(): number {
    return this._totalItems;
  }

  /**
   * A positive integer value representing number of items in a page.
   * Negative values and zero are not allowed.
   *
   */
  set itemsPerPage(v: number) {
    v = Math.trunc(v);
    if (v > 0 && this._itemsPerPage !== v) {
      this._itemsPerPage = v;
      this.reCalculate();
    }
  }
  get itemsPerPage(): number {
    return this._itemsPerPage;
  }

  get totalPages() {
    return this._totalPages;
  }

  get currentPage() {
    const curPage: number = this._pageIndex === undefined ? -1 : this._pageIndex + 1;
    return curPage;
  }
  /**
   * -1 or a possitive interger value. -1 is returned when the paginator is not initialised with totalItems
   *
   */
  get startIndex(): number {
    let si: number = this._pageIndex * this._itemsPerPage;
    si = isNaN(si) ? -1 : si;
    return si;
  }

  /**
   * -1 or a possitive interger value. -1 is returned when the paginator is not initialised with totalItems
   *
   */
  get endIndex(): number {
    const si: number = this.startIndex;
    if (si > -1) {
      let ei: number = this.startIndex + this._itemsPerPage - 1;
      if (ei > this.totalItems - 1) {
        ei = this._totalItems - 1;
      }
      return ei;
    }

    return -1;
  }

  /**
   * Go to a given page number.
   * Only positive real numbers are allowed
   * @param val
   */
  goto(v: number) {
    if (this._totalItems === undefined) {
      //Paginator has not yet initialised
      //in such situation if goto() is invoked, dont do anything
      return;
    }

    v = Math.trunc(v);
    if (v > 0 && this.currentPage !== v) {
      let i: number = v - 1;
      //When trying to goto a page out of maximum available pages
      //Stay on the same page (means.. do not modify any values)
      if (i > this._totalPages - 1) {
        i = this._totalPages - 1;
      }

      if (i !== this._pageIndex) {
        this._pageIndex = i;
      }
    }
  }

  first() {
    this.goto(1);
  }
  next() {
    this.goto(this.currentPage + 1);
  }

  previous() {
    this.goto(this.currentPage - 1);
  }

  last() {
    this.goto(this._totalPages);
  }

  get isNextAvailable(): boolean {
    if (this.currentPage < this._totalPages && this.currentPage >= 1) {
      return true;
    }
    return false;
  }

  get isPreviousAvailable(): boolean {
    if (this.currentPage > 1 && this.currentPage <= this._totalPages) {
      return true;
    }
    return false;
  }

  private reCalculate() {
    this._totalPages = Math.ceil(this._totalItems / this._itemsPerPage);
    this._pageIndex = 0;
  }
}
