import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { ProviderCard } from '../../../constants/result';
import { IDetail } from '../../../interfaces/iProviderDetail';

@Component({
  moduleId: module.id,
  selector: 'app-fc-services-card-cmp',
  templateUrl: './servicesCardCmp.html'
})
export class ServicesCardComponent extends BaseComponent {
  @Input() providerDetails: IDetail;
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  serviceCardContent = this.content?.result?.serviceCard;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to Close Speciality Card.
   * Hiding Speciality Card and Provider Details Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  onShowPreviousState() {
    this.cardSelected.emit(ProviderCard.MORE_DETAILS);
  }
}
