import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DataService {
  private idleTimeResetAction: boolean = null;
  private idleTimeResetActionClick = new BehaviorSubject<any>(this.idleTimeResetAction);
  private setTitle = new Subject<any>();

  setPageTitle(data: any) {
    this.setTitle.next(data);
  }

  resetIdleTimeoutClick(data: boolean) {
    this.idleTimeResetActionClick.next(data);
  }

  get pageTitle() {
    return this.setTitle.asObservable();
  }
}
