import { NgModule } from '@angular/core';
import { NameValidatorDirective } from './nameValidatorDir';
import { RecaptchaDirective } from './recaptchaDir';
import { SafeHtmlDirective } from './safeHtmlDir';

@NgModule({
  imports: [],
  declarations: [SafeHtmlDirective, NameValidatorDirective, RecaptchaDirective],
  exports: [SafeHtmlDirective, NameValidatorDirective, RecaptchaDirective]
})
export class CommonDirectivesModule { }
