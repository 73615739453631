import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-online-pharmacy-label',
  templateUrl: './onlinePharmacyLabel.html'
})

export class OnlinePharmacyLabelComponent {
  @Input() title: string;
  @Input() tooltipMsg: string;
  @Input() ariaLabel: string;
}
