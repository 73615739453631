import { Inject, Injectable } from '@angular/core';
import { IBootstrapRequest, IBootstrapResponse } from '../interfaces/iBootstrap';
import { IRuleTrigger } from '../interfaces/iRuleTrigger';
import { UtilityService } from '../services/utilitySvc';
import { AppSession } from './../../../common/values/appSession';

@Injectable()
export class BootstrapHandler {
  constructor(
    private _utilitySvc: UtilityService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  /**
   * Fetches the bootstrap data for the application session.
   * It uses the UtilityService to get the bootstrap data based on the current application session state.
   * @returns {Promise<IBootstrapResponse>} A promise that resolves to the bootstrap response.
   */
  getBootstrap(): Promise<IBootstrapResponse> {
    const isSecureState = this._appSession.isSecureState;
    return this._utilitySvc.getBootstrap(isSecureState, this.buildRequest());
  }

  /**
   * Builds the bootstrap request object based on the current application session metadata.
   * It constructs the request with necessary criteria such as states, brands, mbus, prefixes, and groups.
   * @returns {IBootstrapRequest} The constructed bootstrap request object.
   */
  private buildRequest(): IBootstrapRequest {
    return {
      criteria: {
        brand: this._appSession.metaData?.brandCd ? [this._appSession.metaData.brandCd] : []
      } as IRuleTrigger
    } as IBootstrapRequest;
  }
}
