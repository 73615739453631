import { Inject, Injectable } from '@angular/core';
import { Locale } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { ProviderSpecialityService } from '../../common/services/providerSpecialitySvc';
import { ISpecialityRequest, ISpecialityResponse } from '../interfaces/iProviderSpeciality';

@Injectable({
  providedIn: 'root'
})
export class ProviderSpecialityHandler {
  locale: string;

  constructor(
    private _providerSpecialitySvc: ProviderSpecialityService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  async getProviderSpeciality(specialtyCategories: Array<string>, taxonomies: Array<string>, typeCodes: Array<string>): Promise<ISpecialityResponse> {
    try {
      const specialityRequest = this.buildRequest(specialtyCategories, taxonomies, typeCodes);
      const apiResponse = await this._providerSpecialitySvc.getProviderSpeciality(specialityRequest);
      return apiResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private buildRequest(specialtyCategories: string[], taxonomies: string[], typeCodes: string[]): ISpecialityRequest {
    const locale = this._appSession?.metaData?.locale?.toString() || Locale.ENGLISH.toString();

    return {
      taxonomyList: taxonomies || [],
      specialtyCategoryFilter: specialtyCategories || [],
      providerTypeCodeList: typeCodes || [],
      locale: locale
    };
  }
}
