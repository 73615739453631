import { Component, EventEmitter, Inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IAdvocateDetails } from '../interfaces/iCareCircle';
import { BaseComponent } from './../../common/components/base-component/baseCmp';
import { EventHandler } from './../../common/services/eventHandler';
import { AppSession } from './../../common/values/appSession';
import { ContentHelper } from './../../common/values/contentHelper';
import { CareCircleUtil } from './../utilities/careCircleUtil';

@Component({
  moduleId: module.id,
  selector: 'app-care-circle-advocate-details-cmp',
  templateUrl: '../views/careCircleAdvocateDetailsCmp.html'
})
export class CareCircleAdvocateDetailsComponent extends BaseComponent {
  @Input()
  selectedAdvocate: IAdvocateDetails;
  @Output()
  refreshContainer: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('advocateDetailModal')
  advocateDetailModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    route: ActivatedRoute,
    contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _careCircleUtil: CareCircleUtil
  ) {
    super(
      route,
      _eventHandler,
      _appSession,
      contentHelper,
      'CareCircleContainerComponent'
    );
  }

  /** Open Side Panel */
  openSidePanel(direction: any) {
    this.sidePanelRef = this._sidePanel.open(direction, this.advocateDetailModal);
  }

  /** Event handler for click of close button  */
  onClose() {
    this.sidePanelRef.close();
  }

  navigateToIdCardPage() {
    this.onClose();
    this._careCircleUtil.navigateToIdCardPage();
  }
}
