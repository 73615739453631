import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, ViewRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppEvents } from './../../enums/appEvents';
import { IEventDetail } from './../../interfaces/iEventDetail';
import { EventHandler } from './../../services/eventHandler';

// Replaces Information, Negative and Positive Alert Components.

@Component({
  selector: 'app-common-alert',
  templateUrl: './commonAlertCmp.html'
})
export class CommonAlertComponent implements AfterContentInit {

  @Input() content: any;
  @Input() type: string;

  alertData: any;
  commonAlert: any;
  showError: boolean = false;

  private _pfAppErrorEvent: EventEmitter<IEventDetail>
    = this._eventHandler.get(AppEvents[AppEvents.GLOBAL_ERROR]);
  private navigationSubscription;

  constructor(
    private _eventHandler: EventHandler,
    private ref: ChangeDetectorRef,
    private _router: Router) {
    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initializeInvites();
      }
    });
  }

  ngAfterContentInit() {
    if (this.content != null) {
      this.alertData = this.content;
    }
    this.commonAlert = {
      alertType: this.type
    };

    this._pfAppErrorEvent.subscribe((detail: IEventDetail) => {
      if (detail.type === AppEvents[AppEvents.PF_APP_ALERT]) {
        this.setAlert(detail);
      }
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  initializeInvites() {
    this.showError = false;
  }

  setAlert(detail: IEventDetail) {
    this.showError = true;
    this.alertData = detail.message;

    if (this.ref !== null &&
      this.ref !== undefined &&
      !(this.ref as ViewRef).destroyed ) {
      this.ref.detectChanges();
    }
  }

}
