import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../components/base-component/baseCmp';
import { ISSOConfig } from '../models/vitalsSSO';
import { EventHandler } from '../services/eventHandler';
import { AppSession } from '../values/appSession';
import { ContentHelper } from '../values/contentHelper';

@Component({
  moduleId: module.id,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[vitals-sso-link-cmp]',
  templateUrl: './vitalsSSOLinkCmp.html'
})
export class VitalsSSOLinkComponent extends BaseComponent {
  @Input()
  ariaLabel: string;
  @Input()
  pdtStrKey: string;
  @Input()
  ssoContent: ISSOConfig;
  @Input()
  index: number;
  @Input()
  btnClass: string;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, '');
  }

  @Input()
  set config(value: ISSOConfig) {
    if (this.ssoContent !== value) {
      this.ssoContent = value;
    }
  }

  get config(): ISSOConfig {
    return this.ssoContent;
  }
}
