import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { InfoListModule } from '../info-list/pfInfoListModule';
import { LookUpListModule } from '../lookup-list/pfLookUpListModule';
import { CommonDirectivesModule } from './../../directives/commonDirectivesModule';
import { RadioGroupModule } from './../radio-group/radioGroupModule';
import { OutOfNetworkCostEstimateComponent } from './outOfNetworkCostEstimateCmp';
import { OutOfNetworkPricingComponent } from './outOfNetworkPricingTabCmp';
import { PFProcServiceLimitCmp } from './pfProcServiceLimitCmp';
import { RecognitionSlideOutComponent } from './recognitionSlideOutCmp';
import { CptSearchSlideOutComponent } from './searchSlideOutCmp';
import { SummaryTabComponent } from './summaryTabCmp';
@NgModule({
    declarations: [CptSearchSlideOutComponent, SummaryTabComponent, OutOfNetworkPricingComponent, OutOfNetworkCostEstimateComponent, RecognitionSlideOutComponent, PFProcServiceLimitCmp],
    exports: [CptSearchSlideOutComponent, SummaryTabComponent, OutOfNetworkPricingComponent, OutOfNetworkCostEstimateComponent, RecognitionSlideOutComponent, PFProcServiceLimitCmp],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule,
        LookUpListModule,
        InfoListModule,
        RadioGroupModule,
        CommonDirectivesModule
    ],
    providers: []
})
export class CptSlidePanelModule { }
