import { IAppConfig } from '../../findcare/common/interfaces/iAppConfig';

export class FcConfig {
  // this config data is as per the NLZ standards only. If any changes in the standards, this data should be updated accordingly.
  // E.g: ProviderFinder URLs, Brand URLs, etc. could be different from baseUrls in structure. So, they are kept separately inside the JSON file.
  // for any variations in particular to any environment, update it in the respective environment config files in same structure.
  // baseUrls for public and secure must be there in the environment config files.
  static get CONFIG(): IAppConfig {
    return {
      web: {
        idleTimeLength: 780,
        sessionTimeoutLength: 120
      },
      sso: {
        baseUrl: '/member'
      },
      wcsApi: {
        baseUrl: '/assets/wcs/',
        baseDocumentURL: '/assets/documents/',
        enableLocalContent: false,
        contentPath: {
          en_US: 'fad_{ROUTE-PATH}',
          es_US: 'fad_{ROUTE-PATH}_es'
        }
      },
      bingMaps: {
        baseUrl: 'https://dev.virtualearth.net/REST/v1/',
        apiKey: 'AnUE73t9h-Fg9gM2QdN9JrzlRvlQlI2zaEDviBrZB64gXL77GUpxIM6Nbj78q6TG',
        locations: 'Locations',
        scriptUrl: 'https://www.bing.com/api/maps/mapcontrol?callback=__onBingLoaded&branch=experimental',
        icons: {
          activePin: 'https://findcare.anthem.com/assets/images/common/dark-pin.png',
          inactivePin: 'https://findcare.anthem.com/assets/images/common/light-pin.png'
        }
      },
      recaptcha: {
        baseUrl: 'https://www.google.com/recaptcha/api.js',
        siteKey: '6LdgNyoUAAAAALsZnMdYvRXWFFaIeNi0trikjq4G'
      },
      images: {
        qualityBaseUrl: '/assets/images/qualitydesignation/',
        commonBaseUrl: '/assets/images/common/',
        brandBaseUrl: '/assets/images/brands/',
        semBaseUrl: 'https://membersecure{ENV}.anthem.com/semassets/'
      },
      cvsPharmacy: {
        ABC: 'https://membersecure{ENV}.anthem.com/member/pharmacy/find-pharmacy',
        ABCBS: 'https://membersecure{ENV}.anthem.com/member/pharmacy/find-pharmacy',
        EBCBS: 'https://membersecure{ENV}.empireblue.com/member/pharmacy/find-pharmacy',
        EBC: 'https://membersecure{ENV}.empireblue.com/member/pharmacy/find-pharmacy'
      },
      esiPharmacy: {
        ABC: 'https://membersecure{ENV}.anthem.com/member/pharmacy',
        ABCBS: 'https://membersecure{ENV}.anthem.com/member/pharmacy',
        EBCBS: 'https://membersecure{ENV}.anthem.com/member/pharmacy',
        EBC: 'https://membersecure{ENV}.anthem.com/member/pharmacy'
      },
      providerFinder: {
        baseUrl: {
          public: {
            abc: 'https://findcare{ENV}.anthem.com',
            abcbs: 'https://findcare{ENV}.anthem.com',
            amv: 'https://findcare{ENV}.amerigroup.com',
            shc: 'https://findcare{ENV}.simplyhealthcareplans.com',
            unicare: 'https://findcare{ENV}.unicare.com',
            ebc: 'https://findcare{ENV}.empireblue.com',
            ebcbs: 'https://findcare{ENV}.empireblue.com',
            healthblue: 'https://findcare{ENV}.healthybluela.com',
            hbnc: 'https://findcare{ENV}.bcbsdirect.com',
            bluema: 'https://findcare{ENV}.bluemedadvgrhs.com',
            hbmo: 'https://findcare{ENV}.healthybluemo.com',
            hbncm: 'https://findcare{ENV}.healthybluenc.com',
            wlp: 'https://findcare{ENV}.wellpoint.com',
            abcny: 'https://findcare{ENV}.anthembluecross.com',
            txdcm: 'https://findcare{ENV}.mydellchildrens.com',
            unimass: 'https://findcare{ENV}.unicaremass.com'
          },
          secure: {
            abc: 'https://findcaresecure{ENV}.anthem.com',
            abcbs: 'https://findcaresecure{ENV}.anthem.com',
            amv: 'https://findcaresecure{ENV}.amerigroup.com',
            shc: 'https://findcaresecure{ENV}.simplyhealthcareplans.com',
            unicare: 'https://findcaresecure{ENV}.unicare.com',
            ebc: 'https://findcaresecure{ENV}.empireblue.com',
            ebcbs: 'https://findcaresecure{ENV}.empireblue.com',
            healthblue: 'https://findcaresecure{ENV}.healthybluela.com',
            hbnc: 'https://findcaresecure{ENV}.bcbsdirect.com',
            bluema: 'https://findcaresecure{ENV}.bluemedadvgrhs.com',
            hbmo: 'https://findcaresecure{ENV}.healthybluemo.com',
            hbncm: 'https://findcaresecure{ENV}.healthybluenc.com',
            wlp: 'https://findcaresecure{ENV}.wellpoint.com',
            AGP: 'https://findcaresecure{ENV}.amerigroup.com',
            SMPLY: 'https://findcaresecure{ENV}.simplyhealthcareplans.com',
            WNY: 'https://findcaresecure{ENV}.anthem.com',
            CLEAR: 'https://findcaresecure{ENV}.simplyhealthcareplans.com',
            hbl: 'https://findcaresecure{ENV}.healthybluela.com'
          }
        },
        vitalsBase: 'https://wellpoint-uat.prs.mdxdata.com/reviews/Doctor/',
        authToken: true
      },
      paginationSize: {
        summaryPrintPage: 100,
        summaryPage: 20,
        hospitalAffiliationPage: 2000,
        groupAffiliationPage: 2000
      },
      chineseMotionPointUrl: {
        ABC: 'https://zh.cn.anthem.com/ca/find-doctor/',
        EBCBS: 'https://zh.cn.empireblue.com/find-doctor/',
        EBC: 'https://zh.cn.empireblue.com/find-doctor/'
      },
      features: {
        lookUp: {
          inputLength: 3,
          inputMaxLength: 150,
          minCount: 5
        },
        freeTextSearch: {
          descriptionMaxLength: 100
        },
        pillFilter: {
          minCount: 6,
          deselectionDefaultCount: 5,
          accordionDefaultExpand: 3
        }
      },
      MedicaidState: {
        en_US: {
          state: {
            CA: 'https://mss.anthem.com/california-medicaid/care/find-a-doctor.html',
            IN: 'https://mss.anthem.com/in/care/find-a-doctor.html',
            KY: 'https://mss.anthem.com/ky/care/find-a-doctor.html',
            NV: 'https://mss.anthem.com/nevada-medicaid/care/find-a-doctor.html',
            VA: 'https://mss.anthem.com/va/care/find-a-doctor.html',
            WI: 'https://mss.anthem.com/wi/care/find-a-doctor.html',
            CAMMP: 'https://mss.anthem.com/california-mmp/care/find-a-doctor.html',
            NY: 'https://mss.empireblue.com/ny/care/find-a-doctor.html'
          }
        },
        es_US: {
          state: {
            CA: 'https://es-mss.anthem.com/california-medicaid/care/find-a-doctor.html',
            IN: 'https://es-mss.anthem.com/in/care/find-a-doctor.html',
            KY: 'https://es-mss.anthem.com/ky/care/find-a-doctor.html',
            NV: 'https://es-mss.anthem.com/nevada-medicaid/care/find-a-doctor.html',
            VA: 'https://es-mss.anthem.com/va/care/find-a-doctor.html',
            WI: 'https://es-mss.anthem.com/wi/care/find-a-doctor.html',
            CAMMP: 'https://es-mss.anthem.com/california-mmp/care/find-a-doctor.html',
            NY: 'https://es-mss.empireblue.com/ny/care/find-a-doctor.html'
          }
        }
      },
      datadog: {
        rumConfiguration: {
          applicationId: '99a5f20d-d193-4a35-9a49-7e73481126da',
          clientToken: 'pub07916788abb7120892d71678d30607e3',
          env: 'dev1',
          silentMultipleInit: true,
          site: 'datadoghq.com',
          service: 'sydney-precare',
          sessionReplaySampleRate: 5,
          sessionSampleRate: 10,
          trackLongTasks: true,
          trackResources: true,
          trackUserInteractions: true
        },
        enabled: false
      },
      lookupResultSortOrder: ['specialtyList', 'providerList', 'procedureList', 'providerLicenseList', 'aoeList', 'ofcSrvcList', 'providerNpiList', 'cptList']
    };
  }

  // getPFBrandUrls() is a helper function to get the public & secure urls based on the environment
  static getPFBrandUrls(baseUrl: Record<string, any>, env: string): { public: Record<string, string>; secure: Record<string, string> } {
    const updatedUrls: { public: Record<string, string>; secure: Record<string, string> } = { public: {}, secure: {} };

    Object.keys(baseUrl).forEach((key) => {
      const urls = baseUrl[key];
      const envUrl = this.getEnvUrls(urls, env);
      updatedUrls[key] = envUrl;
    });

    return updatedUrls;
  }

  // getEnvUrls() is a helper function to get the urls based on the environment
  static getEnvUrls(urls: Record<string, string>, env: string): Record<string, string> {
    const updatedUrls = Object.keys(urls).reduce(
      (acc, key) => {
        acc[key] = urls[key].replace('{ENV}', env);
        return acc;
      },
      {} as Record<string, string>
    );

    return updatedUrls;
  }
}
