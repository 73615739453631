import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppSession } from '../../../common/values/appSession';
import { IAffiliationRequest, IAffiliationResponse } from '../../result/interfaces/iProviderAffiliation';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';

@Injectable({
  providedIn: 'root'
})
export class ProviderAffiliationService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  getProviderAffiliation(request: IAffiliationRequest): Promise<IAffiliationResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.affiliationsV2,
      data: request,
      headers: this.getHeaders()
    });
  }
}
