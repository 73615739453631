import { EventEmitter, Injectable } from '@angular/core';
import { ContentHelper } from '../../values/contentHelper';
/// <reference types="bingmaps"/>

@Injectable()
export class MapOverlayHelper {
  virtualOptionsClick = new EventEmitter<MouseEvent>();

  constructor(private _contentHlpr: ContentHelper) {
  }

  createOverlay(): Microsoft.Maps.CustomOverlay {
    const overlayHlpr: MapOverlayHelper = this;
    const conetnt = this._contentHlpr.getContent('PFSearchResultsContainerComponent');

    //Create an inner class to avoid run time errors
    class MapOverlay extends Microsoft.Maps.CustomOverlay {
      private virtualOptionsBtn: HTMLInputElement;

      constructor() {
        super({ beneathLabels: false });
        this.create();
      }

      private create() {
        this.virtualOptionsBtn = document.createElement('input');
        this.virtualOptionsBtn.type = 'button';
        this.virtualOptionsBtn.value = conetnt?.summaryComponent?.virtualOptions;
        this.virtualOptionsBtn.setAttribute('class', 'btn-map-overlay');
        this.virtualOptionsBtn.onclick = (event) => {
          overlayHlpr.virtualOptionsClick.emit(event);
        }
      }

      onAdd() {
        //Create a div that will hold the buttons
        let container: HTMLDivElement = document.createElement('div');
        container.appendChild(this.virtualOptionsBtn);
        container.setAttribute('class', 'map-overlay-container');
        this.setHtmlElement(container);
      }
    }
    return new MapOverlay();
  }
}
