import { CommonModule, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { ActionItemModule } from '../../../app/common/components/action-items/actionItemModule';
import { CustomPipesModule } from './../../common/pipes/customPipesModule';
import { PFPromotedProceduresContainerComponent } from './components/pfPromotedProceduresContainerCmp';
import { PFPromotedProceduresTileComponent } from './components/pfPromotedProceduresTileCmp';

@NgModule({
    imports: [UxSharedModule, FormsModule, ReactiveFormsModule, CommonModule, ActionItemModule, CustomPipesModule],
    declarations: [PFPromotedProceduresContainerComponent, PFPromotedProceduresTileComponent],
    exports: [PFPromotedProceduresContainerComponent, PFPromotedProceduresTileComponent]
})
export class PFPromotedProceduresModule {
  static forRoot(): ModuleWithProviders<PFPromotedProceduresModule> {
    return {
      ngModule: PFPromotedProceduresModule,
      providers: [TitleCasePipe]
    };
  }
}
