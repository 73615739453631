import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IRadioInput } from '@anthem/uxd/util';
import { IActionReasons, IFeedbackRadioInput } from '../../../fad/search-providers/interfaces/iQuickLinks';
import { ACTION_TAKEN, REASONTYPE_FREEFORM, SIDE_PANEL_STATUS } from '../../constants/app-constants';
import { IActionId, IUserInteraction } from '../../interfaces/iUserInteraction';
import { DataHelper } from '../../services/dataHelper';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-feedback-side-panel-cmp',
  templateUrl: './feedbackSidePanelCmp.html'
})

export class FeedBackSidePanelComponent extends BaseComponent implements OnInit {
  feedbackSidePanelRef: ModalRef<HTMLElement>;
  @ViewChild('feedbackPanel')
  feedbackSidePanel: TemplateRef<HTMLElement>;
  @Input()
  reasonCodeList: Array<IActionReasons> = [];
  @Input() interactionPayload: IUserInteraction;
  reasonCodeHeader: string;
  reasonCodeDescription: string;
  selectedReasonCodeId: string = '';
  otherReasonCodeText: string = '';
  positiveFeedBack: boolean = false;
  showReasonTextBox: boolean = false;
  reasonCodeOptions: Array<IRadioInput>;
  sendFeedback: string;
  cancelFeedback: string;
  backArrowTile: string;
  @Output('feedbackSidePanelStatus') readonly sidePanelStatus = new EventEmitter<SIDE_PANEL_STATUS>();

  constructor(private _sidePanel: SidePanel,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _dataHelper: DataHelper,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      'PFSearchContainerComponent'
    );
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  onClose() {
    this.feedbackSidePanelRef.close();
    this.showReasonTextBox = false;
    this.sidePanelStatus.emit(SIDE_PANEL_STATUS.CLOSE);
  }

  openSlideOutPanel(reasonCodeList: Array<IActionReasons>, positiveFeedBack: boolean) {
    this.selectedReasonCodeId = reasonCodeList[0].actionReasonCode;
    this.otherReasonCodeText = '';
    this.reasonCodeOptions = reasonCodeList.map<IFeedbackRadioInput>((reasonCode) => ({
      id: 'reason-code-id-' + reasonCode.actionReasonCode,
      name: 'reason-code-name-' + reasonCode.actionReasonCode,
      value: reasonCode.actionReasonCode,
      label: reasonCode.actionReasonMsg,
      actionReasonType: reasonCode.actionReasonType
    }));
    this.positiveFeedBack = positiveFeedBack;
    this.getAnalytics();
    this.setReasonCodeValues();
    this.feedbackSidePanelRef = this._sidePanel.open('right', this.feedbackSidePanel);
    this.sidePanelStatus.emit(SIDE_PANEL_STATUS.OPEN);
  }

  getAnalytics() {
    this.sendFeedback = this.positiveFeedBack
      ? this.content.hideQuickLinkShopForProcedure.iAmInterested.dataAnalytics
          .sendFeedbackIntModalFindCare
      : this.content.hideQuickLinkShopForProcedure.notInterested.dataAnalytics
        .sendFeedbackNotIntModalFindCare;
    this.cancelFeedback = this.positiveFeedBack
      ? this.content.hideQuickLinkShopForProcedure.iAmInterested.dataAnalytics
          .cancelFeedbackIntModalFindCare
      : this.content.hideQuickLinkShopForProcedure.notInterested.dataAnalytics
        .cancelFeedbackNotIntModalFindCare;
    this.backArrowTile = this.positiveFeedBack
      ? this.content.hideQuickLinkShopForProcedure.iAmInterested.dataAnalytics
        .backFeedbackIntModalFindCare
      : this.content.hideQuickLinkShopForProcedure.notInterested.dataAnalytics
        .backFeedbackNotIntModalFindCare;
  }

  setReasonCodeValues() {
    this.reasonCodeHeader = this.positiveFeedBack ? this.content.hideQuickLinkShopForProcedure.iAmInterested.header : this.content.hideQuickLinkShopForProcedure.notInterested.header;
    this.reasonCodeDescription = this.positiveFeedBack ? this.content.hideQuickLinkShopForProcedure.iAmInterested.description : this.content.hideQuickLinkShopForProcedure.notInterested.description;
  }

  onReasonCodeChange(reason: IFeedbackRadioInput) {
    this.showReasonTextBox = false;
    this.selectedReasonCodeId = reason.value;
    if (reason.actionReasonType === REASONTYPE_FREEFORM) {
      this.showReasonTextBox = true;
    }
  }

  getInteractionPayLoad(): string | undefined {
    let actionIdObj = {} as IActionId;
    this.interactionPayload.actionTaken = this.positiveFeedBack ? ACTION_TAKEN.acknowledged : ACTION_TAKEN.archived;
    actionIdObj = this.interactionPayload;
    actionIdObj.actionReasonCode = this.selectedReasonCodeId;
    if (!this._dataHelper.isEmptyString(this.otherReasonCodeText)) {
      actionIdObj.actionReasonTxt = this.otherReasonCodeText;
    }
    return this.userInteractionUtil.getUserInteraction(actionIdObj, this.interactionPayload);
  }
}
