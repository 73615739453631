import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ProcedureList } from "../../search-providers/interfaces/ftsProcedureList";
import { ITranslation } from "./../../search-providers/interfaces/iAdaListResp";
import { IFtsSuggestion } from "./../../search-providers/interfaces/iFtsClientInteraction";

@Injectable({ providedIn: "root" })
export class AvailableSuggestionService {
  public suggestions: IFtsSuggestion<ITranslation>[] = undefined;
  private availableSuggestions = new BehaviorSubject<any>(this.suggestions);

  public procedureList: ProcedureList[] = undefined;
  private alternateSearchOptions = new BehaviorSubject<any>(this.procedureList);

  get getSuggestions() {
    return this.availableSuggestions.asObservable();
  }

  get getProcedureList() {
    return this.alternateSearchOptions.asObservable();
  }

  setSuggestion(suggestions: IFtsSuggestion<ITranslation>[]) {
    this.availableSuggestions.next(suggestions);
  }
  setProcedureList(procedureList: ProcedureList[]) {
    this.alternateSearchOptions.next(procedureList);
  }
}
