import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { EventHandler } from "../../../common/services/eventHandler";
import { AppSession } from "../../../common/values/appSession";
import { ContentHelper } from "../../../common/values/contentHelper";
import { IFilter } from "../interfaces/iAppliedFilters";
import { AppliedFilterService } from "../services/appliedFilterSvc";
import { BaseComponent } from "./../../../common/components/base-component/baseCmp";

@Component({
  moduleId: module.id,
  selector: "app-fad-filter-deselection-cmp",
  templateUrl: "../views/pfFilterDeselectionCmp.html",
  providers: [],
})
export class PFFilterDeselectionComponent
  extends BaseComponent
  implements OnInit
{
  allFilters: IFilter[] = [];
  filters: IFilter[] = [];
  showMoreText: string;
  showMore: boolean = true;
  showMoreCount: number;
  hasMoreFilters: boolean = false;
  private appliedFilter: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _appliedFilterService: AppliedFilterService
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchResultsContainerComponent"
    );

    this.appliedFilter = this._appliedFilterService.getAppliedFilters.subscribe(
      (data) => {
        if (data) {
          this.loadFilters(data);
        }
      }
    );
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.showMoreCount = this._appSession.appConfig?.features?.pillFilter?.deselectionDefaultCount ?? 5;
  }

  ngOnDestroy() {
    if (this.appliedFilter) {
      this.appliedFilter.unsubscribe();
    }
  }

  removeFilter(filter: IFilter) {
    this._appliedFilterService.removeFilter(filter);
  }

  showMoreFilters() {
    this.showMore = false;
    this.filters = this.allFilters;
  }

  showLessFilters() {
    this.showMore = true;
    this.filters = this.allFilters.slice(0, this.showMoreCount);
  }

  private loadFilters(appliedFilters: Map<string, IFilter[]>) {
    this.showMore = true;
    this.filters = [];
    this.allFilters = [];
    appliedFilters?.forEach((values: IFilter[], key: string) => {
      values?.forEach((value) => {
        this.allFilters.push({
          name: value.name,
          value: value.value,
          category: key,
        } as IFilter);
      });
    });

    this.filters = this.allFilters.slice(0, this.showMoreCount);
    const moreFilterCount = this.allFilters.length - this.showMoreCount;
    this.showMoreText =
      this.content.summaryComponent.filter.showMoreFilters.replace(
        /{COUNT}/gi,
        moreFilterCount
      );
    this.hasMoreFilters = moreFilterCount > 0;
  }
}
