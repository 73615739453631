import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { AppSession } from '../values/appSession';
import { AppConstants } from './../constants/app-constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(AppSession) private appSession:AppSession) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request && request.url && (request.url.toLowerCase().indexOf('/fad/api/'.toLowerCase()) >= 0 || request.url.toLowerCase().indexOf('/precare/api/'.toLowerCase()) >= 0) &&
      this.appSession.appConfig?.environment && this.appSession.appConfig?.environment.toLocaleUpperCase() !== AppConstants.ENV_LOCAL) {
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request);
  }
}
