import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IOutputFlags } from '../models/iDetailsParameters';
import { ProviderOrchestrationService } from '../services/providerOrchestrationSvc';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { IAddress } from './../models/iAddress';
import { IProviderLocation } from './../models/iProviderLocation';
import { ISelectedProvider } from './../models/iSelectedProvider';
import { ProviderDetailsNavigationService } from './../services/providerDetailsNavigationSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fad-provider-affiliation-cmp',
  templateUrl: '../views/pfAffiliationCmp.html'
})
export class PFAffiliationComponent extends BaseComponent implements OnInit {
  @Input()
  showAffiliationLoader: boolean = true;
  @Input()
  selectedProvider: any;
  @Input()
  inputIndex = 0;
  @Input()
  selectedAddressId?: any;
  @Input()
  ariaLabel?: any;
  @Input()
  inNetworkList: any;
  providerDetail: any;
  affiliationResult: any;
  showAffiliationError: boolean = false;
  affliationMedicalGroup: any = [];
  affliationHospital: any = [];
  showPCPDisclaimer: boolean = false;
  pcpDisclaimerText: any;
  isOpened: boolean = false;

  constructor(private _providerOrchestrationService: ProviderOrchestrationService,
              private _route: ActivatedRoute,
              private _eventHandler: EventHandler,
              @Inject(ContentHelper)
              private _contentHelper: ContentHelper,
              private _navigationService: NavigationService,
              @Inject(AppSession)
              private _appSession: AppSession,
              private _providerDetailsNavigationService: ProviderDetailsNavigationService) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }
  ngOnChanges(args: any) {
    if (args['selectedAddressId'] &&
      args['selectedAddressId'].currentValue !== args['selectedAddressId'].previousValue) {
      this.getAffiliationDetails(args['selectedAddressId'].currentValue);
    }
  }

  onAffliateDropdownChanges(isOpened: boolean) {
    this.isOpened = isOpened;
  }

  getAffiliationDetails(changedAddressId) {
    const alphaPrefix = [];
    if (this._appSession.searchParams.plan && this._appSession.searchParams.plan.alphaPrefix) {
      alphaPrefix.push(this._appSession.searchParams.plan.alphaPrefix);
    }
    const addrId = this.selectedProvider.location && this.selectedProvider.location.address
      ? this.selectedProvider.location.address.addressId : '';
    let reqObj: any = {
      providerIdentifier: this.selectedProvider.providerIdentifier,
      addressIdentifier: changedAddressId === '' ? addrId : changedAddressId,
      sourceSystemCode: '0',
      isDoctorAffiliation: false, // need to write the logic
      networkList: this._appSession.searchParams.plan.networkList || [],
      alphaPrefixList: alphaPrefix,
      inNetworkList: this.inNetworkList
    };
    if (this._appSession.isMemberMedicalGrpSearch &&
      this._appSession.primeGroupAffiliations?.affiliationList?.length) {
      const memberParams = {
        mbrUid: this._appSession.metaData?.appContract?.mbrUid,
        contractUid: this._appSession.metaData?.appContract?.contractUid,
        outputFlags: { includePrimeGroupSearch: true } as IOutputFlags
      };
      reqObj = Object.assign(reqObj, memberParams);
    }

    if (this.selectedProvider.providerTypeCodeList) {
      reqObj.providerTypeCodeList = this.selectedProvider.providerTypeCodeList;
    }

    this._providerOrchestrationService.getAffiliationDetails(reqObj).then(
      (result: any) => {
        this.affiliationResult = result.affiliationSummaryList;
        this.showPCPDisclaimer = result.showPCPDisclaimer;
        this.pcpDisclaimerText = this.WcsUtility.getPCPDisclaimerText(result.pcpCount);
        this.showAffiliationLoader = false;
      },
      (error: any) => {
        try {
          this.onError(error);
          this.showAffiliationLoader = false;
        } catch (e) { }
      });
  }

  onError(error: any) {
    this.showAffiliationLoader = false;
    this.showAffiliationError = true;
    throw error;
  }

  showProviderDetails(affliationDetails: any) {
    if (affliationDetails) {
      const selectedProvider = {} as ISelectedProvider;

      selectedProvider.addressIdentifier = affliationDetails.addressIdentifier;
      selectedProvider.providerIdentifier = affliationDetails.providerIdentifier;
      selectedProvider.providerName = affliationDetails.providerName;
      selectedProvider.networkRegisteredName = affliationDetails.networkRegisteredName;
      selectedProvider.providerTypeCodeList = affliationDetails.providerTypeCodeList;
      selectedProvider.titleList = affliationDetails.titleList;

      if (affliationDetails.locations && affliationDetails.locations.length > 0) {
        selectedProvider.location = {} as IProviderLocation;
        selectedProvider.location.address = {} as IAddress;

        selectedProvider.location.address.addressId = affliationDetails.locations[0].addressId;
        selectedProvider.location.address.latitude = affliationDetails.locations[0].latitude;
        selectedProvider.location.address.longitude = affliationDetails.locations[0].longitude;
      }
      this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
    }
  }

  getAffiliationType(affiliationType: string) {
    return this.WcsUtility.getAffiliationType(affiliationType);
  }

  get showAffiliationLink() {
    return this._providerDetailsNavigationService.showProviderLink && !this._appSession.isEyc;
  }
}
