import { PayLoad } from './payload';

export class PayloadEvent {
  eventTime: string;
  sourceType: string;
  eventType: string;
  client: string;
  payload: PayLoad;
}

export enum SOURCE_TYPE {
  PROVIDER_SEARCH = 'providerSearch'
}

export enum EVENT_TYPE {
  PROVIDER_SEARCH = 'providerSearchEvent'
}

export class EventConstant {
  static get CLIENT(): string { return 'web'; }
}
