import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { RIGHT } from '../../../common/constants/app-constants';
import { OON_ADOBE_TAGS } from '../../../fad/search-results/values/providerSearchConstants';

declare let _satellite: any;
@Component({
  moduleId: module.id,
  selector: 'app-oon-details',
  templateUrl: './oonDetailsCmp.html',
  providers: []
})

export class OONDetailsComponent {
  @Input()
  content: any;
  @Input()
  open: boolean = false;
  @ViewChild('oonLearnMore')
  oonLearnMoreSlider: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  adobeTags = OON_ADOBE_TAGS;

  constructor(private _sidePanel: SidePanel) {}

  openSidePanel() {
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.oonLearnMoreSlider);
    if (typeof _satellite !== "undefined") {
      _satellite.track(this.adobeTags.outNetModalViewedFindCare);
    }
  }

  closeSidePanel() {
    this.sidePanelRef.close();
  }
}
