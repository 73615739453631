import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccordianModule } from '@anthem/uxd/accordion';
import { AlertHelper, AlertModule } from '@anthem/uxd/alert';
import { ButtonModule } from '@anthem/uxd/button';
import { CalloutModule } from '@anthem/uxd/callout';
import { CarouselModule } from '@anthem/uxd/carousel';
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { UxDeprecatedModule, UxModalHelper } from '@anthem/uxd/deprecated';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { FormGroupModule } from '@anthem/uxd/form-group';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { InputModule } from '@anthem/uxd/input';
import { JumpToModule } from '@anthem/uxd/jump-to';
import { MediaModule } from '@anthem/uxd/media';
import { Modal, ModalModule, SidePanel } from '@anthem/uxd/modal';
import { NavigationModule } from '@anthem/uxd/navigation';
import { OverlayModule } from '@anthem/uxd/overlay';
import { RadioModule } from '@anthem/uxd/radio';
import { TabsModule, TabsModuleV2 } from '@anthem/uxd/tabs';
import { ToggleSwitchModule } from '@anthem/uxd/toggle-switch';
import { TooltipModule } from '@anthem/uxd/tooltip';
import { UxHelper } from '@anthem/uxd/util';

@NgModule({
    imports: [
        AlertModule,
        AccordianModule,
        ButtonModule,
        CalloutModule,
        CarouselModule,
        CheckboxModule,
        UxDeprecatedModule,
        DropdownModule,
        FormGroupModule,
        IndicatorModule,
        InputModule,
        JumpToModule.forRoot(),
        ModalModule,
        NavigationModule,
        OverlayModule,
        RadioModule,
        TabsModule,
        TabsModuleV2,
        ToggleSwitchModule,
        TooltipModule,
        MediaModule
    ],
    declarations: [],
    providers: [],
    exports: [
        AlertModule,
        AccordianModule,
        ButtonModule,
        CalloutModule,
        CarouselModule,
        CheckboxModule,
        UxDeprecatedModule,
        DropdownModule,
        FormGroupModule,
        IndicatorModule,
        InputModule,
        JumpToModule,
        ModalModule,
        NavigationModule,
        OverlayModule,
        RadioModule,
        TabsModule,
        TabsModuleV2,
        ToggleSwitchModule,
        TooltipModule,
        MediaModule
    ]
})
export class UxSharedModule {
  static forRoot(): ModuleWithProviders<UxSharedModule> {
    return {
      ngModule: UxSharedModule,
      providers: [UxHelper, AlertHelper, UxModalHelper, Modal, SidePanel]
    };
  }
}
