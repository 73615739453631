import { Injectable, Injector } from '@angular/core';
import { PRECARE_SEARCH_SCENARIO, SEARCH_SCENARIOS, STRATEGY_SEARCH_FEATURE_FLAGS } from '../constants/strategy-search-constant';
import { DefaultPrecareSearch } from './defaultPrecareSearch';
import { PublicV1Specialty } from './publicV1SpecialtySvc';
import { PublicV1TccMedical } from './publicV1TccMedicalSvc';
import { SecureV1CptMedical } from './secureV1CptMedicalSvc';
import { SecureV1RxSpecialty } from './secureV1RxSpecialitySvc';
import { SecureV1Specialty } from './secureV1SpecialtySvc';
import { SecureV1TccDental } from './secureV1TccDentalSvc';
import { SecureV1TccMedical } from './secureV1TccMedicalSvc';

@Injectable({
  providedIn: 'root'
})
export class SearchSvc {
  public currentScenario: PRECARE_SEARCH_SCENARIO;
  constructor(private injector: Injector) {}

  getStrategy() {
    const featureFlag = STRATEGY_SEARCH_FEATURE_FLAGS[this.currentScenario];

    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    if (!featureFlag) {
      return this.injector.get(DefaultPrecareSearch);
    }
    switch (this.currentScenario) {
      case SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY:
        return this.injector.get(PublicV1Specialty);
      case SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL:
        return this.injector.get(PublicV1TccMedical);
      case SEARCH_SCENARIOS.SECURE_V1_SPECIALTY:
        return this.injector.get(SecureV1Specialty);
      case SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL:
        return this.injector.get(SecureV1TccDental);
      case SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL:
        return this.injector.get(SecureV1TccMedical);
      case SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY:
        return this.injector.get(SecureV1RxSpecialty);
      case SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL:
        return this.injector.get(SecureV1CptMedical);
    }
  }
}
