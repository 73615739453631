import { CommonModule, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFSearchCostEstimateSlideOutModule } from '../../../app/common/components/cost-slideout-panel/pfSearchCostEstimateSlideOutModule';
import { HealthwiseModule } from '../../common/components/healthwise/healthwiseModule';
import { LocationModule } from '../../common/components/location/locationModule';
import { OnlinePharmacyLabelModule } from '../../common/components/online-pharmacy-label/onlinePharmacyLabelModule';
import { PaginationModule } from '../../common/components/pagination/pfPaginationModule';
import { PillFilterModule } from '../../common/components/pill-filter/pillFilterModule';
import { PFProviderExperienceModule } from '../../common/components/provider-experience/pfProviderExperienceModule';
import { ScenarioAlertModule } from '../../common/components/scenarioAlert/pfScenarioAlertModule';
import { CptSlidePanelModule } from '../../common/components/search-slide-out/searchSlideOutModule';
import { VirtualCareLabelModule } from '../../common/components/virtual-care-label/virtualCareLabelModule';
import { CustomPipesModule } from '../../common/pipes/customPipesModule';
import { HoursMinutesPipe } from '../../common/pipes/hoursMinutesPipe';
import { RatingsSlideOutModule } from '../../common/ratings-slide-out/ratingsSlideOutModule';
import { CostSlideOutModule } from '../../eyc/cost-slide-out/costSlideOutModule';
import { PFCompareModule } from '../compare-providers/pfCompareModule';
import { PFDetailsModule } from '../provider-details/pfDetailsModule';
import { AssignCareModule } from './../../../app/common/components/assign-care/assignCareModule';
import { ControlListModule } from './../../../app/common/components/control-list/controlListModule';
import { DisclaimerModule } from './../../../app/common/components/disclaimers/pfDisclaimerModule';
import { HelpfulHintsModule } from './../../../app/common/components/helpfulhints/pfHelpfulHintsModule';
import { InfoListModule } from './../../../app/common/components/info-list/pfInfoListModule';
import { LeavingDisclaimerModule } from './../../../app/common/components/leaving-disclaimer/leavingDisclaimerModule';
import { MapModule } from './../../../app/common/components/map/pfMapModule';
import { ScheduleAppointmentModule } from './../../../app/common/components/schedule-appointment/scheduleAppointmentModule';
import { ChangePlanModule } from './../../common/components/change-plan/pfChangePlanModule';
import { CommonAlertModule } from './../../common/components/common-alert/commonAlertModule';
import { EmailModule } from './../../common/components/email/pfEmailModule';
import { AssignPcpModule } from './../../common/components/pcp/pfAssignPcpModule';
import { RatingsModule } from './../../common/components/ratings/pfRatingsModule';
import { SsoModule } from './../../common/components/sso/ssoModule';
import { PFCostResultsModule } from './../../eyc/cost-results/pfCostResultsModule';
import { PFSearchModule } from './../../fad/search-providers/pfSearchModule';
import { PFCptCostColumnComponent } from './components/pfCptCostColumnCmp';
import { PFFeedbackComponent } from './components/pfFeedbackCmp';
import { PFFilterAccordionComponent } from './components/pfFilterAccordionCmp';
import { PFFilterDeselectionComponent } from './components/pfFilterDeselectionCmp';
import { PFFtsAvailableSuggestionComponent } from './components/pfFtsAvailableSuggestionCmp';
import { PFSearchResultProviderInfoCmp } from './components/pfSearchResultProviderInfoCmp';
import { PFSearchResultProviderListCmp } from './components/pfSearchResultProviderListCmp';
import { PFSearchResultRecognitionCmp } from './components/pfSearchResultRecognitionCmp';
import { PFSearchResultsComponent3 } from './components/pfSearchResultsCmp3';
import { PFSearchResultsContainerComponent2 } from './components/pfSearchResultsContainerCmp2';
import { PFSearchResultsFilterComponent2 } from './components/pfSearchResultsFilterCmp2';
import { CptSummaryModel } from './models/cptSummaryModel';
import { SortModel } from './models/sortModel';
import { SummaryModel } from './models/summaryModel';

@NgModule({
  imports: [UxSharedModule, FormsModule, CommonModule, DisclaimerModule, HelpfulHintsModule, PaginationModule,
    PFCostResultsModule, PFDetailsModule, MapModule, PFCompareModule, SsoModule, LeavingDisclaimerModule,
    RatingsModule, ScheduleAppointmentModule, EmailModule, ChangePlanModule, ControlListModule, AssignPcpModule,
    PillFilterModule, PFSearchModule, InfoListModule, AssignCareModule, CustomPipesModule, HealthwiseModule,
    PFProviderExperienceModule, CptSlidePanelModule, VirtualCareLabelModule, PFSearchCostEstimateSlideOutModule, ScenarioAlertModule, CommonAlertModule, LocationModule, RatingsSlideOutModule, CostSlideOutModule,
    OnlinePharmacyLabelModule],
  declarations: [PFSearchResultsContainerComponent2, PFSearchResultsFilterComponent2,
    PFFilterDeselectionComponent, PFFilterAccordionComponent,  PFSearchResultProviderListCmp,
    PFSearchResultProviderInfoCmp, PFSearchResultRecognitionCmp, PFCptCostColumnComponent, PFSearchResultsComponent3, PFFtsAvailableSuggestionComponent, PFFeedbackComponent],
  exports: [PFSearchResultsFilterComponent2, PFFilterDeselectionComponent,
    PFFilterAccordionComponent,  PFSearchResultProviderListCmp, PFSearchResultProviderInfoCmp,
    PFSearchResultRecognitionCmp, PFCptCostColumnComponent, PFSearchResultsContainerComponent2,
    PFSearchResultsComponent3, PFFtsAvailableSuggestionComponent, PFFeedbackComponent],
  providers: [TitleCasePipe, HoursMinutesPipe]
})
export class PFSearchResultsModule {
  static forRoot(): ModuleWithProviders<PFSearchResultsModule> {
    return {
      ngModule: PFSearchResultsModule,
      providers: [
        SummaryModel, SortModel, CptSummaryModel
      ]
    };
  }
}
