import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFPaginationComponent } from './pfPaginationCmp';
@NgModule({
    declarations: [PFPaginationComponent],
    exports: [PFPaginationComponent],
    imports: [
        CommonModule,
        UxSharedModule,
    ],
    providers: []
})
export class PaginationModule { }
