import { ISearchResult } from './../interfaces/iSearchResult';
import { Paginator } from './paginator';
import { ProcedureGroup } from './procedureGroup';

export class SearchByWordsResult implements ISearchResult {
  query: string;
  title: string;
  paginator: Paginator = new Paginator();
  noResultsMessage: string;
  paginatedItems: Array<ProcedureGroup>;

  private _procedureGroups: Array<ProcedureGroup> = [];
  private _relatedProcedureGroups: Array<ProcedureGroup> = [];
  private _isRelProceduresAdded: boolean = false;

  add(g: ProcedureGroup) {
    this._procedureGroups.push(g);
    this.paginator.totalItems = this.allItems.length;
  }

  addRelated(g: ProcedureGroup) {
    this._relatedProcedureGroups.push(g);
    this.paginator.totalItems = this.allItems.length;
  }

  get isResultsFound(): boolean {
    return this.allItems.length > 0;
  }

  get size(): number {
    return this.allItems.length;
  }

  get allItems(): Array<ProcedureGroup> {
    return this._procedureGroups.concat(this._relatedProcedureGroups);
  }

  get procedureGroups(): Array<ProcedureGroup> {
    return this._procedureGroups;
  }

  get relatedProcedureGroups(): Array<ProcedureGroup> {
    return this._relatedProcedureGroups;
  }

  setToFirstPage(): void {
    this.paginator.first();
    this.updatePaginatedItems();
  }

  loadMore(): void {
    this.paginator.next();
    this.updatePaginatedItems();
  }

  clear() {
    this.query = '';
    this.title = '';
    this.noResultsMessage = '';
    this._procedureGroups.length = 0;
    this._relatedProcedureGroups.length = 0;
    this.paginator.totalItems = 0;
    this.paginatedItems = [];
  }

  private updatePaginatedItems() {
    this.paginatedItems = null;
    this.paginatedItems = [];

    this.paginatedItems = this.allItems;

    if (this.paginatedItems && this.paginatedItems.length > 0) {
      this.paginatedItems.sort((a, b) => {
        const bgnA = a.title.substr(0, this.query.length).toLowerCase();
        const bgnB = b.title.substr(0, this.query.length).toLowerCase();

        if (bgnA === this.query.toLowerCase()) {
          if (bgnB !== this.query.toLowerCase()) {
            return -1;
          }
        } else if (bgnB === this.query.toLowerCase()) {
          return 1;
        }

        return a < b ? -1 : (a > b ? 1 : 0);
      });
    }
  }
}
