import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { GeoLocationService } from '../../../common/components/geoLocation/services/geoLocationService';
import { SUBSCRIBER } from '../values/providerSearchConstants';
import { SecureStateHandler } from './../../../common/components/app-secure/services/secureStateHandler';
import { IChangePlanOptions } from './../../../common/components/change-plan/interfaces/iChangePlanOptions';
import { ChangePlanService } from './../../../common/components/change-plan/services/changePlanSvc';
import { DATE_FORMAT } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { ChangePlanEvent, IChangePlanEvent } from './../../../common/enums/changePlanEvent';
import { IMemberListItem, IOptions } from './../../../common/interfaces/iAppMetadata';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-fad-members-list-cmp',
  templateUrl: '../views/pfMembersListCmp.html'
})
export class PFMembersListComponent extends BaseComponent implements OnDestroy {
  hasMultipleMembers: boolean = false;
  members: Array<IMemberListItem> = [];

  private _prevChangeEvt: IChangePlanEvent;
  private _changePlanSubs: Subscription;
  constructor(route: ActivatedRoute, private _eventHandler: EventHandler, public appSessionObj: AppSession,
    contentHlpr: ContentHelper,
    private _secureStateHandler: SecureStateHandler,
    private _titleCasePipe: TitleCasePipe, private _datePipe: DatePipe, private _dataHlpr: DataHelper,
    private _changePlanService: ChangePlanService,
    private _geoLocationService: GeoLocationService
  ) {
    super(route, _eventHandler, appSessionObj, contentHlpr, 'PFSearchContainerComponent');
  }

  ngOnInit(): void {
    this._changePlanSubs = this._eventHandler.get(ChangePlanEvent.CHANGE_PLAN).subscribe((event: IChangePlanEvent) => {
      this.updateMembersList(event);
    });
  }

  ngOnDestroy(): void {
    this._changePlanSubs?.unsubscribe();
  }

  onMemberBtnClick(mbrUid: string) {
    const mbr = this._prevChangeEvt?.selectedContract?.memberList?.find((m) => {
      return m.mbrUid === mbrUid;
    });
    if (!mbr) {
      return;
    }
    this.changeSelectedMember(mbrUid);
    const changePlanOptions: IChangePlanOptions = { mbrUid, updatePostalCode: false };
    this._secureStateHandler.setAppSessionVariables(this._prevChangeEvt.selectedContract, changePlanOptions);
    this._changePlanService.setChangePlanSave(AppNavigations.SEARCH_PROVIDERS_PATH);
    this._geoLocationService.setMemberChangeEvent(mbr);
  }

  updateMembersList(event: IChangePlanEvent) {
    if (!event) {
      return;
    }
    this._prevChangeEvt = event;
    this.members = [];
    event.selectedContract?.memberList?.forEach((mbr) => {
      if (!mbr) {
        return;
      }
      const hasMemberAlreadyCreated = this.members.find((m) => {
        return m.mbrUid === mbr.mbrUid;
      });
      if (hasMemberAlreadyCreated) {
        return;
      }
      const mbrListItem = this.getMemberListItem(mbr);
      this.members.push(mbrListItem);
    });
    //Sort the members by date of birth
    this.members.sort((m1, m2) => {
      const dob1 = new Date(this._datePipe.transform(m1.dateOfBirth, DATE_FORMAT));
      const dob2 = new Date(this._datePipe.transform(m2.dateOfBirth, DATE_FORMAT));
      if (dob1 < dob2) {
        return -1;
      }
      if (dob1 > dob2) {
        return 1;
      }
      //Sort by first name (when dob are equal)
      if (m1.firstName < m2.firstName) {
        return -1;
      }
      if (m1.firstName > m2.firstName) {
        return 1;
      }
      return 0;
    });
    //Bring the subscriber to the top
    this.members.sort((m1, m2) => {
      if (this._dataHlpr.areEqualStrings(m1.relationship, SUBSCRIBER)) {
        return -1;
      }
      return 0;
    });

    this.hasMultipleMembers = this.members.length > 1;
    this.changeSelectedMember(event.selectedMbrUid);
  }

  private changeSelectedMember(mbrUid: string) {
    //Select the given meber & De-select all other members
    this.members.forEach((m) => {
      m.isSelected = m.mbrUid === mbrUid;
    });
  }

  private getMemberListItem(m: IOptions): IMemberListItem {
    const firstName = this._titleCasePipe.transform(m.firstName);
    const lastName = this._titleCasePipe.transform(m.lastName);
    const dob = this._datePipe.transform(m.dateOfBirth, DATE_FORMAT);
    const toolTip = `<div class="mbr-list-tooltip-name"> <strong>${firstName} ${lastName}</strong></div><div class="mbr-list-tooltip-dob">${dob}</div>`;
    const result: IMemberListItem = {
      mbrUid: m.mbrUid,
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
      dateOfBirth: m.dateOfBirth,
      genderCode: m.genderCode,
      relationship: m.relationship,
      isSelected: false,
      toolTip
    }
    return result;
  }
}
