import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { Locale } from '../../../common/constants/app-constants';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { MessagingHandler } from '../../../common/services/messagingHandler';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IRuleParameters } from '../../rules/interfaces/iRulesParameters';
import { CommonUtil } from '../../utilities/commonUtil';
import { IQuickLinks } from '../interfaces/iQuickLinks';

/**
 * QuickLinksService service will return quickLinks response for providers and procedures.
 */
@Injectable()
export class QuickLinksService extends BaseService {
  constructor(
    _appSession: AppSession,
    _appUtility: AppUtility,
    _httpClientSvc: HttpClientService,
    private _messagingHandler: MessagingHandler
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  getQuickLinksV3List(type: IRuleParameters): Promise<IQuickLinks> {
    type.locale = this.appSession?.metaData?.locale ? this.appSession.metaData.locale.toString() : Locale.ENGLISH;
    const promise = this.getQuickLinksV3(type);
    if (CommonUtil.isMemberSecure(this.appSession)) {
      this._messagingHandler.setProcedureTypePromise(promise);
    }
    return promise;
  }

  getQuickLinksV3(request: IRuleParameters): Promise<IQuickLinks> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.quickLinksV3,
      data: request,
      headers: this.getHeaders()
    });
  }
}
