import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { SERVICE_AREA_VALIDATION_SUCCESS_CODE } from '../../../../app/common/constants/app-constants';
import { IPrefixMetadataRequest, IPrefixMetadataResponse } from '../interfaces/iPrefixMetadata';
import { IAddressInfoReq, IInputData, INetworkId, IServiceAreaValidationRequest, IServiceAreaValidationResponse } from '../interfaces/iServiceAreaValidation';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { BaseService } from './../../../common/services/baseService';
import { HttpClientService } from './../../../common/services/httpClientService';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ProviderUtilityService } from './providerUtilitySvc';

@Injectable({ providedIn: 'root' })
export class SAVNavigationService extends BaseService {
  constructor(
    _appSession: AppSession,
    _appUtility: AppUtility,
    httpClientSvc: HttpClientService,
    private _navigationService: NavigationService,
    private _providerUtilityService?: ProviderUtilityService
  ) {
    super(_appSession, _appUtility, httpClientSvc);
  }

  navigate(): void {
    if ((!this.appSession.isCaHmoDmhc || this.appSession.serviceAreaValidated) && this.appSession.searchParams?.zip) {
      this._navigationService.navigateByUrl(
        AppNavigations.SEARCH_RESULTS_PATH
      );
    }
    else if (!this.appSession.serviceAreaValidated && this.appSession.isCaHmoDmhc) {
      if (((this.appSession.searchParams.countyCode === null || this.appSession.searchParams.countyCode === undefined) && this.appSession.searchParams.city === "") || this.appSession.customAddressSearch) {
        this.getZipcodeBasedCounty().then((result) => {
          if (result && isEmpty(result)) {
            this._navigationService.navigateByUrl(
              AppNavigations.SEARCH_RESULTS_PATH
            );
          }
          else {
            this._navigationService.navigateByUrl(
              AppNavigations.SERVICE_AREA_VALIDATION_PATH
            );
          }
        })
      }
      else {
        this._navigationService.navigateByUrl(
          AppNavigations.SERVICE_AREA_VALIDATION_PATH
        );
      }
    }
  }

  async getZipcodeBasedCounty(): Promise<IServiceAreaValidationResponse> {
    const networks = await this.getPrefixData();
    let req = {} as IServiceAreaValidationRequest;
    req.inputData = {} as IInputData;
    req.inputData.networkIds = {} as INetworkId;
    req.inputData.addressInfo = [{}] as Array<IAddressInfoReq>;
    req.inputData.srcSystem = this.appSession.metaData.brandCd;
    req.inputData.networkIds.networkId = this.appSession?.searchParams?.plan?.networkList?.length ? this.appSession?.searchParams?.plan?.networkList : networks?.networkList;
    req.inputData.addressInfo.forEach((address) => {
      address.zipCd = this.appSession?.searchParams?.zip || "";
      address.countyCd = this.appSession?.searchParams?.countyCode || "";
      address.stateCd = this.appSession?.searchParams?.stateCd?.code || "";
      address.addressLine1 = this.appSession?.searchParams?.streetAddress || "";
      address.addressLine2 = this.appSession?.searchParams?.addressLine2 || "";
    });
    return await this._providerUtilityService.validateServiceArea(req).then(
      (result: IServiceAreaValidationResponse) => {
        const data = result?.outputData?.addressInfo?.filter((element) => element.savInfo.every((savResult) => savResult.savOutput === SERVICE_AREA_VALIDATION_SUCCESS_CODE))
        if (data && data.length > 0) {
          result = {} as IServiceAreaValidationResponse;
          this.appSession.serviceAreaValidated = true;
        }
        return new Promise((resolve, reject) => { resolve(result) })
      }
    )
  }

  async getPrefixData(): Promise<IPrefixMetadataResponse> {
    const _prefixMetaDataReqObj: IPrefixMetadataRequest = {} as IPrefixMetadataRequest;
    _prefixMetaDataReqObj.prefix = this.appSession.searchParams.plan.alphaPrefix;
    const network = await this._providerUtilityService.getPrefixMetadata(_prefixMetaDataReqObj);
    return network;
  }
}
