import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { EycProcedureCriteria } from '../../../fad/search-results/interfaces/iSearchRequest';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { QUICK_LINK_TYPE } from '../enums/quickLinksEnum';
import { IProcedure } from '../interfaces/iProcedure';
import { IProviderType } from '../interfaces/iProviderType';
import { ILinkProcedure } from '../interfaces/iQuickLinks';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { ContentLanguageCode, Locale, MODAL_TYPE } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from './../../../common/constants/strategy-search-constant';
import { IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { IOptions } from './../../../common/interfaces/iAppMetadata';
import { IChat } from './../../../common/interfaces/iSymptomChat';
import { IActionId, IUserInteraction } from './../../../common/interfaces/iUserInteraction';
import { IWindow } from './../../../common/interfaces/iWindow';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { SearchSvc } from './../../../common/services/searchSvc';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { VpcMagicLinkSvc } from './../../../fad/search-providers/services/vpcMagicLinkSvc';
import { EycSearchParam } from './../../../fad/search-results/interfaces/iSearchRequest';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { SAVNavigationService } from './../services/savNavigationSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from './../values/providerSearchConstants';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-dynamic-providers-procedures-cmp',
  templateUrl: '../views/pfDynamicProvidersAndProceduresCmp.html',
  providers: []
})
export class PFDynamicProvidersAndProceduresComponent extends BaseComponent implements OnInit {
  @Output()
  modal: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  analytics: EventEmitter<IAdobeSearchDetail> = new EventEmitter<IAdobeSearchDetail>();
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  providerTypes: IProviderType[] = [];
  @Input()
  procedures: ILinkProcedure[] = [];
  @Input()
  searchParams: ISearchParameters;
  @Input()
  planUnknownError: boolean = false;
  @Input()
  showViewMoreLink: boolean = false;
  @Input()
  personalizedIndicator: number;

  searchCriteria: ISearchParameters;
  providerTypeList: IProviderType[] = [];
  procedureList: IProcedure[] = [];
  hasEYC: boolean = false;
  isMemberSecure: boolean = false;
  urgentCareProvider: IProviderType;
  ssoConfig: any;
  languageCd: string;
  otherServicesProvider: IProviderType;
  locale: string;
  symptomList: IChat[] = [];
  isViewAll: boolean = false;
  personalizedIndicatorValue: number;
  providerInfoText: string;

  @ViewChild('SymptomChatSidePanelTemplate')
  SymptomChatSidePanelTemplate: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;

  constructor(
    private searchService: SearchSvc,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _appUtility: AppUtility,
    private _navigationService: NavigationService,
    private _ruleService: RulesService,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _savNavigationService: SAVNavigationService,
    private _vpcMagicLinkService: VpcMagicLinkSvc,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    if (this._appSession) {
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
      this.hasEYC = this.isMemberSecure && (this._appSession.hasEYC || (this._appSession.hasDentalEYC && this.showDentalDDS));
    }
    this.providerInfoText = this.isHideQuickLinksShopForProcedureEnabled
      ? this.isMemberSecure
        ? this.content.newLandingComponent.labels.secureToolTip
        : this.content.newLandingComponent.labels.coldToolTip
      : this.content.newLandingComponent.labels.providerTooltipLabel;
    this.getProcedures(this.procedures);

    if (this._dataHelper.areEqualStrings(this._appSession?.metaData?.locale?.toString(), Locale.SPANISH)) {
      this.languageCd = ContentLanguageCode.SPANISH;
    } else {
      this.languageCd = ContentLanguageCode.ENGLISH;
    }
    this.personalizedIndicatorValue = this.personalizedIndicator;
  }

  ngOnChanges(args: SimpleChanges) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.searchCriteria = args['searchParams'].currentValue;
    }

    // Bind Provider Types
    if (args['providerTypes'] && args['providerTypes'].currentValue) {
      this.getProviderTypes(args['providerTypes'].currentValue);
    }

    // Bind Procedures
    if (args['procedures'] && args['procedures'].currentValue) {
      this.getProcedures(args['procedures'].currentValue);
    }

    if (args['personalizedIndicator'] && args['personalizedIndicator'].currentValue) {
      this.personalizedIndicatorValue = args['personalizedIndicator'].currentValue;
    }
  }

  getProviderTypes(provTypes: IProviderType[]) {
    this.providerTypeList = provTypes;
    this.getMoreOptionsProviderTypes();
  }

  getProcedures(proceList: any) {
    this.procedureList = [];
    if (proceList && proceList.length > 0) {
      for (const procedure of proceList) {
        const _procList: IProcedure = {
          code: procedure.procedureCode ? procedure.procedureCode.code.toUpperCase().trim() : '',
          name: procedure.procedureCode ? procedure.procedureCode.name : '',
          description: procedure.procedureCode ? procedure.procedureCode.description : '',
          categoryCode: procedure.providerCategoryCode ? procedure.providerCategoryCode.code : '',
          costDescription: '',
          adobeTag: procedure.adobeTag,
          imageContentId: procedure.imageContentId,
          imageName: procedure.imageName,
          modelId: procedure.modelId,
          rankPercentile: procedure.rankPercentile,
          actionReasonCodes: procedure.actionReasonCodes,
          hasFeedbackOptions: CommonUtil.toShowFeedbackOptionForTile(procedure)
        };
        this.procedureList.push(_procList);
      }
    }
  }

  isLeavingAnthem(providerTypeCode: string): boolean {
    const provType: IProviderType = (this.providerTypeList || []).find((p) => {
      return p.code.toUpperCase() === providerTypeCode.toUpperCase();
    });
    if (provType?.vendorUrl) {
      this.leavingDisclaimer.emit(provType.vendorUrl);
      return true;
    }
    return false;
  }

  searchByProviderType(providerType: IProviderType) {
    const isLeavingAnthem = this.isLeavingAnthem(providerType.code);
    if (!isLeavingAnthem && this.isLocationValid() && !this.planUnknownError && providerType) {
      this.searchParams.typeSelectNm = providerType.code;
      this.searchParams.coverageTypeCode = '';
      this.searchParams.specialtySelectNm = providerType.specialtyCategoryList;
      this.searchParams.taxonomySelectNm = providerType.taxonomyList;
      this.searchParams.providerTypeCodeList = [];
      this.searchParams.ableToServePcp = providerType.ableToServePcp;
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario([providerType.code] as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      //removing search term when user click on quickLinks
      this._appSession.searchTerm = '';
      this._appSession.isSearchTerm = false;
      this.searchParams = CommonUtil.buildStandAlone(this.searchParams, this._appSession);
      if (this.searchCriteria?.typeSelectNm) {
        this.searchParams?.providerTypeCodeList.push(this.searchCriteria.typeSelectNm);
      }
      // Medsupp scenario
      if (this._appSession.planRule && this._appSession?.planRule?.isMedicareGov) {
        this.searchParams.standAloneVision = true;
      }
      this.searchCriteria = this.searchParams;
      this.setAnalytics(providerType.name, false);
      this.resetAppSessionParams();

      //Set Flag for Pre Filtering BCP filter
      const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
      let isSearchCriteriaPath: boolean = false;
      if (_routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH) {
        isSearchCriteriaPath = true;
      }

      this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
        (result: IRulesResponse) => {
          const _result = result;
          if (_result) {
            if (_result.showMedicareSupplement) {
              this.modal.emit(MODAL_TYPE.MEDICARE_SUPPLEMENT);
            } else {
              this._appSession.feature = _result;
              if (this._appSession && isSearchCriteriaPath && _result.showBCP && _result.showPreFilterBCP && this._appSession.searchParams) {
                this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
              }
              this._savNavigationService.navigate();
            }
          }
        },
        (error: any) => {
          this.onCategoryError('RULE', error);
        }
      );
    }
    if (this.isMemberSecure && !this._appSession.isEmulation) {
      this.emitLiveChatEvent();
    }
  }

  searchByProcedure(procedure: IProcedure) {
    if (this.isLocationValid()) {
      this.searchCriteria.typeSelectNm = '';
      this.searchParams.coverageTypeCode = '';
      this.searchParams.specialtySelectNm = [];
      this.searchParams.taxonomySelectNm = [];
      this.searchParams.providerTypeCodeList = [];
      //removing search term when user click on quickLinks
      this._appSession.searchTerm = '';
      this._appSession.isSearchTerm = false;
      const eycCriteria = new EycProcedureCriteria();
      const eycSearchParam = new EycSearchParam();

      this.searchCriteria.providerTypeCodeList = [];
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureProcedureScenario(procedure.categoryCode as PROVIDER_TYPE) : SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL;
      eycCriteria.procedureCode = procedure.code;
      eycCriteria.providerCategoryCode = procedure.categoryCode;
      eycCriteria.procedureName = procedure.name;
      eycSearchParam.procedureDescription = procedure.description;
      eycSearchParam.costDescription = procedure.costDescription;
      eycSearchParam.eycProcedureCriteria = eycCriteria;

      this.searchCriteria.eycSearchParam = eycSearchParam;
      this.searchCriteria.brandCode = this._appSession.metaData.brandCd;

      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
        const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
        if (_index > -1) {
          const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
          this.searchCriteria.selectedMem = _member.firstName + ' ' + _member.lastName;
        }
        this.searchCriteria.memberSeqNum = this._appSession.metaData.appContract.memberSeqNum;
      }

      this.setAnalytics(procedure.name, true);
      this.resetAppSessionParams(true);
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
    if (this.isMemberSecure && !this._appSession.isEmulation) {
      this.emitLiveChatEvent();
    }
  }

  isLocationValid() {
    let _isValid = true;
    if (!(this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude !== '' && this.searchCriteria.coordinates.longitude !== '' && this.searchCriteria.zip !== '')) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  resetAppSessionParams(eyc: boolean = false) {
    this.searchCriteria.provNm = '';
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.billingType = undefined;
    this._appSession.medicalCode = undefined;
    this._appSession.medicalModifierOptionCode = undefined;
    this._appSession.medicalPosCode = undefined;
  }

  setAnalytics(name: string, eyc: boolean) {
    const _searchDetails = {} as IAdobeSearchDetail;
    if (eyc) {
      _searchDetails.procedureName = name;
    } else {
      _searchDetails.provType = name;
    }
    _searchDetails.zip = this.searchCriteria.zip;
    this.analytics.emit(_searchDetails);
  }

  viewMoreProcedure() {
    this._navigationService.navigateByUrl(AppNavigations.PROMOTED_PROCEDURES_PATH);
  }

  hasTelehealth(): boolean {
    return this._appSession.isSecureState && this._appSession.hasTelehealth;
  }

  getMoreOptionsProviderTypes() {
    if (this._appSession.providerTypes && this._appSession.providerTypes.length) {
      this.urgentCareProvider = this._appSession.providerTypes.find((prov) => prov.code === PROVIDER_TYPE.URGENT_CARE);
      this.otherServicesProvider = this._appSession.providerTypes.find((prov) => prov.code === PROVIDER_TYPE.OTHER_SERVICES_PROVIDER);
    }
  }

  onVideoVisit(event) {
    const videoVisitClick = event.currentTarget.querySelector('#fad-pf-sso-link-0');
    if (videoVisitClick) {
      videoVisitClick.click();
      event.preventDefault();
      event.stopPropagation(); // Instead of returning false we can stop the dom propagation using stopPropagation invocation
    }
  }

  getAriaLabelForProviderAndProced(providerName: string, type: string) {
    let label = '';
    if (type) {
      label = type.replace(/{provider_name}/gi, providerName);
    }
    return label;
  }

  getProvidersActionId(provType: IProviderType): string {
    let actionId = {} as IActionId;
    if (provType) {
      actionId = {
        personalizedIndicator: this.personalizedIndicatorValue,
        actionId: CommonUtil.isValidString(provType.modelId) ? provType.modelId : provType.name,
        rankPercentile: provType.rankPercentile
      } as IActionId;
    }
    return Object.keys(actionId).length ? this.userInteractionUtil.getUserInteraction(actionId, this.userInteraction.landingPage.providerQuickLink) : undefined;
  }

  isRecentActivity(): boolean {
    return this._appSession.isSecureState && this._appSession.hasVirtualUrgentCare;
  }

  onRecentViewMoreClick(direction: 'right') {
    this.sidePanelRef = this._sidePanel.open(direction, this.SymptomChatSidePanelTemplate);
  }
  onClose() {
    this.sidePanelRef.close();
  }

  onGoToKhealth(careType: string, chatid: string) {
    return CommonUtil.redirectKHealth(careType, this._appSession, this._vpcMagicLinkService, this._window, chatid);
  }

  getProcedureQuickLinkInteractionEvent(procedure: IProcedure): string {
    const actionObject = {
      personalizedIndicator: this.personalizedIndicatorValue,
      actionId: procedure?.modelId ? procedure.modelId : procedure.code,
      rankPercentile: procedure?.rankPercentile
    } as IActionId;
    return this.userInteractionUtil.getUserInteraction(actionObject, this.userInteraction.landingPage.procedureQuickLink);
  }

  // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
  hasSwitchMoreOptionsFlow(): boolean {
    return true;
  }

  getFeedbackEventInteraction(
    quickLink: {
      rankPercentile?: number;
      modelId?: string;
      name?: string;
    },
    quickLinkType: QUICK_LINK_TYPE
  ): IUserInteraction {
    const baseFeedbackPayload: IUserInteraction = {
      eventType: quickLinkType === QUICK_LINK_TYPE.PROVIDER ? this.userInteraction.landingPage.providerQuickLink.eventType : this.userInteraction.landingPage.procedureQuickLink.eventType,
      actionId: quickLink.modelId || quickLink.name,
      personalizedIndicator: this.personalizedIndicator,
      rankPercentile: quickLink.rankPercentile
    };
    return baseFeedbackPayload;
  }
}
