import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SearchResultsFilterService {
  private primeGroupResult = new Subject<any>();
  private onSortFilter = new Subject<any>();
  private onSearchInput = new Subject<any>();
  private primeGroupSearchResponse = new Subject<boolean>();
  private isprimeGroupRemoved = new Subject<boolean>();

  setSortFilter(data: any) {
    this.onSortFilter.next(data);
  }

  setSearchInput(data: any) {
    this.onSearchInput.next(data);
  }

  setPrimeGroupAffiliation(isPrimeGroupSearchResponse: boolean) {
    this.primeGroupSearchResponse.next(isPrimeGroupSearchResponse);
  }

  get onPrimeGroupSearchResponse() {
    return this.primeGroupSearchResponse.asObservable();
  }

  get onSortFilterClick() {
    return this.onSortFilter.asObservable();
  }

  get searchInput() {
    return this.onSearchInput.asObservable()
      .pipe(
        map((data) => data),
        debounceTime(500),
        distinctUntilChanged()
      );
  }

  setRemovedPrimeGroupRule(isprimeGroupRemoved: boolean) {
    this.isprimeGroupRemoved.next(isprimeGroupRemoved);
  }

  onRemovedPrimeGroupRule(): Observable<any> {
    return this.isprimeGroupRemoved.asObservable();
  }
}
