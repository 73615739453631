import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { CommonUtil } from '../..//fad/utilities/commonUtil';
import { BaseComponent } from '../../common/components/base-component/baseCmp';
import { IChat } from '../../common/interfaces/iSymptomChat';
import { IWindow } from '../../common/interfaces/iWindow';
import { EventHandler } from '../../common/services/eventHandler';
import { AppSession } from '../../common/values/appSession';
import { ContentHelper } from '../../common/values/contentHelper';
import { VpcMagicLinkSvc } from "../../fad/search-providers/services/vpcMagicLinkSvc";


@Component({
  moduleId: module.id,
  selector: 'app-virtual-care-recent-activity-cmp',
  templateUrl: '../views/pfVirtualCareRecentActivityCmp.html',
  providers: []
})

export class PFVirtualCareRecentActivityComponent extends BaseComponent implements OnInit {
  locale: string;
  symptomList: IChat[] = [];
  isMoreChat: boolean = false;
  @ViewChild("SymptomChatSidePanelTemplate")
  SymptomChatSidePanelTemplate: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;


  constructor(
    eventHandler: EventHandler,
    route: ActivatedRoute,
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    contentHelper: ContentHelper,
    private _vpcMagicLinkService: VpcMagicLinkSvc,
    private _sidePanel: SidePanel,
  ) {
    super(route, eventHandler, _appSession, contentHelper, 'VirtualCareContainerComponent');
  }

  ngOnInit() {
  }

  onGoToKhealth(careType: string, chatid: string) {
    return CommonUtil.redirectKHealth(careType, this._appSession, this._vpcMagicLinkService, this._window, chatid);
  }

  onRecentViewMoreClick(direction: 'right') {
    this.sidePanelRef = this._sidePanel.open(
      direction,
      this.SymptomChatSidePanelTemplate
    );
  }

  onClose() {
    this.sidePanelRef.close();
  }


}
