import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFMachineReadableComponent } from './components/pfMachineReadableCmp';

@NgModule({
    declarations: [PFMachineReadableComponent],
    exports: [PFMachineReadableComponent],
    imports: [
        CommonModule,
        FormsModule,
        UxSharedModule,
    ],
    providers: []
})
export class MachineReadableModule { }
