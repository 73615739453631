import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { IHealthWiseRequest, IHealthWiseResponse, IHealthwiseBaseCode } from '../../../fad/search-results/interfaces/iSearchRequest';
import { IProvider, ISpecialty } from '../../../fad/search-results/interfaces/iSummaryResp';
import { Locale } from '../../constants/app-constants';
import { SidePanelDirections } from '../../interfaces/iCptMedicalResponse';
import { DataHelper } from '../../services/dataHelper';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from './../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-view-health-wise-cmp2',
  templateUrl: './pfViewHealthwiseCmp2.html'
})
export class ViewHealthwiseComponent2 extends BaseComponent {
  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement>;
  isApiError = false;
  showHealthwiseLoader = true;
  showTab = false;
  healthwiseCodes: Array<IHealthwiseBaseCode> = [];
  healthwiseContent: IHealthwiseBaseCode = {} as IHealthwiseBaseCode;

  constructor(
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _contentHelper: ContentHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _sidePanel: SidePanel,
    private _dataHelper: DataHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  openSidePanel(direction: SidePanelDirections, provider: IProvider, procedureCode?: string) {
    this.showHealthwiseLoader = true;
    const healthWiseReq: IHealthWiseRequest = {} as IHealthWiseRequest;

    if (provider && provider.specialties?.length && provider.providerTypeCodeList?.length && provider.specialtyCategory?.length) {
      healthWiseReq.providerTypeCodeList = provider.providerTypeCodeList;
      healthWiseReq.specialtyCategoryFilter = provider.specialtyCategory;
      healthWiseReq.taxonomyList = provider.specialties.map((spec) => spec.specialtyCode);
    }
    if (!this._dataHelper.isEmptyString(procedureCode)) {
      healthWiseReq.procedureCategoryCode = [procedureCode];
    }
    healthWiseReq.locale = this._appSession?.metaData?.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;

    this._providerUtilityService.getHealthwiseProcedureAndSpecialityList(healthWiseReq).then(
      (healthWiseResponse: IHealthWiseResponse) => {
        this.showHealthwiseLoader = false;
        this.healthwiseCodes = this.mapHeaderText(healthWiseResponse.hwContentDesciptionList, provider.specialties);
        if (this.healthwiseCodes.length) {
          this.healthwiseContent = this.healthwiseCodes[0];
        }
      },
      (error: any) => {
        this.showHealthwiseLoader = false;
        this.healthwiseCodes = [];
        this.isApiError = true;
      }
    );

    this.sidePanelRef = this._sidePanel.open(direction, this.viewHealthwiseContentModal);
  }

  onClose() {
    this.isApiError = false;
    this.showHealthwiseLoader = true;
    this.healthwiseCodes = [];
    this.sidePanelRef?.close();
  }

  mapHeaderText(hwContentDesciptionList: IHealthwiseBaseCode[], specialties: ISpecialty[]): IHealthwiseBaseCode[] {
    const mappedResponse: IHealthwiseBaseCode[] = [];
    specialties?.forEach((spec) => {
      const healthwiseContent = hwContentDesciptionList?.find((el) => el.taxonomyCode === spec.specialtyCode);
      if (healthwiseContent) {
        healthwiseContent.header = spec.specialtyCodeName;
        mappedResponse.push(healthwiseContent);
      }
    });

    //sorting speciaties for specialty slide out
    mappedResponse.sort((a, b) => {
      if (a.header > b.header) {
        return 1;
      } else if (a.header < b.header) {
        return -1;
      } else {
        return 0;
      }
    });

    return mappedResponse;
  }
}
