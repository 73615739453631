import { ComponentFactoryResolver, Directive, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PillItemLookupComponent } from '../components/pillItemLookupCmp';
import { PillItemLookupService } from '../services/pillItemLookupService';
import { PillItemCmp } from './../components/pillItemCmp';

@Directive({ selector: '[pill-item-lookup]' })
export class PillItemLookupDirective implements OnDestroy {
  @Input() showButton: boolean = false;
  @Input() parentComponent: PillItemCmp;
  @Input() showHeader: boolean = false;
  @Input() headerText: string = '';
  @Input() lookupCSS: string = '';
  @Output() readonly clearClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() readonly pillLookupAfterView: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _clearClickSubscription: Subscription;
  private _pillLookupAfterViewSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private lookupService: PillItemLookupService) {
      this.lookupService.destoryComponent();
  }

  ngOnInit() {
    const templateView = this.templateRef.createEmbeddedView({});
    const componentFactory = this.resolver.resolveComponentFactory(PillItemLookupComponent);
    const componentRef = this.viewContainer.createComponent(componentFactory, null,
      this.viewContainer.injector, [templateView.rootNodes]);

    if (typeof componentRef !== 'undefined' && componentRef !== null &&
      typeof componentRef.instance !== 'undefined' && componentRef.instance !== null) {
      (componentRef.instance as PillItemLookupComponent).showButton = this.showButton;
      (componentRef.instance as PillItemLookupComponent).showHeader = this.showHeader;
      (componentRef.instance as PillItemLookupComponent).headerText = this.headerText;
      (componentRef.instance as PillItemLookupComponent).lookupCSS = this.lookupCSS;
      (componentRef.instance as PillItemLookupComponent).parentComponent = this.parentComponent;

      this._clearClickSubscription = (componentRef.instance as PillItemLookupComponent).clearClick
        .subscribe((event) => {
        this.clearClick.emit(event);
        });
      this._pillLookupAfterViewSubscription = (componentRef.instance as PillItemLookupComponent).pillLookupAfterView
        .subscribe((event) => {
        this.pillLookupAfterView.emit(event);
      });

      this.lookupService.registerComponent(componentRef);
    }
  }

  ngOnDestroy(): void {
    if (typeof this.parentComponent !== 'undefined' && this.parentComponent !== null) {
      this.parentComponent.selected = false;
    }

    if (typeof this._clearClickSubscription !== 'undefined' && this._clearClickSubscription !== null) {
      this._clearClickSubscription.unsubscribe();
    }

    if (typeof this._pillLookupAfterViewSubscription !== 'undefined' &&
      this._pillLookupAfterViewSubscription !== null) {
      this._pillLookupAfterViewSubscription.unsubscribe();
    }
  }
}
