import { ComponentRef, Injectable } from '@angular/core';
import { ActionItemLookupComponent } from '../components/actionItemLookupCmp';

@Injectable({ providedIn: 'root' })
export class ActionItemLookupService {
  private components: ComponentRef<ActionItemLookupComponent>[] = [];

  /**
   * Method to handle the registerComponent for the action items
   * @param component - component reference
   * @returns - void
   */
  registerComponent(component: ComponentRef<ActionItemLookupComponent>) {
    this.components?.push(component);
  }

  /**
   * Method to handle the destoryComponent for the action items
   * @returns - void
   */
  destoryComponent() {
    this.components?.forEach((c) => c.destroy());
  }
}
