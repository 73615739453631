import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { VirtualCareLabelModule } from '../../common/components/virtual-care-label/virtualCareLabelModule';
import { ActionItemModule } from '../common/components/shareable/action-item/actionItemModule';
import { FeedbackSidePanelModule } from '../common/components/shareable/feedback-sidepanel/feedbackSidePanelModule';
import { SearchHeaderModule } from '../common/components/shareable/search-header/SearchHeaderModule';
import { CareTeamComponent } from './components/care-team/careTeamCmp';
import { LandingComponent } from './components/landingCmp';
import { LastUpdatedComponent } from './components/lastUpdatedCmp/lastUpdated';
import { MachineReadableComponent } from './components/machineReadableCmp/machineReadableCmp';
import { MemberSelectionModalComponent } from './components/member-selection-modal/memberSelectionModalCmp';
import { MoreOptionComponent } from './components/moreOptionCmp/moreOption';
import { QuicklinkComponent } from './components/quicklink/quicklinkCmp';


@NgModule({
  declarations: [LandingComponent, QuicklinkComponent, MoreOptionComponent, LastUpdatedComponent, MachineReadableComponent, MemberSelectionModalComponent, CareTeamComponent],
  exports: [LandingComponent],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, UxSharedModule.forRoot(), FormsModule, ActionItemModule, FeedbackSidePanelModule,VirtualCareLabelModule, SearchHeaderModule],
  providers: []
})
export class LandingModule {}
