import { CurrencyPipe } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ACCOUNT_TYPE, RIGHT } from '../../../../../common/constants/app-constants';
import { PFCostDetail, PFSearchCostEstimateSidePanelVM, PFSearchCostInfo } from '../../../../../common/interfaces/iCptMedicalResponse';
import { ICostInfo } from '../../../../../common/interfaces/iSearchStrategyResponse';
import { DataHelper } from '../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { SliderService } from '../../../../../common/services/sliderSvc';
import { AppSession } from '../../../../../common/values/appSession';
import { CommonUtil } from '../../../../../fad/utilities/commonUtil';
import { IProvider } from '../../../../result/interfaces/iProvider';
import { CptSummaryModel } from '../../../../result/services/cptSummaryModel';
import { BaseComponent } from '../../core/baseCmp';


@Component({
  moduleId: module.id,
  selector: 'app-cost-slide-out-cmp',
  templateUrl: './costSlideOutCmp.html',
  styles: [`
    :host {
      cursor: pointer;
    }
  `]
})
export class CostSlideOutComponent extends BaseComponent implements OnDestroy {
  @ViewChild('viewCostSlider')
  viewCostSlider: TemplateRef<HTMLElement>;
  @ViewChild('estimatedCost')
  estimatedCost: TemplateRef<HTMLElement>;
  @Input()
  index: number;
  @Input()
  eycCostInfo: ICostInfo;
  @Input()
  professional: IProvider;
  sidePanelRef: ModalRef<HTMLElement>;
  showSpendingAccountDisclaimer = false;
  showAccountDisclaimer = false;
  @Input()
  showCostOnSubscription = false;
  cosDetailsVM: PFCostDetail[] = [];
  costInfo = {} as PFSearchCostInfo;
  private _sliderSubscription: Subscription;
  costEstimateContent = this.content.result.cptCostEstimateSidePanelContent;
  vm$:Observable<PFSearchCostEstimateSidePanelVM>  = this._cptSearchModel.cptCostResponse.pipe(
    map((value: PFSearchCostInfo) => {
      const estimatedContent = this._cptSearchModel.costEstimateWithContent(value, this.costEstimateContent.costContent, this.costEstimateContent.costContentForPercentage);
      estimatedContent.costInfo = estimatedContent?.costInfo?.filter((data) => data?.name);
      estimatedContent.serviceLimits = value.serviceLimits;
      estimatedContent.hasServiceLimit = value.hasServiceLimit;
      estimatedContent.orthoTypeFlag = value.orthoTypeFlag;
      estimatedContent.missingToothPeriodFlag = value.missingToothPeriodFlag;
      estimatedContent.hasDentalServiceLimits = value?.hasDentalServiceLimits;
      estimatedContent.isOfficeVisitCost = value?.costInfo?.isOfficeVisitCost;
      return estimatedContent;
    }),
    catchError((error) => of(error))
  );

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    private _sidePanel: SidePanel,
    private _dataHelper: DataHelper,
    private _currencyPipe: CurrencyPipe,
    private _sliderService: SliderService,
    private _cptSearchModel: CptSummaryModel,
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    if (this.showCostOnSubscription) {
      this._sliderSubscription = this._sliderService.costSliderObs.subscribe((provider) => {
        this.openSlider();
      });
    }
    if (!this._appSession.isEyc) {
      let requiredCostInfo;
      requiredCostInfo = {
        memberResponsibility: this.professional?.costInfo?.memberResponsibility
      };

      Object.keys(requiredCostInfo).forEach(key => {
        const vm: PFCostDetail = {
          name: this.content.result.dollarCptCostColumn[key] ?? '',
          value: requiredCostInfo[key] ?? ''
        };
        this.cosDetailsVM.push(vm);
      });
      this._cptSearchModel.cptSummaryResponse.subscribe((resp) => {
        this.costInfo = {
          modifierCode: resp?.modifier?.name,
          placeOfService: resp?.pos?.name,
          serviceLimits: resp?.serviceLimits,
          hasServiceLimit: resp?.hasServiceLimit,
          orthoTypeFlag: resp?.orthoTypeFlag,
          missingToothPeriodFlag: resp?.missingToothPeriodFlag,
          hasDentalServiceLimits: resp?.hasDentalServiceLimits,
          costInfo: resp?.providers?.find((provider) => provider.providerIdentifier === this.professional.providerIdentifier && provider?.addressIdentifier === this.professional?.addressIdentifier)?.costInfo || this.professional?.costInfo
        };
      });
    }
  }

  ngOnDestroy(): void {
    if (this._sliderSubscription) {
      this._sliderSubscription.unsubscribe();
    }
  }

  openSlider(): void {
    if (this._appSession.isEyc) {
      this.showSpendingAccountDisclaimer = this.eycCostInfo?.isVisibleCDHPError && this._dataHelper.areEqualStrings(this.eycCostInfo?.cdhpErrorText, 'CDHPTEXT');
    this.showAccountDisclaimer = this.eycCostInfo?.isVisibleCDHPError && this._dataHelper.areEqualStrings(this.eycCostInfo?.cdhpErrorText, 'CDHPERROR');
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.viewCostSlider);
    } else {
      this.eycCostInfo = undefined;
      this._cptSearchModel.setCptCostResponse(this.costInfo);
      this.sidePanelRef = this._sidePanel.open('right', this.estimatedCost);
    }
  }

  closeSlider(): void {
    this.showSpendingAccountDisclaimer = false;
    this.showAccountDisclaimer = false;
    this.sidePanelRef?.close();
  }

  get costCdhpPaysText(): string {
    return this.content.result.eyc.costCDHPPaysText.replace(/{ACCOUNTTYPE}/gi, this.eycCostInfo.accntType);
  }

  get copay(): string {
    return CommonUtil.getCopayWithPeriod(this.eycCostInfo);
  }
  get isDentalSearch(): boolean {
    return CommonUtil.isDentalSearch(this._appSession?.hasDentalEYC, this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode);
  }

  get showWhatYouPay(): boolean {
    return this.eycCostInfo?.isVisibleTotalYouPay && this.eycCostInfo?.showOutOfPocketCost;
  }

  get commonCostDetailCondition(): boolean {
    return this.showWhatYouPay && !this.eycCostInfo.isBenefitDenied && (this.eycCostInfo.isVisibleCostPopup || this.eycCostInfo.isVisibleCostBeforeCDHP);
  }

  get coverageLinkText(): string {
    return this.content.result.costDetailSlideModalContent.headerText2.replace(/{{COVERAGE}}/gi, this.isDentalSearch ? 'den' : 'med');
  }

  get accountPaysDesc(): string {
    if (this._dataHelper.areEqualStrings(this.eycCostInfo.accntType, ACCOUNT_TYPE.HRA_ABBR)) {
      return this.content.result.costDetailSlideModalContent.hraPaysDescription;
    } else if (this._dataHelper.areEqualStrings(this.eycCostInfo.accntType, ACCOUNT_TYPE.HSA_ABBR)) {
      return this.content.result.costDetailSlideModalContent.hsaPaysDescription;
    }
    return '';
  }

  get showAccountPays(): boolean {
    return this.eycCostInfo?.isVisibleCDHPPays && !this.eycCostInfo?.isVisibleOOPENotavailableMessage;
  }

  get showBenefitDeniedDisclaimer(): boolean {
    return !this.eycCostInfo?.isRangeVisible && this.eycCostInfo?.isBenefitDenied;
  }

  get costText(): string {
    if (this.showWhatYouPay && !this.eycCostInfo.isRangeVisible && typeof this.eycCostInfo.totalYouPay === 'number' && !isNaN(this.eycCostInfo.totalYouPay)) {
      return this._currencyPipe.transform(this.eycCostInfo.totalYouPay, 'USD', 'symbol', '1.0');
    } else if (this.eycCostInfo?.isRangeVisible && this.eycCostInfo.isVisibleTotalAverageCost && this.eycCostInfo.isOOPERange && !this._dataHelper.isEmptyString(this.eycCostInfo.totalAverageCost)) {
      return this.eycCostInfo.totalAverageCost;
    } else {
      return this.content.result.costDetailSlideModalContent.costDetails;
    }
  }

  get cdhpError(): string {
    return this.content.result.costDetailSlideModalContent.cdhpError.replace(/{{ACCOUNTTYPE}}/gi, this.eycCostInfo.accntType);
  }

  dismissDisclaimer(): void {
    this.showSpendingAccountDisclaimer = false;
  }

  dismissAccountDisclaimer(): void {
    this.showAccountDisclaimer = false;
  }

  print() {
    window.print();
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  onHostClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
