import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Locale } from '../../../common/constants/app-constants';
import { labels } from '../../common/contents/labels';
import { routeContentKeys } from '../constants/routeConstant';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Injectable({
  providedIn: 'root'
})
export class ContentResolver implements Resolve<any> {
  constructor(
    private appSession: AppSession,
    private _contentHelper: ContentHelper
  ) {}

  /**
   * Resolves the content based on the route data and locale.
   * @param route - The activated route snapshot.
   * @returns An observable containing the resolved content.
   */
  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    // Determine the locale from the app session, defaulting to English if not available
    const locale = this.appSession?.metaData?.locale || Locale.ENGLISH;

    let templatePath: string = this._contentHelper.getResolvedUrl(route);
    // Trim the leading slash from the template path
    templatePath = templatePath.replace(/^\//, '').toUpperCase();

    // Get the content keys from the route data
    const contentKeys = routeContentKeys[templatePath];
    let contents = {};

    // Populate the contents object with the corresponding labels based on the locale
    (contentKeys || []).forEach((contentKey) => {
      contents = { ...contents, [contentKey]: labels[locale.toString()][contentKey] };
    });

    // Return the contents as a promise
    return Promise.resolve(contents);
  }
}
