import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { NavigationComponent } from './navigationCmp';

@NgModule({
    declarations: [NavigationComponent],
    exports: [NavigationComponent],
    imports: [
        CommonModule,
        UxSharedModule.forRoot(),
        FormsModule
    ],
    providers: []
})
export class NavigationModule {
  static forRoot(): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule
    };
  }
}
