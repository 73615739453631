import { Injectable } from '@angular/core';
import { IProvider } from '../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from '../../fad/utilities/commonUtil';
import { ACTION_TAKEN, Locale, TILDE_SYMBOL, TOP_CHOICES_RECORD_COUNT, USER_INTERACTION } from '../constants/app-constants';
import { IActionId, IUserInteraction } from '../interfaces/iUserInteraction';
import { AppSession } from '../values/appSession';

@Injectable({ providedIn: 'root' })
export class UserInteractionUtil {
  private _appSession: AppSession;

  constructor(appSession: AppSession) {
    this._appSession = appSession;
  }

  getUserInteraction(actionObj: string | IActionId, eventType: IUserInteraction): string | undefined {
    if (actionObj && eventType) {
      const actionIdObj: IActionId = this.validateActionIdObj(actionObj);
      const locale: string = this._appSession?.metaData?.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
      const action_Id: string = locale === Locale.SPANISH ? encodeURI(actionIdObj.actionId) : actionIdObj.actionId;
      let userInteraction: IUserInteraction = eventType;
      userInteraction.actionTaken = eventType?.actionTaken ? eventType?.actionTaken : ACTION_TAKEN.click;
      userInteraction.actionId = action_Id;
      userInteraction.addlActionId = actionIdObj.addlActionId;
      userInteraction.actionIdType = actionIdObj.actionIdType;
      userInteraction.locale = locale;
      const { personalizedIndicator, rankPercentile, actionReasonCode, actionReasonTxt } = actionIdObj;
      if (personalizedIndicator !== undefined) {
        userInteraction.personalizedIndicator = personalizedIndicator;
      }
      if (rankPercentile !== undefined) {
        userInteraction.rankPercentile = rankPercentile;
      }
      if (actionReasonCode !== undefined) {
        userInteraction.actionReasonCode = actionReasonCode;
      }
      if (actionReasonTxt !== undefined) {
        userInteraction.actionReasonTxt = encodeURI(actionReasonTxt);
      }
      if (CommonUtil.isValidString(this._appSession?.metaData?.appContract?.mbrUid)) {
        userInteraction.mbrUid = this._appSession.metaData.appContract.mbrUid;
      }
      return JSON.stringify(userInteraction);
    }
    return undefined;
  }

  validateActionIdObj(actionObj: string | IActionId): IActionId {
    let actionIdObj = {} as IActionId;
    if (CommonUtil.isValidString(actionObj)) {
      actionIdObj.actionId = actionObj as string;
    } else {
      let actionIdGrp = actionObj as IActionId;
      actionIdObj.actionId = actionIdGrp.actionId;
      actionIdObj.actionIdType = actionIdGrp.actionIdType ?? undefined;
      actionIdObj.addlActionId = actionIdGrp.addlActionId ?? undefined;
      if (actionIdGrp.rankPercentile !== undefined && actionIdGrp.rankPercentile !== null) {
        actionIdObj.rankPercentile = actionIdGrp.rankPercentile;
      }
      if (actionIdGrp.personalizedIndicator !== undefined && actionIdGrp.personalizedIndicator !== null) {
        actionIdObj.personalizedIndicator = actionIdGrp.personalizedIndicator;
      }
      if (actionIdGrp.actionReasonCode !== undefined && actionIdGrp.actionReasonCode !== null) {
        actionIdObj.actionReasonCode = actionIdGrp.actionReasonCode;
      }
      if (actionIdGrp.actionReasonTxt !== undefined && actionIdGrp.actionReasonTxt !== null) {
        actionIdObj.actionReasonTxt = actionIdGrp.actionReasonTxt;
      }
    }
    return actionIdObj;
  }

  getActionId(professional: IProvider, index: number, isFacility: boolean = false): string {
    let actionIdObj = {} as IActionId;
    let eventType: IUserInteraction;
    if (professional) {
      actionIdObj = this.getActionIdObj(professional, isFacility);
    }
    eventType = this.getSummaryEventType(index);
    return actionIdObj ? this.getUserInteraction(actionIdObj, eventType) : undefined;
  }

  getSearchResultPageActionId(professional: IProvider, index: number, actionTaken: string): string {
    let actionIdObj = {} as IActionId;
    let eventType: IUserInteraction = this.getSummaryEventType(index);
    actionIdObj.actionId = professional ? this.getSurgeonId(professional) : '';
    actionIdObj.actionIdType = professional && professional?.providerCategoryCode?.code ? professional.providerCategoryCode.code : '';
    eventType.actionTaken = actionTaken ? actionTaken : '';
    return actionIdObj ? this.getUserInteraction(actionIdObj, eventType) : undefined;
  }

  getViewDetailsActionId(professional: IProvider, index: number, actionId: string): string {
    let actionIdObj = {} as IActionId;
    let eventType: IUserInteraction = this.getSummaryEventType(index);
    eventType.actionTaken = '';
    actionIdObj.actionId = actionId ? actionId : '';
    actionIdObj.addlActionId = professional ? this.getSurgeonId(professional) : '';
    return actionIdObj ? this.getUserInteraction(actionIdObj, eventType) : undefined;
  }

  private getSurgeonId(professional: IProvider): string {
    if (professional?.providerIdentifier && professional?.location?.address?.addressId) {
      return professional.providerIdentifier + TILDE_SYMBOL + professional.location.address.addressId;
    }
    return null;
  }

  private getFacilityId(professional: IProvider): string {
    if (
      professional?.eycAffliation?.affiliationList?.length &&
      professional?.eycAffliation?.affiliationList[0]?.providerIdentifier &&
      professional?.eycAffliation?.affiliationList[0]?.location?.address?.addressId
    ) {
      return professional.eycAffliation.affiliationList[0].providerIdentifier + TILDE_SYMBOL + professional.eycAffliation.affiliationList[0].location.address.addressId;
    }
    return null;
  }

  private getSummaryEventType(index: number): IUserInteraction {
    if (!this._appSession.hasPersonalizedMatch) {
      return USER_INTERACTION.providerSummaryPage.providerNonPMDetailLink;
    }
    if (index < TOP_CHOICES_RECORD_COUNT && +(!this._appSession.pageNumber ? 1 : +this._appSession.pageNumber) === 1) {
      return USER_INTERACTION.providerSummaryPage.providerTopChoiceDetailLink;
    }
    return USER_INTERACTION.providerSummaryPage.providerMoreOptionDetailLink;
  }

  private getActionIdObj(professional: IProvider, isFacilityRecord: boolean): IActionId {
    let actionIdObj = {} as IActionId;
    if (professional) {
      actionIdObj.actionId = isFacilityRecord ? this.getFacilityId(professional) : this.getSurgeonId(professional);
      actionIdObj.addlActionId = isFacilityRecord ? this.getSurgeonId(professional) : this.getFacilityId(professional);
    }
    return actionIdObj;
  }
}
