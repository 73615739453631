import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CostSlideOutComponent } from './costSlideOutCmp';

@NgModule({
  declarations: [CostSlideOutComponent],
  exports: [CostSlideOutComponent],
  imports: [CommonModule, UxSharedModule]
})
export class CostSlideOutModule { }
