import { Inject, Injectable } from '@angular/core';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import {
  IAction,
  IPersonActions
} from '../interfaces/iPersonalizationChecklist';
import { IPersonActionsComponentMessages, IPersonActionsNoAvailableContentDetails, IPersonalizationArchiveModalContent } from '../interfaces/personalizationContentTypes';
@Injectable()
export class PersonalizationChecklistModel {
  actionItemsDisplayLimit: number;
  isActionItemRemovedFromOriginalList: boolean = false;
  noActionAvailable: IPersonActionsNoAvailableContentDetails;
  actions: IAction[];
  personalizedSessionId: string;
  archiveModalContent: IPersonalizationArchiveModalContent;
  componentMessages: IPersonActionsComponentMessages;
  private _lastSelectedNextStepIndex: number = 0;
  private _lastSelectedNextSteps: IAction;

  constructor(
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  public removeLastSelectedFromYourNextSteps() {
    this.isActionItemRemovedFromOriginalList = true;
    this.actions.splice(this._lastSelectedNextStepIndex, 1);
  }

  /**
   * @param lastSelectedNextStepIndex number
   * @returns next step index
   */
  public set lastSelectedNextStepsIndex(lastSelectedNextStepIndex: number) {
    this._lastSelectedNextSteps = this.actions[lastSelectedNextStepIndex];
    this._lastSelectedNextStepIndex = lastSelectedNextStepIndex;
  }

  public get youHaveNextSteps(): boolean {
    return Boolean((this.actions || []).length);
  }
  /**
   *
   * @param personalizationActionApiResponse action details from strapi
   * @param content details from wcs
   * @returns seed object
   */
  public transformApi(response: IPersonActions) {
    this.actions = [];
    const content = this._contentHelper.getContent(
      'PFSearchContainerComponent'
    );

    if (this._appSession?.metaData?.appContract?.v2Features?.hasEngagement) {
      this.noActionAvailable = content?.personalization?.noActionAvailable?.MHD;
    } else if (
      this._appSession?.metaData?.appContract?.v2Features?.hasHealthWellness
    ) {
      this.noActionAvailable = content?.personalization?.noActionAvailable?.HWC;
    } else {
      this.noActionAvailable =
        content?.personalization?.noActionAvailable?.default;
    }

    this.actionItemsDisplayLimit = isNaN(
      content?.personalization?.actionItemsDisplayLimit
    )
      ? 3
      : content?.personalization?.actionItemsDisplayLimit;
    if (!response?.actionList) {
      return;
    }
    this.archiveModalContent = content?.personalization?.archiveModal;
    this.personalizedSessionId = response?.personalizedSessionId;
    this.componentMessages = content?.personalization?.componentMessages;
    response.actionList?.forEach((action) => {
      action.archived = true;
      action.availableFeedback = {
        cta: {
          text: action?.contentCTA,
          analyticTag: action?.analyticTagCTA,
          ariaLabel: action?.ariaLabelCTA,
          destination: action?.destination,
        },
        archive: {
          ariaLabel: action?.dismissAriaLabel,
          text: '',
          analyticTag: `dismiss-${action?.actionId}`,
          modal: {
            removeSeed: {
              text: content?.personalization?.archiveModal?.footer?.btnArchive
                ?.text,
              analyticTag: `${content?.personalization?.archiveModal?.footer?.btnArchive?.analyticTag}-${action.actionId}`,
              ariaLabel:
                action.dismissModalAriaLabel ||
                content?.personalization?.archiveModal?.footer?.btnArchive
                  ?.ariaLabel, // The ariaLabel is also configurable in STRAPI
            },
          },
        },
      };

      if (!action.availableFeedback.cta.ariaLabel) {
        action.availableFeedback.cta.ariaLabel = `${content?.displayedText} ${action?.availableFeedback?.cta?.text}`;
      }
      if (!action.availableFeedback.archive.ariaLabel) {
        action.availableFeedback.archive.ariaLabel = `${action?.availableFeedback?.archive?.text}`;
      }
      this.actions.push(action);
    });
  }
}
