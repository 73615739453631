import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, SnackbarAlertContainerComponent } from '@anthem/uxd/alert';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { SliderDirection } from '../../../constants/common';
import { IAppContract } from '../../../interfaces/iAppContract';
import { IContract } from '../../../interfaces/iContract';
import { IMember } from '../../../interfaces/iMember';
import { BootstrapService } from '../../../store/bootstrapSvc';
import { BaseComponent } from '../../core/baseCmp';
import { SNACKBAR } from './../../../../../common/constants/app-constants';

@Component({
  selector: 'app-fc-search-header-cmp',
  templateUrl: './searchHeaderCmp.html'
})
export class SearchHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  members: IMember[] = [];
  activeMember: IMember = undefined;
  modalActiveMember: IMember = undefined;
  sidePanelRef: ModalRef<HTMLElement>;
  sliderDirection = SliderDirection;
  @ViewChild('viewFamilyMemberModal') viewFamilyMemberModal: TemplateRef<HTMLElement>;
  @ViewChild('snackbarModal') snackbarModal: SnackbarAlertContainerComponent;
  @ViewChild('contractChangeAlertTemplate', { static: false }) contractChangeAlertTemplate: TemplateRef<HTMLElement>;

  isMultipleMember = false;
  private _contractSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    private _bootstrapService: BootstrapService,
    private _sidePanel: SidePanel,
    private _alertService: AlertService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract) => {
      this.activeMember = appContract?.selectedContract;
      this.initializeMembers();
    });
  }

  /**
   * Method to open the side panel to view all family members
   * @param direction Direction to open the side panel
   */
  viewAllFamilyMembers(direction: SliderDirection) {
    this.modalActiveMember = this.activeMember;
    this.sidePanelRef = this._sidePanel.open(direction, this.viewFamilyMemberModal);
  }

  viewAllMembersChange(viewMember: IContract) {
    this.modalActiveMember = viewMember;
  }

  /**
   * Set the selected member in bootstrap service and close the modal
   */
  onViewAllContinue() {
    this._bootstrapService.setAppContract(this.modalActiveMember.mbrUid);
    this.sidePanelRef.close();
  }

  /**
   * Close the modal without changing the selected member
   */
  onViewAllCancel() {
    this.sidePanelRef.close();
  }

  /**
   * Gets the updated image URL based on the condition.
   * @returns The updated image URL.
   */
  getUpdatedImageUrl() {
    const imgUrl = this.isFtsFeatureActive ? 'fcr_refresh.svg' : 'fcr_Idea_bulb.svg';
    return this.getCommonImageURL(imgUrl);
  }

  /**
   * Opens a Snackbar alert with the contract change alert template.
   */
  showContractChangeAlert() {
    if (!this.snackbarModal?.alerts?.length) {
      this._alertService.success(this.contractChangeAlertTemplate, {
        regionName: SNACKBAR,
        isDissmisable: true
      });
    }
  }

  /**
   * Initializes members from the bootstrap service.
   */
  private initializeMembers() {
    this.members = this._bootstrapService.members;
    this.isMultipleMember = this.members.length > 1;
    this.addInitialsToMembers();
  }

  /**
   * Adds initials to the members.
   */
  private addInitialsToMembers() {
    for (const member of this.members) {
      const initials = member.firstNm?.charAt(0) + member.lastNm?.charAt(0);
      member.initials = initials;
    }
  }

  ngOnDestroy(): void {
    this._contractSubscription?.unsubscribe();
  }

  get isFtsFeatureLive(): boolean {
    return this._appSession.isFtsFeatureLive;
  }
}
