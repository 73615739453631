import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPlanList } from '../models/iPlanList';
import { IProviderPlansRequest } from '../models/iProviderPlansRequest';
import { IProviderPlansResponse } from '../models/iProviderPlansResponse';
import { ProviderOrchestrationService } from '../services/providerOrchestrationSvc';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { Locale } from './../../../common/constants/app-constants';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { ProviderDetailsUtils } from './../../utilities/providerDetailsUtils';

@Component({
  moduleId: module.id,
  selector: 'app-fad-plans-accepted-cmp',
  templateUrl: '../views/pfPlansAcceptedCmp.html'
})
export class PFPlansAcceptedComponent extends BaseComponent implements OnInit {
  @Input()
  showPlansLoader: boolean;
  @Input()
  showPlansError: boolean;
  @Input()
  selectedProvider: any;
  @Input()
  inputIndex = 0;
  @Input()
  selectedAddressId?: any;
  @Input()
  pageName?: any;
  @Input()
  plansResponse: IProviderPlansResponse;
  @Output()
  planLoaded: EventEmitter<IProviderPlansResponse> = new EventEmitter<IProviderPlansResponse>();

  insurancePlans: any;
  locale: Locale;
  visualIndicators: any = {};
  showVisualIndicators: boolean = false;

  constructor(
    private _providerOrchestrationService: ProviderOrchestrationService,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale : Locale.ENGLISH;
    if (this.waitUntilAppReload) {
      return;
    }
    if (this.plansResponse) {
      this.onPlansAcceptedSuccess(this.plansResponse);
    } else {
      this.getPlansAccepted('');
    }
    this.getVisualIndicators();
  }

  ngOnChanges(args: unknown): void {
    if (!this.plansResponse) {
      if (ProviderDetailsUtils.hasValueChanged(args, 'selectedAddressId')) {
        this.getPlansAccepted(args['selectedAddressId'].currentValue);
      } else if (ProviderDetailsUtils.hasValueChanged(args, 'selectedProvider', 'providerIdentifier')) {
        this.getPlansAccepted('');
      }
    }
  }

  getVisualIndicators() {
    let indicatorList: any = [];
    if (this.selectedProvider && this.selectedProvider.visualIndicators && this.selectedProvider.visualIndicators.indicators && this.selectedProvider.visualIndicators.indicators.length > 0) {
      let visIndList = this.selectedProvider.visualIndicators;
      for (const ind of visIndList.indicators || []) {
        indicatorList.push({
          code: ind.code,
          label: ind.label,
          statusTxt: ind.statusText,
          statusIcon: this.getStatusIcon(ind.value)
        });
      }
      this.visualIndicators = {
        indicators: indicatorList,
        title: visIndList.title,
        disclaimer: visIndList.disclaimer
      };
      if (this.pageName === 'details' && this.visualIndicators && this.visualIndicators.indicators && this.visualIndicators.indicators.length > 0) {
        this.showVisualIndicators = true;
      }
    }
  }

  getStatusIcon(value: any) {
    let className: string;
    switch (value) {
      case 'Y':
        className = 'in-network-key-icon in-network-key-icon-checkmark motif-icon motif-checkmark';
        break;
      case 'N':
        className = 'in-network-key-icon in-network-key-icon-notinplan motif-icon motif-delete';
        break;
      default:
        className = 'in-network-key-icon in-network-key-icon-na faux-icon-na';
        break;
    }
    return className;
  }

  getPlansAccepted(changedAddressId) {
    const plan: IPlanList = {
      identifier: this._appSession.searchParams.plan && this._appSession.searchParams.plan.identifier ? this._appSession.searchParams.plan.identifier.trim() : null,
      isNationalPlan: this._appSession.searchParams.plan.isNationalPlan
    };

    const netwrkList: any[] = [];

    for (const network of this._appSession.searchParams.plan.networkList || []) {
      const net = {
        code: network
      };

      netwrkList.push(net);
    }
    const addrId = this.selectedProvider.location && this.selectedProvider.location.address ? this.selectedProvider.location.address.addressId : '';

    let plansResponse: IProviderPlansResponse;
    const states: string[] = [];
    if (this.selectedProvider.location?.address?.state) {
      states.push(this.selectedProvider.location?.address?.state);
    }
    const providerPlansParams: IProviderPlansRequest = {
      id: this.selectedProvider.providerIdentifier,
      addressId: changedAddressId || addrId,
      brandCode: this._appSession.metaData.brandCd,
      locale: this.locale,
      stateList: states
    };
    if (this._appSession.searchParams?.plan?.stateCd && this._appSession.searchParams?.plan?.stateCd !== this.selectedProvider.location?.address?.state) {
      providerPlansParams.stateList.push(this._appSession.searchParams.plan.stateCd);
    }
    this._providerOrchestrationService
      .getPlansAccepted(providerPlansParams)
      .then(
        (result: IProviderPlansResponse) => {
          plansResponse = result;
          this.onPlansAcceptedSuccess(result);
        },
        (error: any) => {
          this.onError(error);
        }
      )
      .finally(() => {
        this.planLoaded.emit(plansResponse);
      });
  }

  translatePlanCategoriesIntoSpanish(insurancePlans: any) {
    insurancePlans.forEach((planCategory: any, index: number) => {
      insurancePlans[index].category = this.WcsUtility.getSpanishPlanCategoryName(planCategory.code, planCategory.category);
    });
    return insurancePlans;
  }

  onPlansAcceptedSuccess(result: IProviderPlansResponse) {
    this.showPlansLoader = false;
    if (result == null) {
      this.showPlansError = true;
      return;
    }
    if (this.locale === Locale.SPANISH) {
      this.insurancePlans = this.translatePlanCategoriesIntoSpanish(result.insurancePlans);
    } else {
      this.insurancePlans = result.insurancePlans;
    }
  }

  onError(error: any) {
    this.showPlansLoader = false;
    this.showPlansError = true;
    throw error;
  }

  isEmpty(temp: any) {
    if (temp && temp.length > 0) {
      return false;
    }
    return true;
  }
}
