import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { ContentHelper } from '../../../values/contentHelper';
import { BaseComponent } from '../../base-component/baseCmp';
import { ActionItemLookupService } from './../services/actionItemLookupService';
import { ActionItemCmp } from './actionItemCmp';

@Component({
  selector: 'app-action-item-look-up-cmp',
  templateUrl: './actionItemLookupCmp.html'
})
export class ActionItemLookupComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() showButton: boolean = false;
  @Input() parentComponent: ActionItemCmp;
  @Input() items:  Array<string>;
  @Input() showHeader: boolean = false;
  @Input() headerText: string = '';
  @Input() lookupCSS: string = '';
  @Input() isFeedbackMenu = false;
  @Output() readonly actionItemClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _pillItemLookupService: ActionItemLookupService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, "PFSearchContainerComponent");
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnDestroy() {
    if (typeof this.parentComponent !== 'undefined' && this.parentComponent !== null) {
      this.parentComponent.selected = false;
    }
  }

  getDataAnalytics(items: string): string {
    let dataAnalytics: string = "";
    switch (items) {
      case this.content.newLandingComponent.actionItemsLabel.dismissOption: {
        dataAnalytics = "dismissCareActionFindCare";
        break;
      }
      case this.content.newLandingComponent.actionItemsLabel.removeFromCareTeam:{
        dataAnalytics = "removeCareTeamMenuFindCare";
        break;
      }
      case this.content.newLandingComponent.actionItemsLabel.changePCP:{
        dataAnalytics = "changePcpTeamMenuFindCare";
        break;
      }
      case  this.content.newLandingComponent.actionItemsLabel.memberSvcChangePCP:{
        dataAnalytics = "changePcpTeamMenuFindCare";
        break;
      }
      case  this.content.newLandingComponent.actionItemsLabel.bookAppointment:{
        dataAnalytics = "bookApptTeamMenuFindCare";
        break;
      }
    }
    return dataAnalytics;
  }

  moreOptionClick(item: string) {
    this.actionItemClick.emit(item);
  }

}
