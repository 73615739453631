import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { ProviderDetailsNavigationService } from '../../../fad/provider-details/services/providerDetailsNavigationSvc';
import { PROVIDER_CATEGORY_CODE, PROVIDER_TYPE } from '../../../fad/search-providers/values/providerSearchConstants';
import { ICostInfo, ReviewResponse } from '../../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { IFinalProvider } from '../../interfaces/iCommonSearchResponse';
import { PhonePatternPipe } from '../../pipes/phonePatternPipe';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { ILocation, IProvider } from './../../../fad/search-results/interfaces/iSummaryResp';

@Component({
  moduleId: module.id,
  selector: 'app-fad-tile-view-cmp',
  templateUrl: './pfTileViewCmp.html'
})

export class PFTileViewComponent extends BaseComponent {
  @Input()
  set providerInfo(value: any) {
    this.professional = value;
    this.location = CommonUtil.swapOfficeLocation(this.showSPFI, this.professional);
  }

  @Input()
  set index(value: number) {
    this.i = value;
  }

  @Input()
  set drivingTimeSortList(value: unknown) {
  this.providerList = value;
  }

  @Input()
  proSearchResultsCheckboxs: any;

  @Input()
  isEYC: boolean = false;

  @Input()
  toolCostInfo: boolean = false;

  @Input()
  showBeFirstToReview: boolean = true;

  @Input()
  isShowDistance: boolean = true;

  @Input()
  isMemberSecure: boolean = false;

  @Output()
  checkboxClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showInNetworkLabel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  showProviderDetails: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openRecognitionModal: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openDomainDisclaimerModal: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openLocationMapModal: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openSidePanel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  appointmentSchedule: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  assignCare: EventEmitter<any> = new EventEmitter<any>();

  professional: any;
  location: ILocation;
  i: number = 0;
  showMoreLink: boolean = false;
  vitalsSsoConfig: any;
  providerList: unknown;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _phonePatternPipe: PhonePatternPipe,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService) {
      super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  get isDentalSearch(): boolean {
    return (this._appSession.hasDentalEYC && this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL);
  }

  onCheckboxClick(event: any, provider: any, id: string) {
    this.checkboxClick.emit({
      event: event,
      provider: provider,
      id: id
    });
  }

  isShowInNetworkLabel(professional) {
    this.showInNetworkLabel.emit(professional);
  }

  showAffiliation(professional: IProvider) {
    return this.isEYC && this.showSPFI && professional && professional.eycAffliation && professional.eycAffliation.affiliationList[0] && professional.eycAffliation.isVisibleAffiliation;
  }

  getPairedProviderLabel(code: string): string {
    if (code) {
      return code === PROVIDER_CATEGORY_CODE.Individual ? this.content.summaryComponent.providingCareAt : this.content.summaryComponent.performedBy;
    }
    return '';
  }

  onOpenLocationMapModal(professional) {
    this.openLocationMapModal.emit(professional);
  }

  onShowProviderDetails(professional) {
    this.showProviderDetails.emit(professional);
  }

  onOpenRecognitionModal(recognition: any) {
    this.openRecognitionModal.emit(recognition);
  }

  onOpenDomainDisclaimerModal(url: any) {
    this.openDomainDisclaimerModal.emit(url);
  }

  onOpenSidePanel(direction: any, professional: any) {
    this.openSidePanel.emit({
      direction: direction,
      professional: professional
    });
  }

  onAppointmentSchedule(data: any) {
    this.appointmentSchedule.emit(data);
  }

  onAssignCare(data: any) {
    this.assignCare.emit(data);
  }

  isGreyedout(professional: any, enableFutureProvider: boolean): boolean {
    // Feature flag to disable future effective provider navigation to details page.
    if (!this.isFutureEffectiveProviderEnabled) {
      enableFutureProvider = false;
    }

    if ((professional && professional.eycCostInfo && professional.eycCostInfo.isGreyedout)) {
      return true;
    } else if (!enableFutureProvider && professional && professional.isFutureEffective) {
      return true;
    }
    return false;
  }

  getGenderText(genderCode: any) {
    return this.WcsUtility.getGenderText(genderCode);
  }

  getPCPLabelText(professional: any): string {
    return (professional.gender && professional.gender !== '') ?
      ', ' + this.content.summaryComponent.pcpid : this.content.summaryComponent.pcpid;
  }

  private getMoreRating(event: Event) {
    this.showMoreLink = !this.showMoreLink;
    event.preventDefault();
  }

  vitalsSsoConfigValue(professional: any, showAddReviewLabel?: boolean, useClassForAddReview?: boolean) {
    this.vitalsSsoConfig = this.WcsUtility.getVitalsSsoConfig(professional, showAddReviewLabel, useClassForAddReview);
    return this.vitalsSsoConfig;
  }

  showReviewLink(totalReviews: any) {
    return CommonUtil.showReviewLink(totalReviews);
  }

  checkIndividualProvWithProvTypeP(provCategoryCode: string, providerTypeCodeList: Array<string>) {
    return CommonUtil.checkIndividualProvWithProvTypeP(provCategoryCode, providerTypeCodeList);
  }

  isReviewRating(rating: ReviewResponse, provCategoryCode: string, providerTypeCodeList: Array<string>) {
    return CommonUtil.isReviewRating(rating, provCategoryCode, providerTypeCodeList);
  }

  showAssignPCPBtn(professional: any): boolean {
    if ((professional && professional.visibilityRule && professional.visibilityRule.showAssignPCP )
     && this._appSession.feature?.customizedFeatures?.showAssignPCPBtn) {
      return true;
    }
    return false;
  }

  showAddCareTeamBtn(professional: any): boolean {
    if (this.isAddCareTeamEnabled &&
      professional && professional.visibilityRule && professional.visibilityRule.showAddCareTeam &&this.customizedFeatures.showAdddCareTeam ) {
      return true;
    }
    return false;
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  administrativeOffice(activeFlagList: Array<string>): boolean {
    return this.WcsUtility.isAdministrativeOffice(activeFlagList);
  }

  formatDistance(distance: string) {
    return (distance ? parseFloat(distance).toFixed(2) : '');
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  onTileViewHover(professional: any) {
    if (!this.isMobileView) {
      this._providerDetailsNavigationService.highlightMapMarker(professional);
    }
  }

  onTileViewHoverOut(professional: any) {
    if (!this.isMobileView) {
      this._providerDetailsNavigationService.hideMapMarker(professional);
    }
  }

  supressCheckbox(): boolean {
    return CommonUtil.supressCheckbox(this.providerList);
  }

  getEycCostInfo(professional: IProvider): ICostInfo {
    return CommonUtil.swapEycCostInfo(this.showSPFI, professional);
  }

  showAddReviewButton(professional: IFinalProvider): boolean {
    if (professional?.reviewRatings?.length && !isEmpty(professional.reviewRatings[0])) {
      return this.checkIndividualProvWithProvTypeP(professional.providerCategoryCode?.code, professional.providerTypeCodeList) && professional.reviewRatings[0]?.pdtStrKey && this.showBeFirstToReview && !this.isGreyedout(professional, false);
    }
    return false;
  }
}
