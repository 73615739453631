import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { EventHandler } from './../../services/eventHandler';
@Component({
  selector: 'app-pf-pagination',
  templateUrl: './pfPaginationCmp.html'
})
export class PFPaginationComponent extends BaseComponent {

  @Input()
  set totalPages(value: number) {
    if (value) {
      this._totalPages = value;
    }
  }
  @Input()
  set selectedPageNumber(value: number) {
    if (value) {
      this._curPageNumber = value;
    }
  }
  @Output()
  pageChanged = new EventEmitter<number>();

  _curPageNumber = 1;
  _totalPages = 0;

  get numbersToDisplay(): number[] {
    const min = 1;
    let length = this._noOfPageNoToDisplay;
    let start = this._curPageNumber - Math.floor(length / 2);
    start = Math.max(start, min);
    const minVal = min + (this._totalPages - length);
    start = Math.min(start, (minVal < 1) ? min : minVal);
    if (start + this._noOfPageNoToDisplay > this._totalPages) {
      length = this._totalPages - start + 1;
    }
    return Array.from({ length }, (el, i) => start + i);
  }

  private _noOfPageNoToDisplay = 3;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }
  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }
  onNextClick() {
    this._curPageNumber = this._curPageNumber + 1;
    this.emitPageChangedEvent();
  }

  onPreviousClick() {
    this._curPageNumber = this._curPageNumber - 1;
    this.emitPageChangedEvent();
  }

  onFirstClick() {
    this._curPageNumber = 1;
    this.emitPageChangedEvent();
  }

  onLastClick() {
    this._curPageNumber = this._totalPages;
    this.emitPageChangedEvent();
  }

  onPageNoClick(pageNo: number) {
    this._curPageNumber = pageNo;
    this.emitPageChangedEvent();
  }

  private emitPageChangedEvent() {
    this.pageChanged.emit(this._curPageNumber);
  }

}
