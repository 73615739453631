import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { ISecureSpecialtySearchRequest } from '../../../common/interfaces/iSecureSpecialtySearchRequest';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ISearchFilterResponse } from '../interfaces/iSearchFilter';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(searchReq: ISecureSpecialtySearchRequest): Promise<ISearchFilterResponse> {
    const apiUrl = searchReq?.memberCriteria ? apiNameList.restApi.searchFilterSecureV1 : apiNameList.restApi.searchFilterPublicV1;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: searchReq,
      headers: this.getHeaders()
    });
  }
}
