import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'weekdays' })
export class WeekdaysPipe implements PipeTransform {
  transform(value: any, content: any, open24hours: boolean = false) {
    if ((typeof value === 'undefined' || value === null || value === '') ||
      (!content || typeof content.weekdays === 'undefined' || content.weekdays === null)) {
      return '';
    }

    if (open24hours === true) {
      return content.weekdays[value] + ' ' + content.summaryComponent.open24Hours;
    } else {
      const textToReplace = value.substr(0, value.indexOf(' '));
      if (content.weekdays[textToReplace]) {
        return value.replace(textToReplace, content.weekdays[textToReplace]);
      }
      return value;
    }
  }
}
