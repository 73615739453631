import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProvider } from '../interfaces/iProvider';

@Injectable({ providedIn: 'root' })
export class CompareProvider {
  private providersSet = new Set<IProvider>();
  private selectedProviders = new BehaviorSubject<IProvider[]>([]);

  get compareProvider() {
    return this.selectedProviders.asObservable();
  }

  add(provider: IProvider): void {
    if (!this.providersSet.has(provider)) {
      this.providersSet.add(provider);
      this.updateSelectedProviders();
    }
  }

  remove(provider: IProvider): void {
    if (this.providersSet.delete(provider)) {
      this.updateSelectedProviders();
    }
  }

  clear(): void {
    this.providersSet.clear();
    this.updateSelectedProviders();
  }

  private updateSelectedProviders(): void {
    this.selectedProviders.next(Array.from(this.providersSet));
  }
}
