import { IReason } from './../../fad/assign-pcp/models/iReasonCodeList';
import { IAffiliationRadioInput, IPcpIdItem } from './iPCPInfo';

export interface IPcpRequest {
  contractUid: string;
  selMbrUid: string;
  providerName?: string;
  pcpInfo: {
    pcpId: string;
    pcpTrmntnRsnCd: string;
  };
  validate?: boolean;
  multi_updates?: string;
  fromSeed?: boolean;
}

export interface IPcpValidateRequest {
  otherReasonCd: string;
  selMbrUid: string;
  providerName?: string;
  pcpIdItems: IPcpIdItem[];
}

export interface IPcpModifyRequest {
  providerName: string;
  selMbrUid: string;
  selectedAffliation: IAffiliationRadioInput;
  selectedReason: IReason;
}

export const ACTIVE_COVERAGE = 'A';
export const FUTURE_COVERAGE = 'F';
export const BOTH_COVERAGES = 'B';

export const SUPPORTED_COVERAGES = [ACTIVE_COVERAGE, FUTURE_COVERAGE, BOTH_COVERAGES];
