import { DOCUMENT } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { ChangePlanService } from '../../../common/components/change-plan/services/changePlanSvc';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { LocationService } from '../../../common/components/location/services/locationService';
import { IWindow } from '../../../common/interfaces/iWindow';
import { DataHelper } from '../../../common/services/dataHelper';
import { EventHandler } from '../../../common/services/eventHandler';
import { FeatureFlagService } from '../../../common/services/featureFlagInitializer';
import { NavigationService } from '../../../common/services/navigationService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { RouteUtil } from '../../../common/utilities/routeUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { ProcedureCategoryService } from '../../../eyc/cost-search/services/procedureCategorySvc';
import { ProviderOrchestrationService } from '../../provider-details/services/providerOrchestrationSvc';
import { RulesService } from '../../rules/services/RulesSvc';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { PFDynamicSearchLinksV2Component } from './pfDynamicSearchLinksV2Cmp';
import { PFFreeTxtSearchComponent } from './pfFtsCmp';
import { PFIntegratedSearchComponent } from './pfIntegratedSearchCmp';
import { PFSearchContainerComponent } from './pfSearchContainerCmp';


@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-search-v2-cmp',
  templateUrl: '../views/pfSearchContainerV2Cmp.html',
  providers: []
})
export class PFSearchContainerV2Component extends PFSearchContainerComponent {

  constructor(
    _eventHandler: EventHandler,
    @Inject(AppSession)
    _appSession: AppSession,
    _providerUtilityService: ProviderUtilityService,
    _route: ActivatedRoute,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    _navigationService: NavigationService,
    _router: Router,
    @Inject('Window')
    _window: IWindow,
    @Inject(DataHelper)
    _dataHelper: DataHelper,
    _appUtility: AppUtility,
    _featureFlagSvc: FeatureFlagService,
    _changePlanService: ChangePlanService,
    _procedureCategoryService: ProcedureCategoryService,
    _ruleService: RulesService,
    _providerOrchestrationService: ProviderOrchestrationService,
    _infoListSvc: InfoListService,
    _routeUtil: RouteUtil,
    _locationService: LocationService,
    @Inject(DOCUMENT)
    _document: Document
  ) {
    super(
      _eventHandler,
      _appSession,
      _providerUtilityService,
      _route,
      _contentHelper,
      _navigationService,
      _router,
      _window,
      _dataHelper,
      _appUtility,
      _featureFlagSvc,
      _changePlanService,
      _procedureCategoryService,
      _ruleService,
      _providerOrchestrationService,
      _infoListSvc,
      _routeUtil,
      _locationService,
      _document
    )
  }

  @ViewChild(PFDynamicSearchLinksV2Component)
  dynamicSearchLinksCmp;

  get integratedSearchCmp(): PFIntegratedSearchComponent {
    return this.dynamicSearchLinksCmp?.integratedSearchCmp;
  }

  get ftSearchCmp(): PFFreeTxtSearchComponent {
    return this.dynamicSearchLinksCmp?.ftSearchCmp;
  }

  get isRebrandConfigAvailable(): boolean {
    return !isEmpty(this.rebrandConfig);
  }
}
