import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppUtility } from './appUtil';
import { RouteUtil } from './routeUtil';

@NgModule({
    imports: [],
    declarations: [],
    exports: []
})
export class UtilityModule {
  static forRoot(): ModuleWithProviders<UtilityModule> {
    return {
      ngModule: UtilityModule,
      providers: [
        RouteUtil,
        AppUtility
      ]
    };
  }
}
