import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-deselection-cmp',
  templateUrl: './filterDeselectionCmp.html',
  providers: []
})
export class FilterDeselectionComponent extends BaseComponent implements OnInit, OnDestroy {
  allFilters: IAppliedSearchFilter[] = [];
  filters: IAppliedSearchFilter[] = [];
  showMoreText: string;
  showMore: boolean = true;
  showMoreCount: number = 5;
  hasMoreFilters: boolean = false;
  private appliedFilter: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _appliedFilter: AppliedSearchFilter
  ) {
    super(_route, _eventHandler, _appSession);

    this.appliedFilter = this._appliedFilter.getAppliedFilters.subscribe((data) => {
      if (data) {
        this.loadFilters(data);
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnDestroy() {
    if (this.appliedFilter) {
      this.appliedFilter.unsubscribe();
    }
  }

  removeFilter(filter: IAppliedSearchFilter) {
    this._appliedFilter.removeFilter(filter);
  }

  showMoreFilters() {
    this.showMore = false;
    this.filters = this.allFilters;
  }

  showLessFilters() {
    this.showMore = true;
    this.filters = this.allFilters.slice(0, this.showMoreCount);
  }

  private loadFilters(appliedFilters: IAppliedSearchFilter[]) {
    this.showMore = true;
    this.filters = [];
    this.allFilters = [];
    appliedFilters?.forEach((filterOption: IAppliedSearchFilter) => {
      this.allFilters.push({
        name: filterOption.name,
        value: filterOption.value,
        category: filterOption.category
      } as IAppliedSearchFilter);
    });

    this.filters = this.allFilters.slice(0, this.showMoreCount);
    const moreFilterCount = this.allFilters.length - this.showMoreCount;
    this.showMoreText = this.content.result.filter.showMoreFilters.replace(/{COUNT}/gi, moreFilterCount.toString());
    this.hasMoreFilters = moreFilterCount > 0;
  }
}
