import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPipesModule } from '../../pipes/customPipesModule';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { CardsModule } from '../cards-list/cardsModule';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { CommonDirectivesModule } from './../../directives/commonDirectivesModule';
import { PfInfoListComponent } from './components/pfInfoListCmp';

@NgModule({
    declarations: [PfInfoListComponent],
    exports: [PfInfoListComponent],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule,
        CardsModule,
        CommonDirectivesModule,
        CustomPipesModule
    ],
    providers: []
})
export class InfoListModule { }
