import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EycProcedureCriteria } from '../../../fad/search-results/interfaces/iSearchRequest';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { IProcedure } from '../interfaces/iProcedure';
import { IProviderType } from '../interfaces/iProviderType';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { MODAL_TYPE } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { IOptions } from './../../../common/interfaces/iAppMetadata';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { EycSearchParam } from './../../../fad/search-results/interfaces/iSearchRequest';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { PROVIDER_SEARCH_DISTANCE } from './../values/providerSearchConstants';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-providers-procedures-cmp',
  templateUrl: '../views/pfProvidersAndProceduresCmp.html',
  providers: []
})

export class PFProvidersAndProceduresComponent extends BaseComponent implements OnInit {
  @Output()
  modal: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  analytics: EventEmitter<IAdobeSearchDetail> = new EventEmitter<IAdobeSearchDetail>();
  @Input()
  providerTypes: IProviderType[] = [];
  @Input()
  procedures: any = [];
  @Input()
  searchParams: ISearchParameters;
  @Input()
  planUnknownError: boolean = false;

  searchCriteria: ISearchParameters;
  providerTypeList: IProviderType[] = [];
  procedureList: any = [];
  hasEYC: boolean = false;
  isMemberSecure: boolean = false;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _appUtility: AppUtility,
    private _navigationService: NavigationService,
    private _ruleService: RulesService,
    @Inject(DataHelper)
    private _dataHelper: DataHelper) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    if (this._appSession) {
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
      this.hasEYC = this.isMemberSecure && (this._appSession.hasEYC ||
        (this._appSession.hasDentalEYC && this.showDentalDDS));
    }
  }

  ngOnChanges(args: any) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.searchCriteria = args['searchParams'].currentValue;
    }

    // Bind Provider Types
    if (args['providerTypes'] && args['providerTypes'].currentValue) {
      this.getProviderTypes(args['providerTypes'].currentValue);
    }

    // Bind Procedures
    if (args['procedures'] && args['procedures'].currentValue) {
      this.getProcedures(args['procedures'].currentValue);
    }

  }

  getProviderTypes(provTypes: IProviderType[]) {
    this.providerTypeList = [];
    const _providerTypes = this.content.commonHealthCareContent.providers;

    this.providerTypeList = CommonUtil.getProviderTypes(provTypes, _providerTypes);
  }

  getProcedures(proceList: any) {
    this.procedureList = [];
    if (proceList && proceList.length > 0) {
      for (const procedure of proceList) {
        const _procList: IProcedure = {
          code: procedure.procedureCode ?
          procedure.procedureCode.code.toUpperCase().trim() : '',
          name: procedure.procedureCode ?
          procedure.procedureCode.name : '',
          description: procedure.procedureCode ?
           procedure.procedureCode.description : '',
          categoryCode: procedure.providerCategoryCode ?
           procedure.providerCategoryCode.code : '',
          costDescription: '',
          adobeTag: ''
        };
        this.procedureList.push(_procList);
      }
    }
  }

  searchByProviderType(providerType: IProviderType) {
    if (this.isLocationValid() && !this.planUnknownError) {
      this.searchParams.typeSelectNm = providerType.code;
      this.searchParams.coverageTypeCode = '';
      this.searchParams.specialtySelectNm = [];
      this.searchParams.taxonomySelectNm = [];
      this.searchParams.providerTypeCodeList = [];

      this.searchParams = CommonUtil.buildStandAlone(this.searchParams, this._appSession);
      this.searchParams.providerTypeCodeList.push(this.searchCriteria.typeSelectNm);
      // Medsupp scenario
      if (this._appSession?.planRule && this._appSession?.planRule?.isMedicareGov) {
        this.searchParams.standAloneVision = true;
      }
      this.searchCriteria = this.searchParams;
      this.setAnalytics(providerType.name, false);
      this.resetAppSessionParams();

      //Set Flag for Pre Filtering BCP filter
      const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
      let isSearchCriteriaPath: boolean = false;
      if (_routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH) {
        isSearchCriteriaPath = true;
      }

      this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession))
        .then((result: IRulesResponse) => {
          const _result = result;
          if (_result) {
            if (_result.showMedicareSupplement) {
              this.modal.emit(MODAL_TYPE.MEDICARE_SUPPLEMENT);
            } else {
              this._appSession.feature = _result;
              if (this._appSession && isSearchCriteriaPath &&
                _result.showBCP && _result.showPreFilterBCP && this._appSession.searchParams) {
                this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
              }
              this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
            }
          }
        },
          (error: any) => {
            this.onCategoryError('RULE', error);
          }
        );
    }
  }

  searchByProcedure(procedure: IProcedure) {
    if (this.isLocationValid()) {
      this.searchCriteria.typeSelectNm = '';
      this.searchParams.coverageTypeCode = '';
      this.searchParams.specialtySelectNm = [];
      this.searchParams.taxonomySelectNm = [];
      this.searchParams.providerTypeCodeList = [];

      const eycCriteria = new EycProcedureCriteria();
      const eycSearchParam = new EycSearchParam();

      this.searchCriteria.providerTypeCodeList = [];
      eycCriteria.procedureCode = procedure.code;
      eycCriteria.providerCategoryCode = procedure.categoryCode;
      eycCriteria.procedureName = procedure.name;
      eycSearchParam.procedureDescription = procedure.description;
      eycSearchParam.costDescription = procedure.costDescription;
      eycSearchParam.eycProcedureCriteria = eycCriteria;

      this.searchCriteria.eycSearchParam = eycSearchParam;
      this.searchCriteria.brandCode = this._appSession.metaData.brandCd;

      if (this._appSession.metaData &&
        this._appSession.metaData.appContract &&
        this._appSession.metaData.appContract.mbrUid) {

        const _index = this._appSession.metaData.appContract.memberList.findIndex((x) =>
          x.mbrUid === this._appSession.metaData.appContract.mbrUid);
        if (_index > -1) {
          const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
          this.searchCriteria.selectedMem = _member.firstName + ' ' + _member.lastName;
        }
        this.searchCriteria.memberSeqNum = this._appSession.metaData.appContract.memberSeqNum;
      }

      this.setAnalytics(procedure.name, true);
      this.resetAppSessionParams(true);
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
  }

  isLocationValid() {
    let _isValid = true;
    if (!(this.searchCriteria.coordinates &&
      this.searchCriteria.coordinates.latitude !== '' &&
      this.searchCriteria.coordinates.longitude !== '')
    ) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  resetAppSessionParams(eyc: boolean = false) {
    this.searchCriteria.provNm = '';
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
  }

  setAnalytics(name: string, eyc: boolean) {
    const _searchDetails = {} as IAdobeSearchDetail;
    if (eyc) {
      _searchDetails.procedureName = name;
    } else {
      _searchDetails.provType = name;
    }
    _searchDetails.zip = this.searchCriteria.zip;
    this.analytics.emit(_searchDetails);
  }

  getProviderAriaLabel(providerName: string) {
    let label = '';
    if (this.content && this.content.searchCriteriaComponent && this.content.searchCriteriaComponent.labels) {
      label = this.content.searchCriteriaComponent.labels.ariaLabelForproviderName ? this.content.searchCriteriaComponent.labels.ariaLabelForproviderName : '';
      label = label.replace(/{provider_name}/gi, providerName);
    }
    return label;
  }
}
