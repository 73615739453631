import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';

@Injectable({ providedIn: 'root' })
export class IntegratedSearchService {
  private _rules = new Subject<IRulesResponse>();

  setRules(rules: IRulesResponse) {
    this._rules.next(rules);
  }

  get onRuleChange() {
    return this._rules.asObservable();
  }
}
