import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { BaseComponent } from './../base-component/baseCmp';

enum Icon {
  ANGLE = 'fa-caret-down',
  SYMBOL_PLUS = 'fa-plus',
  SYMBOL_MINUS = 'fa-minus'
}

export interface IBaseToggle {
  headerId?: string;
  bodyId: string;
  analyticsId?: string;
  startOpened?: boolean;
  icon?: string;
  align?: string;
  isSalmon?: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-fad-base-toggle-cmp]',
  moduleId: module.id,
  template: `
    <div
      class="base-toggle"
      tabindex="0"
      [id]="baseToggleSettings.headerId"
      (click)="onSelect()"
      (keydown.enter)="onSelect()"
      [attr.data-analytics]="baseToggleSettings.analyticsId"
      [attr.aria-expanded]="isOpened"
      role="button"
      [attr.aria-controls]="baseToggleSettings.bodyId"
      (focus)="onAffliateDropdownFocus()"
    >
      <span *ngIf="baseToggleSettings.align == 'left'" #icon [attr.data-analytics]="baseToggleSettings.analyticsId"
      class="fa" [ngClass]="{ salmon: baseToggleSettings.isSalmon }"></span>
      <ng-content select=".base-toggle-header"></ng-content>
      <span *ngIf="baseToggleSettings.align == 'right'" #icon [attr.data-analytics]="baseToggleSettings.analyticsId"
      class="fa" [ngClass]="{ salmon: baseToggleSettings.isSalmon }"></span>
    </div>
    <div #baseToggleBodyRef [ngClass]="{ 'base-toggle-expanded': isOpened, 'base-toggle-collapsed': !isOpened }"
    [attr.aria-hidden]="!isOpened" [id]="baseToggleSettings.bodyId">
      <ng-content select=".base-toggle-body"></ng-content>
    </div>
  `
})
export class PFToggleComponent extends BaseComponent {
  @ViewChild('baseToggleBodyRef', { static: true }) baseToggleBodyRef: ElementRef;
  @ViewChild('icon', { static: true }) iconElemRef: ElementRef;
  @Input()
  baseToggleSettings: IBaseToggle = {
    headerId: null,
    bodyId: null,
    analyticsId: null,
    startOpened: false,
    icon: 'caret',
    align: 'right',
    isSalmon: true
  };
  @Output()
  onAffliateDropdownChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  isOpened: boolean;
  private _icon: string;

  constructor(
    private _elemRef: ElementRef,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper) {
    super(_route, _eventHandler, _appSession, _contentHelper, '');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    const settings = this.baseToggleSettings;

    // If no header id is given, default to null
    settings.headerId = !settings.headerId ? '' : settings.headerId;

    // If no body toggle id is given, throw warning
    if (!settings.bodyId) {
      console.error('No ID was given for base toggle component body element!');
      settings.bodyId = '';
    }

    // If no initial toggle state given, default to false
    this.isOpened = settings.startOpened === undefined ? false : settings.startOpened;

    // If no icon is given, default to angle-down
    this._icon = !settings.icon ? 'caret' : settings.icon;

    // If no alignment is given, default to right align
    settings.align = !settings.align ? 'right' : settings.align;

    // If no icon color is given, default to salmon
    settings.isSalmon = settings.isSalmon === undefined ? true : settings.isSalmon;
  }

  /**
   * After content has loaded, calculate max height of body element
   */
  ngAfterContentChecked() {
    this.calcMaxHeight();
    this.renderIcon();
  }

  ngAfterViewInit() {
    this.renderIcon();
  }

  /**
   * On select, toggle open/close state, toggle appropriate icon classes
   */
  onSelect(): void {
    this.isOpened = !this.isOpened;
    this.onAffliateDropdownChanges.emit(this.isOpened)
    this.calcMaxHeight();
    this.renderIcon();
  }

  onAffliateDropdownFocus() {
    if (!this.isOpened) {
      this.onAffliateDropdownChanges.emit(false);
    } else {
      this.onAffliateDropdownChanges.emit(true);
    }
  }

  /**
   * Toggle icon classes
   */
  renderIcon() {
    if (!this.iconElemRef) {
      return;
    }
    const iconClassList = this.iconElemRef.nativeElement.classList;
    if (this.icon === 'symbol') {
      if (this.isOpened) {
        iconClassList.remove(Icon.SYMBOL_PLUS);
        iconClassList.add(Icon.SYMBOL_MINUS);
      } else {
        iconClassList.remove(Icon.SYMBOL_MINUS);
        iconClassList.add(Icon.SYMBOL_PLUS);
      }
    } else if (this.icon === 'caret') {
      iconClassList.add(Icon.ANGLE);
      const collapsedClass = 'base-toggle-icon-collapsed';
      const expandedClass = 'base-toggle-icon-expanded';
      if (this.isOpened) {
        iconClassList.remove(collapsedClass);
        iconClassList.add(expandedClass);
      } else {
        iconClassList.remove(expandedClass);
        iconClassList.add(collapsedClass);
      }
    }
  }

  /**
   * Calculates maximum height for the body element
   */
  calcMaxHeight() {
    if (!this.isOpened && this._elemRef && this.baseToggleBodyRef) {
      this.baseToggleBodyRef.nativeElement.style.maxHeight = '0px';
    } else if (this.isOpened && this._elemRef && this.baseToggleBodyRef) {
      this.baseToggleBodyRef.nativeElement.style.maxHeight = this._elemRef.nativeElement.scrollHeight + 'px';
    }
  }

  // Getter & setters
  get icon(): string {
    return this._icon;
  }
}
