import { ComponentFactoryResolver, Directive, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionItemLookupComponent } from '../components/actionItemLookupCmp';
import { ActionItemLookupService } from '../services/actionItemLookupService';
import { ActionItemCmp } from './../components/actionItemCmp';


@Directive({ selector: '[action-item-lookup]' })
export class ActionItemLookupDirective implements OnDestroy {
  @Input() parentComponent: ActionItemCmp;
  @Input() items:  Array<string>;
  @Input() isFeedbackMenu = false;
  @Output() readonly actionItemClick: EventEmitter<string> = new EventEmitter<string>();
  private _actionItemClickSubscription: Subscription;
  moreOptionClick: any;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private lookupService: ActionItemLookupService) {
      this.lookupService.destoryComponent();
  }

  ngOnInit() {
    const templateView = this.templateRef.createEmbeddedView({});
    const componentFactory = this.resolver.resolveComponentFactory(ActionItemLookupComponent);
    const componentRef = this.viewContainer.createComponent(componentFactory, 0,
      this.viewContainer.injector, [templateView.rootNodes]);
    if (typeof componentRef !== 'undefined' && componentRef !== null &&
      typeof componentRef.instance !== 'undefined' && componentRef.instance !== null) {
      (componentRef.instance as ActionItemLookupComponent).parentComponent = this.parentComponent;
      (componentRef.instance as ActionItemLookupComponent).items = this.items;
      componentRef.instance.isFeedbackMenu = this.isFeedbackMenu;
      this._actionItemClickSubscription = (componentRef.instance as ActionItemLookupComponent).actionItemClick
      .subscribe((event) => {
      this.actionItemClick.emit(event);
      });
      this.lookupService.registerComponent(componentRef);
    }
  }

  ngOnDestroy(): void {
    if (typeof this.parentComponent !== 'undefined' && this.parentComponent !== null) {
      this.parentComponent.selected = false;
    }
    if (typeof this._actionItemClickSubscription !== 'undefined' && this._actionItemClickSubscription !== null) {
      this._actionItemClickSubscription.unsubscribe();
    }
  }
}
