import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { ActionKey, FindCareFlag } from '../../../common/enums/findCareFlag';
import { BootstrapService } from '../../../common/store/bootstrapSvc';
import { IMember } from './../../../common/interfaces/iMember';
import { ActionService } from './../../../common/services/actionSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fc-member-selection-modal-cmp',
  templateUrl: './memberSelectionModalCmp.html',
  providers: []
})
export class MemberSelectionModalComponent extends BaseComponent implements OnInit, AfterViewInit {
  memberList: IMember[] = [];
  displayModal = false;
  @ViewChild('memberSelectionModal') memberSelectionModal: ModalComponent;

  constructor(
    @Inject(AppSession) private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _bootstrapSvc: BootstrapService,
    private _changeDetector: ChangeDetectorRef,
    private _actionService: ActionService,
    private _elementRenderer: Renderer2,
    private _elementReference: ElementRef
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Initializes the member list and determines whether to display the modal.
   */
  ngOnInit(): void {
    this.memberList = this._bootstrapSvc.members;
    this.displayModal = this.isFlagLive(FindCareFlag.INITIATE_MEMBER_SELECTION);
  }

  /**
   * Lifecycle hook that is called after a component's view has been fully initialized.
   * Detects changes and opens the modal if there are multiple members.
   */
  ngAfterViewInit(): void {
    const closeButton = this._elementReference?.nativeElement?.querySelector('#fc-member-selection-modal_close');
    if (closeButton) {
      this._elementRenderer?.setAttribute(closeButton, 'data-analytics', 'closeIconMemTileModalFindCare');
    }
    this._changeDetector.detectChanges();
    if (this.memberList?.length > 1) {
      this.openModal();
    }
  }

  /**
   * Opens the member selection modal.
   */
  openModal(): void {
    if (this.memberSelectionModal) {
      this.memberSelectionModal.show();
    }
    this.setAction();
    this._appSession.bootstrap.flags = this._appSession.bootstrap.flags.filter((flag) => flag !== FindCareFlag.INITIATE_MEMBER_SELECTION);
    this._appSession.appFlags = this._appSession.appFlags.filter((flag) => flag !== FindCareFlag.INITIATE_MEMBER_SELECTION);
  }

  /**
   * Closes the member selection modal.
   */
  closeModal(): void {
    this.memberSelectionModal.hide();
  }

  /**
   * Handles the member selection event.
   * Sets the selected member in the bootstrap service and updates the Redis cache.
   * @param member The selected member.
   */
  onMemberSelection(member: IMember): void {
    this._bootstrapSvc.setAppContract(member.mbrUid);
    this.closeModal();
  }

  /**
   * Initiates Redis caching for the member modal
   */
  setAction(): void {
    this._actionService.setRedisCache(ActionKey.MEMBER_MODAL_DURING_INITIALIZATION);
  }
}
