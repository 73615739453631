import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IFinalSearchResponse } from '../../interfaces/iCommonSearchResponse';
import { ICptMedicalResponse, IServiceLimit } from '../../interfaces/iCptMedicalResponse';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
@Component({
  moduleId: module.id,
  selector: 'app-summary-tab-cmp',
  templateUrl: './summaryTabCmp.html'
})
export class SummaryTabComponent extends BaseComponent {
  @Input() cptSearchResult: ICptMedicalResponse | IFinalSearchResponse;
  isExpanded: boolean = true;
  serviceLimits: Array<IServiceLimit>;
  constructor(
    route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession, contentHelper: ContentHelper) {
    super(route, eventHandler, appSession, contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit(): void {
    if (this.cptSearchResult?.hasServiceLimit) {
      this.serviceLimits = this.cptSearchResult?.serviceLimits;
    }
  }
}
