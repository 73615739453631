import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ICareRequest } from '../../rules/interfaces/iRulesParameters';
import { ICareActionItemsResponse } from '../interfaces/ICareActionItemsResponse';
@Injectable()
export class CareActionMessageSvc extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getCareActionItemAlertMsg(reqObj: ICareRequest, locale: string): Promise<ICareActionItemsResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.getCareActionItems,
      urlParams: [
        {
          name: 'locale',
          value: locale,
          isQueryParam: true
        }],
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
