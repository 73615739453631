import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppNavigations } from '../../../constants/app-navigations';

@Injectable({ providedIn: 'root' })
export class ChangePlanService {
  private onChangePlanSave = new Subject<AppNavigations>();
  private onPlanSelectionClick = new Subject<boolean>();
  private onSummaryReload = new Subject<string[]>();

  setChangePlanSave(path: AppNavigations) {
    this.onChangePlanSave.next(path);
  }

  get onChangePlanSaveClick() {
    return this.onChangePlanSave.asObservable();
  }

  setPlanSelectionLinkClick(value: boolean) {
    this.onPlanSelectionClick.next(value);
  }

  get onPlanSelectionLinkClick() {
    return this.onPlanSelectionClick.asObservable();
  }

  setPlanNetworkLinkDisplay(value?: string[]) {
    this.onSummaryReload.next(value);
  }

  get onPlanNetworkLinkDisplay() {
    return this.onSummaryReload.asObservable();
  }
}
