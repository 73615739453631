import { CommonModule, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { RecaptchaModule } from 'ng-recaptcha';
import { ActionItemModule } from '../../common/components/action-items/actionItemModule';
import { ActionableHeaderModule } from '../../common/components/actionable-header/actionableHeaderModule';
import { ChangePlanModule } from '../../common/components/change-plan/pfChangePlanModule';
import { CommonAlertModule } from '../../common/components/common-alert/commonAlertModule';
import { DisclaimerModule } from '../../common/components/disclaimers/pfDisclaimerModule';
import { GeoLocationModule } from '../../common/components/geoLocation/geoLocationModule';
import { InfoListModule } from '../../common/components/info-list/pfInfoListModule';
import { LeavingDisclaimerModule } from '../../common/components/leaving-disclaimer/leavingDisclaimerModule';
import { LocationModule } from '../../common/components/location/locationModule';
import { LookUpListModule } from '../../common/components/lookup-list/pfLookUpListModule';
import { MachineReadableModule } from '../../common/components/machine-readables/pfMachineReadableModule';
import { OnlinePharmacyLabelModule } from '../../common/components/online-pharmacy-label/onlinePharmacyLabelModule';
import { ScenarioAlertModule } from '../../common/components/scenarioAlert/pfScenarioAlertModule';
import { SsoModule } from '../../common/components/sso/ssoModule';
import { VirtualCareLabelModule } from '../../common/components/virtual-care-label/virtualCareLabelModule';
import { HoverTooltipModule } from './../../common/components/hover-tooltip/hoverTooltipModule';
import { CommonDirectivesModule } from './../../common/directives/commonDirectivesModule';
import { CustomPipesModule } from './../../common/pipes/customPipesModule';
import { PFAddressFormComponent } from './components/pfAddressFormCmp';
import { PFCareTeamComponent } from './components/pfCareTeamCmp';
import { PFDynamicCareTeamComponent } from './components/pfDynamicCareTeamCmp';
import { PFDynamicCareTeamV2Component } from './components/pfDynamicCareTeamV2Cmp';
import { PFDynamicProvidersAndProceduresComponent } from './components/pfDynamicProvidersAndProceduresCmp';
import { PFDynamicProvidersAndProceduresV2Component } from './components/pfDynamicProvidersAndProceduresV2Cmp';
import { PFDynamicSearchLinksComponent } from './components/pfDynamicSearchLinksCmp';
import { PFDynamicSearchLinksV2Component } from './components/pfDynamicSearchLinksV2Cmp';
import { PFFreeTxtSearchComponent } from './components/pfFtsCmp';
import { PFFtsMoreExamplesComponent } from './components/pfFtsMoreExamplesCmp';
import { PFFtsSuggestionComponent } from './components/pfFtsSuggestionCmp';
import { PFIntegratedSearchComponent } from './components/pfIntegratedSearchCmp';
import { PFIntegratedSearchV2Component } from './components/pfIntegratedSearchV2Cmp';
import { PFLocationFormComponent } from './components/pfLocationFormCmp';
import { PFMembersListComponent } from './components/pfMembersListCmp';
import { PFMoreOptionsComponent } from './components/pfMoreOptionsCmp';
import { PFMoreOptionsV2Component } from './components/pfMoreOptionsV2Cmp';
import { PFPersonalizationChecklistComponent } from './components/pfPersonalizationChecklistCmp';
import { PFPersonalizationCheckListItemsComponent } from './components/pfPersonalizationChecklistItemsCmp';
import { PFPlanSelectionComponent } from './components/pfPlanSelectionCmp';
import { PFProvidersAndProceduresComponent } from './components/pfProvidersAndProceduresCmp';
import { PFRebrandPlanSelectionComponent } from './components/pfRebrandPlanSelectionCmp';
import { PFSearchContainerComponent } from './components/pfSearchContainerCmp';
import { PFSearchContainerV2Component } from './components/pfSearchContainerV2Cmp';
import { PFSearchCriteriaComponent } from './components/pfSearchCriteriaCmp';
import { PFSearchHomeComponent } from './components/pfSearchHomeCmp';
import { PFSearchLinksComponent } from './components/pfSearchLinksCmp';
import { PFServiceAreaValidationComponent } from './components/pfServiceAreaValidationCmp';
import { PFSwitchLocationComponent } from './components/pfSwitchLocationCmp';
import { FeedbackService } from './services/feedbackSvc';
import { FtsSvc } from './services/ftsSvc';
import { MoreOptionsSvc } from './services/moreOptionsSvc';
import { ProviderLookupServiceV1 } from './services/providerLookupServiceV1';
import { SmartSearchService } from './services/smartSearchSvc';

@NgModule({
  imports: [
    UxSharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DisclaimerModule,
    ChangePlanModule,
    LeavingDisclaimerModule,
    ActionableHeaderModule,
    LocationModule,
    LookUpListModule,
    InfoListModule,
    MachineReadableModule,
    SsoModule,
    ActionItemModule,
    ScenarioAlertModule,
    VirtualCareLabelModule,
    RecaptchaModule,
    OnlinePharmacyLabelModule,
    HoverTooltipModule,
    CommonDirectivesModule,
    CustomPipesModule,
    GeoLocationModule,
    CommonAlertModule
  ],
  declarations: [
    PFSearchContainerComponent,
    PFSearchContainerV2Component,
    PFSearchCriteriaComponent,
    PFPlanSelectionComponent,
    PFRebrandPlanSelectionComponent,
    PFCareTeamComponent,
    PFProvidersAndProceduresComponent,
    PFIntegratedSearchComponent,
    PFIntegratedSearchV2Component,
    PFSearchLinksComponent,
    PFDynamicSearchLinksComponent,
    PFDynamicSearchLinksV2Component,
    PFDynamicProvidersAndProceduresComponent,
    PFDynamicProvidersAndProceduresV2Component,
    PFDynamicCareTeamComponent,
    PFDynamicCareTeamV2Component,
    PFLocationFormComponent,
    PFAddressFormComponent,
    PFSwitchLocationComponent,
    PFPersonalizationChecklistComponent,
    PFPersonalizationCheckListItemsComponent,
    PFServiceAreaValidationComponent,
    PFMoreOptionsComponent,
    PFMoreOptionsV2Component,
    PFSearchHomeComponent,
    PFMembersListComponent,
    PFFreeTxtSearchComponent,
    PFFtsSuggestionComponent,
    PFFtsMoreExamplesComponent
  ],
  exports: [
    PFSearchContainerComponent,
    PFSearchContainerV2Component,
    PFSearchCriteriaComponent,
    PFPlanSelectionComponent,
    PFRebrandPlanSelectionComponent,
    PFCareTeamComponent,
    PFProvidersAndProceduresComponent,
    PFIntegratedSearchComponent,
    PFIntegratedSearchV2Component,
    PFSearchLinksComponent,
    PFDynamicSearchLinksComponent,
    PFDynamicSearchLinksV2Component,
    PFDynamicProvidersAndProceduresComponent,
    PFDynamicProvidersAndProceduresV2Component,
    PFDynamicCareTeamComponent,
    PFDynamicCareTeamV2Component,
    PFLocationFormComponent,
    PFPersonalizationChecklistComponent,
    PFPersonalizationCheckListItemsComponent,
    PFMoreOptionsComponent,
    PFMoreOptionsV2Component,
    PFSearchHomeComponent,
    PFMembersListComponent,
    PFFreeTxtSearchComponent,
    PFFtsSuggestionComponent,
    PFFtsMoreExamplesComponent
  ],
  providers: [ProviderLookupServiceV1, SmartSearchService, MoreOptionsSvc, FtsSvc, FeedbackService]
})
export class PFSearchModule {
  static forRoot(): ModuleWithProviders<PFSearchModule> {
    return {
      ngModule: PFSearchModule,
      providers: [TitleCasePipe],
    };
  }
}
