import { IAddresses } from '../components/app-secure/models/iMetadata';
import { Locale } from '../constants/app-constants';
import { IBaseCode } from './iBaseCode';
export interface IAppMetadata {
  brandCd: string;
  integratedBrandCode?: string;
  brandName: string;
  mbrUid: string;
  locale: Locale;
  appContract: IAppContract;
  pfEmMode: boolean;
  token: string;
  idToken?: string;
  contractUid?: string;
  pcpIndicator?: string;
  metaSrcEnv?: string;
  addresses?: Array<IAddresses>;
  isBrowserNavigationEnabled?: boolean;
}

export interface IAddress {
  postalCode: string;
  state?: string;
  county?: string;
}

export interface IOptions {
  genderCode: string;
  label: string;
  isChecked: boolean;
  memberNumber: string;
  relationship?: string;
  mbrUid: string;
  firstName: string;
  lastName: string;
  coverageDetails: ICoverageDetails;
  futureCoverageDetails?: ICoverageDetails;
  coverageDetailsV2?: ICoverageDetails[];
  address: IAddress;
  addresses?: Array<IAddresses>;
  pcp: IPCPDetails;
  hasMultipleUnknownPrefix: boolean;
  dateOfBirth: string;
}

export interface IPCPDetails {
  pcpId: string;
  providerName: string;
  addressOne: string;
  addressTwo: string;
  phone: string;
}

export interface IProductName {
  type: string;
  name: string;
  identifier?: string;
}

export enum CoverageStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE'
}

export enum RelationshipStatus {
  Subscriber = 'subscriber',
  Dependent = 'dependent',
  Spouse = 'spouse'
}

export interface ICoverageDetails {
  hasMedicalPlan: boolean;
  hasDentalPlan: boolean;
  hasDentalNetwork: boolean;
  hasVisionPlan: boolean;
  hasVisionNetwork: boolean;
  hasPharmacyPlan: boolean;
  hasRxESI: boolean;
  hasRxCVS: boolean;
  isDentalStandAlone: boolean;
  isVisionStandAlone: boolean;
  isPharmacyStandAlone: boolean;
  isMedicare: boolean;
  surrogatePrefix: string;
  productNames: Array<IProductName>;
  productType: string;
  planCategory: string;
  coverageEffectiveDate: string;
  subGroupId?: string;
  coverageStatus?: string;
  coverageUid?: string;
  networkList?: Array<INetwork>;
}

export interface IV2Features {
  hasOnsiteClinics: boolean;
  hasEngagement: boolean;
  hasHealthWellness: boolean;
}

export interface IPrograms {
  programId: string;
  programNm: string;
  description?: string;
  image?: string;
  specialties?: Array<ISpecialty>;
  procedures?: Array<string>;
  dataAnalytics?: string;
}

export interface ISpecialty {
  providerType?: string;
  specialtyCategoryList?: Array<ISpecialtyList>;
}

export interface ISpecialtyList {
  code?: string;
  taxonomyList?: Array<string>;
}

export class HcidPrefix {
  coverageStatus: string;
  hcidPrefix: string;
}

export interface IAppContract {
  // TODO: hasEngagement and hasHealthWellness can be removed once RCP go live with feature auth API.
  contractUid: string;
  coverageDetails?: ICoverageDetails;
  coverageDetailsV2?: ICoverageDetails[];
  defaultContract: boolean;
  groupId?: string;
  hasANPEligibleSourceSystem?: boolean;
  hasCastlightTransparency: boolean;
  hasCostTransparency?: boolean;
  hasDentalEYC: boolean;
  hasEYC: boolean;
  hasEngagement: boolean;
  hasHealthSystem?: boolean;
  hasHealthWellness: boolean;
  hasFindCareFts?: boolean;
  hasLiveChatSupport?: boolean;
  hasLHO?: boolean;
  hasPCP: boolean;
  hasPersonalization?: boolean;
  hasSydneyBenefits: boolean;
  hasVPC?: boolean;
  hasVirtualCareHubWeb?: boolean;
  hasVirtualUrgentCare?: boolean;
  hasTelehealth?: boolean;
  hasVirtualWebPage?: boolean;
  hasTalkSpaceSSO?: boolean;
  hcidPrefix: string;
  hcidPrefixList: Array<HcidPrefix>;
  mbrUid?: string;
  mbu: string;
  memberCounty: string;
  memberList: Array<IOptions>;
  memberSeqNum: string;
  pcpProductType?: string;
  postalCode: string;
  programs: Array<IPrograms>;
  sourceSystem?: string;
  spendingAccountDetails: ISpendingAccount;
  thirdPartyPrograms?: Array<IPrograms>;
  underwritingStateCode: string;
  v2Features: IV2Features;
}

export interface IMultiMemberContract {
  contractList: Array<IAppContract>;
}
export interface IMemberContract {
  underwritingStateCode: string;
  hasCastlightTransparency: boolean;
  hasEYC: boolean;
  hasDentalEYC: boolean;
  hasPCP: boolean;
  contractUid: string;
  defaultContract: boolean;
  postalCode: string;
  sourceSystem?: string;
  memberList: Array<IOptions>;
}

export interface ICoverage {
  coverageType: string;
  coverageStatus: string;
  surrogatePrefix: string;
  providerNetworkList: Array<string>;
  searchByAlphaPrefix: string;
}

export interface ISpendingAccount {
  availableBalance: string;
}

export interface IMemberListItem {
  mbrUid: string; // encrypted memberUid
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string;
  genderCode: string;
  isSelected: boolean;
  relationship: string;
  toolTip: string;
}

// The following interface defines a data type of items displayed in Plans Dropdown list
export interface IMemberPlan {
  label: string;
  value: string;
  contractUid: string;
  type: string;
  name: string;
  coverageEffectiveDate?: string;
}

export interface IMemberProduct {
  label: string;
  value: string;
  contractUid: string;
  mbrUid: string;
  typeOfCare: string;
}

export interface INetwork extends IBaseCode {
  networkType?: string;
}

export interface IAddressByMember {
  [key: string]: IAddresses[];
}
