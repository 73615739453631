import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { PillItemCmp } from './components/pillItemCmp';
import { PillItemLookupComponent } from './components/pillItemLookupCmp';
import { PillListCmp2 } from './components/pillListCmp2';
import { PillItemLookupDirective } from './directives/pillItemLookupDirective';
@NgModule({
  declarations: [PillItemCmp, PillItemLookupComponent, PillItemLookupDirective, PillListCmp2],
  exports: [
    PillItemCmp,
    PillItemLookupDirective,
    PillItemLookupComponent,
    PillListCmp2
  ],
  imports: [
    CommonModule,
    ActionableHeaderModule
  ]
})
export class PillFilterModule { }
