import { Inject, Injectable } from '@angular/core';
import { MessagingHandler } from './../../../../app/common/services/messagingHandler';
import { IRuleParameters } from './../../../../app/fad/rules/interfaces/iRulesParameters';
import { IQuickLinks } from './../../../../app/fad/search-providers/interfaces/iQuickLinks';
import { CommonUtil } from './../../../../app/fad/utilities/commonUtil';
import { apiNameList } from './../../../../environments/api-name-list';
import { Locale } from './../../../common/constants/app-constants';
import { HttpMethod } from './../../../common/enums/httpMethodEnum';
import { IUrlParam } from './../../../common/interfaces/iUrlParam';
import { BaseService } from './../../../common/services/baseService';
import { HttpClientService } from './../../../common/services/httpClientService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { IProcedureCategoryList } from './../../../fad/search-providers/interfaces/iQuickLinks';

/**
 * Provider Utility service will return procedure categories.
 */
@Injectable()
export class ProcedureCategoryService extends BaseService {
  constructor(
    _httpClientService: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility,
    private _messagingHandler: MessagingHandler
  ) {
    super(_appSession, _appUtility, _httpClientService);
  }

  getProcedureCategories(type: string): Promise<any> {
    const promise = this.getProcedures();

    if (CommonUtil.isMemberSecure(this.appSession)) {
      this._messagingHandler.setProcedureTypePromise(promise);
    }

    return promise;
  }

  getQuickLinksList(type: any): Promise<any> {
    type.locale = this.appSession && this.appSession.metaData && this.appSession.metaData.locale ? this.appSession.metaData.locale.toString() : Locale.ENGLISH;
    const promise = this.getQuickLinks(type);
    if (CommonUtil.isMemberSecure(this.appSession)) {
      this._messagingHandler.setProcedureTypePromise(promise);
    }

    return promise;
  }

  getQuickLinksV2List(type: IRuleParameters): Promise<IQuickLinks> {
    type.locale = this.appSession && this.appSession.metaData && this.appSession.metaData.locale ? this.appSession.metaData.locale.toString() : Locale.ENGLISH;
    const promise = this.getQuickLinksV2(type);
    if (CommonUtil.isMemberSecure(this.appSession)) {
      this._messagingHandler.setProcedureTypePromise(promise);
    }

    return promise;
  }

  getQuickLinks(reqObj): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.quickLinks,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getQuickLinksV2(reqObj: IRuleParameters): Promise<IQuickLinks> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.quickLinksV2,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getProcedureDetails(procedurecode: string): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getProcedueDetails,
      urlParams: [
        {
          name: 'procedurecode',
          value: procedurecode,
          isQueryParam: false
        }
      ]
    });
  }

  /**
   * this method will call Node API to get all procedures
   */
  getProcedures(procedureCode?: string): Promise<IProcedureCategoryList> {
    const urlParams: IUrlParam[] = [];

    if (this.appSession && this.appSession.metaData && this.appSession.metaData.appContract) {
      urlParams.push({
        name: 'contractuid',
        value: this.appSession.metaData.appContract.contractUid ? this.appSession.metaData.appContract.contractUid : '',
        isQueryParam: false
      });
    }
    if (procedureCode) {
      urlParams.push({
        name: 'tcc',
        value: procedureCode,
        isQueryParam: true
      });
    }

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.proceduresV2,
      urlParams
    });
  }
}
