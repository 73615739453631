import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { AppErrorComponent } from './appErrorCmp';
@NgModule({
    declarations: [AppErrorComponent],
    exports: [AppErrorComponent],
    imports: [
        CommonModule, UxSharedModule
    ],
    providers: []
})
export class AppErrorModule {
  static forRoot(): ModuleWithProviders<AppErrorModule> {
    return {
      ngModule: AppErrorModule,
      providers: []
    };
  }
 }
