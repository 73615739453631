import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { PfScenarioAlertComponent } from './components/ pfScenarioAlertCmp';


@NgModule({
    declarations: [PfScenarioAlertComponent],
    exports: [PfScenarioAlertComponent],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: []
})
export class ScenarioAlertModule { }
