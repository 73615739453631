import { Inject, Injectable } from '@angular/core';
import { AppSession } from '../values/appSession';

@Injectable()
export class BingMapsLoader {
  private mapPromise: Promise<any>;

  constructor(@Inject(AppSession) private appSession: AppSession) {
    //constructor
  }

  loadMapsLibrary(): Promise<any> {
    if (!this.mapPromise) {
      this.mapPromise = new Promise((resolve, reject) => {
        window['__onBingLoaded'] = (event: any) => {
          resolve('Bing Maps API loaded');
        };

        const body: any = document.getElementsByTagName('body')[0];
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = this.appSession.appConfig?.bingMaps.scriptUrl;
        script.onerror = () => {
          reject();
        };
        body.appendChild(script);
      });
    }

    return this.mapPromise;
  }
}
