import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../../common/services/navigationService';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { ActionType, CodeType } from '../enums/personalizationEnums';
import { IMoreOptionsRequest } from '../interfaces/iMoreOptionsRequest';
import { IMoreOptionsResponse } from '../interfaces/iMoreOptionsResponse';
import { IProviderType } from '../interfaces/iProviderType';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { MoreOptionsSvc } from '../services/moreOptionsSvc';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { ChangePlanService } from './../../../common/components/change-plan/services/changePlanSvc';
import { AppExternalCommands, ContentLanguageCode, Locale, MODAL_TYPE } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from './../../../common/constants/strategy-search-constant';
import { AppEvents } from './../../../common/enums/appEvents';
import { IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { IEventDetail } from './../../../common/interfaces/iEventDetail';
import { IVirtualVideoVisitSSO } from './../../../common/interfaces/iMessage';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { SearchSvc } from './../../../common/services/searchSvc';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { SAVNavigationService } from './../services/savNavigationSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from './../values/providerSearchConstants';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-more-options-cmp',
  templateUrl: '../views/pfMoreOptionsCmp.html',
  providers: []
})
export class PFMoreOptionsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Output()
  modal: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  analytics: EventEmitter<IAdobeSearchDetail> = new EventEmitter<IAdobeSearchDetail>();
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  searchParams: ISearchParameters;
  @Input()
  planUnknownError: boolean = false;

  searchCriteria: ISearchParameters;
  providerTypeList: IMoreOptionsResponse[] = [];
  hasEYC: boolean = false;
  isMemberSecure: boolean = false;
  ssoConfig: IVirtualVideoVisitSSO;
  languageCd: string;
  locale: string;
  private changePlanSaveClickSubscription: Subscription;
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    private searchService: SearchSvc,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _ruleService: RulesService,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _savNavigationService: SAVNavigationService,
    private _optionSvc: MoreOptionsSvc,
    private _changePlanService: ChangePlanService,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick.pipe(filter((path) => path === AppNavigations.SEARCH_PROVIDERS_PATH)).subscribe(() => {
      this.getMoreOptions();
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    if (this._appSession) {
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
      this.hasEYC = this.isMemberSecure && (this._appSession.hasEYC || (this._appSession.hasDentalEYC && this.showDentalDDS));
    }

    if (this._dataHelper.areEqualStrings(this._appSession.metaData?.locale?.toString(), Locale.SPANISH)) {
      this.languageCd = ContentLanguageCode.SPANISH;
    } else {
      this.languageCd = ContentLanguageCode.ENGLISH;
    }
    this.getMoreOptions();
  }

  getMoreOptions() {
    const req: IMoreOptionsRequest = {
      careType: this._appSession.isSecureState
        ? this._appSession.filterChangePlan && this._appSession.filterChangePlan.careType
          ? this._appSession.filterChangePlan.careType
          : ''
        : this._appSession.searchParams.plan && this._appSession.searchParams.plan.careType
          ? this._appSession.searchParams.plan.careType
          : '',
      brand: this._appSession.metaData?.brandCd,
      networks: this._appSession.searchParams?.plan?.networkList ? this._appSession.searchParams?.plan?.networkList : [],
      prefix: CommonUtil.getPrefix(this._appSession.searchParams, this._appSession),
      member: {
        mbrUid: this._appSession.metaData?.appContract?.mbrUid,
        contractUid: this._appSession.metaData?.appContract?.contractUid,
        identification: this._appSession.searchParams?.identificationNumber
      },
      locale: this.locale
    };
    this._optionSvc.getMoreOptions(req).then((resp: Array<IMoreOptionsResponse>) => {
      this.providerTypeList = resp;
    });
  }

  ngOnChanges(args: SimpleChanges) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.searchCriteria = args['searchParams'].currentValue;
    }
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
  }

  isLeavingAnthem(providerTypeCode: string): boolean {
    const provType: IProviderType = (this.providerTypeList || []).find((p) => {
      return this._dataHelper.areEqualStrings(p.searchCriteria?.providerTypeCodeList[0]?.toUpperCase(), providerTypeCode?.toUpperCase());
    });
    if (provType?.vendorUrl) {
      this.leavingDisclaimer.emit(provType.vendorUrl);
      return true;
    }
    return false;
  }
  isLocationValid(): boolean {
    let _isValid = true;
    if (!(this.searchCriteria.coordinates && this.searchCriteria.coordinates.latitude !== '' && this.searchCriteria.coordinates.longitude !== '' && this.searchCriteria.zip !== '')) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  searchByProviderType(moreOptionsResponse: IMoreOptionsResponse): void {
    const isLeavingAnthem = this.isLeavingAnthem(moreOptionsResponse.searchCriteria?.providerTypeCodeList[0]);
    if (!isLeavingAnthem && this.isLocationValid() && !this.planUnknownError && moreOptionsResponse && moreOptionsResponse.searchCriteria?.providerTypeCodeList[0]) {
      this.searchParams.typeSelectNm = moreOptionsResponse.searchCriteria?.providerTypeCodeList[0];
      this.searchParams.coverageTypeCode = '';
      this.searchParams.specialtySelectNm = moreOptionsResponse.searchCriteria?.specialtyCategoryCodes || [];
      this.searchParams.taxonomySelectNm = [];
      this.searchParams.providerTypeCodeList = [];
      this.searchService.currentScenario = this.isMemberSecure
        ? CommonUtil.getSecureSpecialtyScenario(moreOptionsResponse.searchCriteria?.providerTypeCodeList as PROVIDER_TYPE[])
        : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      this.searchParams.providerTier = moreOptionsResponse.searchCriteria?.providerTier;
      //added recognition in search critria
      moreOptionsResponse.searchCriteria?.recognitionList?.forEach((recognition: any) => {
        if (CommonUtil.isValidString(recognition)) {
          this.searchParams[recognition] = true;
        }
      });
      if (this.networkProgramReferenceV2 && moreOptionsResponse.searchCriteria.recognitionCodeList?.length) {
        this.searchParams.recognitionCodes = moreOptionsResponse.searchCriteria.recognitionCodeList;
      }
      //removing search term when user click on quickLinks
      this._appSession.searchTerm = '';
      this._appSession.isSearchTerm = false;
      this.searchParams = CommonUtil.buildStandAlone(this.searchParams, this._appSession);
      this.searchParams.providerTypeCodeList.push(this.searchCriteria.typeSelectNm);
      // Medsupp scenario
      if (this._appSession.planRule && this._appSession.planRule?.isMedicareGov) {
        this.searchParams.standAloneVision = true;
      }
      this.searchCriteria = this.searchParams;
      this.setAnalytics(moreOptionsResponse.name, false);
      this.resetAppSessionParams();

      //Set Flag for Pre Filtering BCP filter
      const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
      let isSearchCriteriaPath: boolean = false;
      if (_routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH) {
        isSearchCriteriaPath = true;
      }

      this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
        (result: IRulesResponse) => {
          const _result = result;
          if (_result) {
            if (_result.showMedicareSupplement) {
              this.modal.emit(MODAL_TYPE.MEDICARE_SUPPLEMENT);
            } else {
              this._appSession.feature = _result;
              if (this._appSession && isSearchCriteriaPath && _result.showBCP && _result.showPreFilterBCP && this._appSession.searchParams) {
                this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
              }
              this._savNavigationService.navigate();
            }
          }
        },
        (error: any) => {
          this.onCategoryError('RULE', error);
        }
      );
    }
  }

  setAnalytics(name: string, eyc: boolean) {
    const _searchDetails = {} as IAdobeSearchDetail;
    if (eyc) {
      _searchDetails.procedureName = name;
    } else {
      _searchDetails.provType = name;
    }
    _searchDetails.zip = this.searchCriteria.zip;
    this.analytics.emit(_searchDetails);
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  resetAppSessionParams(eyc: boolean = false) {
    this.searchCriteria.provNm = '';
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.billingType = undefined;
    this._appSession.medicalCode = undefined;
    this._appSession.medicalModifierOptionCode = undefined;
    this._appSession.medicalPosCode = undefined;
  }

  onVideoVisit(event) {
    const videoVisitClick = event.currentTarget.querySelector('#fad-pf-sso-link-0');
    if (videoVisitClick) {
      videoVisitClick.click();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  navigateToRCPPage(providerType: IMoreOptionsResponse) {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];

    // TODO: This should be refactored into a switch whenever adding
    // more cases. Leaving as an if statement b/c SonarQube throws
    // an error if there are <3 cases in a switch statement.
    if (providerType.code === CodeType.VIRTUAL_CARE) {
      eventDetail.message = AppExternalCommands.RCP_VIRTUAL_CARE.MSG;
      eventDetail.target = AppExternalCommands.RCP_VIRTUAL_CARE.CMD;
    }

    this._pfAppCommandEvent.emit(eventDetail);
  }

  search(providerType: IMoreOptionsResponse, event) {
    if (this.isLocationValid()) {
      switch (providerType.actionType?.toUpperCase()) {
        case ActionType.SEARCH:
          this.searchByProviderType(providerType);
          break;
        case ActionType.SSO:
          providerType.code == CodeType.LHO && this.onVideoVisit(event);
          break;
        case ActionType.EXT_NAV:
          this.navigateTo(providerType.url);
          break;
        case ActionType.INT_RCP_NAV:
          this.navigateToRCPPage(providerType);
          break;
        case ActionType.INT_NAV:
          this._navigationService.navigateByUrl(this.getUrl(providerType.code));
          break;
      }
    }
  }
  navigateTo(url: string) {
    window.open(url, '_blank');
  }

  getUrl(code: string) {
    if (code === 'GET_CARE_NOW') {
      return '/care-now';
    }
    return '';
  }
}
