import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { EventHandler } from '../../../common/services/eventHandler';
import { NetworkProgramSvc } from '../../../common/services/networkProgramSvc';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IPerformance } from '../../search-results/interfaces/iSummaryResp';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pair-provider-recognitions-cmp',
  templateUrl: '../views/pfPairRecognitionsCmp.html'
})
export class PFPairRecognitionsComponent extends BaseComponent {
  @Input()
  providerPerformance: any;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _networkProgramSvc: NetworkProgramSvc
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  getRecognitionImageURL(filename: string) {
    return this.getQualityImageURL(filename);
  }

  decodeHTML(html) {
    return this.WcsUtility.decodeHTML(html);
  }

  getFacilityText(performance: IPerformance): string {
    return this.content.searchCriteriaComponent.labels.facilityRecognition.replace(/{count}/gi, this.getCount(performance));
  }

  getProviderText(performance: IPerformance) {
    return this.content.searchCriteriaComponent.labels.providerRecognition.replace(/{count}/gi, this.getCount(performance));
  }

  getCount(performance: IPerformance): number {
    return this._networkProgramSvc.getCount(performance);
  }
}
