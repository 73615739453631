import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IFinalSearchResponse } from "../../interfaces/iCommonSearchResponse";
import { ICptMedicalResponse } from "../../interfaces/iCptMedicalResponse";
import { EventHandler } from "../../services/eventHandler";
import { AppSession } from "../../values/appSession";
import { ContentHelper } from "../../values/contentHelper";
import { BaseComponent } from "../base-component/baseCmp";

@Component({
  moduleId: module.id,
  selector: 'app-out-of-network-pricing-tab-cmp',
  templateUrl: './outOfNetworkPricingTabCmp.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutOfNetworkPricingComponent extends BaseComponent {
  @Input() cptSearchResult: ICptMedicalResponse | IFinalSearchResponse;

  constructor(
    route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession, contentHelper: ContentHelper) {
    super(route, eventHandler, appSession, contentHelper, 'PFSearchResultsContainerComponent');
  }

  print() {
    window.print();
  }

}
