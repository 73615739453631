import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { ToggleModule } from './../../common/components/toggle-control/toggleModule';
import { PFCostAffiliationComponent } from './components/pfCostAffiliationCmp';
import { PFCostInformationComponent } from './components/pfCostInformationCmp';

@NgModule({
  imports: [UxSharedModule, FormsModule, CommonModule, ToggleModule],
  declarations: [PFCostAffiliationComponent, PFCostInformationComponent],
  exports: [PFCostAffiliationComponent, PFCostInformationComponent],
  providers: []
})
export class PFCostResultsModule {
  static forRoot(): ModuleWithProviders<PFCostResultsModule> {
    return {
      ngModule: PFCostResultsModule
    };
  }
}
