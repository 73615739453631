import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../services/eventHandler';
import { AppUtility } from '../../utilities/appUtil';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  selector: 'app-fly-out-box',
  templateUrl: './flyOutBoxCmp.html'
})
export class FlyOutBoxComponent extends BaseComponent implements OnInit  {
  constructor(
    @Inject(AppSession)
    _appSession: AppSession,
    _route: ActivatedRoute,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    _eventHandler: EventHandler,
    _appUtility: AppUtility) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

}
