import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { FilterCategory } from '../../constants/result';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-accordion-cmp',
  templateUrl: './filterAccordionCmp.html'
})
export class FilterAccordionComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  overflowVisible: boolean = false;
  @Input()
  header: string;
  @Input()
  ariaLabelHeader: string;
  @Input()
  ariaLabelToggle: string;
  @Input()
  category: FilterCategory;
  @Input()
  showFilterCount: boolean = true;
  expand: boolean = false;

  filterContent = this.content?.result?.filter;
  appliedFilterCountText: string;

  private appliedFilter: Subscription;

  constructor(
    @Inject(AppSession)
    _appSession: AppSession,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    private _appliedFilter: AppliedSearchFilter
  ) {
    super(_route, _eventHandler, _appSession);

    this.appliedFilter = this._appliedFilter.getAppliedFilters.subscribe((data) => {
      if (data) {
        const appliedCategoryFilters = this._appliedFilter.mapAppliedFilterItemsByCategory(this.category);
        this.appliedFilterCountText = `${appliedCategoryFilters?.[this.category]?.length || 0} ${this.filterContent.selected}`;
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.appliedFilter) {
      this.appliedFilter.unsubscribe();
    }
  }
}
