import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../../environments/api-name-list';
import { IMemberPlanProgressRequest } from '../../../../findcare/common/interfaces/iMemberPlanProgressRequest';
import { IMemberPlanProgressResp } from '../../../../findcare/common/interfaces/iMemberPlanProgressResponse';
import { HttpMethod } from '../../../enums/httpMethodEnum';
import { BaseService } from '../../../services/baseService';
import { HttpClientService } from '../../../services/httpClientService';
import { AppUtility } from '../../../utilities/appUtil';
import { AppSession } from '../../../values/appSession';

@Injectable({ providedIn: 'root' })
export class MemberPlanProgressService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  getMemberPlanProgressData(urlParams: IMemberPlanProgressRequest): Promise<IMemberPlanProgressResp> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.memberBenefitV1,
      urlParams: [
        {
          name: 'mbrUid',
          value: urlParams.mbrUid,
          isQueryParam: true
        },
        {
          name: 'contractUid',
          value: urlParams.contractUid,
          isQueryParam: true
        }
      ]
    });
  }
}
