import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDropdownItem } from '@anthem/uxd/util';
import { Subscription } from 'rxjs';
import { IProvider } from '../../search-results/interfaces/iSummaryResp';
import { DEFAULT_DENTAL_REASON_CODE, OTHER_REASON_CODES } from '../constants/otherReasonCodes';
import { REVIEW_SELECTIONS, SELECT_MEMBER, SELECT_PCP_ID, SELECT_PLAN, SELECT_REASON } from '../constants/steps';
import { InvalidSourceSystemError } from '../errors/pcpError';
import { PcpEvent } from '../events/pcpEvent';
import { StepChangeEvent } from '../events/stepChangeEvent';
import { IReason, IReasonCodesRequest } from '../models/iReasonCodeList';
import { MemberPcpDetailsApiHelper } from '../services/memberPcpDetailsApiHlpr';
import { PcpReasonCodesApiHelper } from '../services/pcpReasonCodesApiHlpr';
import { Stepper } from '../services/stepper';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { SlideoutMessageComponent } from './../../../common/components/common-alert/slideoutMsgCmp';
import { DOCTOR_NAME, ERR_NO_AFFILIATIONS, ERR_NO_PCPS_IN_AFFILIATION, MEMBER_NAME, NETWORK_TYPECODE, PCP_OPTIONAL, STEP, TERMINATION_DATE, TOTAL } from './../../../common/constants/app-constants';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { IAffiliationRadioInput, IPcpIdItem, IPCPRadioOption } from './../../../common/interfaces/iPCPInfo';
import { ACTIVE_COVERAGE, BOTH_COVERAGES, FUTURE_COVERAGE, IPcpModifyRequest, IPcpValidateRequest } from './../../../common/interfaces/iPcpRequest';
import { ISlideoutMessage } from './../../../common/interfaces/iSlideoutMsg';
import { IWindow } from './../../../common/interfaces/iWindow';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { StepAutoSkipEvent } from './../events/stepChangeEvent';
import { ICoverageRadioInput, IMemberPcpInfo, IMembersModel } from './../models/iMemberPcpDetails';
import { MemberPcpValidateApiHelper } from './../services/memberPcpValidateApiHlpr';
import { ModifyPcpApiHelper } from './../services/modifyPcpApiHlpr';
import { ProviderPcpInfoApiHelper } from './../services/providerPcpInfoApiHlpr';

declare let _satellite: any;

@Component({
  moduleId: module.id,
  selector: 'app-assign-pcp-v2-cmp',
  templateUrl: '../views/pfAssignPcpV2Cmp.html'
})
export class AssignPcpV2Component extends BaseComponent implements OnDestroy {
  pcp: IProvider;
  selectedMbr: IMemberPcpInfo;
  selectedCov: ICoverageRadioInput;
  selectedAffln: IAffiliationRadioInput;
  selectedReason: IReason;

  navStatus: string = '';
  navStatusAria: string = '';
  affiliations: IAffiliationRadioInput[];

  multipleAffiliations: boolean = false;
  pcpList: IPCPRadioOption[] = [];
  reasons: IReason[];

  selectedCovType: string = '';
  selMbrUid: string = '';
  selectedAfflnId: string;
  selectedReasonCd: string = '';

  mbrsModel: IMembersModel;
  hasPcpInfoList: boolean;
  selectPcpLbl: string = '';

  showNavStatus: boolean = true;
  showAssignPcpBtn: boolean = false;
  showCompleteBtn: boolean = false;
  showSelectedReason: boolean = true;

  nextBtnDisabled: boolean = true;
  isEmulation: boolean = false;
  nextBtnAnalytics = '';
  prevBtnAnalytics = '';

  @ViewChild('slideoutMsgCmp') slideoutMsgCmp: SlideoutMessageComponent;
  @ViewChild('NavStatusElem') navStatusElem: ElementRef;
  @Output() cancel = new EventEmitter<PcpEvent>();
  @Output() complete = new EventEmitter<PcpEvent>();

  private stepChangeSubscription: Subscription;
  private stepAutoSkipSubscription: Subscription;
  private _otherReasonCd: string = '';

  constructor(
    route: ActivatedRoute,
    eventHandler: EventHandler,
    private _appSession: AppSession,
    contentHelper: ContentHelper,
    public mbrPcpDtlsApi: MemberPcpDetailsApiHelper,
    private _appUtil: AppUtility,
    public validateApiHlpr: MemberPcpValidateApiHelper,
    public modifyApiHlpr: ModifyPcpApiHelper,
    @Inject('Window') private _window: IWindow,
    public pcpInfoApiHlpr: ProviderPcpInfoApiHelper,
    public pcpResnApiHlpr: PcpReasonCodesApiHelper,
    private _titleCasePipe: TitleCasePipe,
    public stepper: Stepper,
    private _dataHlpr: DataHelper
  ) {
    super(route, eventHandler, _appSession, contentHelper, 'PFAssignPcpContainerComponent', 'assignPcpCmpContent');
  }

  ngOnInit(): void {
    this.isEmulation = this._appSession.isEmulation;
    this.pcp = this._appSession.selectedProvider;
    this.selectedAffln = null;
    this.selectedAfflnId = '';

    this.selectedCovType = '';
    this.selectedCov = null;

    this.selMbrUid = '';
    this.selectedMbr = null;

    this.resetReason();

    //Get reason code for validate api from WCS content using 'Source System'.
    this._otherReasonCd = this._dataHlpr.getValueByKey<string>(this._appSession.sourceSystem, OTHER_REASON_CODES, '');

    this.stepChangeSubscription = this.stepper.onChange.subscribe((evt) => {
      this.onStepChange(evt);
    });

    this.stepAutoSkipSubscription = this.stepper.onAutoSkip.subscribe((evt) => {
      this.onStepAutoSkip(evt);
    });

    this.loadAPIs();
  }

  ngOnDestroy(): void {
    this.stepper.init();
    this.stepChangeSubscription?.unsubscribe();
    this.stepAutoSkipSubscription?.unsubscribe();
  }

  async loadAPIs() {
    //Create request obj for Pcp member Details API
    const pcpDtlsApiReq = {
      defaultContractUid: this._appSession.metaData?.appContract?.contractUid,
      mbrUid: this._appSession.metaData?.appContract?.mbrUid
    };
    //Create request obj for Pcp reason Codes API
    const categoryCodes: Array<string> = [];
    if (this.pcp.providerCategoryCode?.code) {
      categoryCodes.push(this.pcp.providerCategoryCode.code);
    }
    const locale = this._appSession.metaData?.locale?.toString();
    const networkTypeCodes = this.pcp?.providerNetworkStatus?.typeCode?.length ? this.pcp.providerNetworkStatus.typeCode : [];
    const pcpReasonReq: IReasonCodesRequest = {
      locale,
      categoryCodes,
      networkTypeCodes
    };
    const pcpDtlsApiPromise = this.mbrPcpDtlsApi.invoke(pcpDtlsApiReq);
    const pcpInfoApiPromise = this.pcpInfoApiHlpr.invokeByProvider(this.pcp);
    const pcpResnApiPromise = this.pcpResnApiHlpr.invoke(pcpReasonReq);

    //Call APIs and wait for their responses
    await pcpDtlsApiPromise;
    await pcpInfoApiPromise;
    await pcpResnApiPromise;

    //Start creating Radio buttons in MemberPcpDetailsApiHelper
    this.pcpInfoApiHlpr.createAffliationRadioInputs(this.mbrPcpDtlsApi.apiResp?.contracts);
    this.mbrPcpDtlsApi.createPlanRadioInputs(this.pcpInfoApiHlpr.getAllContractsAfflns());

    //Only one Coverage
    if (this.mbrPcpDtlsApi.isSingleContract) {
      this.stepper.skipStep(SELECT_PLAN);
      this.selectedCov = this.mbrPcpDtlsApi.plansRadioGroupModel.options[0];
      this.selectedCovType = this.selectedCov.value;
      this.setMembers();
      this.setAffiliations();
      this.reasons = this.pcpResnApiHlpr.getReasonsByStatus(this.selectedCov.value);
    }
    //initialise Stepper
    this.stepper.start();
    this._appUtil.scrollToTop();
  }

  /**
   * 'Next' button click handler
   * @returns
   */
  onNextClick() {
    const curStepCd = this.stepper.curStepCd;
    try {
      if (this._dataHlpr.areEqualStrings(curStepCd, SELECT_PLAN)) {
        this.setMembers();
        this.setAffiliations();
        this.checkAffiliations();
        if (this.stepper.isSkipped(SELECT_MEMBER) && this.stepper.isSkipped(SELECT_PCP_ID)) {
          this.nextBtnDisabled = true;
          this.invokeValidateAPI();
          return;
        }
      }
      if (this._dataHlpr.areEqualStrings(curStepCd, SELECT_MEMBER)) {
        this.setAffiliations();
        this.checkAffiliations();
        if (this.stepper.isSkipped(SELECT_PCP_ID)) {
          this.nextBtnDisabled = true;
          this.invokeValidateAPI();
          return;
        }
      }
      if (this._dataHlpr.areEqualStrings(curStepCd, SELECT_PCP_ID)) {
        this.checkPcpIDs(this.selectedAffln);
        this.nextBtnDisabled = true;
        this.invokeValidateAPI();
        return;
      }

      this.stepper.next();
    } catch (error) {
      this.displayErrorPanel(error);
    }
  }

  /**
   * 'Previous' button click handler
   */
  onPreviousClick() {
    //Discard previous selections based on the step. Then navigate to previous step.
    switch (this.stepper.curStepCd) {
      case SELECT_REASON:
        this.resetReason();
        break;
      case SELECT_PCP_ID:
        this.selectedAffln = null;
        this.selectedAfflnId = '';
        break;
      case SELECT_MEMBER:
        this.selMbrUid = '';
        this.selectedMbr = null;
        break;
    }
    this.stepper.previous();
  }

  onCancelClick() {
    this.cancel.emit(PcpEvent.CANCEL);
  }

  async onAssignPcpClick() {
    //TODO Post back the PCP info to client requesting using deeplink
    if (this._window.digitalData) {
      if (!this._window.digitalData.pagePageInfo) {
        this._window.digitalData.pagePageInfo = {};
      }
      this._window.digitalData.pagePageInfo.pcpChangeReason = this.selectedReasonCd;
    }
    if (typeof _satellite !== 'undefined') {
      _satellite.track('changeReasonPcpFindCare');
    }
    this.invokeModifyAPI();
  }

  onCompleteClick() {
    this.resetErrorStates();
    this.complete.emit(PcpEvent.COMPLETE);
  }

  onCoverageChange() {
    if (!this.selectedCovType) {
      return;
    }
    this.resetErrorStates();
    this.selectedCov = this.mbrPcpDtlsApi.plansRadioGroupModel.options.find((cov) => {
      return this._dataHlpr.areEqualStrings(cov.value, this.selectedCovType);
    });
    this.nextBtnDisabled = this._dataHlpr.isEmptyString(this.selectedCovType);

    //Reset reasons
    this.resetReason();
    this.reasons = this.pcpResnApiHlpr.getReasonsByStatus(this.selectedCov.value);

    try {
      this.mbrPcpDtlsApi.validateMbrPcpEffectiveDateByStatus(this.selectedCovType);
      this.setMembers();
      this.setAffiliations();
      this.checkAffiliations();
      this.pcpInfoApiHlpr.verifyMbrAgeByContractStatus(this.selectedCov.value);
      this.updateNavStatus();
    } catch (error) {
      this.nextBtnDisabled = true;
      this.displayErrorPanel(error);
    }
  }

  onMemberChange() {
    this.resetErrorStates();
    this.nextBtnDisabled = this._dataHlpr.isEmptyString(this.selMbrUid);
    this.selectedMbr = this.mbrsModel.selectableMbrs.find((mbr) => {
      return this._dataHlpr.areEqualStrings(mbr.mbrUid, this.selMbrUid);
    });
    try {
      this.pcpInfoApiHlpr.verifyMbrAgeBySelectedMbr(this.selectedCovType, this.selMbrUid);
      this.skipReasonSelection();
    } catch (error) {
      this.nextBtnDisabled = true;
      this.displayErrorPanel(error);
    }
  }

  onAffiliationChange(selectedAffln: IAffiliationRadioInput) {
    this.resetErrorStates();
    this.selectedAffln = selectedAffln;
    this.selectedAfflnId = selectedAffln?.value || '';
    this.nextBtnDisabled = this.selectedAffln ? false : true;
  }

  onReasonChange(reason: IDropdownItem) {
    this.resetErrorStates();
    this.selectedReason = reason;
    this.showSelectedReason = true;
    this.nextBtnDisabled = this._dataHlpr.isEmptyString(this.selectedReasonCd);
  }

  /**
   * Display a slide out panel with error message.
   * @param error
   */
  displayErrorPanel(error) {
    //Show a Slideout Message Panel
    const slideoutMsgContent = this.content.slideoutMsgCmpContent;

    let message = this._dataHlpr.getValueByKey<string>(error.code, slideoutMsgContent);
    let title: string = slideoutMsgContent['title_' + error.code] ?? slideoutMsgContent.title;
    let mbrName = `${this.selectedMbr?.firstName} ${this.selectedMbr?.lastName}`;
    mbrName = this._titleCasePipe.transform(mbrName);
    message = message.replace(DOCTOR_NAME, this.pcp?.providerName);
    message = message.replace(TERMINATION_DATE, error.terminationDt ?? '');
    message = message.replace(MEMBER_NAME, mbrName);
    const slideMessage: ISlideoutMessage = {
      title,
      close: slideoutMsgContent.close,
      message
    };
    this.slideoutMsgCmp.showMessage(slideMessage);
    throw error;
  }

  /**
   * Set affiliations based on the selected coverage type 'A', 'F' or 'B'
   */
  setAffiliations() {
    this.selectedAffln = null;
    this.selectedAfflnId = '';
    this.stepper.includeStep(SELECT_PCP_ID);
    this.affiliations = this.pcpInfoApiHlpr.getAffliationRadioInputs(this.selectedCovType);
    //When there is only one affiliation to select
    //Auto skip PCP ID selection
    if (this.affiliations.length === 1) {
      this.selectedAffln = this.affiliations[0];
      this.selectedAfflnId = this.selectedAffln.value;
      this.stepper.skipStep(SELECT_PCP_ID);
    }
    this.multipleAffiliations = this.affiliations.length > 1;
  }

  /**
   * Set members based on the selected coverage type 'A', 'F' or 'B'
   */
  setMembers() {
    this.selMbrUid = '';
    this.selectedMbr = null;
    this.stepper.includeStep(SELECT_MEMBER);
    this.mbrsModel = this.mbrPcpDtlsApi.getMembersModel(this.selectedCovType);

    //When there is only one member to select AND there are no non-selectable members
    if (this.mbrsModel.selectableMbrs.length === 1 && this.mbrsModel.nonSelectableMbrs.length === 0) {
      this.selectedMbr = this.mbrsModel.selectableMbrs[0];
      this.selMbrUid = this.selectedMbr.mbrUid;
      this.stepper.skipStep(SELECT_MEMBER);
      this.skipReasonSelection();
    }
  }

  /**
   * Throws error:
   *  When: There are no affiliations in the selected plan
   *  When: There is 1 affiliation but it is not having needed PCP IDs as per the plan
   */
  checkAffiliations() {
    if (!this.affiliations) {
      return;
    }
    if (this.affiliations.length === 0) {
      const error: IException = { code: ERR_NO_AFFILIATIONS, message: 'There are no affiliation radio buttons' };
      throw error;
    }
    if (this.affiliations.length === 1) {
      this.checkPcpIDs(this.affiliations[0]);
    }
  }

  /**
   * Throws error:
   *  When - Selected Plan is B and the selected affliation is not having PCP IDs in active and future plans
   *  When - Selected Plan is A (active) and the selected affiliation is not having PCP IDs for active plan
   *  When - Selected Plan is F (future) and the selected affiliation is not having PCP IDs for future plan
   *
   * Error thrown from this method is handled when clicking Next Button.
   */
  private checkPcpIDs(affiliation: IAffiliationRadioInput) {
    if (!affiliation) {
      const error: IException = { code: ERR_NO_PCPS_IN_AFFILIATION, message: 'Invalid affiliation' };
      throw error;
    }
    const hasPcpIdForActivePlan = affiliation.activePcpIds?.length > 0;
    const hasPcpIdForFuturePlan = affiliation.futurePcpIds?.length > 0;
    const noPcpIDsForBoth = this._dataHlpr.areEqualStrings(affiliation.contractStatus, BOTH_COVERAGES) && (!hasPcpIdForActivePlan || !hasPcpIdForFuturePlan);
    const noPcpIDsForActive = this._dataHlpr.areEqualStrings(affiliation.contractStatus, ACTIVE_COVERAGE) && !hasPcpIdForActivePlan;
    const noPcpIDsForFuture = this._dataHlpr.areEqualStrings(affiliation.contractStatus, FUTURE_COVERAGE) && !hasPcpIdForFuturePlan;
    let message = '';
    if (noPcpIDsForBoth) {
      message = 'Selected affiliation is not having PCP IDs for active & future plans.';
    }
    if (noPcpIDsForActive) {
      message = 'Selected affiliation is not having PCP IDs for active plan.';
    }
    if (noPcpIDsForFuture) {
      message = 'Selected affiliation is not having PCP IDs for future plan.';
    }
    if (noPcpIDsForBoth || noPcpIDsForActive || noPcpIDsForFuture) {
      const error: IException = { code: ERR_NO_PCPS_IN_AFFILIATION, message };
      throw error;
    }
  }

  private async invokeValidateAPI() {
    this.validateApiHlpr.memberName = this.selectedMbr.firstName + ' ' + this.selectedMbr.lastName;
    this.validateApiHlpr.providerName = this.pcp.providerName;
    const onlyDentalTypeCode = this.pcp?.providerNetworkStatus?.typeCode?.length === 1 && this.pcp.providerNetworkStatus.typeCode[0] === NETWORK_TYPECODE.DENTAL;
    if (onlyDentalTypeCode) {
      this._otherReasonCd = DEFAULT_DENTAL_REASON_CODE;
    }

    const pcpIdItems = this.getPcpIdItems();
    const request: IPcpValidateRequest = {
      otherReasonCd: this._otherReasonCd,
      selMbrUid: this.selMbrUid,
      providerName: this.pcp.providerName,
      pcpIdItems
    };

    await this.validateApiHlpr.invoke(request);
    if (this.validateApiHlpr.isSuccess) {
      this.stepper.next();
    }
  }

  private async invokeModifyAPI() {
    this.modifyApiHlpr.memberName = this.selectedMbr.firstName + ' ' + this.selectedMbr.lastName;
    this.modifyApiHlpr.providerName = this.pcp.providerName;

    const modifyReq: IPcpModifyRequest = {
      providerName: this.pcp.providerName,
      selMbrUid: this.selMbrUid,
      selectedAffliation: this.selectedAffln,
      selectedReason: this.selectedReason
    };
    await this.modifyApiHlpr.invoke(modifyReq);
    this.stepper.showPrevBtn = false;
    this.showAssignPcpBtn = false;
    this.showCompleteBtn = true;
    //TODO Update pcpInfo to member in the appSession
  }

  /**
   * Return the first PCP from active plan and future plan.
   * @returns Array of IPcpIdItem
   */
  private getPcpIdItems(): IPcpIdItem[] {
    const pcpIdItems: IPcpIdItem[] = [];
    if (this.selectedAffln.futurePcpIds?.[0]) {
      pcpIdItems.push(this.selectedAffln.futurePcpIds[0]);
    }
    if (this.selectedAffln.activePcpIds?.[0]) {
      pcpIdItems.push(this.selectedAffln.activePcpIds[0]);
    }
    return pcpIdItems;
  }

  /**
   * Reset error states
   */
  private resetErrorStates() {
    this.validateApiHlpr.reset();
    this.modifyApiHlpr.reset();
  }

  /**
   *
   * @param evt
   * @returns
   */
  private onStepChange(evt: StepChangeEvent) {
    if (!evt) {
      return;
    }

    this._appUtil.scrollToTop();

    this.updateNavStatus();

    this.navStatusAria = this.content?.stepNavStatusAria ?? '';
    this.navStatusAria = this.navStatusAria.replace(STEP, evt.step + '');
    this.navStatusAria = this.navStatusAria.replace(TOTAL, evt.total + '');
    if (this.navStatusElem?.nativeElement) {
      (this.navStatusElem.nativeElement as HTMLElement).focus();
    }

    this.showNavStatus = true;

    this.showAssignPcpBtn = false;
    this.showCompleteBtn = false;

    this.nextBtnDisabled = true;
    this.resetErrorStates();

    this.nextBtnAnalytics = this._dataHlpr.getValueByKey(evt.stepName + '_nextBtn', this.content?.btnDataAnalytics, '');
    this.prevBtnAnalytics = this._dataHlpr.getValueByKey(evt.stepName + '_prevBtn', this.content?.btnDataAnalytics, '');

    switch (evt.stepName) {
      case SELECT_PLAN:
        this.nextBtnDisabled = this._dataHlpr.isEmptyString(this.selectedCovType);
        break;
      case SELECT_MEMBER:
        this.nextBtnDisabled = this._dataHlpr.isEmptyString(this.selMbrUid);
        break;
      case SELECT_PCP_ID:
        if (this.multipleAffiliations) {
          //Multi Affiliations
          this.nextBtnDisabled = this.selectedAffln ? false : true;
        } else {
          //Mono Affiliation
          this.nextBtnDisabled = false;
          this.selectedAffln = this.affiliations[0];
          this.selectedAfflnId = this.selectedAffln.value;
        }
        break;
      case SELECT_REASON:
        this.nextBtnDisabled = this._dataHlpr.isEmptyString(this.selectedReasonCd);
        break;
      case REVIEW_SELECTIONS:
        this.showAssignPcpBtn = true;
        break;
    }
  }

  private onStepAutoSkip(evt: StepAutoSkipEvent) {
    if (!evt) {
      return;
    }
    if (evt.goingBackward) {
      switch (evt.stepName) {
        case SELECT_PCP_ID:
          this.selectedAffln = null;
          this.selectedAfflnId = '';
          break;
        case SELECT_MEMBER:
          this.selMbrUid = '';
          this.selectedMbr = null;
          break;
      }
    }
  }

  private updateNavStatus() {
    this.navStatus = this.content?.stepNavStatus ?? '';
    this.navStatus = this.navStatus.replace(STEP, this.stepper.step + '');
    this.navStatus = this.navStatus.replace(TOTAL, this.stepper.total + '');
  }

  private skipReasonSelection() {
    try {
      const contractStatusCd = this.selectedCov?.value;
      const defaultReason = this.pcpResnApiHlpr.getDefaultReason(contractStatusCd);
      /*
      Reason selection page will be displayed in the following conditions--
        Source systems other than  WGS, STAR, ISG, MEDISYS
        A&F are optional, Both is selected, PCP assigned.
        One of the contracts is optional, Both is selected, Mbr has PCP Assigned / Mbr has PCP not assigned.
        One of the contracts is optional, selected A/F is required, Mbr has PCP Assigned / Mbr has PCP not assigned
        One of the contracts is optional, selected A/F is optional, PCP assigned
        A&F are Required, Both is selected, PCP assigned/PCP Not assigned.
        Single contract A or F, Optional, Mbr has PCP Assigned
        Single contract A or F, Required, Mbr has PCP Assigned / Mbr has PCP not assigned.
      */
      this.resetReason();
      this.stepper.includeStep(SELECT_REASON);

      const selectedBoth = this._dataHlpr.areEqualStrings(contractStatusCd, BOTH_COVERAGES);
      const pcpNotAssigned = this._dataHlpr.isEmptyString(this.selectedMbr?.pcp?.pcpId);

      const selectedContract = this.mbrPcpDtlsApi.getContractByStatus(this.selectedCov?.value);
      const pcpOptional = this._dataHlpr.areEqualStrings(selectedContract?.pcpIndicator, PCP_OPTIONAL);
      const hasSingleContract = !this.mbrPcpDtlsApi.hasBothContracts;

      /* Reason selection page will be skipped in the following conditions */
      //A&F are optional, Both is selected, Mbr has PCP not assigned
      const condition1 = this.mbrPcpDtlsApi.hasPcpOptionalOnBothContract && selectedBoth && pcpNotAssigned;
      //A&F are optional, selected A or F optional, Mbr has PCP not assigned
      const condition2 = this.mbrPcpDtlsApi.hasPcpOptionalOnBothContract && !selectedBoth && pcpNotAssigned;
      //One of the contracts is optional, selected A or F optional, Mbr has PCP not assigned
      const condition3 = !this.mbrPcpDtlsApi.hasPcpOptionalOnBothContract && pcpOptional && pcpNotAssigned;
      //Single contract A or F, Optional, Mbr has PCP not assigned
      const condition4 = hasSingleContract && pcpOptional && pcpNotAssigned;

      if (condition1 || condition2 || condition3 || condition4) {
        this.stepper.skipStep(SELECT_REASON);
        this.selectedReason = defaultReason;
        this.selectedReasonCd = defaultReason.value;
        this.showSelectedReason = false;
        return;
      }
    } catch (error) {
      //Show 'Reason Selection' page when some errors happens
      if (error instanceof InvalidSourceSystemError) {
        this.resetReason();
        this.stepper.includeStep(SELECT_REASON);
      }
      console.error(error);
    }
  }

  private resetReason() {
    this.selectedReason = null;
    this.selectedReasonCd = '';
    this.showSelectedReason = true;
  }
}
