import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFChangePlanComponent } from './components/pfChangePlanCmp';
import { PFChangePlanV2Component } from './components/pfChangePlanV2Cmp';
@NgModule({
    declarations: [PFChangePlanComponent, PFChangePlanV2Component],
    exports: [PFChangePlanComponent, PFChangePlanV2Component],
    imports: [
        CommonModule,
        FormsModule,
        UxSharedModule,
    ],
    providers: []
})
export class ChangePlanModule { }
