import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { PFCostInfoComponent } from '../../../common/components/cost-info/pfCostInfoCmp';
import { TAB_KEYCODE } from '../../../common/constants/app-constants';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { ICptCostInfo, PFSearchCostEstimateSidePanelVM, PFSearchCostInfo } from '../../../common/interfaces/iCptMedicalResponse';
import { DataHelper } from '../../../common/services/dataHelper';
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IProviderCptCodes } from '../../../fad/provider-details/models/iProviderCptCodesResponse';
import { CptSummaryModel } from '../../../fad/search-results/models/cptSummaryModel';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-pf-cpt-cost-info-cmp',
  templateUrl: '../views/pfCptCostInfoCmp.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class PFCptCostInfoComponent extends BaseComponent {
  @Input()
  costInfo: ICptCostInfo;
  @Input()
  selectedCptObj: IProviderCptCodes;
  @Input()
  showCostLoader: boolean;
  @Input()
  selectedPos: string;
  @Input()
  selectedModifier: string;
  @Input()
  showError = false;
  @Input()
  hideCostDetails = false;
  isException: boolean;
  @ViewChild('costDetailModal')
  costDetailModal: PFCostInfoComponent;
  @ViewChild('disclaimerSidePanel') disclaimerSidePanel: TemplateRef<HTMLElement>;
  disclaimerSidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  sidePanelTabKeyListener: { (): void };
  printCostInfo: PFSearchCostEstimateSidePanelVM;
  navigateToUrl: AppNavigations;
  @Output() cptValue = new EventEmitter<boolean>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _dataHelper: DataHelper,
    private _renderer: Renderer2,
    private _sidePanel: SidePanel,
    private _cptSearchModel: CptSummaryModel,
    private router: Router,
    private _navigationService: NavigationService
    ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnChanges(args: SimpleChanges) {
    this.isException = !this.costInfo || (this.costInfo && this._dataHelper.isObject(this.costInfo) && Object.keys(this.costInfo).length === 0);
    if (!this.isException && !this.costInfo.isBenefitDenied && !this.costInfo.isPlanNotCovered) {
      this.getPrintObj();
    } else {
      this.printCostInfo = null;
    }
  }

  openDisclaimerSidePanelMsg() {
    this.disclaimerSidePanelRef = this._sidePanel.open('right', this.disclaimerSidePanel);
    this.sidePanelTabKeyListener = this._renderer.listen('document', 'keydown', (event) => {
      if (event.keyCode === TAB_KEYCODE) {
        event.preventDefault();
      }
    });
  }

  closeDisclaimerSidePanel() {
    this.disclaimerSidePanelRef.close();
    this.sidePanelTabKeyListener();
  }

  getPrintObj() {
    const costInfoObj: PFSearchCostInfo = {
      placeOfService: this.selectedPos,
      modifierCode: this.selectedModifier,
      costInfo: this.costInfo
    };
    this.printCostInfo = this._cptSearchModel.costEstimate(this.content.cptCostEstimateSidePanelContent.costContent, costInfoObj);
  }

  goToProviderList() {
    this._appSession.isCPTCodeSearch = true;
    this.cptValue.emit(this._appSession.isCPTCodeSearch);
    this._appSession.initialSearch = true;
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
  }
}
