export const OTHER_REASON_CODES = {
  default: '99',
  NASCO: '249',
  MEDISYS: 'UNKN',
  MWMEDSYS: 'UNKN'
} as const;

export const REASON_CODES = {
  RC_30: '30',
  RC_MBRQ: 'MBRQ'
} as const;

export const DEFAULT_DENTAL_REASON_CODE = '30';
