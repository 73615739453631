import { Component, Inject, Input, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalRef, SidePanel } from "@anthem/uxd/modal";
import { DataHelper } from "../../../../../app/common/services/dataHelper";
import { ISelectedProvider } from "../../../../../app/fad/provider-details/models/iSelectedProvider";
import { CommonUtil } from "../../../../../app/fad/utilities/commonUtil";
import { EventHandler } from "../../../services/eventHandler";
import { AppSession } from "../../../values/appSession";
import { ContentHelper } from "../../../values/contentHelper";
import { BaseComponent } from "../../base-component/baseCmp";
import { IReportInvalid, ISearchInfo } from "../interfaces/iReportInvalid";
import { IReportValues, IWrongInfo } from "../interfaces/iReportValues";
import { ReportInvalidService } from "../services/reportInvalidSvc";

@Component({
  moduleId: module.id,
  selector: 'app-report-invalid-side-panel',
  templateUrl: '../views/pfReportInvalidSidePanelCmp.html'
})
export class PFReportInvalidSidePanelComponent extends BaseComponent {

  @Input()
  reportValues: IReportValues;
  @Input()
  selectedProvider: ISelectedProvider;
  @Input()
  selectedAddressId: string;

  @ViewChild('reportInvalidContentModal')
  reportInvalidContentModal: TemplateRef<HTMLElement>;

  sidePanelRef: ModalRef<any, any>;
  wrongInfoOptionError: boolean;
  noEmailError: boolean;
  emailError: boolean;
  emailSentError: boolean;
  representativeSelected: boolean;
  emailAddress: string;
  wrongInfoVal: string;
  wrongInfoLbl: string;
  correctedInfo: string;
  cbRepresentative = {
    id: 'representativeInd',
    name: 'representativeInd',
    label: this.content?.reportInvalid?.sidePanel?.representativeLabel,
    trueValue: true,
    falseValue: false
  };
  private _reportInvalid: IReportInvalid;
  private _enableSendEmail: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _contentHelper: ContentHelper,
    private _dataHelper: DataHelper,
    private _sidePanel: SidePanel,
    private _reportInvalidService: ReportInvalidService
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      'PFProviderDetailsContainerComponent'
    );
  }

  get titleMessage(): string {
    return ((this.content?.reportInvalid?.sidePanel?.title || '') as string).replace(/{NAME}/gi, this.reportValues?.providerName);
  }

  /**
   * This method will open the side panel
   * @param event
   * @returns
   */
  openSidePanel(): void {
    this.resetBindings();
    this.emailSentError = false;
    this.sidePanelRef = this._sidePanel.open('right', this.reportInvalidContentModal);
  }

  onCloseClick(): void {
    this.sidePanelRef?.close();
  }

  onWrongInfoChange(wrongInfo: IWrongInfo): void {
    this.wrongInfoVal = wrongInfo.value;
    this.wrongInfoLbl = wrongInfo.label;
  }

  onWrongInfoBlur(): void {
    this.wrongInfoOptionError = this.wrongInfoVal === '0';
  }

  onEmailClick(): void {
    this.onWrongInfoBlur();
    this.validateEmail();
    if (!this.wrongInfoOptionError && !this.noEmailError && !this.emailError && this._enableSendEmail) {
      this._enableSendEmail = false;
      this.setRequestParameters();
      this._reportInvalidService.reportInvalid(this._reportInvalid).then((status: boolean) => {
        if (status) {
          this._reportInvalidService.emailSentSuccessfully(this.content?.reportInvalid?.sidePanel?.successMsg);
          this.onCloseClick();
        } else {
          this.emailSentError = true;
        }
      }).catch((reason) => {
        this.emailSentError = true;
      }).finally(() => {
        if (!this.emailSentError) {
          this.resetBindings();
        }
      })
    } else {
      this.jumpToErrorField();
    }
  }

  get emailAriaLabel(): string {
    let ariaLbl = this.content?.reportInvalid?.sidePanel?.emailAriaLabel;
    if (this.noEmailError) {
      ariaLbl = this.content?.reportInvalid?.sidePanel?.missingEmailError;
    } else if (this.emailError) {
      ariaLbl = this.content?.reportInvalid?.sidePanel?.invalidEmailError;
    }
    return ariaLbl;
  }

  jumpToErrorField() {
    if (this.wrongInfoOptionError) {
      document.getElementById('ddl-wrong-info_button').focus();
    } else if (this.noEmailError || this.emailSentError) {
      document.getElementById('txt-email-address').focus();
    }
  }

  onCancelClick(): void {
    this.resetBindings();
    this.onCloseClick();
  }

  validateEmail(): void {
    this.noEmailError = this._dataHelper.isEmptyString(this.emailAddress);
    this.emailError = this.noEmailError ? false : !this._dataHelper.isValidEmail(this.emailAddress);
  }

  private setRequestParameters(): void {
    this._reportInvalid = {} as IReportInvalid;
    this._reportInvalid.correctedInfo = this.correctedInfo;
    this._reportInvalid.emailAddress = this.emailAddress;
    this._reportInvalid.isRepresentative = this.representativeSelected;
    this._reportInvalid.wrongInfo = this.wrongInfoLbl;
    this._reportInvalid.searchInfo = {} as ISearchInfo;
    this._reportInvalid.searchInfo.distance = this._appSession.searchParams?.distanceNm;
    this._reportInvalid.searchInfo.keyword = this._appSession.searchTerm;
    this._reportInvalid.searchInfo.planName = this._appSession.searchParams?.plan?.name || this._appSession.searchParams?.plan?.alphaPrefix;
    this._reportInvalid.searchInfo.searchCity = !this._appSession.searchParams?.countyCode ? this._appSession.searchParams?.city : '';
    this._reportInvalid.searchInfo.searchState = this._appSession.searchParams?.stateCd?.code;
    this._reportInvalid.searchInfo.searchCounty = this._appSession.searchParams?.countyCode ? this._appSession.searchParams?.city : '';
    this._reportInvalid.searchInfo.searchZip = !this._appSession.searchParams?.countyCode && !this._appSession.searchParams?.city ? this._appSession.searchParams?.zip : '';
    this._reportInvalid.providerDetails = CommonUtil.buildProviderDetailsRequest(this._appSession, this.selectedProvider, this.selectedAddressId);
  }

  private resetBindings(): void {
    this.correctedInfo = '';
    this.emailAddress = '';
    this.representativeSelected = false;
    this.wrongInfoLbl = '';
    this.wrongInfoVal = '0';
    this.wrongInfoOptionError = false;
    this.noEmailError = false;
    this.emailError = false;
    this._enableSendEmail = true;
  }
}
