import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { BaseComponent } from '../../base-component/baseCmp';
import { ProcedureCategoryService } from './../../../../eyc/cost-search/services/procedureCategorySvc';
import { ISearchParameters } from './../../../../fad/search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from './../../../../fad/search-providers/services/providerUtilitySvc';
import { PROVIDER_OPTIONS, SORT_RESULT } from './../../../../fad/search-providers/values/providerSearchConstants';
import { EycProcedureCriteria, ISearchRequest } from './../../../../fad/search-results/interfaces/iSearchRequest';
import { ProviderSummaryService } from './../../../../fad/search-results/services/providerSummarySvc';
import { CommonUtil } from './../../../../fad/utilities/commonUtil';
import { ResultsUtil } from './../../../../fad/utilities/resultsUtil';
import { Locale } from './../../../constants/app-constants';
import { AppNavigations } from './../../../constants/app-navigations';
import { LocationResponse } from './../../../models/locationAddress';
import { DataHelper } from './../../../services/dataHelper';
import { NavigationService } from './../../../services/navigationService';
import { AppUtility } from './../../../utilities/appUtil';
import { ContentHelper } from './../../../values/contentHelper';
import { SecureStateHandler } from './../../app-secure/services/secureStateHandler';
import { EmulateCostService } from './../services/emulateCostSvc';

@Component({
  moduleId: module.id,
  selector: 'app-emulate-cost-cmp',
  templateUrl: './emulateCostCmp.html'
})
export class EmulateCostComponent extends BaseComponent implements OnInit {
  showLoader: boolean = false;
  showError: boolean = false;
  benefitResults: any;
  bodyObj: any;
  metaData: any = {
    mbrUid: '',
    contractUid: '',
    procedurecode: ''
  };
  selectedHierarchy: string;
  hierarchyDdlValues: any = [];
  costFormula: any;
  errorMsg: string = '';
  locationTxt: string = '';
  locale: string = '';
  countyList = [];
  searchParams: ISearchParameters;
  procedureDetails: any = [];
  pageSize: number = 5;
  providerList: any = [];
  primaryState: string = '';
  expansion: string = '';
  sortSelected: number = 5;
  selectedItem: string = 'P';
  eycOpen: boolean = true;
  accOpen: boolean = true;
  benefitOpen: boolean = true;
  sortResult: any = SORT_RESULT;
  providerOptions: any = PROVIDER_OPTIONS;
  findCareProviderList: any = [];
  showEycCost: boolean = false;
  showCostFormula: boolean = false;

  private _promises: any[] = [];

  constructor(
    private _eventHandler: EventHandler,
    private _router: Router,
    private _route: ActivatedRoute,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _secureStateHandler: SecureStateHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _navigationService: NavigationService,
    private _dataHelper: DataHelper,
    private _emulateCostService: EmulateCostService,
    private _providerUtilityService: ProviderUtilityService,
    private _procedureCategoryService: ProcedureCategoryService,
    private _providerSummaryService: ProviderSummaryService,
    private ref: ChangeDetectorRef,
    private _appUtility: AppUtility
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFEmulateCostComponent');
  }

  ngOnInit() {
    this.setSearchParams();
    this.resetPromises = [];

    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
  }

  emulateCostEstimate() {
    if (this.metaData.mbrUid === '' || this.metaData.contractUid === '' || this.metaData.procedurecode === '') {
      this.showError = true;
      this.errorMsg = 'Please enter all required fields';
      return;
    } else {
      this.showLoader = true;
      this.benefitResults = null;
      this.costFormula = null;
      this._appSession.metaData.pfEmMode = true;
      this._providerUtilityService.getDataModified(this.metaData.mbrUid).then(
        (result: any) => {
          if (result && result.isMaintenanceEnabled) {
            this._navigationService.navigateByUrl(AppNavigations.MAINTENANCE_PAGE_PATH);
          } else if (result.codeTypeList && result.codeTypeList.length > 0) {
            this._appSession.provDataModifiedOn = result.codeTypeList[0].code;
            this._appSession.metaData.token = result.token;
            if (this._appSession.metaData && this._appSession.metaData.appContract) {
              this._appSession.metaData.appContract.mbrUid = this.metaData.mbrUid;
            }
            this.onGetTokenSuccess();
          }
        },
        (error: any) => {
          this.onError(error);
        }
      );
    }
  }

  onGetTokenSuccess() {
    this._emulateCostService.getBenefitId(this.metaData).then(
      (result: any) => {
        this.onBenefitIdSuccess(result);
      },
      (error: any) => {
        this.onError(error);
      }
    );
  }

  onBenefitIdSuccess(result) {
    if (result && result.benefitId) {
      this.benefitResults = result;
      this.showLoader = false;
      this.showError = false;
      //this.getHierarchyList();
    } else {
      this.benefitResults = null;
      this.showLoader = false;
      this.showError = true;
      this.errorMsg = 'Benefit Information Unavailable';
    }
  }

  onError(error: any, errorMsg?: string) {
    this.showLoader = false;
    this.showError = true;
    this.errorMsg = errorMsg ? errorMsg : 'Benefit Information Unavailable';
    if (error) {
      throw error;
    }
  }

  getHierarchyList() {
    this._emulateCostService.getMemberHierarchy(this.metaData).then(
      (result: any) => {
        if (result && result.benefitList) {
          this.selectedHierarchy = this.benefitResults.benefitId;
          this.buildHierarchyDropDown(result.benefitList);
          this.showLoader = false;
        }
      },
      (error: any) => {
        this.onError(error);
      }
    );
  }

  buildHierarchyDropDown(benefitList: any) {
    let _index = 0;
    for (const obj of benefitList) {
      const objHierarchies = {
        label: obj.benefitHierarchy,
        value: obj.benefitId,
        id: 'cB_Hier_' + _index,
        name: 'cB_Hier_' + _index,
        trueValue: obj.benefitId,
        falseValue: '',
        isChecked: false
      };
      if (obj.benefitId === this.benefitResults.benefitId) {
        this.selectedHierarchy = obj.benefitId;
      }
      this.hierarchyDdlValues.push(objHierarchies);
      _index++;
    }
  }

  onGetCostFormula() {
    this.showCostFormula = true;
    this.showEycCost = false;
    this.showLoader = true;
    this.bodyObj = {};
    this.bodyObj.benefitId = this.benefitResults.benefitId;
    this.bodyObj.mbrUid = this.metaData.mbrUid;
    this.bodyObj.contractUid = this.metaData.contractUid;
    this._emulateCostService.getCostFormula(this.bodyObj, true).then(
      (result: any) => {
        if (result && result.benefitCostInfos) {
          this.costFormula = result.benefitCostInfos;
          this.showLoader = false;
          this.showError = false;
        }
      },
      (error: any) => {
        this.onError(error);
      }
    );
  }

  emulateEYCCost() {
    this.showEycCost = true;
    this.showCostFormula = false;
    this.resetPromises = [];
    if (this.validateMetaData() === false) {
      return;
    }
    this.showLoader = true;
    //if (this._appSession.metaData && (!this._appSession.metaData.token ||
    //(this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid &&
    //this.metaData.mbrUid !== this._appSession.metaData.appContract.mbrUid))) {
    this.getToken();
    //}
    Promise.all(this.getPromises).then(
      (result: any) => {
        this.getEYCCost();
      },
      (error: any) => {
        const errorMsg = this.content.eyc.alerts.eycCostError;
        this.onError(error, errorMsg);
      }
    );
  }

  getEYCCost() {
    this.resetPromises = [];
    this.getGeoCoordinates();
    this.getProcedure();

    Promise.all(this.getPromises).then(() => {
      this.callSummaryResult();
    });
  }

  callSummaryResult() {
    this.bindSearchParams();
    const searchRequest = ResultsUtil.buildEycRequest(this._appSession, this.pageNumber, this.pageSize);
    this.resetPromises = [];
    this.callSvcProfessional(searchRequest);
    Promise.all(this.getPromises).then(() => {
      if (this.providerList && this.providerList.length > 0) {
        this.callFindCareCost(searchRequest);
      }
    });
  }

  callSvcProfessional(searchRequest: ISearchRequest) {
    this._promises.push(
      this._providerSummaryService.getProfessionals(searchRequest).then(
        (result: any) => {
          this.onProfessionalSummarySuccess(result);
        },
        (error: any) => {
          this.providerList = [];
          const errorMsg = this.content.eyc.alerts.eycCostError;
          this.onError(error, errorMsg);
        }
      )
    );
  }

  callFindCareCost(searchRequest: ISearchRequest) {
    this._emulateCostService.getFindCareCost(searchRequest, true).then(
      (result: any) => {
        this.onFindCareCostSuccess(result);
      },
      (error: any) => {
        this.providerList = [];
        const errorMsg = this.content.eyc.alerts.eycCostError;
        this.onError(error, errorMsg);
      }
    );
  }

  onFindCareCostSuccess(result: any) {
    this.showLoader = false;
    this.showError = false;
    if (result && result.providerList && result.providerList.length > 0) {
      this.findCareProviderList = result.providerList;
      this.mapFindCareCost();
    } else {
      this.findCareProviderList = [];
      const errorMsg = this.content.eyc.labels.noProviderMsg;
      this.onError('', errorMsg);
    }
  }

  mapFindCareCost() {
    if (this.providerList && this.providerList.length > 0 && this.findCareProviderList && this.findCareProviderList.length > 0) {
      this.providerList.forEach((eycProvider) => {
        let providerItem: any = [];
        if (eycProvider.providerIdentifier) {
          providerItem = this.findCareProviderList.filter((fadProv) => fadProv.providerIdentifier === eycProvider.providerIdentifier);
        } else if (eycProvider.providerName) {
          providerItem = this.findCareProviderList.filter((fadProv) => fadProv.providerName === eycProvider.providerName);
        }
        let eycCostInfo = [];
        /* if (eycProvider.location && eycProvider.location.address && providerItem && providerItem.length >0) {
          const addressIdentifier = eycProvider.location.address.addressId ? eycProvider.location.address.addressId :
            eycProvider.location.address.addressOne;
          providerItem.forEach((fadProv) => {
            if (fadProv.location && fadProv.location.address && fadProv.location.address.addressId) {
              eycCostInfo = (fadProv.location.address.addressId === addressIdentifier) ? fadProv.eycCostInfo : eycCostInfo;
            } else if (fadProv.location && fadProv.location.address && fadProv.location.address.addressOne) {
              eycCostInfo = (fadProv.location.address.addressOne === addressIdentifier) ? fadProv.eycCostInfo : eycCostInfo;
            }
          });
        } */
        providerItem.forEach((fadProv) => {
          if (this._dataHelper.deepEqual(fadProv.location.address, eycProvider.location.address)) {
            eycCostInfo = fadProv.eycCostInfo;
          }
        });
        eycProvider.fadCostInfo = eycCostInfo;
      });
    }
  }

  onProfessionalSummarySuccess(result: any) {
    if (result && result.providerList && result.providerList.length > 0) {
      this.providerList = result.providerList;
    } else {
      this.providerList = [];
      const errorMsg = this.content.eyc.labels.noProviderMsg;
      this.onError('', errorMsg);
    }
  }

  getProcedure() {
    this._promises.push(
      this._procedureCategoryService.getProcedureDetails(this.metaData.procedurecode.trim()).then(
        (result: any) => {
          if (result && result.procedureBenefitList) {
            this.procedureDetails = result.procedureBenefitList;
            if (result.procedureBenefitList.providerCategoryCode) {
              this.preSelectCategoryCode(result.procedureBenefitList.providerCategoryCode);
              this.searchParams.providerTypeCodeList = [];
            } else {
              this.selectedItem = this.providerOptions[0].value;
            }
          } else {
            this.procedureDetails = [];
            const errorMsg = this.content.eyc.alerts.procedureError;
            this.onError('', errorMsg);
          }
        },
        (error: any) => {
          const errorMsg = this.content.eyc.alerts.procedureError;
          this.onError(error, errorMsg);
        }
      )
    );
  }

  preSelectCategoryCode(categoryCode: any) {
    if (categoryCode.code) {
      const filterArray = this.providerOptions.filter((option) => option.value === categoryCode.code);
      if (filterArray.length > 0) {
        this.providerOptions.forEach((option) => {
          option.disable = false;
          if (option.value !== categoryCode.code) {
            option.disable = true;
          }
        });
        this.selectedItem = categoryCode.code;
      } else {
        this.providerOptions.forEach((option) => {
          option.disable = false;
        });
        this.selectedItem = this.providerOptions[0].value;
      }
    }
  }

  getToken() {
    this._appSession.metaData.pfEmMode = true;
    this._promises.push(
      this._providerUtilityService.getDataModified(this.metaData.mbrUid).then(
        (result: any) => {
          if (result && result.isMaintenanceEnabled) {
            this._navigationService.navigateByUrl(AppNavigations.MAINTENANCE_PAGE_PATH);
          } else if (result.codeTypeList && result.codeTypeList.length > 0) {
            this._appSession.provDataModifiedOn = result.codeTypeList[0].code;
            this._appSession.metaData.token = result.token;
          }
        },
        (error: any) => {
          const errorMsg = this.content.eyc.alerts.eycCostError;
          this.onError(error, errorMsg);
        }
      )
    );
  }

  bindSearchParams() {
    if (this.procedureDetails) {
      const eycObject: EycProcedureCriteria = {
        procedureCode: this.procedureDetails.procedureCode ? this.procedureDetails.procedureCode : '',
        procedureName: this.procedureDetails.procedureName ? this.procedureDetails.procedureName : '',
        providerCategoryCode: this.procedureDetails.providerCategoryCode && this.procedureDetails.providerCategoryCode.code ? this.procedureDetails.providerCategoryCode.code : ''
      };
      this.searchParams.eycSearchParam = {
        eycProcedureCriteria: eycObject
      };
    }
    this._appSession.initialSearch = true;
    if (this._appSession.metaData && this._appSession.metaData.appContract) {
      this._appSession.metaData.appContract.mbrUid = this.metaData.mbrUid;
    }

    if (this._appSession.metaData && this._appSession.metaData.appContract) {
      this._appSession.metaData.appContract.contractUid = this.metaData.contractUid;
    }
    this._appSession.searchParams = this.searchParams;
  }

  getGeoCoordinates() {
    let _searchTerm = this.locationTxt.trim();
    /* const _countyPattrn = /county/gi;
    const _match = _countyPattrn.exec(_searchTerm);
    const _isCounty = (_match && _match.length > 0) ? true : false;
    if (_isCounty) {
      _searchTerm = _searchTerm.replace(_countyPattrn, '').trim();
    } */
    if (_searchTerm.match(/^[0-9]+$/) != null) {
      if (_searchTerm.length >= 5) {
        this._promises.push(
          this._providerUtilityService.getGeoLocation(_searchTerm).then(
            (result: LocationResponse[]) => {
              if (result && Object.keys(result).length > 0) {
                const results = this._appUtility.buildLocationResponse(result);
                this.searchParams.coordinates.latitude = results[Object.keys(results)[0]].lat;
                this.searchParams.coordinates.longitude = results[Object.keys(results)[0]].long;
                this.searchParams.stateCd = {
                  code: results[Object.keys(results)[0]].stateCd
                };
                this.searchParams.zip = results[Object.keys(results)[0]].city;
              } else {
                this.clearCoordinates();
                const errorMsg = this.content.eyc.alerts.zipCodeError;
                this.onError('', errorMsg);
              }
              this._appSession.resetGeoLocation();
            },
            (error: any) => {
              const errorMsg = this.content.eyc.alerts.zipCodeError;
              this.onError(error, errorMsg);
            }
          )
        );
      }
    } else {
      if (_searchTerm.length >= 3) {
        this._promises.push(
          this._providerUtilityService.getGeoLocation(_searchTerm).then(
            (result: LocationResponse[]) => {
              if (_searchTerm.length >= 3) {
                if (result && Object.keys(result).length > 0) {
                  const results = this._appUtility.buildLocationResponse(result);
                  _searchTerm = this.transformSearchTerm(_searchTerm);
                  const coordinates: any = results[_searchTerm];
                  if (coordinates) {
                    this.setCoordinates(coordinates);
                  } else {
                    this.clearCoordinates();
                    const errorMsg = this.content.eyc.alerts.zipCodeError;
                    this.onError('', errorMsg);
                  }
                }
              }
            },
            (error: any) => {
              const errorMsg = this.content.eyc.alerts.zipCodeError;
              this.onError(error, errorMsg);
            }
          )
        );
      }
    }
  }

  clearCoordinates() {
    this.searchParams.zip = '';
    this.searchParams.countyCode = null;
    this.searchParams.stateCd = null;
    this.searchParams.city = '';
    this.searchParams.coordinates = {
      latitude: '',
      longitude: ''
    };
  }

  setCoordinates(coordinates: any) {
    this.clearCoordinates();
    this.searchParams.coordinates.latitude = coordinates.lat;
    this.searchParams.coordinates.longitude = coordinates.long;
    this.searchParams.coordinates.countyLatitude = '0';
    this.searchParams.coordinates.countyLongitude = '0';
    this.searchParams.city = coordinates.city;
    this.searchParams.stateCd = {
      code: coordinates.stateCd
    };
    this.locationTxt = this.searchParams.zip = coordinates.name;
    this.searchParams.countyCode = null;
  }

  validateMetaData() {
    if (this._dataHelper.isEmptyString(this.metaData.mbrUid)) {
      this.showError = true;
      this.errorMsg = this.content.eyc.alerts.mcidError;
      return false;
    } else if (this._dataHelper.isEmptyString(this.metaData.contractUid)) {
      this.showError = true;
      this.errorMsg = this.content.eyc.alerts.contractUidError;
      return false;
    } else if (this._dataHelper.isEmptyString(this.locationTxt)) {
      this.showError = true;
      this.errorMsg = this.content.eyc.alerts.zipCodeError;
      return false;
    } else if (this._dataHelper.isEmptyString(this.metaData.procedurecode)) {
      this.showError = true;
      this.errorMsg = this.content.eyc.alerts.procedureCodeError;
      return false;
    }
  }

  setSearchParams() {
    this.searchParams = {
      distanceNm: this.content.eyc.labels.distance
    };
    this.clearCoordinates();
  }

  transformSearchTerm(searchTerm: string) {
    let locString = '';
    if (searchTerm.includes(',')) {
      const splitString = searchTerm.split(',');
      if (splitString[0]) {
        const countyTerm = splitString[0].split(' ');
        let mergeTerm = '';
        countyTerm.forEach((term) => {
          if (term) {
            mergeTerm += ' ' + term.replace(term.charAt(0), term.charAt(0).toLocaleUpperCase()).trim();
          }
        });
        splitString[0] = mergeTerm ? mergeTerm : splitString[0];
      }
      if (splitString[1]) {
        splitString[1] = splitString[1].toLocaleUpperCase().trim();
      }
      locString = splitString[0].trim() + ', ' + splitString[1];
    }
    return locString ? locString : searchTerm;
  }

  sortSearchFilter(event: any) {
    if (!(this.sortSelected === event.value)) {
      this.sortSelected = event.value;
      if (event.value === 'All') {
        event.value = 0;
      }
      this.pageSize = event.value;
      this.showLoader = true;
      this.callSummaryResult();
    }
  }

  onItemChange(event: any) {
    this.selectedItem = event;
    this.searchParams.typeSelectNm = event === 'F' ? 'H' : event;
    this.showLoader = true;
    this.callSummaryResult();
  }

  get getPromises(): any[] {
    return this._promises;
  }

  set resetPromises(object: any[]) {
    this._promises = object;
  }

  private costCDHPPaysText(accntType: string): string {
    return this.content.eyc.labels.costCDHPPaysText.replace(/{ACCOUNTTYPE}/gi, accntType);
  }

  private costCDHPPaysAriaText(accntType: string): string {
    this.expansion = CommonUtil.getAccountExpansion(accntType);
    return this.content.eyc.labels.costCDHPPaysText.replace(/{ACCOUNTTYPE}/gi, this.expansion);
  }
}
