export class Stack<T> {
  private _stack: T[];
  private _length: number;
  private readonly _maxSize: number;

  constructor(maxSize: number) {
    this._length = 0;
    this._maxSize = maxSize;
    this._stack = new Array<T>(this._maxSize);
  }

  get isEmpty(): boolean {
    return this._length === 0;
  }

  get isFull(): boolean {
    return this._length === this._maxSize;
  }

  push(newItem: T): void {
    if (this.isFull) {
      throw new Error('Stack overflow');
    }

    this._stack[this._length++] = newItem;
  }

  pop(): T {
    if (this.isEmpty) {
      throw new Error('Stack underflow');
    }

    const retval = this._stack[--this._length];
    return retval;
  }

  top(): T {
    if (this.isEmpty) {
      throw new Error('Stack is empty');
    }

    return this._stack[this._length - 1];
  }
}
