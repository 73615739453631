import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ILocationRequest, ILocationsResponse } from '../models/iLocations';
import { IProviderPlansRequest } from '../models/iProviderPlansRequest';
import { IProviderPlansResponse } from '../models/iProviderPlansResponse';
import { apiNameList } from './../../../../environments/api-name-list';
import { HttpMethod } from './../../../common/enums/httpMethodEnum';
import { BaseService } from './../../../common/services/baseService';
import { IServiceAreaValidationRequest, IServiceAreaValidationResponse } from './../../search-providers/interfaces/iServiceAreaValidation';
import { INetworkPrefixResponse } from './../models/iNetworkPrefixResponse';

@Injectable()
export class ProviderOrchestrationService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getPlansAccepted(reqObj: IProviderPlansRequest): Promise<IProviderPlansResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.plansAccepted,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getProviderLocations(reqObj: ILocationRequest): Promise<ILocationsResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.locationsV1,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getAffiliationDetails(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.affiliations,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
  getAffiliations(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.affiliationsV2,
      data: reqObj,
      headers: this.getHeaders()
    });
  }


  getProviderProcedureDetails(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerProceduresInfo,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getProviderCostEstimate(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerCostEstimate,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getGeoTravelTime(reqObj: any): Promise<any> {

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.traveltime,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getOfficeVisitCost(reqObj: any): Promise<any> {

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.officeVisitCost,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getNetworksBasedOnPrefix(reqObj: string): Promise<INetworkPrefixResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.prefixNetworks,
      urlParams: [
        {
          name: 'prefix',
          value: reqObj,
          isQueryParam: true
        }
      ],
      headers: this.getHeaders()
    });
  }


  getServiceAreaValidation(reqObj: IServiceAreaValidationRequest): Promise<IServiceAreaValidationResponse> {

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.serviceAreaValidation,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
