import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAppointment, IAppointmentLoader } from '../../../common/interfaces/iAppointment';
import { IWindow } from '../../../common/interfaces/iWindow';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { IMemberKeyRequest } from './../../../common/interfaces/iMemberKey';
import { RouteUtil } from './../../../common/utilities/routeUtil';
import { ISearchParameters } from './../../../fad/search-providers/interfaces/iSearchParameters';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { AppExternalCommands, ORIGIN } from './../../constants/app-constants';
import { AppNavigations } from './../../constants/app-navigations';
import { AppEvents } from './../../enums/appEvents';
import { INFOBOX_EVENTS } from './../../enums/infoBox';
import { IEventDetail } from './../../interfaces/iEventDetail';
import { EventHandler } from './../../services/eventHandler';
import { MemberKeyService } from './../../services/memberKeySvc';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-schedule-appointment',
  templateUrl: './scheduleAppointmentCmp.html'
})

export class ScheduleAppointmentComponent extends BaseComponent implements OnInit {

  @Input()
  btnClass: string = 'btn-secondary';
  @Input()
  btnAriaLabel: string = '';
  @Input()
  isVisibile: boolean = false;
  @Input()
  index: number = 0;
  @Input() pageName: string;
  @Input()
  eventKey: string = '';
  @Output()
  loader: EventEmitter<IAppointmentLoader> = new EventEmitter<IAppointmentLoader>();
  isMemberSecure: boolean = false;
  tagName: string = '';

  private _pfAppCommandEvent: EventEmitter<IEventDetail>
    = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  private _npiList: any;
  @Input() set npiList(value: any) {
    this._npiList = value;
  }

  private _facilityId: string;
  @Input() set facilityId(value: string) {
    this._facilityId = value;
  }

  private _apmtType: string = '';
  @Input() set appointmentType(value: string) {
    this._apmtType = value;
  }

  constructor(
    private _memberKeySvc: MemberKeyService,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _routeUtil: RouteUtil
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    if (this._appSession) {
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    }

    this.updateAnalyticsTag();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['eventKey'] !== 'undefined' &&
      changes['eventKey'] !== null &&
      typeof changes['eventKey'].currentValue !== 'undefined' &&
      changes['eventKey'].currentValue !== null &&
      changes['eventKey'].currentValue !== '') {

      const eventKey = changes['eventKey'].currentValue as string;

      this.handleEvents(eventKey);
    }
  }

  appointmentSchedule() {
    const eventDetail = {} as IEventDetail;
    const appointmentObj = {} as IAppointment;
    const appointmentLoaderObj = {} as IAppointmentLoader;
    appointmentLoaderObj.loader = true;
    appointmentLoaderObj.apiError = false;
    this.loader.emit(appointmentLoaderObj);

    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.details = eventDetail.message = AppExternalCommands.RCP_SCHEDULER_LANDING.MSG;
    eventDetail.target = AppExternalCommands.RCP_SCHEDULER_LANDING.CMD;

    const _memberKeyReq = {} as IMemberKeyRequest;
    if (this._npiList) {
      _memberKeyReq.npi = this._npiList;
    }
    if (this._facilityId) {
      _memberKeyReq.facilityId = this._facilityId;
    }

    if (this._appSession.metaData.idToken) {
      _memberKeyReq.idToken = this._appSession.metaData.idToken;
    }

    /** Search params excluding the drop-down object values */
    const _appSession: AppSession = Object.assign({}, this._appSession);
    _appSession.searchParams = this._appSession.searchParams;
    const _searchParams: ISearchParameters = Object.assign({}, _appSession.searchParams);
    _searchParams.providerTypeDdlValues = null;
    _searchParams.speicalityDdlValues = null;
    _searchParams.subSpeicalityDdlValues = null;
    _searchParams.categoryDescription = null;

    _memberKeyReq.searchParameters = _searchParams;
    let _routeUrl = '';
    if (this._appSession.isCareProvider) {
      _routeUrl = AppNavigations.CARE_TEAM_PATH;
    } else {
      _routeUrl = this._routeUtil.getResolvedUrl(this._route.snapshot);
    }
    _memberKeyReq.routeUrl = _routeUrl;

    this._memberKeySvc.getMemberKey(_memberKeyReq).then((resp: any) => {
      if (resp && resp.memberkey) {
        appointmentObj.appKey = resp.memberkey;
        if (resp.cacheKey) {
          appointmentObj.cacheKey = resp.cacheKey;
        }
        appointmentObj.origin = this.getOrigin(_routeUrl);
        eventDetail.object = appointmentObj;

        appointmentLoaderObj.loader = false;
        this.loader.emit(appointmentLoaderObj);

        this._pfAppCommandEvent.emit(eventDetail);
      } else {
        appointmentLoaderObj.loader = false;
        this.loader.emit(appointmentLoaderObj);
      }
    },
      (error: any) => {
        appointmentLoaderObj.loader = false;
        appointmentLoaderObj.apiError = true;
        this.loader.emit(appointmentLoaderObj);
      });
  }

  visibility() {
    const _isBookAppointmentAvailable = (this.isVisibile &&
      this.isMemberSecure &&
      !this._appSession.isEyc &&
      !this._appSession.isChangePCP &&
      this._facilityId && this._appSession.feature.showScheduleAppointment && this.customizedFeatures.showScheduleAppointment);

    return _isBookAppointmentAvailable;
  }

  updateAnalyticsTag() {
    if (this.pageName === 'results') {
      this.tagName = 'bookAppointmentProviderResults';
    } else if (this.pageName === 'careTeam') {
      if (this._apmtType === '1')
        this.tagName = 'bookApptCareTeamFindCare';
      else
        this.tagName = 'requestApptCareTeamFindCare';
    } else {
      this.tagName = 'bookAppointmentProviderDetails';
    }
  }

  get btnText(): string {
    switch (this._apmtType) {
      case '1':
        return this.content.summaryComponent.bookAppointment;
      case '2':
      case '3':
        return this.content.summaryComponent.requestAppointment;
    }
  }

  /**
   * Function to take actions based on event key
   * @param eventKey
   */
  private handleEvents(eventKey: string) {
    switch (eventKey) {
      case INFOBOX_EVENTS.BOOK_APPT:
        {
          this.appointmentSchedule();
          break;
        }
      default:
        break;
    }
  }

  /**
   * return page origin
   * @param routeUrl
   */
  private getOrigin(routeUrl: string) {
    switch (routeUrl) {
      case AppNavigations.CARE_TEAM_PATH:
        return ORIGIN.CARE_TEAM;
      case AppNavigations.CARE_CIRCLE_PATH:
          return ORIGIN.CARE_CIRCLE;
      case AppNavigations.SEARCH_RESULTS_PATH:
        return ORIGIN.SEARCH_RESULTS;
      case AppNavigations.PROVIDER_DETAILS_PATH:
        return ORIGIN.PROVIDER_DETAILS;
      default:
        return ORIGIN.SEARCH_RESULTS;
    }
  }
}
