import { AfterViewInit, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { ISearchParameters } from '../../../fad/search-providers/interfaces/iSearchParameters';
import { PROVIDER_SEARCH_DISTANCE } from '../../../fad/search-providers/values/providerSearchConstants';
import { AppEvents } from './../../../common/enums/appEvents';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-pf-cost-search-cmp',
  templateUrl: '../views/pfCostSearchContainerCmp.html',
  providers: []
})
export class PFCostSearchContainerComponent extends BaseComponent implements OnInit, AfterViewInit {
  primaryState: string = '';
  showSearchLoader: boolean = false;

  typesDdl: any = {
    title: 'Types',
    options: []
  };

  coordinates: any = {
    longitude: '',
    latitude: ''
  };

  searchParams: ISearchParameters = {
    stateCd: {
      code: this.primaryState
    },
    typeSelectNm: 'P',
    specialtySelectNm: [],
    coordinates: this.coordinates,
    acceptingNewPatients: false,
    ableToServePcp: false,
    medicaidInd: false,
    cooperativeCareOnly: false,
    sosOnly: false,
    bluePrecision: false,
    blueDistinct: '',
    blueDistinctPlus: false,
    enhancedPersonalCare: false,
    zip: '',
    genderCd: '',
    provNm: '',
    speicalityDdlValues: undefined,
    providerTypeDdlValues: undefined,
    subSpeicalityDdlValues: undefined,
    categoryDescription: {},
    plan: {},
    distanceNm: PROVIDER_SEARCH_DISTANCE,
    brandCode: '',
    typeOfCareNm: '',
    stateDdlValues: [],
    planDdlValues: [],
    memberDdlValues: this.typesDdl,
    blueDistinctionCenter: [],
    blueDistinctionType: '',
    blueDistinctionTypePlus: '',
    hasPersonalizedMatch: false,
    centerOfExcellence: false,
    providerOfDistinction: false,
    includePrimeGroupSearch: true,
    msp: false,
    valueBasedProvider: false
  };

  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);

  constructor(private _eventHandler: EventHandler,
              private _route: ActivatedRoute,
              private _appSession: AppSession,
              @Inject(ContentHelper)
              private _contentHelper: ContentHelper,
              private _appUtility: AppUtility) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFCostSearchContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.searchParams.plan = this._appSession.searchParams.plan;
    this.searchParams.memberDdlValues.options.values = this.typesDdl;
    if (this._appSession && this._appSession.metaData &&
        this._appSession.metaData.appContract && this._appSession.metaData.appContract.memberList) {
      let capitalizedWord = '';
      this._appSession.metaData.appContract.memberList.forEach((mem, index) => {
        capitalizedWord = mem.relationship && mem.relationship !== '' ?
          (mem.relationship.toLocaleLowerCase().charAt(0).toUpperCase() + mem.relationship.toLocaleLowerCase().slice(1)) : '';
        const objMembers = {
          label: mem.label,
          value: mem.label + '~' + mem.memberNumber,
          id: 'cB_Member_' + index,
          name: 'cB_Member_' + index,
          trueValue: mem.label,
          falseValue: '',
          isChecked: mem.isChecked,
          analytics: 'select' + capitalizedWord + 'EycSearch'
        };
        this.searchParams.memberDdlValues.options.push(objMembers);
      });
      this.searchParams.zip = this._appSession.searchParams.zip;
    }
  }

  ngAfterViewInit() {
    if (this.content.globalHeaderComponent &&
      this.content.globalHeaderComponent.pageHeader &&
      this.content.globalHeaderComponent.pageHeader.eycPageTitle) {
      const pageEycObj = {
        header: this.content.globalHeaderComponent.pageHeader.eycPageTitle,
        title: this.content.globalHeaderComponent.pageTitle.searchResults
      };
      this._headerEvent.emit(pageEycObj);
    }
    this._appUtility.scrollToTop();
  }
}
