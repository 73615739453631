import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AFFILIATION_GROUP, AFFILIATION_LABEL, AFFILIATION_NAME, DATE_FORMAT, ID } from '../../../common/constants/app-constants';
import { IAffiliationRadioInput } from '../../../common/interfaces/iPCPInfo';
import { ACTIVE_COVERAGE, BOTH_COVERAGES, FUTURE_COVERAGE } from '../../../common/interfaces/iPcpRequest';
import { DataHelper } from '../../../common/services/dataHelper';
import { PCPService } from '../../../common/services/pcpSvc';
import { PcpDetailsUtil } from '../../../common/utilities/pcpDetailsUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IPcpEligibleMemberContract } from '../models/iMemberPcpDetails';
import { MemberPcpDetailsApiHelper } from './memberPcpDetailsApiHlpr';
import { ProviderPcpInfoApiHelper } from './providerPcpInfoApiHlpr';

@Injectable()
export class ProviderPcpInfoV2ApiHelper extends ProviderPcpInfoApiHelper {

  constructor(_pcpSvc: PCPService,
    _mbrPcpDtlsApi: MemberPcpDetailsApiHelper,
    _appSession: AppSession,
    protected _dataHlpr: DataHelper,
    protected _datePipe: DatePipe,
    _contentHlpr: ContentHelper,
    protected _pcpUtil: PcpDetailsUtil) {
    super(
      _pcpSvc,
      _mbrPcpDtlsApi,
      _appSession,
      _dataHlpr,
      _datePipe,
      _contentHlpr,
      _pcpUtil
    );
  }

createAffliationRadioInputs(mbrPcpContracts: IPcpEligibleMemberContract[]) {
    //Reset Affiliation Radio Inputs arrays
    this._activePlanAfflns.length = 0;
    this._futurePlanAfflns.length = 0;
    this._bothPlansAfflns.length = 0;

    if (!mbrPcpContracts || !Array.isArray(mbrPcpContracts) || mbrPcpContracts.length === 0) {
      return;
    }
  const content = this.getContent();

    const activeCovAfflnsMap: { [key: string]: IAffiliationRadioInput } = {};//key: affiliationName or ProviderName
    const futureCovAfflnsMap: { [key: string]: IAffiliationRadioInput } = {};
    const bothCovAfflnsMap: { [key: string]: IAffiliationRadioInput } = {};

    let activeAfflnRadioCounter: number = 0;
    let futureAfflnRadioCounter: number = 0;
    let bothAfflnRadioCounter: number = 0;

    this.apiResp.pcpInfos.forEach((planPcp) => {
      const pcpInfoList = this._pcpUtil.groupByPcpId(planPcp.pcpInfoList, ID, [AFFILIATION_NAME, AFFILIATION_GROUP, AFFILIATION_LABEL], content.partof);
      if (!Array.isArray(pcpInfoList) || pcpInfoList.length === 0) {
        return;
      }
      const contract = mbrPcpContracts.find((c) => {
        return this._dataHlpr.areEqualStrings(c.contractUid, planPcp.contractUid);
      });
      if (!contract) {
        return;
      }
      let activeAfflnRadioInput;
      let futureAfflnRadioInput;
      let bothAfflnRadioInput;
      const contractUid = contract.contractUid;
      const plan = contract.plans[0];
      const isActive = this._dataHlpr.areEqualStrings(contract.contractStatus, ACTIVE_COVERAGE);
      const isFuture = this._dataHlpr.areEqualStrings(contract.contractStatus, FUTURE_COVERAGE);
      pcpInfoList.forEach((pcpInfo) => {
        let id = pcpInfo.id;
        const afflnKey = id.trim().toLowerCase();
         activeAfflnRadioInput = activeCovAfflnsMap[afflnKey];
         futureAfflnRadioInput = futureCovAfflnsMap[afflnKey];
         bothAfflnRadioInput = bothCovAfflnsMap[afflnKey];

        if (!activeAfflnRadioInput && isActive) {
          activeAfflnRadioInput = this.createAffiliation(afflnKey, content.lblPcpID +pcpInfo.id, ACTIVE_COVERAGE, activeAfflnRadioCounter);
          activeCovAfflnsMap[afflnKey] = activeAfflnRadioInput;
          this._activePlanAfflns.push(activeAfflnRadioInput);
          activeAfflnRadioCounter++;
        }

        if (!futureAfflnRadioInput && isFuture) {
          futureAfflnRadioInput = this.createAffiliation(afflnKey, content.lblPcpID +pcpInfo.id, FUTURE_COVERAGE, futureAfflnRadioCounter);
          futureCovAfflnsMap[afflnKey] = futureAfflnRadioInput;
          this._futurePlanAfflns.push(futureAfflnRadioInput);
          futureAfflnRadioCounter++;
        }

        if (!bothAfflnRadioInput) {
          bothAfflnRadioInput = this.createAffiliation(afflnKey, content.lblPcpID +pcpInfo.id, BOTH_COVERAGES, bothAfflnRadioCounter);
          bothCovAfflnsMap[afflnKey] = bothAfflnRadioInput;
          this._bothPlansAfflns.push(bothAfflnRadioInput);
          bothAfflnRadioCounter++;
          }
        const pcpId = pcpInfo.id;
        let pcpIdLbl = content.lblPcpID + pcpId;
        let planNameLbl = plan.planNm;
        const affiliations = pcpInfo.affiliations;
        if (isActive) {
          const contractSuffixLbl = content.activeStatus;
          //Create a affiliation Grouping entry for Active coverage
          activeAfflnRadioInput.activePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: ACTIVE_COVERAGE, showPlanName: false, affiliations});
          bothAfflnRadioInput.activePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: ACTIVE_COVERAGE, showPlanName: true, affiliations});
        }
        if (isFuture) {
          const startingDate = this._datePipe.transform(plan.planStartDate, DATE_FORMAT);
          const contractSuffixLbl = content.futureStatus.replace(/{DATE}/ig, startingDate);
          //Create a affiliation Grouping entry for Future coverage
          futureAfflnRadioInput.futurePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: FUTURE_COVERAGE, showPlanName: false, affiliations});
          bothAfflnRadioInput.futurePcpIds.push({ pcpId, pcpIdLbl, planNameLbl, contractSuffixLbl, plan, contractUid, multi_updates: FUTURE_COVERAGE, showPlanName: true, affiliations});
          }
        });
      // });
    });

    //Eliminate affiliations from bothPlansAfflns that are not having PCPIDs for ACTIVE and FUTURE contratcs.
    this._bothPlansAfflns = this._bothPlansAfflns.filter((affln) => {
      return affln.activePcpIds.length > 0 && affln.futurePcpIds.length > 0;
    });
  }
}
