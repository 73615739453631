import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { BaseComponent } from '../base-component/baseCmp';
import { ProviderUtilityService } from './../../../fad/search-providers/services/providerUtilitySvc';
import { ResultsUtil } from './../../../fad/utilities/resultsUtil';
import { PhonePatternPipe } from './../../pipes/phonePatternPipe';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { AddressLocationResponse, IAddressLocationRequest, IAffiliationOption } from './../control-list/interfaces/iAffiliationOption';

@Component({
  moduleId: module.id,
  selector: 'app-view-more-locations-cmp',
  templateUrl: './pfViewMoreLocationsCmp.html'
})
export class ViewMoreLocationsComponent extends BaseComponent implements OnInit {

  @ViewChild('viewMoreLocationsModal')
  viewMoreLocationsModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<any, any>;
  isApiError: boolean = false;
  showLocationsLoader: boolean = true;
  addressLocationResponse: AddressLocationResponse = {
    addressList : []
  };
  controlListItem: IAffiliationOption;
  resultsHeaderLine: string = '';

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerUtilityService: ProviderUtilityService,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _phonePatternPipe: PhonePatternPipe,
    private _titlecasePipe: TitleCasePipe,
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      'PFSearchResultsContainerComponent'
    );
 }
  /** Open Side Panel */
  openSidePanel(direction: any, controlListItem: IAffiliationOption, category: string) {
    this.controlListItem = controlListItem;
    const addressLocationReq: IAddressLocationRequest = {
      providerIdentifier: '',
      providerTypeCode: ''
    };
    if (controlListItem && controlListItem.addressSummary) {
      addressLocationReq.providerIdentifier = controlListItem.providerIdentifier;
      addressLocationReq.providerTypeCode = (category && category === 'affiliation') ? 'H' : 'G';
      addressLocationReq.numberOfAddresses = (controlListItem.addressSummary.numberOfAddresses) ? controlListItem.addressSummary.numberOfAddresses.toString() : '20';
      if (this._appSession && this._appSession.searchParams) {
        addressLocationReq.radius = (this._appSession.searchParams.distanceNm) ? parseInt(this._appSession.searchParams.distanceNm, 10) : 20;
        if (this._appSession.searchParams
          && this._appSession.searchParams.coordinates
          && this._appSession.searchParams.coordinates.latitude
          && this._appSession.searchParams.coordinates.latitude.toString() !== '0'
          && this._appSession.searchParams.coordinates.latitude.toString() !== ''
          && this._appSession.searchParams.coordinates.longitude
          && this._appSession.searchParams.coordinates.longitude.toString() !== '0'
          && this._appSession.searchParams.coordinates.longitude.toString() !== '') {
          addressLocationReq.latitude = this._appSession.searchParams.coordinates.latitude.toString();
          addressLocationReq.longitude = this._appSession.searchParams.coordinates.longitude.toString();
        }
        if (this._appSession.searchParams.countyCode) {
          addressLocationReq.countyCode = this._appSession.searchParams.countyCode;
        }
        this._providerUtilityService.getAffiliationLocationsList(addressLocationReq).then(
          (addressLocationResp: AddressLocationResponse) => {
            this.showLocationsLoader = false;
            if (addressLocationResp && addressLocationResp.addressList && addressLocationResp.addressList.length > 0) {
              this.resultsHeaderLine = this.content.summaryComponent.resultsHeader.summaryMsgLine1.
              replace(/{RDS}/gi, addressLocationReq.radius).
              replace(/{LOC}/gi, ResultsUtil.getLocationText(this._appSession)).
              replace(/{CNT}/gi, addressLocationResp.addressList.length).
              replace(/{MATCH}/gi, '');
              this.addressLocationResponse = addressLocationResp;
            }
          }, (error: any) => {
            this.showLocationsLoader = false;
            this.addressLocationResponse = {
              addressList : []
            };
            this.isApiError = true;
          });
      }
      this.sidePanelRef = this._sidePanel.open(direction, this.viewMoreLocationsModal);
    }
  }

  formatPhone(phone: string) {
    if (phone && phone !== '') {
      if (phone.indexOf('|') > -1) {
        phone = phone.replace(/\|/gi, '');
      }
      return this._phonePatternPipe.transform(phone);
    } else {
      return '';
    }
  }
  /** Event handler for click of close button  */
  onClose() {
    this.isApiError = false;
    this.showLocationsLoader = true;
    this.addressLocationResponse = {
      addressList : []
    };
    this.resultsHeaderLine = '';
    this.sidePanelRef.close();
  }
}
