import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
@Component({
  moduleId: module.id,
  selector: 'app-fad-provider-experience-cmp',
  templateUrl: './pfProviderExperienceCmp.html'
})
export class PFProviderExperienceComponent extends BaseComponent {
  experience: string;
  showTooltip: boolean;
  providerIndex: number;
  @Input()
  set providerExperience(value: string) {
    this.experience = value;
  }
  @Input()
  set showExpTooltip(value: boolean) {
    this.showTooltip = value;
  }
  @Input()
  set index(value: number) {
    this.providerIndex = value;
  }

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,

    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  getProviderExperience(): string {
    return this.content.summaryComponent.providerExperienceToolTip.replace(/{experience}/gi, this.experience);
  }
}
