import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppNavigations } from './../../constants/app-navigations';
import { AppEvents } from './../../enums/appEvents';
import { EventHandler } from './../../services/eventHandler';
import { NavigationService } from './../../services/navigationService';
import { AppUtility } from './../../utilities/appUtil';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { BaseComponent } from './../base-component/baseCmp';
@Component({
  selector: 'app-pf-error',
  templateUrl: './appErrorCmp.html'
})
export class AppErrorComponent extends BaseComponent implements OnInit {
  private _headerEvent: EventEmitter<any>
    = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _appUtility: AppUtility,
    private _navigationService: NavigationService
  ) {
    super( _route, _eventHandler, _appSession, _contentHelper, 'AppErrorComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.onPreLoad();
    }
  }

  ngAfterViewInit() {
    const pageObject = {
      header: this.content.errorComponent.pageHeader.errorHeader,
      title: this.content.errorComponent.pageTitle.errorTitle
    };

    this._headerEvent.emit(pageObject);

    // Scroll if content is displaying on page.
    if (this.isMemberSummaryError === true) {
      this._appUtility.scrollToTop();
    }
  }

  goToPlanSelection() {
    this._appUtility.resetAppToPublicState();
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
  }

  get isMemberSummaryError(): boolean {
    if (this._appSession.appEvent === AppEvents.MEMBER_SUMMARY_ERR) {
      return true;
    } else {
      return false;
    }
  }
}
