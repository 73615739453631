import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { CONTINUE, IEvent } from '../../interfaces/iEvent';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';
import { RIGHT } from './../../constants/app-constants';

@Component({
  moduleId: module.id,
  selector: 'app-ca-hmo-panel-cmp',
  templateUrl: './caHmoPanelCmp.html'
})
export class CaHmoPanelComponent extends BaseComponent {

  @ViewChild('SlideoutPanelTemplate') panelTmpltRef: TemplateRef<HTMLElement>;
  modalRef: ModalRef<HTMLElement> = null;
  @Output() continue: EventEmitter<IEvent> = new EventEmitter<IEvent>();

  constructor(route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession, _contentHlpr: ContentHelper,
    private _sidePanel: SidePanel) {
    super(route, eventHandler, appSession, _contentHlpr, 'CaHmoPanelComponent');
  }

  openSidePanel() {
    this.modalRef = this._sidePanel.open(RIGHT, this.panelTmpltRef);
  }

  onClose() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  onContinue() {
    this.continue.emit({ name: CONTINUE, value: this.continue });
    this.onClose();
  }
}
