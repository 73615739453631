import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '../../../../libs/uxd-shared/uxdSharedModule';
import { CommonDirectivesModule } from '../../directives/commonDirectivesModule';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { InfoListModule } from '../info-list/pfInfoListModule';
import { LookUpListModule } from '../lookup-list/pfLookUpListModule';
import { ViewHealthwiseComponent } from './pfViewHealthwiseCmp';
import { ViewHealthwiseComponent2 } from './pfViewHealthwiseCmp2';

@NgModule({
    declarations: [ViewHealthwiseComponent, ViewHealthwiseComponent2],
    exports: [ViewHealthwiseComponent, ViewHealthwiseComponent2],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule,
        LookUpListModule,
        InfoListModule,
        CommonDirectivesModule
    ],
    providers: []
})
export class HealthwiseModule { }
