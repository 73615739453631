export class StepChangeEvent {
  total: number;
  step: number;
  stepName: string;
}

export class StepAutoSkipEvent {
  step: number;
  stepName: string;
  goingForward?: boolean;
  goingBackward?: boolean;
}
