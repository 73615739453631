import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFReportInvalidComponent } from './components/pfReportInvalidCmp';
import { PFReportInvalidSidePanelComponent } from './components/pfReportInvalidSidePanelCmp';

@NgModule({
    declarations: [PFReportInvalidComponent, PFReportInvalidSidePanelComponent],
    exports: [PFReportInvalidComponent, PFReportInvalidSidePanelComponent],
    imports: [
        CommonModule,
        FormsModule,
        UxSharedModule,
    ],
    providers: []
})
export class ReportInvalidModule { }
