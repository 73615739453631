import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hoursMinutes' })
export class HoursMinutesPipe implements PipeTransform {
  transform(totalSeconds: number): string {
    if (!isNaN(Number(totalSeconds))) {
      const hours = Math.floor(totalSeconds / 3600);
      let minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      // rounding off seconds if seconds grater than or equal to 30
      minutes = (seconds >= 30) ? minutes + 1 : minutes;
      let result = `${minutes
        .toString()} min`;
      if (!!hours) {
        result = `${hours.toString()}hr ${minutes
          .toString()}min`;
      }
      return result;
    }
  }
}
