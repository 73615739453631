import { Injectable } from '@angular/core';
import { ISearchParamsRequest } from '../../../../app/common/interfaces/iSearchParamsReq';
import { ISearchParamsResponse } from '../../../../app/common/interfaces/iSearchParamsRes';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { apiNameList } from './../../../../environments/api-name-list';
import { BaseService } from './../../../common/services/baseService';

@Injectable()
export class CacheService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getCacheData(reqObj: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.cacheData,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  cacheSearchParams(reqObj: ISearchParamsRequest): Promise<ISearchParamsResponse> {
    const apiUrl = apiNameList.restApi.cacheSearchParams;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
