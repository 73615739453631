import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { COUNT, Locale, NETWORK_TYPECODE } from '../../../common/constants/app-constants';
import { HealthwiseSidePanelRequest } from '../../../common/interfaces/iCptMedicalResponse';
import { INoScore, IProvider, IRecognition, IReviewResponse } from '../../../common/interfaces/iSearchStrategyResponse';
import { DataService } from '../../../common/services/dataService';
import { EventHandler } from '../../../common/services/eventHandler';
import { NetworkProgramSvc } from '../../../common/services/networkProgramSvc';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IAddress } from '../../provider-details/models/iAddress';
import { ProviderDetailsNavigationService } from '../../provider-details/services/providerDetailsNavigationSvc';
import { CommonUtil } from '../../utilities/commonUtil';
import { NORATING } from '../values/providerSearchConstants';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';

@Component({
  selector: 'app-fad-search-result-recognition',
  templateUrl: '../views/pfSearchResultRecognitionCmp.html'
})
export class PFSearchResultRecognitionCmp extends BaseComponent {
  @Input()
  professional = {} as IProvider;
  @Input()
  idSuffix: number;
  @Output()
  openRecognition: EventEmitter<IRecognition> = new EventEmitter<IRecognition>();
  @Output()
  openPairRecognition: EventEmitter<IProvider> = new EventEmitter<IProvider>();
  @Output()
  openHealthWiseContentModal: EventEmitter<HealthwiseSidePanelRequest> = new EventEmitter<HealthwiseSidePanelRequest>();
  networkTypeConst = NETWORK_TYPECODE;
  showBeFirstToReview: boolean = true;
  showMoreLink: boolean;
  noScore = {} as INoScore;
  recognitionText: string;

  constructor(
    private _dataService: DataService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _networkProgramSvc: NetworkProgramSvc,
    private _appSession: AppSession,
    route: ActivatedRoute,
    eventHandler: EventHandler,
    contentHlpr: ContentHelper
  ) {
    super(route, eventHandler, _appSession, contentHlpr, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    this.showBeFirstToReview = CommonUtil.isMemberSecure(this._appSession);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  openRecognitionModal(recognition: IRecognition) {
    this.openRecognition.emit(recognition);
  }

  openPairRecognitionModal(professional: IProvider) {
    this.openPairRecognition.emit(professional);
  }

  getRecognitionText(professional: IProvider): string {
    const count = this._networkProgramSvc.getCount({ ...professional?.performance, tier: [] });
    this.recognitionText =
      count > 1
        ? this.content.recognitionSlideModalContent.recognitionsCount.replace(COUNT, count.toString())
        : this.content.recognitionSlideModalContent.recognitionCount.replace(COUNT, count.toString());
    return this.recognitionText;
  }

  getRecognitionAriaLabel(professional: IProvider): string {
    let ariaLabel: string = '';
    const count: string = professional ? this.getRecognitionText(professional) : '';
    if (count && professional && professional.providerName) {
      ariaLabel = this.getRecognitionText(professional) + ' ' + this.content.summaryComponent.forLabel + professional.providerName;
    }
    return ariaLabel && ariaLabel !== '' ? ariaLabel : count;
  }

  showmoreLinkVisibility(professional: IProvider) {
    if (professional && professional.reviewRatings && professional.reviewRatings.length > 1) {
      const _index = professional.reviewRatings.findIndex((review) => review.starRating && review.starRating.toString().toLowerCase() === NORATING.toLowerCase());
      if (_index > -1) {
        professional.reviewRatings.splice(_index, 1);
      }
      return professional.reviewRatings && professional.reviewRatings.length > 1;
    }
    return false;
  }

  isReviewRating(rating: IReviewResponse, provCategoryCode: string, providerTypeCodeList: Array<string>) {
    const isReview = CommonUtil.isReviewRating(rating, provCategoryCode, providerTypeCodeList);
    return isReview;
  }

  checkIndividualProvWithProvTypeP(provCategoryCode: string, providerTypeCodeList: Array<string>) {
    const checkInd = CommonUtil.checkIndividualProvWithProvTypeP(provCategoryCode, providerTypeCodeList);
    return checkInd;
  }

  vitalsSsoConfigValue(professional: IProvider) {
    const vitalsSsoConfig = this.WcsUtility.getVitalsSsoConfig(professional);
    return vitalsSsoConfig;
  }

  showRecognition(professional: IProvider): boolean {
    return this._networkProgramSvc.getCount({ ...professional?.performance, tier: [] }) > 0;
  }

  openHealthwiseSidePanel({ specialty, provider }: HealthwiseSidePanelRequest) {
    this.openHealthWiseContentModal.emit({ specialty, provider });
  }

  getMoreRating(event: Event) {
    this.showMoreLink = !this.showMoreLink;
    event.preventDefault();
  }

  showProviderDetails(professional: IProvider) {
    let address = {} as IAddress;
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    if (professional.location.address) {
      address = {
        addressId: professional.location.address.addressId,
        city: professional.location.address.city,
        state: professional.location.address.state ?? '',
        postalCode: professional.location.address.postalCode,
        latitude: professional.location.address.latitude,
        longitude: professional.location.address.longitude
      };
    }
    const selectedProvider = {
      providerIdentifier: professional.providerIdentifier,
      providerTypeCodeList: professional.providerTypeCodeList,
      providerName: professional.providerName,
      networkRegisteredName: professional.networkRegisteredName,
      titleList: professional.titleList,
      location: {
        address: address
      }
    };
    this._dataService.setPageTitle(pageFadObj);
    this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
  }

  getVisionServicesAvailable(visionServiceList: IBaseCode[]) {
    return this.WcsUtility.getFormattedVisionServices(visionServiceList);
  }

  showNoScore(noScore: INoScore): boolean {
    if (noScore) {
      if (this._appSession.metaData.locale === Locale.ENGLISH) {
        this.noScore.description = noScore.description;
        this.noScore.hoverText = this.WcsUtility.decodeHTML(this.WcsUtility.decodeHTML(noScore.hoverText));
      } else {
        this.noScore.description = this.content.messages.noRanking.noRankingDesc;
        this.noScore.hoverText = this.content.messages.noRanking.noRankingHoverText;
      }
      return true;
    }

    return false;
  }

  showReviewLink(totalReviews: string) {
    return CommonUtil.showReviewLink(totalReviews);
  }
}
