import { IPCPInfo } from '../../common/interfaces/iPCPInfo';
import { IProviderNetworkStatus } from '../../fad/provider-details/models/iDetailsResponse';
import { IUsedContract } from '../interfaces/iUsedContract';
import { IBaseCode } from './../../common/interfaces/iBaseCode';
import { ISpecialty } from './../../fad/search-results/interfaces/iSummaryResp';
import { IAddress } from './iAddress';

export interface ICareProvider {
  id?: string;
  providerId?: string;
  providerTypes?: Array<string>;
  enableDetailLink?: boolean;
  firstNm?: string;
  lastNm?: string;
  fullNm?: string;
  nickName?: string;
  address?: IAddress;
  phone?: string;
  specialties?: IBaseCode[];
  assignedMemberList?: Array<any>;
  lastVisitDate?: string;
  modifiedNickname?: string;
  editNickname?: boolean;
  isRemoved?: boolean;
  isPCP?: boolean;
  isFuturePCP?: boolean;
  showRemovePCP?: boolean;
  showAssignPCP?: boolean;
  pcpList?: string;
  pcpInfoList?: Array<IPCPInfo>;
  appointmentType?: string;
  appointmentAvailable?: boolean;
  npiList?: Array<string>;
  mbrPcpEffectiveDt?: string;
  futureEffectiveDateTooltip?: string;
  labels?: Array<string>;
  healthSystem?: IHealthSystem;
  isVirtualProvider?: boolean;
  callToAction?: IVirtualProviderConfig;
  anp?: IBaseCode;
  providerCategoryCode?: IBaseCode;
  specialtyNames?: string;
  isShipDirectToDoor?: boolean;
  titleList?: Array<string>;
  networkTypeCodes?: Array<string>;
}

export interface IHealthSystem {
  hseFounderName: string;
  hsePhNo: string;
  hsePrimeCode: Array<string>;
  hseLinks: ILinks;
  hseDisclaimer: string;
  effectiveDate: string;
  termDate: string;
  hseLogo: IhseLogo;
  showVirtualCareSection?: boolean;
  showNurseLineLink?: boolean;
}

export interface IhseLogo {
  name: string;
}

export interface ILinks {
  hseSymptomChecker: string;
  externalLinks: IExternalLinks;
  sydneyHealthLinks: ISydneyHealthLinks;
}

export interface IExternalLinks {
  eVisitLink: string;
  urgentCareLink: string;
  hsePatientPortalLink: string;
  hseNurseLine: string;
}

export interface ISydneyHealthLinks {
  faqLink: string;
  hseAnthemNurseLine: string;
}

export interface IProvider {
  providerId?: string;
  nickName?: string;
  isRemoved?: boolean;
  providerDisplayNm?: string;
  providerFirstNm?: string;
  providerMiddleNm?: string;
  providerLastNm?: string;
  nationalProviderId?: Array<string>;
  address?: IAddress;
  specialtyCd?: Array<IBaseCode>;
  usedContracts?: Array<IUsedContract>;
  source?: string;
  providerTypeCodes?: Array<IBaseCode>;
  labels?: Array<string>;
}

export interface IProviderRequest {
  careTeam?: Array<IProvider>;
  mbrUid?: string;
  contractUid?: string;
}

export interface ICareTeamRequest {
  careTeam?: IProvider;
  mbrUid?: string;
  contractUid?: string;
}

export interface ICareProviderRequest {
  type?: string;
  mbrUid?: string;
  contractUid?: string;
  locale?: string;
  brandCd?: string;
  isFuturePlan: boolean;
}

export interface ICareTeamEmailRequest {
  careTeamRequest?: ICareProviderRequest;
  brandCode?: string;
  emailId?: string;
}

export class CareTeamResponse {
  hasPCPFeature?: boolean;
  hasPCPRemoveFeature?: boolean;
  isPCPReadOnly?: boolean;
  mbrUid?: string;
  careTeam?: CareTeamProvider[];
}

export class CareTeamProvider {
  provider?: ICareProvider;
  providerTypes?: Array<string>;
  npiList?: Array<string>;
  taxIdList?: Array<string>;
  specialties?: Array<IBaseCode>;
  lastVisitDate?: string;
  appointmentType?: string;
  appointmentAvailable?: boolean;
  isRemoved?: boolean;
  isPCP?: boolean;
  isFuturePCP?: boolean;
  mbrPcpEffectiveDt?: string;
  pcpId?: string;
  pcpList?: Array<string>;
  pcpInfoList?: Array<IPCPInfo>;
  showAssignPCP?: boolean;
  networkStatus?: string;
  specialityCodeList?: Array<string>;
  specialityCategoryList?: Array<string>;
  isShipDirectToDoor?: boolean;
  networkTypeCodes?: Array<string>;
}

export class ICareProviderDetails {
  providerIdentifier?: string;
  providerName?: string;
  providerTypeCodeList?: Array<string>;
  pcpList?: string;
  nickName?: string;
  location?: ILocation;
  labels?: Array<string>;
  id?: string;
  anp?: IBaseCode;
  providerCategoryCode?: IBaseCode;
  specialties: ISpecialty[];
  providerNetworkStatus?: Pick<IProviderNetworkStatus, 'typeCode'>;
}

export class ILocation {
  address?: IAddress;
}

export class IPCPProvider {
  selectedProvider: ICareProviderDetails;
}

export interface IVirtualProviderConfig {
  internalRedirectId?: string;
  ssoKey?: string;
  virtualCareUrl?: string;
}
