import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalRef } from "@anthem/uxd/modal";
import { EventHandler } from "../../../../../../../common/services/eventHandler";
import { AppSession } from "../../../../../../../common/values/appSession";
import { BaseComponent } from '../../../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: "app-fc-fts-moreExamples-cmp",
  templateUrl: "./ftsMoreExamplesCmp.html",
})
export class FtsMoreExamplesComponent extends BaseComponent {
  sidePanelRef: ModalRef<HTMLElement>;
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  onClose() {
    this.closeModal.emit();
  }
}
