import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../common/components/base-component/baseCmp';
import { RIGHT } from '../../common/constants/app-constants';
import { IFinalProvider } from '../../common/interfaces/iCommonSearchResponse';
import { DataHelper } from '../../common/services/dataHelper';
import { EventHandler } from '../../common/services/eventHandler';
import { SliderService } from '../../common/services/sliderSvc';
import { AppSession } from '../../common/values/appSession';
import { ContentHelper } from '../../common/values/contentHelper';
import { ACCOUNT_TYPE } from '../../fad/search-providers/values/providerSearchConstants';
import { ICostInfo } from '../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from '../../fad/utilities/commonUtil';

@Component({
  moduleId: module.id,
  selector: 'app-cost-slide-out-cmp',
  templateUrl: './costSlideOutCmp.html'
})
export class CostSlideOutComponent extends BaseComponent implements OnDestroy {
  @ViewChild('viewCostSlider')
  viewCostSlider: TemplateRef<HTMLElement>;
  @Input()
  index: number;
  @Input()
  eycCostInfo: ICostInfo;
  @Input()
  professional: IFinalProvider;
  sidePanelRef: ModalRef<HTMLElement>;
  showSpendingAccountDisclaimer = false;
  showAccountDisclaimer = false;
  @Input()
  showCostOnSubscription = false;

  private _sliderSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _dataHelper: DataHelper,
    private _currencyPipe: CurrencyPipe,
    private _sliderService: SliderService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit(): void {
    if (this.showCostOnSubscription) {
      this._sliderSubscription = this._sliderService.costSliderObs.subscribe((provider) => {
        this.openSlider();
      });
    }
  }

  ngOnDestroy(): void {
    if (this._sliderSubscription) {
      this._sliderSubscription.unsubscribe();
    }
  }

  openSlider(): void {
    this.showSpendingAccountDisclaimer = this.eycCostInfo?.isVisibleCDHPError && this._dataHelper.areEqualStrings(this.eycCostInfo?.cdhpErrorText, 'CDHPTEXT');
    this.showAccountDisclaimer = this.eycCostInfo?.isVisibleCDHPError && this._dataHelper.areEqualStrings(this.eycCostInfo?.cdhpErrorText, 'CDHPERROR');
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.viewCostSlider);
  }

  closeSlider(): void {
    this.showSpendingAccountDisclaimer = false;
    this.showAccountDisclaimer = false;
    this.sidePanelRef?.close();
  }

  get costCdhpPaysText(): string {
    return this.content.eyc.costCDHPPaysText.replace(/{ACCOUNTTYPE}/gi, this.eycCostInfo.accntType);
  }

  get copay(): string {
    return CommonUtil.getCopayWithPeriod(this.eycCostInfo);
  }
  get isDentalSearch(): boolean {
    return CommonUtil.isDentalSearch(this._appSession?.hasDentalEYC, this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode);
  }

  get showWhatYouPay(): boolean {
    return this.eycCostInfo?.isVisibleTotalYouPay && this.eycCostInfo?.showOutOfPocketCost;
  }

  get commonCostDetailCondition(): boolean {
    return this.showWhatYouPay && !this.eycCostInfo.isBenefitDenied && (this.eycCostInfo.isVisibleCostPopup || this.eycCostInfo.isVisibleCostBeforeCDHP);
  }

  get coverageLinkText(): string {
    return this.content.costDetailSlideModalContent.headerText2.replace(/{{COVERAGE}}/gi, this.isDentalSearch ? 'den' : 'med');
  }

  get accountPaysDesc(): string {
    if (this._dataHelper.areEqualStrings(this.eycCostInfo.accntType, ACCOUNT_TYPE.HRA)) {
      return this.content.costDetailSlideModalContent.hraPaysDescription;
    } else if (this._dataHelper.areEqualStrings(this.eycCostInfo.accntType, ACCOUNT_TYPE.HSA)) {
      return this.content.costDetailSlideModalContent.hsaPaysDescription;
    }
    return '';
  }

  get showAccountPays(): boolean {
    return this.eycCostInfo?.isVisibleCDHPPays && !this.eycCostInfo?.isVisibleOOPENotavailableMessage;
  }

  get showBenefitDeniedDisclaimer(): boolean {
    return !this.eycCostInfo?.isRangeVisible && this.eycCostInfo?.isBenefitDenied;
  }

  get costText(): string {
    if (this.showWhatYouPay && !this.eycCostInfo.isRangeVisible && typeof this.eycCostInfo.totalYouPay === 'number' && !isNaN(this.eycCostInfo.totalYouPay)) {
      return this._currencyPipe.transform(this.eycCostInfo.totalYouPay, 'USD', 'symbol', '1.0');
    } else if (this.eycCostInfo?.isRangeVisible && this.eycCostInfo.isVisibleTotalAverageCost && this.eycCostInfo.isOOPERange && !this._dataHelper.isEmptyString(this.eycCostInfo.totalAverageCost)) {
      return this.eycCostInfo.totalAverageCost;
    } else {
      return this.content.costDetailSlideModalContent.costDetails;
    }
  }

  get cdhpError(): string {
    return this.content.costDetailSlideModalContent.cdhpError.replace(/{{ACCOUNTTYPE}}/gi, this.eycCostInfo.accntType);
  }

  dismissDisclaimer(): void {
    this.showSpendingAccountDisclaimer = false;
  }

  dismissAccountDisclaimer(): void {
    this.showAccountDisclaimer = false;
  }
}
