import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppEvents } from '../../../common/enums/appEvents';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { IPageTitle } from '../../common/interfaces/iPageTitle';
@Component({
  moduleId: module.id,
  selector: 'app-fc-landing-cmp',
  templateUrl: './landingCmp.html'
})
export class LandingComponent extends BaseComponent implements OnInit {
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.onPreLoad();
    this.setPageHeader();
  }

  setPageHeader() {
    if (this.content && this.content.common && this.content.common.pageHeader && this.content.common.pageTitle) {
      const pageTitleObj: IPageTitle = {
        header: this.content.common.pageHeader.fadPageTitle,
        title: this.content.common.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageTitleObj);
      this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]).emit(this._appSession.metaData.brandCd);
    }
  }
}
