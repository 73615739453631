import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CustomPipesModule } from '../../../../common/pipes/customPipesModule';
import { TrimText } from '../../../../common/pipes/trimText';
import { DisclaimerSvc } from '../../services/disclaimerSvc';
import { CostDisclaimersContainerCmp } from './costDisclaimersContainer/costDisclaimersContainerCmp';
import { CostDisclaimersTabCmp } from './costDisclaimersTab/costDisclaimersTabCmp';
import { DisclaimerCmp } from './genericDisclaimers/genericDisclaimersCmp';

@NgModule({
  declarations: [DisclaimerCmp,CostDisclaimersTabCmp,CostDisclaimersContainerCmp],
  imports: [
    CommonModule,UxSharedModule.forRoot(),CustomPipesModule],
  exports: [DisclaimerCmp,CostDisclaimersContainerCmp],
  providers: [DisclaimerSvc,TrimText]
})
export class DisclaimersModule {}
