// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: {
    public: 'https://fadpublic.perf1.awse1.anthem.com',
    secure: 'https://fadpublic.perf1.awse1.anthem.com'
  },
  web: {
    idleTimeLength: 780,
    sessionTimeoutLength: 120
  },
  sso: {
    baseUrl: '/member'
  },
  wcsApi: {
    baseUrl: '/assets/wcs/',
    baseDocumentURL: '/assets/documents/',
    enableLocalContent: false,
    contentPath: {
      en_US: 'fad_{ROUTE-PATH}',
      es_US: 'fad_{ROUTE-PATH}_es'
    }
  },
  bingMaps: {
    baseUrl: 'https://dev.virtualearth.net/REST/v1/',
    apiKey: 'AnUE73t9h-Fg9gM2QdN9JrzlRvlQlI2zaEDviBrZB64gXL77GUpxIM6Nbj78q6TG',
    locations: 'Locations',
    scriptUrl: 'https://www.bing.com/api/maps/mapcontrol?callback=__onBingLoaded&branch=experimental',
    icons: {
      activePin: 'https://findcare.anthem.com/assets/images/common/dark-pin.png',
      inactivePin: 'https://findcare.anthem.com/assets/images/common/light-pin.png'
    }
  },
  recaptcha: {
    baseUrl: 'https://www.google.com/recaptcha/api.js',
    siteKey: '6LdgNyoUAAAAALsZnMdYvRXWFFaIeNi0trikjq4G'
  },
  providerFinder: {
    baseUrl: {
      public: {
        abc: 'https://fadpublic.perf1.awse1.anthem.com',
        abcbs: 'https://fadpublic.perf1.awse1.anthem.com',
        amv: 'https://fadpublic.perf1.awse1.amerigroup.com',
        shc: 'https://fadpublic.perf1.awse1.simplyhealthcareplans.com',
        unicare: 'https://fadpublic.perf1.awse1.unicare.com',
        ebc: 'https://fadpublic.perf1.awse1.empireblue.com',
        ebcbs: 'https://fadpublic.perf1.awse1.empireblue.com',
        healthblue: 'https://fadpublic.perf1.awse1.healthybluela.com',
        hbnc: 'https://fadpublic.perf1.awse1.bcbsdirect.com',
        bluema: 'https://fadpublic.perf1.awse1.bluemedadv.com',
        hbmo: 'https://fadpublic.perf1.awse1.healthybluemo.com',
        hbncm: 'https://fadpublic.perf1.awse1.healthybluenc.com',
        bcbswn: 'https://fadpublic.perf1.awse1.mybcbswny.com',
        wlp: 'https://fadpublic.perf1.awse1.wellpoint.com',
        abcny: 'https://fadpublic.perf1.awse1.anthembluecross.com',
      },
      secure: {
        abc: 'https://fadsecure.perf1.awse1.anthem.com',
        abcbs: 'https://fadsecure.perf1.awse1.anthem.com',
        amv: 'https://fadsecure.perf1.awse1.amerigroup.com',
        shc: 'https://fadsecure.perf1.awse1.simplyhealthcareplans.com',
        unicare: 'https://fadsecure.perf1.awse1.unicare.com',
        ebc: 'https://fadsecure.perf1.awse1.empireblue.com',
        ebcbs: 'https://fadsecure.perf1.awse1.empireblue.com',
        healthblue: 'https://fadsecure.perf1.awse1.healthybluela.com',
        hbnc: 'https://fadsecure.perf1.awse1.bcbsdirect.com',
        bluema: 'https://fadsecure.perf1.awse1.bluemedadv.com',
        hbmo: 'https://fadsecure.perf1.awse1.healthybluemo.com',
        hbncm: 'https://fadsecure.perf1.awse1.healthybluenc.com',
        bcbswn: 'https://fadsecure.perf1.awse1.mybcbswny.com',
        wlp: 'https://fadsecure.perf1.awse1.wellpoint.com',
        AGP: 'https://fadsecure.perf1.awse1.amerigroup.com',
        SMPLY: 'https://fadsecure.perf1.awse1.simplyhealthcareplans.com',
        WNY: 'https://fadsecure.perf1.awse1.anthem.com',
        CLEAR: 'https://fadsecure.perf1.awse1.simplyhealthcareplans.com',
        hbl: 'https://fadsecure.perf1.awse1.healthybluela.com'
      }
    },
    vitalsBase: 'https://wellpoint-uat.prs.mdxdata.com/reviews/Doctor/',
    authToken: true
  },
  images: {
    qualityBaseUrl: '/assets/images/qualitydesignation/',
    commonBaseUrl: '/assets/images/common/',
    brandBaseUrl: '/assets/images/brands/',
    semBaseUrl: 'https://membersecure.perf1.awse1.anthem.com/semassets/'
  },
  cvsPharmacy: {
    ABC: 'https://membersecure.perf1.awse1.anthem.com/member/pharmacy/find-pharmacy',
    ABCBS: 'https://membersecure.perf1.awse1.anthem.com/member/pharmacy/find-pharmacy',
    EBCBS: 'https://membersecure.perf1.awse1.empireblue.com/member/pharmacy/find-pharmacy',
    EBC: 'https://membersecure.perf1.awse1.empireblue.com/member/pharmacy/find-pharmacy'
  },
  esiPharmacy: {
    ABC: 'https://membersecure.perf1.awse1.anthem.com/member/pharmacy',
    ABCBS: 'https://membersecure.perf1.awse1.anthem.com/member/pharmacy',
    EBCBS: 'https://membersecure.perf1.awse1.anthem.com/member/pharmacy',
    EBC: 'https://membersecure.perf1.awse1.anthem.com/member/pharmacy'
  },
  paginationSize: {
    summaryPrintPage: 100,
    summaryPage: 20,
    hospitalAffiliationPage: 2000,
    groupAffiliationPage: 2000
  },
  chineseMotionPointUrl: {
    ABC: 'https://zh.cn.anthem.com/ca/find-doctor/',
    EBCBS: 'https://zh.cn.empireblue.com/find-doctor/',
    EBC: 'https://zh.cn.empireblue.com/find-doctor/'
  },
  features: {
    lookUp: {
      inputLength: 3,
      inputMaxLength: 150,
      minCount: 5
    },
    freeTextSearch: {
      descriptionMaxLength: 100
    },
    pillFilter: {
      minCount: 6,
      deselectionDefaultCount: 5,
      accordionDefaultExpand: 3
    }
  },
  login: {
    en_US: {
      brand: {
        abc: 'https://consumerpublic.perf1.va.anthem.com/ca/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        abcbs: 'https://consumerpublic.perf1.va.anthem.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        amv: 'https://consumerpublic.perf1.va.myhealth.amerigroup.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        shc: 'https://consumerpublic.perf1.va.medicare.simplyhealthcareplans.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        unicare: 'https://consumerpublic.perf1.awse1.unicare.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebc: 'https://consumerpublic.perf1.va.empireblue.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebcbs: 'https://consumerpublic.perf1.va.empireblue.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        healthblue: 'https://consumerpublic.perf1.va.anthem.com/healthybluela/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        unimass: 'https://consumerpublic.perf1.awse1.unicare.com/mass/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        gw: 'https://consumerpublic.perf1.awse1.unicare.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        wlp: 'https://consumerpublic.perf1.awse1.anthem.com/account-login/?dplid=sso.dpl.providerdirectory.search-criteria',
        bluema: 'https://www.bluemedadvgrhs.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        anthembluecross: 'https://consumerpublic.perf1.awse1.anthembluecross.com/login/?dplid=sso.dpl.providerdirectory.search-criteria'
      }
    },
    es_US: {
      brand: {
        abc: 'https://consumerpublic.perf1.va.anthem.com/ca/login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        abcbs: 'https://consumerpublic.perf1.va.anthem.com/login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        amv: 'https://consumerpublic.perf1.va.myhealth.amerigroup.com/login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        shc: 'https://consumerpublic.perf1.va.medicare.simplyhealthcareplans.com/login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        unicare: 'https://consumerpublic.perf1.awse1.unicare.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebc: 'https://consumerpublic.perf1.va.empireblue.com/login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebcbs: 'https://consumerpublic.perf1.va.empireblue.com/login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        healthblue: 'https://consumerpublic.perf1.va.anthem.com/healthybluela/login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        unimass: 'https://consumerpublic.perf1.awse1.unicare.com/mass/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        gw: 'https://consumerpublic.perf1.awse1.unicare.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        wlp: 'https://consumerpublic.perf1.awse1.anthem.com/account-login/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        bluema: 'https://www.bluemedadvgrhs.com/login/?dplid=sso.dpl.providerdirectory.search-criteria',
        anthembluecross: 'https://consumerpublic.perf1.awse1.anthembluecross.com/login/?dplid=sso.dpl.providerdirectory.search-criteria'
      }
    }
  },
  register: {
    en_US: {
      brand: {
        abc: 'https://consumerpublic.perf1.va.anthem.com/ca/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        abcbs: 'https://consumerpublic.perf1.va.anthem.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        amv: 'https://consumerpublic.perf1.va.myhealth.amerigroup.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        shc: 'https://consumerpublic.perf1.va.medicare.simplyhealthcareplans.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        unicare: 'https://consumerpublic.perf1.awse1.unicare.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebc: 'https://consumerpublic.perf1.va.empireblue.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebcbs: 'https://consumerpublic.perf1.va.empireblue.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        healthblue: 'https://consumerpublic.perf1.va.anthem.com/healthybluela/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        unimass: 'https://consumerpublic.perf1.awse1.unicare.com/mass/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        gw: 'https://consumerpublic.perf1.awse1.unicare.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        wlp: 'https://member.wellpoint.com/public/registration/home',
        bluema: 'https://www.bluemedadvgrhs.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        anthembluecross: 'https://consumerpublic.perf1.awse1.anthembluecross.com/register/?dplid=sso.dpl.providerdirectory.search-criteria'
      }
    },
    es_US: {
      brand: {
        abc: 'https://consumerpublic.perf1.va.anthem.com/ca/register/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        abcbs: 'https://consumerpublic.perf1.va.anthem.com/register/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        amv: 'https://consumerpublic.perf1.va.myhealth.amerigroup.com/register/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        shc: 'https://consumerpublic.perf1.va.medicare.simplyhealthcareplans.com/register/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        unicare: 'https://consumerpublic.perf1.awse1.unicare.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebc: 'https://consumerpublic.perf1.va.empireblue.com/register/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        ebcbs: 'https://consumerpublic.perf1.va.empireblue.com/register/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        healthblue: 'https://consumerpublic.perf1.va.anthem.com/healthybluela/register/es/?dplid=sso.dpl.providerdirectory.search-criteria',
        unimass: 'https://consumerpublic.perf1.awse1.unicarecom/mass/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        gw: 'https://consumerpublic.perf1.awse1.unicare.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        wlp: 'https://member.wellpoint.com/public/registration/home',
        bluema: 'https://www.bluemedadvgrhs.com/register/?dplid=sso.dpl.providerdirectory.search-criteria',
        anthembluecross: 'https://consumerpublic.perf1.awse1.anthembluecross.com/register/?dplid=sso.dpl.providerdirectory.search-criteria'
      }
    }
  },
  MedicaidState: {
    en_US: {
      state: {
        CA: 'https://mss.anthem.com/california-medicaid/care/find-a-doctor.html',
        IN: 'https://mss.anthem.com/in/care/find-a-doctor.html',
        KY: 'https://mss.anthem.com/ky/care/find-a-doctor.html',
        NV: 'https://mss.anthem.com/nevada-medicaid/care/find-a-doctor.html',
        VA: 'https://mss.anthem.com/va/care/find-a-doctor.html',
        WI: 'https://mss.anthem.com/wi/care/find-a-doctor.html',
        CAMMP: 'https://mss.anthem.com/california-mmp/care/find-a-doctor.html',
        NY: 'https://mss.empireblue.com/ny/care/find-a-doctor.html'
      }
    },
    es_US: {
      state: {
        CA: 'https://es-mss.anthem.com/california-medicaid/care/find-a-doctor.html',
        IN: 'https://es-mss.anthem.com/in/care/find-a-doctor.html',
        KY: 'https://es-mss.anthem.com/ky/care/find-a-doctor.html',
        NV: 'https://es-mss.anthem.com/nevada-medicaid/care/find-a-doctor.html',
        VA: 'https://es-mss.anthem.com/va/care/find-a-doctor.html',
        WI: 'https://es-mss.anthem.com/wi/care/find-a-doctor.html',
        CAMMP: 'https://es-mss.anthem.com/california-mmp/care/find-a-doctor.html',
        NY: 'https://es-mss.empireblue.com/ny/care/find-a-doctor.html'
      }
    }
  },
  datadog: {
    rumConfiguration: {
      applicationId: "99a5f20d-d193-4a35-9a49-7e73481126da",
      clientToken: "pub07916788abb7120892d71678d30607e3",
      env: 'local',
      silentMultipleInit: true,
      site: "datadoghq.com",
      service: "sydney-precare",
      sessionReplaySampleRate: 5,
      sessionSampleRate: 10,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
    },
    enabled: false,
  },
  lookupResultSortOrder :['specialtyList', 'providerList', 'procedureList', 'providerLicenseList','aoeList', 'ofcSrvcList', 'providerNpiList', 'cptList'],
  environment: 'local'
};
