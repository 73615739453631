import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IOptions } from '../../../../common/interfaces/iAppMetadata';

@Injectable({ providedIn: 'root' })
export class GeoLocationService {
  private _geoLocation = new Subject<any>();

  private _reloadSAVPage = new Subject<boolean>();
  private _reloadSearch = new Subject<boolean>();
  private _loadResult = new Subject<boolean>();
  private _closeModel = new Subject<boolean>();
  private _memberChangeEvent = new Subject<IOptions>();
  private _currentLocationEvent = new Subject<void>();

  //geoLocation
  getGeoLocation(): Observable<any> {
    return this._geoLocation.asObservable();
  }

  //geoLocation
  setLocation(data: any): void {
    if (!isEmpty(data)) {
      this._geoLocation.next(data);
    }
  }

  //reloadSAVPage
  reloadSAVPage(data: any) {
    this._reloadSAVPage.next(data);
  }

  getReloadSAVPageObservable(): Observable<any> {
    return this._reloadSAVPage.asObservable();
  }

  //reloadSearch
  reloadSearch(data: any) {
    this._reloadSearch.next(data);
  }

  getReloadSearchObservable(): Observable<any>  {
    return this._reloadSearch.asObservable();
  }

  //close model
  closeModel() {
    this._closeModel.next(true);
  }

  getCloseModelObservable(): Observable<any>  {
    return this._closeModel.asObservable();
  }

  // loadResult
  loadResult(data: any) {
    this._loadResult.next(data);
  }
  getLoadResultObservable(): Observable<any> {
   return this._loadResult.asObservable();
  }

  //change member event
  getMemberChangeEvent(): Observable<any> {
    return this._memberChangeEvent.asObservable();
  }

  setMemberChangeEvent(member: IOptions) {
    this._memberChangeEvent.next(member);
  }
  //current location event
  getCurrentLocationChangeEvent(): Observable<any> {
    return this._currentLocationEvent.asObservable();
  }

  setCurrentLocationEvent() {
    this._currentLocationEvent.next();
  }
}
