import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { FilterSidePanelComponent } from '../filterSidePanel/filterSidePanelCmp';
import { SliderDirection } from './../../../common/constants/common';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-header-cmp',
  templateUrl: './filterHeaderCmp.html'
})
export class FilterHeaderComponent extends BaseComponent implements OnInit {
  filterContent = this.content?.result?.filter;

  @ViewChild('searchResultsFilterModal')
  searchResultsFilterModal: FilterSidePanelComponent;

  ngOnInit(): void {}

  openFilterPanel() {
    this.searchResultsFilterModal.openSidePanel(SliderDirection.RIGHT);
  }
}
