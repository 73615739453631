import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper } from '@anthem/uxd/alert';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { BaseComponent } from '../../common/components/base-component/baseCmp';
import { AppExternalCommands, LHO } from '../../common/constants/app-constants';
import { AppEvents } from '../../common/enums/appEvents';
import { IEventDetail } from '../../common/interfaces/iEventDetail';
import { IVirtualVisitSSO } from '../../common/interfaces/iMessage';
import { IWindow } from '../../common/interfaces/iWindow';
import { EventHandler } from '../../common/services/eventHandler';
import { NavigationService } from '../../common/services/navigationService';
import { AppSession } from '../../common/values/appSession';
import { ContentHelper } from '../../common/values/contentHelper';
import { VpcMagicLinkSvc } from "../../fad/search-providers/services/vpcMagicLinkSvc";
import { AppUtility } from "./../../common/utilities/appUtil";

@Component({
  moduleId: module.id,
  selector: 'app-virtual-care-landing-cmp',
  templateUrl: '../views/pfVirtualCareLandingCmp.html',
  providers: []
})

export class PFVirtualCareLandingComponent extends BaseComponent implements OnInit {
  locale: string;
  ssoConfig: IVirtualVisitSSO;
  @ViewChild('kHealthModal')
  kHealthModal: ModalComponent;
  isConsentAccepted: boolean;
  kHealthCb = {
    id: 'kHealthConsentCB',
    name: 'kHealthConsentCB',
    label: '',
    trueValue: true,
    falseValue: false
  };
  constructor(
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _navigationService: NavigationService,
    private _vpcMagicLinkService: VpcMagicLinkSvc,
    private _alertHlpr: AlertHelper,
    private _appUtility: AppUtility
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'VirtualCareContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.onPreLoad();
    }
    this._appUtility.scrollToTop();
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  onError(type: string, error: any) {
    throw error;
  }

  hasTeleHealth(): boolean {
    return this._appSession.isSecureState && this._appSession.hasTelehealth;
  }

  hasVirtualCare() {
    return this._appSession.isSecureState && this._appSession.hasVirtualUrgentCare;
  }

  virtualVisitSsoConfigValue(careType: string): IVirtualVisitSSO {
    if (this._appSession.isKHealthModalOpen) {
      this.kHealthModal.hide();
      this._appSession.isKHealthModalOpen = false;
    }
    this.ssoConfig = this.WcsUtility.getVirtualVisitSsoConfig(careType);
    return this.ssoConfig;
  }

  navigateToFindCare() {
    const appCmdEvt: EventEmitter<IEventDetail> = this._eventHandler.get(
      AppEvents[AppEvents.PF_APP_COMMAND]
    );
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_PF_LANDING.MSG;
    eventDetail.target = AppExternalCommands.RCP_PF_LANDING.CMD;

    appCmdEvt.emit(eventDetail);
  }

  navigateToPolicyPage() {
    window.open(this.content.kHealthPopUpCard.modalKHealthLink, '_blank');
  }

  navigateToLhoConverge() {
    const appCmdEvt: EventEmitter<IEventDetail> = this._eventHandler.get(
      AppEvents[AppEvents.PF_APP_COMMAND]
    );
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_LHO_CONVERGE.MSG;
    eventDetail.target = AppExternalCommands.RCP_LHO_CONVERGE.CMD;

    appCmdEvt.emit(eventDetail);
  }

  getRedirectUrlErrorMessage() {
    return this.content.redirectUrlErrorMessage;
  }

  getVirtualCareCardsContent(): string {
    if ((this.hasTeleHealth()) && this.hasVirtualCare()) {
      return this.content.virtualCareHubCards
    } else if (this.hasVirtualCare()) {
      return this.content.virtualCareHubCards.filter((item) => item.action !== LHO);
    } else if (this.hasTeleHealth()) {
      return this.content.virtualCareHubCards.filter((item) => item.action === LHO);
    }
  }

  openKhealthModal() {
    this.isConsentAccepted = false;
    this.kHealthModal.show();
  }
}
