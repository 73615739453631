import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { ContentHelper } from '../../../values/contentHelper';
import { BaseComponent } from '../../base-component/baseCmp';
import { PillItemLookupService } from './../services/pillItemLookupService';
import { PillItemCmp } from './pillItemCmp';

@Component({
  selector: 'app-pill-item-look-up-cmp',
  templateUrl: './pillItemLookupCmp.html'
})
export class PillItemLookupComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() showButton: boolean = false;
  @Input() parentComponent: PillItemCmp;
  @Input() showHeader: boolean = false;
  @Input() headerText: string = '';
  @Input() lookupCSS: string = '';
  @Output() readonly clearClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() readonly pillLookupAfterView: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _pillItemLookupService: PillItemLookupService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this.showButton = this.isIntegratedSearchEnabled;
  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => {
      const lookupLoad = true;
      this.pillLookupAfterView.emit(lookupLoad);
    });
  }

  ngOnDestroy() {
    if (typeof this.parentComponent !== 'undefined' && this.parentComponent !== null) {
      this.parentComponent.selected = false;
    }
  }

  onClearClick(event: any) {
    this.clearClick.emit(event);
  }

  onApplyClick() {
    this._pillItemLookupService.destoryComponent();
  }
}
