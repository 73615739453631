import { Injectable } from "@angular/core";
import { TIER } from "../../../common/constants/app-constants";
import { ISortOption } from "../interfaces/iSummaryResp";
import { DataHelper } from './../../../common/services/dataHelper';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Injectable()
export class SortModel {
  private _items: ISortOption[];
  selectedValue: string;
  hasApplyError: boolean;
  noResults: boolean;
  hideSorting: boolean;
  ignoreOnchange: boolean = false;

  constructor(private _contentHlpr: ContentHelper, private _dataHlpr: DataHelper, private _appSession: AppSession) {
    this._items = [];
  }

  reset() {
    this._items = [];
    this.selectedValue = '';
    this.ignoreOnchange = false;
    this.hasApplyError = false;
    this.noResults = false;
    this.hideSorting = true;
  }

  get items(): ISortOption[] {
    return this._items;
  }
  init(options: string[], defaultSort: string) {
    if (!Array.isArray(options)) {
      return;
    }
    if (options.length === 0) {
      return;
    }

    let content: any = this._contentHlpr.getContent('PFSearchResultsContainerComponent');
    this.hideSorting = false;
    // Reset personalized match flag
    if (typeof this._appSession.searchParams !== 'undefined' &&
      this._appSession.searchParams !== null) {
      this._appSession.searchParams.hasPersonalizedMatch = false;
    }
    this._items = [];
    if (Array.isArray(options) && options.length > 0) {
      options.forEach((option, index) => {
        let label: string = content.summaryComponent.sortOptions[option.toLowerCase().replace(/ /ig, '_')];
        const sortLabel = this._dataHlpr.isEmptyString(label) ? option : label;
        const sortValue = this._dataHlpr.isEmptyString(label) ? TIER : option;
        let sortOption: ISortOption = {
          label: sortLabel,
          value: sortValue,
          id: 'cb_sort_' + index,
          name: 'cb_sort_' + index,
          trueValue: sortValue,
          falseValue: '',
          isChecked: false
        };
        this.items.push(sortOption);
        //Set personalize match only if sort options has personalize match
        if (option === 'PersonalizedMatch' && this._appSession.searchParams) {
          this._appSession.searchParams.hasPersonalizedMatch = true;
        }
      });
    }

    if (!this.isValidDefaultOption(options, defaultSort)) {
      defaultSort = this.items[0].value;
    }

    this.selectedValue = defaultSort;
    this.hasApplyError = false;
    this.noResults = false;
    this.ignoreOnchange = true;
    //Note: The UXD DropDown component is emiting onChange event multiple times when items are set in it.
    //We dont have a fix yet for this in UXD pkg.
    //As a work around we are delaying the onChange handling in PF side for 200 ms.
    //ignoreOnchange is used for this purpose.
    setTimeout(() => {
      this.ignoreOnchange = false;
    }, 200);
  }

  private isValidDefaultOption(options: string[], defaultSort: string): boolean {
    if (this._dataHlpr.isEmptyString(defaultSort)) {
      return false;
    }
    if (Array.isArray(options) && options.length > 0) {
      return options.some((opt) => {
        return this._dataHlpr.areEqualStrings(opt, defaultSort);
      })
    }
    return false;
  }

}
