import { Injectable } from '@angular/core';
import {
  ILocationInfo,
  INetworkStatus,
  IPcpInfo,
  IProviderPerformance,
  IRecognition as IProviderRecognition,
  ISpecialty as IProviderSpecialty,
  IVisibilityRule
} from '../../common/interfaces/iCommonSearchResponse';
import { IPCPInfo } from '../../common/interfaces/iPCPInfo';
import { ILocation, IPerformance, IProviderAddress, IRecognition, ISpecialty } from '../../common/interfaces/iSearchStrategyResponse';
import { IProviderNetworkStatus } from '../provider-details/models/iDetailsResponse';
import { IRulesResponse } from '../rules/interfaces/iRulesResponse';
import { NO, YES } from '../search-results/values/providerSearchConstants';
import { IBaseCode } from './../../common/interfaces/iBaseCode';
import { IFinalProvider, ISearchProvider } from './../../common/interfaces/iCommonSearchResponse';

@Injectable({
  providedIn: 'root'
})
export class SearchResponseUtil {
  static getSpecialty(specialty: IProviderSpecialty): ISpecialty[] {
    let specialities: ISpecialty[] = [];
    if (specialty?.taxonomies) {
      specialty?.taxonomies?.forEach((taxonomy) => {
        if (taxonomy) {
          let specialty = {} as ISpecialty;
          specialty.specialtyCode = taxonomy.code;
          specialty.specialtyCodeName = taxonomy.name;
          specialities.push(specialty);
        }
      });
    }
    return specialities;
  }

  static bindProviderLocation(responseLocation: ILocationInfo): ILocation {
    const location = {} as ILocation;
    const address = {} as IProviderAddress;
    if (responseLocation?.address) {
      address.addressId = responseLocation.address?.addressId;
      address.addressOne = responseLocation.address?.addressOne;
      address.addressTwo = responseLocation.address?.addressTwo;
      address.city = responseLocation.address?.city;
      address.state = responseLocation?.address?.state;
      address.country = responseLocation.address?.country;
      address.county = responseLocation?.address?.county;
      address.distance = responseLocation?.address?.distance?.toString();
      address.email = responseLocation?.address?.email;
      address.latitude = responseLocation?.address?.latitude;
      address.longitude = responseLocation?.address?.longitude;
      address.businessName = responseLocation?.address?.businessName;
      address.phone = responseLocation?.address?.phone;
      address.postalCode = responseLocation?.address?.postalCode;
      address.homeHlthCareIndc = responseLocation?.homeHlthCareIndc;
      address.onlineUrl = responseLocation?.address?.onlineUrl;
      address.officeHours = responseLocation?.address?.officeHours;
      address.open24hours = responseLocation?.address?.open24hours;
      address.appointmentAvailable = responseLocation?.address?.appointmentAvailable;
      address.appointmentType = responseLocation?.address?.appointmentType;
    }
    location.address = address;
    return location;
  }

  static getPcpInfo(pcpInfosResponse: IPcpInfo[]): IPCPInfo[] {
    const pcpInfos: IPCPInfo[] = [];
    (pcpInfosResponse || []).forEach((pcp) => {
      const pcpInfo = {} as IPCPInfo;
      if (pcp) {
        pcpInfo.affiliationName = pcp.affiliationName;
        pcpInfo.id = pcp.id;
        pcpInfo.poaKey = pcp.poaKey;
        pcpInfo.rltdProvAdrsKey = pcp.rltdProvAdrsKey;
        pcpInfo.affiliationGroup = pcp.affiliationGroup;
      }
      pcpInfos.push(pcpInfo);
    });
    return pcpInfos;
  }

  static getNetworkStatus(networkStatus: INetworkStatus): IProviderNetworkStatus {
    const provNetworkStatus = {} as IProviderNetworkStatus;
    if (networkStatus) {
      provNetworkStatus.date = networkStatus.date;
      provNetworkStatus.statusCode = networkStatus.status;
      provNetworkStatus.typeCode = networkStatus.coverages;
    }
    return provNetworkStatus;
  }

  static getBaseCodes(codes: string[]): IBaseCode[] {
    const baseCodes: IBaseCode[] = [];
    if (codes) {
      codes.forEach((code) => {
        baseCodes.push({ code });
      });
    }
    return baseCodes;
  }

  static getPerformance(performanceResponse: IProviderPerformance): IPerformance {
    const performance = {} as IPerformance;
    if (performanceResponse) {
      performance.acceptMedicaid = performanceResponse.acceptMedicaid;
      performance.indicator = this.getIndicator(performanceResponse.indicators);
      performance.recognition = this.getIndicator(performanceResponse.recognitions);
      performance.tier = this.getIndicator(performanceResponse.tiers);
      performance.noScore = performanceResponse.spfiDisclaimer;
    }
    return performance;
  }

  static getIndicator(recognitionResponse: IProviderRecognition[]): IRecognition[] {
    return (recognitionResponse || []).map((recognition) => {
      const { hyperlink, hoverText, categoryCode, code, description, disclaimer, indicatorValue, name, priorityCode, fileName } = recognition;
      return {
        hyperlink,
        hoverText,
        categoryCode,
        code,
        description,
        disclaimer,
        indicatorValue,
        name,
        priorityCode,
        fileName
      } as IRecognition;
    });
  }

  static getVisibilityRule(visibilityRule: IVisibilityRule): IRulesResponse {
    let visibilityRuleResponse = {} as IRulesResponse;
    if (visibilityRule) {
      visibilityRuleResponse.isSNYMember = visibilityRule.isSNYMember;
      visibilityRuleResponse.showAbleToServePCP = visibilityRule.showAbleToServePCP;
      visibilityRuleResponse.showAcceptMedicaid = visibilityRule.showAcceptMedicaid;
      visibilityRuleResponse.showAcceptNewPatient = visibilityRule.showAcceptNewPatient;
      visibilityRuleResponse.showAccreditation = visibilityRule.showAccreditation;
      visibilityRuleResponse.showAddCareTeam = visibilityRule.showAddCareTeam;
      visibilityRuleResponse.showAffialition = visibilityRule.showAffialition;
      visibilityRuleResponse.showAfterHoursPhone = visibilityRule.showAfterHoursPhone;
      visibilityRuleResponse.showAreaofExpertise = visibilityRule.showAreaofExpertise;
      visibilityRuleResponse.showAssignPCP = visibilityRule.showAssignPCP;
      // visibilityRuleResponse.showBehavioralHealth;
      // visibilityRuleResponse.showCOEList;
      visibilityRuleResponse.showDBAName = visibilityRule.showDBAName;
      visibilityRuleResponse.showDentalPlan = visibilityRule.showDentalPlan;
      visibilityRuleResponse.showDentalProcDisclaimer = visibilityRule.showDentalProcDisclaimer;
      visibilityRuleResponse.showDetailPharmacyDisclaimer = visibilityRule.showDetailPharmacyDisclaimer;
      visibilityRuleResponse.showEducation = visibilityRule.showEducation;
      visibilityRuleResponse.showEmail = visibilityRule.showEmail;
      visibilityRuleResponse.showEssentialProvider = visibilityRule.showEssentialProvider;
      visibilityRuleResponse.showEthnicity = visibilityRule.showEthnicity;
      visibilityRuleResponse.showGender = visibilityRule.showGender;
      visibilityRuleResponse.showGenderPreference = visibilityRule.showGenderPreference;
      visibilityRuleResponse.showInsuranceAccepted = visibilityRule.showInsuranceAccepted;
      visibilityRuleResponse.showLanguageAtPractice = visibilityRule.showLanguageAtPractice;
      visibilityRuleResponse.showLevelOfCare = visibilityRule.showLevelOfCare;
      visibilityRuleResponse.showLicenseNumber = visibilityRule.showLicenseNumber;
      visibilityRuleResponse.showLiveHealth = visibilityRule.showLiveHealth;
      visibilityRuleResponse.showLocation = visibilityRule.showLocation;
      visibilityRuleResponse.showMedicarePartDPharmacyPlan = visibilityRule.showMedicarePartDPharmacyPlan;
      visibilityRuleResponse.showMedicareSupplement = visibilityRule.showMedicareSupplement;
      visibilityRuleResponse.showMinnesotaDisclaimer = visibilityRule.showMinnesotaDisclaimer;
      visibilityRuleResponse.showNpi = visibilityRule.showNpi;
      visibilityRuleResponse.showOfficeHours = visibilityRule.showOfficeHours;
      visibilityRuleResponse.showOfficeService = visibilityRule.showOfficeService;
      visibilityRuleResponse.showPatientPopulation = visibilityRule.showPatientPopulation;
      visibilityRuleResponse.showPharmacyFeatures = visibilityRule.showPharmacyFeatures;
      visibilityRuleResponse.showPharmacyNpi = visibilityRule.showPharmacyNpi;
      visibilityRuleResponse.showPhone = visibilityRule.showPhone;
      // visibilityRuleResponse.showPrimaryCare;
      visibilityRuleResponse.showProviderName = visibilityRule.showProviderName;
      visibilityRuleResponse.showProviderWebsite = visibilityRule.showProviderWebsite;
      visibilityRuleResponse.showQuestionnaire = visibilityRule.showQuestionnaire;
      visibilityRuleResponse.showRecognitionsTab = visibilityRule.showRecognitionsTab;
      visibilityRuleResponse.showRetailPharmacyAccessibility = visibilityRule.showRetailPharmacyAccessibility;
      visibilityRuleResponse.showReview = visibilityRule.showReview;
      visibilityRuleResponse.showScheduleAppointment = visibilityRule.showScheduleAppointment;
      visibilityRuleResponse.showSearch = visibilityRule.showSearch;
      visibilityRuleResponse.showServiceAvailable = visibilityRule.showServiceAvailable;
      visibilityRuleResponse.showServiceLocationName = visibilityRule.showServiceLocationName;
      visibilityRuleResponse.showSpeciality = visibilityRule.showSpeciality;
      visibilityRuleResponse.showSpecialityDetail = visibilityRule.showSpecialityDetail;
      visibilityRuleResponse.showStateLicense = visibilityRule.showStateLicense;
      visibilityRuleResponse.showStoreNumber = visibilityRule.showStoreNumber;
      visibilityRuleResponse.showSubSpeciality = visibilityRule.showSubSpeciality;
      visibilityRuleResponse.showTeleHealth = visibilityRule.showTeleHealth;
      visibilityRuleResponse.showTransportation = visibilityRule.showTransportation;
      visibilityRuleResponse.showUCDisclaimer = visibilityRule.showUCDisclaimer;
      visibilityRuleResponse.showVisionPlan = visibilityRule.showVisionPlan;
      return visibilityRuleResponse;
    }
  }

  static getCommonProviderInformation(provider: ISearchProvider): IFinalProvider {
    const finalProvider = {} as IFinalProvider;
    if (provider) {
      const { location } = provider;
      finalProvider.avatar = provider.avatar;
      finalProvider.providerFlags = provider.providerFlags;
      finalProvider.acceptsNewPatients = provider.acceptsNewPatients;
      finalProvider.activeFlagList = provider.activeFlagList;
      finalProvider.categoryCode = provider.categoryCode;
      finalProvider.careTeamProvider = location?.careTeamProvider;
      finalProvider.gender = provider.gender;
      finalProvider.lastName = provider.lastName;
      finalProvider.location = this.bindProviderLocation(provider.location);
      finalProvider.licenseList = provider.licenseList;
      finalProvider.middleName = provider.middleName;
      finalProvider.numberOfAddresses = location?.numberOfAddresses;
      finalProvider.nameSuffixCode = provider.suffix;
      finalProvider.nationalProviderIdentifier = location?.npiIds;
      finalProvider.onlineProvider = location?.onlineProvider === true ? YES : NO;
      finalProvider.providerIdentifier = provider.id;
      finalProvider.profilePicUrl = provider.profilePicUrl;
      finalProvider.providerLanguages = provider.languages;
      finalProvider.providerName = provider.providerName;
      finalProvider.networkRegisteredName = provider.networkRegisteredName;
      finalProvider.providerCategoryCode = { code: provider.categoryCode };
      finalProvider.providerTypeCodeList = provider.typeCodes;
      finalProvider.performance = this.getPerformance(provider.performance);
      finalProvider.organizationName = provider.organizationName;
      finalProvider.specialtyCategory = provider.specialty?.specialtyCategories;
      finalProvider.pcpInfoList = this.getPcpInfo(provider.pcpInfos);
      finalProvider.pcpList = provider.pcpList;
      finalProvider.providerSituations = provider.providerSituations;
      finalProvider.providerNetworkStatus = this.getNetworkStatus(provider.networkStatus);
      finalProvider.providerTier = provider.providerTier;
      finalProvider.reviewRatings = provider.reviewRatings;
      finalProvider.sequenceNumber = provider.sequenceNumber;
      finalProvider.sourceSystemName = provider.sourceSystemName;
      finalProvider.specialties = this.getSpecialty(provider.specialty);
      finalProvider.teleHealthProvider = location?.teleHealthProvider;
      finalProvider.visibilityRule = this.getVisibilityRule(provider.visibilityRule);
      finalProvider.visionServiceList = provider.visionServiceList;
      finalProvider.isVirtualProvider = location?.virtualProvider;
      finalProvider.callToAction = location?.callToAction;
      finalProvider.anp = location?.anp;
      finalProvider.isShipDirectToDoor = provider?.isShipDirectToDoor;
      finalProvider.pharmacyType = provider?.pharmacyType;
      finalProvider.pdtKey = provider?.pdtKey;
      finalProvider.titleList = provider.titleList;
    }
    return finalProvider;
  }
}
