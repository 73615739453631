import { Injectable } from '@angular/core';
import { HttpMethod } from '../../common/enums/httpMethodEnum';
import { ResponseType } from '../../common/enums/responseTypeEnum';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { apiNameList } from './../../../environments/api-name-list';
import { BaseService } from './../services/baseService';
import { HttpClientService } from './httpClientService';

@Injectable({ providedIn: 'root' })
export class CommmunicationService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getVcard(reqObj: any): Promise<any> {
    const headers = this.getHeaders();
    headers.push({
      name: 'Accept',
      value: 'text/vcard'
    });
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: `${this.baseURL + apiNameList.restApi.vcard}`,
      data: reqObj,
      responseType: ResponseType.ArrayBuffer,
      headers: headers
    });
  }
}
