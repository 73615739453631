import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { PFCostDetail, PFSearchCostInfo } from '../../../common/interfaces/iCptMedicalResponse';
import { IProvider } from '../../../common/interfaces/iSearchStrategyResponse';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { CptSummaryModel } from '../models/cptSummaryModel';

@Component({
  selector: 'app-pf-cpt-cost-column-cmp',
  templateUrl: '../views/pfCptCostColumnCmp.html'
})
export class PFCptCostColumnComponent extends BaseComponent {
  @Input()
  professional = {} as IProvider;
  cosDetailsVM: PFCostDetail[] = [];
  costInfo: PFSearchCostInfo = {} as PFSearchCostInfo;

   constructor(private _cptSummaryModel: CptSummaryModel,
    route: ActivatedRoute, eventHandler: EventHandler, contentHlpr: ContentHelper, appSession: AppSession) {
      super(route, eventHandler, appSession, contentHlpr, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    let requiredCostInfo;
    if (this.professional?.costInfo?.isOfficeVisitCost) {
      requiredCostInfo = {
        memberResponsibility: this.professional?.costInfo?.memberResponsibility
      };
    } else {
      requiredCostInfo = {
        estimatedBill: this.professional?.costInfo?.estimatedBill,
        planPays: this.professional?.costInfo?.planPays,
        memberResponsibility: this.professional?.costInfo?.memberResponsibility
      };
    }

    Object.keys(requiredCostInfo).forEach(key => {
      const vm: PFCostDetail = {
        name: this.content.dollarCptCostColumn[key] ?? '',
        value: requiredCostInfo[key] ?? ''
      };
      this.cosDetailsVM.push(vm);
    });
    this._cptSummaryModel.cptSummaryResponse.subscribe((resp) => {
      this.costInfo = {
        modifierCode: resp?.modifier?.name,
        placeOfService: resp?.pos?.name,
        serviceLimits: resp?.serviceLimits,
        hasServiceLimit: resp?.hasServiceLimit,
        orthoTypeFlag: resp?.orthoTypeFlag,
        missingToothPeriodFlag: resp?.missingToothPeriodFlag,
        hasDentalServiceLimits: resp?.hasDentalServiceLimits,
        costInfo: resp?.providerList?.find((provider) => provider.providerIdentifier === this.professional.providerIdentifier && provider.location?.address?.addressId === this.professional.location?.address?.addressId)?.costInfo  || this.professional?.costInfo};
    });
  }

  onWhatYouPayClick(): void {
    this._cptSummaryModel.setCptCostResponse(this.costInfo);
  }
}
