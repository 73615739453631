export enum INFOBOX_EVENTS {
  COMPARE_CHECKBOX = 'lbl_pfSearchResultsCb-',
  SEE_ALL = 'txt-pcp-seeAll-',
  PROVIDER_NAME = 'txt-summary-provider-name-',
  FACILITY_NAME = 'txt-summary-provider-aff-name-',
  MAP_ICON = 'fad-pf-search-results-map-icon-',
  RATINGS_REVIEWS = 'lnk-summary-rating-reviews-',
  SSO_LINK = 'fad-pf-sso-link-',
  ASSIGN_PCP = 'btn-assign-pcp-',
  ASSIGN_CARE = 'btn-assign-care-',
  BOOK_APPT = 'btn-appointment-schedule-',
  SHOW_MORE = 'lnk-filter-moreless-',
  TELEHEALTH_MODAL = 'fad-pf-search-results-telehealth-text-',
  LOCATION_SLIDER = 'view-all-locations-',
  ALL_SPECIALTY_SLIDER = 'txt-view-all-specialties-',
  SPECIALTY_SLIDER = 'txt-specialty-',
  RATING_SLIDER = 'bcbsa-rating-',
  COST_DETAILS_SLIDER = 'lbl-cost-details-'
}
