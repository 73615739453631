import { ChangeDetectionStrategy, Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CptSummaryModel } from '../../../fad/search-results/models/cptSummaryModel';
import { PFSearchCostEstimateSidePanelVM, PFSearchCostInfo } from '../../interfaces/iCptMedicalResponse';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  selector: '[app-fad-search-cost-estimate-slide-out]',
  styles: [`
    :host {
      cursor: pointer;
    }
  `],
  templateUrl: './pfSearchCostEstimateSlideOutCmp.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PFSearchCostEstimateSlideOutCmp extends BaseComponent {
  costEstimateContent = this.content.cptCostEstimateSidePanelContent;
  vm$: Observable<PFSearchCostEstimateSidePanelVM> = this._cptSearchModel.cptCostResponse.pipe(
    map((value: PFSearchCostInfo) => {
      const estimatedContent = this._cptSearchModel.costEstimateWithContent(value, this.costEstimateContent.costContent, this.costEstimateContent.costContentForPercentage);
      estimatedContent.costInfo = estimatedContent?.costInfo?.filter((data) => data?.name);
      estimatedContent.serviceLimits = value.serviceLimits;
      estimatedContent.hasServiceLimit = value.hasServiceLimit;
      estimatedContent.orthoTypeFlag = value.orthoTypeFlag;
      estimatedContent.missingToothPeriodFlag = value.missingToothPeriodFlag;
      estimatedContent.hasDentalServiceLimits = value?.hasDentalServiceLimits;
      estimatedContent.isOfficeVisitCost = value?.costInfo?.isOfficeVisitCost;
      return estimatedContent;
    }),
    catchError((error) => of(error))
  );
  sidePanelRef: ModalRef<HTMLElement>;
  @ViewChild('estimatedCost')
  estimatedCost: TemplateRef<HTMLElement>;

  constructor(private _sidePanel: SidePanel,
    private _cptSearchModel: CptSummaryModel,
    route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession, contentHelper: ContentHelper) {
      super(route, eventHandler, appSession, contentHelper, 'PFSearchResultsContainerComponent');
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  onHostClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.sidePanelRef = this._sidePanel.open('right', this.estimatedCost);
  }

  onClose() {
    this.sidePanelRef?.close();
  }
}
