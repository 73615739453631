import { TitleCasePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidePanel } from '@anthem/uxd/modal';
import { DataHelper } from '../../../../common/services/dataHelper';
import { NavigationService } from '../../../../common/services/navigationService';
import { RouteUtil } from '../../../../common/utilities/routeUtil';
import { AppSession } from '../../../../common/values/appSession';
import { ContentHelper } from '../../../../common/values/contentHelper';
import { EventHandler } from '../../../services/eventHandler';
import { MemberSummaryService } from '../../app-secure/services/memberSummarySvc';
import { SecureStateHandler } from '../../app-secure/services/secureStateHandler';
import { GeoLocationService } from '../../geoLocation/services/geoLocationService';
import { ScenarioAlertSvc } from '../../scenarioAlert/services/pfScenarioAlertSvc';
import { ChangePlanService } from '../services/changePlanSvc';
import { CommonUtil } from './../../../../fad/utilities/commonUtil';
import { PFChangePlanComponent } from './pfChangePlanCmp';

@Component({
  moduleId: module.id,
  selector: 'app-change-plan-v2-cmp',
  templateUrl: './pfChangePlanCmp.html'
})
export class PFChangePlanV2Component extends PFChangePlanComponent {

  constructor(
    @Inject(AppSession)
    protected _appSession: AppSession,
    _eventHandler: EventHandler,
    _navigationService: NavigationService,
    _route: ActivatedRoute,
    _contentHelper: ContentHelper,
    _sidePanel: SidePanel,
    _memberSummaryService: MemberSummaryService,
    _secureStateHandler: SecureStateHandler,
    _routeUtil: RouteUtil,
    _changePlanService: ChangePlanService,
    _titleCasePipe: TitleCasePipe,
    _scenarioAlertSvc: ScenarioAlertSvc,
    _dataHelper: DataHelper,
    _geoLocationSvc: GeoLocationService
  ) {
    super(
      _appSession,
      _eventHandler,
      _navigationService,
      _route,
      _contentHelper,
      _sidePanel,
      _memberSummaryService,
      _secureStateHandler,
      _routeUtil,
      _changePlanService,
      _titleCasePipe,
      _scenarioAlertSvc,
      _dataHelper,
      _geoLocationSvc
    );
  }

  get changePlanLabel(): string {
    if (CommonUtil.isMemberSecure(this._appSession) && this.multiMemberRes && this.multiMemberRes.contractList) {
      this.hasMultiplePlansForMemeber();
      this.hasDependents();
    }
    let label = this.content?.commonContents?.labels?.changePlanPublic ?? '';
    if (!this.isCareTeamPage && this.showMultiplansLink) {
      label = this.content?.commonContents?.labels?.planLabel ?? '';
    }
    return label;
  }

  // In the new UI we are not displaying Member name along with Plan name.
  // So, Override the parent method and Do Not join Member name.
  joinMemberNameWithPlanName(planNm: string, memberNm: string) {
    this.planText = planNm;
  }

  getShowChangePlanLinkValue(): boolean {
    this.hasMultiplePlansForMemeber();
    //When there are more members in a single plan, we are not showing the 'change member' link.
    if (this.hasMultipleMembers && !this.hasMultiplePlans) {
      return false;
    }
    return super.getShowChangePlanLinkValue();
  }

  onShowPlanNetworkLink(): void {
    super.onShowPlanNetworkLink();
    if (this.planNames && this.planNames.length > 1) {
      let selectedPlanText = '';
      if (!this.isCareTeamPage) {
        selectedPlanText = this.content?.commonContents?.labels?.selectedMemberPlans;
        this.multiplePlansText = this.content?.commonContents?.labels?.allYour;
        this.showMultiplansLink = true;
      }
    }
  }
}
