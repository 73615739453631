import { CurrencyPipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppSession } from '../../../../common/values/appSession';
import { PROVIDER_TYPE } from '../../../../findcare/common/constants/common';
import { ComapreProviderHandler } from '../../classes/comapreProviderHandler';
import { IProviderDetails } from '../../interfaces/iCompareProvider';
import { IProvider } from '../../interfaces/iProvider';
import { CompareProvider } from '../../services/compareProvider';
import { ProviderCompareComponent } from '../providerCompare/providerCompareCmp';
import { ProviderCompareSidePanelComponent } from '../providerCompareSidePanel/providerCompareSidePanelCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-selected-provider',
  templateUrl: './selectedProviderCmp.html'
})
export class SelectedProviderComponent implements OnInit, OnDestroy {
  @Input() resultContent: any;
  comapreSelectedProviders: IProvider[] = [];
  selectedProviderSubscription: Subscription;
  selectedProvidersDetailsResponse: IProviderDetails[];
  @ViewChild(ProviderCompareComponent) providerCompareCmp: ProviderCompareComponent;
  @ViewChild(ProviderCompareSidePanelComponent) providerCompareSidePanelCmp: ProviderCompareSidePanelComponent;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _compareProvider: CompareProvider,
    private _providerCompareService: ComapreProviderHandler,
    private _currencyPipe: CurrencyPipe
  ) {
  }

  ngOnInit(): void {
    this.subscribeProviderSelection();
  }
  ngOnDestroy(): void {
    this.selectedProviderSubscription?.unsubscribe();
  }

  formatProviderName(name: string) {
    if (name.length > 25) {
      name = name.substring(0, 25) + '...';
    }
    return name;
  }

  removeProviderSelection(provider: IProvider) {
    this._compareProvider.remove(provider);
  }

  compareCancel() {
    this._compareProvider.clear();
  }

  subscribeProviderSelection() {
    this.selectedProviderSubscription = this._compareProvider.compareProvider.subscribe((data) => {
      this.comapreSelectedProviders = data || [];
    });
  }

  /** Function to open compare modal on click of compare button */
  openCompareModel() {
    this.getCompareDetails();
  }

  /** Function to call Compare API */
  getCompareDetails() {
    this._providerCompareService.getProviders(this.comapreSelectedProviders).then(
      (result) => {
        this.onCompareResponseSuccess(result);
      },
      (error) => {
        throw error;
      }
    );
  }

  /** Function to capture successful API response */
  onCompareResponseSuccess(result) {
    this.selectedProvidersDetailsResponse = result.providerDetail;
    if (this.selectedProvidersDetailsResponse.length) {
      this.selectedProvidersDetailsResponse.forEach((providerDetail, index) => {
        const matchingProvider = this.comapreSelectedProviders.find(selectedProvider => selectedProvider.providerIdentifier === providerDetail.providerIdentifier);
        if (matchingProvider) {
          this.selectedProvidersDetailsResponse[index] = {
            ...providerDetail,
            memberCostEstimate: this.assignMemberCostEstimate(matchingProvider),
            providerBillEstimate: this.assignProviderBillEstimate(matchingProvider)
          };
        }
      });
      const isMobile = window.innerWidth <= 480;
      if (isMobile) {
        this.providerCompareSidePanelCmp.openSidePanel('right', this.selectedProvidersDetailsResponse);
      } else {
        if (this.comapreSelectedProviders.length) {
          this.providerCompareCmp.showModal(this.selectedProvidersDetailsResponse);
        }
      }
    }
  }

  private assignMemberCostEstimate(provider): string | undefined {
    let memberCostEstimate;
    if (provider?.eycCostInfo?.isVisibleTotalYouPay) {
      memberCostEstimate = this._currencyPipe.transform(provider.eycCostInfo?.totalYouPay, 'USD', 'symbol', '1.0') ?? undefined;
    } else if (provider?.eycCostInfo?.isVisibleTotalAverageCost) {
      memberCostEstimate = provider.eycCostInfo?.totalAverageCost;
    } else if (provider?.costInfo?.memberResponsibility) {
      memberCostEstimate = provider.costInfo.memberResponsibility;
    }
    return memberCostEstimate;
  }

  private assignProviderBillEstimate(provider): string | undefined {
    let providerBillEstimate;
    if (provider?.eycCostInfo?.isVisibleTotalEstimateCost) {
      providerBillEstimate = this._currencyPipe.transform(provider.eycCostInfo?.totalEstimateCost, 'USD', 'symbol', '1.0') ?? undefined;
    } else if (provider?.eycCostInfo?.isVisibleTotalAverageCostRange && !this.isDentalSearch) {
      providerBillEstimate = provider.eycCostInfo?.totalAverageCostRange;
    } else if (provider?.costInfo?.estimatedBill) {
      providerBillEstimate = provider.costInfo.estimatedBill;
    }
    return providerBillEstimate;
  }

  get isDentalSearch(): boolean {
    return (
      (this._appSession.hasDentalEYC &&
        this._appSession.searchParams &&
        this._appSession.searchParams.eycSearchParam &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL) ??
      false
    );
  }

  removeSelectedProvider(provider) {
    const removeProvider = this.comapreSelectedProviders.find(obj =>
      obj.providerIdentifier === provider.providerIdentifier && obj.location?.address?.addressId === provider.locations[0]?.address?.addressId
    );
    this._compareProvider.remove(removeProvider);
  }
}
