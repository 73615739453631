import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
@Component({
  selector: 'app-fad-helpfulhints',
  templateUrl: './pfHelpfulHintsCmp.html'
})
export class PFHelpfulHintsComponent extends BaseComponent implements OnInit {
  @Input() helpfulhintscontent: any;
  @Input() helpfulhintsshowHeader: boolean = true;
  helpfulHints: any = [];
  constructor(
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper
  ) {
      super( _route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  embedBaseUrl(content: string) {
    return this.getDocumentURL(content);
  }
}
