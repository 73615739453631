import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppEvents } from '../../../enums/appEvents';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { BaseComponent } from '../../base-component/baseCmp';
import { MemberSummaryService } from '../services/memberSummarySvc';
import { IProviderSearchDdlValues } from './../../../../fad/search-providers/interfaces/iProviderSearchDdlValues';
import { SECUREFLAGS } from './../../../../fad/search-results/values/providerSearchConstants';
import { AppConstants, Locale } from './../../../constants/app-constants';
import { AppNavigations } from './../../../constants/app-navigations';
import { DeeplinkUrl } from './../../../enums/deepLinkUrls';
import { IEventDetail } from './../../../interfaces/iEventDetail';
import { IWindow } from './../../../interfaces/iWindow';
import { NavigationService } from './../../../services/navigationService';
import { ContentHelper } from './../../../values/contentHelper';
import { IMetadata } from './../models/iMetadata';
import { SecureStateHandler } from './../services/secureStateHandler';

@Component({
  moduleId: module.id,
  selector: 'app-emulate-secure-state-cmp',
  templateUrl: './emulateSecureStateCmp.html'
})
export class EmulateSecureStateComponent extends BaseComponent implements OnInit, AfterViewInit {
  showLoader: boolean = false;
  metaData = {} as IMetadata;
  focusAlphaPrefix: boolean = false;
  focusHcid: boolean = false;
  brandDdl: any;
  focusDob: boolean = false;
  focusFirstName: boolean = false;
  focusLastName: boolean = false;
  secureFlags: any = SECUREFLAGS;
  focusMbrUid: boolean = false;
  focusMbrSequenceNbr: boolean = false;
  focusGroupId: boolean = false;
  focusWebGuid: boolean = false;
  focusSourceSystem: boolean = false;
  focusDeeplink: boolean = false;
  metadataError: boolean = false;
  errorText: string = '';
  focusPcpIndicator: boolean = false;
  focusContractUId: boolean = false;
  focusUserName: boolean = false;
  focusMetaDataLang: boolean = false;
  focusPersonalizedSessionId: boolean = false;
  language: Locale;
  langDdl: IProviderSearchDdlValues;

  showEmulateToggle = true;
  showUserNameToggle = false;
  userNameErrorText: string = '';
  userNameEmulateError: boolean = false;
  emulatedUserName: string = '';

  emulateOptions = [
    {
      label: 'Emulate for public integrated site ?',
      value: false,
      id: 'rdb-emulate-toggle-public-id'
    },
    {
      label: 'Emulate for secure integrated site ?',
      value: true,
      id: 'rdb-emulate-toggle-secure-id'
    }
  ];

  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  private _pfMetadataErrorEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.METADATA_VALIDATION]);
  private _pfAppErrorEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.GLOBAL_ERROR]);
  private _pfMemberSummaryErrorEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.MEMBER_SUMMARY_ERR]);

  private _onPfMetadataErrorSub: Subscription;
  private _onPfAppErrorSub: Subscription;
  private _onPfMemberSummaryErrorSub: Subscription;

  constructor(
    private _eventHandler: EventHandler,
    private _router: Router,
    private _route: ActivatedRoute,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _secureStateHandler: SecureStateHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _navigationService: NavigationService,
    private ref: ChangeDetectorRef,
    @Inject('Window')
    private _window: IWindow,
    private _memberSummaryService: MemberSummaryService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'EmulateUserComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appSession.resetAppSession();
    this.langDdl = AppConstants.LANG;
    this.brandDdl = this.buildDropDownValues(AppConstants.BRAND, 'cb_brand_', '', '');
    this.metaData.brand = 'ABCBS';
    this.metaData.isCastlightTransparency = false;
    this.metaData.isEmulateFlag = false;
    this.metaData.pcpIndicator = '';
    this.metaData.isWebView = false;
    this.metaData.contractUid = '';
    this._appSession.providerTypes = [];
    this.language = this._appSession.metaData.locale.toString() === Locale.SPANISH ? Locale.SPANISH : Locale.ENGLISH;
    // this flag is used to identify whether PF is loaded inside RCP.
    // need to have it here as well to emulate RCP experience.
    this._appSession.isIntegratedMode = true;

    this._onPfMetadataErrorSub = this._pfMetadataErrorEvent.subscribe((detail: IEventDetail) => {
      this.showLoader = false;
      this.errorText = detail.message;
      this.metadataError = true;
    });

    this._onPfAppErrorSub = this._pfAppErrorEvent.subscribe((detail: IEventDetail) => {
      this.showLoader = false;
      if (this.ref !== null && this.ref !== undefined && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });

    this._onPfMemberSummaryErrorSub = this._pfMemberSummaryErrorEvent.subscribe((detail: IEventDetail) => {
      this.showLoader = false;
      this.errorText = detail.message;
      this.metadataError = true;
    });
  }

  ngAfterViewInit() {
    if (this.content.globalHeaderComponent && this.content.globalHeaderComponent.pageHeader && this.content.globalHeaderComponent.pageHeader.emulationPageTitle) {
      const pageEmulationObj = {
        header: this.content.globalHeaderComponent.pageHeader.emulationPageTitle,
        title: this.content.globalHeaderComponent.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageEmulationObj);
    }

    this._appSession.isSecureState = true;
  }

  emulateFindADoctor() {
    this.showLoader = true;
    this.metadataError = false;
    if (!this.metaData.dplUrl || this.metaData?.dplUrl?.indexOf(DeeplinkUrl.COST_SEARCH) >= 0) {
      this.metaData.dplUrl = DeeplinkUrl.SEARCH_PROVIDERS;
    }
    this._secureStateHandler.pfEmMode = true;
    this.metaData.mobileOS = 'web';
    this._appSession.metaData.locale = this.language;
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  emulateEstimateYourCost() {
    this.showLoader = true;
    this.metadataError = false;
    this.metaData.dplUrl = DeeplinkUrl.COST_SEARCH;
    this.metaData.isSecureState = true;
    this._secureStateHandler.pfEmMode = true;
    this._appSession.metaData.locale = this.language;
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  emulateChangePCP() {
    this.showLoader = true;
    this.metadataError = false;
    this.metaData.dplUrl = DeeplinkUrl.SEARCH_PROVIDERS;
    this.metaData.isChangePCP = true;
    this.metaData.isSecureState = true;
    this._secureStateHandler.pfEmMode = true;
    this._appSession.metaData.locale = this.language;
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  emulateCareTeam() {
    this.showLoader = true;
    this.metadataError = false;
    this.metaData.dplUrl = DeeplinkUrl.CARE_CIRCLE;
    this.metaData.isSecureState = true;
    this._secureStateHandler.pfEmMode = true;
    this._appSession.metaData.locale = this.language;
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  emulateFindProcedure() {
    this.showLoader = true;
    this.metadataError = false;
    this.metaData.dplUrl = DeeplinkUrl.PROMOTED_PROCEDURES;
    this.metaData.isSecureState = true;
    this._secureStateHandler.pfEmMode = true;
    this._appSession.metaData.locale = this.language;
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  emulateVirtualCare() {
    this.showLoader = true;
    this.metadataError = false;
    this.metaData.dplUrl = DeeplinkUrl.VIRTUAL_CARE;
    this.metaData.isSecureState = true;
    this._secureStateHandler.pfEmMode = true;
    this._appSession.metaData.locale = this.language;
    this._secureStateHandler.initializeSecureState(JSON.stringify(this.metaData));
  }

  toggleFocusAlphaPrefix() {
    if (this.focusAlphaPrefix === true) {
      this.focusAlphaPrefix = false;
    } else {
      this.focusAlphaPrefix = true;
    }
  }

  toggleFocusHcid() {
    if (this.focusHcid === true) {
      this.focusHcid = false;
    } else {
      this.focusHcid = true;
    }
  }

  toggleFocusDob() {
    if (this.focusDob === true) {
      this.focusDob = false;
    } else {
      this.focusDob = true;
    }
  }

  toggleFocusFirstName() {
    if (this.focusFirstName === true) {
      this.focusFirstName = false;
    } else {
      this.focusFirstName = true;
    }
  }

  toggleFocusLastName() {
    if (this.focusLastName === true) {
      this.focusLastName = false;
    } else {
      this.focusLastName = true;
    }
  }

  toggleFocusMbrUid() {
    if (this.focusMbrUid === true) {
      this.focusMbrUid = false;
    } else {
      this.focusMbrUid = true;
    }
  }

  toggleFocusMbrSequenceNbr() {
    if (this.focusMbrSequenceNbr === true) {
      this.focusMbrSequenceNbr = false;
    } else {
      this.focusMbrSequenceNbr = true;
    }
  }

  toggleFocusGroupId() {
    if (this.focusGroupId === true) {
      this.focusGroupId = false;
    } else {
      this.focusGroupId = true;
    }
  }

  toggleFocusWebGuid() {
    if (this.focusWebGuid === true) {
      this.focusWebGuid = false;
    } else {
      this.focusWebGuid = true;
    }
  }

  toggleFocusSourceSystem() {
    if (this.focusSourceSystem === true) {
      this.focusSourceSystem = false;
    } else {
      this.focusSourceSystem = true;
    }
  }

  toggleFocusDeeplink() {
    if (this.focusDeeplink === true) {
      this.focusDeeplink = false;
    } else {
      this.focusDeeplink = true;
    }
  }

  toggleFocusPcpIndicator() {
    if (this.focusPcpIndicator === true) {
      this.focusPcpIndicator = false;
    } else {
      this.focusPcpIndicator = true;
    }
  }

  toggleFocusContractUId() {
    if (this.focusContractUId === true) {
      this.focusContractUId = false;
    } else {
      this.focusContractUId = true;
    }
  }

  toggleFocusUserName() {
    if (this.focusUserName === true) {
      this.focusUserName = false;
    } else {
      this.focusUserName = true;
    }
  }

  toggleFocusPersonalizedSessionId() {
    this.focusPersonalizedSessionId = !this.focusPersonalizedSessionId;
  }

  setBrand($event) {
    this.metaData.brand = $event.value;
  }

  setLang($event) {
    this.language = $event.value;
  }

  onEmulateCost() {
    this._navigationService.navigateByUrl(AppNavigations.EMULATE_COST_PATH);
  }

  buildDropDownValues(data: any, identifier: any, defaultname: string, defaultValue: string, type?: string) {
    const ddlValues: IProviderSearchDdlValues = {
      title: '',
      options: []
    };
    let name = '';
    let code = '';
    if (defaultname !== '') {
      ddlValues.options.push({
        label: defaultname,
        value: defaultValue,
        id: identifier + 0,
        name: identifier + 0,
        trueValue: defaultValue,
        falseValue: '',
        isChecked: false
      });
    }

    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        name = code = data[i].brand ? data[i].brand.trim() : data[i].brand;
        ddlValues.options.push({
          label: name,
          value: code,
          id: identifier + (i + 1),
          name: identifier + (i + 1),
          trueValue: code,
          falseValue: '',
          isChecked: false
        });
      }
    }
    return ddlValues;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (!this._appSession.searchParams) {
      location.reload();
    }
  }

  onToggleChange(toggleValue: boolean) {
    this.showEmulateToggle = false;
    if (toggleValue) {
      this.showUserNameToggle = true;
      this.emulatedUserName = this.getUserName();
    } else {
      this.metaData = {} as IMetadata;
      this.metaData.isSecureState = toggleValue;
    }
  }

  async fetchUserMetaData() {
    if (Boolean(this.emulatedUserName)) {
      try {
        this.saveUserName(this.emulatedUserName);
        this.showLoader = true;
        this.metaData = await this._memberSummaryService.fetchMetadata(this.emulatedUserName);
        if (!this._appSession.appConfig?.production) {
          this.metaData.isEmulateFlag = false;
        }
        this.showUserNameToggle = false;
        this.userNameEmulateError = false;
        this.showLoader = false;
        this.metaData.isSecureState = true;
      } catch (error) {
        this.showLoader = false;
        this.showUserNameToggle = true;
        this.userNameErrorText = 'There is an api failure please provide valid user name to emulate';
        this.userNameEmulateError = true;
      }
    } else {
      this.userNameErrorText = 'User name is mandatory for emulating the secure state';
      this.userNameEmulateError = true;
    }
  }

  getUserName() {
    if (this._window?.localStorage) {
      return this._window.localStorage.getItem('emulatedUserName');
    }
  }

  saveUserName(userName: string) {
    if (this._window?.localStorage) {
      return this._window.localStorage.setItem('emulatedUserName', userName);
    }
  }
}
