export enum AppEvents {
  PF_APP_LOAD_ERR = 2,
  METADATA_VALIDATION = 3,
  SSO = 4,
  PF_APP_ALERT = 5,
  MEMBER_SUMMARY_ERR = 6,
  OPEN_BRIDGE_MODAL = 7,
  APP_HEADER = 8,
  KEEPALIVE = 9,
  PF_APP_LOAD_STARTED = 10,
  PF_APP_LOAD_SUCCESS = 11,
  PF_APP_COMMAND = 12,
  PF_APP_CMD_NAV = 13,
  HIDE_COBROWSE = 14,
  SEND_COBROWSE_TOKEN = 15,
  CALL_SAML = 16,
  GLOBAL_ERROR = 17,
  MAP_DATA_LOADED = 18,
  SHOW_DRIVE_DIRECTION = 19,
  APP_BRAND = 20,
  HCID_ERR = 21,
  PF_DETAIL_LOAD = 22,
  PF_ASSIGN_PCP_LOAD = 23,
  PF_APP_CMD_PROV_INFO = 24,
  PF_APP_SEARCH_BAR_LOAD = 25,
  PF_APP_CMD_EMAIL = 26,
  PF_CPT_OFC_VISIT_COST = 27
}
