import { Injectable } from '@angular/core';
import { HttpMethod } from '../enums/httpMethodEnum';
import { IEmailResponse } from '../interfaces/iEmailResponse';
import { IUrlParam } from '../interfaces/iUrlParam';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';

@Injectable({ providedIn: 'root' })
export class SummaryEmailSvc extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute<T>(emailRequest: T, apiName: string): Promise<IEmailResponse> {
    const apiUrl = `${apiName}/email`;
    const urlParams: IUrlParam[] = [
      {
        name: 'locale',
        value: this.appSession?.metaData?.locale as string,
        isQueryParam: true
      },
      {
        name: 'tz',
        value: this.timeZone,
        isQueryParam: true
      }
    ];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: emailRequest,
      headers: this.getHeaders(),
      urlParams
    });
  }
}
