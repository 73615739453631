import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { AnpSidePanelComponent } from './anpSidePanelCmp';
import { CommonAlertComponent } from './commonAlertCmp';
import { OONDetailsComponent } from './oonDetailsCmp';
import { SlideoutMessageComponent } from './slideoutMsgCmp';
export * from './commonAlertCmp';
@NgModule({
    declarations: [CommonAlertComponent, SlideoutMessageComponent,AnpSidePanelComponent, OONDetailsComponent],
    exports: [CommonAlertComponent, SlideoutMessageComponent, AnpSidePanelComponent, OONDetailsComponent],
    imports: [
        CommonModule,
        UxSharedModule.forRoot()
    ]
})
export class CommonAlertModule { }
