import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpMethod } from "../../../../../app/common/enums/httpMethodEnum";
import { BaseService } from "../../../../../app/common/services/baseService";
import { apiNameList } from "../../../../../environments/api-name-list";
import { HttpClientService } from "../../../../common/services/httpClientService";
import { AppUtility } from "../../../../common/utilities/appUtil";
import { AppSession } from "../../../../common/values/appSession";
import { IReportInvalid } from "../interfaces/iReportInvalid";

@Injectable({ providedIn: 'root' })
export class ReportInvalidService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  private _emailSent = new Subject<string>();

  get onEmailSent(): Observable<string> {
    return this._emailSent.asObservable();
  }

  public emailSentSuccessfully(message: string): void {
    this._emailSent.next(message);
  }

  public async reportInvalid(reportInvalid: IReportInvalid): Promise<boolean> {
    const response = await this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.reportInvalid,
      data: reportInvalid,
      headers: this.getHeaders()
    });

    return response?.status || false;
  }
}
