import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IRadioInput } from '@anthem/uxd/util';
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/internal/operators/catchError";
import { map } from "rxjs/internal/operators/map";
import { IRadioGroupModel } from "../../../../../common/components/radio-group/iRadioItem";
import { BILLING_TYPE, BOTH, PROFESSIONAL } from "../../../../../common/constants/app-constants";
import { IFinalSearchResponse } from "../../../../../common/interfaces/iCommonSearchResponse";
import { ICptMedicalResponse, PFSearchCostEstimateSidePanelVM, PFSearchCostInfo } from "../../../../../common/interfaces/iCptMedicalResponse";
import { DataHelper } from "../../../../../common/services/dataHelper";
import { EventHandler } from "../../../../../common/services/eventHandler";
import { AppSession } from "../../../../../common/values/appSession";
import { IProviderList } from "../../../../result/interfaces/iProvider";
import { CptSummaryModel } from "../../../../result/services/cptSummaryModel";
import { BaseComponent } from "../../core/baseCmp";


@Component({
  moduleId: module.id,
  selector: 'app-out-of-network-cost-estimate-cmp',
  templateUrl: './outOfNetworkCostEstimateCmp.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutOfNetworkCostEstimateComponent extends BaseComponent {
  outOfNetworkTabContent = this.content.result.cptSlideModalContent.outOfNetworkTab;
  isExpanded: boolean = true;
  professional: string;
  facility: string;
  selectedCostInfo: string;
  selectedCostInfoIndex = 0;
  isOONBothCost: boolean = false;
  costRadioGroup: IRadioGroupModel<IRadioInput> = {
    alignVertical: false,
    ariaLabel: '',
    id: 'pf-oon-cost-radio-group',
    isOptional: false,
    label: '',
    legendSrOnly: true,
    name: 'pf-oon-cost-radio-group',
    options: [],
    showLegendAsLbl: false,
  };
  billFrom: {
    label: string,
    value: string
  } | null = null;
  @Input() cptSearchResult: ICptMedicalResponse | IFinalSearchResponse = {} as ICptMedicalResponse | IFinalSearchResponse;

  vm$: Observable<PFSearchCostEstimateSidePanelVM[]> = this._cptSearchModel.cptSummaryResponse.pipe(
    map((value: IProviderList) => {
      const costInfos = value?.oonCostDetail?.costInfo || [];
      this.isOONBothCost = value?.oonCostDetail?.oonCostType?.toLowerCase() === BOTH;
      if (this.isOONBothCost) {
        const defaultValue = PROFESSIONAL;
        const options: IRadioInput[] = costInfos.map((cost) => {
          const billingClass = cost.billingClass?.toLowerCase();
          const label = this.outOfNetworkTabContent.costInfoRadioButton[billingClass];
          const analyticsText = this._dataHelper.getValueByKey<string>(billingClass, this.outOfNetworkTabContent.costInfoRadioButtonAnalytics, '');
          const option: IRadioInput = {
            label,
            value: billingClass,
            name: label,
            id: billingClass,
            ariaLabel: label,
            analyticsText
          };
          return option;
        });
        this.costRadioGroup.options = options;
        this.selectedCostInfo = defaultValue;
        this.setBillFromData(this.selectedCostInfo);
      } else {
        this.setBillFromData(costInfos[0].billingClass);
      }
      return costInfos.map((costInfo) => {
        const oonCostDetails: PFSearchCostInfo = {
          costInfo,
          modifierCode: value?.oonCostDetail?.modifier?.name,
          placeOfService: value?.oonCostDetail?.pos?.name
        };
        this.setValueForProfessionalAndFacility(this.cptSearchResult.medicalCode.billingType);
        const estimatedContent = this._cptSearchModel.costEstimateWithContent(oonCostDetails, this.outOfNetworkTabContent.OutOfNetworkCostEstimateContent.costContent, this.outOfNetworkTabContent.OutOfNetworkCostEstimateContent.costContentForPercentage);
        estimatedContent.costInfo = estimatedContent?.costInfo?.filter((data) => data?.name);
        return estimatedContent;
      })
    }),
    catchError((error) => of(error))
  );

  constructor( private _cptSearchModel: CptSummaryModel, private _dataHelper: DataHelper,
    route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession) {
    super(route, eventHandler, appSession);
  }

  setValueForProfessionalAndFacility(billingType: string): void {
    if (billingType === BILLING_TYPE.MSDRG) {
      this.professional = this.outOfNetworkTabContent.OutOfNetworkCostEstimateContent.notSelected;
      this.facility = this.outOfNetworkTabContent.OutOfNetworkCostEstimateContent.selected;
    }
    else {
      this.professional = this.outOfNetworkTabContent.OutOfNetworkCostEstimateContent.selected;
      this.facility = this.outOfNetworkTabContent.OutOfNetworkCostEstimateContent.notSelected;
    }
  }

  setBillFromData(billingClass: string): void {
    const billFromContent = this.outOfNetworkTabContent.billFrom;
    this.billFrom = {
      label: billFromContent.label,
      value: billFromContent.value[billingClass.toLowerCase()]
    };
  }

  onCostInfoRadioBtnChange() {
    this.selectedCostInfoIndex = this.costRadioGroup.options.findIndex(({ id }) => id === this.selectedCostInfo);
    this.setBillFromData(this.selectedCostInfo);
  }
}
