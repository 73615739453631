import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IServiceLimit } from '../../../../../common/interfaces/iCptMedicalResponse';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { IProviderList } from '../../../../result/interfaces/iProvider';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-summary-tab-cmp',
  templateUrl: './summaryTabCmp.html'
})
export class SummaryTabComponent extends BaseComponent {
  @Input() cptSearchResult: IProviderList;
  isExpanded: boolean = true;
  serviceLimits: Array<IServiceLimit>;
  constructor(
    route: ActivatedRoute, eventHandler: EventHandler, appSession: AppSession) {
    super(route, eventHandler, appSession);
  }

  ngOnInit(): void {
    if (this.cptSearchResult?.hasServiceLimit) {
      this.serviceLimits = this.cptSearchResult?.serviceLimits;
    }
  }
}
