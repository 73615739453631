import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CareCircleContainerComponent } from '../care-circle/components/careCircleContainerCmp';
import { PFCareNowContainerComponent } from '../care-now/components/pfCareNowContainerCmp';
import { PFAssignPcpContainerComponent } from '../fad/assign-pcp/components/pfAssignPcpContainerCmp';
import { PFServiceAreaValidationComponent } from '../fad/search-providers/components/pfServiceAreaValidationCmp';
import { PFSearchResultsContainerComponent2 } from '../fad/search-results/components/pfSearchResultsContainerCmp2';
import { PFVirtualCareContainerComponent } from '../virtual-care/components/pfVirtualCareContainerCmp';
import { AppErrorComponent } from './../common/components/app-error/appErrorCmp';
import { AppMaintenanceComponent } from './../common/components/app-maintenance/app-maintenance.component';
import { EmulateCostComponent } from './../common/components/app-secure-cost/components/emulateCostCmp';
import { EmulateSecureStateComponent } from './../common/components/app-secure/components/emulateSecureStateCmp';
import { AuthGuardService } from './../common/services/authGuardService';
import { WcsContentResolver } from './../common/services/wcsContentResolver';
import { PFCostSearchContainerComponent } from './../eyc/cost-search/components/pfCostSearchContainerCmp';
import { PFProviderDetailsContainerComponent } from './../fad/provider-details/components/pfProviderDetailsContainerComponent';
import { PFSearchHomeComponent } from './../fad/search-providers/components/pfSearchHomeCmp';
import { PFPromotedProceduresContainerComponent } from './../kodiak-procedure/promoted-procedures/components/pfPromotedProceduresContainerCmp';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PFSearchHomeComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'search-providers',
    component: PFSearchHomeComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'service-area-validation',
    component: PFServiceAreaValidationComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'search-results-next',
    component: PFSearchResultsContainerComponent2,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'provider-details',
    component: PFProviderDetailsContainerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'cost-search',
    component: PFCostSearchContainerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'care-circle',
    component: CareCircleContainerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'promoted-procedures',
    component: PFPromotedProceduresContainerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'emulate-secure-state',
    component: EmulateSecureStateComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'emulate-cost',
    component: EmulateCostComponent,
    canActivate: [],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'app-maintenance',
    component: AppMaintenanceComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'error',
    component: AppErrorComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'virtual-care',
    component: PFVirtualCareContainerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'assign-pcp',
    component: PFAssignPcpContainerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  },
  {
    path: 'care-now',
    component: PFCareNowContainerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      content: WcsContentResolver
    }
  }
];

@NgModule({
  declarations: [],
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })
  ]
})
export class AppRoutingModule { }
