import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { VirtualCareURLSSORequest } from '../interfaces/iVirtualCareUrlSSO';
import { RedirectUrlResponse } from '../interfaces/magicLinkVpcItemTypes';
@Injectable({ providedIn: 'root' })
export class VirtualCareSSOUrlSvc extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getVirtualCareSSOUrl(reqObj: VirtualCareURLSSORequest): Promise<RedirectUrlResponse> {
    return this.httpClientSvc.request({
      cacheable: false,
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.virtualCareSSOURL,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
