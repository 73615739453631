import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { AppEvents } from '../../../common/enums/appEvents';
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { AppSession } from '../../../common/values/appSession';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { IPageTitle } from '../../common/interfaces/iPageTitle';
import { ISearchParameters } from './../../../fad/search-providers/interfaces/iSearchParameters';

@Component({
  moduleId: module.id,
  selector: 'app-fc-home-cmp',
  templateUrl: './homeCmp.html'
})
export class HomeComponent extends BaseComponent implements OnInit {
  searchParams: ISearchParameters;
  showLeavingDisclaimerModal = false;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this.onPreLoad();
    this.setPageHeader();
    this.setSearchParameters();
  }

  setPageHeader() {
    if (this.content && this.content.common && this.content.common.pageHeader && this.content.common.pageTitle) {
      const pageTitleObj: IPageTitle = {
        header: this.content.common.pageHeader.fadPageTitle,
        title: this.content.common.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageTitleObj);
    }
  }

  setSearchParameters() {
    this.searchParams = this._appSession.searchParams ?? {};
    this._appSession.searchParams = this.searchParams;
  }

  navigateToLanding() {
    this._navigationService.navigateByUrl(AppNavigations.FCR_LANDING_PATH);
  }

  openLeavingDisclaimerModal(url: any) {
    this.showLeavingDisclaimerModal = true;
  }
}
