import { Injectable } from '@angular/core';
import { apiNameList } from '../../../environments/api-name-list';
import { CommonUtil } from '../../fad/utilities/commonUtil';
import { HttpMethod } from '../enums/httpMethodEnum';
import { ICptMedicalRequest } from '../interfaces/iCptMedicalRequest';
import { ICptMedicalResponse } from '../interfaces/iCptMedicalResponse';
import { IUrlParam } from '../interfaces/iUrlParam';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';

@Injectable({
  providedIn: 'root',
})

export class SecureV1CptMedical extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(searchReq: ICptMedicalRequest): Promise<ICptMedicalResponse> {
    const apiUrl = apiNameList.restApi.secureV1CptMedical;
    let urlParams: IUrlParam[] = [];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: searchReq,
      headers: this.getHeaders(),
      urlParams
    });
  }
}
