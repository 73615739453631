import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ICptSearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { ICptMedicalEmailRequest } from '../../../common/interfaces/iCptMedicalEmailRequest';
import { ICptMedicalRequest } from '../../../common/interfaces/iCptMedicalRequest';
import { ICptMedicalResponse } from '../../../common/interfaces/iCptMedicalResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { SecureV1CptMedical } from '../../../common/services/secureV1CptMedicalSvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { API_NAME } from '../../../fad/search-results/values/providerSearchConstants';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../../fad/utilities/searchResponseUtil';
import { IAddressSummary, IProvider, IProviderList } from '../../result/interfaces/iProvider';
import { CptSummaryModel } from '../../result/services/cptSummaryModel';

@Injectable({
  providedIn: 'root'
})
export class SecureCptAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = environment.paginationSize.summaryPrintPage;

  constructor(
    appSession: AppSession,
    private _secureCptSvc: SecureV1CptMedical,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility,
    httpClientSvc: HttpClientService,
    public cptSummaryModel: CptSummaryModel
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    let apiResponse = await this._secureCptSvc.execute(transformedRequest);
    this.appSession.medicalCodeDescription = apiResponse?.medicalCode?.description;
    const transformedResponse = this.transformResponse(apiResponse);
    this.cptSummaryModel.setCptSummaryResponse(transformedResponse);
    return transformedResponse;
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent }>(content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ICptMedicalRequest = { ...searchRequest };
    const apiName = SearchRequestUtil.getApiName(API_NAME.SECURE_CPT_MEDICAL);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent }>(email: string, content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ICptMedicalEmailRequest = { ...searchRequest, email };
    const apiName = SearchRequestUtil.getApiName(API_NAME.SECURE_CPT_MEDICAL);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ICptSearchFilterRequest {
    const cptMedicalSecureSearchReq = this.transformRequest(pageNumber, pageSize);
    const filterReq: ICptSearchFilterRequest = {
      ...cptMedicalSecureSearchReq,
      scenario: SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): ICptMedicalRequest {
    return SearchRequestUtil.buildSecureCptRequest(this.appSession, pageNumber, pageSize);
  }

  private transformResponse(apiResponse: ICptMedicalResponse) {
    const response = {} as IProviderList;
    const finalProviders: IProvider[] = [];
    if (apiResponse) {
      response.numberOfServiceLimits = apiResponse.numberOfServiceLimits;
      response.pageNumber = apiResponse.pageNumber;
      response.pageSize = apiResponse.pageSize;
      response.totalPages = apiResponse.totalPages;
      response.totalRecords = apiResponse.totalRecords;
      response.defaultSort = apiResponse.sortBy;
      response.isHPNPlan = apiResponse.isHPNPlan;
      response.isPrimeGroupSearchResponse = apiResponse.isPrimeGroupSearchResponse;
      response.sortOptions = apiResponse.sortOptions;
      response.rules = apiResponse.rules;
      response.isPreventiveCare = apiResponse.isPreventiveCare;
      response.hasOonCostDetail = apiResponse.hasOonCostDetail;
      response.hasServiceLimit = apiResponse.hasServiceLimit;
      response.isPreapprovalRequired = apiResponse.isPreapprovalRequired;
      response.pos = apiResponse.pos;
      response.posInfos = apiResponse.posInfos;
      response.medicalCode = apiResponse.medicalCode;
      response.modifier = apiResponse.modifier;
      response.remainingDeductible = apiResponse.remainingDeductible;
      response.oonCostDetail = apiResponse.oonCostDetail;
      response.serviceLimits = apiResponse.serviceLimits;
      response.orthoTypeFlag = apiResponse.orthoTypeFlag;
      response.missingToothPeriodFlag = apiResponse.missingToothPeriodFlag;
      response.accntBalanceInfo = apiResponse.accntBalanceInfo;
      response.onsiteClinics = apiResponse?.onsiteClinics ?? undefined;
      response.hasErrors = apiResponse.hasErrors;
      response.hasDentalServiceLimits = apiResponse.hasDentalServiceLimits;
      if (apiResponse.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            finalProvider.costInfo = provider.costInfo;
            const { address } = provider.location;
            const { taxonomies } = provider.specialty;
            finalProvider.addressSummary = {
              phoneNumberList: [address.phone],
              taxonomies: taxonomies.map(({ code, name }) => ({ code, name })),
              addressIdentifier: address.addressId,
              addressOne: address.addressOne,
              addressTwo: address.addressTwo,
              cityName: address.city,
              stateCode: address.state,
              postalCode: address.postalCode,
              latitude: address.latitude,
              longitude: address.longitude,
              distance: address.distance?.toString()
            } as IAddressSummary;
            finalProviders.push(finalProvider);
          }
        });
      }
    }
    response.providers = finalProviders;

    return response;
  }
}
