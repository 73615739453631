import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IRadioInput } from '@anthem/uxd/util';
import { ACTION_TAKEN, REASONTYPE_FREEFORM, SIDE_PANEL_STATUS } from '../../../../../common/constants/app-constants';
import { IActionId, IUserInteraction } from '../../../../../common/interfaces/iUserInteraction';
import { DataHelper } from '../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { IActionReason, IFeedbackRadioInput } from '../../../interfaces/iActionReasonCode';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-feedback-side-panel-cmp',
  templateUrl: './feedbackSidePanelCmp.html'
})
export class FeedBackSidePanelComponent extends BaseComponent implements OnInit {
  @Input() reasonCodeList: Array<IActionReason> = [];
  @Input() interactionPayload: IUserInteraction;
  @Output('feedbackSidePanelStatus') readonly sidePanelStatus = new EventEmitter<SIDE_PANEL_STATUS>();

  feedbackSidePanelRef: ModalRef<HTMLElement>;
  @ViewChild('feedbackPanel') feedbackSidePanel: TemplateRef<HTMLElement>;

  reasonCodeHeader: string;
  reasonCodeDescription: string;
  selectedReasonCodeId = '';
  otherReasonCodeText = '';
  positiveFeedBack = false;
  showReasonTextBox = false;
  reasonCodeOptions: Array<IRadioInput>;
  sendFeedback: string;
  cancelFeedback: string;
  backArrowTile: string;
  feedBackSidePanelContent = this.content?.common?.hideQuickLinkShopForProcedure;

  constructor(
    private _sidePanel: SidePanel,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _dataHelper: DataHelper,
    _eventHandler: EventHandler,
    _route: ActivatedRoute
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  /**
   * Open the feedback side panel for the user to provide feedback
   * @param reasonCodeList List of reason codes to be displayed in the feedback side panel
   * @param positiveFeedBack Flag to check if the feedback is positive or negative
   */
  openSlideOutPanel(reasonCodeList: Array<IActionReason>, positiveFeedBack: boolean) {
    this.selectedReasonCodeId = reasonCodeList[0].actionReasonCode;
    this.otherReasonCodeText = '';
    this.reasonCodeOptions = reasonCodeList.map<IFeedbackRadioInput>((reasonCode) => ({
      id: 'reason-code-id-' + reasonCode.actionReasonCode,
      name: 'reason-code-name-' + reasonCode.actionReasonCode,
      value: reasonCode.actionReasonCode,
      label: reasonCode.actionReasonMsg,
      actionReasonType: reasonCode.actionReasonType
    }));
    this.positiveFeedBack = positiveFeedBack;
    const { iAmInterested, notInterested } = this.feedBackSidePanelContent || {};
    this.sendFeedback = this.positiveFeedBack ? iAmInterested?.dataAnalytics?.sendFeedbackIntModalFindCare : notInterested?.dataAnalytics?.sendFeedbackNotIntModalFindCare;
    this.cancelFeedback = this.positiveFeedBack ? iAmInterested?.dataAnalytics?.cancelFeedbackIntModalFindCare : notInterested?.dataAnalytics?.cancelFeedbackNotIntModalFindCare;
    this.backArrowTile = this.positiveFeedBack ? iAmInterested?.dataAnalytics?.backFeedbackIntModalFindCare : notInterested?.dataAnalytics?.backFeedbackNotIntModalFindCare;
    this.reasonCodeHeader = this.positiveFeedBack ? iAmInterested?.header : notInterested?.header;
    this.reasonCodeDescription = this.positiveFeedBack ? iAmInterested?.description : notInterested?.description;
    this.feedbackSidePanelRef = this._sidePanel.open('right', this.feedbackSidePanel);
    this.sidePanelStatus.emit(SIDE_PANEL_STATUS.OPEN);
  }

  /**
   * Close the feedback side panel
   */
  onClose() {
    this.feedbackSidePanelRef.close();
    this.showReasonTextBox = false;
    this.sidePanelStatus.emit(SIDE_PANEL_STATUS.CLOSE);
  }

  /**
   * Reason code change event handler
   * @param reason Selected reason code
   */
  onReasonCodeChange(reason: IFeedbackRadioInput) {
    this.selectedReasonCodeId = reason.value;
    this.showReasonTextBox = reason.actionReasonType === REASONTYPE_FREEFORM;
  }

  /**
   * Get the interaction payload for the user interaction
   * @returns Interaction payload for the user interaction
   */
  getInteractionPayLoad(): string | undefined {
    let actionIdObj = {} as IActionId;
    this.interactionPayload.actionTaken = this.positiveFeedBack ? ACTION_TAKEN.acknowledged : ACTION_TAKEN.archived;
    actionIdObj = this.interactionPayload;
    actionIdObj.actionReasonCode = this.selectedReasonCodeId;
    if (!this._dataHelper.isEmptyString(this.otherReasonCodeText)) {
      actionIdObj.actionReasonTxt = this.otherReasonCodeText;
    }
    return this.userInteractionUtil.getUserInteraction(actionIdObj, this.interactionPayload);
  }
}
