import { Component, EventEmitter, Inject, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "@anthem/uxd/deprecated";
import { BaseComponent } from "../../../common/components/base-component/baseCmp";
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { ProviderOrchestrationService } from '../../provider-details/services/providerOrchestrationSvc';
import { INetworkId, IServiceAreaValidationRequest, IServiceAreaValidationResponse } from "../interfaces/iServiceAreaValidation";
import { ADDRESS_SELECT } from './../../../common/constants/app-constants';
import { IAddressDetails, IAddressForm } from './../interfaces/iAddressForm';
import { IInputData } from './../interfaces/iServiceAreaValidation';

@Component({
  moduleId: module.id,
  selector: "app-fad-location-form-cmp",
  templateUrl: "../views/pfLocationFormCmp.html",
})
export class PFLocationFormComponent extends BaseComponent {
  @Output()
  gotoSearchCriteria: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  gotoPlanSelection: EventEmitter<any> = new EventEmitter<any>();
  formValid: boolean;
  continueClicked: boolean = false;
  addressObj = {} as IAddressDetails;
  showServiceAreaValidateLoader: boolean = false;
  @ViewChild('serviceAreaValidationError')
  serviceAreaValidationModal: ModalComponent;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _navigationService: NavigationService,
    private _providerOrchestrationSvc: ProviderOrchestrationService,
    @Inject(AppSession)
    private _appSession: AppSession,
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchContainerComponent"
    );
  }

  ngOnInit() {
    this.formValid = this._appSession.addressObj?.homeAddress?.isFormValid && (this._appSession.addressObj?.workAddress?.isFormValid === false ? this._appSession.addressObj?.workAddress.isFormValid : true) || false;
  }

  goToSearchCriteria() {
    this.showServiceAreaValidateLoader = true;
    if (this._appSession.customAddressSearch) {
      this._appSession.customAddressSearch = false;
    }
    this.continueClicked = true;
    let req = {} as IServiceAreaValidationRequest;
    req.inputData = {} as IInputData;
    req.inputData.networkIds = {} as INetworkId;
    req.inputData.networkIds.networkId = this._appSession?.networkList || [];
    req.inputData.srcSystem = this._appSession.metaData.brandCd;
    this._providerOrchestrationSvc.getServiceAreaValidation(req).then((res: IServiceAreaValidationResponse) => {
      this.showServiceAreaValidateLoader = false;
        this._appSession.searchParams.streetAddress = this._appSession.addressObj.homeAddress.streetAddress
        this._appSession.searchParams.addressLine2 = this._appSession.addressObj.homeAddress?.addressLine2
        this._appSession.searchParams.city = this._appSession.addressObj.homeAddress.city
        this._appSession.searchParams.stateCd = this._appSession.addressObj.homeAddress.state
        this._appSession.searchParams.postalCode = this._appSession.addressObj.homeAddress.zipCode;
        this._appSession.searchParams.zip = this._appSession.addressObj.homeAddress.zipCode;
        this._appSession.addressObj.addressModel = ADDRESS_SELECT.HOME;
        this.gotoSearchCriteria.emit(true);
    }).catch((err) => {
      if (err) {
        this.showServiceAreaValidateLoader = false;
        this.showPopup();
      }
    });
  }

  submitForm(data: IAddressForm) {
    data.isHomeAddress ? this._appSession.addressObj.homeAddress = data : this._appSession.addressObj.workAddress = data;
    this.formValid = data?.isFormValid && this._appSession.addressObj?.homeAddress?.isFormValid && (this._appSession.addressObj?.workAddress?.isFormValid === false ? this._appSession.addressObj?.workAddress.isFormValid : true);
  }

  showPopup() {
    if (typeof this.serviceAreaValidationModal.config === 'undefined') {
      this.serviceAreaValidationModal.config = { backdrop: false };
    }
    this.serviceAreaValidationModal.show();
  }

  hidePopup() {
    this.serviceAreaValidationModal.hide();
  }

  loadPlanSelection() {
    this._appSession.addressObj = {} as IAddressDetails;
    this._appSession.isHcidSearch = false;
    this.gotoPlanSelection.emit();
  }

}
