import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../common/values/contentHelper';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  selector: 'app-fc-look-up-container-cmp',
  templateUrl: './lookUpContainerCmp.html'
})
export class LookUpContainerCmpComponent extends BaseComponent implements OnInit {
  @Input() parentWidth: HTMLElement;
  @Input() customCSS = '';
  @Input() warningMessage = false;
  warningMessageData: string;
  boxWidth: number;

  constructor(
    @Inject(AppSession)
    _appSession: AppSession,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    this.warningMessageData = this.content.common.pageHeader.searchCriteria.alerts.zipcodeEmptyError;
    if (this.waitUntilAppReload) {
      return;
    }

    if (typeof this.parentWidth !== 'undefined' && this.parentWidth !== null) {
      this.boxWidth = this.parentWidth.clientWidth;
    }
  }
}
