import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '../../../../libs/uxd-shared/uxdSharedModule';
import { CommonDirectivesModule } from './../../directives/commonDirectivesModule';
import { PCPService } from './../../services/pcpSvc';
import { CaHmoPanelComponent } from './caHmoPanelCmp';
import { PCPIdSlideOutComponent } from './pcpIdSlideOutCmp';
import { AssignPcpComponent } from './pfAssignPcpCmp';
import { PcpIdListComponent } from './pfPcpIdListCmp';

@NgModule({
    declarations: [AssignPcpComponent, PcpIdListComponent, CaHmoPanelComponent, PCPIdSlideOutComponent],
    exports: [AssignPcpComponent, PcpIdListComponent, CaHmoPanelComponent, PCPIdSlideOutComponent],
    imports: [
        FormsModule, ReactiveFormsModule,
        UxSharedModule,
        CommonModule,
        CommonDirectivesModule
    ],
    providers: [PCPService]
})
export class AssignPcpModule { }
