import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { MemberPlanProgressComponent } from './memberPlanProgressCmp';

@NgModule({
  declarations: [MemberPlanProgressComponent],
  imports: [CommonModule, UxSharedModule.forRoot(), FormsModule],
  exports: [MemberPlanProgressComponent],
  providers: []
})
export class MemberPlanProgressModule {}
