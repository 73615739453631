import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { FixedTrayComponent } from './fixed-tray.component';
@NgModule({
    declarations: [FixedTrayComponent],
    exports: [FixedTrayComponent],
    imports: [
        CommonModule,
        UxSharedModule,
    ],
    providers: []
})
export class FixedTrayModule { }
