import { Injectable } from '@angular/core';
import { BaseService } from '../../../../common/services/baseService';
import { IGeoLocation, IGeoLocationInfo, IGeoLocationInfoWithCounties, ILocationSearchRequest } from '../models/geoLocation';

import { isEmpty } from 'lodash';
import { DataHelper } from '../../../../common/services/dataHelper';
import { HttpClientService } from '../../../../common/services/httpClientService';
import { AppUtility } from '../../../../common/utilities/appUtil';
import { AppSession } from '../../../../common/values/appSession';
import { LocationSvc } from './locationSvc';

@Injectable()
export class LocationAdapterSvc extends BaseService {
  constructor(
    private _appSession: AppSession,
    private _locationSvc: LocationSvc,
    private _appUtility: AppUtility,
    private _httpClientSvc: HttpClientService,
    private _dataHandler: DataHelper
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  async getLocationLookup(input: string): Promise<IGeoLocationInfoWithCounties> {
    const apiResponse: IGeoLocationInfoWithCounties = await this._locationSvc.getLocationLookup(input);
    return apiResponse;
  }

  async getFullAddressDetails(geoLocation: IGeoLocation): Promise<IGeoLocationInfo> {
    const locationSearchReq: ILocationSearchRequest = this.buildLocationRequest(geoLocation);
    const apiResponse: IGeoLocationInfo = await this._locationSvc.getFullAddressDetails(locationSearchReq);
    return apiResponse;
  }

  async getLocationByGeoCode(lat: string, long: string): Promise<IGeoLocationInfo> {
    const apiResponse: IGeoLocationInfo = await this._locationSvc.getLocationByGeoCode({ latitude: lat, longitude: long });
    return apiResponse;
  }

  buildLocationRequest(geoLocation: IGeoLocation): ILocationSearchRequest {
    let locationSearchReq: ILocationSearchRequest = {};
    if (!isEmpty(geoLocation)) {
      locationSearchReq = {
        addressLine1: geoLocation.addressLine1,
        addressLine2: geoLocation.addressLine2,
        zipcode: geoLocation.zipcode,
        state: geoLocation.stateCd,
        county: geoLocation.countyCode,
        city: geoLocation.city,
      };
    }
    return locationSearchReq;
  }
}
