import { Injectable } from '@angular/core';
import { apiNameList } from '../../../environments/api-name-list';
import { HttpMethod } from '../enums/httpMethodEnum';
import { IPublicSpecialtySearchRequest } from '../interfaces/iPublicSpecialtySearchRequest';
import { IPublicSpecialtySearchResponse } from '../interfaces/iPublicSpecialtySearchResponse';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';

@Injectable({
  providedIn: 'root',
})
export class PublicV1Specialty extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(searchReq: IPublicSpecialtySearchRequest): Promise<IPublicSpecialtySearchResponse> {
    const apiUrl = apiNameList.restApi.publicV1Specialty;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: searchReq,
      headers: this.getHeaders()
    });
  }
}
