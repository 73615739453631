import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import {
  ILocationAddress,
  ILocationsResponse,
} from '../../../fad/provider-details/models/iLocations';
import { ProviderOrchestrationService } from '../../../fad/provider-details/services/providerOrchestrationSvc';
import { IMapRequest } from '../../../fad/search-results/interfaces/iMapRequest';
import { TravelTimeRequest } from '../../../fad/search-results/interfaces/iSearchRequest';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { RIGHT } from '../../constants/app-constants';
import { IFinalProvider } from '../../interfaces/iCommonSearchResponse';
import { IGeoTravelTime, ILocationDropDown, ITravelDuration } from '../../models/locationAddress';
import { PhonePatternPipe } from '../../pipes/phonePatternPipe';
import { CommmunicationService } from '../../services/communicationSvc';
import { DataHelper } from '../../services/dataHelper';
import { EventHandler } from '../../services/eventHandler';
import { SliderService } from '../../services/sliderSvc';
import { AppUtility } from '../../utilities/appUtil';
import { LocationUtil } from '../../utilities/locationUtil';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-locations-slide-out-cmp',
  templateUrl: './locationsSlideOutCmp.html',
})
export class LocationsSlideOutComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @ViewChild('viewLocationsSlider')
  viewLocationsSlider: TemplateRef<HTMLElement>;
  @Input()
  provider: IFinalProvider;
  @Input()
  index: number;
  @Input()
  showLocationOnSubscription: boolean;
  sidePanelRef: ModalRef<HTMLElement>;
  locationsList: ILocationAddress[] = [];
  locations: ILocationAddress[] = [];
  locationDdlValues: ILocationDropDown[] = [];
  selectedAddressId = '';
  showDistance = true;
  providerName = '';
  mapLocations: IMapRequest[] = [];
  showLocationsError = false;
  showAlertMessage = true;
  showLoader = true;
  showMapLoader = true;
  longitude = '';
  latitude = '';
  private _sliderSubscription: Subscription;

  constructor(
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _contentHelper: ContentHelper,
    private _sidePanel: SidePanel,
    private _providerOrchestrationService: ProviderOrchestrationService,
    private _phonePatternPipe: PhonePatternPipe,
    private _communicationsService: CommmunicationService,
    private _appUtility: AppUtility,
    private _locationUtil: LocationUtil,
    private _sliderService: SliderService,
    private _dataHelper: DataHelper,
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      'PFSearchResultsContainerComponent'
    );
  }

  ngOnDestroy(): void {
    if (this._sliderSubscription) {
      this._sliderSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.providerName = this.provider?.providerName ?? '';
    this.latitude = (
      this._appSession?.searchParams?.coordinates?.latitude ?? ''
    )
      .toString()
      .trim();
    this.longitude = (
      this._appSession?.searchParams?.coordinates?.longitude ?? ''
    )
      .toString()
      .trim();
    this.showDistance = this.latitude !== '' && this.latitude !== '0' && this.longitude !== '' && this.longitude !== '0';
    if (this.showLocationOnSubscription) {
      this._sliderSubscription = this._sliderService.locationSliderObs.subscribe((provider) => {
        this.openLocationsSlider();
      });
    }
  }

  get showProviderServiceLocationName(): boolean {
    return this._appSession?.feature?.showServiceLocationName || false;
  }

  openLocationsSlider(): void {
    this.showAlertMessage = !SearchRequestUtil.isOutOfNetwork(this._appSession);
    this.sidePanelRef = this._sidePanel.open(RIGHT, this.viewLocationsSlider);
    this.getLocations();
  }

  closeSlider(): void {
    this.sidePanelRef?.close();
  }

  setLocation(): void {
    this.showMapLoader = true;
    this.getTravelTime();
  }

  getLocations(): void {
    const locationsRequest = this._locationUtil.buildLocationRequest(this.provider);
    this._providerOrchestrationService
      .getProviderLocations(locationsRequest)
      .then(
        (result: ILocationsResponse) => {
          if (Array.isArray(result?.locations) && result.locations.length) {
            this.locationsList = result.locations;
            this.buildLocationDropDown();
          }
        },
        (error) => {
          this.showLoader = false;
          this.showLocationsError = true;
        }
      );
  }

  buildLocationDropDown(): void {
    this.locationDdlValues = [];
    this.showLoader = false;
    this.locationsList.forEach((location, ind) => {
      const addressText = location.addressOne + ' ' + location.addressTwo;
      const index = this.locationDdlValues.findIndex((x) => x.label === addressText);
      if (index === -1) {
        const locationValue = { label: addressText, value: location.addressId };
        this.locationDdlValues.push(locationValue);
      }
    });
    const addressID = this.provider?.location?.address?.addressId ?? '';
    const locationIndex = this.locationsList.findIndex((x) => x.addressId === addressID);
    this.selectedAddressId = this.locationsList[locationIndex]?.addressId;
  }

  getTravelTime(): void {
    let travelTimeRequest = new TravelTimeRequest();
    if (
      this.latitude !== '0' &&
      this.latitude !== '' &&
      this.longitude !== '0' &&
      this.longitude !== ''
    ) {
      travelTimeRequest.origins = this.latitude + ',' + this.longitude;
    }
    travelTimeRequest.destinations = '';
    if (this.locationsList && this.locationsList.length) {
      this.locationsList.forEach((location, index) => {
        if (location.latitude !== '' && location.longitude !== '') {
          let coordinates =
            parseFloat(location.latitude).toString() +
            ',' +
            parseFloat(location.longitude).toString();
          // binding distinct destinations lat/long
          if (travelTimeRequest.destinations.indexOf(coordinates) === -1) {
            let seperator = index > 0 ? ';' : '';
            travelTimeRequest.destinations += seperator;
            travelTimeRequest.destinations += coordinates;
          }
        }
      });
      if (
        travelTimeRequest.origins &&
        travelTimeRequest.origins !== '' &&
        travelTimeRequest.destinations &&
        travelTimeRequest.destinations !== ''
      ) {
        this._providerOrchestrationService
          .getGeoTravelTime(travelTimeRequest)
          .then(
            (travelTimeResp: IGeoTravelTime) => {
              if (
                travelTimeResp &&
                travelTimeResp.travelDuration &&
                travelTimeResp.travelDuration.length > 0
              ) {
                let drivingDurationResp = this.bindTravelTime(
                  travelTimeResp.travelDuration,
                  this.locationsList
                );
                this.bindLocationTravelTime(drivingDurationResp);
              } else {
                this.bindLocationTravelTime(this.locationsList);
              }
            },
            (error) => {
              this.bindLocationTravelTime(this.locationsList);
            }
          );
      } else {
        this.bindLocationTravelTime(this.locationsList);
      }
    }
  }

  bindLocationTravelTime(locations: ILocationAddress[]): void {
    const location = CommonUtil.buildMapDataFromLocation(
      this.providerName,
      locations
    );
    const mapReq = CommonUtil.setMapRequest(location, true, this.showSPFI);
    this.mapLocations = mapReq.filter(
      (x) => x.addressId === this.selectedAddressId
    );
    this.locations = locations.filter(
      (location) => location.addressId === this.selectedAddressId
    );
    this.showMapLoader = false;
  }

  bindTravelTime(travelTimeResp: ITravelDuration[], locations: ILocationAddress[]): ILocationAddress[] {
    locations.forEach((location) => {
      if (location.latitude && location.longitude) {
        const matchedResult = travelTimeResp.filter((resp) => {
          return (
            resp.latitude.toString() ===
              parseFloat(location.latitude).toString() &&
            resp.longitude.toString() ===
              parseFloat(location.longitude).toString()
          );
        });
        if (matchedResult && matchedResult.length > 0) {
          location.travelTime = matchedResult[0].travelTime;
        } else {
          location.travelTime = 0;
        }
      }
    });
    return locations;
  }

  formatPhone(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }

  onDownloadVCard(): void {
    const request = this._locationUtil.buildVcardRequest(this.selectedAddressId, this.provider);
    this._communicationsService.getVcard(request).then((result: any) => {
      this._appUtility.saveVcard(result, this.provider?.providerIdentifier);
    });
  }

  openGetDirectionsModal(location: ILocationAddress): void {
    if (!this._dataHelper.isEmptyObject(location)) {
      const providerForDirection = this.buildProviderForDirection(location);
      if (!this._dataHelper.isEmptyObject(providerForDirection)) {
        this._sliderService.openSlideOut(providerForDirection);
      }
    }
  }

  dismissAlert(): void {
    this.showAlertMessage = false;
  }

  buildProviderForDirection(location: ILocationAddress): IFinalProvider {
    let professional: IFinalProvider;
    if (this.provider?.location?.address) {
      professional = cloneDeep(this.provider);
      professional.location.address.addressId = location.addressId;
      professional.location.address.addressOne = location.addressOne;
      professional.location.address.addressTwo = location.addressTwo;
      professional.location.address.latitude = location.latitude;
      professional.location.address.longitude = location.longitude;
    }
    return professional;
  }

  printDrivingDirection(): void {
    window.print();
  }
}
