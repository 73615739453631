import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CustomPipesModule } from '../../common/pipes/customPipesModule';
import { CommonAlertModule } from './../../common/components/common-alert/commonAlertModule';
import { RadioGroupModule } from './../../common/components/radio-group/radioGroupModule';
import { CommonDirectivesModule } from './../../common/directives/commonDirectivesModule';
import { MemberPcpComponent } from './components/memberPcpCmp';
import { PFAssignPcpContainerComponent } from './components/pfAssignPcpContainerCmp';
import { AssignPcpV2Component } from './components/pfAssignPcpV2Cmp';
import { AssignPcpNavigationService } from './services/assignPcpNavigationSvc';
import { MbrAgeLimitApiHelper } from './services/mbrAgeLimitApiHlpr';
import { MemberPcpDetailsApiHelper } from './services/memberPcpDetailsApiHlpr';
import { MemberPcpDetailsService } from './services/memberPcpDetailsSvc';
import { MemberPcpValidateApiHelper } from './services/memberPcpValidateApiHlpr';
import { ModifyPcpApiHelper } from './services/modifyPcpApiHlpr';
import { PcpReasonCodesApiHelper } from './services/pcpReasonCodesApiHlpr';
import { ProviderPcpInfoApiHelper } from './services/providerPcpInfoApiHlpr';
import { ProviderPcpInfoV2ApiHelper } from './services/providerPcpInfoV2ApiHlpr';
import { Stepper } from './services/stepper';

@NgModule({
  imports: [
    UxSharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CommonAlertModule,
    CustomPipesModule,
    RadioGroupModule,
    CommonDirectivesModule
  ],
  declarations: [PFAssignPcpContainerComponent, AssignPcpV2Component, MemberPcpComponent],
  exports: [PFAssignPcpContainerComponent, AssignPcpV2Component, MemberPcpComponent],
  providers: [ModifyPcpApiHelper, MemberPcpValidateApiHelper, MemberPcpDetailsApiHelper, MemberPcpDetailsService, AssignPcpNavigationService, Stepper,
    ProviderPcpInfoApiHelper, PcpReasonCodesApiHelper, MbrAgeLimitApiHelper,{
      provide: ProviderPcpInfoApiHelper,
      useClass: ProviderPcpInfoV2ApiHelper
    }]
})
export class PFAssignPcpV2Module {
  static forRoot(): ModuleWithProviders<PFAssignPcpV2Module> {
    return {
      ngModule: PFAssignPcpV2Module,
      providers: []
    }
  }
}
