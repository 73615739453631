import { Injectable } from "@angular/core";
import { apiNameList } from "../../../../environments/api-name-list";
import { HttpMethod } from "../../../common/enums/httpMethodEnum";
import { BaseService } from "../../../common/services/baseService";
import { HttpClientService } from "../../../common/services/httpClientService";
import { AppUtility } from "../../../common/utilities/appUtil";
import { AppSession } from "../../../common/values/appSession";
import { IProviderDetailsRequest } from "../models/iProviderDetailsRequest";
import { IProviderDetailsResponse } from "../models/iProviderDetailsResponse";

@Injectable({
  providedIn: "root",
})
export class ProviderDetailsV1Service extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(request: IProviderDetailsRequest): Promise<IProviderDetailsResponse> {
    const apiUrl = apiNameList.restApi.detailsV1;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: request,
      headers: [
        {
          name: "Content-Type",
          value: "application/json",
        },
      ],
    });
  }
}
