import { Inject, Injectable } from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { HasEventTargetAddRemove, fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounce } from 'rxjs/operators';
import { CommonUtil } from '../../fad/utilities/commonUtil';
import { FindCareFeature } from '../../findcare/common/enums/findCareFeature';
import { FeatureManagerUtility } from '../../findcare/common/utilities/featureManagerUtil';
import { IUserInteraction, IUserInteractionResponse } from '../interfaces/iUserInteraction';
import { IWindow } from '../interfaces/iWindow';
import { apiNameList } from './../../../environments/api-name-list';
import { HttpMethod } from './../enums/httpMethodEnum';
import { AppUtility } from './../utilities/appUtil';
import { AppSession } from './../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';

@Injectable({
  providedIn: 'root'
})
export class UserInteraction extends BaseService {
  private subscribeEvent: Subscription;
  constructor(
    _httpClientService: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility,
    @Inject('Window')
    private _window: IWindow
  ) {
    super(_appSession, _appUtility, _httpClientService);
  }

  postInteractions(reqObj: IUserInteraction): Promise<IUserInteractionResponse> {
    const apiUrl = apiNameList.restApi.userInteraction;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  setHandlers() {
    /*
    The debounce prevents  posting logs multiple times, this occurs when user clicks or press key at a fast speed multiple times
    within a very short period of time (milliseconds). Post only after the most recent click or press.
    */
    if (FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_EVENT_INTERACTION, this.appSession) && !CommonUtil.isProdEmulation(this.appSession)) {
      if (typeof this.subscribeEvent !== 'undefined' && this.subscribeEvent !== null) {
        this.subscribeEvent.unsubscribe();
      }
      const clicks = this.observe(this._window.document, 'click');
      const result = clicks.pipe(
        debounce(() => {
          return interval(100);
        })
      );
      this.subscribeEvent = result.subscribe((el: Event) => this.personInteractionHandler(el));
    }
  }

  private observe<T>(target: HasEventTargetAddRemove<T>, eventName: string): Observable<T> {
    return fromEvent(target, eventName);
  }

  private personInteractionHandler(event: Event) {
    //The element instanceof check prevents linting reporting that "code"  field not part of PointerEvent
    if ((event instanceof KeyboardEvent && event.code.toLowerCase() === 'enter') || event instanceof PointerEvent || event instanceof MouseEvent) {
      if (event.target instanceof HTMLElement) {
        const el: HTMLElement = event.target;
        const targetAnalyticTag: string | undefined = this.getMetaEventTag(el);
        if (targetAnalyticTag) {
          this.postInteractions(JSON.parse(targetAnalyticTag));
        }
      }
    }
  }

  private getMetaEventTag(el: HTMLElement | Element): string | undefined {
    //For image within a link, data analytics  is set on the link (<a>)
    if (el) {
      const targetAnalyticTag: string = el.attributes?.getNamedItem('data-fcevent')?.value || el.parentElement?.attributes?.getNamedItem('data-fcevent')?.value || '';
      return targetAnalyticTag;
    }
  }

  postInfoAlertInteractions(interactionReq: string) {
    if (interactionReq && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_EVENT_INTERACTION, this.appSession) && !CommonUtil.isProdEmulation(this.appSession)) {
      this.postInteractions(JSON.parse(interactionReq));
    }
  }
}
