import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../../environments/api-name-list';
import { HttpClientService } from '../../../../common/services/httpClientService';
import { AppUtility } from '../../../../common/utilities/appUtil';
import { AppSession } from '../../../../common/values/appSession';
import { HttpMethod } from '../../../enums/httpMethodEnum';
import { BaseService } from '../../../services/baseService';

@Injectable()
export class EmulateCostService extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getMemberHierarchy(urlParams: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getMemberHierarchy,
      urlParams: [
        {
          name: 'mbrUid',
          value: urlParams.mbrUid,
          isQueryParam: false
        },
        {
          name: 'contractuid',
          value: urlParams.contractUid,
          isQueryParam: false
        }
      ]
    });
  }

  getBenefitId(urlParams: any): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getBenefitId,
      urlParams: [
        {
          name: 'mbrUid',
          value: urlParams.mbrUid,
          isQueryParam: false
        },
        {
          name: 'contractuid',
          value: urlParams.contractUid,
          isQueryParam: false
        },
        {
          name: 'procedurecode',
          value: urlParams.procedurecode,
          isQueryParam: false
        }
      ]
    });
  }

  getCostFormula(reqObj: any, pfEmMode: boolean): Promise<any> {
    const header = this.getHeaders();

    if (pfEmMode === true) {
      header.push({
        name: 'pf-em-user',
        value: 'emulate'
      });
    }
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.costFormula,
      data: reqObj,
      headers: header
    });
  }

  getFindCareCost(reqObj: any, pfEmMode: boolean): Promise<any> {
    const header = this.getHeaders();

    if (pfEmMode === true) {
      header.push({
        name: 'pf-em-user',
        value: 'emulate'
      });
    }
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.getFindCareCost,
      data: reqObj,
      headers: header
    });
  }
}
