import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { IProviderCptCostInfo } from '../../../common/interfaces/iCptMedicalResponse';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IProviderCptCostDetailRequest } from '../models/iProviderCptCostDetailRequest';
import { IUrlParam } from './../../../../app/common/interfaces/iUrlParam';
import { HttpMethod } from './../../../common/enums/httpMethodEnum';
import { BaseService } from './../../../common/services/baseService';

@Injectable()
export class ProviderCptCostDetailService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  getProviderCptCostDetail(reqObj: IProviderCptCostDetailRequest, locale: string): Promise<IProviderCptCostInfo> {
    const urlParams: IUrlParam[] = [{ name: 'locale', value: locale, isQueryParam: true }];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.providerCptCostDetail,
      data: reqObj,
      headers: this.getHeaders(),
      urlParams
    });
  }
}
