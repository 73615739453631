import { Component, Inject, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper } from '@anthem/uxd/alert';
import { ProviderDetailsNavigationService } from '../../../fad/provider-details/services/providerDetailsNavigationSvc';
import { ICostInfo } from '../../interfaces/iSearchStrategyResponse';
import { PhonePatternPipe } from '../../pipes/phonePatternPipe';
import { EventHandler } from '../../services/eventHandler';
import { NavigationService } from '../../services/navigationService';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { PFProviderInfoComponent } from './pfProviderInfo';
@Component({
  moduleId: module.id,
  selector: 'app-provider-info-v2-cmp',
  templateUrl: './pfProviderInfoV2Cmp.html'
})
export class PFProviderInfoV2Component extends PFProviderInfoComponent {

  constructor(
    @Inject(AppSession)
    _appSession: AppSession,
    _route: ActivatedRoute,
    _eventHandler: EventHandler,
    _phonePatternPipe: PhonePatternPipe,
    _navigationService: NavigationService,
    _alertHlpr: AlertHelper,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    _providerDetailsNavigationService: ProviderDetailsNavigationService) {
    super(
      _appSession,
      _route,
      _eventHandler,
      _phonePatternPipe,
      _navigationService,
      _alertHlpr,
      _contentHelper,
      _providerDetailsNavigationService
    );
  }

  @Input()
  isPersonalizedMatch: boolean = false;
  eycCostInfo: ICostInfo;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes['providerInfo']) {
      this.eycCostInfo = CommonUtil.swapEycCostInfo(this.showSPFI, this.professional);
    }
  }
}
