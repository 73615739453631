import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppSession } from "../../../common/values/appSession";
import { IOptions } from '../../search-providers/interfaces/iOptions';
import { IProviderSearchDdlValues } from "../../search-providers/interfaces/iProviderSearchDdlValues";
import { IProviderType } from "../../search-providers/interfaces/iProviderType";
import { IDynamicFilters } from "../interfaces/iDynamicFilters";

@Injectable({ providedIn: "root" })
export class FilterCategoryService {
  public languageOptions: IProviderSearchDdlValues = undefined;
  public areaOfExpertiseOptions: IProviderSearchDdlValues = undefined;
  public adaAccessibilityOptions: IProviderSearchDdlValues = undefined;
  public ethnicityOptions: IProviderSearchDdlValues = undefined;
  public levelOfCareOptions: IProviderSearchDdlValues = undefined;
  public officeServiceOptions: IProviderSearchDdlValues = undefined;
  public patientPopulationOptions: IProviderSearchDdlValues = undefined;
  public pharmacyTypeOptions: IProviderSearchDdlValues = undefined;
  public pharmacyFeatureOptions: IProviderSearchDdlValues = undefined;
  public providerTypeOptions: Array<IProviderType> = [];
  public genderOptions: IProviderSearchDdlValues = undefined;
  public serviceAvailableOptions: IProviderSearchDdlValues = undefined;
  public blueDistinctionOptions: IProviderSearchDdlValues = undefined;
  public cmeDesignationOptions: IProviderSearchDdlValues = undefined;
  public filterCategoryList: Array<string> = [];
  public dynamicFilterOptions: IDynamicFilters = undefined;
  private dynamicFilters = new BehaviorSubject<IDynamicFilters>(this.dynamicFilterOptions);

  accordionExpandCount: number;

  constructor(@Inject(AppSession) private appSession: AppSession) {
    this.accordionExpandCount = this.appSession.appConfig?.features?.pillFilter?.accordionDefaultExpand;
  }

  expandFilterCategory(category: string): boolean {
    const filterCategoryIndex = this.filterCategoryList?.findIndex(
      (item) => item === category
    );
    return filterCategoryIndex < this.accordionExpandCount;
  }

  removeFilterCategory(category: string) {
    this.filterCategoryList = this.filterCategoryList?.filter((catgry) => catgry !== category);
  }

  get getDynamicFilters() {
    return this.dynamicFilters.asObservable();
  }

  updateDynamicFilters(filters: IDynamicFilters) {
    this.dynamicFilterOptions = filters;
    this.dynamicFilters.next(this.dynamicFilterOptions);
  }

  getDynamicFilterOptions(filterOptions: Array<string>, options: Array<IOptions>) {
    let _options = options?.filter((option) => filterOptions?.includes(option?.value?.toUpperCase())).sort((a:IOptions, b:IOptions)=>a?.label?.localeCompare(b?.label)) ?? [];
    return _options;
  }
}
