import { Injectable } from '@angular/core';
import { IMemberCriteria } from '../../../common/interfaces/iCommonSearchRequest';
import { AppSession } from '../../../common/values/appSession';
import { IOutputFlags } from '../../provider-details/models/iProviderDetailsRequest';
import { IPlan } from '../../search-results/interfaces/iSearchRequest';
import { ProviderSummaryService } from '../../search-results/services/providerSummarySvc';
import { CommonUtil } from '../../utilities/commonUtil';
import { IAddressForm } from '../interfaces/iAddressForm';
import { IProvider, IProviderLookup } from '../interfaces/iProviderLookup';
import { IProviderV1LookupRequest } from '../interfaces/iProviderV1LookupRequest';
import { IProviderV1LookupResponse } from '../interfaces/iProviderV1LookupResponse';
import { ISearchParameters } from '../interfaces/iSearchParameters';

@Injectable({
  providedIn: 'root',
})
export class ProviderLookupServiceV1 {
  constructor(private _providerService: ProviderSummaryService) {
  }

  async search(_appSession: AppSession, providerName: string): Promise<IProviderLookup> {
    try {
      const transformedRequest: IProviderV1LookupRequest = this.transformRequest(_appSession, providerName);
      const apiResponse: IProviderV1LookupResponse = await this._providerService.getPrecareProviderLookUp(transformedRequest);
      return this.transformResponse(apiResponse);
    } catch (error) {
      Promise.reject(error);
    }
  }

  private transformRequest(_appSession: AppSession, providerName: string): IProviderV1LookupRequest {
    const providerV1LookupRequest = {} as IProviderV1LookupRequest;
    providerV1LookupRequest.providerName = providerName;
    const searchParams: ISearchParameters = _appSession.searchParams;
    if (searchParams) {
      providerV1LookupRequest.typeCodes = searchParams.providerTypeCodeList;

      if (searchParams.plan) {
        providerV1LookupRequest.planstateCode = searchParams.plan.stateCd ? searchParams.plan.stateCd.trim() : '';
        if (searchParams.plan.alphaPrefix) {
          providerV1LookupRequest.alphaPrefix = searchParams.plan.alphaPrefix;
        }
        if (searchParams.plan.identifier) {
          const planIdentifier: IPlan = {
            identifier: searchParams.plan.identifier?.trim()
          };
          providerV1LookupRequest.plans = [planIdentifier];
        }
        if (searchParams.plan.networkList && searchParams.plan.networkList.length) {
          providerV1LookupRequest.networks = searchParams.plan.networkList;
        }
      }
      // set alphaPrefix for deeplink search
      if (_appSession.deeplinkParams?.alphaprefix) {
        providerV1LookupRequest.alphaPrefix = _appSession.deeplinkParams.alphaprefix;
      }
      if (searchParams.standAloneDental) {
        providerV1LookupRequest.standAloneDental = searchParams.standAloneDental;
      }
      if (searchParams.standAloneVision) {
        providerV1LookupRequest.standAloneVision = searchParams.standAloneVision;
      }

      providerV1LookupRequest.brandCode = _appSession.metaData?.brandCd;
      providerV1LookupRequest.includeVirtualProviders = (_appSession.hasVirtualUrgentCare && _appSession.feature?.customizedFeatures?.showKHealthFeatures) || false;
      const memberCriteria: IMemberCriteria = {};
      if (_appSession.metaData && _appSession.metaData.appContract) {
        if (_appSession.metaData.appContract.mbrUid && !_appSession.isPlanUnknown) {
          memberCriteria.mbrUid = _appSession.metaData.appContract.mbrUid;
        }
        if (_appSession.metaData.appContract.contractUid && !_appSession.isPlanUnknown) {
          memberCriteria.contractUid = _appSession.metaData.appContract.contractUid;
        }
      }
      if (searchParams.identificationNumber) {
        memberCriteria.identificationNumber = searchParams.identificationNumber;
      }
      if ((CommonUtil.isMemberSecure(_appSession) || searchParams?.identificationNumber) && _appSession.isFutureCoverage) {
        memberCriteria.isFuturePlan = _appSession?.isFutureCoverage;
      }
      providerV1LookupRequest.memberCriteria = memberCriteria;

      if (searchParams.countyCode) {
        providerV1LookupRequest.countyCode = searchParams.countyCode;
      }

      providerV1LookupRequest.latitude = searchParams.coordinates?.latitude.toString();
      providerV1LookupRequest.longitude = searchParams.coordinates?.longitude.toString();
      if (searchParams.distanceNm && !isNaN(Number(searchParams.distanceNm.trim()))) {
        providerV1LookupRequest.distance = Number(searchParams.distanceNm);
      }
      if (searchParams.stateCd && searchParams.stateCd.code) {
        providerV1LookupRequest.state = searchParams.stateCd.code;
      }
      if (searchParams.zip) {
        providerV1LookupRequest.postalCode = (isNaN(Number(searchParams.zip.trim())) ? '' :
          searchParams.zip.trim());
      }
      if (searchParams.city) {
        providerV1LookupRequest.city =
          (searchParams.countyCode ? '' : searchParams.city);
      }
      if (searchParams.countyCode) {
        providerV1LookupRequest.countyCode = searchParams.countyCode;
      }
    }
    // Pharmacy criteria starts here
    if (_appSession.customAddressSearch && _appSession.addressObj?.customAddress) {
      const customAddress: IAddressForm = _appSession.addressObj.customAddress;
      if (customAddress) {
        providerV1LookupRequest.state = customAddress.state;
        providerV1LookupRequest.city = customAddress.city;
        providerV1LookupRequest.postalCode = customAddress.zipCode;
      }
    }
    const outputFlags: IOutputFlags = {} as IOutputFlags;
    if (!_appSession.isEyc) {
      outputFlags.includeAllNetworks = (_appSession.hasStandaloneDentalContract || _appSession.hasStandaloneVisionContract)
        && _appSession.hasMedicalContracts;
      providerV1LookupRequest.outputFlags = outputFlags;
    }
    return providerV1LookupRequest;
  }

  private transformResponse(v1lookupResponse: IProviderV1LookupResponse): IProviderLookup {
    const lookupResponse = {} as IProviderLookup;
    const providers: Array<IProvider> = v1lookupResponse.providers.map((_provider) => {
      const provider = {} as IProvider;
      provider.code = _provider.id;
      provider.pdtKey = _provider.pdtKey;
      provider.name = _provider.providerName;
      provider.providerTypes = _provider.typeCodes;
      provider.npi = _provider.npiIds;
      provider.licenseNumber = _provider.licenseNumber
      provider.providerSituations = _provider.providerSituations;
      provider.isVirtualProvider = _provider.isVirtualProvider;
      provider.callToAction = _provider.callToAction;
      provider.address = _provider.address;
      provider.address.homeHlthCareIndc = _provider.homeHlthCareIndc;
      provider.networkRegisteredName = _provider.networkRegisteredName;
      provider.titleList = _provider.titleList;
      return provider;
    });
    lookupResponse.providers = providers;
    return lookupResponse;
  }
}
