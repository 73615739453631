import { Component, Input, ViewChild } from "@angular/core";
import { ModalComponent } from "@anthem/uxd/deprecated";
import { LeavingDisclaimerComponent } from "../../leaving-disclaimer/leavingDisclaimerCmp";
import { PFDrivingDirectionComponent } from "../../map/pfDrivingDirectionCmp";
import { PhonePatternPipe } from './../../.././pipes/phonePatternPipe';
import { MsgCategory } from './../../../interfaces/iMessage';
import { ICardData } from './../models/iCardData';
import { OnSiteClinicSliderComponent } from "./onSiteClinicSliderCmp";

@Component({
  moduleId: module.id,
  selector: 'app-card',
  templateUrl: 'cardCmp.html'
})
export class CardComponent {
  showTitle: boolean;
  showMsg: boolean;
  noPrint: boolean = false;
  showClinicLink: boolean;
  showPhoneLink: boolean;
  showActionLink: boolean;
  phoneNumber: string;
  disclaimerUrl: any;
  isSliderApplicable: boolean;

  // to disable the slider and enforce the old onsite clinic behaviour update the "onSiteClinicSliderenabled to false"
  onSiteClinicSliderEnabled: boolean = true;

  @ViewChild('leavingDisclaimerModal')
  leavingDisclaimerModal: LeavingDisclaimerComponent;

  @ViewChild('viewOnSiteClinicModal')
  viewOnSiteClinicModal: OnSiteClinicSliderComponent;

  @ViewChild('onSiteClinicMapModal')
  onSiteClinicMapModal: ModalComponent;

  @ViewChild('DrivingDirections')
  drivingDirection: PFDrivingDirectionComponent;

  private _data: ICardData;
  constructor(private _phonePatternPipe: PhonePatternPipe
  ) { }

  @Input()
  set data(val: ICardData) {
    if (val !== this._data) {
      this._data = val;
      this.showTitle = val.title && val.title != '';
      this.showMsg = val.message && val.message != '';
      this.showClinicLink = val.clinicLink ? true : false;
      this.showPhoneLink = val.phoneLink ? true : false;
      this.showActionLink = val.actionLink ? true : false;
      this.isSliderApplicable = val.contentId === MsgCategory.ONSITE_CLINICS;
      this.phoneNumber = this._phonePatternPipe.transform(val?.phoneLink?.text);
    }
  }

  get data(): ICardData {
    return this._data;
  }

  openDomainDisclaimerModal(url: string): void {
    this.disclaimerUrl = url;
    this.leavingDisclaimerModal.show();
  }

  getDirection(_cardData: ICardData): void {
    const _address = {
      addressOne: _cardData?.clinic?.address1,
      addressTwo: (_cardData?.clinic?.city ? _cardData.clinic.city + ', ' : '') +
        (_cardData?.clinic?.state ? _cardData.clinic.state : '') +
        (_cardData?.clinic?.zipCode ? ' ' + _cardData.clinic.zipCode : ''),
      latitude: _cardData.clinic.latitude.toString() ?? '',
      longitude: _cardData.clinic.longitude.toString() ?? ''
    };
    const _provider = {
      location: {
        address: _address
      },
      providerName: _cardData.clinic.name
    };
    this.onSiteClinicMapModal.show();
    this.drivingDirection.openMapModal(_provider);
  }

  closeModal(modal: string): void {
    if (modal === 'map') {
      this.onSiteClinicMapModal.hide();
    }
  }

  printDrivingDirection(): void {
    this.noPrint = true;
    setTimeout(() => {
      window.print();
    }, 300);
  }

  onCardSelect(data: ICardData): void {
    this.viewOnSiteClinicModal.openSidePanel('right', data);
  }
}
