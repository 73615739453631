import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import {
  IPersonActionRequest,
  IPersonActions
} from '../interfaces/iPersonalizationChecklist';
import { IPersonalizationChecklistContent } from '../interfaces/personalizationContentTypes';
import { PersonalizationChecklistModel } from '../models/personalizationChecklistModel';
import { PersonalizationChecklistSvc } from '../services/personalizationChecklistSvc';

@Component({
  moduleId: module.id,
  selector: '[app-fad-pf-personalization-checklist-cmp]',
  templateUrl: '../views/pfPersonalizationChecklistCmp.html',
})
export class PFPersonalizationChecklistComponent
  extends BaseComponent
  implements OnInit
{
  isCheckListLoading = true;
  isCheckListCTAProcessing: boolean = false;
  emulationInd: boolean = false;
  constructor(
    private _route: ActivatedRoute,
    _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _personalizationChecklistSvc: PersonalizationChecklistSvc,
    public personalizationChecklistModel: PersonalizationChecklistModel
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      'PFSearchContainerComponent'
    );
  }

  ngOnInit() {
    this.emulationInd = this._appSession?.isEmulation;
    this.getYourNexBestSteps(this.content);
  }

  private async getYourNexBestSteps(content: IPersonalizationChecklistContent) {
    const _personActionRequest = {} as IPersonActionRequest;
    if (this._appSession.metaData.idToken) {
      _personActionRequest.idToken = this._appSession.metaData.idToken;
    }

    try {
      this.isCheckListLoading = true;
      const resp: IPersonActions =
        await this._personalizationChecklistSvc.getActions(
          _personActionRequest
        );
      this.personalizationChecklistModel.transformApi(resp as IPersonActions);
    } catch (error) {
      this.personalizationChecklistModel.transformApi({});
    }
    this.isCheckListLoading = false;
  }
}
