import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppSession } from '../../../common/values/appSession';
import { ISpecialityRequest, ISpecialityResponse } from '../../result/interfaces/iProviderSpeciality';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';

@Injectable({
  providedIn: 'root'
})
export class ProviderSpecialityService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  getProviderSpeciality(request: ISpecialityRequest): Promise<ISpecialityResponse> {
    const apiUrl = `${this.baseURL}${apiNameList.restApi.getProcedueAndSpecialityList}`;
    const options = {
      cancellable: true,
      method: HttpMethod.Post,
      url: apiUrl,
      data: request,
      headers: this.getHeaders()
    };

    return this.httpClientSvc.request(options);
  }
}
