import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { CommonUtil } from './../../../app/fad/utilities/commonUtil';
import { SearchCriteriaPayload } from './../../common/models/searchCriteriaPayload';
import { IOptions } from './../../fad/search-providers/interfaces/iOptions';
import { ISearchRequest, SearchCriteria } from './../../fad/search-results/interfaces/iSearchRequest';
import { FILTER } from './../../fad/search-results/values/providerSearchConstants';
import { SearchPayload } from './../models/payload';
import { EventConstant, EVENT_TYPE, PayloadEvent, SOURCE_TYPE } from './../models/payloadEvent';
import { PublisherRequest } from './../models/publisherReq';
import { AppSession } from './../values/appSession';
import { DataHelper } from './dataHelper';
import { FeatureFlagService } from './featureFlagInitializer';
import { MessagingService } from './messagingSvc';

export enum PROMISE_TYPE {
  PROVIDER_TYPES = 'PROVIDER_TYPES',
  SPECIALTY_TYPES = 'SPECIALTY_TYPES',
  PROCEDURE_TYPES = 'PROCEDURE_TYPES'
}
@Injectable({
  providedIn: 'root'
})
export class MessagingHandler {
  private _promises: any[] = [];
  private _previousPublisherReq: PublisherRequest;
  private _providerTypeList: any;
  private _specialtyTypeList: any;
  private _procedureTypeList: any;
  private _visionServiceList: Array<any>;

  constructor(
    private _dataHelper: DataHelper,
    private _messagingService: MessagingService,
    private _appSession: AppSession
  ) {
    this._visionServiceList = FILTER.serviceAvailable.options;
  }

  setProviderTypePromise(promise: any) {
    if (promise) {
      this._promises.push(promise.then((result) => {
        return {
          key: PROMISE_TYPE.PROVIDER_TYPES,
          value: result
        }
      }));
    }
  }

  setSpecialtyTypePromise(promise: any) {
    if (promise) {
      this._promises.push(promise.then((result) => {
        return {
          key: PROMISE_TYPE.SPECIALTY_TYPES,
          value: result
        }
      }));
    }
  }

  setProcedureTypePromise(promise: any) {
    if (promise) {
      this._promises.push(promise.then((result) => {
        return {
          key: PROMISE_TYPE.PROCEDURE_TYPES,
          value: result
        }
      }));
    }
  }

  async postSearchMessage(searchRequest: ISearchRequest) {
    if (!this.isKafkaMessagingEnabled() || !CommonUtil.isMemberSecure(this._appSession)) {
      return;
    }

    let results = await Promise.all(this._promises.map((p) => p.catch((e) => e)));
    // filter errors and clean the response.
    results = results.filter((result) => !(result instanceof HttpErrorResponse));
    this.processResult(results);
    this.resetPromises();

    let currentPublisherReq: PublisherRequest = {} as PublisherRequest;
    currentPublisherReq.events = [];
    let event: PayloadEvent = this.buildEvent();
    event.payload = {} as SearchPayload;
    event.payload.searchCriteria = this.buildSearchCriteriaPayload(searchRequest);
    currentPublisherReq.events.push(event);

    if (!this._previousPublisherReq ||
      (currentPublisherReq && currentPublisherReq.events && currentPublisherReq.events.length &&
        this._previousPublisherReq && this._previousPublisherReq.events && this._previousPublisherReq.events.length &&
        !this._dataHelper.deepEqual(currentPublisherReq.events[0].payload, this._previousPublisherReq.events[0].payload))) {

      await this._messagingService.postMessage(currentPublisherReq);
      this.setCurrentPublisherReq(currentPublisherReq);
    }
  }

  private resetPromises() {
    this._promises = [];
  }

  private processResult(results: Array<{ key: PROMISE_TYPE, value: any }>) {
    (results || []).forEach((result) => {
      if (result.key === PROMISE_TYPE.PROVIDER_TYPES) {
        this._providerTypeList = result.value;
      } else if (result.key === PROMISE_TYPE.SPECIALTY_TYPES) {
        this._specialtyTypeList = result.value;
      } else if (result.key === PROMISE_TYPE.PROCEDURE_TYPES) {
        this._procedureTypeList = result.value;
      }
    });
  }

  private buildEvent() {
    let event: PayloadEvent = {} as PayloadEvent;
    event.eventTime = this.getCurrentTime();
    event.sourceType = SOURCE_TYPE.PROVIDER_SEARCH;
    event.eventType = EVENT_TYPE.PROVIDER_SEARCH;
    event.client = EventConstant.CLIENT;
    return event;
  }

  private buildSearchCriteriaPayload(searchRequest: ISearchRequest) {
    let searchCriteria: SearchCriteriaPayload = {} as SearchCriteriaPayload;

    if (searchRequest && searchRequest.searchCriteria) {
      searchCriteria.ableToServeAsPCP = (searchRequest.searchCriteria.ableToServeAsPCP === 'true');
      searchCriteria.acceptingNewPatients = (searchRequest.searchCriteria.acceptingNewPatients === 'true');
      searchCriteria.applyPersonalizedMatch = searchRequest.searchCriteria.applyPersonalizedMatch;
      searchCriteria.boardCertification = (searchRequest.searchCriteria.boardCertification === 'true');
      searchCriteria.brandCode = searchRequest.searchCriteria.brandCode;
      searchCriteria.contractUid = searchRequest.searchCriteria.contractUid;
      searchCriteria.latitude = searchRequest.searchCriteria.latitude;
      searchCriteria.longitude = searchRequest.searchCriteria.longitude;
      searchCriteria.medicaid = (searchRequest.searchCriteria.medicaid === 'true');
      searchCriteria.postalCode = searchRequest.searchCriteria.postalCode;
      searchCriteria.scheduleAptmntAvailable = (searchRequest.searchCriteria.scheduleAptmntAvailable === 'true');

      if (searchRequest.eycProcedureCriteria) {
        searchCriteria.procedureName = this.getProcedureTypeName(searchRequest.eycProcedureCriteria.procedureCode);
      }

      searchCriteria.providerTypeCodeList = this.getProviderTypeNames(searchRequest.searchCriteria.providerTypeCodeList);
      searchCriteria.visionSrvcAvailableList = this.getVisionServiceNames(searchRequest.searchCriteria.visionSrvcAvailableList);
      searchCriteria = this.setSpecialtyTypes(searchRequest.searchCriteria, searchCriteria);
    }

    return searchCriteria;
  }

  /**
   * this method will return vision services names
   * @param visionSrvcAvailableList
   */
  private getVisionServiceNames(visionSrvcAvailableList: Array<string>) {
    let serviceNames: Array<string> = [];

    (visionSrvcAvailableList || []).forEach((serviceCode) => {
      const serviceType = (this._visionServiceList || [])
        .filter((service: IOptions) => service.value.toLocaleUpperCase() === serviceCode.toLocaleUpperCase())
        .reduce((pValue, cValue) => {
          return cValue;
        }, {});

      if (serviceType && serviceType.label) {
        serviceNames.push(serviceType.label);
      }
    });

    return serviceNames;
  }

  /**
   * this method will set specialty and sub-specialty to search criteria object
   * @param searchCriteria
   * @param searchCriteriaPayload
   */
  private setSpecialtyTypes(searchCriteria: SearchCriteria, searchCriteriaPayload: SearchCriteriaPayload) {
    let specialtyNames: Array<string> = [];
    let subSpecialtyNames: Array<string> = [];

    (searchCriteria.specialtyCategoryList || []).forEach((specialtyCategoryCode) => {
      const categoryType = (this._specialtyTypeList && this._specialtyTypeList.specialtyCategoryList || [])
        .filter((categoryType) => categoryType.code.toLocaleUpperCase() === specialtyCategoryCode.toLocaleUpperCase())
        .reduce((pValue, cValue) => {
          return cValue;
        }, {});

      if (categoryType && categoryType.name) {
        specialtyNames.push(categoryType.name);
      }

      (searchCriteria.subSpecialtyList || []).forEach((subSpecialtyCode) => {
        const subCategoryType = (categoryType && categoryType.subSpecialtyList || [])
          .filter((categoryType) => categoryType.code.toLocaleUpperCase() === subSpecialtyCode.toLocaleUpperCase())
          .reduce((pValue, cValue) => {
            return cValue;
          }, {});

        if (subCategoryType && subCategoryType.name) {
          subSpecialtyNames.push(subCategoryType.name);
        }
      });
    });

    searchCriteriaPayload.specialtyCategoryList = specialtyNames;
    searchCriteriaPayload.subSpecialtyList = subSpecialtyNames;
    return searchCriteriaPayload;
  }

  /**
   * this method will return provider type names
   * @param providerTypeCodeList
   */
  private getProviderTypeNames(providerTypeCodeList: Array<string>) {
    let providerNames: Array<string> = [];

    (providerTypeCodeList || []).forEach((providerTypeCode) => {
      const codeType = (this._providerTypeList && this._providerTypeList.codeTypeList || [])
        .filter((codeType) => codeType.code.toLocaleUpperCase() === providerTypeCode.toLocaleUpperCase())
        .reduce((pValue, cValue) => {
          return cValue;
        }, {});

      if (codeType && codeType.name) {
        providerNames.push(codeType.name);
      }
    });

    return providerNames;
  }

  /**
   * this method will return procedure names
   * @param procedureCode
   */
  private getProcedureTypeName(procedureCode: string) {
    let procedureName: string;

    const codeType = (this._procedureTypeList && this._procedureTypeList.procedureCategoryList
      && this._procedureTypeList.procedureCategoryList.procedureList || [])
      .filter((procedureType) => procedureType &&
        procedureType.procedureCode && procedureType.procedureCode.code &&
        procedureType.procedureCode.code.toLocaleUpperCase() === procedureCode.toLocaleUpperCase())
      .reduce((pValue, cValue) => {
        return cValue;
      }, {});

    if (codeType && codeType.procedureCode && codeType.procedureCode.name) {
      procedureName = codeType.procedureCode.name;
    }

    return procedureName;
  }

  private setCurrentPublisherReq(publisherReq: PublisherRequest) {
    this._previousPublisherReq = publisherReq;
  }

  private getCurrentTime(): string {
    return moment().format();
  }

  private isKafkaMessagingEnabled(): boolean {
    const switches = FeatureFlagService.getKillSwitches();
    if (switches) {
      return !switches['kafkaMessaging'];
    }
    return false;
  }
}