import { Directive, Input, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Directive({
  selector: '[safeHtml]',
  host: {
    '[innerHTML]': 'innerHTML'
  }
})
export class SafeHtmlDirective {
  innerHTML: any = '';
  @Input()
  safeHtml: string;

  constructor(protected _sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['safeHtml'] && changes['safeHtml'].currentValue) {
      this.innerHTML = this.sanitizeHtml(this.safeHtml);
    }
  }

  private sanitizeHtml(html: string): SafeHtml {
    return this._sanitizer.sanitize(SecurityContext.HTML, html);
  }
}
