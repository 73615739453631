import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { ContentHelper } from '../../../common/values/contentHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { PROVIDER_TYPE } from './../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from './../../../fad/utilities/commonUtil';

@Component({
  moduleId: module.id,
  selector: 'app-pf-cost-info-cmp',
  templateUrl: '../views/pfCostInformationCmp.html',
  providers: []
})
export class PFCostInformationComponent extends BaseComponent {
  @Input() index = 0;
  @Input() eycCostInfo;
  @Input() professional;
  @Output()
  openMapModal: EventEmitter<any> = new EventEmitter<any>();
  expansion: string = '';

  constructor(
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession) {
    super(_route, _eventHandler, _appSession, _contentHelper,  'PFSearchResultsContainerComponent');
  }

  get isEYC(): boolean {
    return this._appSession.isEyc;
  }

  get isDentalSearch(): boolean {
    return (this._appSession.hasDentalEYC && this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL);
  }

  get cdhpError(): string {
    return this.content.eyc.cdhpError.replace(/{ACCOUNTTYPE}/gi, this.eycCostInfo.accntType);
  }

  get costCDHPPaysText(): string {
    return this.content.eyc.costCDHPPaysText.replace(/{ACCOUNTTYPE}/gi, this.eycCostInfo.accntType);
  }

  get costCDHPPaysAriaText(): string {
    this.expansion = CommonUtil.getAccountExpansion(this.eycCostInfo.accntType);
    return this.content.eyc.costCDHPPaysText.replace(/{ACCOUNTTYPE}/gi, this.expansion);
  }

  get costBeforeCDHPBalance(): string {
    return this.content.eyc.costBeforeCDHPBalance.replace(/{ACCOUNTTYPE}/gi, this.eycCostInfo.accntType);
  }

  openLocationMapModal(professional: any) {
    this.openMapModal.emit(professional);
  }
}
