import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CostSlideOutModule } from '../../../eyc/cost-slide-out/costSlideOutModule';
import { CustomPipesModule } from '../../pipes/customPipesModule';
import { RatingsSlideOutModule } from '../../ratings-slide-out/ratingsSlideOutModule';
import { PFProviderExperienceModule } from '../provider-experience/pfProviderExperienceModule';
import { VirtualCareLabelModule } from '../virtual-care-label/virtualCareLabelModule';
import { BingMapsLoader } from './../../services/bingMapsLoader';
import { AssignCareModule } from './../assign-care/assignCareModule';
import { AssignPcpModule } from './../pcp/pfAssignPcpModule';
import { RatingsModule } from './../ratings/pfRatingsModule';
import { ScheduleAppointmentModule } from './../schedule-appointment/scheduleAppointmentModule';
import { SsoModule } from './../sso/ssoModule';
import { DrivingDirectionSlideOutComponent } from './drivingDirectionSlideOutCmp';
import { LocationsSlideOutComponent } from './locationsSlideOutCmp';
import { MapOverlayHelper } from './mapOverlayHlpr';
import { PFDrivingDirectionComponent } from './pfDrivingDirectionCmp';
import { PFMapComponent } from './pfMapCmp';
import { PFProviderInfoComponent } from './pfProviderInfo';
import { PFProviderInfoV2Component } from './pfProviderInfoV2Cmp';
import { PFTileViewComponent } from './pfTileViewCmp';
export * from './pfMapCmp';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UxSharedModule.forRoot(),
        RatingsModule,
        SsoModule,
        ScheduleAppointmentModule,
        AssignCareModule,
        CustomPipesModule,
        PFProviderExperienceModule,
        AssignPcpModule,
        RatingsSlideOutModule,
        CostSlideOutModule,
        VirtualCareLabelModule
    ],
    declarations: [PFMapComponent, PFDrivingDirectionComponent, PFProviderInfoComponent, PFTileViewComponent, DrivingDirectionSlideOutComponent, PFProviderInfoV2Component,
        LocationsSlideOutComponent],
    exports: [PFMapComponent, PFDrivingDirectionComponent, PFProviderInfoComponent, PFTileViewComponent, DrivingDirectionSlideOutComponent, PFProviderInfoV2Component,
        LocationsSlideOutComponent],
    providers: [
        BingMapsLoader,
        MapOverlayHelper
    ]
})
export class MapModule { }
