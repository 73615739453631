import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HostnameService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  getHostname(): string {
    return this.document.location.hostname;
  }
}
