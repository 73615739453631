import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "../../base-component/baseCmp";
import { EventHandler } from './../../../services/eventHandler';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';
import { ICardData } from './../models/iCardData';

@Component({
  moduleId: module.id,
  selector: 'app-cards-h-list',
  templateUrl: 'cardsHListCmp.html'
})
export class CardsHListComponent extends BaseComponent {
  @Input()
  maxVisibleCards: number = 3;
  @Output()
  viewMoreEvent: EventEmitter<void> = new EventEmitter();
  showViewMore: boolean = false;
  showList: boolean = false;

  private _cardsData: ICardData[];
  constructor(route: ActivatedRoute, eventHandler: EventHandler,
    @Inject(AppSession) appSession: AppSession,
    contentHlpr: ContentHelper
  ) {
    super(route, eventHandler, appSession, contentHlpr);
  }

  @Input()
  set cardsData(val: ICardData[]) {
    if (!val) {
      val = [];
    }
    if (val !== this._cardsData) {
      this._cardsData = val;
      this.showList = val.length > 0;
      this.showViewMore = val.length > this.maxVisibleCards;
    }

  }
  get cardsData(): ICardData[] {
    return this._cardsData;
  }
}
