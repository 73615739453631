import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '@anthem/uxd/deprecated';
import { IWindow } from '../../../common/interfaces/iWindow';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-leaving-disclaimer',
  templateUrl: './leavingDisclaimerCmp.html'
})

export class LeavingDisclaimerComponent extends BaseComponent implements OnInit {
  @ViewChild('leavingDisclaimerModal')
  leavingDisclaimerModal: ModalComponent;

  brandDomain: string = '';
  regx = /{BRAND}/gi;

  @Input()
  url: any;
  @Input()
  locale?: string;
  @Input()
  showCloseBtn: boolean = true;
  @Input()
  showContinueBtn: boolean = true;

  private _closeBtnText: string;
  @Input() set closeBtnText(value: string) {
    this._closeBtnText = value;
  }
  get closeBtnText(): string {
    const _value = this._closeBtnText ? this._closeBtnText : this.content.leavingDisclaimerComponent.btnClose;
    return _value.replace(this.regx, this.brandDomain);
  }

  private _continueBtnText: string;
  @Input() set continueBtnText(value: string) {
    this._continueBtnText = value;
  }
  get continueBtnText(): string {
    const _value = this._continueBtnText ? this._continueBtnText : this.content.leavingDisclaimerComponent.btnContinue;
    return _value.replace(this.regx, this.brandDomain);
  }

  private _header: string;
  @Input() set header(value: string) {
    this._header = value;
  }
  get header(): string {
    const _value = this._header ? this._header : this.content.leavingDisclaimerComponent.header;
    return _value.replace(this.regx, this.brandDomain);
  }

  private _content1: string;
  @Input() set content1(value: string) {
    this._content1 = value;
  }
  get content1(): string {
    const _value = this._content1 ? this._content1 : this.content.leavingDisclaimerComponent.content1;
    return _value.replace(this.regx, this.brandDomain);
  }

  private _content2: string;
  @Input() set content2(value: string) {
    this._content2 = value;
  }
  get content2(): string {
    const _value = this._content2 ? this._content2 : this.content.leavingDisclaimerComponent.content2;
    return _value.replace(this.regx, this.brandDomain);
  }

  private _content3: string;
  @Input() set content3(value: string) {
    this._content3 = value;
  }
  get content3(): string {
    const _value = this._content3 ? this._content3 : '';
    return _value.replace(this.regx, this.brandDomain);
  }

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.brandDomain = this._appSession.metaData.brandName;
  }

  openExternalUrl() {
    if (this.url) {
      let windowParams: string;
      windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
      this.url = (this.url.indexOf('://') <= 0) ? 'http://' + this.url : this.url;
      const winArgs = [this.url, '_blank'];
      if (windowParams) {
        winArgs.push(windowParams);
      }
      this._window.open.apply(this._window, winArgs);
    }
    this.leavingDisclaimerModal.hide();
  }

  show() {
    if (typeof this.leavingDisclaimerModal.config === 'undefined') {
      this.leavingDisclaimerModal.config = { backdrop: false };
    }
    this.leavingDisclaimerModal.show();
  }

  hide() {
    this.leavingDisclaimerModal.hide();
  }
}
