import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '../../../../libs/uxd-shared/uxdSharedModule';
import { PFProviderExperienceComponent } from './pfProviderExperienceCmp';

@NgModule({
    declarations: [PFProviderExperienceComponent],
    exports: [PFProviderExperienceComponent],
    imports: [
        FormsModule, ReactiveFormsModule,
        UxSharedModule,
        CommonModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PFProviderExperienceModule { }
