export const LOW_RANGE_AGE = 0;
export const HIGH_RANGE_AGE = 99;

export const providerOutputFlags = {
  visibilityRulesFlag: true,
  reviewRatingsFlag: true,
  imposeAddressConsolidation: true,
  improveSearchCriteria: true,
  includeSpfInfo: false,
  includeProviderNetworks: false,
  includePrimeGroupSearch: false,
  includeDisclaimerRules: true
}
