import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, EventEmitter, Inject, Injectable } from '@angular/core';
import { apiNameList } from './../../../environments/api-name-list';
import { AppErrorMsg, ERROR_CODES } from './../constants/app-constants';
import { AppEvents } from './../enums/appEvents';
import { IEventDetail } from './../interfaces/iEventDetail';
import { ContentHelper } from './../values/contentHelper';
import { EventHandler } from './eventHandler';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _pfAppErrorEvent: EventEmitter<IEventDetail>
    = this._eventHandler.get(AppEvents[AppEvents.GLOBAL_ERROR]);

  constructor(
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper) { }

  handleError(error) {
    // TODO custom error handling logic
    const eventDetail = {} as IEventDetail;

    if (error.rejection instanceof HttpErrorResponse) {
      // Server Error
      const err = error.rejection;
      if (err.status === 0) {
        eventDetail.type = AppEvents[AppEvents.PF_APP_ALERT];
        eventDetail.message = AppErrorMsg.SERVICE_UNAVAILABLE;
        eventDetail.details = AppErrorMsg.SERVICE_UNAVAILABLE;
        eventDetail.errorCode = err.status;
      } else if (err.status === ERROR_CODES.UNAUTHORIZED) {
        eventDetail.type = AppEvents[AppEvents.PF_APP_ALERT];
        eventDetail.message = this.content && this.content.appErrorMsg && this.content.appErrorMsg.sessionExpired
          ? this.content.appErrorMsg.sessionExpired : AppErrorMsg.TOKEN_EXPIRED;
        eventDetail.details = AppErrorMsg.TOKEN_EXPIRED;
        eventDetail.errorCode = err.status;
      }

      // Display error message when services is down during app init load
      if (err.url && err.url.indexOf(apiNameList.restApi.dataModifiedPublic) > 0) {
        eventDetail.type = AppEvents[AppEvents.PF_APP_ALERT];
        eventDetail.message = this.content && this.content.appErrorMsg && this.content.appErrorMsg.defaultErrorMsg
          ? this.content.appErrorMsg.defaultErrorMsg : AppErrorMsg.DEFAULT_ERROR_MSG;
        eventDetail.details = AppErrorMsg.SERVICE_UNAVAILABLE;
        eventDetail.errorCode = err.status;
      }

      console.info(err);
    } else {
      eventDetail.type = AppEvents[AppEvents.GLOBAL_ERROR];
      eventDetail.message = AppErrorMsg.PF_APP_ERROR;
      eventDetail.details = error;

      console.info(error);
    }

      this._pfAppErrorEvent.emit(eventDetail);
  }

  private get content(): any {
    return this._contentHelper.getContent('PFSearchContainerComponent');
  }
}
