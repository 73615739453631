import { Component } from '@angular/core';
import { PFDynamicProvidersAndProceduresComponent } from './pfDynamicProvidersAndProceduresCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-dynamic-providers-procedures-v2-cmp',
  templateUrl: '../views/pfDynamicProvidersAndProceduresV2Cmp.html',
  providers: [],
})
export class PFDynamicProvidersAndProceduresV2Component extends PFDynamicProvidersAndProceduresComponent {
}
