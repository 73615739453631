import { Inject, Injectable } from '@angular/core';
import { Locale } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { ProviderPlanService } from '../../common/services/providerPlanSvc';
import { IProvider } from '../interfaces/iProvider';
import { IProviderPlan, IProviderPlanRequest } from '../interfaces/iProviderPlan';

@Injectable({
  providedIn: 'root'
})
export class ProviderPlanHandler {
  locale: string;

  constructor(
    private _providerPlanSvc: ProviderPlanService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  async getProviderPlans(provider: IProvider): Promise<IProviderPlan[]> {
    try {
      const planRequest = this.buildRequest(provider);
      const apiResponse = await this._providerPlanSvc.getProviderPlans(planRequest);
      return apiResponse.insurancePlans;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private buildRequest(provider: IProvider): IProviderPlanRequest {
    const locale = this._appSession?.metaData?.locale?.toString() || Locale.ENGLISH.toString();
    const stateList = this.buildStateList(provider);

    return {
      id: provider?.providerIdentifier,
      addressId: provider?.addressSummary?.addressIdentifier,
      brandCode: this._appSession.metaData.brandCd,
      locale: locale,
      stateList: stateList
    };
  }

  private buildStateList(provider: IProvider): string[] {
    const states: string[] = [];

    if (provider?.addressSummary?.stateCode) {
      states.push(provider.addressSummary.stateCode);
    }

    const sessionStateCode = this._appSession.searchParams?.plan?.stateCd;
    if (sessionStateCode && sessionStateCode !== provider?.addressSummary?.stateCode) {
      states.push(sessionStateCode);
    }

    return states;
  }
}
