import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { IBootstrapRequest, IBootstrapResponse } from '../interfaces/iBootstrap';
import { ISearchBoxRequest, ISearchBoxResponse } from '../interfaces/iSearchBox';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';

@Injectable({
  providedIn: 'root'
})
export class UtilityService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  /**
   * Fetches the bootstrap data from the server.
   * @param secure - Indicates whether to use the secure bootstrap endpoint. Defaults to false.
   * @param request - The request payload for the bootstrap API. Defaults to an empty object.
   * @returns A promise that resolves to the bootstrap response.
   */
  getBootstrap(secure: boolean = false, request: IBootstrapRequest = {}): Promise<IBootstrapResponse> {
    const url = this.baseURL + (secure ? apiNameList.restApi.secureBootstrapV1 : apiNameList.restApi.publicBootstrapV1);
    const headers = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: url,
      data: request,
      headers: headers
    });
  }

  /**
   * Sends a search box request to the server and returns the response.
   * @param request The search box request object.
   * @returns A promise that resolves to the search box response.
   */
  searchBox(request: ISearchBoxRequest): Promise<ISearchBoxResponse> {
    const url = isEmpty(request?.memberCriteria) ? `${this.baseURL}${apiNameList.restApi.publicSearchBoxV1}` : `${this.baseURL}${apiNameList.restApi.secureSearchBoxV1}`;
    const headers = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: url,
      data: request,
      headers: headers
    });
  }
}
