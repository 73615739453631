import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../../../environments/api-name-list';
import { HttpMethod } from '../../../enums/httpMethodEnum';
import { BaseService } from '../../../services/baseService';
import { HttpClientService } from '../../../services/httpClientService';
import { MessagingHandler } from '../../../services/messagingHandler';
import { AppUtility } from '../../../utilities/appUtil';
import { AppSession } from '../../../values/appSession';
import { IGeoCoordinates, IGeoLocationInfo, IGeoLocationInfoWithCounties, ILocationSearchRequest } from '../models/geoLocation';

/**
 * Provider code list service will return provider types, languages, etinicities,
 * alpha prefix, patient preferences, areas of expertise and WLP states.
 */
@Injectable()
export class LocationSvc extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility,
    private _messagingHandler: MessagingHandler) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  getLocationLookup(input: string): Promise<IGeoLocationInfoWithCounties> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      urlParams: [
        {
          name: 'input',
          value: input,
          isQueryParam: true
        }
      ],
      url: this.baseURL + apiNameList.restApi.geolocationLookup
    });
  }


  getFullAddressDetails(request: ILocationSearchRequest): Promise<IGeoLocationInfo> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      data: request,
      headers: this.getHeaders(),
      url: this.baseURL + apiNameList.restApi.geolocationAddressValidator,
    });
  }

  getLocationByGeoCode(request: IGeoCoordinates): Promise<IGeoLocationInfo> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.geolocationLatLongValidator,
      data: request,
      headers: this.getHeaders(),
    });
  }
}
