import { Injectable } from '@angular/core';
import { apiNameList } from '../../../environments/api-name-list';
import { HttpMethod } from '../enums/httpMethodEnum';
import { IKillSwitch } from '../interfaces/iKillSwitch';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';

/** @deprecated DO NOT use this class. For all feature enablement needs use the baseCmp or FeatureManagerUtility */
@Injectable()
export class FeatureFlagService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  private static _killSwitches: IKillSwitch;

  async load() {
    let apiUrl = apiNameList.restApi.fetchFeaturesFlag;
    const switches = await this.httpClientSvc.request({
      method: HttpMethod.Get,
      url: this.baseURL + apiUrl
    });
    FeatureFlagService._killSwitches = switches;
    return true;
  }

  /** @deprecated DO NOT USE this capability. Move to methods available in FeatureManagerUtility class. */
  static getKillSwitches(): IKillSwitch {
    return this._killSwitches;
  }
}
