import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Locale } from '../../../../common/constants/app-constants';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { ICostInfo } from '../../../../common/interfaces/iSearchStrategyResponse';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { OUTNETWORK } from '../../../../fad/search-results/values/providerSearchConstants';
import { RESULT_CONTENT_EN } from '../../../common/contents/result/resultContent';
import { RESULT_CONTENT_ES } from '../../../common/contents/result/resultContent.es';
import { IProvider } from '../../interfaces/iProvider';
import { CompareProvider } from '../../services/compareProvider';
import { BaseComponent } from './../../../common/components/core/baseCmp';
import { IOption } from './../../../common/interfaces/iOption';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-card-cmp',
  templateUrl: './providerCardCmp.html'
})
export class ProviderCardComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() provider: IProvider;
  @Input() isActive!: boolean;
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _pfOfficeVisitCost: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_CPT_OFC_VISIT_COST]);
  hasCostInfo = false;
  private pfOfficeVisitCostSubscription: Subscription;

  comapreProvider: IOption;
  comapreSelectedProviders: IProvider[] = [];

  private compareProviderSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _compareProvider: CompareProvider
  ) {
    super(_route, _eventHandler, _appSession);
    this.pfOfficeVisitCostSubscription = this._pfOfficeVisitCost.subscribe((resp) => {
      const costDetails = resp.object;
      if (costDetails?.providerList?.length) {
        for (const provider of costDetails.providerList) {
          if (provider.providerKey === this.provider?.providerIdentifier && provider.addressKey === this.provider?.addressSummary?.addressIdentifier) {
            this.provider = { ...this.provider, costInfo: provider.costInfo };
            break;
          }
        }
      }
      this.hasCostInfo = this.provider && this.provider.costInfo ? true : false;
    });
  }

  /**
   * Initializes the component and subscribes to necessary observables.
   */
  ngOnInit(): void {
    if (this.provider?.costInfo) {
      this.hasCostInfo = true;
    }
    this.comapreProvider = this.getOptionObject(this.provider);
    this.compareProviderSubscription = this._compareProvider.compareProvider.subscribe((data) => {
      this.comapreSelectedProviders = data || [];
    });
  }

  getEycCostInfo(provider: IProvider): ICostInfo {
    let eycCostInfo = {} as ICostInfo;
    if (provider?.eycAffliation?.affiliationList?.length) {
      const affFacility = { ...provider.eycAffliation.affiliationList[0] };
      if (affFacility?.eycCostInfo) {
        eycCostInfo = affFacility.eycCostInfo;
      }
    } else if (provider?.eycCostInfo) {
      eycCostInfo = provider.eycCostInfo;
    }
    return eycCostInfo;
  }

  ngOnDestroy(): void {
    if (this.pfOfficeVisitCostSubscription) {
      this.pfOfficeVisitCostSubscription.unsubscribe();
    }
    this.compareProviderSubscription?.unsubscribe();
  }

  getNetworkStatus(status: string): string {
    const statusLabels = {
      IN_NETWORK: this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent.inNetwork : RESULT_CONTENT_EN.summaryComponent.inNetwork,
      OUT_NETWORK: this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent.filter.labels.oonPlan : RESULT_CONTENT_EN.summaryComponent.filter.labels.oonPlan
    };
    return status === OUTNETWORK ? statusLabels.OUT_NETWORK : statusLabels.IN_NETWORK;
  }

  onSendCardStatus(status: boolean) {
    this.cardSelected.emit(status);
  }

  onCompare(event: string, providerObj: IProvider): void {
    if (event !== '') {
      this._compareProvider.add(providerObj);
    } else {
      this._compareProvider.remove(providerObj);
    }
  }

  setCompareSelection(providerObj: IProvider): string {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}${providerObj.addressIdentifier}`;
      if (providerObj && this.comapreSelectedProviders?.some((option) => option.providerIdentifier === providerObj.providerIdentifier && option.addressIdentifier === providerObj.addressIdentifier)) {
        return value;
      } else {
        return '';
      }
    }
  }

  disableSelection(providerIdentifier: string): boolean {
    const provider = this.comapreSelectedProviders.find((option) => option.providerIdentifier === providerIdentifier);
    if (this.comapreSelectedProviders.length >= 3 && !provider) {
      return true;
    } else {
      return false;
    }
  }

  private getOptionObject(providerObj: IProvider): IOption {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}${providerObj.addressIdentifier}`;
      return {
        value: value,
        id: `input-${value}`,
        name: providerObj.providerName,
        trueValue: value,
        falseValue: ''
      } as IOption;
    }
  }
}
