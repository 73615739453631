import { Component, Inject, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IFinalSearchResponse } from "../../interfaces/iCommonSearchResponse";
import { ICptMedicalResponse, IServiceLimit } from "../../interfaces/iCptMedicalResponse";
import { EventHandler } from "../../services/eventHandler";
import { AppSession } from "../../values/appSession";
import { ContentHelper } from "../../values/contentHelper";
import { BaseComponent } from "../base-component/baseCmp";

@Component({
  selector: 'app-service-limit-cmp',
  templateUrl: './pfProcServiceLimitCmp.html'
})
export class PFProcServiceLimitCmp  extends BaseComponent {

  @Input() cptSearchResult: ICptMedicalResponse | IFinalSearchResponse;
  @Input() serviceLimits: Array<IServiceLimit>;
  constructor(
    route: ActivatedRoute, eventHandler: EventHandler, @Inject(AppSession)
    private _appSession: AppSession, contentHelper: ContentHelper) {
    super(route, eventHandler, _appSession, contentHelper, 'PFSearchResultsContainerComponent');
  }

}
