import { Component, Inject, Input, ViewChild } from "@angular/core";
import { ModalComponent } from "@anthem/uxd/deprecated";
import { IWindow } from '../../../common/interfaces/iWindow';
import { ActionTaken } from "../enums/personalizationEnums";
import {
  INextStep,
  IPersonFeedbackToAction
} from "../interfaces/iPersonalizationChecklist";
import { PersonalizationChecklistModel } from "../models/personalizationChecklistModel";
import { PersonalizationChecklistSvc } from "../services/personalizationChecklistSvc";
import { AppSession } from "./../../../common/values/appSession";
import { FINDCARE_PAGE } from "./../enums/personalizationEnums";
@Component({
  moduleId: module.id,
  selector: "[app-fad-pf-personalization-checklist-items-cmp]",
  templateUrl: "../views/pfPersonalizationChecklistItemsCmp.html",
})
export class PFPersonalizationCheckListItemsComponent {
  @Input()
  isEmulationMode: boolean;

  @ViewChild("archiveAnActionModal")
  archiveAnActionModal: ModalComponent;

  isCheckListCTAProcessing: boolean = false;
  selectedNextStep: INextStep;
  errOccured = false;

  constructor(
    public personalizationChecklistModel: PersonalizationChecklistModel,
    private _personalizationChecklistSvc: PersonalizationChecklistSvc,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
  ) {}

  /*
   * @param nextStep action details from strapi
   * @param nextStepIndex details from wcs
   * @returns seed object
   */
  onClickTriggerAction(nextStep: INextStep, nextStepIndex: number) {
    const personFeedbackToAction: IPersonFeedbackToAction = {
      actionId: nextStep.actionId,
      actionTaken: ActionTaken.AKNOWLEDGED,
      personalizedSessionId:
        this.personalizationChecklistModel?.personalizedSessionId,
      idToken: this._appSession?.metaData?.idToken,
      nbaDestination: FINDCARE_PAGE
    };
    this.selectedNextStep = nextStep;
    this.personalizationChecklistModel.lastSelectedNextStepsIndex =
      nextStepIndex;
    this.saveFeedback(personFeedbackToAction);
  }

  /**
   *
   * @param nextStep action details from strapi
   * @param nextStepIndex details from wcs
   * @returns
   */
  onArchiveClick(nextStep: INextStep, nextStepIndex: number) {
    this.errOccured = false;
    this.selectedNextStep = nextStep;
    this.personalizationChecklistModel.lastSelectedNextStepsIndex =
      nextStepIndex;
    this.archiveAnActionModal.show();
  }

  /**
   *This method is for closing archive modal
   */
  closeArchiveModal() {
    this.archiveAnActionModal.hide();
  }

  onArchiveOkClick() {
    const personFeedbackToAction: IPersonFeedbackToAction = {
      actionId: this.selectedNextStep?.actionId,
      actionTaken: ActionTaken.ARCHIVED,
      personalizedSessionId:
        this.personalizationChecklistModel?.personalizedSessionId,
      idToken: this._appSession?.metaData?.idToken,
      nbaDestination: FINDCARE_PAGE,
    };
    this.errOccured = false;
    this.saveFeedback(personFeedbackToAction);
  }

  /**
   * This method is for saving the feedback action
   * @param personFeedbackToAction
   * @param selectedNextStep
   */
  private async saveFeedback(personFeedbackToAction: IPersonFeedbackToAction) {
    this.isCheckListCTAProcessing = true;
    try {
      await this._personalizationChecklistSvc.updateActionApi(
        personFeedbackToAction
      );
      this.personalizationChecklistModel.removeLastSelectedFromYourNextSteps();
    } catch (error) {}

    if (personFeedbackToAction.actionTaken === ActionTaken.ARCHIVED) {
      this.closeArchiveModal();
    } else if (this.selectedNextStep?.destination) {
      //The api called will post a message to kafka topic then redirect to respective URL defined in strapi.
      this._window.location.href = this.selectedNextStep.destination;

    }
    this.isCheckListCTAProcessing = false;
  }
}
