import { Inject, Injectable } from '@angular/core';
import { IAppMetadata } from '../../../common/interfaces/iAppMetadata';
import { IMemberCriteria } from '../../../common/interfaces/iCommonSearchRequest';
import { AppSession } from '../../../common/values/appSession';
import { IOutputFlags } from '../../../fad/provider-details/models/iProviderDetailsRequest';
import { OUT_OF_NETWORK } from '../../../fad/search-providers/values/providerSearchConstants';
import { providerOutputFlags } from '../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { IProviderDetailRequest } from '../../common/interfaces/iProviderDetail';
import { ProviderDetailService } from '../../common/services/providerDetailSvc';
import { IDetail, IProviderOverview } from '../interfaces/iProviderDetail';
import { ProviderDetailsUtils } from './../../../fad/utilities/providerDetailsUtils';
import { IProviderDetailResponse } from './../../common/interfaces/iProviderDetail';

@Injectable({
  providedIn: 'root'
})
export class ProviderDetailHandler {
  constructor(
    private _providerDetailSvc: ProviderDetailService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  async getProviderDetail(providerIdentifier: string, addressIdentifier: string): Promise<IProviderOverview> {
    try {
      const detailsRequest = this.buildRequest(this._appSession, providerIdentifier, addressIdentifier);
      const apiResponse = await this._providerDetailSvc.getProviderDetail(detailsRequest);
      return this.transform(apiResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private buildRequest(appSession: AppSession, providerIdentifier: string, addressIdentifier: string): IProviderDetailRequest {
    const searchParams = appSession?.searchParams;
    const metaData = appSession?.metaData;

    if (!searchParams) {
      return {} as IProviderDetailRequest;
    }

    const detailsRequest: IProviderDetailRequest = {
      addressId: addressIdentifier,
      alphaPrefix: CommonUtil.getAlphaPrefix(appSession),
      brandCode: metaData?.brandCd,
      coverageType: SearchRequestUtil.isOutOfNetwork(appSession) ? OUT_OF_NETWORK : undefined,
      distance: parseInt((searchParams?.distanceNm || '0').trim(), 10),
      id: providerIdentifier,
      isShipDirectToDoor: appSession?.selectedProvider?.isShipDirectToDoor,
      latitude: searchParams?.coordinates?.latitude?.toString() || '',
      locale: metaData?.locale?.toString() || '',
      longitude: searchParams?.coordinates?.longitude?.toString() || '',
      memberCriteria: this.buildMemberCriteria(appSession, metaData),
      networks: searchParams.plan?.networkList,
      outputFlags: this.buildOutputFlags(appSession),
      planStateCode: searchParams?.plan?.stateCd || '',
      procedureCode: searchParams.eycSearchParam?.eycProcedureCriteria?.procedureCode,
      specialtyCategories: searchParams?.specialtySelectNm
    };

    return detailsRequest;
  }

  private buildOutputFlags(appSession: AppSession): IOutputFlags {
    if (providerOutputFlags) {
      return providerOutputFlags
        ? {
            includeReviewRatings: providerOutputFlags.reviewRatingsFlag,
            imposeAddressConsolidation: providerOutputFlags.imposeAddressConsolidation,
            includeProviderNetworks: providerOutputFlags.includeProviderNetworks,
            includeAllNetworks: !appSession.isEyc && (appSession.hasStandaloneDentalContract || appSession.hasStandaloneVisionContract) && appSession.hasMedicalContracts
          }
        : ({} as IOutputFlags);
    }
  }

  private buildMemberCriteria(appSession: AppSession, metaData: IAppMetadata): IMemberCriteria {
    const memberCriteria: IMemberCriteria = {} as IMemberCriteria;

    if (!metaData || appSession.isPlanUnknown) {
      return memberCriteria;
    }

    const { appContract } = metaData;

    if (appContract?.mbrUid) {
      memberCriteria.mbrUid = appContract.mbrUid;
    }

    if (appContract?.contractUid) {
      memberCriteria.contractUid = appContract.contractUid;
    }

    return memberCriteria;
  }

  private transform(providerResponse: IProviderDetailResponse): IProviderOverview {
    if (!providerResponse || !providerResponse.providerDetail) {
      return {} as IProviderOverview;
    }

    const details = {
      acceptsNewPatients: providerResponse.providerDetail.anp?.code,
      accreditations: ProviderDetailsUtils.getAccreditations(providerResponse.providerDetail.accreditation),
      adaAccessibilities: providerResponse.providerDetail.location?.adaAccessibilities,
      address: providerResponse.providerDetail.location?.address,
      addressIdentifier: providerResponse.providerDetail.location?.address?.addressId,
      agePrefHighRange: providerResponse.providerDetail.agePrefHighRange,
      agePrefLowRange: providerResponse.providerDetail.agePrefLowRange,
      anp: providerResponse.providerDetail.location.anp,
      areaOfExpertises: providerResponse.providerDetail.areaOfExpertises,
      biography: providerResponse.providerDetail.biography,
      careTeamProvider: providerResponse.providerDetail.location.careTeamProvider,
      callToAction: providerResponse.providerDetail.callToAction,
      educations: providerResponse.providerDetail.educations,
      ethnicityCodes: providerResponse.providerDetail.ethnicityCodes,
      essentialProviderPrograms: providerResponse.providerDetail.essentialProviderPrograms,
      gender: providerResponse.providerDetail.gender,
      ihspProvider: providerResponse.providerDetail.ihspProvider,
      isEssentialProvider: providerResponse.providerDetail.isEssentialProvider,
      isFutureEffective: providerResponse.providerDetail.isFutureEffective,
      isIPA: providerResponse.providerDetail.isIPA,
      isOnlineProviderIndicator: providerResponse.providerDetail.isOnlineProviderIndicator,
      isRegionalProviderIndicator: providerResponse.providerDetail.isRegionalProviderIndicator,
      isRemoteProviderIndicator: providerResponse.providerDetail.isRemoteProviderIndicator,
      isVirtualProvider: providerResponse.providerDetail.isVirtualProvider,
      languages: providerResponse.providerDetail.location.languages,
      languageAtPractice: providerResponse.providerDetail.location.languageAtPractice,
      levelOfCares: providerResponse.providerDetail.levelOfCares,
      licenseNumbers: providerResponse.providerDetail.licenseNumbers,
      licenseTypes: providerResponse.providerDetail.licenseTypes,
      name: {
        firstName: providerResponse.providerDetail.firstName,
        lastName: providerResponse.providerDetail.lastName,
        middleName: providerResponse.providerDetail.middleName,
        providerName: providerResponse.providerDetail.providerName
      },
      nationalProviderIdentifier: providerResponse.providerDetail.location.npiIds,
      officeFeatureCodeList: providerResponse.providerDetail.location.officeFeatureCodeList,
      officeHours: providerResponse.providerDetail.location.officeHours,
      participatingNetworks: providerResponse.providerDetail.location.participatingNetworks,
      patientGenderPreference: providerResponse.providerDetail.patientGenderPreference,
      pcp: providerResponse.providerDetail.pcp,
      pcpInfoList: providerResponse.providerDetail.pcpInfoList,
      performance: providerResponse.providerDetail.location.performance,
      profilePicUrl: providerResponse.providerDetail.profilePicUrl,
      providerCategoryCode: providerResponse.providerDetail.categoryCodes?.length ? { code: providerResponse.providerDetail.categoryCodes[0] } : undefined,
      providerIdentifier: providerResponse.providerDetail.id,
      providerName: providerResponse.providerDetail.providerName,
      providerTier: providerResponse.providerDetail.providerTier,
      providerTypeCode: providerResponse.providerDetail.providerTypeCode,
      providerTypeCodeList: providerResponse.providerDetail.typeCodes,
      reviewRatings: providerResponse.providerDetail.location.reviewRatings,
      sourceSystemName: providerResponse.providerDetail.sourceSystemName,
      specialities: ProviderDetailsUtils.getSpecialty(providerResponse.providerDetail.specialty),
      teleHealthProvider: providerResponse.providerDetail.teleHealthProvider,
      titleList: providerResponse.providerDetail.titleList,
      visibilityRule: providerResponse.providerDetail.visibilityRule,
      visionServiceList: providerResponse.providerDetail.visionServiceList,
      costInfo: this._appSession.selectedProvider?.eycCostInfo
    } as IDetail;

    return { providerDetail: details, ruleList: providerResponse.ruleList } as IProviderOverview;
  }
}
