import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { OnlinePharmacyLabelModule } from '../../common/components/online-pharmacy-label/onlinePharmacyLabelModule';
import { VirtualCareLabelModule } from '../../common/components/virtual-care-label/virtualCareLabelModule';
import { PFDetailsModule } from '../provider-details/pfDetailsModule';
import { MapModule } from './../../../app/common/components/map/pfMapModule';
import { AssignPcpModule } from './../../common/components/pcp/pfAssignPcpModule';
import { RatingsModule } from './../../common/components/ratings/pfRatingsModule';
import { SsoModule } from './../../common/components/sso/ssoModule';
import { ToggleModule } from './../../common/components/toggle-control/toggleModule';
import { CustomPipesModule } from './../../common/pipes/customPipesModule';
import { PhonePatternPipe } from './../../common/pipes/phonePatternPipe';
import { WeekdaysPipe } from './../../common/pipes/weekdaysPipe';
import { PFCompareComponent } from './components/pfCompareCmp';
@NgModule({
  imports: [
    UxSharedModule,
    FormsModule,
    CommonModule,
    ToggleModule,
    PFDetailsModule,
    MapModule,
    SsoModule,
    RatingsModule,
    CustomPipesModule,
    AssignPcpModule,
    VirtualCareLabelModule,
    OnlinePharmacyLabelModule
  ],
  declarations: [PFCompareComponent],
  exports: [PFCompareComponent],
  providers: [PhonePatternPipe, WeekdaysPipe]
})
export class PFCompareModule {
  static forRoot(): ModuleWithProviders<PFCompareModule> {
    return {
      ngModule: PFCompareModule,
      providers: []
    };
  }
}
