import { EventEmitter, Inject, Injectable } from '@angular/core';
import { CacheService } from '../../../app/fad/cache/services/cacheSvc';
import { ISearchParameters } from '../../../app/fad/search-providers/interfaces/iSearchParameters';
import { AppExternalCommands, ORIGIN } from '../constants/app-constants';
import { AppEvents } from '../enums/appEvents';
import { IEventDetail } from '../interfaces/iEventDetail';
import { ISearchParamsRequest } from '../interfaces/iSearchParamsReq';
import { ISearchParamsResponse } from '../interfaces/iSearchParamsRes';
import { AppSession } from '../values/appSession';
import { EventHandler } from './eventHandler';
import { NavigationService } from './navigationService';
@Injectable({
  providedIn: 'root'
})
export class ClinicalProgramNavigator {
  private _pfAppCommandEvent: EventEmitter<IEventDetail>
    = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _cacheSvc: CacheService,
    private _navigationService: NavigationService
  ) { }

  /**
   * this method will call cache search params API to cache search parameters
   * commad event will be triggered to RCP with target URL, origin and cache key
   * @param programId
   */
  async navigateToClinicalProgram(programId: string): Promise<boolean> {
    try {
      const searchParamsReq: ISearchParamsRequest = this.buildMemberKeyRequest();

      if (searchParamsReq) {
        const searchParamsRes: ISearchParamsResponse = await this._cacheSvc.cacheSearchParams(searchParamsReq);
        const eventDetail: IEventDetail = this.buildEventObject(searchParamsRes, programId);

        if (eventDetail) {
          this._pfAppCommandEvent.emit(eventDetail);
          return Promise.resolve(true);
        }
      }
    } catch (error) { }
    return Promise.resolve(false);
  }

  /**
   * build cache search params API request
   */
  private buildMemberKeyRequest(): ISearchParamsRequest {
    let searchParamsReq: ISearchParamsRequest = {} as ISearchParamsRequest;

    searchParamsReq.routeUrl = this._navigationService.currentRoute as string;
    const searchParams: ISearchParameters = { ...this._appSession.searchParams };

    // remove propertes which are not needed in cache
    if (searchParams) {
      delete searchParams.providerTypeDdlValues;
      delete searchParams.speicalityDdlValues;
      delete searchParams.subSpeicalityDdlValues;
      delete searchParams.categoryDescription;

      if (searchParams.eycSearchParam) {
        delete searchParams.eycSearchParam.costDescription;
        delete searchParams.eycSearchParam.procedureDescription;

        if (searchParams.eycSearchParam.eycProcedureCriteria) {
          delete searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
        }
      }
    }

    searchParamsReq.searchParameters = searchParams;

    return searchParamsReq;
  }

  /**
   * build command event object with type, target, origin, memberkey
   * @param searchParamsRes
   * @param programId
   */
  private buildEventObject(searchParamsRes: ISearchParamsResponse, programId: string): IEventDetail {
    let eventDetail: IEventDetail;

    if (!searchParamsRes || !searchParamsRes.cacheKey || !programId ||
      (!this._appSession.hasEngagement && !this._appSession.hasHealthWellness)) {
      throw new Error('Not enough details to trigger command event');
    }

    eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.object = {
      origin: ORIGIN.SEARCH_RESULTS,
      cacheKey: searchParamsRes.cacheKey
    }

    // switch navigation based on contract feature flags
    if (this._appSession.hasEngagement) {
      eventDetail.message = AppExternalCommands.RCP_ENGAGEMENT_CLINICAL_PROGRAMS.MSG;
      eventDetail.target = AppExternalCommands.RCP_ENGAGEMENT_CLINICAL_PROGRAMS.CMD.replace(/{PGMID}/i, programId);
    } else if (this._appSession.hasHealthWellness) {
      eventDetail.message = AppExternalCommands.RCP_HEALTHWELLNESS_CLINICAL_PROGRAMS.MSG;
      eventDetail.target = AppExternalCommands.RCP_HEALTHWELLNESS_CLINICAL_PROGRAMS.CMD.replace(/{PGMID}/i, programId);
    }

    return eventDetail;
  }
}
