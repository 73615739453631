import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-list-cmp',
  templateUrl: './providerListCmp.html'
})
export class ProviderListComponent {
  @Input() providers: IProvider[] = [];
  @Input() mapVisible = true;
  @Output() providerHover = new EventEmitter<IProvider>();
  @Output() providerLeave = new EventEmitter<IProvider>();
  @Output() selectedProvider = new EventEmitter<IProvider>();
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  activeIndex: number | undefined = undefined;

  onProviderHover(provider: IProvider) {
    this.providerHover.emit(provider);
  }

  onProviderLeave(provider: IProvider) {
    this.providerLeave.emit(provider);
  }

  /**
   * Method to share provider card data.
   * Getting the selected provider details that needs to be shown in provider details card.
   * @param provider Provider data.
   */

  onShowProviderCard(provider: IProvider) {
    this.selectedProvider.emit(provider);
  }

  /**
   * Method to set selected Provider.
   * Method to highlight currently selected provider card from the scrollable cards list on left side.
   * @param index selected card index.
   */

  setProviderSelected(index:number) {
    this.activeIndex = index;
  }

  onSendCardStatus(status: boolean) {
    this.cardSelected.emit(status);
  }
}
