import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomSlicePipe } from './customSlicePipe';
import { HighlightPipe } from './highlightPipe';
import { HoursMinutesPipe } from './hoursMinutesPipe';
import { PhonePatternPipe } from './phonePatternPipe';
import { TrimText } from './trimText';
import { WeekdaysPipe } from './weekdaysPipe';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [HighlightPipe, PhonePatternPipe, TrimText, WeekdaysPipe, HoursMinutesPipe, CustomSlicePipe],
  exports: [HighlightPipe, PhonePatternPipe, TrimText, WeekdaysPipe, HoursMinutesPipe, CustomSlicePipe]
})
export class CustomPipesModule {
}
