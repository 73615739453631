import { KeyValuePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { THREE } from '../../../common/constants/app-constants';
import { IBaseCode } from '../../../common/interfaces/iBaseCode';
import { AppUtility } from '../../../common/utilities/appUtil';
import { CommonUtil } from '../../utilities/commonUtil';
import { IGenericDataResponse } from '../interfaces/iGenericDataResponse';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { VALID } from "../values/providerSearchConstants";
import { BaseComponent } from "./../../../common/components/base-component/baseCmp";
import { Coordinates, ILocationDetails, LocationResponse } from "./../../../common/models/locationAddress";
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from "./../../../common/services/eventHandler";
import { NavigationService } from "./../../../common/services/navigationService";
import { AppSession } from "./../../../common/values/appSession";
import { ContentHelper } from "./../../../common/values/contentHelper";
import { IAddressForm } from './../interfaces/iAddressForm';

@Component({
  moduleId: module.id,
  selector: "app-fad-address-form-cmp",
  templateUrl: "../views/pfAddressFormCmp.html",
})
export class PFAddressFormComponent extends BaseComponent implements OnInit, OnChanges {
  @Output()
  submitForm: EventEmitter<IAddressForm> = new EventEmitter<IAddressForm>();
  @Input()
  customAddressSearch: boolean;
  @Input()
  clearForm: boolean;
  @Input()
  continueClicked: boolean;
  addressForm: FormGroup;
  addressObj = {
    streetAddress: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    isFormValid: false
  } as IAddressForm;
  stateList = [];
  showLoader: boolean = true;
  showError: boolean = false;
  stateDisplay = this.content?.planSelectionComponent?.labels?.defaultStateText;
  locationError: boolean = false;
  locationSatateError: boolean = false;
  errorText: string;
  geoSuggestionArr: string[];
  geoSuggestionTopList: Array<ILocationDetails> = [];
  geoSuggestionMoreList: Array<ILocationDetails> = [];
  locationApiStatus: boolean = false;
  focusCity: boolean = false;
  timer = null;
  locationMore: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _navigationService: NavigationService,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _appUtility: AppUtility,
    private _keyValuePipe: KeyValuePipe,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchContainerComponent"
    );
  }

  ngOnChanges() {
    this.addressObj.isFormValid = false;
    if (this.customAddressSearch && this.addressForm) {
      this.addressForm.get('streetAddress').enable();
      this.addressForm.get('streetAddress2').enable();
      this.addressForm.get('city').enable();
      this.addressForm.get('zipcode').enable();
      this.addressForm.get('state').enable();
      this.checkFormValidation();
    }
    else if (this.addressForm) {
      this.addressForm.get('streetAddress').disable();
      this.addressForm.get('streetAddress2').disable();
      this.addressForm.get('city').disable();
      this.addressForm.get('zipcode').disable();
      this.addressForm.get('state').disable();
    }
    this.setIsFormValid();
    if (this.clearForm) {
      this.addressForm.reset();
      this._appSession.customAddressSearch = false;
    }
  }

  ngOnInit() {
    this.setIsFormValid();
    let cityName = this.addressObj.city;
    if (this.addressObj.city && this.addressObj.city.split(',').length < 2) {
      cityName = this.addressObj.city + ',' + this.addressObj.state;
    }
    this.addressForm = new FormGroup({
      streetAddress: new FormControl({ value: this.addressObj.streetAddress, disabled: !this.customAddressSearch }, [Validators.required]),
      streetAddress2: new FormControl({ value: this.addressObj.addressLine2, disabled: !this.customAddressSearch }),
      city: new FormControl({ value: cityName, disabled: !this.customAddressSearch }, [Validators.required, Validators.pattern('^([A-Za-z\\s\.,]{3,20})$')]),
      state: new FormControl({ value: this.addressObj.state, disabled: !this.customAddressSearch }, [Validators.required]),
      zipcode: new FormControl({ value: this.addressObj.zipCode, disabled: !this.customAddressSearch }, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5), Validators.maxLength(5), this.customValidation()])
    });

    this.checkFormValidation();

    if (this._appSession?.searchParams?.stateDdlValues === undefined || this._appSession?.searchParams?.stateDdlValues?.length === 0) {
      this._appSession.searchParams.stateDdlValues = [];
      this.showLoader = true;
      this._providerUtilityService
        .getStateList(this._appSession?.searchParams.brandCode)
        .then(
        (result: IGenericDataResponse<Array<IBaseCode>>) => {

          if (!this._dataHelper.isEmptyObject(result)) {
            result.data.forEach((state: IBaseCode) => {
              this._appSession.searchParams.stateDdlValues.push({
                label: state.name,
                value: state.code,
              });
            });
          }
            this.stateList = []
            this.stateList = this._appSession?.searchParams?.stateDdlValues || [];
            this.showLoader = false;
          },
          (error: any) => {
            this.onError(error);
          }
        );
    } else {
      this.stateList = this._appSession?.searchParams?.stateDdlValues || [];
      this.showLoader = false;
    }
  }

  setIsFormValid() {
    if (this._appSession.addressObj.homeAddress && !this.customAddressSearch) {
      this.addressObj.isFormValid = this._appSession.addressObj.homeAddress.isFormValid ? true : false;
    }
    if (this._appSession.addressObj.workAddress && !this.customAddressSearch) {
      this.addressObj.isFormValid = this._appSession.addressObj.workAddress.isFormValid ? true : false;
    }
    if (this._appSession?.addressObj?.customAddress) {
      this.addressObj = this._appSession.addressObj.customAddress;
      this.addressObj.isFormValid = this._appSession.addressObj.customAddress.isFormValid;
    }
    this._appSession.addressObj.isFormValid = this.addressObj.isFormValid;
  }

  onError(error: any) {
    this.showLoader = false;
    this.showError = true;
    throw error;
  }

  get formControls() {
    return this.addressForm["controls"];
  }

  setCoordinates(coordinates: Coordinates): void {
    this.geoSuggestionArr = [];
    this.geoSuggestionMoreList = [];
    this.geoSuggestionTopList = [];
    this.locationApiStatus = true;
    this.addressForm.controls['city'].setValue(coordinates.name);
    this.addressForm.controls['state'].setValue(coordinates.stateCd);
    this.addressObj.state = coordinates.stateCd;
    this.addressForm?.get('city').setErrors(null);
  }

  customValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value !== "DEFAULT";
      return !forbidden ? { state: true } : null;
    }
  }

  hasGeoLocation(): boolean {
    let _hasGeoLocation = false;
    let _geoLocationArr = [];

    if (!this.locationError && this.geoSuggestionArr) {
      _geoLocationArr = this._keyValuePipe.transform(this.geoSuggestionArr);
      _hasGeoLocation = _geoLocationArr && _geoLocationArr.length > 0;
    }
    return _hasGeoLocation;
  }

  getCities(): void {
    this.addressObj.city = this.addressForm.get('city').value;
    this.addressObj.state = '';
    this.addressForm.controls['state'].setValue('');
    this.checkFormValidation();
    if (this.locationApiStatus || this.addressObj.city.length <= THREE) {
      this.locationApiStatus = false;
      return;
    }

    if (this.timer) { clearTimeout(this.timer); }
    this.timer = setTimeout(() => {
      if (this.locationApiStatus || this.addressObj.city.length <= THREE) {
        this.locationApiStatus = false;
        return;
      }
      this._providerUtilityService.getGeoLocation(this.addressObj.city)
        .then((result: LocationResponse[]) => {
          const results = this._appUtility.buildLocationResponse(result);
          if (results && Object.keys(results).length > 0) {
            this.geoSuggestionArr = results;
            let _geoLocationArr = [];
            this.locationError = false;
            _geoLocationArr = this._keyValuePipe.transform(this.geoSuggestionArr);
            if (_geoLocationArr && _geoLocationArr.length > 0) {
              this.geoSuggestionMoreList = _geoLocationArr;
              this.geoSuggestionTopList = _geoLocationArr.slice(0, 5);
            }
          } else {
            this.geoSuggestionArr = [];
            this.geoSuggestionMoreList = [];
            this.geoSuggestionTopList = [];
          }
        }).catch((err) => {
          if (err) {
            this.locationError = true;
            this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
          }
        });
    }, 1000);
  }

  showLocationMore(): void {
    this.focusCity = true;
    this.locationMore = !this.locationMore;
  }

  onCityFocusOut(): void {
    setTimeout(() => {
      if (!this.focusCity) {
        this.geoSuggestionArr = [];
        this.geoSuggestionMoreList = [];
        this.geoSuggestionTopList = [];
        this.locationApiStatus = true;
      }
      this.focusCity = true;
      this.checkFormValidation();
      if (this.addressObj?.city && this.addressObj?.city !== '' &&
            this.addressObj?.state && this.addressObj?.state !== '') {
        this.addressForm?.get('city').setErrors(null);
      } else {
        this.addressForm?.get('city').setErrors({ invalid: true });
      }
    }, 1000);
  }

  async updateForm() {
    if (!this.customAddressSearch) {
      this.addressForm.get('state').disable();
    }
    this.addressObj.streetAddress = this.addressForm.get('streetAddress').value;
    this.addressObj.addressLine2 = this.addressForm.get('streetAddress2').value;
    this.addressObj.city = this.addressForm.get('city').value;
    this.addressObj.state = this.addressForm.get('state').value;
    this.addressObj.zipCode = this.addressForm.get('zipcode')?.value;
    await this.checkFormValidation();
    this.submitForm.emit(this.addressObj);
  }

  private async checkFormValidation() {
    if (CommonUtil.isNotBlank(this.addressForm?.get('streetAddress').value) && CommonUtil.isNotBlank(this.addressForm?.get('city').value) && CommonUtil.isNotBlank(this.addressForm?.get('zipcode').value) && CommonUtil.isNotBlank(this.addressForm?.get('state').value) && this.addressForm?.get('zipcode').value.length === 5 && this.addressForm?.status === VALID) {
      if (this.addressForm?.get('state').value === "DEFAULT") {
        this.addressObj.isFormValid = false;
      } else {
        if (this.customAddressSearch) {
          await this._providerUtilityService.getGeoLocation(this.addressObj.zipCode).then((result: LocationResponse[]) => {
            if (result && Object.keys(result).length > 0) {
              if (result[0].stateCd === this.addressObj.state) {
                this.locationError = false;
                this.addressForm?.get('state').valid;
                this.locationSatateError = false;
                this.addressObj.isFormValid = true;
              } else {
                this.locationSatateError = true;
                this.addressForm?.get('state').invalid;
                this.addressObj.isFormValid = false;
                this.errorText = this.content.searchCriteriaComponent.alerts.stateZipCodeError;
              }
            } else {
              this.locationError = true;
              this.addressObj.isFormValid = false;
              this.errorText = this.content.searchCriteriaComponent.alerts.zipcodeError;
            }
          })
        } else {
          this.locationError = false;
          this.addressForm?.get('state').valid;
          this.locationSatateError = false;
          this.addressObj.isFormValid = true;
        }
      }
    }
    else {
      this.addressObj.isFormValid = false;
      this.locationSatateError = false;
      this.locationError = false;
    }
    this._appSession.addressObj.isFormValid = this.addressObj.isFormValid;
  }
}
