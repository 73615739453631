import { Component, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { GeocodeApi } from '../../services/geocodeApi';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { GEO_LOCATION_SEARCH_TYPE, IGeoLocation, IGeoLocationInfo, IGeoLocationInfoWithCounties, IlocationForm, IOtherAddress, LOOKUP_MIN_COUNT, REGEX_MATCH_PATTERN, SEARCH_INPUT, SEARCH_INPUT_TYPE } from './models/geoLocation';
import { GeoLocationService } from './services/geoLocationService';
import { LocationAdapterSvc } from './services/locationAdapterSvc';
@Component({
  moduleId: module.id,
  selector: 'app-switch_location-v1',
  templateUrl: './switchLocationCmp.html',
  providers: []
})
export class PFSwitchLocationComponent extends BaseComponent {
  zinputTemplate: HTMLElement;

  @Input()
  homeAddress: IGeoLocationInfo;
  otherAddress: IOtherAddress = { isValidZipSelected: true };
  geoCodeAddress: IGeoLocationInfo;
  isSecureState: boolean = false;
  locationForm: FormGroup;
  locationMore: boolean = false;
  countyMore: boolean = false;
  dropDownSelected: string = null;
  radioAddress = {
    isOptional: false,
    alignVertical: true,
    id: 'addressGrp',
    name: 'addressGrp',
    label: '',
    options: []
  };
  locationError = false;
  isInitialLoad = true;
  zipCodeError = false;
  isLoading = false;
  countyList: IGeoLocationInfo[] = [];
  zipStateCountyList: IGeoLocationInfo[] = [];
  topCountyList: IGeoLocationInfo[] = [];
  topZipStateCountyList: IGeoLocationInfo[] = [];
  postalCode: string;
  geoLocation: IGeoLocation = {
    name: '',
    city: '',
    stateCd: '',
    stateName: '',
    county: '',
    zipcode: '',
    addressLine1: '',
    addressLine2: '',
    countyCode: '',
    isHomeAddressSearch: false,
    isGeoCodeSearch: false,
    isOtherAddressSearch: false,
    isCountySearch: false,
    isZipStateCountySearch: false,
    isValidZipCode: false,
    searchType: GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY
  };
  searchPattern: string;
  continueBtnDisabled: boolean = false;
  showError: boolean;
  zipCityCountySubscription: Subscription;
  defaultLocationTypeSubscription: Subscription;
  otherAddressZipCodeSubscription: Subscription;
  otherAddrCityEventSubscription: Subscription;
  otherAddressCityCountySubscription: Subscription;
  formGroupSubscription: Subscription;
  memberChangeEventSubscription: Subscription;
  focusCity?: boolean;
  iterationsToBeSkipped: number = 1;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _elementRef: ElementRef,
    private formBuilder?: FormBuilder,
    private _locationAdapterSvc?: LocationAdapterSvc,
    private _geocodeApi?: GeocodeApi,
    private _geoLocationService?: GeoLocationService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    this.isSecureState = this._appSession.isSecureState;
    this.resetLocation();
    this.locationForm = this.formBuilder.group({
      searchType: ['zipOrCityOrCounty'],
      zipOrCityOrCounty: this.formBuilder.group({
        zipCityCounty: ['', [Validators.required, this.zipCityCountyValidator('zipCityCounty')]]
      }),
      currentLocation: this.formBuilder.group({
        cityState: [{ value: '', disabled: true }, Validators.required],
        zipCode: [{ value: '', disabled: true }, Validators.required]
      }),
      otherAddress: this.formBuilder.group({
        addressLine1: ['', [Validators.required, Validators.minLength(SEARCH_INPUT.LOW), Validators.maxLength(SEARCH_INPUT.HIGH)]],
        addressLine2: ['', [Validators.minLength(SEARCH_INPUT.LOW), Validators.maxLength(SEARCH_INPUT.HIGH)]],
        zipCode: ['', [Validators.required,Validators.pattern(REGEX_MATCH_PATTERN.ZIP_CODE)]],
        cityOrCountyState: ['', [Validators.required, this.zipCityCountyValidator('cityOrCountyState')]]
      })
    });

    if (this._appSession.geolocationInfo?.isZipStateCountySearch) {
      this.dropDownSelected = this._appSession.selectLocationForm?.zipOrCityOrCounty?.zipCityCounty;
    } else if (this._appSession.geolocationInfo?.isOtherAddressSearch) {
      this.dropDownSelected = this._appSession.selectLocationForm?.otherAddress?.cityOrCountyState;
    }
    this.geoLocation.isValidZipCode = true;

    //Added change handler only for drop down
    this.zipCityCountySubscription = this.subscribeToFieldDropDownEvent('zipOrCityOrCounty.zipCityCounty');
    //Added change handler only for Zip code search
    this.otherAddressZipCodeSubscription = this.subscribeToOtherAddrZipCodeEvent('otherAddress.zipCode');
    //Added change handler for otherAddress city search
    this.otherAddrCityEventSubscription = this.subscribeToOtherAddrCityEvent('otherAddress.cityOrCountyState');
    this.formGroupSubscription = this.subscribeToSearchTypeChangeEvent();

    if (this._appSession.memberAddressDetails) {
      this.homeAddress = this._appSession.memberAddressDetails;
    }
    if (this._appSession.defaultLocation) {
      const currentGeolocation = this._appSession.defaultLocation;
      this.geoCodeAddress = {
        zipcode: currentGeolocation.zip,
        stateCd: currentGeolocation.state,
        city: currentGeolocation.city,
        latitude: currentGeolocation.latitude,
        longitude: currentGeolocation.longitude
      };
    }
    if (!isEmpty(this._appSession.geolocationInfo) && this._dataHelper.areEqualStrings(this._appSession.geolocationInfo.searchType, GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)) {
      let geolocation = this._appSession.geolocationInfo;
      this.dropDownSelected = this._appSession.selectLocationForm?.otherAddress?.cityOrCountyState;
      this.otherAddress = {
        cityInput: {
          city: geolocation.city,
          stateCd: geolocation.stateCd
        },
        zipInput: {
          zipcode: geolocation.zipcode,
          stateCd: geolocation.stateCd,
          latitude: geolocation.latitude,
          longitude: geolocation.longitude
        },
        isValidZipSelected: true
      };
    }
    //set default and select the search type
    if (this._appSession.geolocationInfo && !isEmpty(this._appSession.geolocationInfo)) {
      this.geoLocation = { ...this.geoLocation, ...this._appSession.geolocationInfo };
      if (!this._dataHelper.isEmptyString(this.geoLocation?.searchType) && Object.values(GEO_LOCATION_SEARCH_TYPE).includes(this.geoLocation?.searchType)) {
        // set the default search type
        this.getFormGroup('searchType').setValue(this.geoLocation.searchType);
      } else {
        this.getFormGroup('searchType').setValue(GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY);
      }
      if (this._appSession.selectLocationForm && !isEmpty(this._appSession.selectLocationForm)) {
        this.locationFormValue = this._appSession.selectLocationForm;
      }
    } else {
      /* **
      When user not responded to location popup and
      Click on update location
      Then default search should be ZipStateCountySearch
      ** */
      this.geoLocation.isZipStateCountySearch = true;
    }
    this.isInitialLoad = false;
  }

  onPaste() {
      this.iterationsToBeSkipped = 0;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeyPress(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    const cancelButton = document.getElementById('btn-assign-pcp-cancel');
    const closeButton = document.getElementById('btn-assign-pcp-close');
    if (event.target === cancelButton || event.target === closeButton) {
      this.onClose();
      return;
    }
    this.onSubmit();
  }

  getFormGroup(formGroup: string): FormGroup | FormControl {
    if (!this._dataHelper.isEmptyString(formGroup)) {
      return this.locationForm.get(formGroup) as FormGroup | FormControl;
    }
  }

  getFormField(formGroup: FormGroup, field: string): any {
    if (formGroup && !this._dataHelper.isEmptyString(field)) {
      return formGroup?.get(field);
    }
  }

  setFormField(formGroup: FormGroup, field: string, value: any): void {
    if (formGroup && !this._dataHelper.isEmptyString(field) && !isEmpty(value)) {
      formGroup.get(field)?.setValue(value);
    }
  }

  subscribeToFieldDropDownEvent(field: string): Subscription {
    return this.getFormGroup(field)
      .valueChanges.pipe(tap(() => {
        //this code block will not wait for 300 microsecond and execute immediately
        if (!this.isInitialLoad) {
          this.geoLocation.isValidZipCode = false;
        }
      }),
      skip(this.iterationsToBeSkipped), debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.zipCityCountDropDownHandler(value, field);
      });
  }

  private zipCityCountDropDownHandler(value: string, field: string) {
    const _field: string = field;
    if (!this._dataHelper.isEmptyString(value) && value.length >= SEARCH_INPUT.LOW && value.length < SEARCH_INPUT.HIGH && !this._dataHelper.areEqualStrings(this.dropDownSelected, value)) {
      let searchParameter = this._geocodeApi.identifySearchParameter(value);
      let searchPattern = this._geocodeApi.identifySearchPattern(searchParameter);
      if (searchPattern.patternEnum === GEO_LOCATION_SEARCH_TYPE.UNKNOWN) {
        this.locationError = true;
        return;
      }
      this.zinputTemplate = this._elementRef.nativeElement.querySelector('.flyout-parent-width');
      if (!this._dataHelper.isEmptyString(searchPattern.patternEnum) && searchPattern.patternEnum == SEARCH_INPUT_TYPE.ZIP_CODE) {
        this.searchPattern = SEARCH_INPUT_TYPE.ZIP_CODE;
        this.geoLocation.isZipcodeSearch = true;
        this.geoLocation.isCitySearch = false;
        this.geoLocation.isCountySearch = false;
        this.getLocationLookup(value);
      } else if (
        !this._dataHelper.isEmptyString(searchPattern.patternEnum) &&
        (searchPattern.patternEnum == SEARCH_INPUT_TYPE.COUNTY_STATE || searchPattern.patternEnum == SEARCH_INPUT_TYPE.COUNTY)
      ) {
        this.searchPattern = SEARCH_INPUT_TYPE.COUNTY;
        this.geoLocation.isZipcodeSearch = false;
        // this.zinputTemplate = document.getElementById('othZip');
        this.getLocationLookup(value);
      } else if (!this._dataHelper.isEmptyString(searchPattern.patternEnum) && (searchPattern.patternEnum == SEARCH_INPUT_TYPE.CITY_STATE || searchPattern.patternEnum == SEARCH_INPUT_TYPE.CITY)) {
        this.searchPattern = SEARCH_INPUT_TYPE.CITY;
        this.geoLocation.isZipcodeSearch = false;
        // this.zinputTemplate = document.getElementById('othZip');
        this.getLocationLookup(value);
      }
      this.dropDownSelected = null;
    } else if (this._dataHelper.isEmptyString(value)) {
      this.resetDropDownOptions();
    }
  }

  subscribeToOtherAddrCityEvent(field: string): Subscription {
    return this.getFormGroup(field)
      .valueChanges.pipe(skip(this.iterationsToBeSkipped),debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.otherAddrCityEventHandler(value, field);
      });
  }

  private async otherAddrCityEventHandler(value: string, field: string) {
    const _field: string = field;
    if (!this._dataHelper.isEmptyString(value) && value.length >= SEARCH_INPUT.LOW && value.length < SEARCH_INPUT.HIGH && !this._dataHelper.areEqualStrings(this.dropDownSelected, value)) {
      try {
        let searchParameter = this._geocodeApi.identifySearchParameter(value);
        let searchPattern = this._geocodeApi.identifySearchPattern(searchParameter);
        if (searchPattern.patternEnum === GEO_LOCATION_SEARCH_TYPE.UNKNOWN) {
          this.locationError = true;
          return;
        }
        this.zinputTemplate = this._elementRef.nativeElement.querySelector('.flyout-parent-width');
        if (!this._dataHelper.isEmptyString(searchPattern.patternEnum) && (searchPattern.patternEnum == SEARCH_INPUT_TYPE.CITY || searchPattern.patternEnum == SEARCH_INPUT_TYPE.CITY_STATE)) {
          const result: IGeoLocationInfoWithCounties = await this._locationAdapterSvc.getLocationLookup(value);
          if (this.geoLocation.isOtherAddressSearch) {
            if (result?.cities.length) {
              this.zipStateCountyList = result.cities;
              this.topZipStateCountyList = result.cities?.slice(0, LOOKUP_MIN_COUNT.minCount);
              this.locationError = false;
              this.dropDownSelected = null;
            } else {
              this.locationError = true;
            }
          }
        }
      }
       catch (error: unknown) {
        this.locationError = true;
      }
    }
  }

  subscribeToOtherAddrZipCodeEvent(field: string): Subscription {
    return this.getFormGroup(field)
      .valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.otherAddrZipCodeEventHandler(value, field)
      });
  }

  private async otherAddrZipCodeEventHandler(value: string, field: string): Promise<void> {
    if (!this._dataHelper.isEmptyString(value)) {
      try {
        let searchParameter = this._geocodeApi.identifySearchParameter(value);
        let searchPattern = this._geocodeApi.identifySearchPattern(searchParameter);
        this.otherAddress.isValidZipSelected = false;
        if (!this._dataHelper.isEmptyString(searchPattern.patternEnum) && searchPattern.patternEnum == SEARCH_INPUT_TYPE.ZIP_CODE) {
          const result: IGeoLocationInfoWithCounties = await this._locationAdapterSvc.getLocationLookup(value);
          if (!this._dataHelper.isEmptyArray(result.cities)) {
            this.setOtherAddrForZipCode(result.cities[0]);
            this.zipCodeError = false;
          } else if (isEmpty(result?.cities)) {
            this.zipCodeError = true;
          }
        }
      } catch (error: unknown) {
        this.zipCodeError = true;
      }
    } else {
        this.zipCodeError = true
    }
  }

  private subscribeToSearchTypeChangeEvent(): Subscription {
    return this.getFormGroup('searchType').valueChanges.subscribe((value) => {
      this.handleSearchTypeChange(value);
    });
  }

  private handleSearchTypeChange(value: string): void {
     // reset previous error if any
     this.showError = false;
     this.locationError = false;
    if (this._dataHelper.areEqualStrings(value, GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY)) {
      this.geoLocation.searchType = GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY;
      this.geoLocation.isZipStateCountySearch = true;
      this.geoLocation.isOtherAddressSearch = false;
      this.searchPattern = '';
      this.resetDropDownOptions();
      this.setZipCityAndCountyField();
      this.dropDownSelected = this._appSession?.geolocationInfo?.searchText ?? null;
      if (!this._dataHelper.areEqualStrings(this._appSession.geolocationInfo?.searchType, GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY)) {
        this.getFormGroup(value).reset();
      }
    } else if (this._dataHelper.areEqualStrings(value, GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION)) {
      this.geoLocation.searchType = GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION;
      this.geoLocation.isGeoCodeSearch = true;
      this._geoLocationService.setCurrentLocationEvent();
      if (this.geoLocation.isGeoCodeAllowed && this.geoLocation.isGeoCodeValid) {
        this.setCurrentLocationField();
      }
    } else if (this._dataHelper.areEqualStrings(value, GEO_LOCATION_SEARCH_TYPE.HOME_ADDRESS)) {
      this.geoLocation.searchType = GEO_LOCATION_SEARCH_TYPE.HOME_ADDRESS;
      this.geoLocation.isHomeAddressSearch = true;
    } else if (this._dataHelper.areEqualStrings(value, GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)) {
      this.geoLocation.searchType = GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS;
      this.geoLocation.isOtherAddressSearch = true;
      this.geoLocation.isZipStateCountySearch = false;
      this.searchPattern = '';
      this.resetDropDownOptions();
      this.setOtherAddressFields();
      this.dropDownSelected = this._appSession?.geolocationInfo?.searchText ?? null;
      if (!this._dataHelper.areEqualStrings(this._appSession.geolocationInfo?.searchType, GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)) {
        this.getFormGroup(value).reset();
      }
    }
  }

  private resetLocation() {
    this.geoLocation = {
      name: '',
      city: '',
      stateCd: '',
      stateName: '',
      county: '',
      zipcode: '',
      addressLine1: '',
      addressLine2: '',
      countyCode: '',
      isHomeAddressSearch: false,
      isGeoCodeSearch: false,
      isOtherAddressSearch: false,
      isCountySearch: false,
      isCitySearch: false,
      isZipcodeSearch: false,
      isZipStateCountySearch: false,
      searchType: GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY
    };
  }
  private resetDropDownOptions() {
    this.countyList = [];
    this.zipStateCountyList = [];
    this.topCountyList = [];
    this.topZipStateCountyList = [];
    this.locationError = false;
  }

  ngOnDestroy(): void {
    if (this.defaultLocationTypeSubscription) {
      this.defaultLocationTypeSubscription.unsubscribe();
    }
    if (this.zipCityCountySubscription) {
      this.zipCityCountySubscription.unsubscribe();
    }
    if (this.otherAddressZipCodeSubscription) {
      this.otherAddressZipCodeSubscription.unsubscribe();
    }
    if (this.otherAddressCityCountySubscription) {
      this.otherAddressCityCountySubscription.unsubscribe();
    }
    if (this.otherAddrCityEventSubscription) {
      this.otherAddrCityEventSubscription.unsubscribe();
    }
    if (this.formGroupSubscription) {
      this.formGroupSubscription.unsubscribe();
    }
    if (this.memberChangeEventSubscription) {
      this.memberChangeEventSubscription.unsubscribe();
    }
  }

  restAllFormFields(): void {
    this.geoLocation.isHomeAddressSearch = false;
    this.geoLocation.isZipStateCountySearch = false;
    this.geoLocation.isOtherAddressSearch = false;
    this.geoLocation.isGeoCodeSearch = false;
  }

  onSubmit() {
    if (!this.disableContinue()) {
      this.restAllFormFields();
      if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY)) {
        const zipCityCountyFormGroup = this.getFormGroup(this.geoLocation.searchType) as FormGroup;
        if (
          !isEmpty(zipCityCountyFormGroup.value) &&
          (this._dataHelper.areEqualStrings(this.searchPattern, SEARCH_INPUT_TYPE.COUNTY_STATE) ||
            this._dataHelper.areEqualStrings(this.searchPattern, SEARCH_INPUT_TYPE.COUNTY) ||
            zipCityCountyFormGroup.valid)
        ) {
          this.geoLocation.isZipStateCountySearch = true;
          this.geoLocation.searchText = this.geoLocation.isZipcodeSearch ? this.geoLocation.zipcode : this.geoLocation.searchText;
          this._geoLocationService.setLocation(this.geoLocation);
          this._appSession.selectLocationForm = this.getLocationFormValue(GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY);
          this.onClose();
        }
      }
      if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION) && this.isGeoCordinateValid()) {
        this.geoLocation.isGeoCodeSearch = true;
        this.geoLocation.searchText = this.geoCodeAddress.zipcode;
        this.geoLocation.zipcode = this.geoCodeAddress.zipcode;
        this.geoLocation.city = this.geoCodeAddress.city;
        this.geoLocation.stateCd = this.geoCodeAddress.stateCd;
        this.geoLocation.latitude = this.geoCodeAddress.latitude;
        this.geoLocation.longitude = this.geoCodeAddress.longitude;
        this.geoLocation.isCountySearch = false;
        this.geoLocation.isCitySearch = false;
        this._geoLocationService.setLocation(this.geoLocation);
        this._appSession.selectLocationForm = this.getLocationFormValue(GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION);
        this.onClose();
      }
      if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.HOME_ADDRESS)) {
        this.geoLocation.isHomeAddressSearch = true;
        this.geoLocation.searchText = this.homeAddress.zipcode;
        this.geoLocation.zipcode = this.homeAddress.zipcode;
        this.geoLocation.stateCd = this.homeAddress.stateCd;
        this.geoLocation.latitude = this.homeAddress.latitude;
        this.geoLocation.longitude = this.homeAddress.longitude;
        this.geoLocation.isCountySearch = false;
        this.geoLocation.isCitySearch = false;
        this._geoLocationService.setLocation(this.geoLocation);
        this._appSession.selectLocationForm = this.getLocationFormValue(GEO_LOCATION_SEARCH_TYPE.HOME_ADDRESS);
        this.onClose();
      }
      if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)) {
        this.geoLocation.isOtherAddressSearch = true;
        const otherAddressForm = this.getFormGroup(GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)?.value;
        this.getFullAddressDetails(this.buildFullAddrSearchRequest(otherAddressForm.addressLine1, otherAddressForm.addressLine2));
      }
    }
  }

  set locationFormValue(formValue: IlocationForm) {
    if (!isEmpty(formValue)) {
      this.locationForm.patchValue(formValue);
    }
  }

  getLocationFormValue(searchType: string): IlocationForm {
    if (!this._dataHelper.isEmptyString(searchType)) {
      return {
        searchType,
        [searchType]: this.getFormGroup(searchType)?.value
      } as IlocationForm;
    }
  }

  private buildFullAddrSearchRequest(addressLine1: string, addressLine2?: string) {
    let geoLocationRequest: IGeoLocation = {};
    if (this.otherAddress.isValidZipSelected) {
      const zipInput = this.otherAddress.zipInput;
      const cityInput = this.otherAddress.cityInput;
      geoLocationRequest = {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        zipcode: zipInput?.zipcode,
        city: cityInput?.city ?? '',
        stateCd: zipInput?.stateCd,
        latitude: zipInput?.latitude,
        longitude: zipInput?.longitude
      };
    }
    return geoLocationRequest;
  }

  private selectDropDownOptions(coordinate: any, formfield: string, dropDownType?: string) {
    let inputText: string = '';
    if (!isEmpty(coordinate)) {
      if (this._dataHelper.areEqualStrings(formfield, 'otherAddress.cityOrCountyState')) {
        if (this._dataHelper.areEqualStrings(dropDownType, 'city')) {
          this.geoLocation.isCountySearch = false;
          this.geoLocation.isCitySearch = false;
          this.setOtherAddrForCity(coordinate);
          inputText = `${coordinate.city}, ${coordinate.stateCd}`;
        }
      } else {
        if (this._dataHelper.areEqualStrings(dropDownType, 'city')) {
          inputText = `${coordinate.city}, ${coordinate.stateCd}`;
          this.geoLocation.isCountySearch = false;
          this.geoLocation.isCitySearch = true;
          this.postalCode = coordinate.zipcode;
        } else if (this._dataHelper.areEqualStrings(dropDownType, 'county')) {
          this.searchPattern = SEARCH_INPUT_TYPE.COUNTY;
          inputText = `${coordinate.county}, ${coordinate.stateCd}`;
          this.geoLocation.isCountySearch = true;
          this.geoLocation.isCitySearch = false;
        }
        this.geoLocation.isZipcodeSearch = false;
        this.geoLocation.searchText = inputText;
      }
    }
    this.dropDownSelected = inputText;
    const _formGroup = this.getFormGroup(formfield);
    if (!isEmpty(_formGroup)) {
      _formGroup.setValue(inputText);
    }
    if (!this._dataHelper.areEqualStrings(formfield, 'otherAddress.cityOrCountyState')) {
      this.setGeoCoordinates(coordinate);
    }
    this.resetDropDownOptions();
  }

  private async getLocationLookup(input: string) {
    this.locationError = false;
    if (!this._dataHelper.isEmptyString(input)) {
      try {
        const result: IGeoLocationInfoWithCounties = await this._locationAdapterSvc.getLocationLookup(input);
        if (this.geoLocation.isZipStateCountySearch) {
          if ((result?.cities.length || result?.counties.length) && !this.geoLocation.isZipcodeSearch) {
            this.zipStateCountyList = result.cities;
            this.topZipStateCountyList = result.cities?.slice(0, LOOKUP_MIN_COUNT.minCount);
            this.countyList = result.counties;
            this.topCountyList = result.counties?.slice(0, LOOKUP_MIN_COUNT.minCount);
            this.locationError = false;
          } else if (this.geoLocation.isZipcodeSearch && this._dataHelper.areEqualStrings(this.searchPattern, SEARCH_INPUT_TYPE.ZIP_CODE) && (result?.cities.length || result?.counties.length)) {
            this.resetDropDownOptions(); // reset the dropdown options while search is zip code.
            this.geoLocation.isValidZipCode = true;
            this.setGeoCoordinates(result?.cities[0]);
            this.locationError = false;
          } else if (isEmpty(result?.cities) && isEmpty(result?.counties)) {
            this.locationError = true;
          }
        }
      } catch (error: unknown) {
        this.locationError = true;
        this.geoLocation.isValidZipCode = false;
      }
    }
  }

  private async getFullAddressDetails(locationParam: IGeoLocation) {
    this.isLoading = true;
    if (!this._dataHelper.isEmptyString(locationParam?.addressLine1) && !this._dataHelper.isEmptyString(locationParam?.zipcode) && !this._dataHelper.isEmptyString(locationParam?.stateCd)) {
      try {
        const result: IGeoLocationInfo = await this._locationAdapterSvc.getFullAddressDetails(locationParam);

        if (!isEmpty(result)) {
          this.setGeoCoordinates(result);
          const enteredZipInputInfo = this.otherAddress.zipInput;
          if (!this._dataHelper.areEqualStrings(enteredZipInputInfo?.zipcode, result.zipcode)) {
            this.setGeoCoordinates(enteredZipInputInfo);
            this.geoLocation.city = this.otherAddress.cityInput?.city;
            this.geoLocation.searchText = this.dropDownSelected;
          } else {
            this.geoLocation.searchText = this.dropDownSelected;
          }
          this.geoLocation.isOtherAddressSearch = true;
          this.geoLocation.isCitySearch = false;
          this.geoLocation.isCountySearch = false;
          this.geoLocation.isZipcodeSearch = true;
          this.geoLocation.searchType = GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS;
          this._geoLocationService.setLocation(this.geoLocation);
          this._appSession.selectLocationForm = this.getLocationFormValue(GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS);
          this.onClose();
          this.isLoading = true;
        } else {
          this.showError = true;
          this.continueBtnDisabled = true;
        }
      } catch (error: unknown) {
        this.isLoading = true;
      }
    }
  }

  private setGeoCoordinates(geolocations: IGeoLocationInfo): IGeoLocation {
    const geoLocation: IGeoLocation = {};
    if (!isEmpty(geolocations)) {
      const _geolocation = geolocations;
      geoLocation.city = _geolocation?.city ?? '';
      geoLocation.stateCd = _geolocation?.stateCd ?? '';
      geoLocation.stateName = _geolocation?.stateName ?? '';
      geoLocation.county = _geolocation?.county ?? '';
      geoLocation.countyCode = _geolocation?.countyCode ?? '';
      geoLocation.zipcode = _geolocation?.zipcode ?? '';
      geoLocation.latitude = _geolocation?.latitude ?? '';
      geoLocation.longitude = _geolocation?.longitude ?? '';
    }
    this.geoLocation = { ...this.geoLocation, ...geoLocation };
    return geoLocation;
  }

  private hasGeoLocation() {
    let _hasGeoLocation = false;
    if (!this.locationError && (this.zipStateCountyList || this.countyList)) {
      _hasGeoLocation = this.zipStateCountyList?.length > 0 || this.countyList?.length > 0;
    }
    return _hasGeoLocation;
  }

  onClose() {
    this._geoLocationService.closeModel();
  }

  showLocationMore(): void {
    this.locationMore = !this.locationMore;
  }

  showCountyMore(): void {
    this.countyMore = !this.countyMore;
  }

  disableContinue() {
    this.continueBtnDisabled = true;
    if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY)) {
      const zipCityCountyFormGroup = this.getFormGroup(this.geoLocation.searchType) as FormGroup;
      const isCountyStateOrCounty =
        this._dataHelper.areEqualStrings(this.searchPattern, SEARCH_INPUT_TYPE.COUNTY_STATE) || this._dataHelper.areEqualStrings(this.searchPattern, SEARCH_INPUT_TYPE.COUNTY);
      const isFormValidOrNotEmpty = isCountyStateOrCounty ? !isEmpty(zipCityCountyFormGroup.value) : zipCityCountyFormGroup.valid && !isEmpty(zipCityCountyFormGroup.value) && ((!this.geoLocation.isCitySearch && !this.geoLocation.isCountySearch) ? this.geoLocation.isValidZipCode: true);
      this.continueBtnDisabled = !(isFormValidOrNotEmpty && !this.locationError);
    } else if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION)) {
      const currentLocation = this.getFormGroup(this.geoLocation.searchType) as FormGroup;
      this.continueBtnDisabled = !(this.isGeoCordinateValid() && !this.locationError);
    } else if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.HOME_ADDRESS)) {
      if (!isEmpty(this.homeAddress)) {
        this.continueBtnDisabled = false;
      }
      this.continueBtnDisabled = false;
    } else if (this._dataHelper.areEqualStrings(this.geoLocation.searchType, GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)) {
      const otherAddress = this.getFormGroup(this.geoLocation.searchType) as FormGroup;
      this.continueBtnDisabled = !(otherAddress.valid && !isEmpty(otherAddress.value) && !this.locationError && this.otherAddress.isValidZipSelected);
    }
    return this.continueBtnDisabled;
  }

  private setCurrentLocationField(): void {
    if (!this._dataHelper.isEmptyString(this.geoCodeAddress.city) && !this._dataHelper.isEmptyString(this.geoCodeAddress.stateCd)) {
      this.getFormGroup(GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION)?.get('cityState').setValue(`${this.geoCodeAddress.city}, ${this.geoCodeAddress.stateCd}`);
    }
    if (!this._dataHelper.isEmptyString(this.geoCodeAddress.zipcode)) {
      this.getFormGroup(GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION)?.get('zipCode').setValue(this.geoCodeAddress.zipcode);
    }
  }

  private setZipCityAndCountyField(): void {
    if (!this._dataHelper.isEmptyString(this.geoLocation.zipcode) && !this._dataHelper.isEmptyString(this.geoLocation.stateCd) && this.geoLocation.isZipStateCountySearch) {
      this.getFormGroup(GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY)
        ?.get('zipCityCounty')
        ?.setValue(this.geoLocation.searchText ? this.geoLocation.searchText : this.geoLocation.zipcode);
    }
  }

  private setOtherAddressFields(): void {
    if (!this._dataHelper.isEmptyString(this.geoLocation.zipcode) && !this._dataHelper.isEmptyString(this.geoLocation.addressLine1) && this.geoLocation.isOtherAddressSearch) {
      this.getFormGroup(GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)?.get('addressLine1')?.setValue(this.geoLocation.addressLine1);
      this.getFormGroup(GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)?.get('addressLine2')?.setValue(this.geoLocation.addressLine2);
      this.getFormGroup(GEO_LOCATION_SEARCH_TYPE.OTHER_ADDRESS)
        ?.get('zipCode')
        ?.setValue(this.geoLocation.searchText ? this.geoLocation.searchText : this.geoLocation.zipcode);
    }
  }

  private isGeoCordinateValid(): boolean {
    return (
      this.geoLocation.isGeoCodeAllowed &&
      this.geoLocation.isGeoCodeValid &&
      !this._dataHelper.isEmptyString(this.geoCodeAddress.latitude) &&
      !this._dataHelper.isEmptyString(this.geoCodeAddress.longitude)
    );
  }

  private zipCityCountyValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let isValid = false;
      if (!this._dataHelper.isEmptyString(value) && value.length >= SEARCH_INPUT.LOW && value.length < SEARCH_INPUT.HIGH) {
        const patternAllowed: Array<string> = Object.values(SEARCH_INPUT_TYPE);
        const searchPattern = this._geocodeApi.identifySearchPattern(this._geocodeApi.identifySearchParameter(value));
        isValid =
          patternAllowed.includes(searchPattern.patternEnum) &&
          (!this._dataHelper.areEqualStrings(searchPattern.patternEnum, SEARCH_INPUT_TYPE.ZIP_CODE) ? this._dataHelper.areEqualStrings(this.dropDownSelected, value) : true);
      }

      return isValid ? null : { [controlName]: { value: control.value } };
    };
  }

  private setOtherAddrForZipCode(value: IGeoLocationInfo): void {
    if (!isEmpty(value)) {
      const zipCodeInput = {
        ...this.otherAddress?.zipInput,
        ...value
      } as IGeoLocationInfo;
      this.otherAddress.zipInput = zipCodeInput;
      this.otherAddress.isValidZipSelected = true;
      const cityInput = this.otherAddress.cityInput;
      if (cityInput && !this._dataHelper.isEmptyString(cityInput.stateCd) && !this._dataHelper.areEqualStrings(zipCodeInput.stateCd, cityInput.stateCd)) {
        this.otherAddress.isValidZipSelected = false;
      }
    }
  }

  private setOtherAddrForCity(geoLocationInfo: IGeoLocationInfo): void {
    if (!isEmpty(geoLocationInfo)) {
      const cityInput = {
        ...this.otherAddress?.cityInput,
        ...geoLocationInfo
      } as IGeoLocationInfo;
      this.otherAddress.cityInput = cityInput;
      const zipInput = this.otherAddress.zipInput;
      if (zipInput && !this._dataHelper.isEmptyString(zipInput.stateCd) && !this._dataHelper.areEqualStrings(zipInput.stateCd, cityInput.stateCd)) {
        this.otherAddress.isValidZipSelected = false;
      } else if(zipInput && !this._dataHelper.isEmptyString(zipInput.stateCd) && this._dataHelper.areEqualStrings(zipInput.stateCd, cityInput.stateCd)) {
        this.otherAddress.isValidZipSelected = true;
      }
    }
  }
}
