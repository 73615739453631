import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IProviderDetail } from '../../../fad/provider-details/models/iDetailsResponse';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fad-essential-provider-cmp',
  templateUrl: './pfEssentialProviderCmp.html'
})
export class PFEssentialProviderComponent extends BaseComponent {
  @Input() providerDetail: IProviderDetail;
  showEssentialProviderFlag = true;
  constructor(
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    private _contentHelper: ContentHelper,
    private _route: ActivatedRoute
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit(): void {}
}
