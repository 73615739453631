import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { EmulateSecureStateComponent } from './components/emulateSecureStateCmp';
import { SecureStateHandler } from './services/secureStateHandler';

@NgModule({
    declarations: [EmulateSecureStateComponent],
    exports: [EmulateSecureStateComponent],
    imports: [
        CommonModule,
        UxSharedModule.forRoot(),
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [SecureStateHandler]
})
export class AppSecureModule { }
