import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IMoreOptionsRequest } from '../interfaces/iMoreOptionsRequest';
import { IMoreOptionsResponse } from '../interfaces/iMoreOptionsResponse';
@Injectable()
export class MoreOptionsSvc extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getMoreOptions(reqObj: IMoreOptionsRequest): Promise<Array<IMoreOptionsResponse>> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.getMoreOptions,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
