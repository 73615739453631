import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '../../../../../../libs/uxd-shared/uxdSharedModule';
import { ActionableHeaderModule } from '../../../../../common/components/actionable-header/actionableHeaderModule';
import { InfoListModule } from '../../../../../common/components/info-list/pfInfoListModule';
import { LookUpListModule } from '../../../../../common/components/lookup-list/pfLookUpListModule';
import { CommonDirectivesModule } from '../../../../../common/directives/commonDirectivesModule';
import { ViewHealthwiseComponent } from './healthwiseCmp';

@NgModule({
    declarations: [ViewHealthwiseComponent],
    exports: [ViewHealthwiseComponent],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule,
        LookUpListModule,
        InfoListModule,
        CommonDirectivesModule
    ],
    providers: []
})
export class HealthwiseModule { }
