import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { PFEssentialProviderComponent } from './pfEssentialProviderCmp';

@NgModule({
    declarations: [PFEssentialProviderComponent],
    exports: [PFEssentialProviderComponent],
    imports: [
        UxSharedModule,
        CommonModule
    ],
})
export class PFEssentialProviderModule { }
