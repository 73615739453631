export const PROCEDURE_VIEW_MORE_LIMIT = 6;
export const CARE_TEAM_VIEW_COUNT = 3;

export enum QuickLinkType {
  PROVIDER = 'PROVIDER',
  PROCEDURE = 'PROCEDURE'
}

export enum ActionType {
  SEARCH = 'SEARCH',
  SSO = 'SSO',
  EXT_NAV = 'EXT_NAV',
  INT_RCP_NAV = 'INT_RCP_NAV',
  INT_NAV = 'INT_NAV'
}

export enum CodeType {
  LHO = 'LHO',
  EYEMED = 'EYEMED',
  DENTAQUEST = 'DENTAQUEST',
  VIRTUAL_CARE = 'VIRTUAL_CARE'
}

export enum GetCareNow {
  GET_CARE_NOW = 'GET_CARE_NOW'
}
