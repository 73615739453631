import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppMaintenanceComponent } from './app-maintenance.component';
@NgModule({
    declarations: [AppMaintenanceComponent],
    exports: [AppMaintenanceComponent],
    imports: [
        CommonModule,
    ],
    providers: []
})
export class AppMaintenanceModule {
  static forRoot(): ModuleWithProviders<AppMaintenanceModule> {
    return {
      ngModule: AppMaintenanceModule,
      providers: []
    };
  }
 }
