import { Injectable } from '@angular/core';
import { CareTeamResponse } from '../../care-circle/interfaces/iCareProvider';
import { AppSession } from '../../common/values/appSession';
import { IProvider } from "../../fad/search-results/interfaces/iSummaryResp";
import { CommonUtil } from '../../fad/utilities/commonUtil';
import { CARE_TEAM_TYPE } from '../constants/careCircleConstants';
import { ICareCircle } from '../interfaces/iCareCircle';
import { CareCircleUtil } from '../utilities/careCircleUtil';
import { CareCircleService } from './careCircleSvc';

@Injectable()

export class MemberPCPHelper {
  constructor(
    public _appSession: AppSession,
    public _careCircleSvc: CareCircleService
  ) {
  }

  updatePcpProviderSession(result: ICareCircle | CareTeamResponse) {
    if (!Object.keys(this._appSession.pcpData).includes(this._appSession?.metaData?.appContract?.mbrUid)) {
      this._appSession.pcpData[this._appSession?.metaData?.appContract?.mbrUid] = {currentPCP:'', futurePCP:''};
    }
      const careTeamData = result as ICareCircle;
      if (careTeamData?.pcp) {
      careTeamData?.pcp?.data.forEach((data) => {
        if (data.isPCP && !data.isFuturePCP) {
          this._appSession.pcpData[this._appSession?.metaData?.appContract?.mbrUid].currentPCP = data?.provider?.providerId + data?.provider?.address?.addressId;
        }
        if (data.isPCP && data.isFuturePCP) {
          this._appSession.pcpData[this._appSession?.metaData?.appContract?.mbrUid].futurePCP = data?.provider?.providerId + data?.provider?.address?.addressId;
        }
      });
     }
  }

checkAndRetrievePcpData(appSession: AppSession) {
    if (
      Object.keys(appSession?.pcpData)?.includes(
        appSession?.metaData?.appContract?.mbrUid
      )
    ) {
      return;
    } else {
        this.getCareCircles(appSession);
    }
}

getCareCircles(appSession) {
  this._careCircleSvc.getCareCircle(
        CareCircleUtil.buildCareCircleRequest(
          appSession,
          CARE_TEAM_TYPE.SELECTED
        )
      )
      .then(
        (result: ICareCircle) => {
          this.updatePcpProviderSession(result);

        },
        (error: unknown) => {
          this.onCategoryError('CARECIRCLE', error);
        }
    );
}

onCategoryError(type: string, error: unknown) {
    throw error;
  }

  isNotAMemberPCPProvider(provider): boolean {
    if (CommonUtil.isMemberSecure(this._appSession) && this._appSession?.pcpData && this._appSession?.metaData?.appContract?.mbrUid) {
      const provAndAddressId = provider?.providerIdentifier + provider?.location?.address?.addressId;
      return provAndAddressId !== this._appSession?.pcpData[this._appSession?.metaData?.appContract?.mbrUid]?.currentPCP;
    }
     else {
      return true;
    }
  }

  isNotAMemberFuturePCPProvider(provider): boolean {
    if (CommonUtil.isMemberSecure(this._appSession) && this._appSession?.pcpData && this._appSession?.metaData?.appContract?.mbrUid) {
      const provAndAddressId = provider?.providerIdentifier + provider?.location?.address?.addressId;
      return provAndAddressId !== this._appSession?.pcpData[this._appSession?.metaData?.appContract?.mbrUid]?.futurePCP;
    }
     else {
      return true;
    }
  }

  showAssignPCPBadge(professional: IProvider): boolean {
    return professional && !this.isNotAMemberPCPProvider(professional);
  }

  showFutureAssignPCPBadge(professional: IProvider): boolean {
    return professional && !this.isNotAMemberFuturePCPProvider(professional);
  }
}
