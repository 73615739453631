import { CommonModule, KeyValuePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SAVNavigationService } from '../../../fad/search-providers/services/savNavigationSvc';
import { CustomPipesModule } from '../../pipes/customPipesModule';
import { GeocodeApi } from '../../services/geocodeApi';
import { ActionableHeaderModule } from '../actionable-header/actionableHeaderModule';
import { LookUpListModule } from '../lookup-list/pfLookUpListModule';
import { MapModule } from '../map/pfMapModule';
import { UxSharedModule } from './../../../../libs/uxd-shared/uxdSharedModule';
import { GeoLocationComponent } from './geoLocationCmp';
import { LocationAdapterSvc } from './services/locationAdapterSvc';
import { LocationSvc } from './services/locationSvc';
import { PFSwitchLocationComponent } from './switchLocationCmp';


@NgModule({
    declarations: [ GeoLocationComponent, PFSwitchLocationComponent],
    exports: [ GeoLocationComponent, PFSwitchLocationComponent ],
    imports: [
        UxSharedModule,
        CommonModule,
        ActionableHeaderModule,
        FormsModule,
        ReactiveFormsModule,
        LookUpListModule,
        MapModule,
        CustomPipesModule
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA],
    providers: [KeyValuePipe, LocationSvc, LocationAdapterSvc, GeocodeApi,SAVNavigationService]
})
export class GeoLocationModule { }
