import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../common/services/dataService';
import { ISelectedProvider } from '../../provider-details/models/iSelectedProvider';
import { ProviderDetailsNavigationService } from '../../provider-details/services/providerDetailsNavigationSvc';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE, RETAIL_PHARMACY } from '../values/providerSearchConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { AppConstants, AppExternalCommands } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { AppEvents } from './../../../common/enums/appEvents';
import { IEventDetail } from './../../../common/interfaces/iEventDetail';
import { PhonePatternPipe } from './../../../common/pipes/phonePatternPipe';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-care-team-cmp',
  templateUrl: '../views/pfCareTeamCmp.html',
  providers: []
})
export class PFCareTeamComponent extends BaseComponent implements OnInit {

  @Input()
  careTeamObj: any = [];
  @Input()
  searchParams: ISearchParameters;
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();

  careProviders: any = [];
  showPCPInfo: boolean = false;
  showReadOnlyMessage: boolean = false;
  private _pfAppCommandEvent: EventEmitter<IEventDetail>
    = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  private _header: string;
  @Input() set header(value: string) {
    this._header = value;
  }
  get header(): string {
    return (this._header && this._header.length > 0) ?
      this._header : this.content.searchCriteriaComponent.labels.mostRecentCareTeam;
  }

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _phonePatternPipe: PhonePatternPipe,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    private _appUtility: AppUtility,
    private _dataService: DataService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnChanges(args: any) {
    if (args['careTeamObj'] && args['careTeamObj'].currentValue) {
      const careTeamResponse = args['careTeamObj'].currentValue;
      this.careProviders = [];
      if (careTeamResponse) {
        if (careTeamResponse.careTeam && careTeamResponse.careTeam.length > 0) {
          let _careProviders = careTeamResponse.careTeam;
          if (this.isCareTeamEnabled && _careProviders.length > 5) {
            _careProviders = _careProviders.slice(0, 5);
          }
          this.careProviders = _careProviders;
        }
        this.managePCPMessage(careTeamResponse);
      }
    }
  }

  viewFullCareTeam() {
    this._navigationService.navigateByUrl(AppNavigations.CARE_CIRCLE_PATH);
  }

  managePCPMessage(careTeamResponse: any) {
    this.showPCPInfo = false;
    if (careTeamResponse.hasPCPFeature) {
      if (careTeamResponse.isPCPReadOnly) {
        this.showPCPInfo = true;
        this.showReadOnlyMessage = true;
      } else if (careTeamResponse.careTeam.findIndex((x) => x.isPCP) < 0) {
        this.showPCPInfo = true;
        this.showReadOnlyMessage = false;
      }
    }
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  // Contact us link will navigate to rcp contact us page
  onContactUsClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_CONTACT_US.MSG;
    eventDetail.target = AppExternalCommands.RCP_CONTACT_US.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

    // Add pcp link will navigate to results page with pcp rules applied
  onAddPCPClick() {
    if (this.isLocationValid() &&
      this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.MyPlan &&
      this._appSession.pcpRule && this._appSession.pcpRule.providerType
      && this._appSession.pcpRule.providerType.length > 0) {
      this.searchParams.typeSelectNm = this._appSession.pcpRule.providerType[0];
      this.searchParams.providerTypeCodeList = this._appSession.pcpRule.providerType;
      this.searchParams.specialtySelectNm = this._appSession.pcpRule.specialities;
      this.searchParams.ableToServePcp = true;
      this.resetAppSessionParams();
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
  }

  isLocationValid() {
    let _isValid = true;
    if (!(this.searchParams && this.searchParams.coordinates &&
      this.searchParams.coordinates.latitude !== '' &&
      this.searchParams.coordinates.longitude !== '')
    ) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  resetAppSessionParams() {
    this.searchParams.provNm = '';
    this.searchParams.coverageTypeCode = '';
    this.searchParams.taxonomySelectNm = [];
    this.searchParams.standAloneDental = null;
    this.searchParams.standAloneVision = null;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchParams;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
  }

  showProviderDetails(careTeam: any) {
    let address = {};
    const provider = careTeam.provider;
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.carePageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    this._dataService.setPageTitle(pageFadObj);
    this._appSession.isCareProvider = true;
    if (provider.address.latitude && provider.address.longitude) {
      this._appSession.searchParams.coordinates = {
        longitude: '',
        latitude: ''
      };
      this._appSession.searchParams.coordinates.latitude = provider.address.latitude;
      this._appSession.searchParams.coordinates.longitude = provider.address.longitude;
    }
    if (careTeam.providerTypes && careTeam.providerTypes.length === 1
      && careTeam.providerTypes[0] === PROVIDER_TYPE.PHARMACY) {
      this._appSession.searchParams.coverageTypeCode = RETAIL_PHARMACY;
      if (provider.address) {
        let provAddress = provider.address;
        address = {
          addressId: provAddress.addressId,
          city: provAddress.city,
          state: provAddress.stateCd ? provAddress.stateCd.code : '',
          postalCode: provAddress.zipCd
        }
      }
    } else {
      this._appSession.searchParams.coverageTypeCode = '';
      if (provider.address) {
        address = {
          addressId: provider.address.addressId,
        }
      }
    }
    const selectedProvider = {
      providerIdentifier: provider.providerId,
      providerTypeCodeList: careTeam.providerTypes,
      providerName: provider.fullNm,
      location: {
        address: address
      }
    } as ISelectedProvider;
    this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
  }
}
