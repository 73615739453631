import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '../../../../libs/uxd-shared/uxdSharedModule';
import { VirtualCareLabelComponent } from './virtualCareLabelCmp';

@NgModule({
    declarations: [VirtualCareLabelComponent],
    exports: [VirtualCareLabelComponent],
    imports: [
        UxSharedModule,
        CommonModule
    ],
    providers: []
})
export class VirtualCareLabelModule { }
