export enum FindCareFlag {
  FCR = 'FCR',
  INITIATE_MEMBER_SELECTION = 'INITIATE_MEMBER_SELECTION',
  OOP_ELIGIBLE = 'OOP_ELIGIBLE',
  CDHP_PRODUCT = 'CDHP_PRODUCT',
  JAA_PRODUCT = 'JAA_PRODUCT',
  NATIONAL_PRODUCT = 'NATIONAL_PRODUCT',
  PCP_REQUIRED = 'PCP_REQUIRED',
  PCP_OPTIONAL = 'PCP_OPTIONAL',
  VISION_PLUS = 'VISION_PLUS'
}

export enum ActionKey {
  MEMBER_MODAL_DURING_INITIALIZATION = 'member-modal'
}
