
import { ISearchRequest } from '../../fad/search-results/interfaces/iSearchRequest';
import { ICptMedicalRequest } from '../interfaces/iCptMedicalRequest';
import { ISecureDentalRequest } from '../interfaces/iDentalProcedureSearchRequest';
import { IPublicSpecialtySearchRequest } from '../interfaces/iPublicSpecialtySearchRequest';
import { IPublicTccMedicalSearchRequest } from '../interfaces/iPublicTccMedicalRequest';
import { ISecurePharmacyRequest } from '../interfaces/iSecurePharmacySearchRequest';
import { ISecureSpecialtySearchRequest } from '../interfaces/iSecureSpecialtySearchRequest';
import { ISecureTccMedicalSearchRequest } from '../interfaces/iSecureTccMedicalSearchRequest';

export enum SEARCH_SCENARIOS {
  'PUBLIC_V1_SPECIALTY' = 'PUBLIC_V1_SPECIALTY',
  'PUBLIC_V1_TCC_MEDICAL' = 'PUBLIC_V1_TCC_MEDICAL',
  'SECURE_V1_CPT_MEDICAL' = 'SECURE_V1_CPT_MEDICAL',
  'SECURE_V1_RX_SPECIALTY' = 'SECURE_V1_RX_SPECIALTY',
  'SECURE_V1_SPECIALTY' = 'SECURE_V1_SPECIALTY',
  'SECURE_V1_TCC_DENTAL' = 'SECURE_V1_TCC_DENTAL',
  'SECURE_V1_TCC_MEDICAL' = 'SECURE_V1_TCC_MEDICAL'
}

export type ISearchFilterRequest =
  | (IPublicSpecialtySearchRequest)
  | (ISecureSpecialtySearchRequest)
  | (ISecurePharmacyRequest)
  | (IPublicTccMedicalSearchFilterRequest)
  | (ISecureTccMedicalSearchFilterRequest)
  | (ISecureDentalSearchFilterRequest)
  | (ICptSearchFilterRequest)
  | (ISearchRequest);

export type IPublicSpecialtySearchFilterRequest = IPublicSpecialtySearchRequest & { scenario: SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY };
export type ISecureSpecialtySearchFilterRequest = ISecureSpecialtySearchRequest & { scenario: SEARCH_SCENARIOS.SECURE_V1_SPECIALTY };
export type ISecurePharmacyFilterRequest = ISecurePharmacyRequest & { scenario: SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY };
export type IPublicTccMedicalSearchFilterRequest = IPublicTccMedicalSearchRequest & { scenario: SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL };
export type ISecureTccMedicalSearchFilterRequest = ISecureTccMedicalSearchRequest & { scenario: SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL };
export type ISecureDentalSearchFilterRequest = ISecureDentalRequest & { scenario: SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL };
export type ICptSearchFilterRequest = ICptMedicalRequest & { scenario: SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL; mock?: boolean }

export type PRECARE_SEARCH_SCENARIO = keyof typeof SEARCH_SCENARIOS;

export type StrategyFeatureFlag = {
  [Key in SEARCH_SCENARIOS]: string;
};

export const STRATEGY_SEARCH_FEATURE_FLAGS: StrategyFeatureFlag = {
  PUBLIC_V1_SPECIALTY: 'strategyColdSpecialtySearch',
  PUBLIC_V1_TCC_MEDICAL: 'strategyColdProcedureSearch',
  SECURE_V1_RX_SPECIALTY: 'strategyHotSpecialtyRxSearch',
  SECURE_V1_SPECIALTY: 'strategyHotSpecialtySearch',
  SECURE_V1_TCC_DENTAL: 'strategyHotDentalProcedureSearch',
  SECURE_V1_TCC_MEDICAL: 'strategyHotMedicalProcedureSearch',
  SECURE_V1_CPT_MEDICAL:'strategyHotCptMedicalCodeSearch'
}

export const SELECT_DEFAULT_ZERO = '00';
