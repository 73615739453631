import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../common/services/httpClientService';
import { AppUtility } from '../../../../common/utilities/appUtil';
import { AppSession } from '../../../../common/values/appSession';
import { apiNameList } from './../../../../../environments/api-name-list';
import { HttpMethod } from './../../../enums/httpMethodEnum';
import { BaseService } from './../../../services/baseService';
@Injectable()
export class SSOApi extends BaseService {

  constructor(public appSession: AppSession, public appUtility: AppUtility, public httpClientSvc: HttpClientService) {
    super(appSession, appUtility, httpClientSvc);
  }

  getSSOData(
    pdtStrKey: string
  ): Promise<any> {
    const urlParms: Array<any> = [];
    const header = [];

    urlParms.push({
      name: 'mbrUid',
      value: this.appSession.metaData.mbrUid,
      isQueryParam: false
    });

    urlParms.push({
      name: 'fivePartKey',
      value: pdtStrKey,
      isQueryParam: true
    });

    urlParms.push({
      name: 'locale',
      value: this.appSession.metaData.locale,
      isQueryParam: true
    });

    if (this.appSession.metaData.pfEmMode) {
      header.push({
        name: 'pf-em-user',
        value: 'emulate'
      });
    }

    return this.httpClientSvc.request({
      cacheable: false,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.vitalsSSO,
      urlParams: urlParms,
      headers: header
    });
  }
}
