import { Component, Input } from '@angular/core';
import { CareTeamResponse } from './../../../care-circle/interfaces/iCareProvider';
import { PFDynamicCareTeamComponent } from './pfDynamicCareTeamCmp';

@Component({
  moduleId: module.id,
  selector: "[app-fad-pf-dynamic-care-team-v2-cmp]",
  templateUrl: "../views/pfDynamicCareTeamV2Cmp.html"
})
export class PFDynamicCareTeamV2Component extends PFDynamicCareTeamComponent {
  @Input() careTeamObj: CareTeamResponse;
}
