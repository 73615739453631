import { Component, EventEmitter, Inject, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from "@anthem/uxd/modal";
import { ICardData } from "../models/iCardData";
import { IHoursOfOperation } from './../../../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from './../../../../fad/utilities/commonUtil';
import { PhonePatternPipe } from './../../../pipes/phonePatternPipe';
import { EventHandler } from './../../../services/eventHandler';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';
import { BaseComponent } from './../../base-component/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-on-site-clinic-slider-cmp',
  templateUrl: './onSiteClinicSliderCmp.html'
})

export class OnSiteClinicSliderComponent extends BaseComponent implements OnInit {

  @Output()
  openDomain: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('viewOnSiteClinicSliderModal')
  viewOnSiteClinicModal: TemplateRef<HTMLElement>;

  @Output()
  openMapModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _sidePanel: SidePanel,
    private _phonePatternPipe: PhonePatternPipe,
    @Inject(AppSession)
    _appSession: AppSession,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  drivingModalTitle: any = { id: 'map-details-modal', title: 'get directions' };
  sidePanelRef: ModalRef<any, any>;
  _cardData: ICardData = {} as ICardData;

  openSidePanel(direction: any, cardData?: ICardData): void {
    this._cardData = cardData;
    this.sidePanelRef = this._sidePanel.open(direction, this.viewOnSiteClinicModal);
  }

  /** Event handler for click of close button  */
  onClose(): void {
    this._cardData = null;
    this.sidePanelRef.close();
  }

  get addressOne(): string {
    return this._cardData.clinic.address1;
  }

  get addressTwo(): string {
    return this._cardData.clinic.address2 + this.concatAddress(this._cardData.clinic.city)
      + this.concatAddress(this._cardData.clinic.state) + this.concatAddress(String(this._cardData.clinic.zipCode))
  }

  get hoursOfOperation(): IHoursOfOperation[] {
    const hoopList: IHoursOfOperation[] = (this._cardData?.clinic?.hours_of_operation || []).filter((obj) => {
      if (obj !== null) {
        return obj;
      }
    });
    return hoopList;
  }

  /**
   *
   * @param string_value
   * @returns if the incoming value is valid retuns the same value prefixed with blank space otherwise returns no space
   */
  concatAddress(val: string): string {
    if (CommonUtil.isNotBlank(val)) {
      return ' ' + val;
    }
    return '';
  }

  getWorkingHour(hoop: IHoursOfOperation): string {
    return hoop.days + ': ' + hoop.opens + ' to ' + hoop.closes + ' ' + hoop.timezone;
  }

  formatPhone(phone: number): string {
    return this._phonePatternPipe.transform(String(phone));
  }

  openDomainDisclaimerModal(url: string): void {
    this.sidePanelRef.close();
    this.openDomain.emit(url);
  }

  openLocationMapModal(professional: ICardData): void {
    this.sidePanelRef.close();
    this.openMapModal.emit(professional);
  }
}
