import { Component, Inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "../../../common/components/base-component/baseCmp";
import { EventHandler } from "../../../common/services/eventHandler";
import { AppSession } from "../../../common/values/appSession";
import { ContentHelper } from "../../../common/values/contentHelper";
import { IPillID } from "../../search-providers/interfaces/iProviderType";
import { FilterCategoryService } from "../services/filterCategorySvc";
import { PILL_ID } from "../values/providerSearchConstants";

@Component({
  moduleId: module.id,
  selector: "app-fad-filter-accordion-cmp",
  templateUrl: "../views/pfFilterAccordionCmp.html",
})
export class PFFilterAccordionComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  overflowVisible: boolean = false;
  @Input()
  header: string;
  @Input()
  ariaLabelHeader: string;
  @Input()
  ariaLabelToggle: string;
  @Input()
  pillID: IPillID;

  pillFilterID = PILL_ID;
  expand: boolean = false;

  constructor(
    @Inject(AppSession)
    _appSession: AppSession,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    _contentHelper: ContentHelper,
    private _filterCategoryService: FilterCategoryService
  ) {
    super(
      _route,
      _eventHandler,
      _appSession,
      _contentHelper,
      "PFSearchContainerComponent"
    );
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this.ariaLabelHeader = this.header
      ? `${this.header} ${this.content.searchCriteriaComponent.viewInformation}`
      : this.content.searchCriteriaComponent.viewInformation;
    this.ariaLabelToggle = this.header
      ? `${this.header} ${this.content.searchCriteriaComponent.viewInformationToggle}`
      : this.content.searchCriteriaComponent.viewInformationToggle;

    this.onAccordionLoad();

    this.expand = this._filterCategoryService.expandFilterCategory(
      this.pillID?.label
    );
  }

  onAccordionLoad() {
    const filterCategoryList = this._filterCategoryService.filterCategoryList;
    if (this.pillID) {
      const hasFilterCategory = filterCategoryList?.includes(this.pillID.label);
      if (!hasFilterCategory) {
        // Sub-specialty visibility handling
        if (this.pillID.label === this.pillFilterID.subSpeciality.label) {
          this.updateFilterCategory(this.pillID.label, this.pillFilterID.speciality.label);
        } else if (this.pillID.label === this.pillFilterID.medicalgrpAffiliation.label) {
          this.updateFilterCategory(this.pillID.label, this.pillFilterID.groupaffiliation.label);
        } else {
          this._filterCategoryService.filterCategoryList.push(this.pillID.label);
        }
      }
    }
  }

  private updateFilterCategory(category: string, parentCategory: string) {
    const filterCategoryList = this._filterCategoryService.filterCategoryList;
    const parentCategoryIndex = filterCategoryList?.findIndex((item) => item === parentCategory);
    if (parentCategoryIndex !== filterCategoryList.length - 1) {
      const subCategoryIndex = parentCategoryIndex + 1;
      this._filterCategoryService.filterCategoryList.splice(subCategoryIndex, 0, category);
    } else {
      this._filterCategoryService.filterCategoryList.push(category);
    }
  }
}
