import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { IProvider } from '../../../fad/search-results/interfaces/iSummaryResp';
import { NavigationService } from '../../services/navigationService';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { AddressByLatLongResponse, LocationResponse } from './../../../common/models/locationAddress';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { EventHandler } from './../../services/eventHandler';
import { AppUtility } from './../../utilities/appUtil';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
import { PFMapComponent } from './pfMapCmp';

@Component({
  moduleId: module.id,
  selector: 'app-driving-direction-cmp',
  templateUrl: './pfDrivingDirectionCmp.html'
})

export class PFDrivingDirectionComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('viewProvidersDirectionsMapComponent')
  viewProvidersDirectionsMapComponent: PFMapComponent;
  @Input()
  provider: any;
  @Output()
  closeDDModel: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  printDrivingDirection: EventEmitter<string> = new EventEmitter<string>();
  startLocation: string = '';
  endLocation: string = '';
  showInputContent: boolean = false;
  isShortestTime: boolean = true;
  directionsMapData: any;
  directionMapError: boolean = false;
  radioObject1: any = {
    label: this.content?.mapModal?.shortestTime,
    id: 'compareShortTime',
    name: 'compareDistance',
    value: true
  };
  radioObject2: any = {
    label: this.content?.mapModal?.shortestDistance,
    id: 'compareShortdistance',
    name: 'compareDistance',
    value: false,
    analyticsText: ''
  };
  index: number ;
  providers: IProvider ;

  constructor(@Inject(AppSession)
              private _appSession: AppSession,
              private _route: ActivatedRoute,
              private _navigationService: NavigationService,
              private _eventHandler: EventHandler,
              @Inject(ContentHelper)
              private _contentHelper: ContentHelper,
              private _providerUtilityService: ProviderUtilityService,
              private _appUtility: AppUtility) {
              super( _route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngAfterViewInit() {
    //TODO:
  }

  getMapDirection() {
    if ((this.startLocation && this.startLocation.trim() !== '') &&
      (this.endLocation && this.endLocation.trim() !== '')) {
      this.directionMapError = false;
      this.showInputContent = false;
      if (
        this.viewProvidersDirectionsMapComponent &&
        this.viewProvidersDirectionsMapComponent.mapView &&
        this.viewProvidersDirectionsMapComponent.directionsItinerary &&
        this.viewProvidersDirectionsMapComponent.mapView.nativeElement &&
        this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement
      ) {
        const directionAttributes = {
          startLocation: this.startLocation,
          endLocation: this.endLocation,
          isShortestTimeSelected: this.isShortestTime,
          isShortestDistanceSelected: !this.isShortestTime
        };
        this.viewProvidersDirectionsMapComponent.clearDirections();
        this.viewProvidersDirectionsMapComponent.createDirections(directionAttributes);
        this.viewProvidersDirectionsMapComponent.mapView.nativeElement.removeAttribute('style');
        this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.removeAttribute('style');
        this.viewProvidersDirectionsMapComponent.directionsItinerary.
          nativeElement.classList.add('col-md-4', 'col-sm-12', 'col-xs-12');
        this.viewProvidersDirectionsMapComponent.mapView.
          nativeElement.classList.remove('col-md-11', 'col-md-offset-1', 'col-md-8');
        this.viewProvidersDirectionsMapComponent.mapView.
          nativeElement.classList.add('col-md-8', 'col-sm-12', 'col-xs-12', 'col-xxs-12');
      }
    } else {
      this.directionMapError = true;
    }
  }

  openMapModal(provider: any) {
    this.directionsMapData = [];
    this.showInputContent = false;
    this.startLocation = '';
    const data = CommonUtil.buildMapRequest(provider, 'E', null, this.showSPFI);
    this.directionsMapData.push(data);
    this.endLocation = data.title;
    this.index = provider.actualIndex;
    this.providers = provider;
    if (
      this.viewProvidersDirectionsMapComponent &&
      this.viewProvidersDirectionsMapComponent.mapView &&
      this.viewProvidersDirectionsMapComponent.directionsItinerary &&
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement &&
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement) {
      this.viewProvidersDirectionsMapComponent.clearDirections();
      this.viewProvidersDirectionsMapComponent.setMap(this.directionsMapData);
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement.removeAttribute('style');
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.removeAttribute('style');
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.classList.remove('col-md-5');
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement.classList.remove('col-md-9');
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.classList.add('col-md-4');
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement.classList.add('col-md-11', 'col-md-offset-1');
      this.isShortestTime = true;
    }
    setTimeout(() => {
      this.showInputContent = true;
    }, 300);
  }

  closeModal() {
    this.showInputContent = false;
    this.closeDDModel.emit('drivingDirection');
  }

  swapAddresses() {
    const newStartLocation = this.endLocation;
    this.endLocation = this.startLocation;
    this.startLocation = newStartLocation;
  }

  useYourLocation() {
    if (this._appSession.defaultLocation
      && this._appSession.defaultLocation.formattedAddress
      && this._appSession.defaultLocation.formattedAddress !== '') {
      this.startLocation = this._appSession.defaultLocation.formattedAddress;
    } else {
      if (!this._appSession.defaultLocation && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.onGeoLocationSuccess(position);
        });
      }
    }
  }

  onGeoLocationSuccess(position: any) {
    if (position && position.coords) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      if (latitude !== '' && longitude !== '') {
        this._providerUtilityService.getAddressByLatLong(latitude, longitude)
          .then((address: AddressByLatLongResponse) => {
          if (address.zipcode !== '') {
            const zipCode = address.zipcode;
            const formattedAddress = address.formattedAddress;
            // Recalling bing service to check if latitue and longitude is returning valid zip code in united states
            this._providerUtilityService.getGeoLocation(zipCode).then((result: LocationResponse[]) => {
              if (result && Object.keys(result).length > 0) {
                const results = this._appUtility.buildLocationResponse(result);
                this._appSession.defaultLocation = {
                  zip: zipCode,
                  state: results[Object.keys(results)[0]].stateCd,
                  latitude: results[Object.keys(results)[0]].lat,
                  longitude: results[Object.keys(results)[0]].long,
                  formattedAddress: formattedAddress
                };
                this.startLocation = formattedAddress;
              }
            });
          }
        });
      }
    }
  }

  hideMapDirection() {
    if (
      this.viewProvidersDirectionsMapComponent &&
      this.viewProvidersDirectionsMapComponent.mapView &&
      this.viewProvidersDirectionsMapComponent.directionsItinerary &&
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement &&
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement
    ) {
      this.viewProvidersDirectionsMapComponent.clearDirections();
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement.removeAttribute('style');
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.removeAttribute('style');
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.classList.remove('col-md-5');
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement.classList.remove('col-md-7');
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.classList.add('col-md-4');
      this.viewProvidersDirectionsMapComponent.mapView.nativeElement.classList.add('col-md-11', 'col-md-offset-1');
      this.showInputContent = true;
      this.viewProvidersDirectionsMapComponent.directionsItinerary.nativeElement.focus();
    }
  }

  printDirection() {
    this.printDrivingDirection.emit();
  }

  getDirectionActionId(): string {
    if ((this.startLocation && this.startLocation.trim() !== '') &&
      (this.endLocation && this.endLocation.trim() !== '') && this._contentHelper.isSearchResultRoute(this._navigationService.currentRoute)) {
      return this.userInteractionUtil.getSearchResultPageActionId(this.providers, this.index, this.userInteraction.providerSummaryPage.getDirectionsLink.actionTaken);
    }
    return null;
  }
}
