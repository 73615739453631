import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACCOUNT_TYPE } from '../../../fad/search-providers/values/providerSearchConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { DisclaimerService } from './../../services/disclaimerService';
import { EventHandler } from './../../services/eventHandler';
import { AppSession } from './../../values/appSession';
import { ContentHelper } from './../../values/contentHelper';
@Component({
  selector: 'app-cost-disclaimer',
  templateUrl: './pfCostDisclaimerCmp.html'
})
export class PFCostDisclaimerComponent extends BaseComponent implements OnInit {
  @Input() costRuleList: any;
  @Input() isEycDetails: boolean = false;
  @Input() screenName: string;
  tabView: any = {
    tabs: [],
    color: 'teal'
  };
  disclaimerMsg = [];

  constructor(private _disclaimer: DisclaimerService,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.tabView.tabs = Object.assign([], this.costRuleList.tabDisclaimer || []);
    this.tabView.tabs = this.tabView.tabs.map((tab, index) => {
      tab.isActive = index === 0;
      tab.isVisible = false;
      return tab;
    });
    this.disclaimerMsg = this.costRuleList.dataDisclaimer || [];
  }
  ngAfterViewInit() {
    this.addAriaLabelTab();
  }
  addAriaLabelTab() {
    if (this.costRuleList) {
      const tabTitle = document.getElementById('costDisclaimersTab').getElementsByClassName('viewButton');
      if (tabTitle && tabTitle !== undefined && tabTitle.length > 0) {
        for (let i = 0; i < tabTitle.length; i++) {
          tabTitle[i].setAttribute('aria-label', this.tabView.tabs[i].heading);
        }
      }
      let costDiscMsg: any = document.getElementsByClassName('cost-disclaimer-msg');
      if (costDiscMsg) {
        for (let index = 0; index < costDiscMsg.length; index++) {
          let costDisclaimer = document.getElementById('cost-disclaimer' + index);
          let costDis = costDisclaimer.childNodes.forEach((x: any) => {
            if (x && !x.ariaLabel) {
              let text = this.costDisclaimerAriaLabel(x.innerText);
              x.ariaLabel = text;
            }
          });
        }
      }
    }
  }

  costDisclaimerAriaLabel(disclaimer) {
    if (disclaimer && ((disclaimer.indexOf(ACCOUNT_TYPE.HIA) > -1) || (disclaimer.indexOf(ACCOUNT_TYPE.HRA) > -1) || (disclaimer.indexOf(ACCOUNT_TYPE.HSA) > -1))) {
     disclaimer= disclaimer.replace(new RegExp(ACCOUNT_TYPE.HIA, 'g'), 'H I A')
        .replace(new RegExp(ACCOUNT_TYPE.HRA, 'g'), 'H R A')
        .replace(new RegExp(ACCOUNT_TYPE.HSA, 'g'), 'H S A')
    }
    return disclaimer;
  }
}
